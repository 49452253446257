import _module from 'module.js'
import _ from 'lodash'

_module.directive('uniqueDomain', function (
  portalApi,
  $q,
  globalState
) {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, $input, attrs, ngModelCtrl) {
      let originalValue = scope.$eval(attrs.ngModel)

      ngModelCtrl.$parsers.push(function(model) {
        let modelInLowerCase = model.toLowerCase()
        ngModelCtrl.$viewValue = modelInLowerCase
        ngModelCtrl.$render(modelInLowerCase)
        return modelInLowerCase
      });

      ngModelCtrl.$asyncValidators.uniqueDomain = (
        model,
        view
      ) => {
        var value = model || view
        var dfd = $q.defer()

        if (!value || value.length === 0) {
          dfd.resolve(true)
        }

        if (!originalValue || originalValue !== value) {
          if (value) {
            portalApi
              .pbx.pbxs('domain').one(value)
              .get()
              .then(
                () => {
                  dfd.reject('exists')
                },
                err => {
                  if (err.status === 404) {
                    dfd.resolve(true)
                  } else {
                    $q.reject(err)
                  }
                }
              )
          }
        } else {
          dfd.resolve(true)
        }
        return dfd.promise
      }
    }
  }
})
