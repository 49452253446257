import jrnParser from 'classes/jrnParser'

const determineIcon = type => {
  switch (type) {
    case 'line':
      return 'icon icon-lines'
    case 'ring-group':
      return 'icon icon-ringGroups'
    case 'call-queue':
      return 'icon icon-callQueues'
    case 'conference-room':
      return 'icon icon-conferenceCall'
    case 'dial-plan':
      return 'icon icon-dialPlans'
    case 'shared-voicemail-box':
      return 'icon icon-voicemail'
    case 'virtual-fax-machine':
      return 'icon icon-sendFax'
    default:
      return ''
  }
}

const determineTooltip = type => {
  switch (type) {
    case 'line':
      return _stringLine
    case 'ring-group':
      return _stringRingGroup
    case 'call-queue':
      return _stringCallQueue
    case 'conference-room':
      return _stringConferenceRoom
    case 'dial-plan':
      return _stringDialPlan
    case 'shared-voicemail-box':
      return _stringSVB
    case 'virtual-fax-machine':
      return _stringVFM
    default:
      return ''
  }
}

let _jrn, _state, _globalState, _portalApi, _portalUtil
let _stringLine,
  _stringRingGroup,
  _stringCallQueue,
  _stringConferenceRoom,
  _stringDialPlan,
  _stringSVB,
  _stringVFM
export default class GetExtensionController {
  constructor (gettextCatalog, $state, globalState, portalUtil, portalApi) {
    'ngInject'
    this._model = {}
    _state = $state
    _globalState = globalState
    _jrn = jrnParser
    _portalApi = portalApi
    _portalUtil = portalUtil
    this.showIcon = true

    this.placeholder = gettextCatalog.getString('Select an extension...')

    this.gettextCatalog = gettextCatalog

    _stringLine = gettextCatalog.getString('Line')
    _stringRingGroup = gettextCatalog.getString('Ring Group')
    _stringCallQueue = gettextCatalog.getString('Call Queue')
    _stringConferenceRoom = gettextCatalog.getString('Conference Room')
    _stringDialPlan = gettextCatalog.getString('Dial Plan')
    _stringSVB = gettextCatalog.getString('Shared Voicemail Box')
    _stringVFM = gettextCatalog.getString('Virtual Fax Machine')

    if (!this.type) {
      this.api = _portalApi.extension.pbxs(
        _globalState.selectedPbxUUID
      ).extensions
    } else if (this.type === 'line') {
      this.api = _portalApi.extension.pbxs(_globalState.selectedPbxUUID).lines
      this.placeholder = gettextCatalog.getString('Select a line...')
    }

    this.$onInit = () => {
      this.setViewValue = item => {
        this.ngModel.$setViewValue(item.id)
      }
    }
  }

  map (ext) {
    let number = ''
    if (ext.extensionNumber) {
      number = ext.extensionNumber + ': '
    }
    return {
      id: ext.jrn,
      text: number + (ext.name || ''),
      icon: determineIcon(ext.type),
      tooltip: determineTooltip(ext.type)
    }
  }

  openLink () {
    let parsedJrnResource = _jrn.parse(this.jrn).getResources()
    let type = parsedJrnResource[0]
    let extensionId = parsedJrnResource[1]

    switch (type) {
      case 'line':
        _state.go('root.nav.pbx.extensions.lines.view.general', {
          id: _globalState.selectedPbx.domain,
          extId: extensionId
        })
        break
      case 'ring-group':
        _state.go('root.nav.pbx.extensions.ringgroups.view.general', {
          id: _globalState.selectedPbx.domain,
          extId: extensionId
        })
        break
      case 'call-queue':
        _state.go('root.nav.pbx.extensions.callqueues.view.general', {
          id: _globalState.selectedPbx.domain,
          extId: extensionId
        })
        break
      case 'conference-room':
        _state.go('root.nav.pbx.extensions.conferencerooms.view.general', {
          id: _globalState.selectedPbx.domain,
          extId: extensionId
        })
        break
      case 'dial-plan':
        _portalApi.extension
          .pbxs(_globalState.selectedPbxUUID)
          .dialPlans(extensionId)
          .get()
          .then(dialPlan => {
            _portalUtil.openDialPlanWindow(dialPlan)
          })
          .catch(error => {
            this.portalUtil.showErrorAlert(
              this.gettextCatalog.getString(
                'Error loading Dial Plan, please retry.'
              )
            )
          })
        break
      case 'shared-voicemail-box':
        _state.go('root.nav.pbx.extensions.voicemailboxes.view.general', {
          id: _globalState.selectedPbx.domain,
          extId: extensionId
        })
        break
      case 'virtual-fax-machine':
        _state.go('root.nav.pbx.extensions.virtualfaxmachines.view.general', {
          id: _globalState.selectedPbx.domain,
          extId: extensionId
        })
        break
      default:
      // do nothing
    }
  }
}
