import _module from 'module.js'
import ListControllerBase from 'classes/listController.class'

export default class ProxiesController extends ListControllerBase {
  constructor (
    $state,
    $stateParams,
    $location,
    $analytics,
    Proxies,
    gettextCatalog,
    infiniteLoader,
    navigation,
    globalState,
    portalUtil
  ) {
    'ngInject'
    super(
      gettextCatalog,
      $analytics,
      $location,
      portalUtil,
      globalState,
      navigation,
      $state,
      $stateParams
    )
    this.gettextCatalog = gettextCatalog
    this.title = this.gettextCatalog.getString('Proxies')
    this.note = this.gettextCatalog.getString(
      'Registration Proxies are deprecated, please use Outbound Proxies.'
    )

    this.headers = [
      {
        width: 30,
        name: this.gettextCatalog.getString('Address'),
        sortBy: 'address',
        asc: null
      },
      {
        width: 30,
        name: this.gettextCatalog.getString('Label'),
        sortBy: 'label',
        asc: true
      },
      {
        width: 20,
        name: this.gettextCatalog.getString('Protocol'),
        sortBy: 'transportProtocol',
        asc: null
      },
      {
        width: 10,
        name: ''
      }
    ]
    this.buttonText = this.gettextCatalog.getString('Add Registration Proxy')
    this.loader = infiniteLoader(Proxies)
  }

  delete (event, proxy) {
    let name = this.gettextCatalog.getString('proxy') + ': ' + proxy.address
    this.loader.entityApi.startDelete(event, proxy, this.data, name)
  }

  itemSelected () {}
}

_module.controller('ProxiesController', ProxiesController)
