/**
 * @ngdoc function
 * @name platformApp.controller: SharedVoicemailBoxesController
 * @description
 * # SharedVoicemailBoxesController
 * Controller of the platformApp
 */
import _module from 'module.js'
import ListControllerBase from 'classes/listController.class'

export default class SharedVoicemailBoxesController extends ListControllerBase {
  constructor (
    $state,
    $stateParams,
    $location,
    $analytics,
    SharedVoicemailBox,
    gettextCatalog,
    infiniteLoader,
    navigation,
    globalState,
    portalUtil
  ) {
    'ngInject'
    super(
      gettextCatalog,
      $analytics,
      $location,
      portalUtil,
      globalState,
      navigation,
      $state,
      $stateParams
    )

    this.gettextCatalog = gettextCatalog
    this.title = this.gettextCatalog.getString('Shared Voicemail Boxes')
    this.headers = [
      {
        width: 10,
        name: this.gettextCatalog.getString('Extension'),
        sortBy: 'extensionNumber',
        asc: true
      },
      {
        width: 50,
        name: this.gettextCatalog.getString('Name'),
        sortBy: 'name',
        asc: null
      },
      {
        width: 30,
        name: this.gettextCatalog.getString('Omit from Directory')
      },
      {
        width: 10,
        name: ''
      }
    ]
    this.buttonText = this.gettextCatalog.getString('Add Shared Voicemail Box')
    this.loader = infiniteLoader(SharedVoicemailBox)
  }

  delete (event, svb) {
    var name =
      this.gettextCatalog.getString('shared voicemail box') +
      ': ' +
      (svb.extensionNumber ? svb.extensionNumber + ': ' + svb.name : svb.name)
    this.loader.entityApi.startDelete(event, svb, this.data, name)
  }

  itemSelected (item) {
    super.itemSelected(
      item,
      'root.nav.pbx.extensions.voicemailboxes.view.general',
      'extId'
    )
  }
}

_module.controller(
  'SharedVoicemailBoxesController',
  SharedVoicemailBoxesController
)
