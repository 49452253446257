import _module from 'module.js'

_module.directive('ipAddressMask', function () {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, $input, attrs, ngModelCtrl) {
      ngModelCtrl.$validators.ipAddressMask = () => {

        return (
          $input.val().length === 0 ||
          (/^(?:[0-9]{1,3}\.){3}[0-9]{1,3}(\/(?:[0-9]{1,2}))?$/.test($input.val()) &&
            validV4Ip($input.val()))
        )
      }

      let validV4Ip = ipString => {
        let result = true
        let checkMask = $input.val().split('/')
        let ipv4 = checkMask[0]
        let mask = checkMask[1]
        let values = ipv4.split('.')
        for (let i = 0; i < 4; i++) {
          result = result && parseInt(values[i], 10) <= 255
        }
        if(mask) {
          result = result && parseInt(mask, 10) <= 32
        }
        return result
      }
    }
  }
})
