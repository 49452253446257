import jrnParser from 'classes/jrnParser'
import { FEATURE_FLAGS } from '../../services/featuresService'

export default class PermissionsBase {
  constructor (
    PermissionsData,
    User,
    Group,
    targetProperty,
    confirmationWindowOK,
    globalState,
    gettextCatalog,
    userService,
    $state
  ) {
    'ngInject'
    this.active = null
    this.confirmationWindowOK = confirmationWindowOK
    this.gettextCatalog = gettextCatalog
    this.jrn = jrnParser
    this.targets = PermissionsData[targetProperty] || []
    this.state = $state

    let active = null

    if (this.targets.length) {
      this.targets.forEach(item => {
        if (item.active) {
          this.setAsActive(item, this.targets)
          active = item
        }
      })

      if (!active) {
        this.targets[0].active = true
        this.setAsActive(this.targets[0], this.targets)
      }
    }

    const memberQuery = () => ({
      get: (params = null) => {
        const userApi = new User(null)
        const groupApi = new Group(null)
        return Promise.all([
          userApi.getList(params),
          groupApi.getList(params)
        ]).then(data => ({ items: data[0].concat(data[1]) }))
      }
    })

    this.getMembers = {
      api: memberQuery,
      filter: member => this.targets.filter(target => target.id === member.id),
      map: member => member
    }

    userService.isPermissionTabAllowed(globalState.selectedPbx).then(ret => {
      this.writePermissions = ret
    })

    this.closeBulkChanges = this.closeBulkChanges.bind(this)
    this.phoenixBulkChangesLoaded = false
    this.bulkChangesOpen = false
    this.oauthToken = globalState.oauthToken
    this.orgId = globalState.selectedPbxUUID
    this.lmiAccountKey = globalState.selectedPbx.lmiAccountKey
    this.locale = globalState.currentLanguage.langCode
    this.history = globalState.history
  }

  setAsActive (item) {
    this.active = item || null
    if (item) {
      item.active = true
      this.targets.forEach(target => {
        if (target.id !== item.id) {
          target.active = false
        }
      })
    }
  }

  addItem (list, item) {
    list.forEach(i => {
      i.active = false
    })
    list.push(item)
  }

  resetActive (item, list) {
    if (item.active) {
      this.setAsActive(list[0], list)
    }
  }

  alertPermissions (item, list) {
    if (this.writePermissions) {
      this.resetActive(item, list)
      return
    }
    list.push(item)
    this.confirmationWindowOK.show(
      this.gettextCatalog.getString('Insufficient Permissions'),
      this.gettextCatalog.getString(
        'You do not have permission to alter permissions'
      )
    )
  }

  openBulkChanges (itemForm) {
    if(itemForm && itemForm.$dirty){
      this.confirmationWindowOK
        .show(
          this.gettextCatalog.getString('Warning'),
          this.gettextCatalog.getString(
            'There are unsaved changes. Are you sure you want to navigate away?'
          )
        )
        .then(
          () => {
            this.phoenixBulkChangesLoaded = true
            this.bulkChangesOpen = true
          },
          () => {}
        )
    } else {
      this.phoenixBulkChangesLoaded = true
      this.bulkChangesOpen = true
    }
  }

  closeBulkChanges () {
    this.bulkChangesOpen = false
  }

  goToUsers () {
    this.state.go('root.nav.pbx.users.list')
  }
}
