import _module from 'module.js'
/**
 * @ngdoc service
 * @name platformApp.api
 * @description
 * # auth
 * Factory in the platformApp.
 */

let listeners = []
let deferreds = []
let authData = null

const authCallback = (err, authInfo) => {
  authData = authInfo
  notifyListeners()
  finalizePromises()
}

const notifyListeners = () => {
  for (let i = 0, l = listeners.length; i < l; i++) {
    listeners[i](authData)
  }
}
const finalizePromises = () => {
  for (let i = 0, l = deferreds.length; i < l; i++) {
    deferreds[i].resolve(authData)
  }
}

let _q, _jiveOAuthClient, _window, _location

export default class AuthService {
  doAuth (callback) {
    _jiveOAuthClient.auth.getAuthInfo(
      { inflightRequest: _window.location.pathname },
      (err, authInfo) => {
        callback(err, authInfo)
        authCallback(err, authInfo)
      }
    )
  }

  getAuthInfo (listener) {
    if (listener !== undefined && typeof listener === 'function') {
      listeners.push(listener)
      if (authData) {
        listener(authData)
      }
    }

    let deferred = _q.defer()
    if (authData) {
      deferred.resolve(authData)
    } else {
      deferreds.push(deferred)
    }
    return deferred.promise
  }

  logout () {
    _jiveOAuthClient.auth.logout()
  }

  reAuthenticate (callback) {
    _jiveOAuthClient.auth.clearAuthInfo()
    this.doAuth(callback)
  }

  static factory ($q, jiveOAuthClient, $window, $location) {
    'ngInject'
    _q = $q
    _jiveOAuthClient = jiveOAuthClient
    _window = $window
    _location = $location

    return new AuthService()
  }
}

_module.factory('authService', AuthService.factory)
