import _module from 'module.js'
import ListControllerBase from 'classes/listController.class'
import jrnParser from 'classes/jrnParser'

let _jrn, _usagePopup, _portalUtil, _BillingGroup

export default class BillingGroupController extends ListControllerBase {
  constructor (
    $state,
    $stateParams,
    $location,
    $analytics,
    BillingGroup,
    gettextCatalog,
    infiniteLoader,
    navigation,
    globalState,
    portalUtil,
    usagePopup,
    portalApi,
    FileSaver
  ) {
    'ngInject'
    super(
      gettextCatalog,
      $analytics,
      $location,
      portalUtil,
      globalState,
      navigation,
      $state,
      $stateParams
    )
    this.portalApi = portalApi
    this.globalState = globalState
    this.FileSaver = FileSaver

    _jrn = jrnParser
    this.gettextCatalog = gettextCatalog
    _usagePopup = usagePopup
    _portalUtil = portalUtil
    _BillingGroup = BillingGroup

    this.title = this.gettextCatalog.getString('Billing Groups')

    this.headers = [
      {
        width: 80,
        name: this.gettextCatalog.getString('Name'),
        sortBy: 'name',
        asc: null
      },
      {
        width: 10,
        name: ''
      },
      {
        width: 10,
        name: ''
      }
    ]

    this.buttonText = this.gettextCatalog.getString('Add Billing Group')
    this.exportDidsText = this.gettextCatalog.getString('Export DIDs')
    this.exportDevicesText = this.gettextCatalog.getString('Export Devices')
    this.selectedLanguage = globalState.currentLanguage.langCode || 'en-US'

    this.loader = infiniteLoader(BillingGroup)
  }

  delete (event, billingGroup) {
    var name =
      this.gettextCatalog.getString('billing group') + ': ' + billingGroup.name
    this.loader.entityApi.startDelete(event, billingGroup, this.data, name)
  }

  getUsage (event, item) {
    var name = this.gettextCatalog.getString('Billing Group') + ': ' + item.name
    var id = _jrn.parse(item.jrn).getResources()[1]
    _usagePopup
      .open(event, name, item, new _BillingGroup(id, item))
      .then(() => {}, () => {})
      .catch(error => {
        _portalUtil.showErrorAlert(
          this.gettextCatalog.getString('Query failed, please retry.')
        )
        throw error
      })
  }

  exportDevices () {
    this.busy = true
    const pbxDomain = this.globalState.selectedPbx.domain

    this.portalApi.tool
      .pbxs(this.globalState.selectedPbxUUID)
      .billingGroupDeviceExport()
      .get({language: this.selectedLanguage})
      .then(csv => {
        const data = new Blob([csv], { type: 'text/plain;charset=utf-8' })
        this.FileSaver.saveAs(data, pbxDomain + '-Billing Group Device Export.csv')
        this.busy = false
      })
      .catch(error => {
        this.busy = false
        _portalUtil.showErrorAlert(
          this.gettextCatalog.getString('File download failed, please retry.')
        )
        throw error
      })
  }

  exportDids () {
    this.busy = true
    const pbxDomain = this.globalState.selectedPbx.domain

    this.portalApi.tool
      .pbxs(this.globalState.selectedPbxUUID)
      .billingGroupDidExport()
      .get({language: this.selectedLanguage})
      .then(csv => {
        const data = new Blob([csv], { type: 'text/plain;charset=utf-8' })
        this.FileSaver.saveAs(data,  pbxDomain + '-Billing Group Did Export.csv')
        this.busy = false
      })
      .catch(error => {
        this.busy = false
        _portalUtil.showErrorAlert(
          this.gettextCatalog.getString('File download failed, please retry.')
        )
        throw error
      })
  }
}

_module.controller('BillingGroupController', BillingGroupController)
