import { getJrnDefaultComponent } from '../getJrn/getJrnDefaultComponent.js'
import getDidController from './getDidController.js'
import _module from 'module.js'
import _ from 'lodash'

let getDidComponent = _.cloneDeep(getJrnDefaultComponent)
getDidComponent.controller = getDidController
getDidComponent.bindings.filterFax = '<?'

_module.component('getDid', getDidComponent)
