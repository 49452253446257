import _module from 'module.js'

_module.directive('preventSubmit', () => {
  return {
    restrict: 'A',
    link: (scope, el) => {
      el.keydown(event => {
        if (event.keyCode === 13) {
          event.stopPropagation()
        }
      })

      scope.$on('$destroy', () => {
        el.off('keydown')
      })
    }
  }
})
