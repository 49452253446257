import getJrnTemplate from './getJrnTemplate.html'
import getJrnController from './getJrnController.js'
import _module from 'module.js'

_module.component('getJrn', {
  template: getJrnTemplate,
  require: {
    ngModel: 'ngModel'
  },
  bindings: {
    jrn: '=?',
    api: '<',
    placeholder: '@',
    addNewLabel: '@',
    filter: '=?',
    map: '=?',
    removable: '<?',
    onRemove: '&',
    clearOnFocus: '<?',
    openLink: '&',
    showLink: '<?',
    allowCreate: '<',
    onCreate: '&',
    off: '<',
    showIcon: '<',
    showAudioPreview: '<',
    useStandardPaging: '<',
    queryParams: '=?',
    isJrnUser: '=?',
    isJrnUserAgentType: '=?'
  },
  controllerAs: 'getJrn',
  controller: getJrnController
})
