import _ from 'lodash'

export default class CallFilterActionsController {
  constructor (portalApi, globalState, gettextCatalog) {
    'ngInject'
    this.$onInit = () => {
      this.ngModel.$render = () => {
        if (this.row && this.row.action) { this.setAction(this.getAction(this.row.action)) }
      }
    }

    this.actions = [
      {
        action: 'HANG_UP',
        label: gettextCatalog.getString('Hang Up')
      },
      {
        action: 'NOTIFY_BLOCKED',
        label: gettextCatalog.getString('Play block notification')
      },
      {
        action: 'SOUND_CLIP',
        label: gettextCatalog.getString('Sound Clip'),
        message: gettextCatalog.getString('Play sound clip')
      },
      {
        action: 'EXTENSION',
        label: gettextCatalog.getString('Extension'),
        message: gettextCatalog.getString('Send to extension')
      },
      {
        action: 'VOICEMAIL',
        label: gettextCatalog.getString('Voicemail'),
        message: gettextCatalog.getString('Send to voicemail box of extension')
      }
    ]

    this.portalApi = portalApi
    this.pbxId = globalState.selectedPbxUUID
    this.selectedActionParameter = undefined
    this.setDefaultAction()
  }

  setActionModel (action, model) {
    this.row.action = action.action
    this.row.extensionJrn = null
    this.row.voicemailBoxJrn = null
    this.row.soundClipJrn = null
    this.row.name = null
    this.row.extensionNumber = null

    if (model) {
      switch (action.action) {
        case 'EXTENSION':
          this.row.extensionJrn = model.id
          break
        case 'VOICEMAIL':
          this.row.voicemailBoxJrn = model.id
          break
        case 'SOUND_CLIP':
          this.row.soundClipJrn = model.id
          break
      }
      this.row.name = model.text
    }

    this.ngModel.$setViewValue(this.row, false)
  }

  getAction (action) {
    return _.keyBy(this.actions, 'action')[action]
  }

  setDefaultAction (action) {
    this.selectedAction = action || this.actions[0]
    this.showSelector = false
  }

  setAction (action) {
    this.selectedAction = action
    this.showActionSelector(action)
  }

  showSoundClipSelector () {
    this.showSelector = true
    this.api = this.portalApi.media.pbxs(this.pbxId).soundClips
    this.jrn = this.selectedActionParameter = this.row.soundClipJrn
  }

  showExtensionSelector () {
    this.showSelector = true
    this.api = this.portalApi.extension.pbxs(this.pbxId).extensions
    this.jrn = this.selectedActionParameter = this.row.extensionJrn
  }

  showVoicemailSelector () {
    this.showSelector = true
    this.api = this.portalApi.extension.pbxs(this.pbxId).voicemailBoxes
    this.jrn = this.selectedActionParameter = this.row.voicemailBoxJrn
  }

  map (item) {
    return {
      id: item.jrn,
      text: (item.extensionNumber ? item.extensionNumber + ': ' : '') +
        item.name
    }
  }

  showActionSelector (action) {
    this.showSelector = false
    this.jrn = this.selectedActionParameter = null
    switch (action.action) {
      case 'SOUND_CLIP':
        this.showSoundClipSelector()
        break

      case 'EXTENSION':
        this.showExtensionSelector()
        break

      case 'VOICEMAIL':
        this.showVoicemailSelector()
        break

      case 'NOTIFY_BLOCKED':
        this.setActionModel(action)
        break

      case 'HANG_UP':
        this.setActionModel(action)
        break
    }
  }
}
