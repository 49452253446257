import { Environment } from '@jive/ui-common'
import { hvoiceIcon } from '../assets/hvoiceIcon'

const assetsConfig = env => {
  const basePath = (env === 'local' || env == 'feature') ? '/images' : '/pbx/images'

  return {
    addressBook: `${basePath}/business-inactive.svg`,
    alertIcon: `${basePath}/notifications-alert-inactive.svg`,
    alertInactiveYellow: `${basePath}/alert-inactive-yellow.svg`,
    arrowIcon: `${basePath}/arrow-right.svg`,
    calendarIcon: `${basePath}/calendar-inactive.svg`,
    cartIcon: `${basePath}/cart-inactive.svg`,
    checkIcon: `${basePath}/check.svg`,
    clockInactiveIcon: `${basePath}/clock-inactive.svg`,
    close: `${basePath}/close.svg`,
    cloudIcon: `${basePath}/cloud.svg`,
    contactsIcon: `${basePath}/address-book-contacts-inactive.svg`,
    dashboardIcon: `${basePath}/dashboard-inactive.svg`,
    devicesIcon: `${basePath}/devices-icon.svg`,
    dialInIcon: `${basePath}/dial-in-inactive.svg`,
    dialOutIcon: `${basePath}/dial-out-inactive.svg`,
    dialPlanIcon: `${basePath}/dial-plan-icon.svg`,
    e911Carousel1: `${basePath}/business-active.svg`,
    e911Carousel2: `${basePath}/address-book-black.svg`,
    e911Carousel3: `${basePath}/hardphone.svg`,
    eolIcon: `${basePath}/eol-icon-24.svg`,
    errorIcon: `${basePath}/block.svg`,
    faxIcon: `${basePath}/fax.svg`,
    filterIcon: `${basePath}/filter-inactive.svg`,
    flowchartIcon: `${basePath}/flowchart.svg`,
    globeIcon: `${basePath}/globe.svg`,
    gotoIcon: `${basePath}/goto-favicon.png`,
    groupsIcon: `${basePath}/people-inactive.svg`,
    hvoiceIcon: hvoiceIcon,
    hardphoneIcon: `${basePath}/hardphone.svg`,
    infoIcon: `${basePath}/info-inactive.svg`,
    launchIcon: `${basePath}/launch-inactive.svg`,
    lockIcon: `${basePath}/locked-inactive-20-px.svg`,
    paintIcon: `${basePath}/paint-inactive.svg`,
    personIssue: `${basePath}/person_issue.svg`,
    phoneIcon: `${basePath}/phone-up-inactive.svg`,
    reportIcon: `${basePath}/report.svg`,
    roleIcon: `${basePath}/view-switch-portrait.svg`,
    roomIcon: `${basePath}/room-physical.svg`,
    securityIcon: `${basePath}/shield-inactive.svg`,
    settingsIcon: `${basePath}/settings-inactive.svg`,
    sharedLineIcon: `${basePath}/shared-line.svg`,
    siteIcon: `${basePath}/site-icon-16px.svg`,
    siteIconBlack: `${basePath}/site-icon-16px-black.svg`,
    speakerIcon: `${basePath}/loud-speaker.svg`,
    speakerOnIcon: `${basePath}/speaker-on-active.svg`,
    spacesIcon: `${basePath}/spaces-inactive.svg`,
    starEmptyIcon: `${basePath}/star-empty.svg`,
    statusIcon: `${basePath}/status-icon.svg`,
    usageIcon: `${basePath}/data-usage.svg`,
    usersIcon: `${basePath}/person-inactive.svg`,
    voicemailIcon: `${basePath}/voicemail.svg`,
    voipIcon: `${basePath}/voip.svg`,
    whatsNewOff: `${basePath}/WhatsNewOff.svg`,
    wrenchIcon: `${basePath}/wrench-inactive.svg`,
    zohoIcon: `${basePath}/zoho-logo.svg`,
    GoTo: {
      connect: `${basePath}/GoToConnect.svg`,
      meeting: `${basePath}/GoToMeeting.svg`,
      webinar: `${basePath}/GoToWebinar.svg`
    }
  }
}

const local = {
  apiBase: `http://${window.location.hostname}:1103/bff`,
  importExportBase: `http://${window.location.hostname}:1104`,
  partnerBase: `http://${window.location.hostname}:1114`,
  featuresApiUrl: `http://${window.location.hostname}:1301`,
  hotDeskingApiUrl: `http://${window.location.hostname}:1501`,
  deviceFeaturesApiUrl: `http://${window.location.hostname}:1701`,
  userSyncManagementApiUrl: `https://${window.location.hostname}:1801`,
  userAgentTypeApiUrl: `http://${window.location.hostname}:1501/v1`,
  ambassadorApiIntegrations: `http://${window.location.hostname}/ambassador/v1`,
  userMappingApiIntegrations: `http://${window.location.hostname}/integrations-usermapping/v1`,
  contactCenterConfigurationApiUrl: `http://${window.location.hostname}/contact-center-config/v2`,
  contactCenterAuthorityApiUrl: `http://${window.location.hostname}/contact-center-authority/v1`,
  orderingApiUrl: `http://${window.location.hostname}/ordering/v2`,
  numbersApiUrl: `http://${window.location.hostname}/numbers/v3`,
  locationsBffApiUrl: `https://${window.location.hostname}/unified-admin-locations-bff/v1`,
  phoneNumbersSettingsApiUrl: `http://${window.location.hostname}/phone-numbers-settings-bff/v1`,
}

const envSubDomain = env =>
  ['local', Environment.PROD].includes(env) ? '' : env

const notLocal = env => {
  let subDomain = envSubDomain(env)
  let subOfficeDomain = 'office/'
  let localDomain = ''
  if (env !== Environment.PROD) {
    subDomain = (env == 'feature') ? 'dev.' : subDomain + '.'
    subOfficeDomain = ''
  }
  if (window.location.hostname.includes('-local')) {
    localDomain = '-local'
  }

  return {
    apiBase: `https://api${localDomain}.${subDomain}jive.com/v5`,
    importExportBase: `https://api${localDomain}.${subDomain}jive.com/v5`,
    partnerBase: `https://api${localDomain}.${subDomain}jive.com/v5`,
    featuresApiUrl: `https://api${localDomain}.${subDomain}jive.com/features/v1`,
    hotDeskingApiUrl: `https://api${localDomain}.${subDomain}jive.com/hot-desking/v1`,
    deviceFeaturesApiUrl: `https://api${localDomain}.${subDomain}jive.com/device-features/v1`,
    userSyncManagementApiUrl: `https://api${localDomain}.${subDomain}jive.com/user-synchronizations-management/v1`,
    userAgentTypeApiUrl: `https://api${localDomain}.${subDomain}jive.com/user-agent-type/v1`,
    ambassadorApiIntegrations: `https://api${localDomain}.${subDomain}jive.com/ambassador/v1`,
    userMappingApiIntegrations: `https://api${localDomain}.${subDomain}jive.com/integrations-usermapping/v1`,
    contactCenterConfigurationApiUrl: `https://api${localDomain}.${subDomain}jive.com/contact-center-config/v2`,
    contactCenterAuthorityApiUrl: `https://api${localDomain}.${subDomain}jive.com/contact-center-authority/v1`,
    orderingApiUrl: `https://api${localDomain}.${subDomain}jive.com/${subOfficeDomain}ordering/v2`,
    numbersApiUrl: `https://api${localDomain}.${subDomain}jive.com/${subOfficeDomain}numbers/v3`,
    locationsBffApiUrl: `https://api${localDomain}.${subDomain}jive.com/unified-admin-locations-bff/v1`,
    phoneNumbersSettingsApiUrl: `https://api${localDomain}.${subDomain}jive.com/phone-numbers-settings-bff/v1`,
  }
}

export default env =>
  Object.assign(
    {},
    assetsConfig(env),
    env === 'local' ? local : notLocal(env),
    {
      ambassadorEnablementPath: `https://${window.location.hostname}/integrations/`
    }
  )
