import musicListItemsTemplate from './musicListItemsTemplate.html'
import musicListItemsController from './musicListItemsController.js'
import module from 'module'

module.component('musicListItems', {
  template: musicListItemsTemplate,
  controllerAs: 'musicListItems',
  require: {
    ngModel: 'ngModel'
  },
  bindings: {
    selectedList: '=ngModel'
  },
  controller: musicListItemsController
})
