import _module from 'module.js'
import musicFileUploadTemplate from './musicFileUploadTemplate.html'

let _holdMusic, _portalUtil, _ngModel, _this

const onFileUpload = (file, volume) => {
  return _holdMusic.uploadMusicFile(file, volume)
}

class musicFileUploadController {
  constructor (holdMusic, gettextCatalog, portalUtil, normalizationPopup) {
    'ngInject'
    _holdMusic = holdMusic
    this.gettextCatalog = gettextCatalog
    this.normalizationPopup = normalizationPopup
    _portalUtil = portalUtil
    this.loading = false
  }

  // as this is called from the file-upload directive, 'this' is not applied properly
  uploadFile (file) {
    _this.normalizationPopup
      .open()
      .then(volume => {
        _this.loading = true
        onFileUpload(file, volume).then(data => {
          _this.loading = false
          _ngModel.$setViewValue(data)
        })
      })
      .catch(error => {
        _this.loading = false
        _portalUtil.showErrorAlert(
          _this.gettextCatalog.getString('Music upload failed, please retry.')
        )
        throw error
      })
  }

  $onInit () {
    _ngModel = this.ngModel
    _this = this
  }
}

_module.component('musicFileUpload', {
  template: musicFileUploadTemplate,
  require: {
    ngModel: 'ngModel'
  },
  controllerAs: 'musicFileUpload',
  controller: musicFileUploadController
})
