import _module from 'module.js'
import confirmationTemplateWarn from './confirmationTemplateWarn.html'
import ConfirmationWindowBase
  from 'services/confirmation/confirmationWindowBase'

export default class ConfirmationWindowWarn extends ConfirmationWindowBase {
  constructor ($mdDialog) {
    'ngInject'
    super($mdDialog)
  }

  show (title, message, actionText) {
    super.show(title, message)
    if (actionText) {
      this.actionText = actionText
    }
    return this.$mdDialog.show({
      template: confirmationTemplateWarn,
      controller: () => this,
      controllerAs: 'ctrl'
    })
  }
}

_module.service('confirmationWindowWarn', ConfirmationWindowWarn)
