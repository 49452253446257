import _module from 'module.js'
import _ from 'lodash'
import BaseES6 from 'factories/baseES6'

let _globalState, _portalApi, _featureFlags

const isFinalized = (data) => {
  return data.addressStatus === "COMPLETE" ||
      data.addressStatus === "PARTIAL" ||
      data.addressStatus === "ADJUSTED_PARTIAL" ||
      data.addressStatus === "ADJUSTED" ||
      data.addressStatus === "FAILED"  
}

export default class Site extends BaseES6 {
  constructor (id, site, pbxId) {
    'ngInject'
    super(id, pbxId || _globalState.selectedPbxUUID)

    if (site) {
      this._map(site)
    }
    
    this.cacheEnabled = true
  }

  getApi () {
    if (!this._api) {
      this._api = _portalApi.site.pbxs(this._pbxId).sites
    }
    return this._api
  }

  getPath () {
    if (!this._path) {
      this._path = _portalApi.site.pbxs(this._pbxId).sites(this._id)
    }
    return this._path
  }

  getDevicesPath () {
    if (!this._path) 
      this._path = _portalApi.site.pbxs(this._pbxId).sites(this._id).one('devices');

    return this._path
  }

  getReferencesPath () {
    if (!this._referencesPath) {
      this._referencesPath = _portalApi.site
        .pbxs(this._pbxId)
        .sites(this._id)
        .one('references')
    }
    return this._referencesPath
  }

  map (data) {}

  mapBatchData (data) {
    data._isPbxDefault = _globalState.selectedPbx.defaultSite === data.jrn

    data.isUnverifiedState = false
    data.isProcessingState = false
    data.isFinalizedState = false

    data._fullAddress = ''
    if (data.addressLine1) {
      data._fullAddress = data.addressLine1 + ', ';
      if (data.addressLine2) {
        data._fullAddress += data.addressLine2 + ', ';
      }
      data._fullAddress += data.city + ', ' + data.state + ' ' + data.postalCode + ", ";
      data._fullAddress += data.country;

      data.isUnverifiedState = data.customerStatus !== "CUSTOMER_VERIFIED"
      data.isProcessingState = data.customerStatus === "CUSTOMER_VERIFIED" && !isFinalized(data)
      data.isFinalizedState = data.customerStatus === "CUSTOMER_VERIFIED" && isFinalized(data)
    }
  }

  build (payload) {
    if (!payload.description) {
      payload.description = ''
    }
    return payload
  }

  getClass () {
    return 'Site'
  }

  static factory (globalState, portalApi) {
    'ngInject'
    _globalState = globalState
    _portalApi = portalApi

    return (id, site, pbxId = null) => {
      return new Site(id, site, pbxId)
    }
  }
}

_module.factory('Site', Site.factory)
