import { getJrnDefaultComponent } from '../getJrn/getJrnDefaultComponent.js'
import getUserController from './getUserController.js'
import _module from 'module.js'
import _ from 'lodash'

let getUserComponent = _.cloneDeep(getJrnDefaultComponent)
getUserComponent.controller = getUserController
getUserComponent.bindings.onRemove = '&'

_module.component('getUser', getUserComponent)
