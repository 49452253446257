import jrnParser from 'classes/jrnParser'

let _jrn, _state, _globalState, _User
export default class GetUserController {
  constructor (gettextCatalog, $state, globalState, User) {
    'ngInject'
    this._model = {}
    _state = $state
    _globalState = globalState
    _jrn = jrnParser
    _User = User

    this.placeholder = gettextCatalog.getString('Select a user...')

    this.$onInit = () => {
      this.setViewValue = item => {
        this.ngModel.$setViewValue(item.id)
      }
    }
  }

  api (id = null) {
    return new _User(id)
  }

  map (user) {
    var name = user.lastName
    if (user.firstName) {
      name = user.firstName + ' ' + name
    }
    return {
      id: user.jrn,
      text: name
    }
  }

  openLink () {
    _state.go('root.nav.pbx.users.view.general', {
      id: _globalState.selectedPbx.domain,
      userId: _jrn.parse(this.jrn).getResources()[1]
    })
  }
}
