import groupListTooltipTemplate from './groupListTooltipTemplate.html'
import _module from 'module.js'

_module.component('groupListTooltip', {
  template: groupListTooltipTemplate,
  bindings: {
    label: '<',
    list: '<'
  },
  controllerAs: 'listTooltip'
})
