import dedicatedLineKeysButtonConfigTemplate
  from './dedicatedLineKeysButtonConfigTemplate.html'
import _module from 'module.js'
import _ from 'lodash'
import { ButtonConfigController } from '../../classes/buttonConfigController'

export class DedicatedLineKeysButtonConfigController
  extends ButtonConfigController {
  constructor () {
    'ngInject'
    super()
  }

  initButtonConfigSections (allButtonsByGroup, userAgent) {
    this.dedicatedLineButtonConfigs = this.createAndFillAllDeviceButtons(
      userAgent.maxAccounts,
      allButtonsByGroup.shift()
    )
    this.buttonConfigs = this.createAndFillAllDeviceButtons(
      userAgent.numberLineButtons,
      allButtonsByGroup.shift()
    )
    this.createAndFillAllSidecarCollections(userAgent, allButtonsByGroup)
  }

  loadSerializedButtons () {
    let dedicatedLineButtonConfigs = this.serializeButtonConfigs(
      this.dedicatedLineButtonConfigs,
      0
    )
    let deviceButtonConfigs = this.serializeButtonConfigs(this.buttonConfigs, 1)

    let sidecarButtonConfigs = _(
      this.sidecarButtonConfigs
    ).reduce((list, buttonConfigs, idx) => {
      return _(list)
        .extend(this.serializeButtonConfigs(buttonConfigs, idx + 2))
        .value()
    }, {})

    return _.extend(
      {},
      dedicatedLineButtonConfigs,
      deviceButtonConfigs,
      sidecarButtonConfigs
    )
  }
}

_module.component('dedicatedLineKeysButtonConfig', {
  template: dedicatedLineKeysButtonConfigTemplate,
  require: {
    ngModel: 'ngModel'
  },
  bindings: {
    buttons: '<',
    configs: '<'
  },
  controllerAs: 'dedicatedLineKeysButtonConfigCtrl',
  controller: DedicatedLineKeysButtonConfigController
})
