import _module from 'module.js'
import ListControllerBase from 'classes/listController.class'

export default class CallFiltersController extends ListControllerBase {
  constructor (
    $state,
    $stateParams,
    $location,
    $analytics,
    CallFilter,
    gettextCatalog,
    infiniteLoader,
    navigation,
    globalState,
    portalUtil
  ) {
    'ngInject'
    super(
      gettextCatalog,
      $analytics,
      $location,
      portalUtil,
      globalState,
      navigation,
      $state,
      $stateParams
    )

    this.gettextCatalog = gettextCatalog
    this.title = this.gettextCatalog.getString('Inbound Call Filters')
    this.headers = [
      {
        width: 20,
        name: this.gettextCatalog.getString('Number'),
        sortBy: 'number',
        asc: true
      },
      {
        width: 20,
        name: this.gettextCatalog.getString('Description'),
        sortBy: 'description',
        asc: null
      },
      {
        width: 50,
        name: this.gettextCatalog.getString('Action'),
        sortBy: 'action',
        asc: null
      },
      {
        width: 10,
        name: ''
      }
    ]

    this.buttonText = this.gettextCatalog.getString('Add Call Filter')
    this.loader = infiniteLoader(CallFilter)
  }

  saveAndClean (item) {
    this.save(item)
  }

  delete (event, callFilter) {
    let name =
      this.gettextCatalog.getString('call filter') + ': ' + callFilter.number
    this.loader.entityApi.startDelete(event, callFilter, this.data, name)
  }

  itemSelected () {}
}

_module.controller('CallFiltersController', CallFiltersController)
