import _ from 'lodash'

let SECONDS_LABEL, MINUTES_LABEL, HOURS_LABEL

class TimeDurationController {
  constructor (gettextCatalog) {
    'ngInject'
    this.SECONDS_PER_MINUTE = 60
    this.SECONDS_PER_HOUR = 3600
    this.MIN_SAFE_INTEGER = 0

    SECONDS_LABEL = gettextCatalog.getString('seconds')
    MINUTES_LABEL = gettextCatalog.getString('minutes')
    HOURS_LABEL = gettextCatalog.getString('hours')

    this.unitsOfTime = [
      {
        label: SECONDS_LABEL,
        value: 1
      },
      {
        label: MINUTES_LABEL,
        value: this.SECONDS_PER_MINUTE
      },
      {
        label: HOURS_LABEL,
        value: this.SECONDS_PER_HOUR
      }
    ]

    this.unitOfTime = SECONDS_LABEL
    this.secondsDuration = 0
    this.defaultSecondsDuration = this.MIN_SAFE_INTEGER
    this.maxSecondsDuration = Number.MAX_SAFE_INTEGER
    this.maxVisibleDuration = Number.MAX_SAFE_INTEGER
    this.minSecondsDuration = this.MIN_SAFE_INTEGER
    this.minVisibleDuration = this.MIN_SAFE_INTEGER
    this.required = false
  }

  $onChanges (attributesObj) {
    let unitIndex = _.findIndex(this.unitsOfTime, ['label', this.unitOfTime])
    let unitValue = this.unitsOfTime[unitIndex].value
    this.defaultSecondsDuration = attributesObj.defaultSecondsDuration.currentValue || this.defaultSecondsDuration
    attributesObj.secondsDuration.currentValue = attributesObj.secondsDuration.currentValue || this.defaultSecondsDuration
    if (attributesObj.secondsDuration.isFirstChange()) {
      this.secondsDuration = attributesObj.secondsDuration.currentValue || 0
      if (this.secondsDuration == 0) {
        this.duration = 0
        this.unitOfTime = SECONDS_LABEL
        return
      }
      if (this.secondsDuration % this.SECONDS_PER_HOUR == 0) {
        this.unitOfTime = HOURS_LABEL
        unitValue = this.SECONDS_PER_HOUR
      } else if (this.secondsDuration % this.SECONDS_PER_MINUTE == 0) {
        this.unitOfTime = MINUTES_LABEL
        unitValue = this.SECONDS_PER_MINUTE
      } else {
        this.unitOfTime = SECONDS_LABEL
      }
      this.duration = this.secondsDuration / unitValue
    }
    this.required = attributesObj.inputRequired.currentValue
    this.maxSecondsDuration =
      attributesObj.maxSecondsDuration.currentValue || Number.MAX_SAFE_INTEGER
    this.maxVisibleDuration = this.maxSecondsDuration / unitValue
    this.minSecondsDuration =
      attributesObj.minSecondsDuration.currentValue || this.MIN_SAFE_INTEGER
    this.minVisibleDuration = this.minSecondsDuration / unitValue
  }

  updateUnit (newUnit) {
    this.unitOfTime = newUnit
    let unitIndex = _.findIndex(this.unitsOfTime, ['label', this.unitOfTime])
    this.secondsDuration = this.duration * this.unitsOfTime[unitIndex].value
    this.maxVisibleDuration =
      this.maxSecondsDuration / this.unitsOfTime[unitIndex].value
    this.minVisibleDuration =
      this.minSecondsDuration / this.unitsOfTime[unitIndex].value
    this.secondsDurationChanged({ duration: this.secondsDuration })
  }

  updateSeconds (newNumber) {
    let unitIndex = _.findIndex(this.unitsOfTime, ['label', this.unitOfTime])
    let newDuration = newNumber * this.unitsOfTime[unitIndex].value
    this.secondsDuration = newDuration
    this.secondsDurationChanged({ duration: this.secondsDuration })
  }
}

export default TimeDurationController
