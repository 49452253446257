import { getJrnDefaultComponent } from '../getJrn/getJrnDefaultComponent.js'
import getBillingGroupController from './getBillingGroupController.js'
import _module from 'module.js'
import _ from 'lodash'

let getBillingGroupComponent = _.cloneDeep(getJrnDefaultComponent)
getBillingGroupComponent.controller = getBillingGroupController
getBillingGroupComponent.bindings.onCreate = '&'

_module.component('getBillingGroup', getBillingGroupComponent)
