import _ from 'lodash'
import _module from 'module.js'
import ListControllerBase from 'classes/listController.class'

export default class SchedulesController extends ListControllerBase {
  constructor (
    $state,
    $stateParams,
    $location,
    $analytics,
    Schedule,
    gettextCatalog,
    infiniteLoader,
    navigation,
    globalState,
    portalUtil
  ) {
    'ngInject'
    super(
      gettextCatalog,
      $analytics,
      $location,
      portalUtil,
      globalState,
      navigation,
      $state,
      $stateParams
    )

    this.gettextCatalog = gettextCatalog

    this.title = this.gettextCatalog.getString('Schedules')
    this.headers = [
      {
        width: 40,
        name: this.gettextCatalog.getString('Name'),
        sortBy: 'name',
        asc: true
      },
      {
        width: 50,
        name: this.gettextCatalog.getString('Override')
      },
      {
        width: 10,
        name: ''
      }
    ]
    this.buttonText = this.gettextCatalog.getString('Add Schedule')
    this.loader = infiniteLoader(Schedule)
  }

  delete (event, item) {
    var name = this.gettextCatalog.getString('schedule') + ': ' + item.name
    this.loader.entityApi.startDelete(event, item, this.data, name)
  }

  itemSelected (item) {
    super.itemSelected(
      item,
      'root.nav.pbx.schedules.view.general',
      'scheduleId'
    )
  }
}

_module.controller('SchedulesController', SchedulesController)
