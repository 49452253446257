import _module from 'module.js'
import changeExtensionDigitsTemplate
  from '../controllers/conferenceRoom/general/scheduleTemplate.html'
import _ from 'lodash'
import moment from 'moment'
import jrnParser from 'classes/jrnParser'

_module.factory('scheduleConference', function (
  $mdDialog,
  $mdMedia,
  portalApi,
  $stateParams,
  $timeout,
  $filter,
  gettextCatalog,
  userService,
  $mdConstant,
  $location,
  portalUtil
) {
  'ngInject'
  gettextCatalog.getString('reserved')

  var useFullScreen = $mdMedia('sm') || $mdMedia('xs')

  var openDialog = function (ev, conferenceBridge) {
    return $mdDialog.show({
      template: changeExtensionDigitsTemplate,
      parent: angular.element(document.body),
      targetEvent: ev,
      clickOutsideToClose: true,
      fullscreen: useFullScreen,

      controller: function ($scope, _conferenceBridge) {
        'ngInject'
        $scope.email = {}
        $scope.email.sent = false
        $scope.email.subject = _conferenceBridge.name
        $scope.email.conference = {}

        $scope.email.conference.startTime = moment().second(0).toDate()
        $scope.email.conference.endTime = moment().add(1, 'hour').toDate()

        var pbxId = jrnParser.parse(_conferenceBridge.jrn).getAccount()
        var conferenceId = jrnParser
          .parse(_conferenceBridge.jrn)
          .getResources()[1]

        function parseUserData (data) {
          var users = []
          if (data.users) {
            angular.forEach(data.users, function (user) {
              user.name = user.firstName + ' ' + user.lastName
              user.email = user.emailAddress
              users.push(user)
            })
          }
          return users
        }

        userService.getUserInfo().then(function (userInfo) {
          // taking first user 'cause lazy
          $scope.email.from = parseUserData(userInfo).shift() || {
            name: userInfo.username,
            email: userInfo.emailAddress
          }
          $scope.email.from.email = $scope.email.from.email
            ? $scope.email.from.email
            : $scope.email.from.name
          var semicolon = 186
          var space = 32
          $scope.email.to = {}
          $scope.email.to.customKeys = [
            $mdConstant.KEY_CODE.ENTER,
            $mdConstant.KEY_CODE.COMMA,
            semicolon,
            space
          ]
          $scope.email.to.addresses = []
          $scope.email.to.addresses.push($scope.email.from.email)
        })

        $scope.send = function () {
          $scope.conferenceCallbackURL =
            $location.protocol() +
            '://' +
            $location.host() +
            ':' +
            $location.port() +
            ($location.host() === 'localhost' ? '/' : '/pbx/') +
            pbxId +
            '/extensions/conferencerooms/' +
            conferenceId +
            '/callback?sendAt=' +
            $scope.email.conference.startTime.getTime()

          $scope.email.emailBody =
            '<div> <h3>Call my phone: If you would like to be called right before the conference starts, please click <a href="' +
            $scope.conferenceCallbackURL +
            '" target="_blank">here.</a>' +
            '</h3></div>'

          $scope.email.emailBody +=
            '<br><div style="font-weight: bold;">Call in number:</div>' +
            '<div> Extension:' +
            _conferenceBridge.extensionNumber +
            '</div>'
          let len = _conferenceBridge.pbxDids.length
          if (len > 0) {
            for (var i = 0; i < len; i++) {
              $scope.email.emailBody +=
                '<div>' +
                $filter('tel')(conferenceBridge.pbxDids[i].number) +
                '</div>'
            }
          }

          if (_conferenceBridge.password) {
            $scope.email.emailBody +=
              '<br><div style="font-weight: bold;">PIN:</div><span>' +
              _conferenceBridge.password +
              '</span>'
          }

          if ($scope.email.note) {
            $scope.email.emailBody +=
              '<h4>Note</h4>' + '<pre>' + $scope.email.note + '</pre>'
          }

          let request = {
            subject: $scope.email.subject,
            fromAddress: $scope.email.from.email,
            htmlBody: $scope.email.emailBody,
            toAddresses: JSON.parse(JSON.stringify($scope.email.to.addresses)),
            ics: createCalendarEvent()
          }

          let emailApi = portalApi.extension
            .pbxs(pbxId)
            .conferenceRooms(conferenceId)
            .one('email')

          _.extend(emailApi, request)

          emailApi
            .put()
            .then(() => {
              $scope.email.sent = true
              $timeout(() => {
                $scope.email.sent = false
              }, 5000)
            })
            .catch(function (error) {
              portalUtil.showErrorAlert('Sending email failed, please retry.')
              throw error
            })
        }

        $scope.cancel = function () {
          $mdDialog.cancel('cancel')
        }

        function createCalendarEvent () {
          var SEPARATOR = navigator.appVersion.indexOf('Win') !== -1
            ? '\r\n'
            : '\n'
          var calendarStart = ['BEGIN:VCALENDAR', 'VERSION:2.0'].join(SEPARATOR)
          var calendarEnd = SEPARATOR + 'END:VCALENDAR'

          var startDate = $scope.email.conference.startTime
          var endDate = $scope.email.conference.endTime

          var startYear = ('0000' + startDate.getFullYear().toString()).slice(
            -4
          )
          var startMonth = ('00' + (startDate.getMonth() + 1).toString()).slice(
            -2
          )
          var startDay = ('00' + startDate.getDate().toString()).slice(-2)
          var startHours = ('00' + startDate.getHours().toString()).slice(-2)
          var startMinutes = ('00' + startDate.getMinutes().toString()).slice(
            -2
          )
          var startSeconds = ('00' + startDate.getSeconds().toString()).slice(
            -2
          )

          var endYear = ('0000' + endDate.getFullYear().toString()).slice(-4)
          var endMonth = ('00' + (endDate.getMonth() + 1).toString()).slice(-2)
          var endDay = ('00' + endDate.getDate().toString()).slice(-2)
          var endHours = ('00' + endDate.getHours().toString()).slice(-2)
          var endMinutes = ('00' + endDate.getMinutes().toString()).slice(-2)
          var endSeconds = ('00' + endDate.getSeconds().toString()).slice(-2)

          // Since some calendars don't add 0 second events, we need to remove time if there is none...
          var startTime = ''
          var endTime = ''
          if (startMinutes + startSeconds + endMinutes + endSeconds !== 0) {
            startTime = 'T' + startHours + startMinutes + startSeconds
            endTime = 'T' + endHours + endMinutes + endSeconds
          }

          var start = startYear + startMonth + startDay + startTime
          var end = endYear + endMonth + endDay + endTime

          var description =
            'Conference Number extension: ' +
            _conferenceBridge.extensionNumber +
            '\\n'
          let len = _conferenceBridge.pbxDids.length
          if (len > 0) {
            for (var i = 0; i < len; i++) {
              description +=
                $filter('tel')(conferenceBridge.pbxDids[i].number) + '\\n'
            }
          }
          description += '\\nPIN: ' + _conferenceBridge.password + '\\n'
          description += $scope.email.note ? $scope.email.note : ''

          var stamp = new Date().toISOString()

          var calendarEvent = [
            'BEGIN:VEVENT',
            'CLASS:PUBLIC',
            'ORGANIZER;CN=' +
              $scope.email.from.email +
              ':MAILTO:' +
              $scope.email.from.email,
            'DTSTART:' + start,
            'DTEND:' + end,
            'DTSTAMP:' +
              stamp.substring(0, stamp.length - 13).replace(/[-]/g, '') +
              '000000Z',
            'SUMMARY;LANGUAGE=en-us:' + $scope.email.subject,
            'DESCRIPTION:' + description.replace('\r\n', '\\n'),
            'TRANSP:TRANSPARENT',
            'END:VEVENT'
          ].join(SEPARATOR)

          return calendarStart + SEPARATOR + calendarEvent + calendarEnd
        }
      },

      resolve: {
        _conferenceBridge: function () {
          return conferenceBridge
        }
      }
    })
  }

  return {
    confirm: openDialog
  }
})
