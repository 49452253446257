/**
 * @ngdoc function
 * @name platformApp.factory:soundClipService
 * @description
 * # soundClipService
 * Controller of the platformApp
 */
import _module from 'module.js'
import BaseES6 from 'factories/baseES6'

let _stateParams, _portalApi, _globalState

export default class SoundClip extends BaseES6 {
  constructor (id, soundClip, pbxId, gettextCatalog, normalizationPopup, portalUtil) {
    'ngInject'
    super(id || _stateParams.clipId, pbxId || _globalState.selectedPbxUUID)
    this.gettextCatalog = gettextCatalog
    this.normalizationPopup = normalizationPopup
    this.portalUtil = portalUtil

    if (id === undefined) {
      throw new Error(
        this.gettextCatalog.getString('Missing id for new SoundClip')
      )
    }

    if (soundClip) {
      this._map(soundClip)
    }
    this.cacheEnabled = true
  }

  save (file, fileName) {
    return this.normalizationPopup
      .open()
      .then(volume => {
        let _file = file || this.file

        let payload = this._build(this.getPath())
        let formData = new FormData()
        let details = {
          name: this.name,
          description: this.description,
          normalization: volume
        }

        if (fileName) {
          formData.append('content', _file, fileName)
        } else {
          formData.append('content', _file)
        }
        formData.append('details', JSON.stringify(details))
        return payload
          .withHttpConfig({
            transformRequest: angular.identity
          })
          .customPOST(formData, undefined, undefined, {
            'Content-Type': undefined
          })
      })
      .catch(error => {
        this.loading = false
        this.portalUtil.showErrorAlert(
          this.gettextCatalog.getString(
            'Sound Clip upload failed, please retry.'
          )
        )
        throw error
      })
  }

  getApi () {
    if (!this._api) this._api = _portalApi.media.pbxs(this._pbxId).soundClips

    return this._api
  }

  getPath () {
    if (!this._path) { this._path = _portalApi.media.pbxs(this._pbxId).soundClips(this._id) }

    return this._path
  }

  getReferencesPath () {
    if (!this._referencesPath) {
      this._referencesPath = _portalApi.media
        .pbxs(this._pbxId)
        .soundClips(this._id)
        .one('references')
    }
    return this._referencesPath
  }

  map (data) {
    if (data.file) {
      this.file = data.file
    }
  }

  mapListItem (clip) {
    return {
      id: clip.jrn,
      text: clip.name
    }
  }

  getClass () {
    return 'SoundClip'
  }

  static factory (
    $state,
    $stateParams,
    portalApi,
    gettextCatalog,
    globalState,
    normalizationPopup,
    portalUtil
  ) {
    'ngInject'
    _stateParams = $stateParams
    _portalApi = portalApi
    _globalState = globalState

    return (id, soundClip, pbxId = null) => {
      return new SoundClip(
        id,
        soundClip,
        pbxId,
        gettextCatalog,
        normalizationPopup,
        portalUtil
      )
    }
  }
}
_module.factory('SoundClip', SoundClip.factory)
