import _module from 'module.js'
import moment from 'moment'
import BaseController from 'classes/baseController'
import jrnParser from 'classes/jrnParser'

let _jrn, _window, _DialPlan, _globalState

export default class ScheduleController extends BaseController {
  constructor (
    portalApi,
    $stateParams,
    schedule,
    Schedule,
    userService,
    $window,
    gettextCatalog,
    $state,
    portalUtil,
    errorService,
    globalState,
    navigation,
    DialPlan
  ) {
    'ngInject'
    super(
      $state,
      $stateParams,
      Schedule,
      portalApi,
      userService,
      gettextCatalog,
      portalUtil,
      errorService,
      globalState,
      navigation,
      'root.nav.pbx.schedules.list',
      'scheduleId',
      schedule
    )

    _jrn = jrnParser
    _window = $window
    _DialPlan = DialPlan
    _globalState = globalState

    this.itemName = schedule.name
    this.ready = true

    this.data.usageLoading = true
    this.data.getReferencesApi().get().then(references => {
      if (references && references.dialPlans) {
        references.dialPlans.forEach(dialPlan => {
          dialPlan.id = _jrn.parse(dialPlan.jrn).getResources()[1]
        })
        this.data.references = references
      }
      this.data.usageLoading = false
    })
  }

  save (form) {
    this.ready = false
    this.data.name = this.itemName
    this.data.getHolidayData().then(() => {
      return this.data
        .update()
        .then(() => {
          form.$setPristine()
          this.ready = true
        })
        .catch(error => {
          this.portalUtil.showErrorAlert(
            this.gettextCatalog.getString('Save failed, please retry.')
          )
          this.ready = true
          throw error
        })
    })
  }

  timeRangesChanged (timeRanges) {
    this.data.timeRanges = timeRanges
  }

  openDialPlan (dialPlan) {
    let dialPlanApi = new _DialPlan(dialPlan.id, dialPlan)
    _window.open(dialPlanApi.createDialPlanUrl(), '_blank').focus()
  }
}

_module.controller('ScheduleController', ScheduleController)
