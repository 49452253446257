import _module from 'module.js'
import _ from 'lodash'

import ListControllerBase from 'classes/listController.class'

export default class corpDirectoryControllerFactory extends ListControllerBase {
  constructor (
    $state,
    $stateParams,
    $location,
    $analytics,
    CorporateDirectory,
    gettextCatalog,
    infiniteLoader,
    navigation,
    globalState,
    portalUtil
  ) {
    'ngInject'
    super(
      gettextCatalog,
      $analytics,
      $location,
      portalUtil,
      globalState,
      navigation,
      $state,
      $stateParams
    )

    this.gettextCatalog = gettextCatalog

    this.title = this.gettextCatalog.getString('Corporate Directories')

    this.headers = [
      {
        width: 25,
        name: this.gettextCatalog.getString('Name'),
        sortBy: 'name',
        asc: true
      },
      {
        width: 25,
        name: this.gettextCatalog.getString('Host'),
        sortBy: 'host',
        asc: null
      },
      {
        width: 40,
        name: this.gettextCatalog.getString('Port'),
        sortBy: 'port',
        asc: null
      },
      {
        width: 10,
        name: ''
      }
    ]

    this.buttonText = this.gettextCatalog.getString('Add Corporate Directory')

    this.loader = infiniteLoader(CorporateDirectory)
  }

  delete (event, item) {
    let name = this.gettextCatalog.getString('directory') + ': ' + item.name
    this.loader.entityApi.startDelete(event, item, this.data, name)
  }

  itemSelected (item) {
    super.itemSelected(
      item,
      'root.nav.pbx.corp-directories.view.general',
      'directoryId'
    )
  }
}

_module.controller(
  'CorporateDirectoriesController',
  corpDirectoryControllerFactory
)
