export default [
  {
    deviceFeatureKey: 'polycom.acousticFenceEnable',
    value: false,
    valueSourceId: 'DFC-1',
    valueSourceType: 'DEVICE',
    valueType: 'boolean'
  },
  {
    deviceFeatureKey: 'polycom.acousticFenceThreshold',
    value: 10,
    valueSourceId: 'DFC-2',
    valueSourceType: 'DEVICE',
    valueType: 'integer'
  },
  {
    deviceFeatureKey: 'network.cdp',
    value: null,
    valueSourceId: 'DFC-3',
    valueSourceType: 'ORGANIZATION',
    valueType: 'boolean'
  },
  {
    deviceFeatureKey: 'network.lldp',
    value: null,
    valueSourceId: 'DFC-4',
    valueSourceType: 'ORGANIZATION',
    valueType: 'boolean'
  },
  {
    deviceFeatureKey: 'polycom.ldap.directoryViewPersistence',
    value: null,
    valueSourceId: 'DFC-5',
    valueSourceType: 'DEVICE',
    valueType: 'boolean'
  },
  {
    deviceFeatureKey: 'polycom.ldap.backgroundSync',
    value: null,
    valueSourceId: 'DFC-6',
    valueSourceType: 'DEVICE',
    valueType: 'boolean'
  },
  {
    deviceFeatureKey: 'polycom.ldap.directoryViewPersistence',
    value: true,
    valueSourceId: 'DFC-7',
    valueSourceType: 'ORGANIZATION',
    valueType: 'boolean'
  },
  {
    deviceFeatureKey: 'polycom.ldap.backgroundSync',
    value: true,
    valueSourceId: 'DFC-8',
    valueSourceType: 'ORGANIZATION',
    valueType: 'boolean'
  },
  {
    deviceFeatureKey: 'feature.hotDesking',
    value: true,
    valueSourceId: 'DFC-9',
    valueSourceType: 'DEVICE',
    valueType: 'boolean'
  },
  {
    deviceFeatureKey: 'network.jbc',
    value: null,
    valueSourceId: 'DFC-10',
    valueSourceType: 'DEVICE',
    valueType: 'string'
  },
  {
    deviceFeatureKey: 'network.jbc',
    value: 'cpe71',
    valueSourceId: 'DFC-11',
    valueSourceType: 'ORGANIZATION',
    valueType: 'string'
  },
  {
    deviceFeatureKey: 'provisioning.security.adminPassword',
    value: 'fj39kf9h3kld9kasd04hkd093',
    valueSourceId: 'OFC-12',
    valueSourceType: 'ORGANIZATION',
    valueType: 'string'
  }
]
