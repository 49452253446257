import _ from 'lodash'
import _module from 'module.js'
import ListControllerBase from 'classes/listController.class'

export default class UnavailableLinesController extends ListControllerBase {
  constructor (
    $state,
    $stateParams,
    $location,
    $analytics,
    UnavailableLine,
    gettextCatalog,
    infiniteLoader,
    navigation,
    globalState,
    portalUtil
  ) {
    'ngInject'
    super(
      gettextCatalog,
      $analytics,
      $location,
      portalUtil,
      globalState,
      navigation,
      $state,
      $stateParams
    )

    this.gettextCatalog = gettextCatalog

    this.title = this.gettextCatalog.getString('Unavailable Lines')

    // TODO: sort on configuration name
    this.headers = [
      {
        width: 25,
        name: this.gettextCatalog.getString('Extension'),
        sortBy: 'extension_number',
        asc: true
      },
      {
        width: 25,
        name: this.gettextCatalog.getString('Line Name'),
        sortBy: 'extension_name',
        asc: true
      },
      {
        width: 25,
        name: this.gettextCatalog.getString('Phone Type')
      },
      {
        width: 25,
        name: this.gettextCatalog.getString('Notes')
      }
    ]

    this.loader = infiniteLoader(UnavailableLine)
  }
}

_module.controller('UnavailableLinesController', UnavailableLinesController)
