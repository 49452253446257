import selectExtensionTypeTemplate from './selectExtensionTypeTemplate.html'
import _module from 'module.js'

_module.directive('selectExtensionType', function () {
  return {
    restrict: 'A',
    replace: true,
    template: selectExtensionTypeTemplate,
    require: ['ngModel', 'selectExtensionType'],
    controllerAs: 'selectExtensionType',
    bindToController: true,

    controller: function (extensionTypes) {
      'ngInject'
      this.types = extensionTypes.allTypes
    },
    link: function (scope, attrs, el, ctrls) {
      let ngModel = ctrls[0]
      let selectExtensionType = ctrls[1]

      if (!ngModel.$modelValue) {
        ngModel.$setViewValue(selectExtensionType.types[0].id)
      }
    }
  }
})
