import _module from 'module.js'
import { FEATURE_FLAGS } from './featuresService'

export default class UnifiedAdminLocationsService {
  constructor (globalState, featuresService) {
    'ngInject'
    this.globalState = globalState
    this.featuresService = featuresService
  }

  isLocationsAvailable () {
    return this.globalState.selectedPbx.featureFlags[FEATURE_FLAGS.e911V3Configuration] || this.globalState.isPlatformAdmin || this.globalState.isPlatform;
  }

  fetchIsLocationsAvailable () {
    return this.featuresService
      .getContextFeature(this.globalState._selectedPbxUUID, FEATURE_FLAGS.e911V3Configuration)
      .then(featureFlag => {
        const isFeatureEnabled = featureFlag.setting === 'ENABLED';
        this.globalState.selectedPbx.featureFlags[FEATURE_FLAGS.e911V3Configuration] = isFeatureEnabled;

        return this.isLocationsAvailable();
      });
  }
}

_module.service(
  'unifiedAdminLocationsService',
  UnifiedAdminLocationsService
)
