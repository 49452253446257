import _module from 'module.js'
import listTooltipTemplate from './listTooltipTemplate.html'
import ListTooltipController from './listTooltipController.js'

_module.component('listTooltip', {
  template: listTooltipTemplate,
  bindings: {
    label: '<',
    list: '<'
  },
  controllerAs: 'listTooltip',
  controller: ListTooltipController
})
