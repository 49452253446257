/**
 * Device Settings Controller contains the display logic
 for settings
 * Device Controller contains the actual data retrieval code
 */

import _module from 'module.js'
import jrnParser from 'classes/jrnParser'


export default class DeviceHiddenController {
  constructor (
    $state,
    $stateParams,
    portalApi,
    $timeout,
    portalUtil,
    gettextCatalog,
    deviceData,
    confirmationWindowWarn,
    globalState,
    userAgentTypeService,
    FEATURE_FLAGS,
  
  ) {
    'ngInject'
    this.$state = $state
    this.$stateParams = $stateParams
    this.jrn = jrnParser
    this.portalApi = portalApi
    this.$timeout = $timeout
    this.confirmationWindowWarn = confirmationWindowWarn
    this.portalUtil = portalUtil
    this.gettextCatalog = gettextCatalog
    this.globalState = globalState
    this.userAgentTypeService = userAgentTypeService
    this.isSipTrunkEnabled = globalState.selectedPbx.featureFlags[FEATURE_FLAGS.sipTrunks]
    this.copyDelay = 5000
    this.copyCalled, this.copyErrorCalled
    this.data = deviceData
    this.isUATypeSipTrunk = !!this.data.userAgentType && this.data.userAgentType.jrn === "jrn:user-agent::jive::user-agent-type/goto.sip_trunk"
    this.hideSection = this.isUATypeSipTrunk && this.isSipTrunkEnabled

    this.sipRegistrations = []
    let sipApi = this.portalApi.sip
      .pbxs(this.globalState.selectedPbxUUID)
      .sipRegistrations(this.$stateParams.deviceId)
    sipApi.get().then(data => {
      _.forEach(data, sip => {
        if (sip.source === ':0') {
          sip.source = null
        }
      })
      this.sipRegistrations = data
    })

    this.childRegistrations = []
    let childUacApi = this.portalApi.userAgent
      .pbxs(this.globalState.selectedPbxUUID)
      .userAgentConfigurations(this.$stateParams.deviceId)
      .one('children')
    childUacApi.get().then(data => {
      let registrations = data.registrations
      Object.keys(registrations).forEach(key => {
        let parent = {}
        parent.uacJrn = key
        parent.childRegistrations = []
        let userAgentType =
          (data.userAgents[key].intendedUserAgentType &&
            data.userAgents[key].intendedUserAgentType.split('/')[1]) ||
          'unknown'
        parent.rawUserAgent = userAgentType

        if (userAgentType !== 'unknown') {
          this.userAgentTypeService
            .getUserAgentType(userAgentType)
            .then(userAgentType => {
              parent.userAgentType = userAgentType
            })
        }

        registrations[key].forEach(sip => {
          sip.uacJrn = key

          parent.childRegistrations.push(sip)
        })
        this.childRegistrations.push(parent)
      })
    })
  }

  clipboardSuccess (ev, copiedSipRegistration, type) {
    if (this.copyCalled) {
      this.$timeout.cancel(this.copyCalled)
      angular.forEach(this.sipRegistrations, sip => {
        sip.usernameCopied = false
        sip.passwordCopied = false
      })

      angular.forEach(this.childRegistrations, parent => {
        angular.forEach(parent.childRegistrations, sip => {
          sip.usernameCopied = false
          sip.passwordCopied = false
        })
      })
    }
    if (type === 'username') {
      copiedSipRegistration.usernameCopied = true
    } else {
      copiedSipRegistration.passwordCopied = true
    }
    this.copyCalled = this.$timeout(() => {
      angular.forEach(this.sipRegistrations, sip => {
        sip.usernameCopied = false
        sip.passwordCopied = false
      })

      angular.forEach(this.childRegistrations, parent => {
        angular.forEach(parent.childRegistrations, sip => {
          sip.usernameCopied = false
          sip.passwordCopied = false
        })
      })
    }, this.copyDelay)

    ev.clearSelection()
  }

  clipboardError (ev, copiedSipRegistration, type) {
    if (this.copyErrorCalled) {
      this.$timeout.cancel(this.copyErrorCalled)
      angular.forEach(this.sipRegistrations, sip => {
        sip.usernameClipboardError = false
        sip.passwordClipboardError = false
      })

      angular.forEach(this.childRegistrations, parent => {
        angular.forEach(parent.childRegistrations, sip => {
          sip.usernameCopied = false
          sip.passwordCopied = false
        })
      })
    }
    if (type === 'username') {
      copiedSipRegistration.usernameClipboardError = true
    } else {
      copiedSipRegistration.passwordClipboardError = true
    }
    this.copyErrorCalled = $timeout(() => {
      angular.forEach(this.sipRegistrations, sip => {
        sip.usernameClipboardError = false
        sip.passwordClipboardError = false
      })

      angular.forEach(this.childRegistrations, parent => {
        angular.forEach(parent.childRegistrations, sip => {
          sip.usernameCopied = false
          sip.passwordCopied = false
        })
      })
    }, this.copyDelay)
  }

  delete (deviceInfo) {
    let deviceId = deviceInfo.uacJrn.split('/')[1]
    let displayName = deviceInfo.rawUserAgent
    this.confirmationWindowWarn
      .show(
        this.gettextCatalog.getString('Confirm Delete'),
        this.gettextCatalog.getString('Are you sure you want to delete ') +
          displayName +
          '?',
        this.gettextCatalog.getString('Delete')
      )
      .then(
        () => {
          for (var i = 0; i < this.childRegistrations.length; i++) {
            if (this.childRegistrations[i].uacJrn == deviceInfo.uacJrn) {
              this.childRegistrations[i].deleting = true
            }
          }

          this.portalApi.userAgent
            .pbxs(this.globalState.selectedPbxUUID)
            .userAgentConfigurations(deviceId)
            .remove()
            .then(data => {
              let index = _.findIndex(this.childRegistrations, child => {
                return child.uacJrn.split('/')[1] === deviceId
              })
              this.childRegistrations.splice(index, 1)
            })
            .catch(error => {
              for (var i = 0; i < this.childRegistrations.length; i++) {
                if (this.childRegistrations[i].uacJrn == deviceInfo.uacJrn) {
                  this.childRegistrations[i].deleting = false
                }
              }
              this.portalUtil.showErrorAlert(
                gettextCatalog.getString('Delete failed, please retry.')
              )
              throw error
            })
        },
        () => {}
      )
  }
}

_module.controller('DeviceHiddenController', DeviceHiddenController)
