import jrnParser from 'classes/jrnParser'

export default class playlistItemsController {
  constructor (portalUtil, gettextCatalog, HoldMusicPlaylist, $timeout) {
    'ngInject'
    this.jrn = jrnParser
    this.portalUtil = portalUtil
    this.gettextCatalog = gettextCatalog
    this.HoldMusicPlaylist = HoldMusicPlaylist
    this.$timeout = $timeout
  }

  $onInit () {
    this.ngModel.$render = () => {
      this.data = this.ngModel.$modelValue
      this.songs = this.data.files
      // using timeoout so that the spinning-wheel showing up.
      this.$timeout(() => {
        this.data.HMLoading = false
      }, 100)
    }
  }

  updateShuffle () {
    this.data.randomPlayOrder = !this.data.randomPlayOrder
    this.update(this.data).catch(error => {
      this.data.randomPlayOrder = !this.data.randomPlayOrder
      this.portalUtil.showErrorAlert(
        this.gettextCatalog.getString('Updating playlist failed, please retry.')
      )
      throw error
    })
  }

  rearrangeSongs (index, song) {
    let songsWithoutSong = _.reject(this.songs, _song => {
      return _song.jrn === song.jrn
    })
    songsWithoutSong.splice(index, 0, song)

    this.data.files = this.songs = songsWithoutSong

    return this.update(this.data).catch(error => {
      // reset back to the songs before change
      this.data.files = this.songs
      this.portalUtil.showErrorAlert(
        this.gettextCatalog.getString('Updating playlist failed, please retry.')
      )
      throw error
    })
  }

  // Here we only delete the song from the list, because when
  // a song is requested to be deleted from the server, the server
  // will remove the song from every playlist. The client does not need
  // to find out all playlists of which this song is a member.
  deleteSongFromList (song) {
    this.songs = this.data.files = _.reject(this.data.files, function (_song) {
      return _song.jrn === song.jrn
    })
  }

  // When removing a song from a specific playlist, we need to update
  // the playlist only.
  removeSongFromList (song) {
    let beforeRemove = angular.copy(this.data.files)
    this.songs = this.data.files = _.reject(this.data.files, _song => {
      return _song.jrn === song.jrn
    })

    return this.update(this.data).catch(error => {
      // reset back to the songs before remove
      this.data.files = this.songs = beforeRemove
      this.portalUtil.showErrorAlert(
        this.gettextCatalog.getString(
          'Removing a song from playlist failed, please retry.'
        )
      )
      throw error
    })
  }

  update (item) {
    let id = this.jrn.parse(item.jrn).getResources()[1]
    let playlist = new this.HoldMusicPlaylist(id)
    return playlist.get().then(() => {
      playlist.map(item)
      return playlist.update()
    })
  }

  addSong (song) {
    let id = this.jrn.parse(this.data.jrn).getResources()[1]
    let playlist = new this.HoldMusicPlaylist(id)
    playlist
      .addSong(song)
      .then(() => {
        this.songs.unshift(song)
      })
      .catch(error => {
        this.portalUtil.showErrorAlert(
          this.gettextCatalog.getString(
            'Adding a song to a playlist failed, please retry.'
          )
        )
        throw error
      })
  }

  onSongsRearranged (droppedIntoIndex, song) {
    if (!(this.selectedList._id === 'default')) {
      this.rearrangeSongs(droppedIntoIndex, song).then(() => {
        this.ngModel.$setViewValue(_.clone(this.data))
      })
    }
  }
}
