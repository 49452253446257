import RecordRTC from 'recordrtc/RecordRTC.js'

// The audio size is 64kb/s, 30 minutes recording is 64kb * 30 * 60 = 115.2Mb
const TIMEOUT = 30 * 60 * 1000
export default class soundRecorderController {
  constructor ($element, $timeout, gettextCatalog, portalUtil, $interval) {
    'ngInject'
    this.portalUtil = portalUtil
    this.gettextCatalog = gettextCatalog
    this.recorder = null
    this.audioElement = $element.find('audio')[0]
    this.audio = {}

    this.microphone = null
    this.$timeout = $timeout
    this.$interval = $interval
    this.timeoutPromise, this.timerPromise
    this.timer = {
      value: 0,
      minute: 0,
      second: '00'
    }
  }

  createNewAudioElement (changeSrc = false) {
    // Bug: https://jira.ops.expertcity.com/browse/ADMIN-8452
    // When fixing this bug, the audio element needs to be recreated everytime,
    // otherwise, a high pitch sound is recorded rather what is intended.
    // Example: https://github.com/muaz-khan/RecordRTC/blob/master/simple-demos/audio-recording.html#L55

    let newAudio = document.createElement('audio')
    newAudio.controls = true
    newAudio.controlsList = 'nodownload'

    if (changeSrc) {
      newAudio.src = URL.createObjectURL(this.recorder.getBlob())
    }

    let parentNode = document.querySelector('.audio-recorder-player')
    parentNode.innerHTML = ''
    parentNode.appendChild(newAudio)

    this.audio.element = this.audioElement = newAudio
    this.audioElement.recordRTC = this.recorder
  }

  recordingCallback (audioUrl) {
    this.$interval.cancel(this.timerPromise)
    this.createNewAudioElement(true)
    this.audioElement.muted = false
  }

  startRecording () {
    this.timer = {
      value: 0,
      minute: 0,
      second: '00'
    }

    this.createNewAudioElement()

    this.audioElement.busy = true
    let mediaConstraints = { audio: true, video: false }

    navigator.mediaDevices
      .getUserMedia(mediaConstraints)
      .then(microphoneStream => {
        this.audioElement.muted = true
        this.audioElement.srcObject = microphoneStream
        this.audioElement.play()

        if (this.recorder) {
          this.recorder.destroy()
          this.recorder = null
        }

        this.recorder = RecordRTC(microphoneStream, {
          type: 'audio',
          recorderType: RecordRTC.StereoAudioRecorder,
          desiredSampRate: 16000
        })

        this.timeoutPromise = this.$timeout(() => {
          this.audioElement.busy = false
        }, TIMEOUT)

        this.recorder
          .setRecordingDuration(TIMEOUT)
          .onRecordingStopped(this.recordingCallback.bind(this))

        this.timerPromise = this.$interval(() => {
          this.timer.value++
          this.timer.minute = Math.trunc(this.timer.value / 60)
          let secondValue = this.timer.value % 60
          this.timer.second = secondValue < 10 ? '0' + secondValue : secondValue
        }, 1000)

        this.recorder.startRecording()
      })
      .catch(error => {
        this.portalUtil.showErrorAlert(
          this.gettextCatalog.getString('Unable to access your microphone.')
        )
        throw error
      })
  }

  stopRecording () {
    this.audioElement.busy = false
    if (this.timeoutPromise) {
      this.$timeout.cancel(this.timeoutPromise)
    }
    this.recorder.stopRecording(this.recordingCallback.bind(this))
  }
}
