/**
 * @ngdoc function
 * @name platformApp.controller:HoldMusicsController
 * @description
 * # HoldMusicsController
 * Controller of the platformApp
 */
import _module from 'module.js'
import jrnParser from 'classes/jrnParser'

export default class HoldMusicsController {
  constructor (
    $state,
    $stateParams,
    $location,
    $rootScope,
    globalState,
    portalApi,
    HoldMusicPlaylist,
    gettextCatalog,
    holdMusic,
    errorService,
    portalUtil,
    $timeout
  ) {
    'ngInject'
    this.$state = $state
    this.$stateParams = $stateParams
    this.$location = $location
    this.globalState = globalState
    this.HoldMusicPlaylist = HoldMusicPlaylist
    this.gettextCatalog = gettextCatalog
    this.holdMusic = holdMusic
    this.errorService = errorService
    this.portalUtil = portalUtil

    this.listType = null
    this.list = null
    this.ready = false

    this.musicListSelectTypes = {
      BUILT_IN: {
        value: 'builtIn',
        label: this.gettextCatalog.getString('Built In')
      },

      UPLOADED: {
        value: 'uploaded',
        label: this.gettextCatalog.getString('Uploaded')
      }
    }

    let holdmusicId = this.$stateParams.holdmusicId

    if (holdmusicId === 'builtIn' || holdmusicId === 'uploaded') {
      this.holdMusic
        .getMusic(holdmusicId)
        .then(data => {
          if (holdmusicId === 'builtIn') {
            data.type = this.musicListSelectTypes['BUILT_IN']
          } else {
            data.type = this.musicListSelectTypes['UPLOADED']
          }
          this.list = data
          this.listType = 'musicList'
          this.ready = true
        })
        .catch(error => {
          let errorMessage = this.errorService.getErrorMessage(error)
          this.portalUtil.showErrorAlert(
            _.isEmpty(errorMessage)
              ? this.gettextCatalog.getString(
                  'Data retrieval failed, please retry.'
                )
              : errorMessage
          )
          this.ready = true
          throw error
        })
    } else {
      let playlist = new this.HoldMusicPlaylist(this.$stateParams.holdmusicId)
      playlist
        .get({'language': this.globalState.currentLanguage.langCode})
        .then(data => {
          this.listType = 'playList'
          this.list = data
          this.ready = true
          $timeout(() => {
            $rootScope.$apply()
          })
        })
        .catch(error => {
          $timeout(() => {
            $rootScope.$apply()
          })
          let errorMessage = this.errorService.getErrorMessage(error)
          this.portalUtil.showErrorAlert(
            _.isEmpty(errorMessage)
              ? this.gettextCatalog.getString(
                  'Data retrieval failed, please retry.'
                )
              : errorMessage
          )
          this.ready = true
          throw error
        })
    }
  }

  listSelected (list, type) {
    let id = ''
    if (type === 'musicList') {
      id = list.type.value
    } else {
      id = list._id
    }
    this.$state.go(
      'root.nav.pbx.holdmusic.list',
      { holdmusicId: id },
      { notify: false }
    )
    this.listType = type
    this.list = list
    this.list.HMLoading = true
  }
}

_module.controller('HoldMusicsController', HoldMusicsController)
