import displayZohoUserExtensionController
  from './displayZohoUserExtensionController'
import _module from 'module.js'

let displayZohoUserExtensionComponent = {
  template: `<div>{{ctrl.getUser()}}</div>`,
  require: {
    ngModel: 'ngModel'
  },
  bindings: {
    extension: '=ngModel'
  },
  controllerAs: 'ctrl',
  controller: displayZohoUserExtensionController
}

_module.component('displayZohoUserExtension', displayZohoUserExtensionComponent)
