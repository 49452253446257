export default class confirmationWindowOKBase {
  constructor ($mdDialog) {
    'ngInject'
    this.$mdDialog = $mdDialog
  }

  show (title, message, isDelete = false) {
    this.title = title
    this.message = message
  }

  ok () {
    this.$mdDialog.hide('ok')
  }

  delete () {
    this.$mdDialog.hide('delete')
  }

  cancel () {
    this.$mdDialog.cancel()
  }
}
