import _module from 'module.js'
import ListControllerBase from 'classes/listController.class'
import _ from 'lodash'
import { LMI_ADMIN_PORTAL_TOKEN } from '../../services/jiveOAuthClient'

export default class ContactCenterConfigurationController extends ListControllerBase {
  constructor (
    $state,
    $stateParams,
    $location,
    $analytics,
    gettextCatalog,
    navigation,
    globalState,
    portalUtil,
    contactCenterConfigurationService
  ) {
    'ngInject'
    super(
      gettextCatalog,
      $analytics,
      $location,
      portalUtil,
      globalState,
      navigation,
      $state,
      $stateParams
    )

    this.oauthToken = JSON.parse(
      localStorage.getItem(LMI_ADMIN_PORTAL_TOKEN)
    ).token
    this.orgId = this.globalState.selectedPbxUUID
    this.locale = this.globalState.currentLanguage.langCode
    this.isContactCenterConfigurationEnabled = false;
    this.userContext = null;
    this.host = 'ADMIN';
    this.baseUrl = $state.href('root.nav.pbx.contactCenterConfiguration')
    this.whitelabel = this.globalState.isWhiteLabel;

    contactCenterConfigurationService.getUserContext(this.orgId).then(ret => {
      // ret has extra properties/functions added by the rest framework, pull only what is needed
      this.userContext = {
        license: ret.license,
        permissions: ret.permissions,
        userId: ret.userId,
        attributes: ret.attributes,
        externalUserKey: ret.externalUserKey,
        freeTrialEnrollmentDateTime: ret.freeTrialEnrollmentDateTime
      }
      this.isContactCenterConfigurationEnabled = true
    })
  }

}

_module.controller('ContactCenterConfigurationController', ContactCenterConfigurationController)
