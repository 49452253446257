import jrnParser from 'classes/jrnParser'
import _ from 'lodash'

export default class ListControllerBase {
  constructor (
    gettextCatalog,
    $analytics,
    $location,
    portalUtil,
    globalState,
    navigation,
    $state,
    $stateParams,
    $queryParams = {}
  ) {
    'ngInject'

    this.gettextCatalog = gettextCatalog
    this.analytics = $analytics
    this.location = $location
    this.portalUtil = portalUtil
    this.globalState = globalState
    this.navigation = navigation
    this.state = $state
    this.stateParams = $stateParams
    this.jrn = jrnParser


    this.data = {
      q: this.location.search().q || null,
      sort: this.location.search().sort || null,
      direction: this.location.search().direction || null,
      filter: this.location.search().filter || null,
      noMoreData: false
    }

    this.queryParams = $queryParams
    this.isBrowserSupported = this.globalState.isBrowserSupported
    this.selectedPbx = this.globalState.selectedPbx
    this.lastLoad = null
  }

  async search (query) {
    query.offset = 0
    query.noMoreData = false
    query.items = []

    if (this.sortBy) {
      query.sort = this.sortBy
      query.direction = this.direction
    }

    this.location.search({
      q: query.q || null,
      sort: query.sort,
      direction: query.direction,
      filter: query.filter || null
    })
    this.analytics.eventTrack('List Display: Search for ' + query.q, {
      category: 'List Display'
    })

    await this.requestMoreData(query)
  }

  clearSearch () {
    this.data.q = ''
    this.location.search({
      q: null,
      sort: this.data.sort,
      direction: this.data.direction,
      filter: this.data.filter
    })
    this.search(this.data)
  }

  createNew (data) {
    this.loader.entityApi.createNew(data)
  }

  async requestMoreData (query) {
    if (query.noMoreData) {
      return
    }
    // ADMIN-10426 / avoids double loads
    if (this.lastLoad && (new Date().getTime() - this.lastLoad.getTime()) < 500) {
      return
    }

    this.analytics.eventTrack('List Display: Request More Data', {
      category: 'List Display'
    })

    if (query.sort) {
      if (this.headers) {
        this.headers.forEach(header => {
          if (header.sortBy && header.sortBy === query.sort) {
            this.sortBy = header.sortBy
            this.direction = query.direction
            header.asc = query.direction === 'asc'
          } else if (header.hasOwnProperty('asc')) {
            header.asc = null
          }
        })
      }
    } else if (!this.hasOwnProperty('sortBy') && this.headers) {
      this.headers.forEach(header => {
        if (
          header.hasOwnProperty('sortBy') &&
          header.hasOwnProperty('asc') &&
          header.asc !== null
        ) {
          this.sortBy = header.sortBy
          this.direction = header.asc ? 'asc' : 'desc'
        }
      })
    }

    if (this.sortBy) {
      query.sort = this.sortBy
      query.direction = this.direction
    }

    Object.assign(query, this.queryParams)

    try {
      const results = await this.loader.getListResults(query)
      if (results) {
        if (this.data.q === null || this.data.q === results.q) {
          // only set the data to result if the query match or null (meaning there is no query)
          results.filter = this.data.filter
          this.data = results
        }
      }
      return results
    } catch (err) {
      this.portalUtil.showErrorAlert(
        this.gettextCatalog.getString('Query failed, please retry.')
      )
      throw err
    } finally {
      angular.element('#list-search').focus()
      this.lastLoad = new Date()
    }
  }

  async save (item) {
    if (item.isNew) {
      if (this.build) {
        this.build(item)
      }
      await this.loader.entityApi.saveNew(item, this.data)
      if (this.onNew) {
        return this.onNew(item)
      }
      return
    }
    await this.loader.entityApi.update(item)
    if (this.onUpdate) {
      return this.onUpdate(item)
    }
  }

  async sortByHeader (header) {
    if (!header.hasOwnProperty('sortBy')) {
      return
    }

    this.data.loading = true

    if (header.sortBy === this.sortBy) {
      header.asc = !header.asc
      this.direction = header.asc ? 'asc' : 'desc'
    } else {
      this.sortBy = header.sortBy

      // set all sort header to null, which means not sorted.
      this.headers.forEach(_header => {
        if (_header.hasOwnProperty('asc')) {
          _header.asc = null
        }
      })

      header.asc = true
      this.direction = header.asc ? 'asc' : 'desc'
    }

    this.location.search({
      q: this.data.q || null,
      sort: this.sortBy,
      direction: this.direction,
      filter: this.data.filter || null
    })

    await this.requestMoreData({
      q: this.data.q,
      offset: 0,
      sort: this.sortBy,
      direction: this.direction
    })

    this.data.loading = false
  }

  static idSelector (parsedJrn) {
    const [_, entityId] = parsedJrn.getResources()
    return entityId
  }

  static accountSelector (parsedJrn) {
    return parsedJrn.getAccount()
  }

  itemSelected (item, uri, idKey, selector = ListControllerBase.idSelector) {
    const id = selector(this.jrn.parse(item.jrn))
    this.navigation.listController = this
    this.navigation.jrn = item.jrn
    this.stateParams[idKey] = id
    this.state.go(uri, this.stateParams)
  }
}
