import musicRowEditorTemplate from './musicRowEditorTemplate.html'
import jrnParser from 'classes/jrnParser'
import _module from 'module.js'
import is from 'is'

export default class MusicRowEditorController {
  constructor (
    Music,
    gettextCatalog,
    audioPlayerService,
    $analytics,
    $element,
    deletionConfirmation,
    confirmationWindowOK,
    portalUtil,
    usagePopup,
    portalConfig,
    mediaTypeSelector
  ) {
    'ngInject'
    this.Music = Music
    this.gettextCatalog = gettextCatalog
    this.audioPlayerService = audioPlayerService
    this.$analytics = $analytics
    this.$element = $element
    this.deletionConfirmation = deletionConfirmation
    this.confirmationWindowOK = confirmationWindowOK
    this.portalUtil = portalUtil
    this.usagePopup = usagePopup
    this.portalConfig = portalConfig
    this.mediaTypeSelector = mediaTypeSelector
    this.fileUrl = null

    this.editing = false
    this.audioPlaying = false
    this.copy = null
    this.isMusicRowEditor = true
    if (!this.song) {
      this.song = {}
    }
    this.isCustomHoldMusicFile = this.audioPlayerService.isCustomTypeFromJrn(
      this.song.jrn
    )
    this.isBuiltInHoldMusicFile = !this.isCustomHoldMusicFile
    this.usageIcon = this.portalConfig.usageIcon
  }

  makeCopy () {
    this.copy = angular.copy(this.song)
  }

  reset () {
    this.song = this.copy
    this.copy = null
  }

  toggleEdit () {
    if (!this.copy) {
      this.makeCopy()
    }
    this.editing = !this.editing
    this.$element.attr('draggable', !this.editing)
  }

  togglePlaying () {
    this.audioPlaying = !this.audioPlaying
    this.$element.attr('draggable', !this.audioPlaying)
  }

  cancelEdit ($event) {
    this.toggleEdit()
    this.reset()
    $event.stopPropagation()
  }

  playAudioTrack (url) {
    this.fileUrl = url
    this.togglePlaying()
  }

  startAudioFile (_jrn) {
    this.audioPlayerService.getSongUrl(_jrn).then(url => {
      this.playAudioTrack(url)
    })
  }

  downloadFile (_jrn) {
    this.audioPlayerService.downloadAudioFile(_jrn, 'mp3')
  }

  save () {
    let id = jrnParser.parse(this.song.jrn).getResources()[1]
    let music = new this.Music(id)
    music.map(this.song)

    return music
      .update()
      .then(() => {
        this.toggleEdit()
      })
      .catch(error => {
        this.portalUtil.showErrorAlert(
          this.gettextCatalog.getString('Updating a song failed, please retry.')
        )
        throw error
      })
  }

  delete (event) {
    this.$analytics.eventTrack('Music List: Start Delete', {
      category: 'MusicList'
    })
    let item = this.song
    let displayName = this.gettextCatalog.getString('Music') + ': ' + item.name
    let id = jrnParser.parse(item.jrn).getResources()[1]
    let music = new this.Music(id)

    this.deletionConfirmation
      .confirm(event, displayName, item, music)
      .then(
        () => {
          this.confirmDeleteSong(item)
        },
        () => {
          // do nothing for cancel.
        }
      )
      .catch(error => {
        this.portalUtil.showErrorAlert(
          this.gettextCatalog.getString('Delete failed, please retry.')
        )
        throw error
      })
  }

  usage (event) {
    let item = this.song
    let displayName = this.gettextCatalog.getString('Music') + ': ' + item.name
    let id = jrnParser.parse(item.jrn).getResources()[1]
    let music = new this.Music(id)
    this.usagePopup
      .open(event, displayName, item, music)
      .then(() => {}, () => {})
      .catch(error => {
        this.portalUtil.showErrorAlert(
          this.gettextCatalog.getString('Query failed, please retry.')
        )
        throw error
      })
  }
}

_module.directive('musicRowEditor', function (
  Music,
  gettextCatalog,
  audioPlayerService,
  $analytics,
  deletionConfirmation,
  confirmationWindowOK,
  portalUtil,
  usagePopup,
  portalConfig
) {
  return {
    template: musicRowEditorTemplate,
    require: [
      'ngModel',
      'musicRowEditor',
      '^?playlistItems',
      '^?musicListItems'
    ],
    restrict: 'A',
    replace: true,
    scope: {
      song: '=',
      index: '=',
      deletable: '=',
      removable: '=',
      editable: '='
    },

    controllerAs: 'rowEditor',
    bindToController: true,
    controller: MusicRowEditorController,
    link: function (scope, el, attrs, ctrls) {
      var ngModel = ctrls[0]
      var ctrl = ctrls[1]
      var playlistItemsCtrl = ctrls[2]
      var musicListItemsCtrl = ctrls[3]

      var updateModelValue = function (action, value) {
        ngModel.$setViewValue({
          action: action,
          value: value
        })
      }

      ctrl.confirmDeleteSong = function (song) {
        var id = jrnParser.parse(song.jrn).getResources()[1]
        var music = new Music(id)
        return music
          .delete()
          .then(function () {
            // delete this song from display.
            // The server will take care of deleting this song from all playlists.
            if (playlistItemsCtrl) {
              playlistItemsCtrl.deleteSongFromList(song)
            }
            if (musicListItemsCtrl) {
              musicListItemsCtrl.deleteSongFromList(song)
            }
            updateModelValue('delete', song)
          })
          .catch(function (error) {
            portalUtil.showErrorAlert(
              gettextCatalog.getString('Deleting a song failed, please retry.')
            )
            throw error
          })
      }

      ctrl.removeSongFromList = function (song) {
        if (playlistItemsCtrl) {
          playlistItemsCtrl.removeSongFromList(song)
        }
        updateModelValue('remove', song)
      }

      ctrl.onSongsRearranged = song => {
        if (playlistItemsCtrl) {
          playlistItemsCtrl.onSongsRearranged(ctrl.index, song)
        }
      }
    }
  }
})
