import _module from 'module.js'
import _ from 'lodash'
import BaseController from 'classes/baseController'
import jrnParser from 'classes/jrnParser'
import { checkRoles } from 'controllers/user/userService'
import {
  fetchUsers,
  fetchUsersByKeys,
  fetchUserGroups,
  fetchUserGroupsByKeys,
  fetchUser,
  updateUserRole,
  updatePhoneSettings,
  fetchDefaultPhoneSettings,
} from '@ucc/phoenix'

const ACCOUNT_DIALING_SETTINGS = [
  'dialingDomesticLongDistanceEnabled',
  'dialingDomesticMobileEnabled',
  'dialingInternalEnabled',
  'dialingInternationalEnabled',
  'dialingLocalEnabled',
  'dialingMaliciousAreaCodeEnabled',
  'dialingTollNumberEnabled',
]

const ACCOUNT_PHONE_SETTINGS = [
  'aclMonitoringEnabled',
  'monitoringAclUserKeys',
  'monitoringAclGroupKeys',
  'monitoringEnabled',
  'promptRecordingEnabled',
  ...ACCOUNT_DIALING_SETTINGS,
]

const toMonitoredUserOptionId = (k) => `USER/${k}`
const toMonitoredGroupOptionId = (k) => `GROUP/${k}`
const toMonitoredMemberOptions = (usersResult, groupsResult) => {
  if (!usersResult || !groupsResult) {
    return []
  }
  const userOptions = usersResult.results.map(u => ({ id: toMonitoredUserOptionId(u.key), text: [u.firstName, u.lastName].filter((s) => s).join(' '), key: u.key, type: 'USER' }))
  const groupOptions = groupsResult.results.map(g => ({ id: toMonitoredGroupOptionId(g.key), text: g.name, key: g.key, type: 'GROUP' }))

  return userOptions.concat(groupOptions)
}
const getMonitoredMemberKeysProp = (type) => type === 'USER' ? 'monitoringAclUserKeys' : 'monitoringAclGroupKeys'

export default class UserController extends BaseController {
  constructor (
    portalApi,
    $stateParams,
    User,
    userService,
    hotDeskingService,
    $rootScope,
    confirmationWindowWarn,
    confirmationWindowOK,
    gettextCatalog,
    $state,
    portalUtil,
    portalConfig,
    pbxUtil,
    errorService,
    globalState,
    navigation,
    $log,
    $q,
    $timeout,
    FEATURE_FLAGS
  ) {
    'ngInject'
    super(
      $state,
      $stateParams,
      User,
      portalApi,
      userService,
      gettextCatalog,
      portalUtil,
      errorService,
      globalState,
      navigation,
      'root.nav.pbx.users.list',
      'userId'
    )

    this.oauthToken = globalState.oauthToken
    this.locale = globalState.currentLanguage.langCode
    this.lmiAccountKey = globalState._selectedPbx.lmiAccountKey
    this.jrn = jrnParser
    this.hotDeskingService = hotDeskingService
    this.userService = userService
    this.$rootScope = $rootScope
    this.confirmationWindowWarn = confirmationWindowWarn
    this.confirmationWindowOK = confirmationWindowOK
    this.$log = $log
    this.$q = $q
    this.pbxUtil = pbxUtil
    this.$timeout = $timeout
    this.isWhiteLabel = globalState.isWhiteLabel
    this.hasManageSeatsPerm = globalState.isUserManageSeatsOnly
    this.hasManageUsersPerm = globalState.isUserManageUsersOnly
    this.hasManageGroupsPerm = globalState.isUserManageGroupsOnly
    this.canEditRole = globalState.isUserSuperAdmin
    this.canEditPhoneSettings = !this.hasManageUsersPerm && !this.hasManageGroupsPerm && !this.hasManageSeatsPerm
    this.canEdit = this.canEditPhoneSettings || this.hasManageUsersPerm
    this.showAllowWebLogin = !globalState.selectedPbx.featureFlags[FEATURE_FLAGS.hideAllowWebLogin]
    this.isUnifiedAdminOrTryNewGoToAdminEnabled = this.globalState.isUnifiedAdminEnabled
    || this.globalState.selectedPbx.isTryNewGoToAdminEnabled

    if ($state.current.name === 'root.nav.pbx.users.view.hidden' && !globalState.isPlatform) {
      $state.go('root.nav.pbx.users.view.general', $stateParams)
    }

    this.baseHotDeskingUserConfig = {}

    this.warningWhenChangeJiveIDCount = 0
    this.warningWhenChangeEmailCount = 0

    this.isBusyWithResetPin = false
    this.isBusyWithSetPin = false
    this.isBusyWithVerifyPin = false

    this.showResetPinMessage = false
    this.showSetPinMessage = false
    this.showVerifyPinMessage = false

    this.pinVerified = false
    this.formPristine = undefined

    this.GoToIcons = portalConfig.GoTo

    this.jiveIdOrEmailUpdated = false

    this.canShowEmailField = true

    const pbxRegion = this.globalState._selectedPbx.region
    this.hideUserLinks = this.hasManageUsersPerm || this.hasManageGroupsPerm || this.hasManageSeatsPerm || (pbxRegion !== 'US' && pbxRegion !== 'CA' && pbxRegion !== 'MX' && pbxRegion !== 'BR')

    this.canEditEmail = !this.globalState._selectedPbx.billedByLicense

    this.data = new User()
    this.hotDeskingUserConfig = {}

    this.initialUserRole = null
    this.userRole = null
    this.notifyUserRoleChange = true
    this.initialAccountPhoneSettings = null
    this.accountPhoneSettings = null
    this.accountDefaultPhoneSettings = null
    this.monitoredMembers = null

    this.$q
      .all([
        this.data.get(),
        this.userService.hasPlatformPermissions(),
        !this.hasManageUsersPerm && !this.hasManageGroupsPerm && !this.hasManageSeatsPerm ? this.hotDeskingService.getUserConfig(this.$stateParams.userId) : Promise.resolve({})
      ])
      .then(userData => {
        let hasPermissions = userData[1]
        let hotDeskingUserConfig = userData[2]
        this.canShowEmailField = !this.data.systemGeneratedEmail
        this.ready = true
        this.initial = false
        this.itemName = this.data.getFullName()
        this.softphoneAlreadyEnabled =
          this.data.mobilityEnabledOnExternalPbx || this.data.softphoneEnabled

        this.showHidden = hasPermissions || this.data.softphoneEnabled
        this.baseHotDeskingUserConfig = _.clone(hotDeskingUserConfig)
        this.hotDeskingUserConfig = hotDeskingUserConfig
        this.hasExternalUserKey = this.data.externalUserKey

        this.goToAdminUserUrl =
          this.pbxUtil.getGoToAdminUrl(this.selectedPbx) +
          '/people/users/details/' +
          this.data.externalUserKey

        checkRoles(this.data)
        $timeout(() => {
          $rootScope.$apply()
        })

        if (this.canEditRole || this.canEditPhoneSettings) {
          return this.fetchAccountUser()
        }
      })
      .catch(error => {
        let errorMessage = this.errorService.getErrorMessage(error)
        this.portalUtil.showErrorAlert(
          _.isEmpty(errorMessage)
            ? this.gettextCatalog.getString(
              'Data retrieval failed, please retry.'
            )
            : errorMessage
        )
        this.ready = true
        this.initial = false
        $timeout(() => {
          $rootScope.$apply()
        })
        throw error
      })

    if (this.data.getReferencesApi) {
      this.data
        .getReferencesApi()
        .get()
        .then(references => {
          if (references && references.virtualFaxMachines) {
            references.virtualFaxMachines.forEach(fax => {
              fax.id = this.jrn.parse(fax.jrn).getResources()[1]
            })
            this.data.references = references
          }
        })
    }

    this.portalUtil.setStateI18N(this, this.$state.$current.parent.data)

    const memberQuery = () => ({
      get: (params = null) => {
        return Promise.all([
          fetchUsers({ oauthToken : this.oauthToken, lmiAccountKey: this.lmiAccountKey, query: params.q || '', attributes: ['key', 'firstName', 'lastName'] }),
          fetchUserGroups({ oauthToken : this.oauthToken, lmiAccountKey: this.lmiAccountKey, query: params.q || '', attributes: ['key', 'name'] }),
        ]).then(data => ({
          items: _.orderBy(
            toMonitoredMemberOptions(data[0], data[1]),
            [(item) => item.text.toLowerCase()],
            ['asc'])
        }))
      }
    })
    this.getMembers = {
      api: memberQuery,
      filter: (member) => !this.monitoredMembers.some(m => m.id === member.id),
      map: member => member
    }
    this.handleRoleChange = this.handleRoleChange.bind(this)
  }

  hideSave () {
    return !this.canEdit
  }

  ok () {
    if (this.data.assignedDevices.length === 1) {
      this.data.assignedDevices[0].softphoneEnabled = true
      this.data.designatedSoftphoneDeviceMasterConfiguration = this.data.assignedDevices[0].jrn
    }
  }

  cancel () {
    this.data.softphoneEnabled = false
  }

  resetConfirm () {
    this.data
      .resetMobility()
      .then(() => {
        this.data.get()
      })
      .catch(() => {
        this.portalUtil.showErrorAlert(
          this.gettextCatalog.getString('Reset failed')
        )
      })
  }

  resetMobilityCount () {
    this.apps.resetMobilityCount(resetConfirm)
  }

  goToDevice (deviceJrn) {
    this.apps.goToDevice(deviceJrn)
  }

  getDeviceIdFromJrn (deviceJrn) {
    return this.jrn.parse(deviceJrn).getResources()[1]
  }

  // TODO make sure this function is viable in the scope context
  takeMeToUserMobility () {
    this.portalApi.pbx
      .pbxs(this.jrn.parse(this.data.mobilityEnabledOnPbx).getAccount())
      .get({})
      .then(pbx => {
        this.$rootScope.$broadcast('pbxUpdated', pbx)
        this.$state.go('root.nav.pbx.users.view.general', {
          id: this.jrn.parse(this.data.mobilityEnabledOnPbx).getAccount(),
          userId: this.data.mobilityEnabledOnUser
        })
      })
  }

  onToggleSetNewEmailAddress() {
    if (this.canShowEmailField) {
      this.data._emailAddress = ''
      this.data._externalUserId = ''
    } else {
      this.data._emailAddress = this.data.emailAddress
      this.data._externalUserId = this.data.externalUserId
      this.data.enabled = false
    }
  }

  onAllowWebLoginToggled() {
    let newToggleValue = this.data.enabled
    if (this.data.systemGeneratedEmail) {
      this.canShowEmailField = newToggleValue
      this.onToggleSetNewEmailAddress()
    }
  }

  correctEmail (email) {
    this.data._emailAddress = email
    if (this.data.emailAddress === this.data.externalUserId) {
      this.data._externalUserId = email
    }
  }

  correctUsername (email) {
    this.data._externalUserId = email
  }

  ignoreShowPassword (form) {
    form.$pristine =
      form.usersForm.dialableUsername.$pristine &&
      form.usersForm.email.$pristine &&
      form.usersForm.firstName.$pristine &&
      form.usersForm.lastName.$pristine &&
      form.usersForm.jiveId.$pristine &&
      form.usersForm.loginEnabled.$pristine &&
      form.usersForm.userPassword.$pristine
    form.$dirty = !form.$pristine
  }

  warningWhenChangeJiveID () {

    this.jiveIdOrEmailUpdated = true

    let warningMessage = this.gettextCatalog.getString(
      'Changing your own id will no longer allow you to login to this PBX. Are you sure you want to do this?'
    )

    if (this.warningWhenChangeJiveIDCount === 0) {
      let matchingUser = _.find(this.globalState.userData.users, user => {
        return this.data.jrn === user.jrn
      })

      if (matchingUser) {
        this.confirmationWindowWarn
          .show(this.gettextCatalog.getString('Warning'), warningMessage)
          .then(
            () => {
              // do nothing
            },
            () => {
              // revert back to the original value
              this.data._externalUserId = this.data.externalUserId
            }
          )
        this.warningWhenChangeJiveIDCount++
      }
    }
  }

  warningWhenChangeEmail () {

    this.jiveIdOrEmailUpdated = true

    let warningMessage = this.gettextCatalog.getString(
      "Changing this user's email will reset this user's support PIN. Are you sure you want to do this?"
    )

    if (
      this.data.emailAddress &&
      this.data.hasSupportPin &&
      this.warningWhenChangeEmailCount === 0
    ) {
      this.confirmationWindowWarn
        .show(this.gettextCatalog.getString('Warning'), warningMessage)
        .then(
          () => {
            // do nothing
          },
          () => {
            // revert back to the original value
            this.data._emailAddress = this.data.emailAddress
          }
        )
      this.warningWhenChangeEmailCount++
    }

    // If both values are the same, we set to externalUserId the value of emailAddress
    if (this.data.externalUserId === this.data.emailAddress) {
      this.data._externalUserId = this.data._emailAddress
    }
  }

  matchJiveIDWithEmail () {
    this.data._externalUserId = this.data._emailAddress
    this.data.externalUserId = this.data._emailAddress
  }

  goToEndUserPortal () {
    window.open(
      `https://${window.location.host}/me/${this.$stateParams.id}/extensions-directory?viewAs=${this.data.externalUserKey}`
    )
  }

  async updateRole () {
    if (this.canEditRole && this.userRole !== this.initialUserRole) {
      const result = await updateUserRole({
        lmiAccountKey: this.lmiAccountKey,
        oauthToken: this.oauthToken,
        userKey: this.accountUser.key,
        role: this.userRole,
        sendNotification: this.notifyUserRoleChange
      })

      if (result) {
        this.initialUserRole = this.userRole
      } else {
        throw new Error('Failed to update user role')
      }
    }
  }

  async updateAccountPhoneSettings () {
    if (this.canEditPhoneSettings) {
      const updatedPhoneSettings = _.omitBy(_.pick(this.accountPhoneSettings, ACCOUNT_PHONE_SETTINGS),
          (value, key) => _.isEqual(value, this.initialAccountPhoneSettings[key]))

      if (!_.isEmpty(updatedPhoneSettings)) {
        if (updatedPhoneSettings.monitoringAclUserKeys || updatedPhoneSettings.monitoringAclGroupKeys) {
          updatedPhoneSettings.monitoringEnabled =
            !_.isEmpty(updatedPhoneSettings.monitoringAclUserKeys || this.initialAccountPhoneSettings.monitoringAclUserKeys) ||
            !_.isEmpty(updatedPhoneSettings.monitoringAclGroupKeys || this.initialAccountPhoneSettings.monitoringAclGroupKeys)
        }
        const res = await updatePhoneSettings({
          oauthToken: this.oauthToken,
          lmiAccountKey: this.lmiAccountKey,
          userKeys: [this.accountUser.key],
          settings: updatedPhoneSettings,
        })

        if (res) {
          this.initialAccountPhoneSettings = _.cloneDeep(this.accountPhoneSettings)
        } else {
          throw new Error('Error updating account phone settings')
        }
      }
    }
  }

  save (form) {

    const hotDeskingUpdatePromise = this.hotDeskingService.updateUserConfig(
      this.baseHotDeskingUserConfig,
      this.hotDeskingUserConfig
    )

    const updatePromise = this.data.update().catch(updateError => {
      // Revert the hot desking update if the user update failed
      return hotDeskingUpdatePromise
        .then(() => {
          return this.hotDeskingService
            .updateUserConfig(
              this.hotDeskingUserConfig,
              this.baseHotDeskingUserConfig
            )
            .catch(revertError => {
              this.$log.error(
                'Failure reverting changes to hot desk user config for user ' +
                  this.id,
                revertError
              )
            })
        })
        .finally(() => {
          throw updateError
        })
    })

    const roleChangePromise = this.updateRole()
    const accountPhoneSettingsUpdatePromise = this.updateAccountPhoneSettings()

    this.ready = false
    return this.$q
      .all([hotDeskingUpdatePromise, updatePromise, roleChangePromise, accountPhoneSettingsUpdatePromise])
      .then(results => {
        const updatedHotDeskUserConfig = results[0]
        this.baseHotDeskingUserConfig = _.clone(updatedHotDeskUserConfig)
        this.hotDeskingUserConfig = updatedHotDeskUserConfig

        this.itemName = this.data.getFullName()
        form.$setPristine()
        form.$setUntouched()
        this.canShowEmailField = !this.data.systemGeneratedEmail
        this.ready = true
      })
      .catch(error => {
        if (error.status === 404) {
          this.portalUtil.showErrorAlert(
            this.gettextCatalog.getString('User') +
              ' ' +
              this.gettextCatalog.getString('Not found')
          )
        } else {
          this.portalUtil.showErrorAlert(
            this.gettextCatalog.getString('Save failed, please retry.')
          )
        }
        this.ready = true
        throw error
      })
  }

  normalizeEmptyEmailAddress() {
    if (!this.data._externalUserId) {
      this.data._externalUserId = null
    }
    if (!this.data._emailAddress) {
      this.data._emailAddress = null
    }
  }

  keydown (form) {
    if (this.formPristine === undefined) {
      this.formPristine = form.$$parentForm.$pristine
    }
  }

  pinVerifyChanged (userHiddenForm) {
    this.pinVerified = false
    this.showVerifyPinMessage = false
    if (this.formPristine) {
      userHiddenForm.$$parentForm.$dirty = !this.formPristine
    }
  }

  pinSetChanged (userHiddenForm) {
    this.showSetPinMessage = false
    if (this.formPristine) {
      userHiddenForm.$$parentForm.$dirty = !this.formPristine
    }
  }

  verifyPin (pin) {
    this.supportPinToSet = null
    this.isBusyWithVerifyPin = true
    this.data
      .verifyPin(pin)
      .then(data => {
        this.isBusyWithVerifyPin = false
        this.showVerifyPinMessage = true
        this.pinVerified = data.isPinMatched
      })
      .catch(error => {
        let errorMessage = this.errorService.getErrorMessage(error)
        this.portalUtil.showErrorAlert(
          _.isEmpty(errorMessage)
            ? this.gettextCatalog.getString('Server error.')
            : errorMessage
        )
        this.isBusyWithVerifyPin = false
      })
  }

  setPin (pin) {
    this.isBusyWithSetPin = true
    this.data
      .setPin(pin)
      .then(data => {
        this.isBusyWithSetPin = false
        this.showSetPinMessage = true
      })
      .catch(error => {
        let errorMessage = this.errorService.getErrorMessage(error)
        this.portalUtil.showErrorAlert(
          _.isEmpty(errorMessage)
            ? this.gettextCatalog.getString('Server error.')
            : errorMessage
        )
        this.isBusyWithSetPin = false
      })
  }

  resetPin () {
    this.showResetPinMessage = false
    this.isBusyWithResetPin = true
    this.data
      .resetPin()
      .then(result => {
        this.data.hasSupportPin = true
        this.isBusyWithResetPin = false
        this.showResetPinMessage = true
        // this.$timeout(() => {
        //   this.showResetPinMessage = false
        // }, 3000)
      })
      .catch(error => {
        let errorMessage = this.errorService.getErrorMessage(error)
        this.portalUtil.showErrorAlert(
          _.isEmpty(errorMessage)
            ? this.gettextCatalog.getString('Server error.')
            : errorMessage
        )
        this.isBusyWithResetPin = false
      })
  }

  addMonitoredMember (member) {
    this.monitoredMembers.push(member)
    const monitoredMemberKeys =  this.accountPhoneSettings[getMonitoredMemberKeysProp(member.type)]
    monitoredMemberKeys.push(member.key)
    monitoredMemberKeys.sort()
  }

  handleMonitoredMemberRemoved (member) {
    const monitoredMemberKeysProp = getMonitoredMemberKeysProp(member.type)
    this.accountPhoneSettings[monitoredMemberKeysProp] = this.accountPhoneSettings[monitoredMemberKeysProp].filter(k => k !== member.key)
  }

  handleRoleChange ({ role, sendNotification }) {
    this.userRole = role
    this.notifyUserRoleChange = sendNotification || false
  }

  getAccountUserRole () {
    let userRole = 'MEMBER'
    const roles = _.concat(this.accountUser.adminRoles || [], this.accountUser.roleSets || [])
    if (roles.length) {
      if (roles.includes('SUPER_USER')) {
        userRole = 'SUPER_USER'
      } else if (roles.includes('MANAGE_ACCOUNT')) {
        userRole = 'MANAGE_ACCOUNT'
      } else {
        userRole = roles[0]
      }
    }

    return userRole
  }

  async fetchAccountUser () {
    let accountUserAttrs = ['key']
    if (this.canEditRole) {
      accountUserAttrs = accountUserAttrs.concat(['roleSets', 'adminRoles'])
    }
    if (this.canEditPhoneSettings) {
      accountUserAttrs = accountUserAttrs.concat(['settings.JIVE'])
    }

    this.accountUser = await fetchUser({
      lmiAccountKey: this.lmiAccountKey,
      oauthToken: this.oauthToken,
      userKey: this.data.externalUserKey,
      attributes: accountUserAttrs
    })

    if (!this.accountUser) {
      throw new Error('Error fetching account user')
    }

    if (this.canEditRole) {
      this.userRole = this.initialUserRole = this.getAccountUserRole()
    }

    if (this.canEditPhoneSettings) {
      this.initialAccountPhoneSettings = this.accountUser.settings.JIVE || {}
      await this.fetchAccountDefaultPhoneSettings()
      await this.fetchMonitoredMembers()
      this.accountPhoneSettings = _.cloneDeep(this.initialAccountPhoneSettings)
    }
  }

  async fetchAccountDefaultPhoneSettings () {
    this.accountDefaultPhoneSettings = await fetchDefaultPhoneSettings({ oauthToken: this.oauthToken, lmiAccountKey: this.lmiAccountKey })
    if (!this.accountDefaultPhoneSettings) {
      throw new Error('Error fetching account default phone settings')
    }
    this.initialAccountPhoneSettings = _.mergeWith(
      {},
      this.initialAccountPhoneSettings,
      _.pick(this.accountDefaultPhoneSettings, ACCOUNT_DIALING_SETTINGS),
      (v1, v2) => v1 || v2)
  }

  async fetchMonitoredMembers () {
    if (!this.accountDefaultPhoneSettings.aclMonitoringEnabled) {
      return
    }

    const monitoringAclUserKeys = this.initialAccountPhoneSettings.monitoringAclUserKeys = this.initialAccountPhoneSettings.monitoringAclUserKeys || []
    const monitoringAclGroupKeys = this.initialAccountPhoneSettings.monitoringAclGroupKeys = this.initialAccountPhoneSettings.monitoringAclGroupKeys || []

    if (!monitoringAclUserKeys.length && !monitoringAclGroupKeys.length) {
      this.monitoredMembers = []
      return
    }

    const data = await Promise.all([
      monitoringAclUserKeys.length ? fetchUsersByKeys({ oauthToken: this.oauthToken, lmiAccountKey: this.lmiAccountKey, keys: monitoringAclUserKeys, attributes: ['key', 'firstName', 'lastName'] }) : { results: [] },
      monitoringAclGroupKeys.length ? fetchUserGroupsByKeys({ oauthToken: this.oauthToken, lmiAccountKey: this.lmiAccountKey, keys: monitoringAclGroupKeys, attributes: ['key', 'name'] }) : { results: [] }
    ])

    const identityOptionMap = _.keyBy(toMonitoredMemberOptions(data[0], data[1]), 'id')

    if (!monitoringAclUserKeys.every(k => identityOptionMap[toMonitoredUserOptionId(k)]) ||
        !monitoringAclGroupKeys.every(k => identityOptionMap[toMonitoredGroupOptionId(k)])) {
      return
    }

    this.monitoredMembers = monitoringAclUserKeys.map(k => identityOptionMap[toMonitoredUserOptionId(k)])
      .concat(monitoringAclGroupKeys.map(k => identityOptionMap[toMonitoredGroupOptionId(k)]))
    monitoringAclUserKeys.sort()
    monitoringAclGroupKeys.sort()
  }
}

_module.controller('UserController', UserController)
