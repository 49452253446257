import _module from "module.js";
import alertTemplate from "./alertTemplate.html";

_module.component("alert", {
  template: alertTemplate,
  controllerAs: "ctrl",
  transclude: true,
  bindings: {
    title: '@'
  },
});
