/**
 * @ngdoc function
 * @name platformApp.factory:User
 * @description
 * # User
 * Service of the platformApp
 */
import module from 'module'
import BaseES6 from 'factories/baseES6'
import jrnParser from 'classes/jrnParser'
import _ from 'lodash'

const createFullName = (last, first) => {
  let name = last
  if (first) {
    name = first + ' ' + last
  }
  return name
}

let _stateParams, _portalApi, _jrn, _state, _globalState

export default class User extends BaseES6 {
  constructor (id, user, pbxId, gettextCatalog) {
    'ngInject'
    super(id || _stateParams.userId, pbxId || _globalState.selectedPbxUUID)
    this.gettextCatalog = gettextCatalog

    if (this._id === undefined) {
      throw new Error(this.gettextCatalog.getString('Missing id for new User'))
    }

    this._memberOfUserGroups = []

    if (user) {
      this._map(user)
    }
    this.cacheEnabled = true
  }

  getFullName () {
    let name = this.lastName
    if (this.firstName) {
      name = this.firstName + ' ' + name
    }
    return name
  }

  getApi () {
    if (!this._api) this._api = _portalApi.identity.pbxs(this._pbxId).users

    return this._api
  }

  getPath () {
    if (!this._path) {
      this._path = _portalApi.identity.pbxs(this._pbxId).users(this._id)
    }

    return this._path
  }

  getReferencesPath () {
    if (!this._id) {
      return null
    }
    if (!this._referencesPath) {
      this._referencesPath = _portalApi.identity
        .pbxs(this._pbxId)
        .users(this._id)
        .one('references')
    }
    return this._referencesPath
  }

  map (data) {
    if (data.memberOfUserGroups) {
      this._memberOfUserGroups = data.memberOfUserGroups.map(function (group) {
        return {
          id: group.jrn,
          text: group.name,
          path: _state.href('root.nav.pbx.groups.view.general', {
            groupId: _jrn.parse(group.jrn).getResources()[1]
          })
        }
      })
    }
    if (data._memberOfUserGroups) {
      this._memberOfUserGroups = data._memberOfUserGroups
    }

    this._externalUserId = data.externalUserId
    this._emailAddress = data.emailAddress
    if (this._emailAddress) {
      this.emailSet = true
    }

    let device = _.find(this.assignedDevices, function (_device) {
      return _device.softphoneEnabled
    })
    if (device) {
      this.designatedSoftphoneDeviceMasterConfiguration = device.jrn
    }

    for (let i in data.assignedDevices) {
      data.assignedDevices[i].id = jrnParser.parse(data.assignedDevices[i].jrn).getResources()[1]
    }

    this.isNew = data.isNew || false
  }

  build (payload) {
    payload.memberOfUserGroups = this._memberOfUserGroups.map(function (group) {
      return group.id
    })

    if (!this._emailAddress && payload.systemGeneratedEmail) {
      payload.emailAddress = payload.externalUserId
    } else {
      payload.externalUserId = this._emailAddress
      payload.emailAddress = this._emailAddress
    }

    payload.designatedSoftphoneDeviceMasterConfiguration =
      this.designatedSoftphoneDeviceMasterConfiguration || null

    return payload
  }

  mapListItem (user) {
    return {
      id: user.jrn,
      text: createFullName(user.lastName, user.firstName)
    }
  }

  cleanListItem (item) {
    item._emailAddress = item.emailAddress
    item._externalUserId = item.externalUserId
    return item
  }

  mapBatchData (user) {
    user._emailAddress = user.emailAddress
    return user
  }

  getByDialableUsername (dialableUsername) {
    return this.getApi()
      .call(this)
      .one('dialable-username/' + dialableUsername)
      .get()
  }

  resetMobility () {
    return this.getApi()
      .call(this)
      .one(this._id + '/mobility/reset')
      .post()
  }

  getByJiveId (jiveId) {
    return this.getApi()
      .call(this)
      .one('jive-id/' + jiveId)
      .get()
  }

  resetPin () {
    return this.getApi()
      .call(this)
      .one(this._id + '/pin/reset')
      .post()
  }

  setPin (pin) {
    return this.getApi()
      .call(this)
      .post(this._id + '/pin/set', { newPin: pin })
  }

  verifyPin (pinToVerify) {
    return this.getApi()
      .call(this)
      .post(this._id + '/pin/verify', { pin: pinToVerify })
  }

  getClass () {
    return 'User'
  }

  delete () {
    return super.delete({deleteUacs: true})
  }

  static factory ($stateParams, portalApi, gettextCatalog, $state, globalState) {
    'ngInject'
    _stateParams = $stateParams
    _portalApi = portalApi
    _jrn = jrnParser
    _state = $state
    _globalState = globalState

    return (id, user, pbxId = null) => {
      return new User(id, user, pbxId, gettextCatalog)
    }
  }
}

export const checkRoles = user => {
  user.roles = user.roles || []
  if (!user.roles.includes('ROLE_GOTOCONNECT_ORGANIZER')) {
    return
  }
  const idx = user.roles.indexOf('ROLE_G2M_ORGANIZER')
  if (idx > -1) {
    user.roles.splice(idx, 1)
  }
}

module.factory('User', User.factory)
