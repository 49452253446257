let _OutboundProxies

export default class GetOutboundProxyController {
  constructor (gettextCatalog, OutboundProxies) {
    'ngInject'
    this._model = {}
    _OutboundProxies = OutboundProxies

    this.placeholder = gettextCatalog.getString('Select an outbound proxy...')

    this.$onInit = () => {
      this.setViewValue = item => {
        this.ngModel.$setViewValue(item.id)
      }
    }
  }

  api (id = null) {
    return new _OutboundProxies(id)
  }
}
