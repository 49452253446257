import _module from 'module.js'
import saveTemplate from '../../directives/saveButton/saveTemplate.html'

_module.directive('saveButton', function (
  $q,
  gettextCatalog,
  $document,
  $timeout
) {
  return {
    restrict: 'A',
    template: saveTemplate,
    require: ['saveButton', '^form'],
    controllerAs: 'saveCtrl',
    bindToController: true,
    scope: {
      save: '&',
      display: '@',
      saveDisabled: '@'
    },
    controller: function () {
      'ngInject'
      if (this.display) {
        this.text = this.display
      } else {
        this.text = gettextCatalog.getString('Save')
      }
      this.saving = false
    },
    link: function (scope, element, attrs, ctrls) {
      let ctrl = ctrls[0]
      let form = ctrls[1]
      let buttonElem = element.find('button')

      ctrl.saveHandler = () => {
        if (!ctrl.saving) {
          ctrl.saving = true
          ctrl.text = gettextCatalog.getString('Saving...')
          buttonElem.attr(
            'class',
            'md-raised md-primary md-button md-ink-ripple'
          )

          $q
            .when(ctrl.save())
            .then(() => {
              ctrl.text = gettextCatalog.getString('Saved')
              buttonElem.attr(
                'class',
                'md-raised success md-button md-ink-ripple'
              )
              ctrl.saving = false

              $timeout(() => {
                if (ctrl.display) {
                  ctrl.text = ctrl.display
                } else {
                  ctrl.text = gettextCatalog.getString('Save')
                }
                buttonElem.attr(
                  'class',
                  'md-raised md-primary md-button md-ink-ripple'
                )
              }, 1500)
            })
            .catch(error => {
              ctrl.text = gettextCatalog.getString('Save failed')
              buttonElem.attr(
                'class',
                'md-raised md-warn md-button md-ink-ripple'
              )
              ctrl.saving = false

              $timeout(() => {
                if (ctrl.display) {
                  ctrl.text = ctrl.display
                } else {
                  ctrl.text = gettextCatalog.getString('Save')
                }
                buttonElem.attr(
                  'class',
                  'md-raised md-primary md-button md-ink-ripple'
                )
              }, 3000)
            })
        }
      }
    }
  }
})
