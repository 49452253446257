import is from 'is'

const MAX_FILE_SIZE = 150000000

class fileUploadController {
  constructor ($scope, $attrs, $parse, gettextCatalog, $timeout, portalUtil) {
    'ngInject'
    this.$scope = $scope
    this.$attrs = $attrs
    this.$parse = $parse
    this.gettextCatalog = gettextCatalog
    this.$timeout = $timeout
    this.portalUtil = portalUtil

    this.label = this.$attrs.label || this.gettextCatalog.getString('Upload')
  }

  createInput (type, accept) {
    return angular.element(
      '<input type="' + type + '" accept="' + accept + '">'
    )
  }

  handleUpload (cb, scope, input) {
    let file = input[0].files[0]
    if (file) {
      if (file.size >= MAX_FILE_SIZE) {
        this.portalUtil.showErrorAlert(
          this.gettextCatalog.getString(
            'File exceeds maximum allowed file size! (150MB)'
          )
        )
        file = null
      } else if (this.$parse && this.$attrs && this.$scope) {
        let outterScope = this.$parse(this.$attrs.scope)(this.$scope) || this
        this.$parse(this.$attrs.onUpload)(this.$scope).call(outterScope, file)
      } else if (cb) {
        cb.call(scope, file)
      }
    }
  }

  openFileUploader (cb, scope) {
    let input = this.createInput('file', this.$attrs.accept)
    input.trigger('click')

    if (is.ie() || is.edge()) {
      // http://stackoverflow.com/questions/2389341/jquery-change-event-to-input-file-on-ie
      // IE suspends timeouts until after the file dialog closes
      this.$timeout(() => {
        this.handleUpload(cb, scope, input)
      }, 0)
    } else {
      input.change(() => {
        this.handleUpload(cb, scope, input)
        input.off('change')
      })
    }
  }
}

import _module from 'module.js'
_module.directive('fileUpload', function () {
  return {
    restrict: 'A',
    require: 'fileUpload',
    controllerAs: 'fileUpload',
    bindToController: true,
    controller: fileUploadController,

    link: function (scope, el, attrs, fileUpload) {
      el.on('click', function () {
        fileUpload.openFileUploader()
      })

      scope.$on('$destroy', function () {
        el.off('click')
      })
    }
  }
})

export default fileUploadController
