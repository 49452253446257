import _module from 'module.js'
import ListControllerBase from 'classes/listController.class'

export default class LinesController extends ListControllerBase {
  constructor (
    $state,
    $stateParams,
    $location,
    $analytics,
    Line,
    gettextCatalog,
    infiniteLoader,
    navigation,
    globalState,
    portalUtil
  ) {
    'ngInject'
    super(
      gettextCatalog,
      $analytics,
      $location,
      portalUtil,
      globalState,
      navigation,
      $state,
      $stateParams
    )

    this.gettextCatalog = gettextCatalog
    this.title = this.gettextCatalog.getString('Lines')
    this.headers = [
      {
        width: 10,
        name: this.gettextCatalog.getString('Extension'),
        sortBy: 'extensionNumber',
        asc: true
      },
      {
        width: 50,
        name: this.gettextCatalog.getString('Name'),
        sortBy: 'name',
        asc: null
      },
      {
        width: 30,
        name: this.gettextCatalog.getString('Omit from Directory')
      },
      {
        width: 10,
        name: ''
      }
    ]

    this.toggleVisibleItemsFromPermissions()

    this.loader = infiniteLoader(Line)
  }

  toggleVisibleItemsFromPermissions() {
    // Users with manage settings cannot add or delete
    this.hideDelete = false
    this.buttonText = this.gettextCatalog.getString('Add Line')
    if (this.globalState.isUserManageSettingsOnly) {
      this.hideDelete = true
      this.buttonText = undefined
    }
  }

  delete (event, line) {
    let name =
      this.gettextCatalog.getString('line') +
      ': ' +
      (line.extensionNumber ? line.extensionNumber + ': ' : '') +
      line.name
    this.loader.entityApi.startDelete(event, line, this.data, name)
  }

  itemSelected (item) {
    super.itemSelected(
      item,
      'root.nav.pbx.extensions.lines.view.general',
      'extId'
    )
  }
}

_module.controller('LinesController', LinesController)
