import _module from 'module.js'
import _ from 'lodash'

_module.directive('playListRow', function (
  HoldMusicPlaylist,
  gettextCatalog,
  portalUtil
) {
  return {
    require: ['?holdMusicRowEditor', 'playListRow'],
    restrict: 'A',
    controller: function () {},
    controllerAs: 'playListRowCtrl',

    link: (scope, el, attrs, ctrls) => {
      let rowEditor = ctrls[0]
      let playListRow = ctrls[1]
      playListRow.onSongAdded = song => {
        let playlist = new HoldMusicPlaylist(rowEditor.row._id)

        return playlist
          .get()
          .then(() => {
            let p = _.find(playlist.files, {
              jrn: song.jrn
            })

            if (!p) {
              playlist.files.push(song)
              return playlist.update()
            }
          })
          .catch(error => {
            portalUtil.showErrorAlert(
              gettextCatalog.getString(
                'Adding a song to a playlist failed, please retry.'
              )
            )
            throw error
          })
      }
    }
  }
})
