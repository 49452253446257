import {
  LOGOUT_STAGING,
  LOGOUT_PROD,
  LOGIN_PROD,
  LOGIN_STAGING,
  LOGOUT_DEV,
  LOGIN_DEV
} from '@jive/core'

declare global {
  interface Window { environment: string; }
}

export interface EnvironmentConfig {
  current: string,
  production: boolean,
  gaTrackingCode: string,
  logging: boolean,
  redirectUri: string,
  e2e: boolean,
  logoutBaseUrl: string,
  loginBaseUrl: string,
  angularI18NPrefix: string
  unleashEnvironment: string
  unleashClientKey: string
}

const localConfig = {
  production: false,
  gaTrackingCode: 'UA-143909-13',
  smartyStreetsWebsiteKey: '15257038319866821',
  logging: true,
  redirectUri: '/pbx/',
  e2e: false,
  logoutBaseUrl: LOGOUT_STAGING,
  loginBaseUrl: LOGIN_STAGING,
  angularI18NPrefix: '/',
  unleashEnvironment: 'ED',
  unleashClientKey: '[]:ED.7262a863cbe0e8321619f4d987316ad2b70cea6f4459dd8ecebcd411'
}

const featureConfig = {
  production: false,
  gaTrackingCode: 'UA-143909-13',
  smartyStreetsWebsiteKey: '15257038319866821',
  logging: true,
  redirectUri: '/',
  e2e: false,
  logoutBaseUrl: LOGOUT_DEV,
  loginBaseUrl: LOGIN_DEV,
  angularI18NPrefix: '/',
  unleashEnvironment: 'ED',
  unleashClientKey: '[]:ED.7262a863cbe0e8321619f4d987316ad2b70cea6f4459dd8ecebcd411'
}

const devConfig = {
  production: false,
  gaTrackingCode: 'UA-143909-13',
  smartyStreetsWebsiteKey: '15257038319866821',
  logging: true,
  redirectUri: '/pbx/',
  e2e: false,
  logoutBaseUrl: LOGOUT_DEV,
  loginBaseUrl: LOGIN_DEV,
  angularI18NPrefix: '/pbx/',
  unleashEnvironment: 'ED',
  unleashClientKey: '[]:ED.7262a863cbe0e8321619f4d987316ad2b70cea6f4459dd8ecebcd411'
}

const e2eConfig = {
  production: false,
  gaTrackingCode: 'UA-143909-13',
  smartyStreetsWebsiteKey: '15257038319866821',
  logging: true,
  redirectUri: '/pbx/',
  e2e: true,
  logoutBaseUrl: '',
  loginBaseUrl: '',
  angularI18NPrefix: '/pbx/',
  unleashEnvironment: 'ED',
  unleashClientKey: '[]:ED.7262a863cbe0e8321619f4d987316ad2b70cea6f4459dd8ecebcd411'
}

const stagingConfig = {
  production: false,
  gaTrackingCode: 'UA-143909-13',
  smartyStreetsWebsiteKey: '15257038319866821',
  logging: true,
  redirectUri: '/pbx/',
  e2e: false,
  logoutBaseUrl: LOGOUT_STAGING,
  loginBaseUrl: LOGIN_STAGING,
  angularI18NPrefix: '/pbx/',
  unleashEnvironment: 'Stage',
  unleashClientKey: '[]:Stage.7995add709415d66710dd7ce151e2792cd38151b264090f90a7e634d'
}

const prodConfig = {
  production: true,
  gaTrackingCode: 'UA-143909-31',
  smartyStreetsWebsiteKey: '15257038319866821',
  logging: true,
  redirectUri: '/pbx/',
  e2e: false,
  logoutBaseUrl: LOGOUT_PROD,
  loginBaseUrl: LOGIN_PROD,
  angularI18NPrefix: '/pbx/',
  unleashEnvironment: 'Production',
  unleashClientKey: '[]:Production.96ffe11bb3a9f18a6d5fcfa993e8025999ebba1162fdb973ec663aba'
}

const config: EnvironmentConfig = Object.assign(
  { current: window.environment },
  (() => {
    switch (window.environment) {
      case 'local':
        return localConfig
      case 'e2e':
        return e2eConfig
      case 'feature':
        return featureConfig
      case 'dev':
        return devConfig
      case 'staging':
        return stagingConfig
      default:
        return prodConfig
    }
  })()
)

export default config
