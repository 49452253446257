import _module from 'module.js'
import _ from 'lodash'

class LanguagesSwitcher {

    constructor (languages, $localStorage, tmhDynamicLocale, $q, api, gettextCatalog, $rootScope) {
        'ngInject'
        this.languages               = languages
        this.tmhDynamicLocale        = tmhDynamicLocale
        this.$localStorage           = $localStorage
        this.$q                      = $q
        this.$rootScope              = $rootScope
        this.api                     = api
        this.gettextCatalog          = gettextCatalog
        this.LANGUAGE_SWITCHED_EVENT = 'LANG_SWITCHER:LANGUAGE_CHANGED_EVENT'

        this._selectedLanguage()
            .then(this.language.bind(this))
    }

    language (lang, ev) {
      if (ev) ev.preventDefault()
      if (!lang.hasOwnProperty('langCode')) {
        // combine the lang object, hopefully it does not break either of them.
        lang = _.assignIn(lang, _.find(this.languages, {langCode: 'en-US'}))
      }
     
      this.selectedLanguage = lang
      this.$localStorage.selected_language = JSON.stringify(lang);
      this.gettextCatalog.setCurrentLanguage(lang.langCode)
      this.tmhDynamicLocale.set(lang.langCode.toLowerCase());
      this.$rootScope.$broadcast(this.LANGUAGE_SWITCHED_EVENT, this.selectedLanguage)
    }

    set languages (languages) {
        this._languages = languages
    }

    get languages () {

        return this._languages

    }

    _selectedLanguage () {
      var lang = this.$localStorage.selected_language;
      if (!lang && this.api) {

        return this.api.get()
            .then(function (data) {
                var language = null;
                var groupedLanguages = _.groupBy(this._languages, 'langCode');

                if (data.acceptLanguage) {
                  var acceptedLanguage = data.acceptLanguage[0].language;
                  var acceptedLanguageExists = groupedLanguages[acceptedLanguage];
                  if (acceptedLanguageExists) {
                    language = groupedLanguages[acceptedLanguage][0];
                  } else {
                    language = languages[0];
                  }
                }
                return language;
            });

      } else if (lang) {
        var langJson = JSON.parse(lang);
        // this.tmhDynamicLocale.set(langJson.langCode.toLowerCase());
        return this.$q.when(langJson);
      }

      return this.$q.when(this.languages[0])

    }

}

export default class Languages {

    set api (api) {
        this._api = api
    }

    get api () {
        return this._api
    }

    set languages (languages) {
        this._languages = languages
    }

    $get ($localStorage, tmhDynamicLocale, $q, gettextCatalog, $rootScope) {
        'ngInject'
        return new LanguagesSwitcher(this._languages, $localStorage, tmhDynamicLocale, $q, this.api, gettextCatalog, $rootScope)
    }
}

_module
    .provider('langSwitcher', Languages)
