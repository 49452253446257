/**
 * @ngdoc function
 * @name platformApp.factory:HoldMusicPlaylist
 * @description
 * # HoldMusicPlaylist
 * Service of the platformApp
 */
import _module from 'module.js'
import _ from 'lodash'
import BaseES6 from 'factories/baseES6'

let _portalApi, _globalState
export default class HoldMusicPlaylist extends BaseES6 {
  constructor (id, gettextCatalog) {
    'ngInject'
    super(id)
    this.gettextCatalog = gettextCatalog

    this.defaultName = this.gettextCatalog.getString('Default')
    this.silentName = this.gettextCatalog.getString('None (Silence)')
    if (id === undefined) {
      throw new Error(
        this.gettextCatalog.getString('Missing id for new HoldMusicPlaylist')
      )
    }

    this.files = []
    this.randomPlayOrder = false

    if (id === 'default') {
      this.name = this.defaultName
    }
    if (id === 'silent') {
      this.name = this.silentName
    }
    this.cacheEnabled = true
  }

  getApi () {
    if (!this._api) {
      if (this._id === 'default') {
        this._api = _portalApi.music.api().default()
      } else if (this._id === 'silent') {
        this._path = _portalApi.music.api().silent()
      } else {
        this._api = _portalApi.media.pbxs(
          _globalState.selectedPbxUUID
        ).holdMusicPlaylists
      }
    }
    return this._api
  }

  getPath () {
    if (!this._path) {
      if (this._id === 'default') {
        this._path = _portalApi.music.api().default()
      } else if (this._id === 'silent') {
        this._path = _portalApi.music.api().silent()
      } else {
        this._path = _portalApi.media
          .pbxs(_globalState.selectedPbxUUID)
          .holdMusicPlaylists(this._id)
      }
    }
    return this._path
  }

  getReferencesPath () {
    if (!this._referencesPath) {
      this._referencesPath = _portalApi.media
        .pbxs(_globalState.selectedPbxUUID)
        .holdMusicPlaylists(this._id)
        .one('references')
    }
    return this._referencesPath
  }

  map (data) {
    this.jrn = data.jrn
    this.name = data.name
    this.randomPlayOrder = data.randomPlayOrder
    this.files = data.files
  }

  addSong (song) {
    return this.get().then(() => {
      var p = _.find(this.files, {
        jrn: song.jrn
      })

      if (!p) {
        this.files.push(song)
        return this.update()
      }
    })
  }

  mapListItem (group) {
    return {
      id: group.jrn,
      text: group.name
    }
  }

  getClass () {
    return 'HoldMusicPlaylist'
  }

  static factory (globalState, portalApi, gettextCatalog) {
    'ngInject'
    _portalApi = portalApi
    _globalState = globalState

    return id => {
      return new HoldMusicPlaylist(id, gettextCatalog)
    }
  }
}

_module.factory('HoldMusicPlaylist', HoldMusicPlaylist.factory)
