import jrnParser from 'classes/jrnParser'

let _jrn, _state, _globalState, _portalApi
export default class GetDialByNameDirectoryController {
  constructor (gettextCatalog, $state, globalState, portalApi) {
    'ngInject'
    this._model = {}
    _state = $state
    _globalState = globalState
    _jrn = jrnParser
    _portalApi = portalApi

    this.api = _portalApi.extension.pbxs(
      _globalState.selectedPbxUUID
    ).directories

    this.placeholder = gettextCatalog.getString(
      'Select a dial-by-name directory...'
    )

    this.$onInit = () => {
      this.setViewValue = item => {
        this.ngModel.$setViewValue(item.id)
      }
    }
  }

  openLink () {
    _state.go('root.nav.pbx.directories.view.general', {
      id: _globalState.selectedPbx.domain,
      directoryId: _jrn.parse(this.jrn).getResources()[1]
    })
  }
}
