/**
 * @ngdoc function
 * @name platformApp.factory:RingGroup
 * @description
 * # RingGroup
 * Service of the platformApp
 */
import _module from 'module.js'
import _ from 'lodash'
import ExtensionWithVoicemailBoxES6
  from 'factories/extensionWithVoicemailBoxES6'
import jrnParser from 'classes/jrnParser'

let _stateParams, _portalApi, _jrn, _portalUtil, _state, _globalState, _filter

export default class RingGroup extends ExtensionWithVoicemailBoxES6 {
  constructor (id, ringGroup, gettextCatalog) {
    'ngInject'
    super(id)
    this.gettextCatalog = gettextCatalog

    if (this._id === undefined) {
      throw new Error(
        this.gettextCatalog.getString('Missing id for new RingGroup')
      )
    }

    this._members = []
    this._lineRingGroupMembers = []
    this._externalRingGroupMembers = []

    if (ringGroup) {
      this._map(ringGroup)
    }
  }

  getApi () {
    if (!this._api) {
      this._api = _portalApi.extension.pbxs(
        _globalState.selectedPbxUUID
      ).ringGroups
    }

    return this._api
  }

  getPath () {
    if (!this._path) {
      this._path = _portalApi.extension
        .pbxs(_globalState.selectedPbxUUID)
        .ringGroups(this._id)
    }

    return this._path
  }

  getReferencesPath () {
    if (!this._referencesPath) {
      this._referencesPath = _portalApi.extension
        .pbxs(_globalState.selectedPbxUUID)
        .ringGroups(this._id)
        .one('references')
    }
    return this._referencesPath
  }

  map (data) {
    this._members = []
    this._lineRingGroupMembers = []
    this._externalRingGroupMembers = []

    this.extensionRegion = data.extensionRegion ? data.extensionRegion : ''

    this.voicemailBox.map(data.voicemailBox)
    this.voicemailBox._voicemailAccessPermissions = []
    if (data.ringGroupPermissions) {
      this.voicemailBox._voicemailAccessPermissions = data.ringGroupPermissions.map(
        target => {
          return {
            id: target.identity,
            text: _portalUtil.getNameFromUserOrGroup(target),
            path: _portalUtil.resolveIdentityPath(target)
          }
        }
      )
    }

    let i = 0
    if (data.members) {
      this._members = data.members.map(member => {
        if (member.type === 'line') {
          let number = ''
          if (member.extensionNumber) {
            number = member.extensionNumber + ': '
          }
          let lineMember = {
            id: member.line,
            text: number + member.name,
            path: _state.href('root.nav.pbx.extensions.lines.view.general', {
              extId: _jrn.parse(member.line).getResources()[1]
            })
          }

          this._lineRingGroupMembers.push(lineMember)
          return lineMember
        } else if (member.type === 'external-number') {
          i++
          if (member.number.startsWith('+1') && member.number.length === 9) {
            let externalNumber = member.number
            externalNumber = externalNumber.replace('+1', '')
            member.number =
              '+1' + _globalState.selectedPbx.localAreaCode + externalNumber
          }
          let val = {
            id: 'external-number' + i,
            text: _filter('tel')(member.number),
            e164: member.number
          }

          this._externalRingGroupMembers.push(val)
          return val
        }
      })
    } else if (data._members) {
      this._members = data._members
    }
  }

  build (payload) {
    payload.extensionNumber = this.extensionNumber || null
    if (this._voicemailBoxOverrideTarget) {
      payload.voicemailBoxOverrideTarget = this._voicemailBoxOverrideTarget.id
    }

    payload.ringGroupPermissions = this.voicemailBox._voicemailAccessPermissions.map(
      target => {
        return {
          identity: target.id,
          permissions: ['ACCESS_VOICEMAIL']
        }
      }
    )

    if (this._members) {
      payload.members = this._members.map(member => {
        if (member.id.indexOf('line') > -1) {
          var name = member.text.split(': ')
          return {
            type: 'line',
            line: member.id,
            extensionNumber: (name.length > 1) ? name[0] : null,
            name: (name.length > 1) ? name[1] : name[0]
          }
        } else {
          return {
            type: 'external-number',
            number: member.e164
          }
        }
      })
    }

    return payload
  }

  getClass () {
    return 'RingGroup'
  }

  static factory (
    $stateParams,
    portalApi,
    gettextCatalog,
    portalUtil,
    globalState,
    $state,
    $filter
  ) {
    'ngInject'
    _stateParams = $stateParams
    _portalApi = portalApi
    _jrn = jrnParser
    _portalUtil = portalUtil
    _globalState = globalState
    _state = $state
    _filter = $filter

    return (id, ringGroup) => {
      return new RingGroup(id, ringGroup, gettextCatalog)
    }
  }
}

_module.factory('RingGroup', RingGroup.factory)
