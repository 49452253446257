import _module from 'module.js'
import _ from 'lodash'

_module.directive('uniqueSite', (portalApi, $q, globalState) => ({
  restrict: 'A',
  require: 'ngModel',
  link: (scope, $input, attrs, ngModelCtrl) => {
    const originalValue = scope.$eval(attrs.ngModel)
    ngModelCtrl.$asyncValidators.uniqueSite = async (model, view) => {
      const value = model || view
      if (
        !value ||
        value.length === 0 ||
        (originalValue && originalValue === value)
      ) {
        return Promise.resolve()
      }
      const deferred = $q.defer()
      try {
        const r = await portalApi.site
          .pbxs(globalState._selectedPbxUUID)
          .sites('by-name')
          .one(value)
          .get()
        deferred.reject('exists')
      } catch (err) {
        if (err.status === 404) {
          deferred.resolve(true)
        } else {
          deferred.reject(err)
        }
      }
      return deferred.promise
    }
  }
}))
