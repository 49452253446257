import _module from 'module.js'
import jvListItemsTemplate from './jvListItemsTemplate.html'
import JvListItemsController from './jvListItemsController.js'

_module.component('jvListItems', {
  template: jvListItemsTemplate,
  controllerAs: 'jvListItems',
  controller: JvListItemsController,
  bindings: {
    items: '=',
    disabled: '<',
    onSelect: '&',
    onRemove: '&'
  }
})
