
import _module from 'module.js'
import unifiedAdminDashboardTemplate from 'controllers/unifiedAdminDashboard/unifiedAdminDashboardTemplate.html'

_module.config(($stateProvider) => {
  'ngInject'

  $stateProvider
    .state('root.nav.pbx.unified-admin-dashboard', {
      url: '/unified-admin-dashboard',
      template: unifiedAdminDashboardTemplate,
      controller: 'UnifiedAdminDashboardController as ctrl'
    })
})
