import jrnParser from 'classes/jrnParser'

let _portalApi, _jrn, _globalState, _state, _window

export default class GetChatQueueController {
  constructor (gettextCatalog, $state, globalState, portalApi, $window) {
    'ngInject'
    this._model = {}
    _portalApi = portalApi
    _state = $state
    _jrn = jrnParser
    _window = $window
    _globalState = globalState

    this.placeholder = gettextCatalog.getString('Select a chat queue...')

    this.api = _portalApi.contactCenterConfiguration.pbxs(_globalState.selectedPbxUUID).chatQueues;

    this.$onInit = () => {
      this.setViewValue = item => {
        this.ngModel.$setViewValue(item.id)
      }
    }
  }

  map (chatQueue) {
    return {
      id: chatQueue.id,
      text: chatQueue.name
    }
  }
}


