import jrnParser from 'classes/jrnParser'

let _jrn, _state, _globalState, _portalApi, _filter
export default class GetJbcController {
  constructor (gettextCatalog, $state, globalState, $filter, portalApi) {
    'ngInject'
    this._model = {}
    _state = $state
    _globalState = globalState
    _jrn = jrnParser
    _portalApi = portalApi
    _filter = $filter

    this.placeholder = gettextCatalog.getString(
      'Select a business continuity setting...'
    )

    this.api = _portalApi.userAgent.pbxs(_globalState.selectedPbxUUID).jbcs

    this.$onInit = () => {
      this.setViewValue = item => {
        this.ngModel.$setViewValue(item.id)
      }
    }
  }

  map (jbc) {
    return {
      id: jbc.id,
      text: jbc.description
    }
  }

  openLink () {
    _state.go('root.nav.pbx.dids.view.general', {
      id: _globalState.selectedPbx.domain,
      didId: _jrn.parse(this.jrn).getResources()[1]
    })
  }
}
