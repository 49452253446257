import getGroupsController from './getGroupsController.js'
import getGroupsTemplate from './getGroupsTemplate.html'
import _module from 'module.js'
import _ from 'lodash'

_module.component('getGroups', {
  template: getGroupsTemplate,
  require: {
    ngModel: 'ngModel'
  },
  bindings: {
    members: '<ngModel',
    filterId: '<',
    limit: '<',
    disabled: '<'
  },
  controllerAs: 'ctrl',
  controller: getGroupsController
})
