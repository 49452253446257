import _module from 'module.js'

/**
 * Filter is checking a list of attributes values based on names in the names array.
 * If one of the checked setting is true, then the item will not be filtered.
 */
_module.filter('settingEnabled', function () {
  return function (
    items,
    settingValue,
    settingEnabledPropertyNames = 'settingEnabled'
  ) {
    return items.filter(item => {

      if (!item[settingEnabledPropertyNames]) {
        return true;
      } else  {
        var isEnabled = false;
        angular.forEach(item[settingEnabledPropertyNames], function(value, key) {
          if (isEnabled || typeof settingValue[value] === 'undefined' || settingValue[value]) {
            isEnabled = true;
          }
        })
        return isEnabled;
      }
    })
  }
})
