import playlistsTemplate from './playlistsTemplate.html'
import playlistsController from './playlistsController.js'
import module from 'module'

module.component('playLists', {
  template: playlistsTemplate,
  controllerAs: 'playLists',
  require: {
    ngModel: 'ngModel'
  },
  bindings: {
    selectedList: '=ngModel',
    playlistCount: '='
  },
  controller: playlistsController
})
