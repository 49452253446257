import _module from 'module.js'

_module.directive('extensionLength', function (pbxUtil) {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, $input, attrs, ngModelCtrl) {
      pbxUtil.getExtensionLength().then(length => {
        ngModelCtrl.$validators.extensionLength = () => {
          return $input.val().length === 0 || $input.val().length === length
        }
      })
    }
  }
})
