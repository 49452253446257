import _module from 'module.js'

_module.directive('largeFaxDestRequired', function () {
  return {
    restrict: 'A',
    require: 'ngModel',
    scope: {
      largeFaxDestRequired: '='
    },
    link: function (scope, $input, attrs, ngModelCtrl) {
      scope.$watch('largeFaxDestRequired', () => {
        ngModelCtrl.$validate()
      })
      ngModelCtrl.$validators.largeFaxDestRequired = () =>
        scope.largeFaxDestRequired === false ||
        (!!$input.val() && !!$input.val().trim())
    }
  }
})
