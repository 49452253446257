/**
 * @ngdoc function
 * @name platformApp.factory:Settings
 * @description
 * # Settings
 * Service of the platformApp
 */
import _module from 'module.js'
import _ from 'lodash'
import BaseES6 from 'factories/baseES6'

let _portalApi, _globalState

export default class SettingsService extends BaseES6 {
  constructor () {
    'ngInject'
    super(null, _globalState.selectedPbxUUID)

    this._defaultOutboundDid = null
    this.partnerInfo = _globalState.selectedPbx.partnerInfo
  }

  getPath () {
    if (!this._path) this._path = _portalApi.pbx.pbxs(this._pbxId)

    return this._path
  }

  map (data) {
    this._timeZone = {
      id: data.timeZone,
      text: data.timeZone
    }
    if (data.region) {
      this.region = data.region.value || data.region
    }
    this.newNumber = ''
    this._useDefaultRegistrationProxy = !data.defaultRegistrationProxy
    this._useDefaultOutboundProxy = !data.defaultOutboundProxy
  }

  build (payload) {
    payload.timeZone = this._timeZone.id
    payload.region = this.region

    if (this.defaultCorporateDirectory != null) {
      if (this.defaultCorporateDirectory.id) {
        payload.defaultCorporateDirectory = this.defaultCorporateDirectory.id
      } else {
        payload.defaultCorporateDirectory = this.defaultCorporateDirectory
      }
    }

    if (!this._useDefaultRegistrationProxy) {
      payload.defaultRegistrationProxy = this.defaultRegistrationProxy
    } else {
      payload.defaultRegistrationProxy = null
    }

    if (!this._useDefaultOutboundProxy) {
      payload.defaultOutboundProxy = this.defaultOutboundProxy
    } else {
      payload.defaultOutboundProxy = null
    }

    return payload
  }

  getClass () {
    return 'Settings'
  }

  static factory (portalApi, globalState) {
    'ngInject'
    _portalApi = portalApi
    _globalState = globalState

    return () => new SettingsService()
  }
}
_module.factory('Settings', SettingsService.factory)
