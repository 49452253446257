import _module from 'module.js'
import { LMI_ADMIN_PORTAL_TOKEN } from '../../services/jiveOAuthClient'

export default class GuidedSetupController {
  constructor (globalState, authService) {
    this.globalState = globalState
    this.authService = authService

    this._setToken()
    this.orgId = this.globalState.selectedPbxUUID
    this.locale = this.globalState.currentLanguage.langCode
    this.history = this.globalState.history
    this.error401Handler = this.reAuthenticate.bind(this)

    setTimeout(() => {
      this.readyToRender = true
    }, 0)
  }

  reAuthenticate () {
    this.authService.reAuthenticate((err, authInfo) => {
      if (authInfo) {
        if (authInfo.inflightRequest) {
          _window.location.assign(new URL(decodeURIComponent(authInfo.inflightRequest), window.location.toString()).pathname)
        } else {
          this.readyToRender = false
          this._setToken()
          this.readyToRender = true
        }
      }
    })
  }

  _setToken () {
    const {token, jiveId} = JSON.parse(
      localStorage.getItem(LMI_ADMIN_PORTAL_TOKEN) || localStorage.getItem('jive-foken')
    )
    this.oauthToken = token
    this.principal = jiveId
  }
}

_module.controller('GuidedSetupController', GuidedSetupController)
