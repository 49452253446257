import _module from 'module.js'
import _ from 'lodash'

let _dataController, _LIMIT_DEFAULT

export default class InfiniteLoader {
  constructor (dataApi, Type, onDataLoaded) {
    'ngInject'
    this.api = dataApi
    this.entityApi = _dataController(dataApi, Type)
    this.onDataLoaded = onDataLoaded
    this.extraParams = {}
  }

  setExtraParams (extraParams) {
    this.extraParams = extraParams
  }

  _parseResults (results) {
    results.offset += results.limit
    results.noMoreData = results.offset >= results.matchCount

    results.q = results.query
    results.loading = false

    delete results.query // stupid server changes to this when returned

    return results
  }

  async getListResults (query) {
    const _query = _.defaults(
      {
        limit: _LIMIT_DEFAULT
      },
      _.omit(Object.assign(query, this.api.customParams()), [
        'items',
        'loading',
        'matchCount',
        'totalCount'
      ])
    )

    Object.assign(_query, this.extraParams)

    query.loading = true
    const results = (await this.api.getListResult(_query)).plain()
    query.loading = false

    if (query.items) {
      results.items = query.items.concat(results.items)
    }

    if (this.api.mapBatchData) {
      results.items.map(this.api.mapBatchData)
    }

    if (this.onDataLoaded) {
      this.onDataLoaded(results)
    }

    return this._parseResults(results)
  }

  static factory (dataController, LIMIT_DEFAULT) {
    'ngInject'
    _dataController = dataController
    _LIMIT_DEFAULT = LIMIT_DEFAULT

    return (Type, onDataLoaded) => {
      return new InfiniteLoader(new Type(null), Type, onDataLoaded)
    }
  }
}

_module.factory('infiniteLoader', InfiniteLoader.factory)
