import _module from 'module.js'
import jrnParser from 'classes/jrnParser'
import _ from 'lodash'

class UrlProvider {
  urlFor (referencedType, resource, referencingClass = null) {
    const parsedJrn = referencedType === 'address' ? jrnParser.parse(resource.emergencyLocationJrn) : jrnParser.parse(resource.jrn)
    const pbxId = parsedJrn.getAccount()
    const [resourceType, resourceId] = parsedJrn.getResources()
    const extType = _extensionTypes.allTypes.find(
      ({ id }) => id === resourceType
    )
    const params = p => {
      if (
        _globalState.selectedPbx.domain === _stateParams.id ||
        _.includes(_globalState.selectedPbx.jrn, pbxId)
      ) {
        return Object.assign(
          { id: _globalState.selectedPbx.domain },
          _stateParams,
          p
        )
      }
      return Object.assign(_stateParams, { id: pbxId }, p)
    }
    const url = (() => {
      switch (referencedType) {
        case 'pbx':
          return _state.href('root.nav.pbx.settings.general', params())
        case 'site':
          return _state.href(
            'root.nav.pbx.sitesv2.view.general',
            params({
              siteId: resourceId
            })
          )
          case 'address':
          return _state.href(
            'root.nav.pbx.sitesv2.list.locations.view',
            params({
              emergencyLocationId: resourceId
            })
          )
        case 'extensionMapping':
          return _state.href(
            'root.nav.pbx.settings.extensionMappings',
            params()
          )
        case 'device':
          if (this.isExtension(referencingClass)) {
            return _state.href(
              'root.nav.pbx.devices.view.linesAndButtons',
              params({
                deviceId: resourceId
              })
            )
          }
          if (referencingClass === 'MasterDid') {
            _stateParams.id = pbxId
            return _state.href('root.nav.pbx.devices.hardware', params())
          }
          if (referencingClass === 'Hardware') {
            return _state.href(
              'root.nav.pbx.devices.view.hardware',
              params({
                deviceId: resourceId
              })
            )
          }
          return _state.href(
            'root.nav.pbx.devices.view.settings',
            params({
              deviceId: resourceId
            })
          )
        case 'did':
          return _state.href(
            'root.nav.pbx.dids.view.general',
            params({
              didId: resourceId
            })
          )
        case 'line':
          return _state.href(
            'root.nav.pbx.extensions.lines.view.general',
            params({
              extId: resourceId
            })
          )
        case 'dialPlan':
          return new _DialPlan(resourceId, resource).createDialPlanUrl()
        case 'ringGroup':
          if (this.isExtension(referencingClass)) {
            return _state.href(
              'root.nav.pbx.extensions.ringgroups.view.members',
              params({ extId: resourceId })
            )
          }
          return _state.href(
            'root.nav.pbx.extensions.ringgroups.view.general',
            params({ extId: resourceId })
          )
        case 'callQueue':
          if (
            referencingClass === 'Device' ||
            this.isExtension(referencingClass)
          ) {
            return _state.href(
              'root.nav.pbx.extensions.callqueues.view.general',
              params({ extId: resourceId })
            )
          }
          return _state.href(
            'root.nav.pbx.extensions.callqueues.view.queuebehavior',
            params({ extId: resourceId })
          )
        case 'extension':
          switch (extType.id) {
            case 'line':
              return _state.href(
                'root.nav.pbx.extensions.lines.view.voicemail',
                params({ extId: resourceId })
              )
            case 'ringGroup':
              return _state.href(
                'root.nav.pbx.extensions.ringgroups.view.voicemail',
                params({ extId: resourceId })
              )
            case 'dialPlan':
              return _state.href(
                'root.nav.pbx.extensions.lines.view.general',
                params({ extId: resourceId })
              )
            case 'callQueue':
              return _state.href(
                'root.nav.pbx.extensions.callqueues.view.queuebehavior',
                params({ extId: resourceId })
              )
            default:
              console.error('Unknown extType: ' + extType)
          }
          return null
        case 'pagingProfile':
          return _state.href(
            'root.nav.pbx.devices.profiles.view.general',
            params({
              profileId: resourceId
            })
          )
        case 'userGroup':
          return _state.href(
            'root.nav.pbx.groups.view.general',
            params({
              groupId: resourceId
            })
          )
        case 'virtualFaxMachine':
          return _state.href(
            'root.nav.pbx.extensions.virtualfaxmachines.view.sending',
            params({ extId: resourceId })
          )
        case 'callFilter':
          return _state.href('root.nav.pbx.callfilters.list', params())
        default:
          console.error('Unknown referenced type: ' + referencedType)
          return null
      }
    })()

    return this._removeIndexHtmSuffix(url)
  }

  isExtension (referencingClass) {
    return _extensionClasses.includes(referencingClass)
  }

  // for whatever reason, the url returned above includes 'index.htm' at the beginning
  _removeIndexHtmSuffix (url) {
    const starting = 'index.htm'
    const startingIndex = url.indexOf(starting)
    return startingIndex != -1
      ? url.substring(startingIndex + starting.length + 1)
      : url
  }

  static factory (extensionTypes, $stateParams, $state, DialPlan,  globalState) {
    'ngInject'
    _extensionTypes = extensionTypes
    _stateParams = $stateParams
    _state = $state
    _DialPlan = DialPlan
    _globalState = globalState
    return new UrlProvider()
  }
}

let _extensionTypes, _stateParams, _state, _DialPlan, _globalState

const _extensionClasses = [
  'Extension',
  'Line',
  'RingGroup',
  'CallQueue',
  'ConferenceRoom',
  'DialPlan',
  'SharedVoicemailBox',
  'VirtualFaxMachine',
  'Did',
]

_module.factory('urlProvider', UrlProvider.factory)
