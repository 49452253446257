import _module from 'module'

export class ZohoMappingAuthController {
  constructor (
    zohoMappingAuthService,
    $state,
    confirmationWindowWarn,
    gettextCatalog,
    globalState
  ) {
    'ngInject'
    this.zohoMappingAuthService = zohoMappingAuthService
    this.$state = $state
    this.confirmationWindowWarn = confirmationWindowWarn
    this.gettextCatalog = gettextCatalog

    this.zohoMappingAuthService.resolve().then(response => {
      const integrationsUrl = response.pbxDomain
      const state = encodeURIComponent(this.getState())

      if (!response.isAuthenticated) {
        this.confirmationWindowWarn
          .show(
            this.gettextCatalog.getString('Sign in Zoho CRM Account'),
            this.gettextCatalog.getString(
              "You must be signed in to Zoho CRM to view your Zoho user mappings. Press the 'Sign In' button to be redirected to the Zoho CRM login page."
            ),
            this.gettextCatalog.getString('Sign in')
          )
          .then(
            () => {
              window.location.href = `${integrationsUrl}?clientId=${globalState.ambassadorClientAppId}&state=${state}`
            },
            () => {
              $state.go('root.nav.pbx.dashboard')
            }
          )
      }
    })
  }

  /**
   * Returns the current state path without the base href. This state can be used for
   * the redirection on the integration page.
   *
   * For example, when the user is on `https://my.jive.com/pbx/my-pbx/crmmapping` it
   * will returns `my-pbx/crmmapping`, because `https://my.jive.com/pbx` is our base
   * href.
   */
  getState () {
    const currentStateHref = this.$state.href(this.$state.current.name)
    const rootStateHref = this.$state.href('root')
    const newStateHref = currentStateHref.replace(rootStateHref, '')

    // Remove the starting slash (/) if it has one
    if (newStateHref.indexOf('/') === 0) {
      return newStateHref.substr(1)
    }

    return newStateHref
  }
}

_module.component('zohoMappingAuth', {
  require: {
    ngModel: 'ngModel'
  },
  controllerAs: 'ctrl',
  controller: ZohoMappingAuthController
})
