import _module from 'module.js'
import { LMI_ADMIN_PORTAL_TOKEN } from '../../services/jiveOAuthClient'

export default class PhoenixController {
  constructor (globalState) {
    this.globalState = globalState

    this.oauthToken = JSON.parse(
      localStorage.getItem(LMI_ADMIN_PORTAL_TOKEN) || localStorage.getItem('jive-foken')
    ).token
    this.orgId = this.globalState.selectedPbxUUID
    this.locale = this.globalState.currentLanguage.langCode
    this.history = globalState.history

    setTimeout(() => {
      this.readyToRender = true
    }, 0);
  }
}

_module.controller('PhoenixController', PhoenixController)
