import _module from 'module.js'
import holdMusicsTemplate from 'controllers/holdMusics/holdMusicsTemplate.html'
import soundClipsTemplate from 'controllers/soundClips/soundClipsTemplate.html'
import soundClipTemplate from 'controllers/soundClip/soundClipTemplate.html'

_module.config($stateProvider => {
  'ngInject'

  $stateProvider
    .state('root.nav.pbx.holdmusic', {
      url: '/music/playlists',
      abstract: true,
      template: '<ui-view class="holdmusic-abstract max-height"></ui-view>'
    })
    .state('root.nav.pbx.holdmusic.list', {
      url: '/:holdmusicId',
      controller: 'HoldMusicsController as holdMusicsCtrl',
      template: holdMusicsTemplate,
      params: {
        holdmusicId: { value: 'builtIn' }
      },
      reloadOnSearch: false
    })
    .state('root.nav.pbx.holdmusic.view', {
      url: '',
      controller: 'HoldMusicsController as holdMusicsCtrl',
      template: holdMusicsTemplate,
      params: {
        holdmusicId: { value: 'builtIn' }
      }
    })
    .state('root.nav.pbx.soundclips', {
      url: '/music/soundclips',
      abstract: true,
      template: '<ui-view class="sound-clips-abstract max-height"></ui-view>'
    })
    .state('root.nav.pbx.soundclips.list', {
      url: '?q&sort&direction&source',
      template: soundClipsTemplate,
      controller: 'SoundClipsController as ctrl',
      reloadOnSearch: false
    })
    .state('root.nav.pbx.soundclips.view.item', {
      url: '/:clipId',
      template: soundClipTemplate
    })
})
