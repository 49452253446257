import moment from 'moment/min/moment-with-locales'
import _ from 'lodash'

let SEC_IN_DAY = 60 * 60 * 24

const isEqualTime = (a, b) => {
  return (
    a.startSeconds % SEC_IN_DAY === b.startSeconds % SEC_IN_DAY &&
    a.endSeconds % SEC_IN_DAY === b.endSeconds % SEC_IN_DAY
  )
}

const isAdjacent = (a, b) => {
  return Math.abs(b.getStart().column - a.getStart().column) <= 1
}

export default class ScheduleController {
  constructor (TimeRange, globalState, gettextCatalog) {
    'ngInject'
    this.TimeRange = TimeRange

    this.gettextCatalog = gettextCatalog

    this.SCHEDULE_HEIGHT = 624
    this.SCHEDULE_WIDTH = 812
    this.ROW_HEIGHT = 13
    this.ROW_WIDTH = 116
    this.ROWS_PER_HOUR = 4
    this.BASE_LANGUAGE = 'en-US'
    this.langCode = this.BASE_LANGUAGE
    this.hoursLocalized = []

    this.mode = 'idle'
    this.region = globalState.supportedRegions.find(
      ({ code }) => code === globalState.selectedPbx.region
    )
    this.weekStartMonday = this.region.weekStartMonday

    this._timeRanges = this.timeRanges.map(range => {
      let start = range.startSeconds
      let end = range.endSeconds
      if(this.weekStartMonday) {
        start -= SEC_IN_DAY
        end -= SEC_IN_DAY
        if(start < 0) {
          start += SEC_IN_DAY * 7
          end += SEC_IN_DAY * 7
        }
      }
      return new this.TimeRange(start, end)
    })
    this._timeRanges.sort((a, b) => {
      if (a.getStart().column < b.getStart().column) {
        return -1
      }
      if (a.getStart().column > b.getStart().column) {
        return 1
      }
      return 0
    })

    this.createLocalizedTimeRanges()

    this.hours = new Array(24)
    this.days = new Array(7)

    if (this.langCode != globalState.currentLanguage.langCode) {
      this.langCode = globalState.currentLanguage.langCode
      moment.locale(this.langCode)
      this.createLocalizedTimeRanges()

    }

    const baseHoursLocalized = moment()
      .locale(this.langCode)
      .startOf('year')
      .startOf('day')
      
    for(let i = 0; i <= 24; i++) {
      this.hoursLocalized[i] = baseHoursLocalized
      .format('LT')

      baseHoursLocalized
      .add(1, 'hours')
    }
    this.dayOfWeekLocalized = [this.gettextCatalog.getString('Sunday'),
        this.gettextCatalog.getString('Monday'),
        this.gettextCatalog.getString('Tuesday'),
        this.gettextCatalog.getString('Wednesday'),
        this.gettextCatalog.getString('Thursday'),
        this.gettextCatalog.getString('Friday'),
        this.gettextCatalog.getString('Saturday')]
    if(this.weekStartMonday) {
      this.dayOfWeekLocalized.push(this.dayOfWeekLocalized.shift())
    }
  }

  createLocalizedTimeRanges () {
    for (let i = this._timeRanges.length - 1; i >= 1; i--) {
      let target = this._timeRanges[i]
      target.setLocale(this.langCode)
      for (let j = i - 1; j >= 0; j--) {
        let next = this._timeRanges[j]
        next.setLocale(this.langCode)
        if (isEqualTime(target, next) && isAdjacent(target, next)) {
          let timeRangeStart = next.startTime
          let timeRangeEnd = target.endTime
          target.setLocalizedTimestringEndpoints(
            timeRangeStart.toDate(),
            timeRangeEnd.toDate(),
            this.langCode
          )
          this._timeRanges.splice(j, 1)
          i--
        }
      }
    }
    this._timeRanges.forEach(range => {
      if (range.startTime.locale() != this.langCode) {
        range.setLocalizedTimestringEndpoints(
          range.startTime.toDate(),
          range.endTime.toDate(),
          this.langCode
        )
      }
    })
  }

  updateBackendTimeRanges () {
    let ranges = []
    this._timeRanges.forEach(range => {
      let cols = range.getEnd().column - range.getStart().column + 1
      let j = 0
      for (let i = cols; i > 0; i--) {
        let r = new this.TimeRange()
        let column = range.getStart().column + j
        j++
        let timeRangeStart = moment()
          .locale(this.BASE_LANGUAGE)
          .year(1970)
          .startOf('year')
          .startOf('week')
          .add(column, 'days')
        timeRangeStart.hours(range.startTime.hours())
        timeRangeStart.minutes(range.startTime.minutes())
        let timeRangeEnd = moment()
          .locale(this.BASE_LANGUAGE)
          .year(1970)
          .startOf('year')
          .startOf('week')
          .add(column, 'days')
        timeRangeEnd.hours(range.endTime.hours())
        timeRangeEnd.minutes(range.endTime.minutes())
        if (timeRangeEnd.hours() == 0 && timeRangeEnd.minutes() == 0) {
          timeRangeEnd.add(1, 'day')
        }

        r.setLocalizedTimestringEndpoints(
          timeRangeStart.toDate(),
          timeRangeEnd.toDate(),
          this.BASE_LANGUAGE
        )

        ranges.push({
          startSeconds: r.startSeconds,
          endSeconds: r.endSeconds
        })
      }
    })

    if(this.weekStartMonday) {   
      ranges = ranges.map(range => {
        let start = range.startSeconds + SEC_IN_DAY % (SEC_IN_DAY * 7)
        let end = range.endSeconds + SEC_IN_DAY % (SEC_IN_DAY * 7)
      
        return new this.TimeRange(start, end)
      })
    }

    return ranges
  }

  addNewTimeRange (row, column) {
    let timeRange = new this.TimeRange()
    let adjustedRow = row * this.ROWS_PER_HOUR
    timeRange.setEndpoints(
      [adjustedRow, column],
      [adjustedRow + this.ROWS_PER_HOUR, column]
    )
    timeRange.setLocale(this.langCode)

    this._timeRanges.push(timeRange)
    this.save()
  }

  remove (timeRange) {
    _.remove(this._timeRanges, range => {
      return timeRange.getId() === range.getId()
    })
    this.save()
  }

  changeMode (mode) {
    this.mode = mode
  }

  save () {
    let newTimeRanges = this.updateBackendTimeRanges()
    this.ngModel.$setViewValue(newTimeRanges)
  }
}
