/**
 * @ngdoc function
 * @name platformApp.factory:Hardware
 * @description
 * # Hardware
 * Service of the platformApp
 */
import _module from 'module.js'
import BaseES6 from 'factories/baseES6'

let _stateParams, _portalApi, _globalState

export default class Hardware extends BaseES6 {
  constructor (id, hardware, pbxId, gettextCatalog) {
    super(id || _stateParams.hardwareId, pbxId || _globalState.selectedPbxUUID)

    this.gettextCatalog = gettextCatalog

    if (this._id === undefined) {
      throw new Error(
        this.gettextCatalog.getString('Missing id for new Hardware')
      )
    }

    if (hardware) {
      this._map(hardware)
    }
    this.cacheEnabled = true
  }

  getApi () {
    if (!this._api) { this._api = _portalApi.userAgent.pbxs(this._pbxId).userAgents }
    return this._api
  }

  getPath () {
    if (!this._path) { this._path = _portalApi.userAgent.pbxs(this._pbxId).userAgents(this._id) }
    return this._path
  }

  getReferencesPath () {
    if (!this._referencesPath) {
      this._referencesPath = _portalApi.userAgent
        .pbxs(this._pbxId)
        .userAgents(this._id)
        .one('references')
    }
    return this._referencesPath
  }

  build (payload) {
    payload.location = this.location || ''

    return payload
  }

  mapListItem (hardware) {
    return {
      id: hardware.jrn,
      text: hardware.macAddress
    }
  }

  getClass () {
    return 'Hardware'
  }

  static factory ($stateParams, portalApi, gettextCatalog, globalState) {
    'ngInject'
    _stateParams = $stateParams
    _portalApi = portalApi
    _globalState = globalState

    return (id, hardware, pbxId = null) => {
      return new Hardware(id, hardware, pbxId, gettextCatalog)
    }
  }
}

_module.factory('Hardware', Hardware.factory)
