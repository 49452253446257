import { Environment } from "@jive/ui-common";
import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AppModule } from "./app.module";
import environments from "./configs/environments";
import { registerServicewoker } from "../service-worker/sw-registration";

import "./index";

if (environments.current === Environment.PROD) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((_) => registerServicewoker())
  .catch(console.error.bind(console));
