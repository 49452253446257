let _portalApi, _Site, _globalState, _state

export default class GetSiteController {
  constructor (gettextCatalog, portalApi, globalState, $state) {
    'ngInject'
    this._model = {}
    _portalApi = portalApi
    _globalState = globalState
    _state = $state

    this.placeholder = gettextCatalog.getString('Select an Emergency Location ...')

    this.api = _portalApi.site.pbxs(_globalState.selectedPbxUUID).sites

    this.$onInit = () => {
      this.setViewValue = item => {
        this.ngModel.$setViewValue(item.id)
      }
    }
  }

  map (site) {
    return {
      id: site.jrn,
      text: site.name
    }
  }

  openLink () {
    _state.go('root.nav.pbx.sitesv2.list.locations', {
      id: _globalState.selectedPbx.domain
    })
  }
}
