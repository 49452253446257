import _module from 'module.js'
import _ from 'lodash'

export default class VoicemailBox {
  constructor () {
    'ngInject'
    this._timeZone = {
      id: null,
      text: 'PBX Default'
    }

    this._changePasswordOpen = false
    this._showAllowAccessWithoutPassword = false
  }

  onChangeAllowAccessWithoutPassword (allowAccessWithoutPassword) {
    this._changePasswordAllowed = !allowAccessWithoutPassword
    this._changePasswordOpen = false
  }

  // return a deep copy of this object
  copy () {
    return _.cloneDeep(this)
  }

  map (data = {}) {
    _.extend(this, data)

    if (data.timeZone) {
      this._timeZone = {
        id: data.timeZone,
        text: data.timeZone
      }
    }

    if (!data.sendEmailNotification) {
      data.deleteMessageAfterEmailingNotification = false
    }

    if (data.operatorExtension) {
      this._operatorExtension = {
        id: data.operatorExtension,
        text: data.operatorExtensionName
      }
    }

    this._password = this._confirmPassword = ''
    this._changePasswordAllowed = !data.allowAccessWithoutPassword
  }

  build (payload = {}) {
    _.extend(payload, this)

    if (this._operatorExtension) {
      payload.operatorExtension = this._operatorExtension.id
    }

    if (this._password) {
      payload.password = this._password
    }

    if (!payload.sendEmailNotification) {
      payload.deleteMessageAfterEmailingNotification = false
    }

    if (this._timeZone) {
      payload.timeZone = this._timeZone.id
    }

    return payload
  }
}
