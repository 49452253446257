import _module from 'module.js'

_module.directive('jvSearch', function () {
  return {
    restrict: 'A',
    link: function (scope, element) {
      element.wrap('<div class="jv-search-wrapper"></div>')
      element.before('<span class="icon icon-search"></span>')

      scope.$on('$destroy', () => {
        element.parent().remove()
      })
    }
  }
})
