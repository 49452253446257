import UATS from './fixtures/UATS'

export default ($q, portalConfig) => {
  'ngInject'
  const UATS_BY_ID = UATS.reduce((acc, current) => {
    acc[current.id] = current
    return acc
  }, {})

  const userAgentTypeGetSingleRegex = new RegExp(
    `${portalConfig.userAgentTypeApiUrl}/user-agent-types/([a-zA-Z0-9_.-]+)$`
  )

  const userAgentTypeGetListRegex = new RegExp(
    `${portalConfig.userAgentTypeApiUrl}/user-agent-types$`
  )

  const uatByIdCache = {
    get (typeId) {
      return UATS_BY_ID[typeId]
    }
  }

  const uatListCache = {
    get (query) {
      let items = UATS

      if (query) {
        items = UATS.filter(item => {
          return item.fullName.toLowerCase().indexOf(query.toLowerCase()) >= 0
        })
      }

      return {
        page: 0,
        pageSize: 50,
        matchesTotal: items.length,
        items: items
      }
    }
  }

  return {
    request: function (requestConfig) {
      if (requestConfig.method === 'GET') {
        if (userAgentTypeGetSingleRegex.test(requestConfig.url)) {
          const match = userAgentTypeGetSingleRegex.exec(requestConfig.url)
          requestConfig.url = match[1]
          requestConfig.cache = uatByIdCache
        } else if (userAgentTypeGetListRegex.test(requestConfig.url)) {
          requestConfig.url = requestConfig.params.q || ''
          requestConfig.params = {}
          requestConfig.cache = uatListCache
        }
      }

      return requestConfig
    }
  }
}
