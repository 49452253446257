import _module from 'module.js'

export default class UserSyncManagementService {
  constructor(
    Restangular,
    portalConfig,
    globalState,
  )
  {
    'ngInject'
    this.globalState = globalState
    this.api = Restangular.withConfig(restangularConfig => {
      restangularConfig.setBaseUrl(portalConfig.userSyncManagementApiUrl)
    })
  }

  getSynchronizations()
  {
    return this.api
      .one('user-synchronizations')
      .get({
        organizationId: this.globalState.selectedPbxUUID
      })
      .then(data => data.plain())
  }

  createSynchronization()
  {
    return this.api
      .one('user-synchronizations')
      .post(null, {
        organizationId: this.globalState.selectedPbxUUID
      })
      .then(data => data.plain())
  }

  deleteSynchronization(syncId)
  {
    return this.api
      .one('user-synchronizations')
      .one(syncId)
      .remove()
  }

  getIssues()
  {
    return this.api
      .one('issues')
      .get({
        organizationId: this.globalState.selectedPbxUUID
      })
      .then(data => data)
  }

}

_module.service('userSyncManagementService', UserSyncManagementService)
