import _ from 'lodash'
import _module from 'module.js'
import ListControllerBase from 'classes/listController.class'
import jrnParser from 'classes/jrnParser'

let _jrn, _PagingRealm

export default class PagingProfilesController extends ListControllerBase {
  constructor (
    $state,
    $stateParams,
    $location,
    $analytics,
    PagingProfile,
    PagingRealm,
    gettextCatalog,
    infiniteLoader,
    navigation,
    globalState,
    portalUtil
  ) {
    'ngInject'
    super(
      gettextCatalog,
      $analytics,
      $location,
      portalUtil,
      globalState,
      navigation,
      $state,
      $stateParams
    )

    this.gettextCatalog = gettextCatalog
    _jrn = jrnParser
    _PagingRealm = PagingRealm

    this.title = this.gettextCatalog.getString('Paging Profiles')
    this.headers = [
      {
        width: 25,
        name: this.gettextCatalog.getString('Name'),
        sortBy: 'name',
        asc: true
      },
      {
        width: 25,
        name: this.gettextCatalog.getString('Realm'),
        sortBy: 'pagingRealmName',
        asc: null
      },
      {
        width: 40
      },
      {
        width: 10,
        name: ''
      }
    ]
    this.buttonText = this.gettextCatalog.getString('Add Profile')
    this.loader = infiniteLoader(PagingProfile)
  }

  delete (event, item) {
    let name = this.gettextCatalog.getString('profile') + ': ' + item.name
    this.loader.entityApi.startDelete(event, item, this.data, name)
  }

  itemSelected (item) {
    super.itemSelected(
      item,
      'root.nav.pbx.devices.profiles.view.general',
      'profileId'
    )
  }

  onUpdate (data) {
    if (data.pagingRealm) {
      let id = _jrn.parse(data.pagingRealm).getResources()[1]
      let realm = new _PagingRealm(id)

      realm.get().then(result => {
        data.pagingRealmName = result.name
      })
    } else {
      data.pagingRealmName = ''
    }
  }

  onNew (data) {
    if (data.pagingRealm) {
      let id = _jrn.parse(data.pagingRealm).getResources()[1]
      let realm = new _PagingRealm(id)

      realm.get().then(result => {
        this.data.items[0].pagingRealmName = result.name
      })
    }
  }
}

_module.controller('PagingProfilesController', PagingProfilesController)
