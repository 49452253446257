import networkPermissionsTemplate from './networkPermissionsTemplate.html'
import _module from 'module.js'

_module.directive('networkPermissions', function () {
  return {
    restrict: 'EA',
    require: ['networkPermissions', '^networkRowEditor'],
    template: networkPermissionsTemplate,
    scope: {
      permissions: '=',
      editing: '='
    },
    controllerAs: 'networkPermissions',
    bindToController: true,
    controller: function () {
      'ngInject'
      this.editing = false

      this.DEVICE_REGISTRATION = 'DEVICE_REGISTRATION';
      this.DEVICE_PROVISIONING = 'DEVICE_PROVISIONING';
      this.DEVICE_DIRECTMEDIA = 'DEVICE_DIRECTMEDIA';
      this.ADMIN_PORTAL = 'ADMIN_PORTAL';

      if (!this.permissions) {
        this.permissions = []
      }

      this.changePermissions = function (permission) {
        if (this.permissions.includes(permission)) {
          this.permissions = this.permissions.filter((value) => value !== permission);
        } else {
          this.permissions.push(permission);
        }
      }

    }
  }
})
