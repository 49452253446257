/**
 * @ngdoc function
 * @name platformApp.factory:Group
 * @description
 * # Group
 * Service of the platformApp
 */
import _module from 'module.js'
import BaseES6 from 'factories/baseES6'
import jrnParser from 'classes/jrnParser'

let _state, _stateParams, _portalApi, _jrn, _globalState

export default class Group extends BaseES6 {
  constructor (id, group, pbxId, gettextCatalog) {
    'ngInject'
    super(id || _stateParams.groupId, pbxId || _globalState.selectedPbxUUID)
    this.gettextCatalog = gettextCatalog

    if (id === undefined) {
      throw new Error(this.gettextCatalog.getString('Missing id for new Group'))
    }

    this._members = []
    this._memberOfUserGroups = []

    if (group) {
      this._map(group)
    }
  }

  getApi () {
    if (!this._api) this._api = _portalApi.identity.pbxs(this._pbxId).userGroups

    return this._api
  }

  getPath () {
    if (!this._path) { this._path = _portalApi.identity.pbxs(this._pbxId).userGroups(this._id) }

    return this._path
  }

  getReferencesPath () {
    if (!this._referencesPath) {
      this._referencesPath = _portalApi.identity
        .pbxs(this._pbxId)
        .userGroups(this._id)
        .one('references')
    }
    return this._referencesPath
  }

  map (data) {
    if (data._members) {
      this._members = data._members
    } else if (data.members) {
      this._members = data.members.map(function (member) {
        let resolvedPath
        if (_jrn.parse(member.jrn).getResources()[0] === 'user-group') {
          resolvedPath = _state.href('root.nav.pbx.groups.view.general', {
            groupId: _jrn.parse(member.jrn).getResources()[1]
          })
        } else {
          resolvedPath = _state.href('root.nav.pbx.users.view.general', {
            userId: _jrn.parse(member.jrn).getResources()[1]
          })
        }
        return {
          id: member.jrn,
          text: member.name,
          path: resolvedPath
        }
      })
    }
    if (data._memberOfUserGroups) {
      this._memberOfUserGroups = data._memberOfUserGroups
    } else if (data.memberOfUserGroups) {
      this._memberOfUserGroups = data.memberOfUserGroups.map(function (group) {
        return {
          id: group.jrn,
          text: group.name,
          path: _state.href('root.nav.pbx.groups.view.general', {
            groupId: _jrn.parse(group.jrn).getResources()[1]
          })
        }
      })
    }

    this.isNew = data.isNew || false
  }

  build (payload) {
    payload.members = this._members.map(function (member) {
      return member.id
    })
    payload.memberOfUserGroups = this._memberOfUserGroups.map(function (group) {
      return group.id
    })

    return payload
  }

  mapListItem (group) {
    return {
      id: group.jrn,
      text: group.name
    }
  }

  cleanOldInfo (newInfo, oldInfo) {
    _.remove(newInfo.members, i => {
      return i.jrn === oldInfo.jrn
    })
    _.remove(newInfo.memberOfUserGroups, i => {
      return i.jrn === oldInfo.jrn
    })
    return newInfo
  }

  getClass () {
    return 'Group'
  }

  static factory ($state, $stateParams, portalApi, gettextCatalog, globalState) {
    'ngInject'
    _state = $state
    _stateParams = $stateParams
    _portalApi = portalApi
    _jrn = jrnParser
    _globalState = globalState

    return (id, group, pbxId = null) => {
      return new Group(id, group, pbxId, gettextCatalog)
    }
  }
}

_module.factory('Group', Group.factory)
