/**
 * @ngdoc function
 * @name platformApp.factory:lineService
 * @description
 * # lineService
 * Controller of the platformApp
 */
import _module from 'module.js'
import _ from 'lodash'
import ExtensionWithVoicemailBoxES6
  from 'factories/extensionWithVoicemailBoxES6'

let _stateParams, _portalApi, _portalUtil, _globalState

export default class Line extends ExtensionWithVoicemailBoxES6 {
  constructor (id, line, gettextCatalog) {
    'ngInject'
    super(_stateParams.extId || id)
    this.gettextCatalog = gettextCatalog

    if (this._id === undefined) {
      throw new Error(this.gettextCatalog.getString('Missing id for new Line'))
    }

    this.dialPlan = {}
    this.ringback = 'RINGING'

    if (line) {
      this._map(line)
    }
  }

  getApi () {
    if (!this._api) { this._api = _portalApi.extension.pbxs(_globalState.selectedPbxUUID).lines }

    return this._api
  }

  getPath () {
    if (!this._path) {
      this._path = _portalApi.extension
        .pbxs(_globalState.selectedPbxUUID)
        .lines(this._id)
    }

    return this._path
  }

  getReferencesPath () {
    if (!this._referencesPath) {
      this._referencesPath = _portalApi.extension
        .pbxs(_globalState.selectedPbxUUID)
        .lines(this._id)
        .one('references')
    }
    return this._referencesPath
  }

  map (data) {
    this.language = data.language || ''
    this.extensionRegion = data.extensionRegion ? data.extensionRegion : ''
    this.voicemailBox._showAllowAccessWithoutPassword = true
    this.voicemailBox._voicemailAccessPermissions = []
    if (data.linePermissions) {
      this.voicemailBox._voicemailAccessPermissions = data.linePermissions.map(
        target => {
          return {
            id: target.identity,
            text: _portalUtil.getNameFromUserOrGroup(target),
            path: _portalUtil.resolveIdentityPath(target)
          }
        }
      )
    }

    if (this.dialPlan) {
      if (this.dialPlan.finalStep) {
        this.dialPlan.finalStep.externalNumber = this.dialPlan.finalStep
          .externalNumber
          ? this.dialPlan.finalStep.externalNumber
          : ''
      }
      if (this.dialPlan.ringSteps) {
        this.dialPlan.ringSteps.map(step => {
          step.ringItems.map(item => {
            item.externalNumber = item.externalNumber ? item.externalNumber : ''
          })
        })
      }

      if (this.dialPlan.unavailableStep) {
        this.dialPlan.unavailableStep.externalNumber = this.dialPlan
          .unavailableStep.externalNumber
          ? this.dialPlan.unavailableStep.externalNumber
          : ''
      }
    }
  }

  build (payload) {
    payload.extensionNumber = this.extensionNumber || null
    payload.language = this.language || null
    payload.linePermissions = this.voicemailBox._voicemailAccessPermissions.map(
      target => {
        return {
          identity: target.id,
          permissions: ['ACCESS_VOICEMAIL']
        }
      }
    )

    // clean up line dial plan
    payload.dialPlan = _.cloneDeep(this.dialPlan)
    if (payload.dialPlan.finalStep) {
      payload.dialPlan.finalStep.externalNumber = payload.dialPlan.finalStep
        .externalNumber
        ? payload.dialPlan.finalStep.externalNumber
        : null
    }
    if (payload.dialPlan.unavailableStep) {
      payload.dialPlan.unavailableStep.externalNumber = payload.dialPlan
        .unavailableStep.externalNumber
        ? this.dialPlan.unavailableStep.externalNumber
        : null
    }

    payload.dialPlan.ringSteps = _.filter(
      payload.dialPlan.ringSteps,
      ringStep => {
        ringStep.ringItems = _.filter(ringStep.ringItems, ringItem => {
          ringItem.externalNumber = ringItem.externalNumber
            ? ringItem.externalNumber
            : null
          if (ringItem.destinationType === 'LINE') {
            return ringItem.line
          }
          if (ringItem.destinationType === 'EXTERNAL_NUMBER') {
            if (
              ringItem.externalNumber.startsWith('+1') &&
              ringItem.externalNumber.length === 9
            ) {
              let externalNumber = ringItem.externalNumber
              externalNumber = externalNumber.replace('+1', '')
              ringItem.externalNumber =
                '+1' + globalState.selectedPbx.localAreaCode + externalNumber
            }
            return ringItem.externalNumber
          }
          return false
        })
        return ringStep.ringItems.length
      }
    )

    return payload
  }

  getClass () {
    return 'Line'
  }

  static factory (
    $stateParams,
    portalApi,
    gettextCatalog,
    portalUtil,
    globalState
  ) {
    'ngInject'
    _stateParams = $stateParams
    _portalApi = portalApi
    _portalUtil = portalUtil
    _globalState = globalState

    return (id, line) => {
      return new Line(id, line, gettextCatalog)
    }
  }
}

_module.factory('Line', Line.factory)
