import musicListsTemplate from './musicListsTemplate.html'
import musicListsController from './musicListsController.js'
import module from 'module'

module.component('musicLists', {
  template: musicListsTemplate,
  controllerAs: 'musicLists',
  require: {
    ngModel: 'ngModel'
  },
  bindings: {
    selectedList: '=ngModel',
    selectableTypes: '<'
  },
  controller: musicListsController
})
