import _ from 'lodash'
import jrnParser from 'classes/jrnParser'
import { entityCache } from 'services/portalApi'

const ignoredAttrs = [
  '_path',
  '_api',
  '_payload',
  'gettextCatalog',
  'references',
  '_referencesPath',
  'portalUtil',
  'normalizationPopup'
]

export default class BaseES6 {
  constructor (id = null, pbxId = null) {
    this._id = id
    this._pbxId = pbxId
    this.cacheEnabled = false
  }

  extend (item) {
    _.extend(this, item)
    return this
  }

  copy () {
    const copy = _.cloneDeep(this)
    if (copy._path) {
      delete copy._path
    }
    return copy
  }

  _map (data) {
    _.extend(this, data)

    if (this.map) {
      this.map(data)
    }
  }

  _build (payload) {
    _.extend(payload, _.omit(this, ignoredAttrs))

    if (this.build) {
      payload = this.build(payload)
    }

    return payload
  }

  customParams () {
    return {}
  }

  get (options) {
    options = options || {}
    if (!this.getPath) {
      throw new Error('Function: "getPath" is not set!')
    }

    const pathObj = this.getPath()

    // 1st approach: to use server data as authority unless the server doesn't return data yet.
    // With this approach, there is no need to keep the data returned from update(PUT) in cache
    // as we always use the data from server.

    // Since we are using Promise here, the caller needs to call $rootScope.$apply().
    // http://rahulgaba.com/front-end/2016/09/25/Why-When-do-I-need-scope-apply-in-AngularJS.html

    return new Promise(async (resolve, reject) => {
      try {
        const result = await pathObj.get(options)
        this._map(result.plain())
        resolve(this)
      } catch (err) {
        if (err.status !== 404 || !_.isEmpty(options) || !this.cacheEnabled) {
          return reject(err)
        }
        const data = entityCache
          ? entityCache.get(pathObj.getRequestedUrl())
          : null
        if (!data) {
          return reject(err)
        }
        this._map(data)
        resolve(this)
      }
      
    })

    // 2nd approach: to use the cache data as authority
    // With this approach, we need to keep the data returned from update(PUT) in cache.
    // return new Promise ((resolve, reject ) => {
    //   if (entityCache && entityCache.get(pathObj.getRequestedUrl()) && this.cacheEnabled) {
    //     console.log("cachedData")
    //     this._map(entityCache.get(pathObj.getRequestedUrl()));
    //     resolve(this)
    //   } else {
    //     console.log("no cacheData")
    //     pathObj.get(options).then( (data) => {
    //       this._map(data.plain());
    //       resolve (this);
    //     }).catch((err) => {
    //       reject(err);
    //     });
    //   }
    // })
  }

  getListResult (params = {}) {
    if (!this.getApi) {
      throw new Error('Function: "getApi" is not set!')
    }

    return this.getApi()
      .call(this)
      .get(params)
  }

  async getList (params) {
    const { items } = await this.getListResult(params)
    return items.map(this.mapListItem.bind(this))
  }

  getReferencesApi () {
    return this.getReferencesPath()
  }

  getId () {
    return this._id
  }

  async save () {
    if (!this.getPath) {
      throw new Error('Function: "getPath" is not set!')
    }

    const pathObj = this.getPath()
    const payload = this._build(pathObj)

    const data = await payload.post()
    if (data) {
      this._map(data.plain())
      if (data.jrn) {
        let entityId = jrnParser.parse(data.jrn).getResources()[1]
        // handling pbx id after a pbx is created
        if (entityId === 'default') {
          entityId = jrnParser.parse(data.jrn).getAccount()
        }

        entityCache.put(
          pathObj.getRequestedUrl() + '/' + entityId,
          data.plain()
        )
      }
    }

    return data
  }

  async update (deactivated = false) {
    if (!this.getPath) {
      throw new Error('Function: "getPath" is not set!')
    }

    const pathObj = this.getPath()
    const payload = this._build(this.getPath())

    // leave reference to _payload not set to payload here that cause a circular dep and
    // when you try and update the object a second time it errors
    const data = deactivated ? await payload.put({allowAutomaticDeletion: true}) : await payload.put()

    if (data) {
      this._map(data.plain())
      if (data.jrn) {
        const entityId = jrnParser.parse(data.jrn).getResources()[1]
        entityCache.put(
          pathObj.getRequestedUrl() + '/' + entityId,
          data.plain()
        )
      }
    }
    return data
  }

  delete (params) {
    if (!this.getPath) {
      throw new Error('Function: "getPath" is not set!')
    }

    const payload = this._build(this.getPath())
    return payload.remove(params)
  }

  deleteCustomerData () {
    if (!this.getPath) {
      throw new Error('Function: "getPath" is not set!')
    }
    return this._build(this.getPath().one('customer-data')).remove()
  }
}
