import _module from 'module.js'
import BaseES6 from 'factories/baseES6'

let _globalState, _portalApi

export default class UserAgentConfigurationSecurity extends BaseES6 {
  constructor (id, userAgentConfigurationSecurity, pbxId) {
    'ngInject'
    super(id, pbxId || _globalState.selectedPbxUUID)

    if (userAgentConfigurationSecurity) {
      this._map(userAgentConfigurationSecurity)
    }
  }

  getApi () {
    if (!this._api) {
      this._api = _portalApi.userAgent.pbxs(
        this._pbxId
      ).userAgentConfigurationSecurity
    }

    return this._api
  }

  getPath () {
    if (!this._path) {
      this._path = _portalApi.userAgent
        .pbxs(this._pbxId)
        .userAgentConfigurationSecurity(this._id)
    }

    return this._path
  }

  getClass () {
    return 'UserAgentConfigurationSecurity'
  }

  static factory (globalState, portalApi) {
    'ngInject'
    _globalState = globalState
    _portalApi = portalApi

    return (id, billingGroup, pbxId = null) => {
      return new UserAgentConfigurationSecurity(id, billingGroup, pbxId)
    }
  }
}

_module.factory(
  'UserAgentConfigurationSecurity',
  UserAgentConfigurationSecurity.factory
)
