/**
 * @ngdoc function
 * @name platformApp.controller:CorporateDirectoryController
 * @description
 * # CorporateDirectoryController
 * Controller of the platformApp
 */
import _module from 'module.js'
import _ from 'lodash'
import BaseController from 'classes/baseController'

export default class CorporateDirectoryController extends BaseController {
  constructor (
    $state,
    $stateParams,
    portalApi,
    CorporateDirectory,
    gettextCatalog,
    portalUtil,
    errorService,
    globalState,
    navigation,
    $location,
    $window,
    userService,
    $timeout,
    scheduleConference,
    $analytics,
    $rootScope
  ) {
    'ngInject'
    super(
      $state,
      $stateParams,
      CorporateDirectory,
      portalApi,
      userService,
      gettextCatalog,
      portalUtil,
      errorService,
      globalState,
      navigation,
      'root.nav.pbx.corp-directories.list',
      'directoryId'
    )

    let keys = {
      tls: 'TLS',
      poly: 'Polycom',
      dn: 'DN',
      searchBaseText: 'Ex. "ou=Users,dc=example,dc=com"',
      searchClauses: 'Ex. "(objectclass=person)".',
      commonValues: '"cn" or "displayName".',
      givenName: '"givenName".',
      sn: '"sn".',
      number: 'Ex. "telephoneNumber, mobile, extension".'
    }

    this.translations = {
      useTls: gettextCatalog.getString('Use {{:: tls }}', keys),
      bindDn: gettextCatalog.getString('Bind {{:: dn }}', keys),
      searchBase: gettextCatalog.getString('Search base'),
      searchBaseHelp: gettextCatalog.getString(
        'Base path for searches. {{:: searchBaseText  }}',
        keys
      ),
      searchClauses: gettextCatalog.getString(
        'Clause to be added to all searches. {{:: searchClauses }}',
        keys
      ),
      displayName: gettextCatalog.getString(
        'Common values are {{:: commonValues }}',
        keys
      ),
      firstName: gettextCatalog.getString('Usually {{:: givenName }}', keys),
      lastName: gettextCatalog.getString('Usually {{:: sn }}', keys),
      phoneNumber: gettextCatalog.getString(
        'Comma separated list of phone number attributes. {{:: number }}',
        keys
      ),
      searchScope: gettextCatalog.getString('Search scope'),
      searchScopeHelp: gettextCatalog.getString(
        'Depth of search. Should all sub entries (subtrees) be searched or just the entries in the search base.'
      )
    }

    this.data
      .get()
      .then(data => {
        this.itemName = data.name
        this.ready = true
        this.initial = false
        $timeout(() => {
          $rootScope.$apply()
        })
      })
      .catch(error => {
        var errorMessage = this.errorService.getErrorMessage(error)
        this.portalUtil.showErrorAlert(
          _.isEmpty(errorMessage)
            ? this.gettextCatalog.getString(
                'Data retrieval failed, please retry.'
              )
            : errorMessage
        )
        this.ready = true
        this.initial = false
        $timeout(() => {
          $rootScope.$apply()
        })
        throw error
      })
  }

  save (form) {
    this.ready = false
    this.data.name = this.itemName
    return this.data
      .update()
      .then(() => {
        form.$setPristine()
        this.ready = true
      })
      .catch(error => {
        this.portalUtil.showErrorAlert(
          this.gettextCatalog.getString('Save failed, please retry.')
        )
        this.ready = true
        throw error
      })
  }
}
_module.controller('CorporateDirectoryCtrl', CorporateDirectoryController)
