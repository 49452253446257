import _module from 'module.js'
_module.directive('cancel', $document => {
  'ngInject'
  return {
    priority: 10,
    restrict: 'A',
    scope: {
      cancel: '&ngClick'
    },
    link: function (scope) {
      this.cancelOnEsc = event => {
        if (event.keyCode === 27) {
          scope.$apply(() => {
            scope.cancel({
              $event: event
            })
          })
        }
      }

      $document.bind('keydown', this.cancelOnEsc)

      scope.$on('$destroy', () => {
        $document.unbind('keydown', this.cancelOnEsc)
      })
    }
  }
})
