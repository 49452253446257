import is from 'is'
import _module from 'module.js'

let _Restangular, _CacheFactory, _portalConfig
const SECOND = 1000
const MINUTE = 1 * 60 * SECOND

export let entityCache

export class PortalApi {
  constructor() {
    'ngInject'
    let apiConfig = _Restangular.withConfig(restangularConfig => {
      restangularConfig.setBaseUrl(_portalConfig.apiBase)

      entityCache = _CacheFactory.createCache('portal.entityCache', {
        capacity: 100,
        maxAge: 15 * SECOND,
        deleteOnExpire: 'aggressive',
        storageMode: 'localStorage',
        storagePrefix: 'jive.'
      })

      let listCache = _CacheFactory.createCache('portal.listCache', {
        capacity: 1000,
        maxAge: 1 * MINUTE,
        deleteOnExpire: 'aggressive'
      })

      restangularConfig.addFullRequestInterceptor(
        (element, operation, what, url, headers, params, httpConfig) => {
          if (operation.toLowerCase() === 'get') {
            if (!element.id || (element.id && params.cache)) {
              return {
                httpConfig: {
                  cache: listCache
                }
              }
            }
          } else {
            // when not 'GET' options, clear the cache
            _CacheFactory.clearAll()
          }
        }
      )
    })

    let ambassadorApiConfigIntegrations = _Restangular.withConfig(
      restangularConfig => {
        restangularConfig.setBaseUrl(_portalConfig.ambassadorApiIntegrations)
      }
    )

    let userMappingApiConfigIntegrations = _Restangular.withConfig(
      restangularConfig => {
        restangularConfig.setBaseUrl(_portalConfig.userMappingApiIntegrations)
      }
    )

    let orderingApiConfig = _Restangular.withConfig(
      restangularConfig => {
        restangularConfig.setBaseUrl(_portalConfig.orderingApiUrl)
      }
    )

    let numbersApiConfig = _Restangular.withConfig(
      restangularConfig => {
        restangularConfig.setBaseUrl(_portalConfig.numbersApiUrl)
      }
    )

    let phoneNumbersSettingsApiConfig = _Restangular.withConfig(
      restangularConfig => {
        restangularConfig.setBaseUrl(_portalConfig.phoneNumbersSettingsApiUrl)
      }
    )

    let ambassadorProviderStatus = is.include(
      _portalConfig.ambassadorApiIntegrations,
      'staging'
    )
      ? 'STAGING'
      : is.include(_portalConfig.ambassadorApiIntegrations, 'dev')
        ? 'STAGING'
        : 'PROD'

    let importExportConfig = _Restangular.withConfig(restangularConfig => {
      restangularConfig.setBaseUrl(_portalConfig.importExportBase)
    })

    let partnerConfig = _Restangular.withConfig(restangularConfig => {
      restangularConfig.setBaseUrl(_portalConfig.partnerBase)
    })

    let contactCenterConfigurationConfig = _Restangular.withConfig(restangularConfig => {
      restangularConfig.setBaseUrl(_portalConfig.contactCenterConfigurationApiUrl)
    })

    let contactCenterAuthorityConfig = _Restangular.withConfig(restangularConfig => {
      restangularConfig.setBaseUrl(_portalConfig.contactCenterAuthorityApiUrl)
    })

    let locationsBffConfig = _Restangular.withConfig(restangularConfig => {
      restangularConfig.setBaseUrl(_portalConfig.locationsBffApiUrl)
    })

    let extensionsApiBase = apiConfig.all('extension').all('v1')
    let extensionApi = {
      pbxs: pbxId => {
        let pbxsBase = extensionsApiBase.one('pbxs', pbxId)
        return {
          directories: this.endpoint(
            apiConfig.service('directories', pbxsBase)
          ),
          extensions: this.endpoint(apiConfig.service('extensions', pbxsBase)),
          callQueues: this.endpoint(apiConfig.service('call-queues', pbxsBase)),
          conferenceRooms: this.endpoint(
            apiConfig.service('conference-rooms', pbxsBase)
          ),
          dialPlans: this.endpoint(apiConfig.service('dial-plans', pbxsBase)),
          lines: this.endpoint(apiConfig.service('lines', pbxsBase)),
          ringGroups: this.endpoint(apiConfig.service('ring-groups', pbxsBase)),
          sharedVoicemailBoxes: this.endpoint(
            apiConfig.service('shared-voicemail-boxes', pbxsBase)
          ),
          voicemailBoxes: this.endpoint(
            apiConfig.service('voicemail-boxes', pbxsBase)
          ),
          extensionMappings: this.endpoint(
            apiConfig.service('extension-mappings', pbxsBase)
          ),
          virtualFaxMachines: this.endpoint(
            apiConfig.service('virtual-fax-machines', pbxsBase)
          ),
          uploadGreeting: this.endpoint(
            apiConfig.service('vmgreeting', pbxsBase)
          )
        }
      }
    }

    let languageApi = apiConfig.one('language').one('v1')

    let timeZoneApi = apiConfig.one('time-zones').one('v1')

    let i18nApiBase = apiConfig.all('i18n').all('v1')
    let i18nApi = {
      languages: this.endpoint(apiConfig.service('languages', i18nApiBase)),
      regions: this.endpoint(apiConfig.service('regions', i18nApiBase))
    }

    let partnerBffApiBase = apiConfig.all('partner').all('v1')
    let partnerBffApi = {
      partners: this.endpoint(apiConfig.service('partners', partnerBffApiBase))
    }

    let partnerApiBase = partnerConfig.all('partner')
    let partnerApi = {
      partners: this.endpoint(partnerConfig.service('partners', partnerApiBase))
    }

    let toolApiBase = importExportConfig.all('import-export').all('v1')
    let toolApi = {
      pbxs: pbxId => {
        let pbxsBase = toolApiBase.one('pbxs', pbxId)
        return {
          users: this.endpoint(importExportConfig.service('users', pbxsBase)),
          usersDelete: this.endpoint(
            importExportConfig.service('users/delete', pbxsBase)
          ),
          userAgents: this.endpoint(
            importExportConfig.service('user-agents', pbxsBase)
          ),
          userAgentsDelete: this.endpoint(
            importExportConfig.service('user-agents/delete', pbxsBase)
          ),
          userAgentConfigurations: this.endpoint(
            importExportConfig.service('user-agent-configurations', pbxsBase)
          ),
          userAgentConfigurationsDelete: this.endpoint(
            importExportConfig.service(
              'user-agent-configurations/delete',
              pbxsBase
            )
          ),
          phoneNumbers: this.endpoint(
            importExportConfig.service('dids', pbxsBase)
          ),
          lines: this.endpoint(importExportConfig.service('lines', pbxsBase)),
          linesDelete: this.endpoint(
            importExportConfig.service('lines/delete', pbxsBase)
          ),
          userFulfillmentWs: this.endpoint(
            importExportConfig.service('fulfillment/user', pbxsBase)
          ),
          ringGroups: this.endpoint(
            importExportConfig.service('ring-groups', pbxsBase)
          ),
          ringGroupsDelete: this.endpoint(
            importExportConfig.service('ring-groups/delete', pbxsBase)
          ),
          extensions: this.endpoint(
            importExportConfig.service('extensions', pbxsBase)
          ),
          extensionsDelete: this.endpoint(
            importExportConfig.service('extensions/delete', pbxsBase)
          ),
          masterPhoneNumbers: this.endpoint(
            importExportConfig.service('master-dids', pbxsBase)
          ),
          organization: this.endpoint(
            importExportConfig.service('organization', pbxsBase)
          ),
          organizations: this.endpoint(
            importExportConfig.service('pbxs', toolApiBase)
          ),
          organizationsState: this.endpoint(
            importExportConfig.service('pbxs/states', toolApiBase)
          ),
          directoryPdf: this.endpoint(
            importExportConfig.service('organization/pdf', pbxsBase)
          ),
          billingGroupDeviceExport: this.endpoint(
            importExportConfig.service('billing-groups/devices', pbxsBase)
          ),
          billingGroupDidExport: this.endpoint(
            importExportConfig.service('billing-groups/dids', pbxsBase)
          ),
          sites: this.endpoint(importExportConfig.service('sites', pbxsBase)),
          e911SitesExport: this.endpoint(
            importExportConfig.service('e911/sites', pbxsBase)
          ),
          e911DevicesExport: this.endpoint(
            importExportConfig.service('e911/devices', pbxsBase)
          )
        }
      }
    }

    let billingApiBase = apiConfig.all('billing').all('v1')
    let billingApi = {
      pbxs: pbxId => {
        let pbxsBase = billingApiBase.one('pbxs', pbxId)
        return {
          summary: this.endpoint(apiConfig.service('summary', pbxsBase)),
          details: this.endpoint(apiConfig.service('details', pbxsBase))
        }
      }
    }

    let orderingApi = {
      setup: pbxId => orderingApiConfig.one('setup', pbxId),
      pbxs: pbxId => {
        return {
          guidedSetupConfig: this.endpoint(
            orderingApiConfig.service('guided-setup-config', orderingApiConfig.one('setup', pbxId))
          )
        }
      }
    }

    let numbersApi = {
      pbxs: pbxId => {
        let accountsBase = numbersApiConfig.one('accounts', pbxId)
        return {
          pbxSmsCampaignDetails: this.endpoint(
            numbersApiConfig.service('pbx-campaign-details', accountsBase)
          ),
          did: didId => {
            let phoneNumbersApiBase = accountsBase.one('phone-numbers', didId)
            return {
              details: this.endpoint(
                numbersApiConfig.service('details', phoneNumbersApiBase)
              ),
            }
          }
        }
      }
    }

    let phoneNumbersSettingsApi = {
      pbxs: pbxId => {
        let pbxBase = phoneNumbersSettingsApiConfig.one('pbxs', pbxId)
        return {
          portOutPasscode: this.endpoint(
            phoneNumbersSettingsApiConfig.service('port-out-passcode', pbxBase)
          ),
        }
      }
    }

    let identityApiBase = apiConfig.all('identity').all('v1')
    let identityApi = {
      principals: this.endpoint(
        apiConfig.service('principals', identityApiBase)
      ),
      pbxs: pbxId => {
        let pbxsBase = identityApiBase.one('pbxs', pbxId)
        return {
          userGroups: this.endpoint(apiConfig.service('user-groups', pbxsBase)),
          users: this.endpoint(apiConfig.service('users', pbxsBase))
        }
      }
    }

    let musicApi = {
      api: () => {
        let pbxsBase = apiConfig.all('media').all('v1')
        return {
          default: this.endpoint(
            apiConfig.service('built-in-hold-music-playlist/default', pbxsBase)
          ),
          silent: this.endpoint(
            apiConfig.service('built-in-hold-music-playlist/silent', pbxsBase)
          ),
          builtIn: this.endpoint(
            apiConfig.service('built-in-hold-music-files', pbxsBase)
          )
        }
      }
    }

    let mediaApiBase = apiConfig.all('media').all('v1')
    let mediaApi = {
      pbxs: pbxId => {
        let pbxsBase = mediaApiBase.one('pbxs', pbxId)
        return {
          holdMusicFiles: this.endpoint(
            apiConfig.service('hold-music-files', pbxsBase)
          ),
          holdMusicPlaylists: this.endpoint(
            apiConfig.service('hold-music-playlists', pbxsBase)
          ),
          soundClips: this.endpoint(apiConfig.service('sound-clips', pbxsBase))
        }
      }
    }

    let mobilityApiBase = apiConfig.all('mobility').all('v1')
    let mobilityApi = {
      licenses: this.endpoint(apiConfig.service('licenses', mobilityApiBase))
    }

    let externalStorageBase = apiConfig.all('external-storage').all('v1')
    let externalStorageApi = {
      validateAWSCredential: this.endpoint(
        apiConfig.service('aws-validate', externalStorageBase)
      )
    }

    let pbxApiBase = apiConfig.all('pbx').all('v1')
    let pbxApi = {
      pbxs: this.endpoint(apiConfig.service('pbxs', pbxApiBase))
    }

    let billingGroupsApi = {
      pbxs: pbxId => {
        let pbxsBase = pbxApiBase.one('pbxs', pbxId)
        return {
          billingGroups: this.endpoint(
            apiConfig.service('billing-groups', pbxsBase)
          )
        }
      }
    }

    let sitesApi = {
      pbxs: pbxId => {
        let pbxsBase = pbxApiBase.one('pbxs', pbxId)
        return {
          sites: this.endpoint(apiConfig.service('sites', pbxsBase))
        }
      }
    }

    let locationsApi = {
      accounts: lmiAccountKey => {
        let locationsBffBase = locationsBffConfig.one('accounts', lmiAccountKey)
        return {
          emergencySettings: this.endpoint(locationsBffConfig.service('emergency-settings', locationsBffBase)),
          locations: this.endpoint(locationsBffConfig.service('locations', locationsBffBase)),
          featureFlags: this.endpoint(locationsBffConfig.service('feature-flags', locationsBffBase))
        }
      }
    }

    let numberTestingDialplanApi = {
      pbxs: pbxId => {
        let pbxsBase = pbxApiBase.one('pbxs', pbxId)
        return {
          numberTestingDialplan: this.endpoint(apiConfig.service('number-testing-dialplan', pbxsBase))
        }
      }
    }

    let regionInfoApi = {
      pbxs: pbxId => {
        let pbxsBase = pbxApiBase.one('pbxs', pbxId)
        return {
          regionInfo: this.endpoint(apiConfig.service('region-info', pbxsBase))
        }
      }
    }

    let pstnApiBase = apiConfig.all('pstn').all('v1')
    let pstnApi = {
      pbxs: pbxId => {
        let pbxsBase = pstnApiBase.one('pbxs', pbxId)
        return {
          dids: this.endpoint(apiConfig.service('dids', pbxsBase)),
          callFilters: this.endpoint(
            apiConfig.service('call-filters', pbxsBase)
          )
        }
      }
    }

    let scheduleApiBase = apiConfig.all('schedule').all('v1')
    let scheduleApi = {
      pbxs: pbxId => {
        let pbxsBase = scheduleApiBase.one('pbxs', pbxId)
        return {
          schedules: this.endpoint(apiConfig.service('schedules', pbxsBase))
        }
      }
    }

    let userAgentApiBase = apiConfig.all('user-agent').all('v1')
    let userAgentApi = {
      userAgentTypes: this.endpoint(
        apiConfig.service('user-agent-types', userAgentApiBase)
      ),
      pbxs: pbxId => {
        let pbxsBase = userAgentApiBase.one('pbxs', pbxId)
        return {
          corporateDirectories: this.endpoint(
            apiConfig.service('corporate-directories', pbxsBase)
          ),
          pagingProfiles: this.endpoint(
            apiConfig.service('paging-profiles', pbxsBase)
          ),
          pagingRealms: this.endpoint(
            apiConfig.service('paging-realms', pbxsBase)
          ),
          registrationProxies: this.endpoint(
            apiConfig.service('registration-proxies', pbxsBase)
          ),
          userAgents: this.endpoint(apiConfig.service('user-agents', pbxsBase)),
          userAgentConfigurations: this.endpoint(
            apiConfig.service('user-agent-configurations', pbxsBase)
          ),
          deviceStatusList: this.endpoint(
            apiConfig.service('user-agent-configurations/status', pbxsBase)
          ),
          unavailableLines: this.endpoint(
            apiConfig.service('user-agent-configurations/unavailable', pbxsBase)
          ),
          proxy: this.endpoint(apiConfig.service('reg-proxy', pbxsBase)),
          proxies: this.endpoint(
            apiConfig.service('registration-proxies', pbxsBase)
          ),
          outboundProxies: this.endpoint(
            apiConfig.service('outbound-proxies', pbxsBase)
          ),
          userAgentConfigurationSecurity: this.endpoint(
            apiConfig.service('user-agent-configuration-security', pbxsBase)
          ),
          jbcs: this.endpoint(apiConfig.service('jbc-mgmt', pbxsBase))
        }
      }
    }

    let sipApiBase = apiConfig.all('sip').all('v1')
    let sipApi = {
      pbxs: pbxId => {
        let pbxsBase = sipApiBase.one('pbxs', pbxId)
        return {
          sipRegistrations: this.endpoint(
            apiConfig.service('sip-registrations/device', pbxsBase)
          )
        }
      }
    }

    let jiveWebBase = apiConfig.all('jiveweb').all('v1')
    let jiveWebApi = {
      pbxs: pbxId => {
        let pbxsBase = jiveWebBase.one('pbxs', pbxId)
        return {
          lines: this.endpoint(apiConfig.service('lines', pbxsBase))
        }
      }
    }

    let contactCenterConfigurationApi = {
      pbxs: pbxId => {
        let pbxsBase = contactCenterConfigurationConfig.one('organizations', pbxId)
        return {
          chatQueues: this.endpoint(contactCenterConfigurationConfig.service('chat-queues', pbxsBase)),
          userContext: this.endpoint(contactCenterConfigurationConfig.service('user-context', pbxsBase)),
          licenseEligibilities: this.endpoint(contactCenterConfigurationConfig.service('free-trial/license-eligibilities', pbxsBase)),
          enroll: this.endpoint(contactCenterConfigurationConfig.service('free-trial/enroll', pbxsBase)),
          upgrade: this.endpoint(contactCenterConfigurationConfig.service('free-trial/upgrade', pbxsBase))
        }
      }
    }

    let contactCenterAuthorityApi = {
      pbxs: pbxId => {
        let pbxsBase = contactCenterAuthorityConfig.one('organizations', pbxId)
        return {
          userContext: this.endpoint(contactCenterAuthorityConfig.service('user/context', pbxsBase)),
        }
      }
    }

    let customEmergencyNumbersBase = apiConfig
      .all('custom-emergency-numbers')
      .all('v1')
    let customEmergencyNumbersApi = pbxId => {
      return customEmergencyNumbersBase.all('pbxs').all(pbxId)
    }

    let integrationsUsermappingApi = {
      organization: orgId => {
        let organizationApiBase = userMappingApiConfigIntegrations.one(
          'organizations',
          orgId
        )
        return {
          integrations: integrationId => {
            let integrationsApiBase = organizationApiBase.one(
              'integrations',
              integrationId
            )
            return {
              users: this.endpoint(
                userMappingApiConfigIntegrations.service(
                  'users',
                  integrationsApiBase
                )
              ),
              mapping: this.endpoint(
                userMappingApiConfigIntegrations.service(
                  'mapping',
                  integrationsApiBase
                )
              ),
              masteruser: this.endpoint(
                userMappingApiConfigIntegrations.service(
                  'masteruser',
                  integrationsApiBase
                )
              )
            }
          }
        }
      },
      authorization: orgId => {
        let organizationApiBase = ambassadorApiConfigIntegrations.one(orgId)
        return {
          users: userId => {
            let usersApiBase = organizationApiBase.one('users', userId)
            return {
              providers: this.endpoint(
                ambassadorApiConfigIntegrations.service(
                  'providers',
                  usersApiBase
                )
              )
            }
          },
          metadata: () => {
            let metadataApiBase = organizationApiBase.one('metadata')
            return {
              providers: this.endpoint(
                ambassadorApiConfigIntegrations.service(
                  `providers?status=${ambassadorProviderStatus}`,
                  metadataApiBase
                )
              )
            }
          }
        }
      }
    }

    this.extension = extensionApi
    this.identity = identityApi
    this.media = mediaApi
    this.mobility = mobilityApi
    this.pbx = pbxApi
    this.pstn = pstnApi
    this.schedule = scheduleApi
    this.userAgent = userAgentApi
    this.music = musicApi
    this.language = languageApi
    this.sip = sipApi
    this.billing = billingApi
    this.externalStorage = externalStorageApi
    this.partnerBff = partnerBffApi
    this.partner = partnerApi
    this.i18n = i18nApi
    this.tool = toolApi
    this.regionInfo = regionInfoApi
    this.jiveWeb = jiveWebApi
    this.contactCenterConfiguration = contactCenterConfigurationApi
    this.contactCenterAuthority = contactCenterAuthorityApi
    this.customEmergencyNumbers = customEmergencyNumbersApi
    this.billingGroup = billingGroupsApi
    this.userMapping = integrationsUsermappingApi
    this.site = sitesApi
    this.timeZone = timeZoneApi
    this.ordering = orderingApi
    this.numbers = numbersApi
    this.numberTestingDialplan = numberTestingDialplanApi
    this.location = locationsApi
    this.phoneNumbersSettings = phoneNumbersSettingsApi
  }

  endpoint(service) {
    return (...args) => {
      let id = null
      if (args.length === 1) {
        if (typeof args[0] === 'string') {
          id = args[0]
        }
      } else {
        id = args[0]
      }

      if (id !== null && id !== undefined) {
        // console.log("id= " + id.toString())
        return service.one(id)
      } else {
        return service.one()
      }
    }
  }

  static factory(Restangular, CacheFactory, portalConfig) {
    'ngInject'
    _Restangular = Restangular
    _CacheFactory = CacheFactory
    _portalConfig = portalConfig

    return new PortalApi()
  }
}

_module.factory('portalApi', PortalApi.factory)
