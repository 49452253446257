import _ from 'lodash'
import ListControllerBase from 'classes/listController.class'
import jrnParser from 'classes/jrnParser'
import _module from 'module.js'

let _portalApi, _jrn

export default class hardwaresControllerFactory extends ListControllerBase {
  constructor (
    $state,
    $stateParams,
    $location,
    $analytics,
    Hardware,
    gettextCatalog,
    infiniteLoader,
    navigation,
    globalState,
    portalUtil,
    portalApi
  ) {
    'ngInject'
    super(
      gettextCatalog,
      $analytics,
      $location,
      portalUtil,
      globalState,
      navigation,
      $state,
      $stateParams,
      {product:"GO_TO_CONNECT"},
    )

    this.gettextCatalog = gettextCatalog
    _portalApi = portalApi
    _jrn = jrnParser
    this.title = this.gettextCatalog.getString('Hardware')

    this.headers = [
      {
        width: 10,
        name: this.gettextCatalog.getString('MAC Address'),
        sortBy: 'mac_address',
        asc: true
      },
      {
        width: 20,
        name: this.gettextCatalog.getString('Model'),
        sortBy: 'device_model',
        asc: null
      },
      {
        width: 20,
        name: this.gettextCatalog.getString('Device Name'),
        sortBy: 'device_name',
        asc: null
      },
      {
        width: 20,
        name: this.gettextCatalog.getString('Display Name'),
        sortBy: 'device_label',
        asc: null
      },
      {
        width: 20,
        name: this.gettextCatalog.getString('Notes'),
        sortBy: 'location',
        asc: null
      },
      {
        width: 10,
        name: ''
      }
    ]

    this.buttonText = this.gettextCatalog.getString('Add Hardware')
    this.loader = infiniteLoader(Hardware)
    if (this.globalState.isUserManageSettingsOnly && !this.globalState.isUserManageSeatsOnly) {
      this.hideDelete = true
      this.buttonText = undefined
    }
  }

  delete (event, item) {
    let name =
      this.gettextCatalog.getString('hardware') + ': ' + item.macAddress
    this.loader.entityApi.startDelete(event, item, this.data, name)
  }

  itemSelected (item) {
    super.itemSelected(
      item,
      'root.nav.pbx.devices.hardware.view.general',
      'hardwareId'
    )
  }

  retrieveModel (type, data) {
    let id = _jrn.parse(type).getResources()[1]
    _portalApi.userAgent.userAgentTypes(id).get().then(result => {
      data.model = result.displayString
    })
  }

  build (item) {
    item.macAddress = item.macAddress.replace(/[:\.-]/g, '')
  }

  onUpdate (data) {
    if(data.type) {
      this.retrieveModel(data.type, data)
    } else {
      data.model = ''
    }
  }

  onNew (data) {
    this.retrieveModel(data.type, this.data.items[0])
  }
}

_module.controller('HardwaresController', hardwaresControllerFactory)
