import _module from 'module.js'
import itemTemplate from 'controllers/item/itemTemplate.html'
import groupsTemplate from 'controllers/groups/groupsTemplate.html'
import groupTemplate from 'controllers/group/groupTemplate.html'
import environments from '../configs/environments'

const basePath = environments.current === 'local' ? '/images' : '/pbx/images'

_module.config((gettext, $stateProvider) => {
  'ngInject'

  $stateProvider
    .state('root.nav.pbx.groups', {
      url: '/groups',
      template: '<ui-view class="groups-abstract max-height"></ui-view>',
      abstract: true
    })
    .state('root.nav.pbx.groups.list', {
      url: '?q&sort&direction&source',
      template: groupsTemplate,
      controller: 'GroupsController as ctrl',
      reloadOnSearch: false
    })
    .state('root.nav.pbx.groups.view', {
      url: '/:groupId',
      abstract: true,
      template: itemTemplate,
      controller: 'GroupController as ctrl',
      data: {
        itemIcon: `${basePath}/people-inactive.svg`,
        title: gettext('User Group'),
        tabs: [
          {
            id: 'group-general',
            label: gettext('General'),
            state: 'root.nav.pbx.groups.view.general'
          }
        ]
      }
    })
    .state('root.nav.pbx.groups.view.general', {
      url: '',
      template: groupTemplate
    })
})
