import _module from 'module.js'

_module.directive('ipAddressNoPort', function () {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, $input, attrs, ngModelCtrl) {
      ngModelCtrl.$validators.ipAddressNoPort = () => {
        return (
          $input.val().length === 0 ||
          (/^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/.test($input.val()) &&
            validV4Ip($input.val()))
        )
      }

      let validV4Ip = ipString => {
        let result = true
        let values = $input.val().split('.')
        for (let i = 0; i < 4; i++) {
          result = result && parseInt(values[i], 10) <= 255
        }
        return result
      }
    }
  }
})
