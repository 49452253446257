import _module from 'module.js'
import featuresService from './factories/featuresService'
import hotDesking from './factories/hotDesking'
import deviceFeatures from './factories/deviceFeatures'
import userAgentType from './factories/userAgentType'

// Mocks
// Comment these out if you would like to use local endpoints (and adjust the URLs above as needed)
export default () => {
  _module
    .factory('featuresServiceApiInterceptor', featuresService)
    .factory('hotDeskingApiInterceptor', hotDesking)
    .factory('deviceFeaturesApiInterceptor', deviceFeatures)
    .factory('userAgentTypeApiInterceptor', userAgentType)

  _module.config(function ($httpProvider) {
    'ngInject'
    $httpProvider.interceptors.push('featuresServiceApiInterceptor')
    $httpProvider.interceptors.push('hotDeskingApiInterceptor')
    $httpProvider.interceptors.push('deviceFeaturesApiInterceptor')
    $httpProvider.interceptors.push('userAgentTypeApiInterceptor')
  })
}
