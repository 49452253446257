import jrnParser from 'classes/jrnParser'

let _jrn, _CorporateDirectory, _state, _globalState
export default class GetCorporateDirectoryController {
  constructor (CorporateDirectory, gettextCatalog, $state, globalState) {
    'ngInject'
    this._model = {}
    _CorporateDirectory = CorporateDirectory
    _state = $state
    _globalState = globalState
    _jrn = jrnParser

    this.placeholder = gettextCatalog.getString(
      'Select a corporate directory...'
    )

    this.$onInit = () => {
      this.setViewValue = item => {
        this.ngModel.$setViewValue(item.id)
      }
    }
  }

  api (id = null) {
    return new _CorporateDirectory(id)
  }

  openLink () {
    _state.go('root.nav.pbx.corp-directories.view.general', {
      id: _globalState.selectedPbx.domain,
      directoryId: _jrn.parse(this.jrn).getResources()[1]
    })
  }
}
