import lineDialplanTemplate from './lineDialplanTemplate.html'
import lineDialplanController from './lineDialplanController.js'
import module from 'module'

module.component('lineDialplan', {
  template: lineDialplanTemplate,
  controllerAs: 'lineDialplan',
  bindings: {
    dialPlan: '=ngModel'
  },
  require: {
    ngModel: 'ngModel'
  },
  controller: lineDialplanController
})
