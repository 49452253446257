import _module from 'module.js'
import BaseES6 from 'factories/baseES6'
import * as utils from 'classes/permissionsUtils'

let _stateParams, _portalApi, _portalUtil, _globalState
let _everyoneName

export default class PermissionsService extends BaseES6 {
  constructor (pbxId) {
    'ngInject'
    super(null, pbxId || _globalState.selectedPbxUUID)
  }

  getPath () {
    if (!this._path) {
      this._path = _portalApi.pbx.pbxs(this._pbxId).one('permissions')
    }
    return this._path
  }

  map (data) {
    this.general = data.acl.map(target => {
      const admin = utils.isAdmin(target)
      return {
        id: target.identity,
        active: false,
        text: utils.getName(target, _everyoneName),
        admin,
        configPbx: admin || utils.isConfigPBX(target),
        manageSeats: admin || utils.isConfigPBX(target) || utils.isManageSeats(target),
        manageUsers: admin || utils.isConfigPBX(target) || utils.isManageUsers(target),
        manageGroups: admin || utils.isConfigPBX(target) || utils.isManageGroups(target),
        manageSettings: admin || utils.isConfigPBX(target) || utils.isManageSettings(target),
        reports: admin || utils.isViewReports(target),
        reportRecordings: admin || utils.isReportRecordings(target),
        viewInvoices: admin || utils.isViewInvoices(target),
        payInvoices: admin || utils.isPayInvoices(target)
      }
    })

    this.dialing = data.dialingResourceAcl.map(target => ({
      id: target.identity,
      active: false,
      text: utils.getName(target, _everyoneName),
      hide: utils.shouldHide(target),
      internal: utils.isInternal(target),
      local: utils.isLocal(target),
      longDist: utils.isLongDistance(target),
      mobile: utils.isMobile(target),
      international: utils.isInternational(target),
      tollNumbers: utils.isTollNumber(target),
      malAreaCodes: utils.isMalicious(target)
    }))

    // TODO finish this when implement in v5
    // this.monitoring = data.eavesdroppingResourceAcl.map(mapSimpleSRI);
    // this.dynamicConfRoom = data.dynamicConferenceRoomResourceSecuredResourceIdentity.map(mapSimpleSRI);

    const resolveIdentityPath = _portalUtil.resolveIdentityPath.bind(
      _portalUtil
    )
    this.prompts = data.promptRecordingResourceAcl.map(target =>
      utils.mapSimpleSRI(target, resolveIdentityPath)
    )
  }

  build (payload) {
    payload.acl = this.general.map(utils.mapGeneral)
    payload.dialingResourceAcl = this.dialing.map(utils.mapDialing)
    payload.promptRecordingResourceAcl = this.prompts.map(utils.mapPrompts)

    // TODO map when implemented in v5
    // payload.eavesdroppingResourceAcl = this.eavesdroppingResourceAcl;

    return payload
  }

  getClass () {
    return 'Permissions'
  }

  static factory (
    $stateParams,
    portalApi,
    gettextCatalog,
    portalUtil,
    globalState
  ) {
    'ngInject'
    _stateParams = $stateParams
    _portalApi = portalApi
    _portalUtil = portalUtil
    _globalState = globalState

    _everyoneName = gettextCatalog.getString('Everyone')

    return (pbxId = null) => new PermissionsService(pbxId)
  }
}

_module.factory('Permissions', PermissionsService.factory)
