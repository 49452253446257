import _module from 'module.js'
import ListControllerBase from 'classes/listController.class'
import jrnParser from 'classes/jrnParser'

export default class ExtensionMappingsController extends ListControllerBase {
  constructor (
    $state,
    $stateParams,
    $location,
    $analytics,
    ExtensionMappings,
    gettextCatalog,
    infiniteLoader,
    navigation,
    globalState,
    portalUtil
  ) {
    'ngInject'
    super(
      gettextCatalog,
      $analytics,
      $location,
      portalUtil,
      globalState,
      navigation,
      $state,
      $stateParams
    )

    this.gettextCatalog = gettextCatalog
    this.jrn = jrnParser
    this.ExtensionMappings = ExtensionMappings

    this.title = this.gettextCatalog.getString('Extension Mappings')

    this.headers = [
      {
        width: 45,
        name: this.gettextCatalog.getString('Pattern'),
        sortBy: 'pattern',
        asc: true
      },
      {
        width: 45,
        name: this.gettextCatalog.getString('Extension')
      },
      {
        width: 10,
        name: ''
      }
    ]

    this.buttonText = this.gettextCatalog.getString('Add Extension Mapping')
    this.loader = infiniteLoader(ExtensionMappings)
  }

  delete (event, mapping) {
    let pattern =
      this.gettextCatalog.getString('extension mapping') +
      ': ' +
      mapping.destinationPattern
    this.loader.entityApi.startDelete(event, mapping, this.data, pattern)
  }

  onUpdate (item) {
    new this.ExtensionMappings(item.mapId, item).get().then(data => {
      item.targetExtensionName = data.targetExtensionName
      item.targetExtensionNumber = data.targetExtensionNumber
    })
  }
}

_module.controller('ExtensionMappingsController', ExtensionMappingsController)
