export default class VoicemailGreetingController {
  constructor (gettextCatalog, confirmationWindowWarn) {
    'ngInject'
    this.upload = gettextCatalog.getString('Upload')
    this.handler = null
    this.options = []
    this.confirmationWindowWarn = confirmationWindowWarn
    this.gettextCatalog = gettextCatalog
    this.selection = null
  }

  init () {
    this.handler = this.api()
    this.options = [
      { name: this.upload, api: this.handler.upload, play: this.handler.play }
    ]
    this.selection = this.options[0]
    this.voicemailFunction = {
      api: this.handler.play,
      param: this.param
    }

    if (this.greeting) {
      this.initPlayer()
    }
  }

  $onInit () {
    this.ngModel.$render = () => {
      this.init()
    }
  }

  uploadTts (content, api) {
    api().customPUT({ content }, '', { vmgtype: this.param })
  }

  del () {
    this.confirmationWindowWarn
      .show(
        this.gettextCatalog.getString('Confirm Delete'),
        this.gettextCatalog.getString(
          'Are you sure you want to delete the {{type}}?',
          { type: this.label }
        ),
        this.gettextCatalog.getString('Delete')
      )
      .then(
        () => {
          this.handler.del.remove({ vmgtype: this.param }).then(() => {
            this.greeting = this.ttsValue = null
          })
        },
        () => {
          // Leave it alone
        }
      )
  }

  initPlayer () {
    this.handler.play
      .get({
        redirect: false,
        transcode: true,
        format: 'mp3',
        vmgtype: this.param
      })
      .then(url => {
        this.fileUrl = url.contentUrl
      })
  }
}
