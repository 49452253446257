import _module from 'module.js'
import _ from 'lodash'
import BaseES6 from 'factories/baseES6'

let _globalState, _portalApi

export default class BillingGroup extends BaseES6 {
  constructor (id, billingGroups, pbxId) {
    'ngInject'
    super(id, pbxId || _globalState.selectedPbxUUID)

    if (billingGroups) {
      this._map(billingGroups)
    }
  }

  getApi () {
    if (!this._api) { this._api = _portalApi.billingGroup.pbxs(this._pbxId).billingGroups }

    return this._api
  }

  getPath () {
    if (!this._path) {
      this._path = _portalApi.billingGroup
        .pbxs(this._pbxId)
        .billingGroups(this._id)
    }

    return this._path
  }

  getReferencesPath () {
    if (!this._referencesPath) {
      this._referencesPath = _portalApi.billingGroup
        .pbxs(this._pbxId)
        .billingGroups(this._id)
        .one('references')
    }
    return this._referencesPath
  }

  getClass () {
    return 'BillingGroup'
  }

  static factory (globalState, portalApi) {
    'ngInject'
    _globalState = globalState
    _portalApi = portalApi

    return (id, billingGroup, pbxId = null) => {
      return new BillingGroup(id, billingGroup, pbxId)
    }
  }
}

_module.factory('BillingGroup', BillingGroup.factory)
