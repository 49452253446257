
import _module from 'module.js'
import guidedSetupTemplate from 'controllers/guidedSetup/guidedSetupTemplate.html'

_module.config(($stateProvider) => {
  'ngInject'

  $stateProvider
    .state('root.nav.pbx.setup', {
      url: '/setup',
      template: guidedSetupTemplate,
      controller: 'GuidedSetupController as ctrl'
    })
    .state('root.nav.pbx.orders', {
      url: '/orders',
      abstract: true,
      template: '<ui-view></ui-view>'
    })
    .state('root.nav.pbx.orders.view', {
      url: '',
      template: guidedSetupTemplate,
      controller: 'GuidedSetupController as ctrl',
    })
    .state('root.nav.pbx.orders.view.id', {
      url: '/:orderId',
      template: guidedSetupTemplate,
      controller: 'GuidedSetupController as ctrl'
    })
})
