import voicemailGreetingTemplate from './voicemailGreetingTemplate.html'
import voicemailGreetingController from './voicemailGreetingController.js'

import _module from 'module.js'

_module.component('voicemailGreeting', {
  restrict: 'E',
  require: {
    ngModel: 'ngModel'
  },
  template: voicemailGreetingTemplate,
  controller: voicemailGreetingController,
  controllerAs: 'voicemailGreetingController',
  bindings: {
    label: '<',
    greeting: '<ngModel',
    param: '<',
    api: '&'
  }
})
