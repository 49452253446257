import _module from 'module.js'
import audioPreviewTemplate from './audioPreviewTemplate.html'
import AudioPreviewController from './audioPreviewController'

_module.component('audioPreview', {
  template: audioPreviewTemplate,
  require: {
    ngModel: 'ngModel'
  },
  bindings: {
    url: '=ngModel',
    autoPlay: '=?',
    songInfo: '='
  },
  controllerAs: 'ctrl',
  controller: AudioPreviewController
})
