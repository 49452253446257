import _module from 'module.js'
import jbcEditTemplate from '../views/common/jbcEditTemplate.html'
import _ from 'lodash'

class JbcEditPopupController {
  constructor (
    jbcInfo,
    Jbcs,
    $analytics,
    gettextCatalog,
    $mdDialog,
    globalState,
    portalUtil
  ) {
    'ngInject'
    this.gettextCatalog = gettextCatalog
    this.isWhiteLabel = globalState.isWhiteLabel
    this.portalUtil = portalUtil

    this.jbcEditLabel = this.isWhiteLabel
      ? this.gettextCatalog.getString('Business Continuity Configuration')
      : this.gettextCatalog.getString('JBC Configuration')

    this.jbcInfo = jbcInfo
    this.form = {
      macAddress: jbcInfo.macAddress,
      description: jbcInfo.description,
      ipAddress: jbcInfo.ipAddress,
      pstnGatewayUri: jbcInfo.pstnGatewayUri,
      pstnLineCount: jbcInfo.pstnLineCount,
      priorityPhoneNumbers: jbcInfo.priorityPhoneNumbers,
      bypassPhoneNumbers: jbcInfo.bypassPhoneNumbers,
      netConfigMode: jbcInfo.netConfigMode,
      netConfigStatic: jbcInfo.netConfigStatic,
      busy: false
    }

    if (!this.form.priorityPhoneNumbers) {
      this.form.priorityPhoneNumbers = [
        {
          number: ''
        }
      ]
    }

    if (!this.form.bypassPhoneNumbers) {
      this.form.bypassPhoneNumbers = [
        {
          number: ''
        }
      ]
    }
    this.Jbcs = Jbcs
    this.$analytics = $analytics
    this.$mdDialog = $mdDialog
  }

  addPriorityPhoneNumber () {
    this.form.priorityPhoneNumbers.push({
      number: ''
    })
  }

  removePriorityPhoneNumber (index) {
    this.form.priorityPhoneNumbers.splice(index, 1)
  }

  addBypassPhoneNumber () {
    this.form.bypassPhoneNumbers.push({
      number: ''
    })
  }

  removeBypassPhoneNumber (index) {
    this.form.bypassPhoneNumbers.splice(index, 1)
  }

  complete () {
    this.form.busy = true
    this.$analytics.eventTrack('Jbc Edit: Complete', { category: 'Jbc Edit' })
    if (this.form.pstnGatewayUri === '') {
      this.form.pstnGatewayUri = null
    }
    let updateJbc = new this.Jbcs(this.jbcInfo.id)
    updateJbc.id = this.jbcInfo.id
    updateJbc.organizationId = updateJbc._pbxId
    updateJbc.macAddress = this.form.macAddress
    updateJbc.description = this.form.description
    updateJbc.ipAddress = this.form.ipAddress
    updateJbc.pstnGatewayUri = this.form.pstnGatewayUri
    updateJbc.pstnLineCount = this.form.pstnLineCount
    updateJbc.priorityPhoneNumbers = _.pullAllWith(
      this.form.priorityPhoneNumbers,
      { number: '' }
    )
    updateJbc.bypassPhoneNumbers = _.pullAllWith(this.form.bypassPhoneNumbers, {
      number: ''
    })
    updateJbc.netConfigMode = this.form.netConfigMode
    updateJbc.netConfigStatic = this.form.netConfigStatic
    updateJbc.update().then(
      () => {
        this.form.busy = false
        this.$mdDialog.hide(updateJbc)
      },
      error => {
        this.form.busy = false
        this.portalUtil.showErrorAlert(
          this.gettextCatalog.getString('Update failed, please retry.')
        )
      }
    )
  }

  cancelDialog () {
    this.$mdDialog.cancel()
  }
}

class JbcEditPopup {
  constructor (
    $mdDialog,
    $analytics,
    Jbcs,
    gettextCatalog,
    globalState,
    portalUtil
  ) {
    'ngInject'
    this.$mdDialog = $mdDialog
    this.$analytics = $analytics
    this.Jbcs = Jbcs
    this.globalState = globalState
    this.gettextCatalog = gettextCatalog
    this.portalUtil = portalUtil
  }

  open (jbcInfo) {
    this.$analytics.eventTrack('Jbc Edit: Open', { category: 'Jbc Edit' })
    return this.$mdDialog.show({
      template: jbcEditTemplate,
      controllerAs: 'jbcEdit',
      controller: JbcEditPopupController,
      locals: {
        jbcInfo: jbcInfo,
        $mdDialog: this.$mdDialog,
        gettextCatalog: this.gettextCatalog,
        Jbcs: this.Jbcs,
        globalState: this.globalState,
        $analytics: this.$analytics,
        portalUtil: this.portalUtil
      },
      parent: angular.element(document.body),
      clickOutsideToClose: true,
      fullscreen: true
    })
  }
}

_module.service('jbcEditPopup', JbcEditPopup)
