import _module from 'module.js'
import _ from 'lodash'

export default class UserSynchronizationsController {
  constructor(
    $state,
    $stateParams,
    gettextCatalog,
    globalState,
    FEATURE_FLAGS,
    portalUtil,
    $rootScope,
    errorService,
    $timeout,
    userSyncManagementService,
    confirmationWindowWarn,
    FileSaver
  )
  {
    'ngInject'

    this.portalUtil = portalUtil;
    this.FileSaver = FileSaver
    this.$rootScope = $rootScope;
    this.$timeout = $timeout;

    this.userSyncManagementService = userSyncManagementService;
    this.featureEnabled = globalState.selectedPbx.featureFlags[FEATURE_FLAGS.userSyncEnabled];
    this.title = gettextCatalog.getString('User Synchronizations');
    this.messageOperationStart = gettextCatalog.getString('Do you really want to start the Active Directory Synchronization?');
    this.messageOperationStop = gettextCatalog.getString('Do you really want to stop the Active Directory Synchronization?');
    this.$stateParams = $stateParams;
    this.globalState = globalState;

    this.data = [];
    this.data.activeDirectoryUserSyncEnabled = false;
    this.data.activeDirectoryUserSyncId = null;

    this.errorService = errorService;
    this.gettextCatalog = gettextCatalog;

    this.$state = $state;
    this.portalUtil.setStateI18N(this, this.$state.$current.parent.data);
    this.confirmationWindowWarn = confirmationWindowWarn;

    if (this.featureEnabled) {
      this.retrieveSyncStatus();
    } else {
      $state.go('root.nav.pbx.dashboard', $stateParams)
      return
    }
  }

  syncErrorHandler(error) {
      this.portalUtil.showErrorAlert(
        this.gettextCatalog.getString('Data retrieval failed, please retry.'));

      this.$timeout(() => {
        this.$rootScope.$apply()
      });

      throw error;
  }

  retrieveSyncStatus() {
    this.userSyncManagementService.getSynchronizations()
      .then(response => {
        if (response.items.length > 0) {
          // assuming a single result returned, this is the current service implementation
          _.forEach(response.items, item => {
            this.data.activeDirectoryUserSyncId = item.id;
            this.data.activeDirectoryUserSyncEnabled = (item.status === "ENABLED");
          })
        }
        else {
          this.data.activeDirectoryUserSyncEnabled = false
        }
      })
      .catch(error => {
        this.syncErrorHandler(error);
      })
  }

  deleteSync() {
    this.userSyncManagementService.deleteSynchronization(this.data.activeDirectoryUserSyncId)
      .then(response => {})
      .catch(error => {
        this.data.activeDirectoryUserSyncEnabled = true;
        this.syncErrorHandler(error);
      })
  }

  createSync() {
    this.userSyncManagementService.createSynchronization()
      .then(response => {
        this.data.activeDirectoryUserSyncId = response.id
      })
      .catch(error => {
        this.data.activeDirectoryUserSyncEnabled = false;
        this.syncErrorHandler(error);
      })
  }

  confirmSync(activeDirectoryUserSyncEnabled) {
    const messageOperation = activeDirectoryUserSyncEnabled? this.messageOperationStart: this.messageOperationStop;
    this.confirmationWindowWarn
      .show(messageOperation, '', this.gettextCatalog.getString('Continue'))
      .then(
        () => activeDirectoryUserSyncEnabled ? this.createSync() : this.deleteSync(), () => {
          // do nothing (cancel chosen)
          this.data.activeDirectoryUserSyncEnabled = !activeDirectoryUserSyncEnabled;
        })
      .catch(() => {
        this.data.activeDirectoryUserSyncEnabled = !activeDirectoryUserSyncEnabled;
        this.portalUtil.showErrorAlert(
          this.gettextCatalog.getString('Failed to modify the Active Directory Synchronization, please retry.')
        )
      })

  }

  async exportErrors(){
    const filename =
      this.globalState.selectedPbx.domain + '-user-synchronizations-errors-'+new Date().valueOf()+'.csv'
    try{
      const csv = await this.userSyncManagementService.getIssues()
      const data = new Blob([csv], { type: 'text/plain;charset=utf-8' })
      this.FileSaver.saveAs(data, filename)
    } catch (err) {
      this.portalUtil.showErrorAlert(
        this.gettextCatalog.getString('File download failed, please retry.')
      )
      throw err
    }
  }

}

_module.controller('UserSynchronizationsController', UserSynchronizationsController);
