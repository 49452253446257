let _mdDialog, _Hardware
class CreateHardwareController {
  constructor ($mdDialog, Hardware) {
    'ngInject'
    this.hardware = {}
    _mdDialog = $mdDialog
    _Hardware = Hardware
  }

  createHardware () {
    let create = new _Hardware(null, this.hardware)
    create.macAddress = this.hardware.macAddress ? this.hardware.macAddress.replace(/\W/g, '') : ''

    return create.save().then(result => {
      return result.plain()
    })
  }

  complete () {
    this.createHardware().then(newHardware => {
      _mdDialog.hide(newHardware)
    })
  }

  cancelDialog () {
    _mdDialog.cancel()
  }
}

export default CreateHardwareController
