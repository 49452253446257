import _module from 'module.js'
import itemTemplate from 'controllers/item/itemTemplate.html'
import directoriesTemplate from 'controllers/directories/directoriesTemplate.html'
import directoryGeneralTemplate from 'controllers/directory/general/directoryGeneralTemplate.html'
import corpDirectoriesTemplate from 'controllers/corpDirectories/corpDirectoriesTemplate.html'
import corpDirectoryGeneralTemplate from 'controllers/corpDirectory/general/corpDirectoryGeneralTemplate.html'
import corpDirectoryCertificatesTemplate from 'controllers/corpDirectory/certificates/corpDirectoryCertificatesTemplate.html'
import phoenixTemplate from 'controllers/phoenix/phoenixTemplate.html'
import environments from '../configs/environments'

const basePath = environments.current === 'local' ? '/images' : '/pbx/images'

_module.config((gettext, $stateProvider) => {
  'ngInject'

  $stateProvider
    .state('root.nav.pbx.directories', {
      url: '/directories',
      abstract: true,
      template: '<ui-view class="directories-abstract max-height"></ui-view>'
    })
    .state('root.nav.pbx.directoriesv2', {
      url: '/directories-v2',
      template: phoenixTemplate,
      controller: 'PhoenixController as ctrl',
    })
    .state('root.nav.pbx.directoriesv2.view', {
      url: '/:directoryId/:tab',
      template: phoenixTemplate,
      controller: 'PhoenixController as ctrl',
    })
    .state('root.nav.pbx.directories.list', {
      url: '?q&sort&direction&source',
      template: directoriesTemplate,
      controller: 'DirectoriesController as ctrl',
      reloadOnSearch: false
    })
    .state('root.nav.pbx.directories.view', {
      url: '/:directoryId',
      template: itemTemplate,
      controller: 'DirectoryController as ctrl',
      abstract: true,
      data: {
        title: gettext('Dial By Name Directory'),
        itemIcon: `${basePath}/address-book-contacts-inactive.svg`,
        tabs: [
          {
            id: 'directory-general',
            label: gettext('General'),
            state: 'root.nav.pbx.directories.view.general'
          }
        ]
      }
    })
    .state('root.nav.pbx.directories.view.general', {
      url: '/general',
      template: directoryGeneralTemplate
    })
    // -----
    .state('root.nav.pbx.corp-directories', {
      url: '/corporate-directories',
      abstract: true,
      template: '<ui-view class="directories-abstract max-height"></ui-view>'
    })
    .state('root.nav.pbx.corp-directoriesv2', {
      url: '/corporate-directories-v2',
      template: phoenixTemplate,
      controller: 'PhoenixController as ctrl',
    })
    .state('root.nav.pbx.corp-directoriesv2.view', {
      url: '/:corporateDirectoryId/:tab',
      template: phoenixTemplate,
      controller: 'PhoenixController as ctrl',
    })
    .state('root.nav.pbx.corp-directories.list', {
      url: '?q&sort&direction&source',
      template: corpDirectoriesTemplate,
      controller: 'CorporateDirectoriesController as ctrl',
      reloadOnSearch: false
    })
    .state('root.nav.pbx.corp-directories.view', {
      url: '/:directoryId',
      template: itemTemplate,
      controller: 'CorporateDirectoryCtrl as ctrl',
      abstract: true,
      data: {
        title: gettext('Corporate Directory'),
        itemIcon: `${basePath}/address-book-contacts-inactive.svg`,
        tabs: [
          {
            id: 'corp-directory-general',
            label: gettext('General'),
            state: 'root.nav.pbx.corp-directories.view.general'
          },
          {
            id: 'corp-directory-certificates',
            label: gettext('Certificates'),
            state: 'root.nav.pbx.corp-directories.view.certificates'
          }
        ]
      }
    })
    .state('root.nav.pbx.corp-directories.view.general', {
      url: '/general',
      template: corpDirectoryGeneralTemplate
    })
    .state('root.nav.pbx.corp-directories.view.certificates', {
      url: '/entries',
      template: corpDirectoryCertificatesTemplate
    })
})
