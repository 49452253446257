export default class ListTooltipController {
  constructor ($filter) {
    'ngInject'
    this.name = this.label
    this.status = null
    this.secureCalling = false
    this.$filter = $filter

    if (this.list) {
      this.items = this.list.map(item => {
        let name = item.name || this.$filter('tel')(item.number)
        let extensionNumber = item.extensionNumber || null
        let status = this.convertStatus(item.status)
        let secureCalling = !!item.secureCallingMode
        if (status !== null) {
          if (this.status === null) {
            this.status = 0
          }
          this.status += status
        }
        this.secureCalling = this.secureCalling || secureCalling

        return {
          name: name,
          extensionNumber: extensionNumber,
          status: status,
          secureCalling: secureCalling
        }
      })

      if (this.status !== null) {
        this.status = this.status === 0
          ? 0
          : this.status === this.items.length
              ? 1
              : this.status === this.items.length * -1 ? -1 : 0
      }
    }
  }

  convertStatus (status) {
    if (status === 'UNKNOWN') {
      return 0
    } else if (status === 'ONLINE') {
      return 1
    } else if (status === 'OFFLINE') {
      return -1
    } else {
      return null
    }
  }
}
