export class SecureDevicesInfoController {
  constructor ($element, portalApi, globalState) {
    'ngInject'
    this.$element = $element
    this.numberOfSecureCallingDevices = 0
    this.baseNumberOfSecureCallingDevices = 0
    this.numberOfInsecureCallingDevices = 0
    this.baseNumberOfInsecureCallingDevices = 0
    this.numberOfUnsupportedDevices = 0
    this.segments = []
    this.portalApi = portalApi
    this.globalState = globalState
  }

  async $onInit () {
    const summary = await this.portalApi.userAgent
      .pbxs(this.globalState.selectedPbxUUID)
      .userAgentConfigurationSecurity('summary')
      .get()
    this.numberOfSecureCallingDevices = this.baseNumberOfSecureCallingDevices =
      summary.numberOfSecureCallingDevices
    this.numberOfInsecureCallingDevices = this.baseNumberOfInsecureCallingDevices =
      summary.numberOfInsecureCallingDevices
    this.numberOfUnsupportedDevices = summary.numberOfUnsupportedDevices
    this.updateSegments()
  }

  $onChanges () {
    this.numberOfSecureCallingDevices =
      this.baseNumberOfSecureCallingDevices + this.secureCallingDevicesDiff
    this.numberOfInsecureCallingDevices =
      this.baseNumberOfInsecureCallingDevices - this.secureCallingDevicesDiff
    this.updateSegments()
  }

  getPercentage (segmentNumber) {
    return (this.totalNumberOfDevices === 0
      ? 0
      : segmentNumber / this.totalNumberOfDevices
    ).toFixed(2)
  }

  updateSegments () {
    this.segments = [
      {
        percent: this.getPercentage(this.numberOfSecureCallingDevices),
        color: '#cddc39'
      },
      {
        percent: this.getPercentage(this.numberOfInsecureCallingDevices),
        color: '#00b0ff'
      },
      {
        percent: this.getPercentage(this.numberOfUnsupportedDevices),
        color: '#ffb300'
      }
    ]
  }

  getGraphicCenterX () {
    return this.$element.find('.secure-devices-info-graphic').width() / 2
  }

  getGraphicCenterY () {
    return this.$element.find('.secure-devices-info-graphic').height() / 2
  }

  get totalNumberOfDevices () {
    return (
      this.numberOfSecureCallingDevices +
      this.numberOfInsecureCallingDevices +
      this.numberOfUnsupportedDevices
    )
  }
}
