/**
 * @ngdoc function
 * @name platformApp.factory:callQueueService
 * @description
 * # callQueueService
 * Controller of the platformApp
 */
import _module from 'module.js'
import _ from 'lodash'
import ExtensionBaseES6 from 'factories/extensionBaseES6'
import jrnParser from 'classes/jrnParser'

let _portalApi, _jrn, _globalState, _stringOnName

// workaround for gettextCatalog
const getStringOnName = (gettextCatalog, agentName) => {
  return gettextCatalog.getString('on {{name}}', { name: agentName })
}

export default class CallQueue extends ExtensionBaseES6 {
  constructor (id, callQueue, gettextCatalog) {
    'ngInject'
    super(id)

    this.gettextCatalog = gettextCatalog
    if (id === undefined) {
      throw new Error(
        this.gettextCatalog.getString('Missing id for new CallQueue')
      )
    }

    if (callQueue) {
      this._map(callQueue)
    }
  }
  getApi () {
    if (!this._api) {
      this._api = _portalApi.extension.pbxs(
        _globalState.selectedPbxUUID
      ).callQueues
    }

    return this._api
  }

  getPath () {
    if (!this._path) {
      this._path = _portalApi.extension
        .pbxs(_globalState.selectedPbxUUID)
        .callQueues(this._id)
    }

    return this._path
  }

  getReferencesPath () {
    if (!this._referencesPath) {
      this._referencesPath = _portalApi.extension
        .pbxs(_globalState.selectedPbxUUID)
        .callQueues(this._id)
        .one('references')
    }
    return this._referencesPath
  }

  mapAgent (agent) {
    var name = agent.extensionName
    if (agent.extensionNumber) {
      name = agent.extensionNumber + ': ' + name
    }
    let deviceNameAndModel = ''
    if (agent.deviceName) {
      deviceNameAndModel = agent.deviceName
      name += ' ('
      name += getStringOnName(this.gettextCatalog, agent.deviceName)

      // this.gettextCatalog.getString("on {{name}}",  {name: agent.deviceName} )
      if (agent.deviceModel) {
        name += ' / ' + agent.deviceModel
        deviceNameAndModel += ' (' + agent.deviceModel + ')'
      }
      name += ')'
    }

    return {
      id: agent.aorJrn,
      text: name,
      deviceName: deviceNameAndModel,
      extensionNumber: agent.extensionNumber,
      extensionName: agent.extensionName,
      extensionId: _jrn.parse(agent.extension).getResources()[1],
      deviceId: _jrn.parse(agent.device).getResources()[1]
    }
  }

  map (data) {
    if (data.agentsInQueue) {
      this._agentsInQueue = data.agentsInQueue.map(this.mapAgent)
    }
    this.extensionRegion = data.extensionRegion ? data.extensionRegion : ''
    if (
      data.callerRingback === 'HOLD_MUSIC' &&
      data.holdMusicPlaylist === null
    ) {
      _portalApi.media
        .pbxs(_globalState.selectedPbxUUID)
        .holdMusicPlaylists()
        .one('all')
        .get()
        .then(playlists => {
          _.forEach(playlists.items, item => {
            if (item.name === 'Default') {
              this.holdMusicPlaylist = item.jrn
              this.holdMusicPlaylistName = item.name
            }
          })
        })
    }
  }

  build (payload) {
    // DropDowns
    if (this._agentsInQueue) {
      payload.agentsInQueue = this._agentsInQueue.map(function (agent) {
        return agent.id
      })
    }

    return payload
  }

  mapListItem (queue) {
    var number = ''
    if (queue.extensionNumber) {
      number = queue.extensionNumber + ': '
    }
    return {
      id: queue.jrn,
      text: number + queue.name
    }
  }

  addAgent (aorJrn) {
    var request = this.getPath().one('login')
    return request.put({
      aorJrn: aorJrn
    })
  }

  removeAgent (aorJrn) {
    var request = this.getPath().one('logout')
    return request.put({
      aorJrn: aorJrn
    })
  }

  getClass () {
    return 'CallQueue'
  }

  static factory (portalApi, gettextCatalog, globalState) {
    'ngInject'
    _portalApi = portalApi
    _jrn = jrnParser
    _globalState = globalState

    return (id, callQueue) => {
      return new CallQueue(id, callQueue, gettextCatalog)
    }
  }
}

_module.factory('CallQueue', CallQueue.factory)
