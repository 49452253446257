import { getJrnDefaultComponent } from '../getJrn/getJrnDefaultComponent.js'
import getHotDeskDeviceController from './getHotDeskDeviceController.js'
import _module from 'module.js'
import _ from 'lodash'

let getHotDeskDeviceComponent = _.cloneDeep(getJrnDefaultComponent)
getHotDeskDeviceComponent.controller = getHotDeskDeviceController
getHotDeskDeviceComponent.bindings.userId = '@'

_module.component('getHotDeskDevice', getHotDeskDeviceComponent)
