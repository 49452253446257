import CallFilterActionsController from './callFilterActionsController'
import callFilterActionsTemplate from './callFilterActionsTemplate.html'
import _module from 'module.js'

_module.component('callFilterActions', {
  require: { ngModel: 'ngModel' },
  template: callFilterActionsTemplate,
  controllerAs: 'ctrl',
  bindings: {
    row: '=ngModel',
    editing: '='
  },
  controller: CallFilterActionsController
})
