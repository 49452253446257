/**
 * @ngdoc function
 * @name platformApp.controller:PagingProfileController
 * @description
 * # PagingProfileController
 * Controller of the platformApp
 */
import _module from 'module.js'
import _ from 'lodash'
import BaseController from 'classes/baseController'
import jrnParser from 'classes/jrnParser'

let _jrn

export default class PagingProfileController extends BaseController {
  constructor (
    portalApi,
    $stateParams,
    PagingProfile,
    userService,
    gettextCatalog,
    $state,
    portalUtil,
    errorService,
    globalState,
    navigation,
    $rootScope,
    $timeout
  ) {
    'ngInject'
    super(
      $state,
      $stateParams,
      PagingProfile,
      portalApi,
      userService,
      gettextCatalog,
      portalUtil,
      errorService,
      globalState,
      navigation,
      'root.nav.pbx.devices.profiles.list',
      'profileId'
    )

    _jrn = jrnParser

    this.data
      .get()
      .then(data => {
        this.itemName = data.name
        this.ready = true
        this.initial = false
        $timeout(() => {
          $rootScope.$apply()
        })
      })
      .catch(error => {
        let errorMessage = this.errorService.getErrorMessage(error)
        this.portalUtil.showErrorAlert(
          _.isEmpty(errorMessage)
            ? this.gettextCatalog.getString(
                'Data retrieval failed, please retry.'
              )
            : errorMessage
        )
        this.ready = true
        this.initial = false
        $timeout(() => {
          $rootScope.$apply()
        })
        throw error
      })

    this.data.usageLoading = true
    this.data.getReferencesApi().get().then(references => {
      if (references && references.userAgentConfigurations) {
        references.userAgentConfigurations.forEach(device => {
          device.id = _jrn.parse(device.jrn).getResources()[1]
        })
        this.data.references = references
      }
      this.data.usageLoading = false
    })
  }
  save (form) {
    this.ready = false
    this.data.name = this.itemName
    return this.data
      .update()
      .then(() => {
        form.$setPristine()
        this.ready = true
      })
      .catch(error => {
        this.portalUtil.showErrorAlert(
          this.gettextCatalog.getString('Save failed, please retry.')
        )
        this.ready = true
        throw error
      })
  }
}

_module.controller('PagingProfileController', PagingProfileController)
