import _module from 'module.js'
import _ from 'lodash'

_module
  .filter('didType', function (gettextCatalog) {
    'ngInject'
    return function (type) {
      if (type && !type.outboundRoutable) {
        return gettextCatalog.getString('Inbound Only')
      } else {
        return gettextCatalog.getString('Two-Way')
      }
    }
  })
  .filter('didTarget', function ($filter) {
    'ngInject'
    return function (data) {
      if (data && data.targetDid && data.action === 'ALIAS') {
        return {
          id: data.targetDid,
          text: (_.isEmpty(data.targetDidName)
            ? ''
            : data.targetDidName + ': ') + $filter('tel')(data.targetDidNumber)
        }
      } else if (data && data.targetExtension) {
        return {
          id: data.targetExtension,
          text: (_.isEmpty(data.targetExtensionNumber)
            ? ''
            : data.targetExtensionNumber + ': ') + data.targetExtensionName
        }
      }
      return null
    }
  })
