import _module from 'module.js'
import _ from 'lodash'
import moment from 'moment/min/moment-with-locales'
import BaseController from 'classes/baseController'

let _location, _deletionConfirmation

export default class ScheduleHolidaysController extends BaseController {
  constructor (
    portalApi,
    $stateParams,
    schedule,
    Schedule,
    userService,
    $window,
    $location,
    gettextCatalog,
    $state,
    portalUtil,
    errorService,
    globalState,
    navigation,
    DialPlan,
    deletionConfirmation
  ) {
    'ngInject'
    super(
      $state,
      $stateParams,
      Schedule,
      portalApi,
      userService,
      gettextCatalog,
      portalUtil,
      errorService,
      globalState,
      navigation,
      'root.nav.pbx.schedules.list',
      'scheduleId',
      schedule
    )

    _location = $location
    _deletionConfirmation = deletionConfirmation
    this.resolvedData = schedule

    this.scheduleData = new Schedule(this.$stateParams.scheduleId, schedule)
    this.title = this.gettextCatalog.getString('Holidays')
    this.langCode = this.globalState.currentLanguage.langCode
    this.BASE_LANG_CODE = 'en-us'

    this.scheduleData.holidays = this.parseDates(this.scheduleData.holidays)
    // There is no pagination on this page
    this.lastLoad = new Date()

    this.data = {
      q: _location.search().q || null,
      loading: false,
      holidays: this.scheduleData.holidays,
      totalCount: this.scheduleData.holidays.length
    }

    this.headers = [
      {
        width: 25,
        name: this.gettextCatalog.getString('Name'),
        sortBy: 'name',
        asc: null
      },
      {
        width: 65,
        name: this.gettextCatalog.getString('Date'),
        sortBy: 'start',
        asc: true
      },
      {
        width: 10,
        name: ''
      }
    ]

    this.sortBy = 'start'

    this.buttonText = this.gettextCatalog.getString('Add Holiday')
  }

  parseDates (holidays) {
    var parsedHolidays = []
    _.forEach(holidays, (holiday, index) => {
      holiday.allDay = false
      let startMoment = moment(holiday.interval.split('/')[0]).locale(
        this.BASE_LANG_CODE
      )
      let endMoment = moment(holiday.interval.split('/')[1]).locale(
        this.BASE_LANG_CODE
      )
      startMoment.locale(this.langCode)
      endMoment.locale(this.langCode)
      holiday.start = startMoment.toDate()
      holiday.end = endMoment.toDate()
      holiday.startTime = startMoment.format('LT')
      holiday.endTime = endMoment.format('LT')
      holiday.startChanged = () => {
        if (moment(holiday.start).isAfter(moment(holiday.end))) {
          holiday.end = holiday.start
        }
      }
      if (
        startMoment.isSame(startMoment.clone().startOf('day'), 'minute') &&
        endMoment.isSame(endMoment.clone().endOf('day'), 'minute')
      ) {
        holiday.allDay = true
        if (startMoment.isSame(endMoment, 'day')) {
          holiday.displayString = startMoment.format('LL')
        } else {
          holiday.displayString =
            startMoment.format('LL') + ' - ' + endMoment.format('LL')
        }
      } else {
        holiday.displayString =
          startMoment.format('LLL') + ' - ' + endMoment.format('LLL')
      }
      holiday.minStartDate = moment().locale(this.langCode).toDate()
      holiday.maxStartDate = moment()
        .locale(this.langCode)
        .add(5, 'year')
        .toDate()
      holiday.maxEndDate = moment()
        .locale(this.langCode)
        .add(5, 'year')
        .toDate()

      parsedHolidays[index] = holiday
    })
    return _.sortBy(parsedHolidays, ['start'])
  }

  sortByHeader (header) {
    let sortedHolidays = _.sortBy(this.data.holidays, [header.sortBy])
    if (header.sortBy === this.sortBy) {
      header.asc = !header.asc
      this.direction = header.asc ? 'asc' : 'desc'
    } else {
      this.sortBy = header.sortBy

      // set all sort header to null, which means not sorted.
      this.headers.forEach(_header => {
        if (_header.hasOwnProperty('asc')) {
          _header.asc = null
        }
      })
      header.asc = true
      this.direction = header.asc ? 'asc' : 'desc'
    }
    if (this.direction === 'desc') {
      sortedHolidays = _.reverse(sortedHolidays)
    }

    this.data.holidays = sortedHolidays
  }

  delete (event, holiday) {
    let Holiday = {
      getClass: () => {
        return 'Holiday'
      }
    }
    _deletionConfirmation
      .confirm(event, holiday.name, holiday, Holiday)
      .then(
        () => {
          _.remove(this.data.holidays, day => {
            return (
              holiday.interval === day.interval && holiday.name === day.name
            )
          })
          this.save()
        },
        () => {
          // do nothing for cancel.
        }
      )
      .catch(error => {
        this.portalUtil.showErrorAlert(
          this.gettextCatalog.getString('Delete failed, please retry.')
        )
        throw error
      })
  }

  save (holiday) {
    if (this.data.holidays[0] && this.data.holidays[0].isNew) {
      this.data.holidays[0].isNew = false
    }
    if (holiday) {
      if (!holiday.end) {
        holiday.end = holiday.start
      }
      if (holiday.allDay) {
        let startMoment = moment(holiday.start).startOf('day')
        let endMoment = moment(holiday.end).endOf('day')
        if (startMoment.isSame(endMoment, 'day')) {
          holiday.displayString = startMoment.format('LL')
        } else {
          holiday.displayString =
            startMoment.format('LL') + ' - ' + endMoment.format('LL')
        }
        holiday.start = startMoment.toDate()
        holiday.end = endMoment.toDate()
      } else {
        let startMoment = moment(holiday.start)
          .locale(this.langCode)
          .hours(moment(holiday.startTime, 'LT').hours())
        startMoment.minutes(moment(holiday.startTime, 'LT').minutes())
        startMoment.seconds(moment(holiday.startTime, 'LT').seconds())
        holiday.start = startMoment.toDate()
        let endMoment = moment(holiday.end)
          .locale(this.langCode)
          .hours(moment(holiday.endTime, 'LT').hours())
        endMoment.minutes(moment(holiday.endTime, 'LT').minutes())
        endMoment.seconds(moment(holiday.endTime, 'LT').seconds())
        if (startMoment.isAfter(endMoment)) {
          endMoment.endOf('day')
        }
        holiday.end = endMoment.toDate()
        holiday.displayString =
          startMoment.format('LLL') + ' - ' + endMoment.format('LLL')
      }
      holiday.interval =
        moment(holiday.start)
          .locale(this.BASE_LANG_CODE)
          .format('YYYY-MM-DDTHH:mm:ss') +
        '/' +
        moment(holiday.end)
          .locale(this.BASE_LANG_CODE)
          .format('YYYY-MM-DDTHH:mm:ss')
    }
    this.scheduleData.holidays = this.data.holidays
    this.data.totalCount = this.data.holidays.length
    this.resolvedData.holidays = this.data.holidays

    this.scheduleData.update().catch(error => {
      this.portalUtil.showErrorAlert(
        this.gettextCatalog.getString('Save failed, please retry.')
      )
      throw error
    })
  }

  createNew (data) {
    if (!this.data.holidays[0] || !this.data.holidays[0].isNew) {
      var holiday = {}
      holiday.start = moment().startOf('day')
      holiday.end = moment().endOf('day')
      holiday.holidayName = ''
      this.data.holidays.unshift({
        name: holiday.holidayName,
        start: moment(holiday.start).locale(this.langCode).toDate(),
        end: moment(holiday.end).locale(this.langCode).toDate(),
        startTime: moment(holiday.start).locale(this.langCode).format('LT'),
        endTime: moment(holiday.end).locale(this.langCode).format('LT'),
        allDay: true,
        startChanged: function () {
          if (moment(this.start).isAfter(moment(this.end))) {
            this.end = this.start
          }
        },
        isNew: true,
        minStartDate: moment().locale(this.langCode).toDate(),
        maxStartDate: moment().locale(this.langCode).add(5, 'year').toDate(),
        maxEndDate: moment().locale(this.langCode).add(5, 'year').toDate(),
        interval: moment(holiday.start)
          .locale(this.BASE_LANG_CODE)
          .format('YYYY-MM-DDTHH:mm:ss') +
          '/' +
          moment(holiday.end)
            .locale(this.BASE_LANG_CODE)
            .format('YYYY-MM-DDTHH:mm:ss')
      })
    }
  }

  cancelNew () {
    if (this.data.holidays[0] && this.data.holidays[0].isNew) {
      this.data.holidays.shift()
    }
  }
}

_module.controller('ScheduleHolidaysController', ScheduleHolidaysController)
