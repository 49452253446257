let _portalApi, _globalState

const parseDisplayInfo = item => (item ? item.name : null)

export default class GetLocationsController {
  constructor ($q,LIST_LIMIT_DEFAULT,
    $rootScope, portalApi, globalState,$timeout,gettextCatalog){
    'ngInject'
    _portalApi = portalApi
    _globalState = globalState

    this.api = _portalApi.location.accounts(_globalState.selectedPbxLmiAccountKey).locations
    this.placeholder = gettextCatalog.getString('Select an Emergency Location ...')
    this.key = this.locationItem ? this.locationItem.key : null
    this.assignedLocation = ''
    this.selectedItem = {}
    this.busy = false
    this.$q = $q
    this.$rootScope = $rootScope
    this.LIST_LIMIT_DEFAULT = LIST_LIMIT_DEFAULT
    this.$timeout = $timeout
    this.query = {
      q: '',
      noMoreData: false,
      infiniteScrollLength: 0,
      items: []
    }

    this.infiniteItems = {
      getItemAtIndex: index => {
        if (!this.busy && index > this.query.items.length) {
          this.loadData(false)
          return null
        }
        return this.query.items[index]
      },
      getLength: () => this.query.infiniteScrollLength
    }
  }

  $onInit () {
    this.ngModel.$render = () => {
      const validateOnResponse = promisedResource =>
        promisedResource.then(res => {
          if (res) {
            this.ngModel.$setValidity('required', true)
          }
        })

      if (this.key && !this.assignedLocation) {
        validateOnResponse(this.requestLocation(this.key))
      } else {
        this.$onChanges = changes => {
          if (changes.key) {
            validateOnResponse(this.requestLocation(changes.key.currentValue))
          }
        }
      }
    }

    this.assignedLocation = parseDisplayInfo(this.ngModel.$modelValue)

    if (this.required) {
      this.ngModel.$validators.isSet = value => !!value
    }

    this._resetPaging()
  }

  setSelectedItem (item) {
    this.inputFocusSet(false)
    this.assignedLocation = parseDisplayInfo(item)
    this.key = item.key
    this.selectedItem = item
    this.ngModel.$setViewValue(item)
    this.resetQuery()
  }

  async requestLocation (locationKey) {
    if (!locationKey || this.busy) {
      return
    }
    this.busy = true
    try {
      const data = await this.api.call(this, locationKey).get({ cache: true })
      this.assignedLocation = parseDisplayInfo(data)
      this.selectedItem = data
      return data
    } finally {
      this.busy = false
      this.$timeout(() => this.$rootScope.$apply())
    }
  }

  remove (event) {
    event.stopImmediatePropagation()
    Object.assign(this, {
      assignedLocation: null,
      selectedItem: null,
      key: null
    })
    if (this.onRemove) {
      this.onRemove()
    }
  }

  resetQuery (text) {
    this.q = this.query.q = text || ''
    this._resetPaging()
    this.query.items = []
    this.query.noMoreData = false
  }

  async loadData (resetPaging) {
    if (this.query.noMoreData) {
      return
    }

    this.busy = true

    if (resetPaging) {
      this._resetPaging()
    } else {
      this.query.page += 1
    }

    const _query = _.omit(this.query, [
      'items',
      'noMoreData',
      'infiniteScrollLength'
    ])

    if (this.queryParams) {
        Object.assign(_query, this.queryParams)
    }

    try {
      const data = await this.api.call(this).get(_query)
      if (data) {
        this.query.noMoreData = this.LIST_LIMIT_DEFAULT > data.items.length
        this.query.items = this.query.items.concat(
        data.items
        )
        this.query.infiniteScrollLength = this.query.noMoreData
          ? this.query.items.length
          : this.query.items.length + 2
      }
    } finally {
      this.busy = false
      this.$timeout(() => this.$rootScope.$apply())
    }
  }

  inputFocusSet (val, $evt) {
    const extraHeight = 350
    const docEl = angular.element(document)
    let itemTemplate = docEl.find('.item-template')
    let newHeight = 0
    if (itemTemplate.length !== 0) {
      newHeight = angular.element('.item-template')[0].offsetHeight
    }
    if ($evt) {
      $evt.stopImmediatePropagation()
    }
    if (this.off) {
      return
    }
    
    if (val) {
      this.isFocused = val
      this.loadData(true)

      // prevent the left panel scroll when this is in focus
      docEl.find('md-sidenav').css({
        'overflow-y': 'hidden'
      })

      docEl.find('md-content').css({
        'overflow-y': 'scroll'
      })

      docEl.find('.list-display .table-container').css({
        'overflow-y': 'hidden'
      })

      if (newHeight !== 0) {
        newHeight = newHeight + extraHeight
        docEl.find('.item-template').css({
          height: newHeight + 'px'
        })
      }

    } else if (this.isFocused) {
      this.isFocused = false
      _.each(this.query.items, item => {
        item.autoPlay = false
      })
      docEl.find('md-sidenav').css({
        'overflow-y': 'auto'
      })
      docEl.find('.list-display .table-container').css({
        'overflow-y': 'auto'
      })

      if (newHeight !== 0) {
        newHeight = newHeight - extraHeight
        docEl.find('.item-template').css({
          height: newHeight + 'px'
        })
      }
    }
  }

  searchForLocations (text) {
    if (text !== this.query.q) {
      this.resetQuery(text)
    }
    this.loadData(true)
  }

  clearSearch () {
    this.resetQuery('')
    this.loadData(true)
  }

  openLink () {
    _state.go('root.nav.pbx.sitesv2.list.locations', {
      id: _globalState.selectedPbx.domain
    })
  }

  gotoLink (event) {
    event.stopImmediatePropagation()
    this.openLink()
  }

  _resetPaging () {
    this.query.page = 0
    this.query.pageSize = this.LIST_LIMIT_DEFAULT
  }

}
