import _module from 'module.js'
import _ from 'lodash'
import BaseES6 from 'factories/baseES6'

let _globalState, _portalApi

export default class Jbcs extends BaseES6 {
  constructor (id, jbc) {
    'ngInject'
    super(id, _globalState.selectedPbxUUID)

    if (jbc) {
      this._map(jbc)
    }
  }

  getApi () {
    if (!this._api) this._api = _portalApi.userAgent.pbxs(this._pbxId).jbcs

    return this._api
  }

  getPath () {
    if (!this._path) { this._path = _portalApi.userAgent.pbxs(this._pbxId).jbcs(this._id) }

    return this._path
  }

  getReferencesPath () {}

  mapBatchData (data) {
    data.status = "PENDING"
    data.subStatuses = []
    data.getJbcStatus = () => {
      _portalApi.userAgent.pbxs(data.organizationId).jbcs(data.id).one('status').get().then(results => {
        data.status = results.statusCode || "UNKNOWN"
        data.status = data.status.toUpperCase()
        data.summary = results.summary
        data.icon = "AVAILABLE"
        data.subStatuses = results.subStatuses
      }).catch(err => {
        data.status = "ERROR"
        data.icon = "OFFLINE"
      })
    }
    return data
  }

  getClass () {
    return 'Jbc'
  }

  static factory (globalState, portalApi) {
    'ngInject'
    _globalState = globalState
    _portalApi = portalApi

    return (id, jbc, pbxId = null) => {
      return new Jbcs(id, jbc, pbxId)
    }
  }
}

_module.factory('Jbcs', Jbcs.factory)
