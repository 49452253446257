import _module from 'module.js'

_module.directive('jvDropdown', function () {
  return {
    restrict: 'A',
    link: function (scope, element) {
      element.wrap('<div class="jv-dropdown-wrapper form-control"></div>')
      element.after('<span class="fa fa-sort-desc pull-right"></span>')
    }
  }
})
