import _module from 'module.js'

_module.directive('confirmNavigation', function (
  $rootScope,
  $state,
  gettextCatalog,
  confirmationWindowOK
) {
  return {
    restrict: 'A',
    require: ['form', 'confirmNavigation', '^^?confirmNavigation'],
    controllerAs: 'confirmNavigation',
    bindToController: true,
    controller: function () {
      'ngInject'
      this.confirmed = false
      this.state = $state.current

      this.isNavigationAwayFromCurrentState = (currentState, toState) => {
        var currentStateSplit = currentState.name.split('.')
        var toStateSplit = toState.name.split('.')

        var curPage = currentStateSplit[3]
        var curView = currentStateSplit[4]
        var toPage = toStateSplit[3]
        var toView = toStateSplit[4]

        if (curPage === 'hardware' || curPage === 'extensions') {
          curPage = currentStateSplit[4]
          curView = currentStateSplit[5]
          toPage = toStateSplit[4]
          toView = toStateSplit[5]
        } else if (curPage === 'permissions' && curView !== 'network') {
          return curPage !== toPage
        } else if (curPage === 'settings') {
          return curPage !== toPage
        } else if (curPage === 'sites' || curPage === 'pbxs') {
          return false
        }

        return curPage !== toPage || curView !== toView
      }
    },
    link: function (scope, el, attrs, ctrls) {
      var form = ctrls[0]
      var confirmNavigation = ctrls[1]
      var parentConfirmNavigation = ctrls[2]

      if (!parentConfirmNavigation) {
        var cleanUpListener = $rootScope.$on('$stateChangeStart', function (
          event,
          toState,
          toParams,
          fromState,
          fromParams
        ) {
          if (
            !confirmNavigation.confirmed &&
            form.$dirty &&
            confirmNavigation.isNavigationAwayFromCurrentState(
              confirmNavigation.state,
              toState
            )
          ) {
            event.preventDefault()
            confirmationWindowOK
              .show(
                gettextCatalog.getString('Warning'),
                gettextCatalog.getString(
                  'There are unsaved changes. Are you sure you want to navigate away?'
                )
              )
              .then(
                function () {
                  confirmNavigation.confirmed = true
                  $state.go(toState.name, toParams)
                },
                function () {
                  $rootScope.busy = false
                  $rootScope.$broadcast(
                    'cancelNavigation',
                    fromState,
                    fromParams
                  )
                }
              )
          }
        })

        scope.$on('$destroy', function () {
          cleanUpListener()
        })
      }
    }
  }
})
