import _module from 'module.js'

_module.directive('scheduleDrag', function ($document) {
  return {
    require: ['^schedule', '^timeRange'],
    restrict: 'A',
    scope: {
      x: '=',
      y: '=',
      width: '=',
      height: '=',
      onMousemove: '&?',
      onMouseup: '&?'
    },
    link: function (scope, element, attr, ctrls) {
      let schedule = ctrls[0],
        startX = 0,
        startY = 0,
        startDragX = 0,
        startDragY = 0,
        oldHeight = schedule.ROW_HEIGHT,
        oldWidth = schedule.ROW_WIDTH,
        x = scope.x || 0,
        y = scope.y || 0,
        height = scope.height || schedule.ROW_HEIGHT,
        width = scope.width || schedule.ROW_WIDTH

      element.css({
        left: x + 'px',
        top: y + 'px',
        height: height + 'px',
        width: width + 'px',
        lineHeight: height + 'px'
      })

      let timeRange = ctrls[1]

      element.on('mousedown', mousedown)

      scope.$on('time-change', function () {
        rerender()
      })

      function mousedown (event) {
        // Prevent default dragging of selected content
        event.preventDefault()
        startX = event.screenX - timeRange.x
        startY = event.screenY - timeRange.y
        startDragX = event.screenX
        startDragY = event.screenY
        height = oldHeight = timeRange.height
        width = oldWidth = timeRange.width
        $document.on('mousemove', mousemove)
        $document.on('mouseup', mouseup)
      }

      function mousemove (event) {
        if (schedule.mode === 'move') {
          y = event.screenY - startY
          x = event.screenX - startX

          y = applySnapY(y)
          x = applySnapX(x)
          // horizontal
          if (x < 0) {
            x = 0
          } else if (x > schedule.SCHEDULE_WIDTH - width) {
            x = schedule.SCHEDULE_WIDTH - width
          }
          if (y < 0) {
            y = 0
          } else if (y > schedule.SCHEDULE_HEIGHT - height) {
            y = schedule.SCHEDULE_HEIGHT - height
          }
        } else if (schedule.mode === 'resize-y') {
          let deltaY = event.screenY - startDragY
          height = oldHeight + applySnapY(deltaY)

          if (height < schedule.ROW_HEIGHT) {
            height = schedule.ROW_HEIGHT
          } else if (height > schedule.SCHEDULE_HEIGHT - y) {
            height = schedule.SCHEDULE_HEIGHT - y
          }
        } else if (schedule.mode === 'resize-x') {
          let deltaX = event.screenX - startDragX
          width = oldWidth + applySnapX(deltaX)

          if (width < schedule.ROW_WIDTH) {
            width = schedule.ROW_WIDTH
          } else if (width > schedule.SCHEDULE_WIDTH - x) {
            width = schedule.SCHEDULE_WIDTH - x
          }
        }

        scope.onMousemove({
          update: {
            x: x,
            y: y,
            width: width,
            height: height
          }
        })

        timeRange.x = x
        timeRange.y = y
        timeRange.height = height
        timeRange.width = width

        element.css({
          top: y + 'px',
          left: x + 'px',
          height: height + 'px',
          width: width + 'px',
          lineHeight: height + 'px'
        })
      }

      function applySnapX (_x) {
        let xOffset = _x % schedule.ROW_WIDTH
        let snapOffset =
          Math.round(xOffset / schedule.ROW_WIDTH) * schedule.ROW_WIDTH

        return _x - xOffset + snapOffset
      }

      function applySnapY (_y) {
        let yOffset = _y % (schedule.ROW_HEIGHT / 2)
        let snapOffset =
          Math.round(yOffset / schedule.ROW_HEIGHT / 2) * schedule.ROW_HEIGHT
        return _y - yOffset + snapOffset
      }

      function mouseup () {
        $document.off('mousemove', mousemove)
        $document.off('mouseup', mouseup)
        scope.onMouseup()
      }

      function rerender () {
        element.css({
          top: timeRange.y + 'px',
          left: timeRange.x + 'px',
          height: timeRange.height + 'px',
          width: timeRange.width + 'px',
          lineHeight: timeRange.height + 'px'
        })
      }

      scope.$on('$destroy', function () {
        $document.off('mousedown', mousedown)
        $document.off('mousemove', mousemove)
        $document.off('mouseup', mouseup)
      })
    }
  }
})
