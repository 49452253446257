import _module from 'module.js'
import jrnParser from 'classes/jrnParser'

let _portalApi, _jrn, _globalState

export default class AudioPlayerService {
  constructor () {
    'ngInject'
  }

  parseAudioUrl (response) {
    return response.contentUrl
  }

  // Formats: WAV("wav", "audio/wav"),
  //   WAV49("WAV", "audio/wav49"),
  //   ULAW("ulaw", "audio/x-ulaw"),
  //   MP3("mp3", "audio/mpeg", "audio/mp3", "audio/mpeg3", "audio/x-mp3", "audio/x-mpeg3"),
  //   G722("g722", "audio/G722");

  callGetAndParse (
    api,
    options = { redirect: false, transcode: true, format: 'mp3' }
  ) {
    return api.get(options).then(this.parseAudioUrl)
  }

  getHoldMusicUrlByJrn (jrn, options) {
    let id = _jrn.parse(jrn).getResources()[1]
    return this.callGetAndParse(
      _portalApi.media
        .pbxs(_globalState.selectedPbxUUID)
        .holdMusicFiles(id)
        .one('content-url'),
      options
    )
  }

  getBuiltInMusicUrlByJrn (jrn) {
    let id = _jrn.parse(jrn).getResources()[1]
    return this.callGetAndParse(
      _portalApi.music.api().builtIn().one(`${id}/content-url`)
    )
  }

  getSoundClipUrlByJrn (jrn, options) {
    let id = _jrn.parse(jrn).getResources()[1]
    return this.callGetAndParse(
      _portalApi.media
        .pbxs(_globalState.selectedPbxUUID)
        .soundClips(id)
        .one('content-url'),
      options
    )
  }

  isCustomTypeFromJrn (jrn) {
    return !!jrn.match('custom-hold-music-file')
  }

  isBuiltInTypeFromJrn (jrn) {
    return !!jrn.match('built-in-hold-music-file')
  }

  isSoundclipTypeFromJrn (jrn) {
    return !!jrn.match('sound-clip')
  }

  downloadAudioFile (jrn, mediaType) {
    let options = { redirect: false, download: true }

    if (mediaType) {
      options.format = mediaType
      options.transcode = true
    }
    this.getSongUrl(jrn, options).then(url => {
      window.location = url
    })
  }

  getSongUrl (jrn, options) {
    if (this.isCustomTypeFromJrn(jrn)) {
      return this.getHoldMusicUrlByJrn(jrn, options)
    } else if (this.isBuiltInTypeFromJrn(jrn)) {
      return this.getBuiltInMusicUrlByJrn(jrn)
    } else if (this.isSoundclipTypeFromJrn(jrn)) {
      return this.getSoundClipUrlByJrn(jrn, options)
    }
  }

  static factory (portalApi, globalState) {
    'ngInject'
    _portalApi = portalApi
    _jrn = jrnParser
    _globalState = globalState

    return new AudioPlayerService()
  }
}

_module.factory('audioPlayerService', AudioPlayerService.factory)
