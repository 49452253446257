import jrnParser from 'classes/jrnParser'
import { PhoneNumberUtil } from 'google-libphonenumber'


let _jrn, _state, _globalState, _portalApi, _filter, _phoneUtil, _filterFax
export default class GetDidController {
  constructor (gettextCatalog, $state, globalState, $filter, portalApi) {
    'ngInject'
    this._model = {}
    _state = $state
    _globalState = globalState
    _jrn = jrnParser
    _portalApi = portalApi
    _filter = $filter
    _filterFax = this.filterFax

    this.placeholder = gettextCatalog.getString('Select a phone number...')
    _phoneUtil = PhoneNumberUtil.getInstance()

    this.api = _portalApi.pstn.pbxs(_globalState.selectedPbxUUID).dids

    this.$onInit = () => {
      this.setViewValue = item => {
        this.ngModel.$setViewValue(item.id)
      }
    }
  }

  map (did) {
    return {
      id: did.jrn,
      text: did.name +
        ' (' +
        (did.callerId ? did.callerId + ' ' : '') +
        '<' +
        _filter('telE164')(did.number) +
        '>' +
        ')'
    }
  }

  filter (item) {
    if(_filterFax)
    {
      let parsedNumber = _phoneUtil.parse(item.number, 'US')
      let regionCode = _phoneUtil.getRegionCodeForNumber(parsedNumber)
      let region = _globalState.supportedRegions.find(
        ({ code }) => code === regionCode
      )
      return region ? region.faxSupported : true
    }
    return true
  }

  openLink () {
    _state.go('root.nav.pbx.dids.view.general', {
      id: _globalState.selectedPbx.domain,
      didId: _jrn.parse(this.jrn).getResources()[1]
    })
  }
}
