import _module from 'module.js'

_module.filter('ringGroupMember', function ($filter) {
  var i = 0
  return function (member) {
    if (!member) {
      return null
    }

    if (member.type === 'line') {
      var number = ''
      if (member.extensionNumber) {
        number = member.extensionNumber + ': '
      }
      var lineMember = {
        id: member.line,
        text: number + member.name
      }

      return lineMember
    } else if (member.type === 'external-number') {
      i++
      var val = {
        id: 'external-number' + i,
        text: $filter('tel')(member.number)
      }

      return val
    }
  }
})
