import { getJrnDefaultComponent } from '../getJrn/getJrnDefaultComponent.js'
import getHardwareController from './getHardwareController.js'
import _module from 'module.js'
import _ from 'lodash'

let getHardwareComponent = _.cloneDeep(getJrnDefaultComponent)
getHardwareComponent.controller = getHardwareController
getHardwareComponent.bindings.onCreate = '&'

_module.component('getHardware', getHardwareComponent)
