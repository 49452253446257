import _module from 'module.js'
import _ from 'lodash'
import BaseES6 from 'factories/baseES6'

let _globalState, _portalApi

export default class ExtensionMappings extends BaseES6 {
  constructor (id, extensionMapping) {
    'ngInject'
    super(id, _globalState.selectedPbxUUID)

    if (extensionMapping) {
      this._map(extensionMapping)
    }
  }

  map (data) {
    // infinite loader expects a jrn field
    this.jrn = data.mapId
  }

  build (data) {
    data.destinationPattern = data.destinationPattern.toUpperCase()
    return data
  }

  mapBatchData (mapping) {
    mapping.jrn = mapping.mapId
    return mapping
  }

  getApi () {
    if (!this._api) { this._api = _portalApi.extension.pbxs(this._pbxId).extensionMappings }

    return this._api
  }

  getPath () {
    if (!this._path) {
      this._path = _portalApi.extension
        .pbxs(this._pbxId)
        .extensionMappings(this.mapId)
    }

    return this._path
  }

  getReferencesPath () {
    if (!this._referencesPath) {
      this._referencesPath = _portalApi.extension
        .pbxs(this._pbxId)
        .extensionMappings(this.mapId)
        .one('references')
    }
    return this._referencesPath
  }

  getClass () {
    return 'ExtensionMapping'
  }

  static factory (globalState, portalApi) {
    'ngInject'
    _globalState = globalState
    _portalApi = portalApi

    return (id, map, pbxId = null) => {
      return new ExtensionMappings(id, map, pbxId)
    }
  }
}

_module.factory('ExtensionMappings', ExtensionMappings.factory)
