import _ from 'lodash'
import _module from 'module.js'
import ListControllerBase from 'classes/listController.class'
import jrnParser from 'classes/jrnParser'
import { checkRoles } from 'controllers/user/userService'

export default class UsersController extends ListControllerBase {
  constructor (
    $state,
    $stateParams,
    $location,
    $analytics,
    User,
    gettextCatalog,
    infiniteLoader,
    portalApi,
    FileSaver,
    navigation,
    globalState,
    portalUtil,
    addUserForm,
    adminCenterUserWarningPopup
  ) {
    'ngInject'
    super(
      gettextCatalog,
      $analytics,
      $location,
      portalUtil,
      globalState,
      navigation,
      $state,
      $stateParams,
      {isHardwareUser: false}
    )
    this.jrn = jrnParser
    this.addUserForm = addUserForm
    this.FileSaver = FileSaver
    this.portalApi = portalApi
    this.gettextCatalog = gettextCatalog
    this.portalUtil = portalUtil
    this.$stateParams = $stateParams
    this.globalState = globalState
    this.adminCenterUserWarningPopup = adminCenterUserWarningPopup

    this.toggleVisibleItemsFromPermissions()

    this.title = this.gettextCatalog.getString('Users')
    this.selectedLanguage = globalState.currentLanguage.langCode || 'en-US'

    this.headers = [
      {
        width: 15,
        name: this.gettextCatalog.getString('Last Name'),
        sortBy: 'last_name',
        asc: true
      },
      {
        width: 15,
        name: this.gettextCatalog.getString('First Name'),
        sortBy: 'first_name',
        asc: null
      },
      {
        width: 15,
        name: this.gettextCatalog.getString('Email'),
        sortBy: 'email',
        asc: null
      },
      {
        width: 15,
        name: this.gettextCatalog.getString('Products')
      },
      {
        width: 15,
        name: this.gettextCatalog.getString('Role')
      },
      {
        width: 15,
        name: this.gettextCatalog.getString('Groups')
      },
      {
        width: 10,
        name: ''
      }
    ]

    this.exportText = this.gettextCatalog.getString('Export to CSV')

    this.loader = infiniteLoader(User, ({ items }) => this.onDataLoaded(items))
  }

  toggleVisibleItemsFromPermissions() {
    // Users with manage groups cannot add, edit or delete a user
    this.hideEdit = false
    this.hideDelete = false
    this.buttonText = this.gettextCatalog.getString('Add User')
    if ((this.globalState.isUserManageGroupsOnly || this.globalState.isUserManageSeatsOnly) && !this.globalState.isUserManageUsersOnly) {
      this.hideEdit = true
      this.hideDelete = true
      this.buttonText = undefined
    }
    if (this.globalState.isUnifiedAdminEnabled || this.globalState.selectedPbx.isTryNewGoToAdminEnabled) {
      this.hideEdit = true
    }
  }

  onDataLoaded (users = []) {
    users.forEach(user => {
      checkRoles(user)
      user.isBilledByLicense = this.globalState._selectedPbx.billedByLicense
    })
  }

  delete (event, user) {
    if(!user.deletable) {
      this.adminCenterUserWarningPopup.open(user.emailAddress);
      return
    }
    var name =
      this.gettextCatalog.getString('user') +
      ': ' +
      user.firstName +
      ' ' +
      user.lastName
    this.loader.entityApi.startDelete(event, user, this.data, name)
  }

  createNew () {
    this.addUserForm.open().then(newUser => {
      this.itemSelected(newUser)
    })
  }

  itemSelected (user) {
    super.itemSelected(user, 'root.nav.pbx.users.view.general', 'userId')
  }

  build (payload) {
    if (payload._emailAddress) {
      payload.emailAddress = payload._emailAddress.toLowerCase()
      payload.externalUserId = payload._emailAddress.toLowerCase()
    }
    return payload
  }

  async export () {
    this.busy = true
    const filename =
      this.globalState.selectedPbx.domain + '- Organization Export.csv'
    try {
      const csv = await this.portalApi.tool
        .pbxs(this.globalState.selectedPbxUUID)
        .organization()
        .get({language: this.selectedLanguage})
      const data = new Blob([csv], { type: 'text/plain;charset=utf-8' })
      this.FileSaver.saveAs(data, filename)
    } catch (err) {
      this.portalUtil.showErrorAlert(
        this.gettextCatalog.getString('File download failed, please retry.')
      )
      throw err
    } finally {
      this.busy = false
    }
  }
}

_module.controller('UsersController', UsersController)
