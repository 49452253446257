import _module from 'module.js'
_module.directive('clickOutside', function ($document) {
  return {
    restrict: 'A',
    scope: {
      clickOutside: '&'
    },
    link: function (scope, el) {
      function handler (e) {
        if (el !== e.target && !el[0].contains(e.target)) {
          scope.$applyAsync(function () {
            scope.$eval(scope.clickOutside)
          })
        }
      }

      $document.on('click', handler)

      scope.$on('$destroy', function () {
        $document.off('click', handler)
      })
    }
  }
})
