import moment from 'moment/min/moment-with-locales'
import _module from 'module.js'
import _ from 'lodash'
import timePickerTemplate from './timePickerTemplate.html'

class TimePickerCtrl {
  constructor ($mdDialog, globalState, gettextCatalog, time, scheduleName, autoSwitch) {
    'ngInject'
    this.$mdDialog = $mdDialog
    this.gettextCatalog = gettextCatalog
    this.region = globalState.supportedRegions.find(
      ({ code }) => code === globalState.selectedPbx.region
    )
    this.weekStartMonday = this.region.weekStartMonday
    this.VIEW_HOURS = 1
    this.VIEW_MINUTES = 2
    this.currentView = this.VIEW_HOURS
    this.locale = time.locale
    this.time = {
      start: moment(time.start).locale(this.locale),
      end: moment(time.end).locale(this.locale),
      startString: time.startString,
      endString: time.endString
    }
    this.autoSwitch = !!autoSwitch
    this.startValid = true
    this.endValid = true
    this.startBeforeEnd = true
    this.allDay =
      this.time.end.clone().startOf('day').isSame(this.time.end) &&
      this.time.start.clone().startOf('day').isSame(this.time.start)
    this.partialDayStart = time.startString
    this.partialDayEnd = time.endString
    this.scheduleName = scheduleName

    this.dayNames = [this.gettextCatalog.getString('Sunday'),
        this.gettextCatalog.getString('Monday'),
        this.gettextCatalog.getString('Tuesday'),
        this.gettextCatalog.getString('Wednesday'),
        this.gettextCatalog.getString('Thursday'),
        this.gettextCatalog.getString('Friday'),
        this.gettextCatalog.getString('Saturday')]
    if(this.weekStartMonday) {
      this.dayNames.push(this.dayNames.shift())
    }
    this.startDayName = this.dayNames[this.time.start.day()]
    let endDayNumber = this.time.end
      .clone()
      .startOf('day')
      .isSame(this.time.end)
      ? (this.time.end.day() + 6) % 7
      : this.time.end.day()
    this.endDayName = this.dayNames[endDayNumber]
  }

  cancel () {
    this.$mdDialog.cancel()
  }

  confirm () {
    this.$mdDialog.hide(this.time)
  }

  toggleAllDay () {
    if (!this.allDay) {
      this.time.startString = this.partialDayStart
      this.time.endString = this.partialDayEnd
    }
    this.validateTimes()
  }

  validateTimes () {
    if (!this.allDay) {
      let startCheck = moment(this.time.startString, 'LT', true).locale(
        this.locale
      )
      this.startValid = startCheck.isValid()
      let endCheck = moment(this.time.endString, 'LT', true).locale(this.locale)
      this.partialDayStart = startCheck.format('LT')
      this.partialDayEnd = endCheck.format('LT')
      this.endValid = endCheck.isValid()

      if (endCheck.clone().startOf('day').isSame(endCheck)) {
        endCheck.add(1, 'day')
      }

      this.startBeforeEnd = startCheck.isBefore(endCheck)
    } else {
      this.time.startString = this.time.start.startOf('day').format('LT')
      this.time.endString = this.time.end.startOf('day').format('LT')
      this.startValid = true
      this.endValid = true
      this.startBeforeEnd = true
    }
    if (this.startValid && this.endValid) {
      let startChange = moment(this.time.startString, 'LT', true)
      this.time.start.day(
        _.findIndex(this.dayNames, name => {
          return name == this.startDayName
        })
      )
      this.time.start.hours(startChange.hours())
      this.time.start.minutes(startChange.minutes())
      let endChange = moment(this.time.endString, 'LT', true)
      this.time.end.day(
        _.findIndex(this.dayNames, name => {
          return name == this.endDayName
        })
      )
      if (endChange.clone().startOf('day').isSame(endChange)) {
        this.time.end.add(1, 'day')
      }

      this.time.end.hours(endChange.hours())
      this.time.end.minutes(endChange.minutes())
      this.startBeforeEnd =
        this.startBeforeEnd && this.time.start.isBefore(this.time.end)
    }
    return this.startValid && this.endValid && this.startBeforeEnd
  }
}

_module.provider('$mdpTimePicker', function () {
  this.$get = [
    '$mdDialog',
    $mdDialog => {
      let timePicker = (time, scheduleName, options) => {
        if (!angular.isDate(time.start) && !angular.isDate(time.end)) {
          time = {
            start: moment(Date.now()),
            end: moment(Date.now()),
            startString: moment().format('LT'),
            endString: moment().format('LT')
          }
        }
        if (!scheduleName) scheduleName = ''
        if (!angular.isObject(options)) options = {}

        return $mdDialog.show({
          controller: [
            '$mdDialog',
            'globalState',
            'gettextCatalog',
            'time',
            'scheduleName',
            'autoSwitch',
            TimePickerCtrl
          ],
          controllerAs: 'timepicker',
          clickOutsideToClose: true,
          template: timePickerTemplate,
          targetEvent: options.targetEvent,
          locals: {
            time: time,
            scheduleName: scheduleName,
            autoSwitch: options.autoSwitch
          },
          skipHide: true
        })
      }

      return timePicker
    }
  ]
})
