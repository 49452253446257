/**
 * @ngdoc function
 * @name platformApp.factory:PagingRealmService
 * @description
 * # PagingRealmService
 * Controller of the platformApp
 */
import _module from 'module.js'
import BaseES6 from 'factories/baseES6'
import jrnParser from 'classes/jrnParser'

let _stateParams, _portalApi, _jrn, _globalState

export default class PagingRealm extends BaseES6 {
  constructor (id, directory, pbxId, gettextCatalog) {
    'ngInject'
    super(id || _stateParams.realmId, pbxId || _globalState.selectedPbxUUID)
    this.gettextCatalog = gettextCatalog

    if (id === undefined) {
      throw new Error(
        this.gettextCatalog.getString('Missing id for new PagingRealm')
      )
    }

    if (directory) {
      this._map(directory)
    }
  }
  getApi () {
    if (!this._api) {
      this._api = _portalApi.userAgent.pbxs(this._pbxId).pagingRealms
    }

    return this._api
  }

  getPath () {
    if (!this._path) {
      this._path = _portalApi.userAgent.pbxs(this._pbxId).pagingRealms(this._id)
    }

    return this._path
  }

  getReferencesPath () {
    if (!this._referencesPath) {
      this._referencesPath = _portalApi.userAgent
        .pbxs(this._pbxId)
        .pagingRealms(this._id)
        .one('references')
    }
    return this._referencesPath
  }

  mapListItem (dir) {
    return {
      id: dir.jrn,
      text: dir.name
    }
  }

  getClass () {
    return 'PagingRealm'
  }

  static factory ($state, $stateParams, portalApi, gettextCatalog, globalState) {
    'ngInject'
    _stateParams = $stateParams
    _portalApi = portalApi
    _jrn = jrnParser
    _globalState = globalState

    return (id, directory, pbxId = null) => {
      return new PagingRealm(id, directory, pbxId, gettextCatalog)
    }
  }
}

_module.factory('PagingRealm', PagingRealm.factory)
