/**
 * @ngdoc function
 * @name platformApp.factory:CallFilter
 * @description
 * # CallFilter
 * Service of the platformApp
 */
import _module from 'module.js'
import _ from 'lodash'
import BaseES6 from 'factories/baseES6'

let _portalApi, _globalState

export default class CallFilter extends BaseES6 {
  constructor (id, callFilter, pbxId, gettextCatalog) {
    'ngInject'
    super(id, pbxId || _globalState.selectedPbxUUID)
    this.gettextCatalog = gettextCatalog

    if (id === undefined) {
      throw new Error(
        this.gettextCatalog.getString('Missing id for new CallFilter')
      )
    }

    if (callFilter) {
      this._map(callFilter)
    }
  }

  getApi () {
    if (!this._api) this._api = _portalApi.pstn.pbxs(this._pbxId).callFilters

    return this._api
  }

  getPath () {
    if (!this._path) { this._path = _portalApi.pstn.pbxs(this._pbxId).callFilters(this._id) }

    return this._path
  }

  build (payload) {
    return payload
  }

  getClass () {
    return 'CallFilter'
  }

  static factory (globalState, portalApi, gettextCatalog) {
    'ngInject'
    _portalApi = portalApi
    _globalState = globalState

    return (id, callFilter, pbxId = null) => {
      return new CallFilter(id, callFilter, pbxId, gettextCatalog)
    }
  }
}

_module.factory('CallFilter', CallFilter.factory)
