import timeDurationTemplate from './timeDurationTemplate.html'
import timeDurationController from './timeDurationController.js'
import _module from 'module.js'

_module.component('timeDuration', {
  template: timeDurationTemplate,
  bindings: {
    secondsDuration: '<',
    inputRequired: '<',
    defaultSecondsDuration: '<',
    maxSecondsDuration: '<',
    minSecondsDuration: '<',
    secondsDurationChanged: '&'
  },
  controller: timeDurationController,
  controllerAs: 'ctrl'
})
