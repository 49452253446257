/**
 * @ngdoc function
 * @name platformApp.controller:VirtualFaxMachineController
 * @description
 * # VirtualFaxMachineController
 * Controller of the platformApp
 */
import _module from 'module.js'
import _ from 'lodash'
import ExtensionController from 'classes/extensionController'

const PERMISSION_DEP_MAP = {
  deleteReceivedFaxes: 'viewReceivedFaxes',
  deleteAllSentFaxes: 'viewAllSentFaxes',
}

export default class VirtualFaxMachineController extends ExtensionController {
  constructor (
    $state,
    $stateParams,
    portalApi,
    VirtualFaxMachineFactory,
    userService,
    gettextCatalog,
    portalUtil,
    errorService,
    globalState,
    User,
    navigation,
    confirmationWindowWarn,
    confirmationWindowOK
  ) {
    super(
      $state,
      $stateParams,
      VirtualFaxMachineFactory,
      portalApi,
      userService,
      gettextCatalog,
      portalUtil,
      errorService,
      globalState,
      navigation,
      'root.nav.pbx.extensions.virtualfaxmachines.list',
      'extId'
    )

    this.externalCallerIDRadioOptions = {
      radios: [
        {
          label: this.gettextCatalog.getString(
            "Use PBX's default external caller ID"
          ),
          value: true
        },
        {
          label: this.gettextCatalog.getString('Use:'),
          value: false
        }
      ]
    }

    this.isVirtualFaxMachineLargeFaxesEnabled = globalState.isVirtualFaxMachineLargeFaxesEnabled
    this.isVirtualFaxMachinePermissionsEnabled = globalState.isVirtualFaxMachinePermissionsEnabled
    this.confirmationWindowWarn = confirmationWindowWarn
    this.confirmationWindowOK = confirmationWindowOK
    this.isStoreIncomingFaxes = this.globalState._selectedPbx.storeIncomingFaxes
    this.accessCodeSize = this.globalState.selectedPbx.vfmAccessCodeSizeEnabled
    ? 10
    : 9

  this.helperTextSubjectLine = this.gettextCatalog.getString(
    "Start with this Virtual Fax Machine's {{:: size }}-digit access code. Any additional text is used in the fax header. The access code can be reset below.",
    { size: this.accessCodeSize }
  )

    this._permDepMsg = {
      viewReceivedFaxes: this.gettextCatalog.getString('This permission can be enabled only if "View faxes and history" is also enabled.'),
      viewAllSentFaxes: this.gettextCatalog.getString('This permission can be enabled only if "View faxes and history (from all users)" is also enabled.'),
      deleteReceivedFaxes: this.gettextCatalog.getString('Disabling this permission will also disable "Delete faxes".'),
      deleteAllSentFaxes: this.gettextCatalog.getString('Disabling this permission will also disable "Delete faxes (from all users)".')
    }

    this.acl = []
    this.activeAce = null

    let userApi = new User(null)
    let aclMemberQuery = () => ({
      get: params => userApi.getList(params || null).then(data => ({ items: data }))
    })

    this.getAclMembers = {
      api: aclMemberQuery,
      filter: member => !this.acl.find(ace => ace.id === member.id),
      map: (member) => member
    }

    this.data
      .get()
      .then(() => {
        this.itemName = this.data.extensionNumber
          ? this.data.extensionNumber + ': ' + this.data.name
          : this.data.name
        this.initExtensionNumber = this.data.extensionNumber
        this.accessCodeText = this.gettextCatalog.getString('Reset')
        this.ready = true
        this.initial = false
        this._initAcl()
      })
      .catch(error => {
        let errorMessage = this.errorService.getErrorMessage(error)
        this.portalUtil.showErrorAlert(
          _.isEmpty(errorMessage)
            ? this.gettextCatalog.getString(
                'Data retrieval failed, please retry.'
              )
            : errorMessage
        )
        this.ready = true
        this.initial = false
        throw error
      })
  }

  async save (form) {
    this.ready = false
    try {
      await this.data.update()
      this.initExtensionNumber = this.data.extensionNumber
      form.$setPristine()
      this._initAcl()
    } catch (err) {
      this.portalUtil.showErrorAlert(
        this.gettextCatalog.getString('Save failed, please retry.')
      )
    } finally {
      this.ready = true
    }
  }

  _initAcl() {
    this.acl = this.data.permissions
    this.activeAce = (this.activeAce ?
      this.acl.find(ace => ace.id === this.activeAce.id) : this.acl[0]) || null
    if (this.activeAce) {
      this.activeAce.active = true
    }
  }

  resetFaxCode () {
    this.confirmationWindowOK.show(
      this.gettextCatalog.getString('Warning'),
      this.gettextCatalog.getString('Are you sure you want to reset?')).then(
      () => {
        this.portalApi.extension
        .pbxs(this.globalState.selectedPbxUUID)
        .virtualFaxMachines(this.$stateParams.extId)
        .one('reset-access-code')
        .post()
        .then(data => {
          this.data.accessCode = data
          this.data.subject =
            data + ' ' + this.gettextCatalog.getString('[Optional Fax Title]')
          this.accessCodeText = this.gettextCatalog.getString('Reset')
        })
      }
    )

  }

  setAsActiveAce (ace) {
    this.activeAce = ace || null
    if (ace) {
      ace.active = true
    }
  }

  addNewAce (acl, ace) {
    acl.forEach(entry => entry.active = false)
    acl.push(ace)
    this.setAsActiveAce(ace)
  }

  resetActiveAce (ace, acl) {
    if (ace.active) {
      this.setAsActiveAce(acl[0])
    }
  }

  handlePermissionChanged (activeAce, changedPermKey) {
    if (activeAce[changedPermKey]) {
      this._handleAddPerm(activeAce, changedPermKey)
    } else {
      this._handleRemovePerm(activeAce, changedPermKey)
    }
  }

  _handleAddPerm(activeAce, addedPermKey) {
    const prerequisitePerm = PERMISSION_DEP_MAP[addedPermKey]
    if (prerequisitePerm && !activeAce[prerequisitePerm]) {
      const prereqPermMsg = this._permDepMsg[prerequisitePerm]
      const enablePrequisiteMsg = this.gettextCatalog.getString('Do you want to enable that permission?')
      this.confirmationWindowWarn.show(
        this.gettextCatalog.getString('Warning'),
        `${prereqPermMsg} ${enablePrequisiteMsg}`,
        this.gettextCatalog.getString('Enable')).then(
        () => activeAce[prerequisitePerm] = true,
        () => activeAce[addedPermKey] = false)
    }
  }

  _handleRemovePerm(activeAce, removedPermKey) {
    const dependingPerm = Object.keys(PERMISSION_DEP_MAP).find(k => PERMISSION_DEP_MAP[k] === removedPermKey)
    if (dependingPerm && activeAce[dependingPerm]) {
      const dependingPermMsg = this._permDepMsg[dependingPerm]
      const disableDependingPermMsg = this.gettextCatalog.getString('Are you sure you want to do this?')
      this.confirmationWindowWarn.show(
        this.gettextCatalog.getString('Warning'),
        `${dependingPermMsg} ${disableDependingPermMsg}`,
        this.gettextCatalog.getString('Disable')).then(
          () => activeAce[dependingPerm] = false,
          () => activeAce[removedPermKey] = true)
    }
  }
}

_module.controller('VirtualFaxMachineCtrl', VirtualFaxMachineController)
