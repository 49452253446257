import _module from 'module.js'
import BaseController from 'classes/baseController'
import jrnParser from 'classes/jrnParser'
import moment from 'moment/min/moment-with-locales'

export default class DidController extends BaseController {
  constructor(
    $state,
    $stateParams,
    portalApi,
    Did,
    gettextCatalog,
    portalUtil,
    errorService,
    globalState,
    navigation,
    userService,
    confirmationWindowWarn,
    didData,
  ) {
    'ngInject'
    super(
      $state,
      $stateParams,
      Did,
      portalApi,
      userService,
      gettextCatalog,
      portalUtil,
      errorService,
      globalState,
      navigation,
      'root.nav.pbx.dids.list',
      'didId',
    )

    this.data = didData
    this.showBilling = globalState.selectedPbx.billingGroupsEnabled
    this.confirmationWindowWarn = confirmationWindowWarn

    this.billingGroupsUseMaster = this.data.billingGroup === null

    this.routingText = this.gettextCatalog.getString('Direct calls for {{number}} to:', { number: this.data.number })

    this.showHidden = this.data.supportsSms
    this.GDPREnabled = globalState._selectedPbx.featureFlags['pbx.gdpr'] && this.showHidden
    this.canRecord = this.globalState._selectedPbx.storageAccessKeyId ||
      this.globalState._selectedPbx.useCboxStorage
    this.callRecordingAnnouncementEnabled = this.globalState._selectedPbx.featureFlags['pbx.call-recordings.announcement']
    this.isDidDeletionEnabled = globalState._selectedPbx.featureFlags['pbx.did.deletion']

    this.isCboxStorage = this.globalState._selectedPbx.useCboxStorage
    this.selectedTab = window.location.href.substring(window.location.href.lastIndexOf('/') + 1)
    this.ready = true
    this.initial = false
    this.itemName = this.data.name
    this.data.didFilter = did => this.data.jrn !== did.jrn
    this.loadHistories();
    this.loadPortOutPasscodeDetails();
  }

  isWithinAWeek(date) {
    const week = moment().subtract(6, 'days').startOf('day')
    return moment(date).isAfter(week)
  }

  isDateOlderThanAWeek(date) {
    return moment(date, 'MMM Do YYYY, h:mm a').format('lll') === date
  }

  changeIsWithinAWeek(item) {
    const week = moment().subtract(6, 'days').startOf('day')
    return moment(item.changeTime).isAfter(week)
  }
  changeIsWithinAMonth(item) {
    const month = moment()
    return moment(item.changeTime).isSame(month, 'month')
  }

  changeIsOlder(item) {
    const month = moment()
    const week = moment().subtract(6, 'days').startOf('day')
    return !moment(item.changeTime).isSame(month, 'month') && !moment(item.changeTime).isAfter(week)
  }

  setSelectedTab(tab) {
    return this.selectedTab = tab
  }

  isUserOnPbx(user) {
    return this.globalState.selectedPbxUUID === user.pbxId
  }

  holdMusicFilter(playlist) {
    return playlist.jrn.indexOf('built-in-hold-music-playlist') < 0
  }

  async reactivateDid() {
    this.ready = false
    this.data.active = true
    const result = await this.data.update()
    this.loadHistories()
    this.ready = true
  }

  async save(form) {
    this.ready = false
    if (this.billingGroupsUseMaster) {
      this.data.billingGroup = null
    }
    this.data.name = this.itemName
    //Extension returned is generic Extension Jrn
    let targetExtension = this.data.targetExtension

    try {
      const result = await this.data.update()
      this.data.targetExtension = targetExtension
      form.$setPristine()
      return result
    } catch (err) {
      this.portalUtil.showErrorAlert(
        this.gettextCatalog.getString('Save failed, please retry.')
      )
      throw err
    } finally {
      this.ready = true
    }
  }

  loadHistories() {
    this.data.getPath().one('state-history').get()
      .then(history => {
        if (history.items.length === 0) {
          this.data.history = undefined
        } else {
          this.data.history = history.items
          this.data.deleteDate = moment(history.items[0].changeTime).add(30, 'days').format('ll')
          this.data.isDidDeleted = history.items[0].toBeDeleted
          this.data.isDeletionAllowed = history.items[0].allowAutomaticDeletion
          this.data.history.map(item => {
            item.userFullName = item.firstName.concat(' ', item.lastName)
            item.changeDate = this.isWithinAWeek(item.changeTime) ? (moment(item.changeTime).calendar()) : (moment(item.changeTime).format('lll'))
            if (this.isUserOnPbx(item)) {
              this.data.foundUserInfo(item.externalUserId).get().then(user => {
                item.userId = jrnParser.parse(user.jrn).getResources()[1]
              })
            }
            return item
          })
        }
      })
      .catch(error => {
        this.portalUtil.showErrorAlert(
          this.gettextCatalog.getString('Save failed, please retry.')
        )
        this.ready = true
        throw error
      })
  }

  loadPortOutPasscodeDetails() {
    this.portalApi.numbers.pbxs(this.globalState.selectedPbxUUID).did(this.data._id).details().get()
      .then(detail => {
        this.isPortOutPasscodeSet = detail.portOutPasscodeDetails ? detail.portOutPasscodeDetails.isPortOutPasscodeSet : false
        this.data.smsDetails = detail.smsDetails;
      })
      .catch(() => {
        this.data.smsDetails = { isHosted: false, isSmsBeingEnabled: false, isSmsEligible: false };
      })
  }

  confirmClearSMS() {
    this.confirmationWindowWarn
      .show(
        this.gettextCatalog.getString(
          'Do you really want to clear all SMS messages?'
        ),
        this.gettextCatalog.getString('This action cannot be undone.'),
        this.gettextCatalog.getString('Clear')
      )
      .then(() => this.data.deleteCustomerData(), () => { })
      .catch(() => {
        this.portalUtil.showErrorAlert(
          this.gettextCatalog.getString(
            'Clear SMS messages failed, please retry.'
          )
        )
      })
  }
}

_module.controller('DidController', DidController)
