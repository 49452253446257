import _module from 'module.js'
import BaseES6 from 'factories/baseES6'

let _stateParams, _portalApi, _globalState

export default class MonitoringService extends BaseES6 {
  constructor (pbxId) {
    'ngInject'
    super(null, pbxId || _globalState.selectedPbxUUID)
  }

  getPath () {
    if (!this._api) {
      this._api = _portalApi.pbx.pbxs(this._pbxId).one('monitoring')
    }
    return this._api
  }

  static factory ($stateParams, portalApi, globalState) {
    'ngInject'
    _stateParams = $stateParams
    _portalApi = portalApi
    _globalState = globalState

    return (pbxId = null) => new MonitoringService(pbxId)
  }
}

_module.factory('Monitoring', MonitoringService.factory)
