import rowOptionsEditTemplate from './rowOptionsEditTemplate.html'
import _module from 'module.js'

_module.directive('rowOptionsEdit', function ($document) {
  'ngInject'
  return {
    restrict: 'A',
    replace: true,
    require: ['rowOptionsEdit', '^?rowEditor'],
    template: rowOptionsEditTemplate,

    controllerAs: 'rowOptionsEdit',
    bindToController: true,
    controller: function () {
      'ngInject'
      this.editing = false
    },

    link: function (scope, el, attrs, ctrls) {
      'ngInject'
      let rowOptionsEdit = ctrls[0]
      let rowEditor = ctrls[1]

      rowOptionsEdit.editing = rowEditor.editing

      if (rowOptionsEdit.editing) {
        $document.bind('keydown', submitOnEnter)
      }

      function submitOnEnter (event) {
        if (rowOptionsEdit.editing) {
          if (event.keyCode === 13) {
            rowOptionsEdit.confirmEdit(event)
          } else if (event.keyCode === 27) {
            rowOptionsEdit.cancelEdit(event)
          }
        }
      }

      rowEditor.register(isEditing => {
        rowOptionsEdit.editing = isEditing
        if (isEditing) {
          $document.bind('keydown', submitOnEnter)
        } else {
          $document.unbind('keydown', submitOnEnter)
        }
      })

      scope.$on('$destroy', () => {
        $document.unbind('keydown', submitOnEnter)
      })

      rowOptionsEdit.confirmEdit = $event => {
        if (scope.rowEditorForm.$valid) {
          rowEditor.save()
          $event.stopPropagation()
        }
      }

      rowOptionsEdit.cancelEdit = $event => {
        rowEditor.cancelEdit()
        $event.stopPropagation()
      }
    }
  }
})
