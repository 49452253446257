import _module from 'module.js'
import _ from 'lodash'
import jrnParser from 'classes/jrnParser'

import ListControllerBase from 'classes/listController.class'

let _jrn, _portalApi, _globalState

export default class conferenceRoomsController extends ListControllerBase {
  constructor (
    $state,
    $stateParams,
    $location,
    $analytics,
    ConferenceRoom,
    gettextCatalog,
    infiniteLoader,
    navigation,
    globalState,
    portalUtil,
    portalApi
  ) {
    'ngInject'
    super(
      gettextCatalog,
      $analytics,
      $location,
      portalUtil,
      globalState,
      navigation,
      $state,
      $stateParams
    )

    this.gettextCatalog = gettextCatalog
    _jrn = jrnParser
    _portalApi = portalApi
    _globalState = globalState

    this.title = this.gettextCatalog.getString('Conference Bridges')

    this.headers = [
      {
        width: 10,
        name: this.gettextCatalog.getString('Extension'),
        sortBy: 'extensionNumber',
        asc: true
      },
      {
        width: 35,
        name: this.gettextCatalog.getString('Name'),
        sortBy: 'name',
        asc: null
      },
      {
        width: 15,
        name: this.gettextCatalog.getString('Participant PIN')
      },
      {
        width: 15,
        name: this.gettextCatalog.getString('Host PIN')
      },
      {
        width: 15,
        name: this.gettextCatalog.getString('Omit from Directory')
      },
      {
        width: 10,
        name: ''
      }
    ]

    this.buttonText = this.gettextCatalog.getString('Add Conference Bridge')
    this.loader = infiniteLoader(ConferenceRoom)
  }

  delete (event, conference) {
    let name =
      this.gettextCatalog.getString('conference bridge') +
      ': ' +
      (conference.extensionNumber
        ? conference.extensionNumber + ': ' + conference.name
        : conference.name)
    this.loader.entityApi.startDelete(event, conference, this.data, name)
  }

  itemSelected (item) {
    super.itemSelected(
      item,
      'root.nav.pbx.extensions.conferencerooms.view.general',
      'extId'
    )
  }

  onNew (data) {
    // retrieve the conference URL
    let item = _.find(this.data.items, {
      extensionNumber: data.extensionNumber
    })
    let id = _jrn.parse(item.jrn).getResources()[1]

    _portalApi.extension
      .pbxs(_globalState.selectedPbxUUID)
      .conferenceRooms(id)
      .get()
      .then(result => {
        item.url = result.url
      })
      .catch(error => {
        _portalUtil.showErrorAlert(
          this.gettextCatalog.getString(
            'Failed in retrieving the newly created conference room, please refresh the page.'
          )
        )
        throw error
      })
  }
}
_module.controller('ConferenceRoomsController', conferenceRoomsController)
