import Mailcheck from 'mailcheck'

class AngularMailcheckController {
  constructor () {
    'ngInject'
    this.email = ''
    this.showSuggestion = false
    this.suggestion = ''
    Mailcheck.defaultSecondLevelDomains.push('jive')
  }

  $onChanges (attributes) {
    this.email = attributes.email.currentValue || ''
    Mailcheck.run({
      email: this.email,
      suggested: this.suggested.bind(this),
      empty: this.clear.bind(this)
    })
  }

  suggested (suggestions) {
    this.suggestion = suggestions.full
    this.showSuggestion = true
  }

  clear () {
    this.suggestion = ''
    this.showSuggestion = false
  }

  correctionAccepted () {
    this.showSuggestion = false
    this.onCorrection({ email: this.suggestion })
  }
}

export default AngularMailcheckController
