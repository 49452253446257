import ExtensionBaseES6 from 'factories/extensionBaseES6'
import VoicemailBox from 'classes/voicemailBox'
import _ from 'lodash'

const ignoredAttrs = [
  '_path',
  '_api',
  '_payload',
  'gettextCatalog',
  'references',
  '_referencesPath'
]

export default class ExtensionWithVoicemailBoxES6 extends ExtensionBaseES6 {
  constructor (id) {
    super(id)
    this.voicemailBox = new VoicemailBox()
  }

  copy () {
    const voicemailBox = this.voicemailBox.copy()
    const clone = angular.copy(this)
    clone.voicemailBox = voicemailBox
    return clone
  }

  _map (data) {
    this.voicemailBox.map(data.voicemailBox)
    _.extend(this, _.omit(data, ['voicemailBox']))

    if (this.map) {
      this.map(data)
    }
  }

  _build (payload) {
    const voicemailBox = this.voicemailBox.build(payload.voicemailBox)
    _.extend(payload, _.omit(this, ignoredAttrs))
    payload.voicemailBox = voicemailBox

    if (this.build) {
      payload = this.build(payload)
    }

    return payload
  }
}
