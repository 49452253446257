/**
 * @ngdoc function
 * @name platformApp.factory:UnavailableLine
 * @description
 * # UnavailableLine
 * Service of the platformApp
 */
import _module from 'module.js'
import BaseES6 from 'factories/baseES6'

let _portalApi, _globalState

export default class UnavailableLine extends BaseES6 {
  constructor (id, unavailableLine, gettextCatalog) {
    'ngInject'
    super(id || _globalState.selectedPbxUUID)
    this.gettextCatalog = gettextCatalog

    if (id === undefined) {
      throw new Error(
        this.gettextCatalog.getString('Missing id for new UnavailableLine')
      )
    }

    if (unavailableLine) {
      this._map(unavailableLine)
    }
  }

  getApi () {
    if (!this._api) {
      this._api = _portalApi.userAgent.pbxs(
        _globalState.selectedPbxUUID
      ).unavailableLines
    }

    return this._api
  }

  static factory (globalState, portalApi, gettextCatalog) {
    'ngInject'
    _portalApi = portalApi
    _globalState = globalState

    return (id, unavailableLine) => {
      return new UnavailableLine(id, unavailableLine, gettextCatalog)
    }
  }
}

_module.factory('UnavailableLine', UnavailableLine.factory)
