import BaseES6 from 'factories/baseES6'
import jrnParser from 'classes/jrnParser'

const callFlowFields = [
  'extensionsUsingSharedVoicemailBox',
  'lines',
  'dialPlans',
  'ringGroups',
  'callQueues',
  'directories',
  'callFilters',
  'dids',
  'extensionMappings'
]

export default class ExtensionBaseES6 extends BaseES6 {
  constructor (id) {
    super(id)
    this.cacheEnabled = true
    this.references = {}
  }

  mapListItem (ext) {
    const number = ext.extensionNumber ? `${ext.extensionNumber}: ` : ''
    return {
      id: ext.jrn,
      text: number + ext.name || ''
    }
  }

  async getUsage () {
    this.usageLoading = true
    this.references = await this.getReferencesApi().get()
    this.usageLoading = false
    if (!this.references) {
      return
    }

    this.references.extensionsCount = 0
    this.references.directoryCount = 0
    this.references.callFlowCount = 0

    if (this.references.userAgentConfigurations) {
      this._populateId(this.references.userAgentConfigurations, device => {
        this._populateId(device.extensions)
      })
    }

    callFlowFields.forEach(field => this._countCallFlow(field))
  }

  cleanListItem (ext) {
    return {
      jrn: ext.jrn,
      extensionNumber: ext.extensionNumber,
      name: ext.name,
      omitFromAutomaticDirectories: ext.omitFromAutomaticDirectories,
      type: ext.type,
      voicemailBoxId: ext.voicemailBoxId
    }
  }

  _countCallFlow (field) {
    if (this.references[field]) {
      this._populateId(this.references[field])
      this.references.callFlowCount += this.references[field].length
    }
  }

  _populateId (resources, cbk) {
    resources.forEach(resource => {
      if (resource.jrn) {
        resource.id = jrnParser.parse(resource.jrn).getResources()[1]
      }
      if (cbk) {
        cbk(resource)
      }
    })
  }
}
