import _module from 'module.js'
import buttonConfigurationModeTemplate
  from './buttonConfigurationModeTemplate.html'
import buttonConfigurationModeController
  from './buttonConfigurationModeController.js'

_module.component('buttonConfigurationMode', {
  template: buttonConfigurationModeTemplate,
  require: {
    ngModel: 'ngModel'
  },
  bindings: {
    buttons: '=ngModel',
    configs: '<'
  },
  controllerAs: 'buttonConfigurationMode',
  controller: buttonConfigurationModeController
})
