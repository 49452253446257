import jrnParser from 'classes/jrnParser'

let _jrn, _state, _stateParams, _SoundClip
export default class GetSoundClipController {
  constructor (gettextCatalog, SoundClip) {
    'ngInject'
    this.showAudioPreview = true
    this._model = {}
    _jrn = jrnParser
    _SoundClip = SoundClip

    this.placeholder = gettextCatalog.getString('Select a sound clip...')
    this.addNewLabel = gettextCatalog.getString('Record a sound clip')

    this.$onInit = () => {
      this.setViewValue = item => {
        this.ngModel.$setViewValue(item.id)
      }
    }
  }

  api (id = null) {
    return new _SoundClip(id)
  }

  map (clip) {
    return {
      id: clip.jrn,
      text: clip.name
    }
  }
}
