import _module from 'module.js'
import confirmationTemplateOK from './confirmationTemplateOK.html'
import ConfirmationWindowBase
  from 'services/confirmation/confirmationWindowBase'

export default class confirmationWindowOK extends ConfirmationWindowBase {
  constructor ($mdDialog) {
    'ngInject'
    super($mdDialog)
  }

  show (title, message, isNoCancel = false) {
    super.show(title, message)
    this.isNoCancel = isNoCancel
    return this.$mdDialog.show({
      template: confirmationTemplateOK,
      controller: () => this,
      controllerAs: 'ctrl'
    })
  }
}

_module.service('confirmationWindowOK', confirmationWindowOK)
