import _module from 'module.js'

const MAX_AGE = 5 * 60 * 1000

class UserAgentTypeService {
  constructor (Restangular, portalConfig, CacheFactory) {
    'ngInject'
    this.api = Restangular.withConfig(restangularConfig => {
      restangularConfig.setBaseUrl(portalConfig.userAgentTypeApiUrl)

      let uatCache = CacheFactory.createCache('user-agent-types.cache', {
        capacity: 1000,
        maxAge: MAX_AGE,
        deleteOnExpire: 'aggressive',
        storagePrefix: 'jive.'
      })

      restangularConfig.addFullRequestInterceptor(
        (element, operation, what, url, headers, params, httpConfig) => {
          let cacheParam = params.cache
          // Don't pass cache param to server
          delete params.cache

          if (operation.toLowerCase() === 'get') {
            if (!element.id || (element.id && cacheParam)) {
              return {
                httpConfig: {
                  cache: uatCache
                }
              }
            }
          }
        }
      )
    })

    const restangularApiService = this.api.service('user-agent-types')
    this.commonApiService = (...args) => {
      let id = null
      if (args.length === 1) {
        if (typeof args[0] === 'string') {
          id = args[0]
        }
      } else {
        id = args[0]
      }

      if (id !== null && id !== undefined) {
        return restangularApiService.one(id)
      } else {
        return restangularApiService.one()
      }
    }
  }

  getUserAgentType (userAgentTypeId) {
    return this.api
      .one('user-agent-types')
      .one(userAgentTypeId)
      .get()
      .then(data => data.plain())
  }

  listUserAgentTypes ({ query, page, pageSize } = {}) {
    return this.api
      .one('user-agent-types')
      .get({
        q: query,
        page: page,
        pageSize: pageSize
      })
      .then(data => data.plain())
  }

  getCommonApiService () {
    return this.commonApiService
  }

  mapUserAgentTypeIdToJrn (userAgentTypeId) {
    return `jrn:user-agent::jive::user-agent-type/${userAgentTypeId}`
  }
}

_module.service('userAgentTypeService', UserAgentTypeService)
