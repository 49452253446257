import _module from 'module.js'
import _ from 'lodash'
import ListControllerBase from 'classes/listController.class'

export default class JbcsController extends ListControllerBase {
  constructor (
    $state,
    $stateParams,
    $location,
    $analytics,
    Jbcs,
    gettextCatalog,
    infiniteLoader,
    navigation,
    globalState,
    portalUtil,
    jbcEditPopup,
    pbxUtil
  ) {
    'ngInject'
    super(
      gettextCatalog,
      $analytics,
      $location,
      portalUtil,
      globalState,
      navigation,
      $state,
      $stateParams
    )

    this.gettextCatalog = gettextCatalog
    this.jbcEditPopup = jbcEditPopup
    this.pbxUtil = pbxUtil
    this.pbxData = globalState.selectedPbx

    this.isWhiteLabel = globalState.isWhiteLabel
    this.isNA = globalState.isNA
    this.alertCenterEnabled = globalState.selectedPbx.alertCenterEnabled

    this.title = this.isWhiteLabel
      ? this.gettextCatalog.getString('Business Continuity')
      : this.gettextCatalog.getString('JBC')

    this.description = this.gettextCatalog.getString(
      'Business Continuity provides an automatic, onsite, emergency backup for your voice' +
        ' traffic through dedicated hardware and proprietary business-continuity software.'
    )

    this.emptyListText = this.isWhiteLabel
      ? this.gettextCatalog.getString(
          'Business Continuity requires aditional hardware to operate. Please contact your sales representative for more information.'
        )
      : this.gettextCatalog.getString(
          'JBCs require additional hardware to operate.'
        )

    this.alertCenterButtonText = this.gettextCatalog.getString('Alert Center')

    this.hideSearch = true

    this.headers = [
      {
        width: 25,
        name: this.gettextCatalog.getString('Name')
      },
      {
        width: 15,
        name: this.gettextCatalog.getString('Status')
      },
      {
        width: 10,
        name: this.gettextCatalog.getString('IP Address')
      },
      {
        width: 20,
        name: this.gettextCatalog.getString('MAC Address')
      },
      {
        width: 30,
        name: this.gettextCatalog.getString('PSTN Gateway URI')
      }
    ]

    this.loader = infiniteLoader(Jbcs, ({ items }) => this.onDataLoaded(items))
  }

  onDataLoaded (jbcs = []) {
    jbcs.forEach(jbc => jbc.getJbcStatus())
  }


  delete () {}

  goToAlertCenter () {
    this.pbxUtil.goToAlertCenter(this.pbxData)
  }

  itemSelected (item) {
    this.jbcEditPopup.open(item).then(update => {
      for (let i = 0; i < this.data.items.length; i++) {
        if (this.data.items[i].id === update.id) {
          this.data.items[i] = update
          break
        }
      }
    })
  }
}

_module.controller('JbcsController', JbcsController)
