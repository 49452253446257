import _module from 'module.js'
import _ from 'lodash'

_module.controller('ConferenceRoomCallbackController', function (
  $scope,
  $stateParams,
  $location,
  $state,
  $timeout,
  gettextCatalog,
  portalApi,
  $analytics,
  portalUtil,
  globalState
) {
  'ngInject'
  let sendAt = $stateParams.sendAt
  let pbxId = globalState.selectedPbxUUID
  let conferenceId = $stateParams.extId

  this.callbackScheduled = false

  /* callback */
  this.scheduleCallback = () => {
    let request = {
      sendAt: $stateParams.sendAt,
      urlOfEndpoint: 'https://api.jive.com/conferencing/v1/pbxs/' +
        pbxId +
        '/conferences/' +
        conferenceId +
        '/participants',
      phoneNumber: $scope.phoneNumber,
      participantPin: $scope.data.password
    }
    let scheduleCallbackApi = portalApi.extension
      .pbxs(pbxId)
      .conferenceRooms(conferenceId)
      .one('callback')
    _.extend(scheduleCallbackApi, request)
    scheduleCallbackApi
      .put()
      .then(() => {
        this.callbackScheduled = true
        $timeout(() => {
          this.callbackScheduled = false
        }, 5000)
      })
      .catch(error => {
        portalUtil.showErrorAlert('Callback scheduling failed, please retry.')
        throw error
      })
  }
})
