import _module from 'module.js'
import _ from 'lodash'
import moment from 'moment-timezone'
import BaseES6 from 'factories/baseES6'

export default class Device extends BaseES6 {
  constructor (id, device, pbxId, gettextCatalog) {
    super(_stateParams.deviceId || id, _globalState.selectedPbxUUID || pbxId)

    this.gettextCatalog = gettextCatalog

    if (id === undefined) {
      throw new Error(
        this.gettextCatalog.getString('Missing id for new Device')
      )
    }

    this.buttons = {}
    this.customAttributes = {}
    this.lineAssignments = []

    if (device) {
      this._map(device)
    }
  }

  getApi () {
    if (!this._api) {
      this._api = _portalApi.userAgent.pbxs(this._pbxId).userAgentConfigurations
    }
    return this._api
  }

  getPath () {
    if (!this._path) {
      this._path = _portalApi.userAgent
        .pbxs(this._pbxId)
        .userAgentConfigurations(this._id)
    }

    return this._path
  }

  getReferencesPath () {
    if (!this._referencesPath) {
      this._referencesPath = _portalApi.userAgent
        .pbxs(this._pbxId)
        .userAgentConfigurations(this._id)
        .one('references')
    }
    return this._referencesPath
  }

  map (data) {
    if (!this.useUserFullNameAsName) {
      this.specifiedName = data.name
    }
    if (!this.useUserFullNameAsStationLabel) {
      this.specifiedStationLabel = data.stationLabel
    }
    if (this.userAgent) {
      this.parent = data.parent
    }

    if (data.userAgentTypeModel) {
      this._userAgentTypeModel = {
        id: data.userAgentTypeModel.jrn,
        text: data.userAgentTypeModel.displayString
      }
    }

    let timeZoneToUse = data.timeZone;
    this.useUaTimeZoneSource = !!data.timeZoneSource;
    let tzSourceToUse = this.useUaTimeZoneSource ? data.timeZoneSource : data.usePbxTimeZone ? "PBX" : "DEVICE";

    if (tzSourceToUse === "USER" && data.assignedUser && data.userTimeZone && this.useUaTimeZoneSource) {
      timeZoneToUse = data.userTimeZone;
    } else if (tzSourceToUse === "DEVICE") {
      timeZoneToUse = data.timeZone;
    } else {
      tzSourceToUse = "PBX";
      timeZoneToUse = data.pbxTimeZone;
    }

    this._timeZoneSource = tzSourceToUse;

    if (timeZoneToUse) {
      this.timeZoneToUse = moment()
        .tz(timeZoneToUse)
        .format('z')

      this._timeZone = {
        id: timeZoneToUse,
        text: timeZoneToUse,
        offset: moment.tz(timeZoneToUse).format('Z z')
      };
    } else {
      if (data.timeZone) {
        this._timeZone = {
          id: data.timeZone,
          text: data.timeZone
        }
      }
    }

    if (data.activationDate) {
      this.activationDate = new Date(data.activationDate + ' 00:00:00')
    }

    this.isNotActivated = this.activationDate
      ? moment(data.activationDate).isAfter(moment.utc())
      : false

    if (data.assignedUser) {
      let name = data.assignedUserLastName
      if (data.assignedUserFirstName) {
        name = data.assignedUserFirstName + ' ' + name
      }
    }
    if (data.extensions) {
      this._extensions = data.extensions.map(function (item) {
        let number = ''
        if (item.extensionNumber) {
          number = item.extensionNumber + ': '
        }
        return {
          id: item.extension,
          text: number + item.name
        }
      })
    }
    if (data.model) {
      this._model = {
        id: data.intendedUserAgentType,
        text: data.model
      }
    }
    if (data.macAddress) {
      this.macAddress = data.macAddress
    }

    if (data.specifiedName) {
      this.specifiedName = data.specifiedName
    }

    this.userAgentType = data.userAgentType
    this._lowUsageDevice = data.lowUsageDevice

    _.forEach(this.buttons, button => {
      if (button.type === 'LINE' || button.type === 'LINE_SILENT') {
        this.lineAssignments.push(button)
      }
    })

    this._useDefaultRegistrationProxy = !data.registrationProxy
    this._useDefaultOutboundProxy = !data.outboundProxy

    if (data.trunkEndpointIpAddress && data.trunkEndpointSipPort) {
      this._trunkEndpointIpAddressPort =
        data.trunkEndpointIpAddress + ':' + data.trunkEndpointSipPort
    }

    this._markSublocationNA = !data.useSublocation
  }

  build (payload) {
    let name = this.assignedUserLastName
    if (this.assignedUserFirstName) {
      name = this.assignedUserFirstName + ' ' + name
    }

    if (this.assignedUser) {
      payload.assignedUser = this.assignedUser
      if (payload.useUserFullNameAsStationLabel === undefined) {
        payload.useUserFullNameAsStationLabel = payload.isNew
      }
    } else {
      payload.useUserFullNameAsName = false
      payload.useUserFullNameAsStationLabel = false
    }

    payload.specifiedName = payload.useUserFullNameAsName
      ? name
      : this.specifiedName

    payload.specifiedStationLabel = this.useUserFullNameAsStationLabel
      ? name
      : this.specifiedStationLabel

    if (this._userAgentTypeModel) {
      payload.intendedUserAgentType = this._userAgentTypeModel.id
    }

    if (this._timeZone) {
      payload.timeZone = this._timeZone.id
    }

    if (this._timeZoneSource) {
      payload.timeZoneSource = this._timeZoneSource;
      payload.usePbxTimeZone = this._timeZoneSource === "PBX";
    }

    if (this.activationDate) {
      payload.activationDate = moment(this.activationDate).format('YYYY-MM-DD')
    }

    payload.corporateDirectory = this.useDefaultCorporateDirectory
      ? null
      : this.corporateDirectory

    payload.customAttributes = this.customAttributes || {}

    payload.registrationProxy = this._useDefaultRegistrationProxy
      ? null
      : this.registrationProxy

    payload.outboundProxy = this._useDefaultOutboundProxy
      ? null
      : this.outboundProxy

    if (this._trunkEndpointIpAddressPort) {
      ;[
        payload.trunkEndpointIpAddress,
        payload.trunkEndpointSipPort
      ] = this._trunkEndpointIpAddressPort.split(':')
    } else {
      payload.trunkEndpointIpAddress = null
      payload.trunkEndpointSipPort = null
    }

    payload.useSublocation = !this._markSublocationNA

    return payload
  }

  async getUserAgent (userAgentId) {
    const userAgent = await _portalApi.userAgent
      .userAgentTypes(userAgentId)
      .get()
    return userAgent.plain()
  }

  get (options = {}) {
    const _this = this
    return this.getPath()
      .get(options)
      .then(device => {
        this._map(device.plain())
        return _this
      })
      .then(async device => {
        if (!device.userAgentType) {
          return device
        }
        const userAgentId = device.userAgentType.split('/')[1]
        device.userAgentType = await this.getUserAgent(userAgentId)
        return device
      })
  }

  mapListItem (device) {
    return {
      id: device.jrn,
      text: device.name
    }
  }

  getClass () {
    return 'Device'
  }
}

let _stateParams, _portalApi, _globalState

export function DeviceFactory (
  $stateParams,
  portalApi,
  gettextCatalog,
  globalState
) {
  'ngInject'
  _stateParams = $stateParams
  _portalApi = portalApi
  _globalState = globalState

  return (id, device, pbxId = null) => {
    return new Device(id, device, pbxId, gettextCatalog)
  }
}

_module.factory('Device', DeviceFactory)

export const BILLALBLE_HARDWARE =["vtech vsp600","yealink sip w52p","yealink sip w60b","snom m3", "snom m9", "polycom kirk 300 ","panasonic kx tgp500", "panasonic kx tgp550", "panasonic kx tgp600", "linksys wrtp 54g","linksys spa 8000", "linksys spa 3102", "linksys pap 2", "linksys pap 2t", "linksys rtp 300", "linksys spa 1000","linksys spa 1001", "linksys spa 112", "linksys spa 122", "linksys spa 2000", "linksys spa 2002","linksys spa 2102", "linksys spa 232"]
