import _module from 'module.js'

/**
 * @ngdoc service
 * @name platformApp.api
 * @description
 * # auth interceptor
 * Factory in the platformApp.
 */

const needsAuthHeader = config => {
  if (config.url) {
    for (let i = 0; i < authUrls.length; i++) {
      if (config.url.indexOf(authUrls[i]) === 0) {
        return true
      }
    }
  }

  return false
}

const needsAmbassadorHeader = config => {
  if (is.include(config.url, '/ambassador/')) {
    return true
  }
  return false
}

let _q, _authService, _window, _analytics, _portalConfig, _globalState, authUrls

export default class AuthInterceptor {
  request(config) {
    // Return promise that will either A) return a config, or B) fail
    return _q((resolve, reject) => {
      // Is ambassador Client-App-Id required?
      if (needsAmbassadorHeader(config)) {
        config.headers['Client-App-Id'] = _globalState.ambassadorClientAppId
      }
      // Is this something we should be adding an Authorization header to?
      if (needsAuthHeader(config)) {
        // If so, get the auth info and add the header
        _authService.getAuthInfo().then(
          authInfo => {
            config.headers.Authorization = authInfo.type + ' ' + authInfo.token
            resolve(config)
          },
          reason => {
            reject(reason)
          }
        )
      } else {
        // Otherwise, pass on through untouched
        resolve(config)
      }
    })
  }

  responseError(rejection) {
    rejection.config = rejection.config || {}

    let errorMessage =
      'Error: ' +
      (rejection.config ? rejection.config.method : 'Null') +
      '-' +
      rejection.status +
      ', ' +
      rejection.config.url
    _analytics.eventTrack(errorMessage, { category: 'HTTP Error' })

    if (
      rejection.status === 403 &&
      rejection.data &&
      rejection.data.errorCode === 'AUTHZ_INSUFFICIENT_SCOPE'
    ) {
      _authService.reAuthenticate((err, authInfo) => {
        if (authInfo && authInfo.inflightRequest) {
          _window.location.assign(new URL(decodeURIComponent(authInfo.inflightRequest), window.location.toString()).pathname)
        }
      })
    } else if (rejection.status === 401 || rejection.status === 403) {
      let config = {
        status: rejection.status
      }

      _globalState.selectedPbx = null
      _globalState.noPbx = true

      if (rejection.status === 401) {
        // force logout
        _authService.reAuthenticate((err, authInfo) => {
          if (authInfo && authInfo.inflightRequest) {
            _window.location.assign(new URL(decodeURIComponent(authInfo.inflightRequest), window.location.toString()).pathname)
          }
        })
      }
    }
    return _q.reject(rejection)
  }

  static factory(
    $q,
    authService,
    $window,
    $analytics,
    portalConfig,
    globalState
  ) {
    'ngInject'
    _q = $q
    _authService = authService
    _window = $window
    _analytics = $analytics
    _portalConfig = portalConfig
    _globalState = globalState

    authUrls = Object.freeze([
      _portalConfig.apiBase,
      _portalConfig.importExportBase,
      _portalConfig.featuresApiUrl,
      _portalConfig.hotDeskingApiUrl,
      _portalConfig.deviceFeaturesApiUrl,
      _portalConfig.userAgentTypeApiUrl,
      _portalConfig.ambassadorApiIntegrations,
      _portalConfig.userMappingApiIntegrations,
      _portalConfig.userSyncManagementApiUrl,
      _portalConfig.contactCenterConfigurationApiUrl,
      _portalConfig.contactCenterAuthorityApiUrl,
      _portalConfig.orderingApiUrl,
      _portalConfig.numbersApiUrl,
      _portalConfig.locationsBffApiUrl,
      _portalConfig.phoneNumbersSettingsApiUrl
    ])

    return new AuthInterceptor()
  }
}

_module.factory('authInterceptor', AuthInterceptor.factory)
