import _module from 'module.js'
import _ from 'lodash'

_module
  // Include lodash
  .constant('_', window._)
  // Include libphonenumber
  .constant('phoneUtils', window.phoneUtils)
  .constant('LIMIT_DEFAULT', 50)
  .constant('LIST_LIMIT_DEFAULT', 50)
  .constant('DEVICE_RESYNC_DELAY', 3000)
  .constant('DEVICE_RESYNCALL_DELAY', 7000)
  .run(function (authService, $window) {
    'ngInject'
    authService.doAuth((err, authInfo) => {
      if (authInfo && authInfo.inflightRequest) {
        $window.location.assign(new URL(decodeURIComponent(authInfo.inflightRequest), window.location.toString()).pathname)
      }
    })
  })
  .run(function (
    $rootScope,
    $state,
    $location,
    langSwitcher,
    userService,
    $window,
    $timeout,
    $localStorage,
    portalUtil,
    globalState,
    errorService,
    gettextCatalog
  ) {
    'ngInject'
    let initialRequest = true

    let stateChangeStarts = 0
    $rootScope.$on('$stateChangeStart', function (
      event,
      toState,
      toParams
    ) {
      $rootScope.busy = true

      if (toState.name === 'root.nav.pbx') {
        event.preventDefault()
        $state.go('root.nav.pbx.dashboard', toParams)
      }

      const search = $location.search()

      let isCrmMappingRedirect = false
      if (search.state) {
        const [, selectedTab] = search.state.split('/')
        if (selectedTab === 'crmmapping') {
          isCrmMappingRedirect = true
        }
      }

      if (toState.name === 'root.nav.pbx.dashboard' && isCrmMappingRedirect) {
        event.preventDefault()
        $state.go('root.nav.pbx.zohoMapping.list', toParams)
      }

      stateChangeStarts++
      if (initialRequest) {
        let pbxId = null
        if (toParams) {
          pbxId = toParams.id
        }
        if (!pbxId) {
          toParams.id = $localStorage.lastPbx || ''
        }
        initialRequest = false
      }

      if (!_.isEmpty(toParams.id)) {
        $localStorage.lastPbx = toParams.id
      } else {
        toParams.id = $localStorage.lastPbx
      }

      // Work around for ui-router 'reloadOnSearch' keeps language param correct
      if (search && search.lang !== toParams.lang && stateChangeStarts < 2) {
        event.preventDefault()
        toParams.lang = search.lang || null
        $state.transitionTo(toState.name, toParams)
      }
    })

    $rootScope.$on('$stateChangeSuccess', function (event, toState) {
      $rootScope.busy = false
      if ($location.host() !== 'localhost') {
        ga('send', 'pageview', toState.name)
        ga('marketing.send', 'pageview')
      }
      stateChangeStarts = 0
    })

    $rootScope.$on('$locationChangeSuccess', function(event, newUrl, oldUrl, newState, oldState) {
      // reload the page when loading usage tab under extensions (lines, ringgroups, etc)
      // so that we only call /references API when needed
      if (newUrl !== oldUrl
        && newUrl.indexOf('/usage') !== -1
        && newUrl.indexOf('/extensions/' !== -1)
        ) {
          $window.location.reload()
      }
    })

    $rootScope.$on('$stateChangeError', function (
      event,
      toState,
      toParams,
      fromState,
      fromParams,
      error
    ) {
      $rootScope.busy = false
      // This is to handle error thrown during the resolve in the state transition
      // https://github.com/angular-ui/ui-router/wiki#state-change-events
      if (_.includes(toState.name, 'root.nav.pbx.permissions')) {
        $rootScope.denied = true
      }

      let errorMessage = errorService.getErrorMessage(error)
      portalUtil.showErrorAlert(
        _.isEmpty(errorMessage)
          ? gettextCatalog.getString('Data retrieval failed, please retry.')
          : errorMessage
      )
    })
  })
  .config(function ($httpProvider) {
    'ngInject'
    $httpProvider.useApplyAsync(true)
  })
  .config(function ($analyticsProvider) {
    'ngInject'
    // turn off automatic tracking
    $analyticsProvider.virtualPageviews(false)
  })
  .config(function ($httpProvider) {
    'ngInject'
    $httpProvider.interceptors.push('authInterceptor')
  })
  .config(function (langSwitcherProvider, languagesProvider) {
    'ngInject'
    langSwitcherProvider.languages = languagesProvider.$get()
  })
  .config(function (
    $provide,
    $locationProvider,
    gettext,
    $stateProvider,
    $urlRouterProvider,
    RestangularProvider
  ) {
    'ngInject'
    RestangularProvider.setDefaultHttpFields({
      cache: false
    })

    RestangularProvider.setRequestInterceptor(function (elem, operation) {
      if (operation === 'remove') {
        return undefined
      }
      return elem
    })

    // TODO: add client version. NEed to fix CORS througj
    // $httpProvider.defaults.headers.common['X-Jive-App'] = 'Portal/V5'

    // only run in html5 mode for now.
    $locationProvider.html5Mode(true)
    $locationProvider.hashPrefix(null)
  })
  .config(function ($urlMatcherFactoryProvider) {
    'ngInject'
    $urlMatcherFactoryProvider.strictMode(false)
  })
  .config(function ($mdThemingProvider) {
    'ngInject'
    $mdThemingProvider
      .theme('default')
      .primaryPalette('light-blue', {
        default: 'A700'
      })
      .accentPalette('grey', {
        default: '200'
      })
      .warnPalette('red', {
        default: '600'
      })
  })
  .config(function (partnerInfoProvider) {
    'ngInject'
    partnerInfoProvider.defaultIcons = false
  })

const getElemWhenReady = (selector, cbk) => {
  const elem = document.querySelector(selector)
  if (elem) {
    cbk(elem)
  } else {
    setTimeout(() => getElemWhenReady(selector, cbk), 100)
  }
}

let adminLinkClicked = false
function listenAdminLinkClick () {
  getElemWhenReady('#administrationLink', link =>
    link.addEventListener('click', () => {
      adminLinkClicked = true
      setTimeout(() => (adminLinkClicked = false), 500)
    })
  )
}
listenAdminLinkClick()
