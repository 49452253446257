import _ from 'lodash'

export default class MusicListsItemsController {
  constructor (portalApi, $stateParams, gettextCatalog, holdMusic, $timeout) {
    'ngInject'
    this.portalApi = portalApi
    this.$stateParams = $stateParams
    this.gettextCatalog = gettextCatalog
    this.holdMusic = holdMusic
    this.songs = []
    this.currentType = null
    this.noMoreData = true
    this.query = null
    this.$timeout = $timeout
    this.$onInit = () => {
      this.ngModel.$render = () => {
        this.data = this.ngModel.$modelValue
        this.songs = this.data.items
        this.noMoreData = this.data.noMoreData
        this.query = null

        // using timeoout so that the spinning-wheel showing up.
        this.$timeout(() => {
          this.data.HMLoading = false
        }, 100)
      }
    }

    this.requestMoreMusics = _.debounce(() => {
      this.data.HMLoading = true
      if (this.ngModel.$modelValue.offset > 0) {
        this.holdMusic
          .requestMoreMusics(this.ngModel.$modelValue.type.value, this.query)
          .then(data => {
            this.songs = this.songs.concat(this.items)
            this.noMoreData = this.noMoreData
            this.data.HMLoading = false
          })
      }
    }, 200)

    this.applyQuery = _.debounce(query => {
      this.data.HMLoading = true
      this.query = query
      this.songs = []
      this.noMoreData = false
      this.holdMusic
        .applyQuery(this.ngModel.$modelValue.type.value, this.query)
        .then(data => {
          this.songs = this.songs.concat(data.items)
          this.noMoreData = data.noMoreData
          this.data.HMLoading = false
        })
    }, 200)
  }

  deleteSongFromList (song) {
    this.songs = _.reject(this.songs, _song => {
      return _song.jrn === song.jrn
    })
  }

  fileUploaded (song) {
    this.songs.unshift(song)
  }
}
