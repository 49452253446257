/**
 * @ngdoc function
 * @name platformApp.factory:VirtualFaxMachineService
 * @description
 * # VirtualFaxMachineService
 * Controller of the platformApp
 */
import _module from 'module.js'
import _ from 'lodash'
import ExtensionBaseES6 from 'factories/extensionBaseES6'

let _portalApi, _globalState
let _stringFaxNumber, _stringOptionalFaxTitle

export default class VirtualFaxMachine extends ExtensionBaseES6 {
  constructor (id, virtualFaxMachine, gettextCatalog) {
    'ngInject'
    super(id)
    this.gettextCatalog = gettextCatalog

    if (id === undefined) {
      throw new Error(
        this.gettextCatalog.getString('Missing id for new VirtualFaxMachine')
      )
    }

    if (virtualFaxMachine) {
      this._map(virtualFaxMachine)
    }
  }

  getApi () {
    if (!this._api) {
      this._api = _portalApi.extension.pbxs(
        _globalState.selectedPbxUUID
      ).virtualFaxMachines
    }

    return this._api
  }

  getPath () {
    if (!this._path) {
      this._path = _portalApi.extension
        .pbxs(_globalState.selectedPbxUUID)
        .virtualFaxMachines(this._id)
    }

    return this._path
  }

  getReferencesPath () {
    if (!this._referencesPath) {
      this._referencesPath = _portalApi.extension
        .pbxs(_globalState.selectedPbxUUID)
        .virtualFaxMachines(this._id)
        .one('references')
    }
    return this._referencesPath
  }

  map (data) {
    let domain = _globalState.selectedPbx.domain
    if (domain !== null) {
      if (_globalState.selectedPbx.partnerInfo.brandedDomain === 'jive.com') {
        this.toField =
          _stringFaxNumber +
          domain +
          '.fax.on' +
          _globalState.selectedPbx.partnerInfo.brandedDomain
      } else {
        this.toField =
          _stringFaxNumber +
          domain +
          '.fax.' +
          _globalState.selectedPbx.partnerInfo.brandedDomain
      }
    }
    this.title = data.extensionNumber + ': ' + data.name
    this.subject = _stringOptionalFaxTitle
    if (this.accessCode) {
      this.subject = this.accessCode + ' ' + this.subject
    }
    this.extensionRegion = data.extensionRegion ? data.extensionRegion : ''
    this._mapPermissions(data)
  }

  _mapPermissions(data) {
    this.permissions = data.permissions ? data.permissions.map(ace => {
      ace.id = ace.identity
      ace.text = this._getIdentityName(ace)
      ace.active = false
      ace.viewReceivedFaxes = this._hasPermission(ace, 'VIEW_RECEIVED_FAXES')
      ace.deleteReceivedFaxes = this._hasPermission(ace, 'DELETE_RECEIVED_FAXES')
      ace.sendFaxes = this._hasPermission(ace, 'SEND_FAXES')
      ace.deleteOwnSentFaxes = this._hasPermission(ace, 'DELETE_OWN_SENT_FAXES')
      ace.viewAllSentFaxes = this._hasPermission(ace, 'VIEW_ALL_SENT_FAXES')
      ace.deleteAllSentFaxes = this._hasPermission(ace, 'DELETE_ALL_SENT_FAXES')

      return ace
    }) : []
  }

  build (payload) {
    payload.extensionNumber = this.extensionNumber || null
    this._buildPermissions(payload)

    return payload
  }

  _buildPermissions(payload) {
    payload.permissions = this.permissions.map(ace => {
      const permissions = []

      if (ace.viewReceivedFaxes) {
        permissions.push('VIEW_RECEIVED_FAXES')
      }
      if (ace.deleteReceivedFaxes) {
        permissions.push('DELETE_RECEIVED_FAXES')
      }
      if (ace.sendFaxes) {
        permissions.push('SEND_FAXES')
      }
      if (ace.deleteOwnSentFaxes) {
        permissions.push('DELETE_OWN_SENT_FAXES')
      }
      if (ace.viewAllSentFaxes) {
        permissions.push('VIEW_ALL_SENT_FAXES')
      }
      if (ace.deleteAllSentFaxes) {
        permissions.push('DELETE_ALL_SENT_FAXES')
      }

      return {
        identity: ace.id,
        permissions
      }
    })
  }

  getClass () {
    return 'VirtualFaxMachine'
  }

  static factory (globalState, portalApi, gettextCatalog) {
    'ngInject'
    _portalApi = portalApi
    _globalState = globalState

    _stringFaxNumber = gettextCatalog.getString('[FaxNumber]@')
    _stringOptionalFaxTitle = gettextCatalog.getString('[Optional Fax Title]')

    return (id, vfm) => {
      return new VirtualFaxMachine(id, vfm, gettextCatalog)
    }
  }

  _getIdentityName ({ identity, userGroupName, userFirstName, userLastName }) {
    if (!identity) {
      return this.gettextCatalog.getString('Everyone')
    }
    if (!userLastName) {
      return userGroupName
    }
    return [userFirstName, userLastName]
      .filter(str => str && str.length)
      .join(' ')
  }

  _hasPermission (ace, permission) {
    return !!ace.permissions.find(p => p === permission)
  }
}

_module.factory('VirtualFaxMachineFactory', VirtualFaxMachine.factory)
