/**
 * @ngdoc filter
 * @name platformApp.filter:expiration
 * @description
 * # expiration
 */
import _module from 'module.js'

_module.filter('expiration', function (gettextCatalog) {
  return function (input) {
    if (!input) {
      return gettextCatalog.getString('Never')
    }
    return input
  }
})
