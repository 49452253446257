import _module from 'module.js'
import addUserFormTemplate from '../views/common/addUserForm.html'
import jrnParser from 'classes/jrnParser'
import {
  GENERAL_PERMISSIONS_MAP,
  PERMISSION_CHILDREN,
  validatePermissions,
  buildGeneralPermissions
} from 'classes/permissionsUtils'
import { waitForUserToExist, updateUserRole } from '@ucc/phoenix'

class AddUserFormController {
  constructor (
    Device,
    Line,
    User,
    Hardware,
    portalApi,
    $stateParams,
    $analytics,
    gettextCatalog,
    portalUtil,
    $mdDialog,
    globalState,
    FEATURE_FLAGS
  ) {
    'ngInject'
    this.createNewHardware = false
    this.form = {
      device: {
        useUserFullNameAsName: true,
        useUserFullNameAsStationLabel: true,
        presenceAware: true
      },
      user: {},
      permissions: {
        type: 'NONE'
      },
      busy: false
    }

    this.oauthToken = globalState.oauthToken
    this.Device = Device
    this.Line = Line
    this.User = User
    this.Hardware = Hardware
    this.portalApi = portalApi
    this.$stateParams = $stateParams
    this.jrn = jrnParser
    this.$analytics = $analytics
    this.gettextCatalog = gettextCatalog
    this.portalUtil = portalUtil
    this.$mdDialog = $mdDialog
    this.globalState = globalState
    this.userIsPlatformLevel = globalState.isPlatform
    this.locale = globalState.currentLanguage.langCode
    this.isWhiteLabel = globalState.isWhiteLabel
    this.lmiAccountKey = globalState._selectedPbx.lmiAccountKey
    this.isCallReportRecordingsEnabled =
      globalState.isCallReportRecordingsEnabled
    this.billingPortalAccess = globalState._selectedPbx.billingPortalAccess
    this.showPermissions = globalState.writePermissions
    this.showExtensionWarning = globalState.selectedPbx.extensionDigits == 3
    this.hasManageUsersPerm = globalState.isUserManageUsersOnly
    this.featureManageSeatsEnabled = globalState.selectedPbx.featureFlags[FEATURE_FLAGS.manageSeatsPerm];
    this.featureManageUsersEnabled = globalState.selectedPbx.featureFlags[FEATURE_FLAGS.manageUsersPerm];
    this.featureManageSettingsEnabled = globalState.selectedPbx.featureFlags[FEATURE_FLAGS.manageSettingsPerm];
    this.featureManageGroupsEnabled = globalState.selectedPbx.featureFlags[FEATURE_FLAGS.manageGroupsPerm];

    this.GENERAL_PERMISSIONS_MAP = GENERAL_PERMISSIONS_MAP

    // Defaults
    this.assignDevice = false
    this.enableSoftphone = !globalState.isUserManageUsersOnly
    this.deviceHardwareSelector = 'SELECT'
    this.userRole = 'MEMBER'

    this._validatePermissions()
    this.handleRoleChange = this.handleRoleChange.bind(this)
  }

  correctEmail (email) {
    this.form.user.emailAddress = email
  }

  _onParentPermissionChange (permission) {
    if (this.form.permissions[permission]) {
      return
    }
    // uncheck children if parent is unchecked
    const children = PERMISSION_CHILDREN[permission]
    children.forEach(p => (this.form.permissions[p] = false))
  }

  _onChildPermissionChange (permission) {
    if (!this.form.permissions[permission]) {
      return
    }
    // check parent if a child is checked
    const parent = Object.entries(PERMISSION_CHILDREN)
      .filter(([parent, children]) => children.includes(permission))
      .map(([parent]) => parent)
      .shift()
    if (parent) {
      this.form.permissions[parent] = true
    }
  }

  _validatePermissionRelations (permission) {
    const isParentPermission = Object.keys(PERMISSION_CHILDREN).includes(
      permission
    )
    if (isParentPermission) {
      this._onParentPermissionChange(permission)
    } else {
      this._onChildPermissionChange(permission)
    }
  }

  _validatePermissions () {
    this.form.permissions = validatePermissions(
      this.form.permissions.type,
      this.form.permissions
    )
  }

  onPermissionTypeChange () {
    this._validatePermissions()
  }

  onPermissionChange (permission) {
    this._validatePermissions()
    this._validatePermissionRelations(permission)
  }

  onConfigurePbxChange () {
    this.form.permissions.manageUsers = this.form.permissions.configPbx
    this.onPermissionChange(this.GENERAL_PERMISSIONS_MAP.CONFIGURE_PBX_ACCESS)
  }

  createDevice (device, user, line, hardware) {
    const _device = new this.Device(null, device)
    _device.assignedUser = user.jrn
    _device.assignedUserLastName = user.lastName
    _device.assignedUserFirstName = user.firstName

    if (hardware) {
      _device.userAgent = hardware.jrn
    }

    _device.buttons = {
      '0:0': {
        label: line.extensionNumber,
        parameters: line.jrn,
        type: 'LINE',
        userEditable: true
      }
    }

    return _device.save()
  }

  createLine (line) {
    const _line = new this.Line(null, line)
    _line.name = [this.form.user.firstName, this.form.user.lastName]
      .filter(s => s)
      .join(' ')
    return _line.save()
  }

  createHardware (hardware) {
    const _hardware = new this.Hardware(null, hardware)
    _hardware.macAddress = hardware.macAddress.replace(/[:\.-]/g, '')
    return _hardware.save()
  }

  createUser (user) {
    const _user = new this.User(null, user)
    _user._externalUserId = this.form.user.emailAddress
    _user.enabled = true
    _user.permissions = buildGeneralPermissions(this.form.permissions)
    return _user.save()
  }

  async assignRole (user) {
    if (this.showPermissions && this.userRole !== 'MEMBER') {
      await waitForUserToExist({
        lmiAccountKey: this.lmiAccountKey,
        oauthToken: this.oauthToken,
        userKey: user.externalUserKey
      })

      const result = await updateUserRole({
        lmiAccountKey: this.lmiAccountKey,
        oauthToken: this.oauthToken,
        userKey: user.externalUserKey,
        role: this.userRole,
        isNewUser: true,
      })

      if (!result) {
        throw new Error('Error assigning role to new user')
      }
    }

    return user
  }

  handleRoleChange ({ role }) {
    this.userRole = role
  }

  complete () {
    this.form.busy = true
    if (this.form.line) {
      this.form.line.name = this.lineName
    }
    if (this.form.user.emailAddress) {
      this.form.user.emailAddress = (
        this.form.user.emailAddress || ''
      ).toLowerCase()
      this.form.user.externalUserId = this.form.user.emailAddress
    }

    const creators = [
      this.createUser(this.form.user).then((user) => this.assignRole(user))
    ]

    if (this.enableSoftphone || this.assignDevice) {
      creators.push(this.createLine(this.form.line))
    }

    if (this.form.hardware && this.form.hardware.device) {
      const hardwareId = this.jrn
        .parse(this.form.hardware.device)
        .getResources()[1]
      creators.push(
        this.portalApi.userAgent
          .pbxs(this.globalState.selectedPbxUUID)
          .userAgents(hardwareId)
          .get()
      )
    } else if (this.form.hardware && this.form.hardware.macAddress) {
      creators.push(this.createHardware(this.form.hardware))
    }

    Promise.all(creators)
      .then(([user, line, hardware]) => {
        if (!this.enableSoftphone && !this.assignDevice) {
          this.$mdDialog.hide(user)
          return
        }
        return this.createDevice(this.form.device, user, line, hardware).then(
          () => this.$mdDialog.hide(user)
        )
      })
      .catch(err => {
        console.error(err)
        this.portalUtil.showErrorAlert(
          this.gettextCatalog.getString('Save failed, please retry.')
        )
      })
      .finally(() => {
        this.form.busy = false
      })
  }

  cancelDialog () {
    this.$mdDialog.cancel()
  }

  static factory (
    Device,
    Line,
    User,
    Hardware,
    portalApi,
    $stateParams,
    $analytics,
    gettextCatalog,
    portalUtil,
    $mdDialog,
    globalState,
    FEATURE_FLAGS
  ) {
    'ngInject'
    return new AddUserFormController(
      Device,
      Line,
      User,
      Hardware,
      portalApi,
      $stateParams,
      $analytics,
      gettextCatalog,
      portalUtil,
      $mdDialog,
      globalState,
      FEATURE_FLAGS
    )
  }
}

class AddUserForm {
  constructor ($mdDialog, $analytics) {
    'ngInject'
    this.$mdDialog = $mdDialog
    this.$analytics = $analytics
  }

  open () {
    return this.$mdDialog.show({
      template: addUserFormTemplate,
      controllerAs: 'ctrl',
      controller: AddUserFormController.factory,
      parent: angular.element(document.body),
      clickOutsideToClose: true,
      fullscreen: true
    })
  }

  static factory ($mdDialog, $analytics) {
    'ngInject'
    return new AddUserForm($mdDialog, $analytics)
  }
}

_module.factory('addUserForm', AddUserForm.factory)
