/**
 * @ngdoc function
 * @name platformApp.factory:ZohoMappingService
 * @description
 * # ZohoMappingService
 * Controller of the platformApp
 */
import _module from 'module.js'
import _ from 'lodash'
import BaseES6 from 'factories/baseES6'
import jrnParser from 'classes/jrnParser'

let _globalState, _integrationsService, _jrnParser

export default class ZohoMapping extends BaseES6 {
  constructor (id, zohoMapping, gettextCatalog) {
    'ngInject'
    super(id)
    this.gettextCatalog = gettextCatalog

    _jrnParser = jrnParser

    _integrationsService.getExtensionsMapping()
    _integrationsService.getZohoUsers()

    if (_.isUndefined(id)) {
      throw new Error(
        this.gettextCatalog.getString('Missing id for new ZohoMapping')
      )
    }

    if (zohoMapping) {
      this._map(zohoMapping)
    }
  }

  getApi () {
    if (!this._api) {
      this._api = _integrationsService.getZohoMappingApiService()
    }
    return this._api
  }

  getPath () {
    if (!this._path) { this._path = _integrationsService.getZohoMappingPath(this._id) }

    return this._path
  }

  build (payload) {
    payload.extensionId = _jrnParser.parse(payload.extensionJrn).getResources()[
      1
    ]

    return payload
  }

  map (data) {
    this.jrn = _integrationsService.mapZohoMappingIdToJrn(data.id)
    this.isNew = data.isNew || false
  }

  mapBatchData (data) {
    data.jrn = _integrationsService.mapZohoMappingIdToJrn(data.id)

    if (_integrationsService.getZohoUsers()) {
      data.zohoUser = _integrationsService.getZohoUsers().find(user => {
        return user.id === data.integrationUserId
      })
    } else {
      data.zohoUser = {
        id: data.integrationUserId
      }
    }
    return data
  }

  getClass () {
    return 'ZohoMapping'
  }

  static factory (
    globalState,
    gettextCatalog,
    integrationsService,
    zohoMappingAuthService
  ) {
    'ngInject'
    _globalState = globalState
    _integrationsService = integrationsService

    return (id, zohoMapping) => {
      return new ZohoMapping(
        id,
        zohoMapping,
        gettextCatalog,
        zohoMappingAuthService
      )
    }
  }
}

_module.factory('ZohoMappingFactory', ZohoMapping.factory)
