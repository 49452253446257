import assignLinesTemplate from './assignLinesTemplate.html'
import _module from 'module.js'
import _ from 'lodash'
import { ButtonConfigController } from '../../classes/buttonConfigController'

export class AssignLinesController extends ButtonConfigController {
  constructor () {
    'ngInject'
    super()

    this.isCiscoSidecar = false
    this.sideCarTypes = ['7914', '7915', '7916']
    this.showSidecarType = false
  }

  initButtonConfigSections (allButtonsByGroup, userAgent) {
    this.buttonConfigs = this.createAndFillAllDeviceButtons(
      userAgent.numberLineButtons,
      allButtonsByGroup.shift()
    )
    this.createAndFillAllSidecarCollections(userAgent, allButtonsByGroup)
  }

  initWithUserAgent (userAgent) {
    super.initWithUserAgent(userAgent)
    this.showSidecarType =
      userAgent.jrn.indexOf('cisco') !== -1 &&
      userAgent.sidecarCapable &&
      ['7941', '7942', '7945', '7961', '7962', '7965'].indexOf(
        userAgent.model
      ) >= 0
  }

  loadSerializedButtons () {
    let deviceButtonConfigs = this.serializeButtonConfigs(this.buttonConfigs, 0)

    let sidecarButtonConfigs = _(
      this.sidecarButtonConfigs
    ).reduce((list, buttonConfigs, idx) => {
      return _(list)
        .extend(this.serializeButtonConfigs(buttonConfigs, idx + 1))
        .value()
    }, {})

    return _.extend({}, deviceButtonConfigs, sidecarButtonConfigs)
  }
}

_module.component('assignLines', {
  template: assignLinesTemplate,
  require: {
    ngModel: 'ngModel'
  },
  bindings: {
    buttons: '<',
    configs: '<'
  },
  controllerAs: 'assignLines',
  controller: AssignLinesController
})
