import _module from 'module.js'
import _ from 'lodash'
import jrnParser from 'classes/jrnParser'

let deferreds = []
let userInfo = null

const JIVE_PBX_ID = '0127d974-f9f3-0704-2dee-000100420001'

let requested = false

const CONFIGURE_PBX_ACCESS_PERM = 'CONFIGURE_PBX_ACCESS'
const PARTNER_ADMIN_PERM = 'PARTNER_ADMIN'
const PARTNER_PBX_ADMIN_PERM = 'PARTNER_PBX_ADMIN'

let permissionMapping = {
  superAdmin: {
    user: ['ADMIN'],
    partnerUser: [PARTNER_ADMIN_PERM, PARTNER_PBX_ADMIN_PERM]
  },
  pbxAdmin: {
    user: ['ADMIN', CONFIGURE_PBX_ACCESS_PERM],
    partnerUser: [PARTNER_ADMIN_PERM, PARTNER_PBX_ADMIN_PERM],
    partnerOrganizationPermissions: [CONFIGURE_PBX_ACCESS_PERM]
  },
  manageSeats: {
    user: ['MANAGE_SEATS']
  },
  manageUsers: {
    user: ['MANAGE_USERS']
  },
  manageSettings: {
    user: ['MANAGE_SETTINGS']
  },
  manageGroups: {
    user: ['MANAGE_GROUPS']
  }
}

const userInfoCallback = info => {
  userInfo = info
  finalizePromises()
}

const finalizePromises = () => {
  for (let i = 0, l = deferreds.length; i < l; i++) {
    deferreds[i].resolve(userInfo)
  }
}

const checkPlatformAdminPermissions = _userInfo => {
  for (let p in _userInfo.authorities) {
    let auth = _userInfo.authorities[p]
    if (auth === 'PLATFORM_ADMIN') {
      return true
    }
  }
  return false
}


const checkPlatformFeatureFlagPermissions = _userInfo => {
  for (let p in _userInfo.authorities) {
    let auth = _userInfo.authorities[p]
    if (auth === 'PLATFORM_ADMIN' || auth === 'PLATFORM_FEATURE_FLAG') {
      return true
    }
  }
  return false
}

const checkPartnerAdminPermissions = _userInfo => {
  for (let p in _userInfo.authorities) {
    let auth = _userInfo.authorities[p]
    if (auth === PARTNER_ADMIN_PERM) {
      return true
    }
  }
  return false
}

const checkPartnerPbxAdminPermissions = _userInfo => {
  for (let p in _userInfo.authorities) {
    let auth = _userInfo.authorities[p]
    if (auth === PARTNER_PBX_ADMIN_PERM) {
      return true
    }
  }
  return false
}

const checkPlatformPermissions = _userInfo => {
  for (let p in _userInfo.authorities) {
    let auth = _userInfo.authorities[p]
    if (
      auth === 'PLATFORM_ADMIN' ||
      auth === 'ROLE_VIEW_ACCOUNT' ||
      auth === 'PLATFORM_CUSTOMER_SERVICE' ||
      auth === 'PLATFORM_NUMBERS' ||
      auth === 'PLATFORM_FEATURE_FLAG'
    ) {
      return true
    }
  }
  return false
}

const checkPlatformReadOnlyAccessPermissions = _userInfo => {
  for (let p in _userInfo.authorities) {
    let auth = _userInfo.authorities[p]
    if (
      auth === 'ROLE_VIEW_ACCOUNT'
    ) {
      return true
    }
  }
  return false
}

const checkPlatformNumbersOrHigherPermissions = _userInfo => {
  for (let p in _userInfo.authorities) {
    let auth = _userInfo.authorities[p]
    if (auth === 'PLATFORM_ADMIN' || auth === 'PLATFORM_NUMBERS') {
      return true
    }
  }
  return false
}

const doGetUserInfo = () => {
  _authService.getAuthInfo().then(authInfo => {
    _q
      .all([
        _portalApi.identity.principals(authInfo.jiveId).get(),
        _language.getSelectedLanguage()
      ])
      .then(data => {
        // the idea here is that when principal data is returned, we check it for the
        // errors and if it finds that the data meets the error criteria the app will be
        // redirected to an error page.
        //
        // The environment for this is set at the service creation level and the language your user
        // had set needs to be passed in here as well
        const principalData = data[0]

        // userConfigCheck uses _ as in en_US, not en-US
        let language = data[1]
        language = language ? language.langCode.replace('-', '_') : 'en_US'
        if (!checkPlatformPermissions(principalData)) {
          _userConfigCheck.checkPrincipalDataConfiguration(
            principalData,
            language
          )
        }
        return principalData
      })
      .then(userInfoCallback)
      .then(() => {
        userInfo.authInfo = authInfo
      })
      .catch(error => {
        let authError = {
          error: JSON.stringify(error),
          authInfo: JSON.stringify(authInfo)
        }
        _analytics.eventTrack(`HTTP Principal ${JSON.stringify(authError)}`, {
          category: 'HTTP Principal'
        })
      })
  })
}

let _q,
  _authService,
  _portalApi,
  _analytics,
  _http,
  _LIST_LIMIT_DEFAULT,
  _jrn,
  _portalUtil,
  _featuresService,
  _FEATURE_FLAGS,
  _portalConfig
let _userConfigCheck, _language

export default class UserService {
  constructor (gettextCatalog) {
    'ngInject'
    this.gettextCatalog = gettextCatalog
  }

  getUserInfo () {
    // only get the userInfo once
    if (!requested) {
      doGetUserInfo()
      requested = true
    }

    let deferred = _q.defer()
    if (userInfo) {
      deferred.resolve(userInfo)
    } else {
      deferreds.push(deferred)
    }
    return deferred.promise
  }

  hasPlatformFeatureFlagPermissions () {
    return this.getUserInfo().then(_userInfo => {
      return checkPlatformFeatureFlagPermissions(_userInfo)
    })
  }

  hasPlatformAdminPermissions () {
    return this.getUserInfo().then(_userInfo => {
      return checkPlatformAdminPermissions(_userInfo)
    })
  }

  hasPartnerAdminPermissions () {
    return this.getUserInfo().then(_userInfo => {
      return checkPartnerAdminPermissions(_userInfo)
    })
  }

  hasPartnerPbxAdminPermissions () {
    return this.getUserInfo().then(_userInfo => {
      return checkPartnerPbxAdminPermissions(_userInfo)
    })
  }

  hasPlatformPermissions () {
    return this.getUserInfo().then(_userInfo => {
      return checkPlatformPermissions(_userInfo)
    })
  }

  hasPlatformNumberPermissionsOrHigher () {
    return this.getUserInfo().then(_userInfo => {
      return checkPlatformNumbersOrHigherPermissions(_userInfo)
    })
  }

  isPermissionAllowed (pbx, permission, skipPlatformPermissionsOverride) {
    const pbxId = _jrn.parse(pbx.jrn).getAccount()
    return this.getUserInfo().then(_userInfo => {
      if (
        !skipPlatformPermissionsOverride && pbx.type !== "LMI" && checkPlatformPermissions(_userInfo)
      ) {
        return true
      }

      let userPbx = _.find(_userInfo.users, user => {
        let userPbxId = _jrn.parse(user.jrn).getAccount()
        if (userPbxId === _jrn.parse(pbx.jrn).getAccount()) {
          return true
        }
        return false
      })
      let userPermissions = permissionMapping[permission].user
      if (userPbx && userPermissions) {
        for (let p of userPermissions) {
          if (_.includes(userPbx.pbxPermissions, p)) {
            return true
          }
        }
      }

      // check partner
      let partner = _.find(_userInfo.partnerUsers, user => {
        if (
          pbx.partner &&
          _jrn.parse(user.jrn).getAccount() ===
            _jrn.parse(pbx.partner).getResources()[1]
        ) {
          return true
        }
        return false
      })

      // Check if the user is 'PARTNER_ADMIN' or 'PARTNER_PBX_ADMIN' on the partner
      let partnerUserPermissions = permissionMapping[permission].partnerUser
      if (partner && partnerUserPermissions) {
        for (let p of partnerUserPermissions) {
          if (_.includes(partner.partnerPermissions, p)) {
            return true
          }
        }
      }

      // check if the user has CONFIGURE_PBX_ACCESS on the specified PBX
      let partnerOrganizationPermissionFlags = permissionMapping[permission].partnerOrganizationPermissions
      if (partner && partnerOrganizationPermissionFlags) {
        for (let pop of partner.partnerOrganizationPermissions) {
          if (pop.organizationId === pbxId) {
            for (let flag of partnerOrganizationPermissionFlags) {
              if (_.includes(pop.permissions, flag)) {
                return true
              }
            }
          }
        }
      }

      return false
    })
  }

  canManagePbxThroughPartner (pbx) {
    if (!pbx.partner) {
      return Promise.resolve(false)
    }

    return this.getUserInfo().then(principal => {
      const pbxId = _jrn.parse(pbx.jrn).getAccount()
      const partnerId = _jrn.parse(pbx.partner).getResources()[1]

      const partnerUser = _.find(principal.partnerUsers, user => _jrn.parse(user.jrn).getAccount() === partnerId)

      return !!partnerUser &&
        (!!_.intersection(partnerUser.partnerPermissions, [PARTNER_ADMIN_PERM, PARTNER_PBX_ADMIN_PERM]).length ||
          _.some(partnerUser.partnerOrganizationPermissions,
            p => p.organizationId === pbxId && _.includes(p.permissions, CONFIGURE_PBX_ACCESS_PERM)))
    })
  }

  isUserSuperAdmin (pbx) {
     return this.isPermissionAllowed(pbx, 'superAdmin')
  }

  isPermissionTabAllowed (pbx) {
    return this.isUserSuperAdmin(pbx)
  }

  hasPbxAdminPermission (pbx) {
    return this.isPermissionAllowed(pbx, 'pbxAdmin')
  }

  hasManageSeatsPermission (pbx, skipPlatformPermissionsOverride) {
    return this.isPermissionAllowed(pbx, 'manageSeats', skipPlatformPermissionsOverride)
  }

  hasManageUsersPermission (pbx, skipPlatformPermissionsOverride) {
    return this.isPermissionAllowed(pbx, 'manageUsers', skipPlatformPermissionsOverride)
  }

  hasManageSettingsPermission (pbx, skipPlatformPermissionsOverride) {
    return this.isPermissionAllowed(pbx, 'manageSettings', skipPlatformPermissionsOverride)
  }

  hasManageGroupsPermission (pbx, skipPlatformPermissionsOverride) {
    return this.isPermissionAllowed(pbx, 'manageGroups', skipPlatformPermissionsOverride)
  }

  hasPlatformReadOnlyAccessPermissions () {
    return this.getUserInfo().then(_userInfo => {
      return checkPlatformReadOnlyAccessPermissions(_userInfo)
    })
  }

  getAdministeredPbxs () {
    let query = {
      limit: _LIST_LIMIT_DEFAULT,
      includeUnifiedAdmin: true
    }
    return _portalApi.pbx
      .pbxs()
      .get(query)
      .then(pbxList => {
        return pbxList.items
      })
      .catch(error => {
        _portalUtil.showErrorAlert(
          this.gettextCatalog.getString('Query failed, please retry.')
        )
        throw error
      })
  }

  isBillingAllowed (pbx) {
    if (pbx.billingPortalAccess)
    {
      return this.getUserInfo().then(_userInfo => {
        if (checkPlatformPermissions(_userInfo)) {
          return true
        }

        let billingPermissions = ['BILLING_ACCESS', 'BILLING_VIEW_ACCESS']
        let userPbx = _.find(_userInfo.users, user => {
          let pbxId = _jrn.parse(user.jrn).getAccount()
          if (pbxId === _jrn.parse(pbx.jrn).getAccount()) {
            return true
          }
          return false
        })

        if (userPbx) {
          for (let p of billingPermissions) {
            if (_.includes(userPbx.pbxPermissions, p)) {
              return true
            }
          }
        }
        return false
      })
    }
    else {
      return false
    }
  }

  static factory (
    $q,
    authService,
    portalApi,
    $analytics,
    $http,
    LIST_LIMIT_DEFAULT,
    gettextCatalog,
    portalUtil,
    featuresService,
    FEATURE_FLAGS,
    portalConfig,
    userConfigCheck,
    language
  ) {
    'ngInject'
    _q = $q
    _authService = authService
    _portalApi = portalApi
    _analytics = $analytics
    _http = $http
    _LIST_LIMIT_DEFAULT = LIST_LIMIT_DEFAULT
    _jrn = jrnParser
    _portalUtil = portalUtil
    _featuresService = featuresService
    _FEATURE_FLAGS = FEATURE_FLAGS
    _portalConfig = portalConfig
    _userConfigCheck = userConfigCheck
    _language = language
    return new UserService(gettextCatalog)
  }
}

_module.factory('userService', UserService.factory)
