import _module from 'module.js'

_module.directive('clearInput', function ($parse) {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, element, attr) {
      let htmlMarkup = '<span class="clear blue-hover">x</span>'
      let clearButton = angular.element(htmlMarkup)
      clearButton.css('visibility', 'hidden')
      element.before(clearButton)

      clearButton.on('click', event => {
        if (attr.clearInput) {
          let fn = $parse(attr.clearInput)
          scope.$apply(function () {
            fn(scope, {
              $event: event
            })
          })
        }
      })

      scope.$watch(attr.ngModel, val => {
        let hasValue = val && val.length > 0
        clearButton.css('visibility', hasValue ? 'visible' : 'hidden')
      })
    }
  }
})
