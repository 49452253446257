import listDisplayTemplate from './listDisplayTemplate.html'
import _module from 'module.js'

_module.directive('listDisplay', function () {
  return {
    template: listDisplayTemplate,
    restrict: 'A',
    transclude: true
  }
})
