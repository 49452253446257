import timeRangeTemplate from './timeRangeTemplate.html'
import _module from 'module.js'

_module.directive('timeRange', function ($mdpTimePicker) {
  return {
    restrict: 'EA',
    template: timeRangeTemplate,
    require: ['ngModel', '^schedule', 'timeRange'],
    controllerAs: 'timeRange',
    bindToController: true,
    scope: {
      model: '=ngModel'
    },
    controller: function () {},
    link: {
      pre: function (scope, el, attrs, ctrls) {
        let schedule = ctrls[1]
        let timeRange = ctrls[2]

        timeRange.x = timeRange.model.getStart().column * schedule.ROW_WIDTH
        timeRange.y = timeRange.model.getStart().row * (schedule.ROW_HEIGHT / 2)
        timeRange.width =
          (timeRange.model.getEnd().column -
            timeRange.model.getStart().column +
            1) *
          schedule.ROW_WIDTH
        timeRange.height =
          (timeRange.model.getEnd().row - timeRange.model.getStart().row) *
          (schedule.ROW_HEIGHT / 2)
        timeRange.startTimeModel = timeRange.model.startTime.toDate()
        timeRange.endTimeModel = timeRange.model.endTime.toDate()
      },
      post: function (scope, el, attrs, ctrls) {
        let ngModel = ctrls[0]
        let schedule = ctrls[1]
        let timeRange = ctrls[2]

        scope.onMousemove = function (update) {
          let startRow = update.y / (schedule.ROW_HEIGHT / 2),
            startColumn = update.x / schedule.ROW_WIDTH,
            endRow = update.height / (schedule.ROW_HEIGHT / 2) + startRow,
            endColumn =
              (update.width - schedule.ROW_WIDTH) / schedule.ROW_WIDTH +
              startColumn
          if (schedule.mode == 'resize-x') {
            timeRange.model.startTime.day(startColumn)
            timeRange.model.endTime.day(endColumn)
            if (
              timeRange.model.endTime.hour() == 0 &&
              timeRange.model.endTime.minute() == 0
            ) {
              timeRange.model.endTime.add(1, 'day')
            }
            this.timeRange.model.setLocalizedTimestringEndpoints(
              this.timeRange.model.startTime.toDate(),
              this.timeRange.model.endTime.toDate(),
              schedule.langCode
            )
          } else if (
            this.timeRange.model.getStart().row !== startRow ||
            this.timeRange.model.getStart().column !== startColumn ||
            this.timeRange.model.getEnd().row !== endRow ||
            this.timeRange.model.getEnd().column !== endColumn
          ) {
            let startPoint = [startRow, startColumn]
            let endPoint = [endRow, endColumn]
            this.timeRange.model.setEndpoints(startPoint, endPoint)
            this.timeRange.model.setLocale(schedule.langCode)
            ngModel.$setViewValue(this.timeRange.model)
          }
        }
        scope.doneEditing = function () {
          this.timeRange.startTimeModel = this.timeRange.model.startTime.toDate()
          this.timeRange.endTimeModel = this.timeRange.model.endTime.toDate()
          schedule.changeMode('idle')
          schedule.save()
        }

        scope.move = function () {
          if (schedule.mode === 'idle') {
            schedule.changeMode('move')
          }
        }
        scope.resizeX = function () {
          schedule.changeMode('resize-x')
        }
        scope.resizeY = function () {
          schedule.changeMode('resize-y')
        }
        scope.remove = function () {
          schedule.remove(timeRange.model)
        }

        scope.showTimePicker = function (ev) {
          let time = {
            start: this.timeRange.startTimeModel,
            end: this.timeRange.endTimeModel,
            startString: this.timeRange.model.startTime.format('LT'),
            endString: this.timeRange.model.endTime.format('LT'),
            locale: this.timeRange.model.startTime.locale()
          }
          let scheduleName = schedule.name
          $mdpTimePicker(time, scheduleName, {
            targetEvent: ev
          }).then(selectedDates => {
            this.timeRange.startTimeModel = selectedDates.start.toDate()
            this.timeRange.endTimeModel = selectedDates.end.toDate()
            let oldStartRow = this.timeRange.model._start.row
            let oldEndRow = this.timeRange.model._end.row
            let oldStartColumn = this.timeRange.model._start.column
            let oldEndColumn = this.timeRange.model._end.column
            this.timeRange.model.setLocalizedTimestringEndpoints(
              this.timeRange.startTimeModel,
              this.timeRange.endTimeModel,
              schedule.langCode
            )
            let startRowDifference =
              this.timeRange.model._start.row - oldStartRow
            let endRowDifference = this.timeRange.model._end.row - oldEndRow
            this.timeRange.y += schedule.ROW_HEIGHT / 2 * startRowDifference
            this.timeRange.height -=
              schedule.ROW_HEIGHT / 2 * startRowDifference
            this.timeRange.height += schedule.ROW_HEIGHT / 2 * endRowDifference
            let startColumnDifference =
              this.timeRange.model._start.column - oldStartColumn
            let endColumnDifference =
              this.timeRange.model._end.column - oldEndColumn
            this.timeRange.x += schedule.ROW_WIDTH * startColumnDifference
            this.timeRange.width -= schedule.ROW_WIDTH * startColumnDifference
            this.timeRange.width += schedule.ROW_WIDTH * endColumnDifference
            schedule.timeRanges = schedule.updateBackendTimeRanges()
            ngModel.$setViewValue(this.timeRange.model)
            scope.$broadcast('time-change')
          })
        }
      }
    }
  }
})
