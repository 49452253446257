/**
 * @ngdoc function
 * @name platformApp.factory:SharedVoicemailBoxService
 * @description
 * # SharedVoicemailBoxService
 * Controller of the platformApp
 */
import _module from 'module.js'
import _ from 'lodash'
import ExtensionWithVoicemailBoxES6
  from 'factories/extensionWithVoicemailBoxES6'

let _portalApi, _portalUtil, _globalState

export default class SharedVoicemailBox extends ExtensionWithVoicemailBoxES6 {
  constructor (id, sharedVoicemailBox, gettextCatalog) {
    'ngInject'
    super(id)
    this.gettextCatalog = gettextCatalog

    if (this._id === undefined) {
      throw new Error(
        this.gettextCatalog.getString('Missing id for new SharedVoicemailBox')
      )
    }

    if (sharedVoicemailBox) {
      this._map(sharedVoicemailBox)
    }
  }

  getApi () {
    if (!this._api) {
      this._api = _portalApi.extension.pbxs(
        _globalState.selectedPbxUUID
      ).sharedVoicemailBoxes
    }

    return this._api
  }

  getPath () {
    if (!this._path) {
      this._path = _portalApi.extension
        .pbxs(_globalState.selectedPbxUUID)
        .sharedVoicemailBoxes(this._id)
    }

    return this._path
  }

  getReferencesPath () {
    if (!this._referencesPath) {
      this._referencesPath = _portalApi.extension
        .pbxs(_globalState.selectedPbxUUID)
        .sharedVoicemailBoxes(this._id)
        .one('references')
    }
    return this._referencesPath
  }

  map (data) {
    this.isNew = data.isNew || false
    this.extensionRegion = data.extensionRegion ? data.extensionRegion : ''
    this.voicemailBox._voicemailAccessPermissions = []

    if (data.sharedVoicemailBoxPermissions) {
      this.voicemailBox._voicemailAccessPermissions = data.sharedVoicemailBoxPermissions.map(
        target => {
          return {
            id: target.identity,
            text: _portalUtil.getNameFromUserOrGroup(target)
          }
        }
      )
    }
  }

  build (payload) {
    payload.extensionNumber = this.extensionNumber || null
    payload.sharedVoicemailBoxPermissions = this.voicemailBox._voicemailAccessPermissions.map(
      target => {
        return {
          identity: target.id,
          permissions: ['ACCESS_VOICEMAIL']
        }
      }
    )

    return payload
  }

  getClass () {
    return 'SharedVoicemailBox'
  }

  static factory (globalState, portalApi, gettextCatalog, portalUtil) {
    'ngInject'
    _portalApi = portalApi
    _portalUtil = portalUtil
    _globalState = globalState

    return (id, sharedVoicemailBox) => {
      return new SharedVoicemailBox(id, sharedVoicemailBox, gettextCatalog)
    }
  }
}

_module.factory('SharedVoicemailBox', SharedVoicemailBox.factory)
