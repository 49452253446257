import jrnParser from 'classes/jrnParser'

let _jrn, _state, _globalState, _PagingRealm
export default class GetPagingRealmController {
  constructor (gettextCatalog, $state, globalState, PagingRealm) {
    'ngInject'
    this._model = {}
    _state = $state
    _globalState = globalState
    _jrn = jrnParser
    _PagingRealm = PagingRealm

    this.placeholder = gettextCatalog.getString('Select a paging realm...')

    this.$onInit = () => {
      this.setViewValue = item => {
        this.ngModel.$setViewValue(item.id)
      }
    }
  }

  api (id = null) {
    return new _PagingRealm(id)
  }

  openLink () {
    _state.go('root.nav.pbx.devices.realms.view.general', {
      id: _globalState.selectedPbx.domain,
      realmId: _jrn.parse(this.jrn).getResources()[1]
    })
  }
}
