import jrnParser from 'classes/jrnParser'

let _jrn, _Hardware, _state, _globalState

export default class GetHardwareController {
  constructor (gettextCatalog, Hardware, $state, globalState) {
    'ngInject'
    this._model = {}
    this.gettextCatalog = gettextCatalog
    _Hardware = Hardware
    _state = $state
    _globalState = globalState
    _jrn = jrnParser

    this.placeholder = this.gettextCatalog.getString('Select hardware...')
    this.addNewLabel = this.gettextCatalog.getString('Add new hardware')

    this.extraParams = [
      {
        key: 'additionalFields',
        value: ['ASSIGNED_USER_NAME']
      }
    ]

    this.$onInit = () => {
      this.setViewValue = item => {
        this.ngModel.$setViewValue(item.id)
      }
    }
  }

  api (id = null) {
    return new _Hardware(id)
  }

  map (ua) {
   
    let label = ua.macAddress
    if (ua.deviceName) {
      label += ': ' + ua.deviceName
      label += ua.location ? ' - ' + ua.location : ''
    } else {
      label += ua.location ? ': ' + ua.location : ''
    }

    return {
      id: ua.jrn,
      text: label
    }
    
  }

  openLink () {
    _state.go('root.nav.pbx.devices.hardware.view.general', {
      id: _globalState.selectedPbx.domain,
      hardwareId: _jrn.parse(this.jrn).getResources()[1]
    })
  }

  filter(item) {
    return !item.assignedUserIsHardwareUser && item.model !== 'SIP Trunk'
  }
}
