import _module from 'module.js'

class ResyncAllDevicesWarning {
  constructor (confirmationWindowWarn, $analytics) {
    'ngInject'
    this.confirmationWindowWarn = confirmationWindowWarn
    this.$analytics = $analytics
  }

  open (deviceCount, resyncAll, gettextCatalog) {
    this.$analytics.eventTrack('Resync All Devices Warning: Open', {
      category: 'Resync All Devices Warning'
    })

    const warningQuestion = gettextCatalog.getString(
      'Are you sure you want to resync all {{count}} devices?',
      { count: deviceCount }
    )

    const warningMessage = gettextCatalog.getString(
      'Resyncing multiple devices at one time can place a significant ' +
        'load on the infrastructure. For the customer, this can overwhelm network resources, cause phones ' +
        'to be unavailable, and result in poor call quality.'
    )

    const okText = gettextCatalog.getString('Resync {{count}} Devices', {
      count: deviceCount
    })

    this.confirmationWindowWarn
      .show(warningQuestion, warningMessage, okText)
      .then(resyncAll)
  }

  static factory (confirmationWindowWarn, $analytics) {
    'ngInject'
    return new ResyncAllDevicesWarning(confirmationWindowWarn, $analytics)
  }
}

_module.factory('resyncAllDevicesWarning', ResyncAllDevicesWarning.factory)
