let _Proxies

export default class GetOutboundProxyController {
  constructor (gettextCatalog, Proxies) {
    'ngInject'
    this._model = {}
    _Proxies = Proxies

    this.placeholder = gettextCatalog.getString(
      'Select an registration proxy...'
    )

    this.$onInit = () => {
      this.setViewValue = item => {
        this.ngModel.$setViewValue(item.id)
      }
    }
  }

  api (id = null) {
    return new _Proxies(id)
  }
}
