/**
 * @ngdoc function
 * @name platformApp.factory:PagingProfileService
 * @description
 * # PagingProfileService
 * Controller of the platformApp
 */
import _module from 'module.js'
import BaseES6 from 'factories/baseES6'
import jrnParser from 'classes/jrnParser'

let _stateParams, _portalApi, _jrn, _globalState

export default class PagingProfile extends BaseES6 {
  constructor (id, profile, pbxId, gettextCatalog) {
    'ngInject'
    super(id || _stateParams.profileId, pbxId || _globalState.selectedPbxUUID)

    this.gettextCatalog = gettextCatalog

    if (id === undefined) {
      throw new Error(
        this.gettextCatalog.getString('Missing id for new PagingProfile')
      )
    }

    this._entries = []

    if (profile) {
      this._map(profile)
    }
  }

  getApi () {
    if (!this._api) {
      this._api = _portalApi.userAgent.pbxs(this._pbxId).pagingProfiles
    }

    return this._api
  }

  getPath () {
    if (!this._path) {
      this._path = _portalApi.userAgent
        .pbxs(this._pbxId)
        .pagingProfiles(this._id)
    }

    return this._path
  }

  getReferencesPath () {
    if (!this._referencesPath) {
      this._referencesPath = _portalApi.userAgent
        .pbxs(this._pbxId)
        .pagingProfiles(this._id)
        .one('references')
    }
    return this._referencesPath
  }

  mapListItem (dir) {
    return {
      id: dir.jrn,
      text: dir.name
    }
  }

  getClass () {
    return 'PagingProfile'
  }

  static factory ($state, $stateParams, portalApi, gettextCatalog, globalState) {
    'ngInject'
    _stateParams = $stateParams
    _portalApi = portalApi
    _jrn = jrnParser
    _globalState = globalState

    return (id, profile, pbxId = null) => {
      return new PagingProfile(id, profile, pbxId, gettextCatalog)
    }
  }
}

_module.factory('PagingProfile', PagingProfile.factory)
