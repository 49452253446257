import _module from 'module.js'
import * as angular from 'angular'
class VoicemailGreetingService {
  constructor (portalApi, $stateParams, gettextCatalog, globalState) {
    'ngInject'
    this.portalApi = portalApi
    this.$stateParams = $stateParams
    this.globalState = globalState
    this.greetings = [
      {
        label: gettextCatalog.getString('Busy Message'),
        param: 'busy'
      },
      {
        label: gettextCatalog.getString('Unavailable Message'),
        param: 'unavail'
      },
      {
        label: gettextCatalog.getString('Temporary Message'),
        param: 'temp'
      }
    ]
  }

  getLineGreetingApi () {
    return this.portalApi.extension
      .pbxs(this.globalState.selectedPbxUUID)
      .lines(this.$stateParams.extId)
  }

  uploadLineGreeting (file, param) {
    return this.uploadGreetingFile(
      file,
      this.getLineGreetingApi().one('vmgreeting'),
      param
    )
  }

  uploadLineTTSGreeting () {
    return this.getLineGreetingApi().one('vmgreeting').one('tts')
  }

  getRingGroupGreetingApi () {
    return this.portalApi.extension
      .pbxs(this.globalState.selectedPbxUUID)
      .ringGroups(this.$stateParams.extId)
  }

  uploadRingGroupGreeting (file, param) {
    return this.uploadGreetingFile(
      file,
      this.getRingGroupGreetingApi().one('vmgreeting'),
      param
    )
  }

  uploadRingGroupTTSGreeting () {
    return this.getRingGroupGreetingApi().one('vmgreeting').one('tts')
  }

  getSharedVMGreetingApi () {
    return this.portalApi.extension
      .pbxs(this.globalState.selectedPbxUUID)
      .sharedVoicemailBoxes(this.$stateParams.extId)
  }

  uploadSharedVMGreeting (file, param) {
    return this.uploadGreetingFile(
      file,
      this.getSharedVMGreetingApi().one('vmgreeting'),
      param
    )
  }

  uploadSharedVMTTSGreeting () {
    return this.getSharedVMGreetingApi().one('vmgreeting').one('tts')
  }

  assignGreetingValues (
    greetings,
    { busyGreeting, temporaryGreeting, unavailableGreeting }
  ) {
    greetings.forEach(greeting => {
      if (greeting.param === 'busy') {
        greeting.value = busyGreeting
      } else if (greeting.param === 'unavail') {
        greeting.value = unavailableGreeting
      } else if (greeting.param === 'temp') {
        greeting.value = temporaryGreeting
      }
    })

    return greetings
  }

  uploadGreetingFile (file, api, param) {
    var formData = new FormData()

    var details = {
      name: file.name,
      vmgtype: param.type,
      normalization: param.volume
    }

    formData.append('content', file, file.name)
    formData.append('details', JSON.stringify(details))

    return api
      .withHttpConfig({
        transformRequest: angular.identity
      })
      .customPUT(formData, undefined, undefined, {
        'Content-Type': undefined
      })
  }
}
_module.service('voicemailGreeting', VoicemailGreetingService)
