import _module from 'module.js'
import contactCenterConfigurationTemplate from 'controllers/contactCenterConfiguration/contactCenterConfigurationTemplate.html'

_module.config($stateProvider => {
  'ngInject'

  $stateProvider
    .state('root.nav.pbx.contactCenterConfiguration', {
      url: '/contact-center-configuration',
      template: contactCenterConfigurationTemplate,
      controller: 'ContactCenterConfigurationController as ctrl',
      abstract: true,
    })
    .state('root.nav.pbx.contactCenterConfiguration.queues', {
      url: '/queues',
    })
    .state('root.nav.pbx.contactCenterConfiguration.queues.single', {
      url: '/:queueId',
    })
    .state('root.nav.pbx.contactCenterConfiguration.other', {
      url: '/*path',
    })
})
