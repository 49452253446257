import _module from "module.js";
import bannerTemplate from "./bannerTemplate.html";
import bannerController from './bannerController'

_module.component("banner", {
  template: bannerTemplate,
  controllerAs: "ctrl",
  controller: bannerController,
  bindings: {
    messages: '<'
  },
});
