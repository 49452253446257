import _module from 'module.js'
import mediaTypeSelectorTemplate from './mediaTypeSelectorTemplate.html'

class MediaTypeSelectorController {
  constructor ($mdDialog) {
    'ngInject'
    this.$mdDialog = $mdDialog
    this.mediaType = ''
  }

  ok () {
    this.$mdDialog.hide(this.mediaType)
  }

  cancel () {
    this.$mdDialog.cancel()
  }

  static factory ($mdDialog) {
    'ngInject'
    return new MediaTypeSelectorController($mdDialog)
  }
}

class MediaTypeSelector {
  constructor ($mdDialog) {
    'ngInject'
    this.$mdDialog = $mdDialog
  }

  open () {
    return this.$mdDialog.show({
      template: mediaTypeSelectorTemplate,
      controllerAs: 'ctrl',
      controller: MediaTypeSelectorController.factory,
      parent: angular.element(document.body),
      clickOutsideToClose: true,
      fullscreen: true
    })
  }

  static factory ($mdDialog) {
    'ngInject'
    return new MediaTypeSelector($mdDialog)
  }
}

_module.factory('mediaTypeSelector', MediaTypeSelector.factory)
