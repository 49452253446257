import _module from 'module.js'
import normalizationTemplate from '../views/common/normalizationTemplate.html'
import _ from 'lodash'

class NormalizationController {
  constructor ($mdDialog) {
    'ngInject'
    this.normalizationLevel = 'NORMAL'

    this.$mdDialog = $mdDialog
  }

  complete () {
    this.$mdDialog.hide(this.normalizationLevel)
  }

  cancelDialog () {
    this.$mdDialog.cancel()
  }
}

class NormalizationPopup {
  constructor ($mdDialog) {
    'ngInject'
    this.$mdDialog = $mdDialog
  }

  open () {
    return this.$mdDialog.show({
      template: normalizationTemplate,
      controllerAs: 'normalizationCtrl',
      controller: NormalizationController,
      locals: {
        $mdDialog: this.$mdDialog
      },
      parent: angular.element(document.body),
      clickOutsideToClose: true,
      fullscreen: true,
      multiple: true
    })
  }
}

_module.service('normalizationPopup', NormalizationPopup)
