import jrnParser from 'classes/jrnParser'

let _jrn, _BillingGroup, _state, _globalState

export default class GetBillingGroupController {
  constructor (gettextCatalog, BillingGroup, $state, globalState) {
    'ngInject'
    this._model = {}
    this.gettextCatalog = gettextCatalog
    _BillingGroup = BillingGroup
    _state = $state
    _globalState = globalState
    _jrn = jrnParser

    this.placeholder = this.gettextCatalog.getString(
      'Select a Billing Group...'
    )

    this.$onInit = () => {
      this.setViewValue = item => {
        this.ngModel.$setViewValue(item.id)
      }
    }
  }

  api (id = null) {
    return new _BillingGroup(id)
  }

  map (billingGroup) {
    return {
      id: billingGroup.jrn,
      text: billingGroup.name
    }
  }
}
