export default class DanteAiController {
  constructor() {
    'ngInject'

    this.chatAssistantVisible = false
  }

  onClickChatAssistantButton() {
    this.chatAssistantVisible = !this.chatAssistantVisible
  }
}
