import _module from 'module.js'

_module.directive('scrollInto', function ($timeout) {
  // Attach to directive and when th element is placed on the DOM
  // it will be scrolled into view, or you can put a jquery event
  // and when the event is fired for the element it will show

  // Tested in Chrome only

  return {
    restrict: 'A',
    link: function (scope, el, attrs) {
      var scrollElementIntoView = function () {
        $timeout(function () {
          el[0].scrollIntoView()
        }, 100)
      }

      if (attrs.scrollInto) {
        el.on(attrs.scrollInto, function () {
          scrollElementIntoView()
        })
      } else {
        scrollElementIntoView()
      }

      scope.$on('$destroy', function () {
        el.off(attrs.scrollInto)
      })
    }
  }
})
