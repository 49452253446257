import jrnParser from 'classes/jrnParser'

let _jrn, _state, _globalState, _Device
export default class GetHotDeskDeviceController {
  constructor (gettextCatalog, $state, globalState, Device) {
    'ngInject'
    this._model = {}
    _state = $state
    _globalState = globalState
    this.gettextCatalog = gettextCatalog
    _jrn = jrnParser
    _Device = Device

    this.placeholder = this.gettextCatalog.getString('Select a device...')

    this.$onInit = () => {
      this.setViewValue = item => {
        this.ngModel.$setViewValue(item.id)
      }
    }

    this.filter = ua => {
      if (ua.jrn) {
        return !ua.assignedUser || ua.assignedUser === this.userId
      }
      return true
    }
  }

  api (id = null) {
    return new _Device(id)
  }

  map (ua) {
    let id
    if (ua.jrn) {
      const uaJrn = _jrn.parse(ua.jrn)
      id = uaJrn.getResources()[1]
    } else {
      id = null
    }

    return {
      id: id,
      text: ua.name
    }
  }

  openLink () {
    _state.go('root.nav.pbx.devices.view.settings', {
      id: _globalState.selectedPbx.domain,
      deviceId: this.jrn
    })
  }
}
