import _module from 'module.js'
import PermissionsBase from '../PermissionsBase'

export default class PermissionsPromptsController extends PermissionsBase {
  constructor (
    PermissionsData,
    User,
    Group,
    confirmationWindowOK,
    globalState,
    gettextCatalog,
    userService,
    $state
  ) {
    'ngInject'
    super(
      PermissionsData,
      User,
      Group,
      'prompts',
      confirmationWindowOK,
      globalState,
      gettextCatalog,
      userService,
      $state
    )

    this.phoenixBulkChangesPath = `/${globalState.selectedPbx.domain}/permissions/prompts`
  }
}

_module.controller('PermissionsPromptsController', PermissionsPromptsController)
