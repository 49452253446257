import playlistItemsTemplate from './playlistItemsTemplate.html'
import playlistItemsController from './playlistItemsController.js'
import module from 'module'

module.component('playlistItems', {
  template: playlistItemsTemplate,
  controllerAs: 'playlistItems',
  require: {
    ngModel: 'ngModel'
  },
  bindings: {
    selectedList: '=ngModel'
  },
  controller: playlistItemsController
})
