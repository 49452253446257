import module from 'module'
import moment from 'moment/min/moment-with-locales'

let SEC_PER_DAY = 86400
let SEC_PER_QUARTER_HOUR = 900
let SEC_PER_MIN = 60

let ROWS_PER_HOUR = 4
let ROWS_PER_12_HOURS = 48
let ROWS_PER_24_HOURS = 96

let nextId = 0

export default class TimeRange {
  constructor (startSeconds, endSeconds) {
    this.startSeconds = startSeconds || 0
    this.startTime = moment()
      .locale('en')
      .year(1970)
      .startOf('year')
      .startOf('week')
      .add(this.startSeconds, 'seconds')
    this.endSeconds = endSeconds || 3600
    this.endTime = moment()
      .locale('en')
      .year(1970)
      .startOf('year')
      .startOf('week')
      .add(this.endSeconds, 'seconds')

    this._id = nextId++

    this.determineEndPoints.call(this, this.startSeconds, this.endSeconds)
    return this
  }
  determineEndPoints () {
    this._start = {
      row: this.startTime.hour() * 4 +
        Math.floor(this.startTime.minutes() / 15),
      column: this.startTime.day(),
      label: this.startTime.minutes()
        ? this.startTime.format('LT')
        : this.startTime.format('LT')
    }
    if (this.endTime.hour() == 0 && this.endTime.minutes() == 0) {
      this._end = {
        row: ROWS_PER_24_HOURS,
        column: (this.endTime.day() + 6) % 7, // column -1 with wraparound
        label: this.endTime.minutes()
          ? this.endTime.format('LT')
          : this.endTime.format('LT')
      }
    } else {
      this._end = {
        row: this.endTime.hour() * 4 + Math.floor(this.endTime.minutes() / 15),
        column: this.endTime.day(),
        label: this.endTime.minutes()
          ? this.endTime.format('LT')
          : this.endTime.format('LT')
      }
    }
  }

  setEndpoints (startPoint, endPoint) {
    this.startSeconds =
      startPoint[0] * SEC_PER_QUARTER_HOUR + startPoint[1] * SEC_PER_DAY
    this.startTime = moment()
      .locale('en')
      .year(1970)
      .startOf('year')
      .startOf('week')
      .add(this.startSeconds, 'seconds')
    this.endSeconds =
      endPoint[0] * SEC_PER_QUARTER_HOUR + endPoint[1] * SEC_PER_DAY
    this.endTime = moment()
      .locale('en')
      .year(1970)
      .startOf('year')
      .startOf('week')
      .add(this.endSeconds, 'seconds')

    this.determineEndPoints.call(this, this.startSeconds, this.endSeconds)
  }

  setLocalizedTimestringEndpoints (startTimeRange, endTimeRange, langCode) {
    this.startTime = moment(startTimeRange).locale(langCode)
    this.endTime = moment(endTimeRange).locale(langCode)
    this.startSeconds = this.startTime.diff(
      this.startTime.clone().locale(langCode).startOf('week'),
      'seconds'
    )
    this.endSeconds = this.endTime.diff(
      this.startTime.clone().locale(langCode).startOf('week'),
      'seconds'
    )
    this.determineEndPoints.call(this, this.startSeconds, this.endSeconds)
  }

  setLocale (languageCode) {
    this.startTime.locale(languageCode)
    this.endTime.locale(languageCode)

    this.determineEndPoints.call(this, this.startSeconds, this.endSeconds)
  }

  getId () {
    return this._id
  }

  getStart () {
    return this._start
  }

  getEnd () {
    return this._end
  }

  getLabel () {
    return this._start.label + ' - ' + this._end.label
  }

  static factory () {
    'ngInject'
    return (startSeconds, endSeconds) => {
      return new TimeRange(startSeconds, endSeconds)
    }
  }
}

module.factory('TimeRange', TimeRange.factory)
