import _module from 'module.js'
import BaseController from 'classes/baseController'
import { FEATURE_FLAGS } from '../../services/featuresService'

export default class PermissionsController extends BaseController {
  constructor (
    $state,
    $stateParams,
    portalApi,
    Permissions,
    gettextCatalog,
    portalUtil,
    errorService,
    globalState,
    navigation,
    userService,
    $analytics,
    PermissionsData,
    MonitoringData,
    User,
    Group,
    confirmationWindowOK,
  ) {
    'ngInject'
    super(
      $state,
      $stateParams,
      Permissions,
      portalApi,
      userService,
      gettextCatalog,
      portalUtil,
      errorService,
      globalState,
      navigation,
      'root.nav.pbx.permissions.general.template',
      'permissions'
    )

    this.globalState = globalState
    this.state = $state
    this.data = PermissionsData
    this.ready = true
    this.confirmationWindowOK = confirmationWindowOK
    this.gettextCatalog = gettextCatalog

    this.monitoring = MonitoringData

    this.monitoringType = this.monitoring.useSimplePermissions
      ? 'useSimplePermissions'
      : 'useAclMonitoringPermissions'

    this.memberQuery = () => ({
      get: (params = null) => {
        const userApi = new User(null)
        const groupApi = new Group(null)
        return Promise.all([
          userApi.getList(params),
          groupApi.getList(params)
        ]).then(data => ({ items: data[0].concat(data[1]) }))
      }
    })

    userService.isPermissionTabAllowed(this.globalState.selectedPbx).then(ret => {
      this.writePermissions = ret
    })

    this.closeBulkChanges = this.closeBulkChanges.bind(this)
    this.phoenixBulkChangesLoaded = false
    this.bulkChangesOpen = false
    this.oauthToken = globalState.oauthToken
    this.orgId = globalState.selectedPbxUUID
    this.lmiAccountKey = globalState.selectedPbx.lmiAccountKey
    this.locale = globalState.currentLanguage.langCode
    this.history = globalState.history
    this.phoenixMonitoringBulkChangesPath = `/${this.globalState.selectedPbx.domain}/permissions/monitoring`
  }

  monitoringTypeSelected (type) {
    this.monitoring.useAclMonitoringPermissions =
      type === 'useAclMonitoringPermissions'
    this.monitoring.useSimplePermissions = type === 'useSimplePermissions'
  }

  hideSave () {
    return !this.writePermissions
  }

  async save (form) {
    this.ready = false
    try {
      await this.data.update()
      form.$setPristine()
      this.globalState.selectedPbx.useVoicemailAcls = this.data.useVoicemailAcls
      if (
        this.monitoringType === 'useSimplePermissions' ||
        (this.monitoringType === 'useAclMonitoringPermissions' &&
          !this.monitoring.aclMonitoringPermissions.filter(
            user => user.allowedToMonitor.length == 0
          ).length)
      ) {
        // This is a work around with bug https://jira.ops.expertcity.com/browse/ADMIN-4325
        this.monitoring.update()
            .catch(error => {
              this.portalUtil.showErrorAlert('Save failed, please retry.')
              throw error
            })
            .then(() => {
              this.portalUtil.showAlert(
                this.gettextCatalog.getString('Monitoring permissions saved'),
                this.gettextCatalog.getString('Processing your changes can take a few minutes.'),
                true
              )
            })
      } else {
        this.portalUtil.showErrorAlert(
          'All the persons or groups that can monitor, should have at least one person or group to monitor.'
        )
      }
    } catch (err) {
      console.error(err)
      this.portalUtil.showErrorAlert('Save failed, please retry.')
    } finally {
      this.ready = true
    }
  }

  openBulkChanges (itemForm) {
    if(itemForm && itemForm.$dirty){
      this.confirmationWindowOK
        .show(
          this.gettextCatalog.getString('Warning'),
          this.gettextCatalog.getString(
            'There are unsaved changes. Are you sure you want to navigate away?'
          )
        )
        .then(
          () => {
            this.phoenixBulkChangesLoaded = true
            this.bulkChangesOpen = true
          },
          () => {}
        )
    } else {
      this.phoenixBulkChangesLoaded = true
      this.bulkChangesOpen = true
    }
  }

  closeBulkChanges () {
    this.bulkChangesOpen = false
  }

  goToUsers () {
    this.state.go('root.nav.pbx.users.list')
  }
}

_module.controller('PermissionsController', PermissionsController)
