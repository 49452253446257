import _module from 'module.js'
import navTemplate from 'controllers/navBar/navBarTemplate.html'
import pbxTemplate from 'controllers/pbx/pbxTemplate.html'
import leftPanelTemplate from 'controllers/leftPanel/leftPanel_Template.html'
import securityTemplate from 'controllers/security/securityTemplate.html'
import settingsCallFilterTemplate from 'controllers/settings/settingsCallFilter/settingsCallFilterTemplate.html'
import notFoundTemplate from '404.html'
import commonRecordingTemplate from 'views/common/recording.tab.html'
import commonRecordingWarningTemplate from 'views/common/recordingWarning.tab.html'
import unsupportedBrowserWarningTemplate from 'views/common/unsupportedBrowserWarning.html'
import pbxActivationWarningTemplate from 'views/common/pbxActivationWarningTemplate.html'
import deviceStatusTemplate from 'controllers/deviceStatus/deviceStatusTemplate.html'
import unavailableLinesTemplate from 'controllers/unavailableLines/unavailableLinesTemplate.html'
import phoenixTemplate from 'controllers/phoenix/phoenixTemplate.html'
import toolsTemplate from 'controllers/tools/toolsTemplate.html'

import 'routes/guidedSetupRoutes'
import 'routes/unifiedAdminDashboardRoutes'
import 'routes/unifiedAdminLocationsRoutes'
import 'routes/userRoutes'
import 'routes/groupRoutes'
import 'routes/phoneNumberRoutes'
import 'routes/deviceRoutes'
import 'routes/activeDirectoryRoutes'
import 'routes/extensionRoutes'
import 'routes/musicRoutes'
import 'routes/directoryRoutes'
import 'routes/permissionRoutes'
import 'routes/scheduleRoutes'
import 'routes/settingRoutes'
import 'routes/siteRoutesV2'
import 'routes/contactCenterRoutes'

_module
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject'

    $stateProvider
      .state('root', {
        url: '',
        abstract: true,
        template: '<ui-view class="root"></ui-view>',
        controller: 'RootCtrl as ctrl'
      })
      .state('root.nav', {
        url: '',
        abstract: true,
        template: navTemplate,
        controller: 'NavbarCtrl as ctrl'
      })
      .state('root.nav.home', {
        url: '/',
        abstract: true,
        views: {
          leftPanel: {
            template: leftPanelTemplate,
            controller: 'LeftPanelCtrl as ctrl'
          },
          pbx: {
            template: '<ui-view></ui-view>'
          }
        }
      })
      .state('root.nav.pbx', {
        url: '/:id?lang',
        views: {
          leftPanel: {
            template: leftPanelTemplate,
            controller: 'LeftPanelCtrl as ctrl'
          },
          pbx: {
            template: '<ui-view></ui-view>'
          }
        }
      })
      .state('root.nav.pbx.dashboard', {
        url: '/dashboard',
        template: pbxTemplate,
        controller: 'PbxController as ctrl'
      })
      .state('root.nav.pbx.ldap', {
        url: '/ldap',
        abstract: true,
        template: '<ui-view class="ldap-abstract max-height"></ui-view>'
      })
      .state('root.nav.pbx.security', {
        url: '/security',
        template: securityTemplate,
        controller: 'SecurityController as ctrl'
      })
      .state('root.nav.pbx.callfilters', {
        url: '/callfilters',
        abstract: true,
        template: '<ui-view></ui-view>'
      })
      .state('root.nav.pbx.callfiltersv2', {
        url: '/callfilters-v2',
        template: phoenixTemplate,
        controller: 'PhoenixController as ctrl'
      })
      .state('root.nav.pbx.callfilters.list', {
        url: '?q&sort&direction&source',
        template: settingsCallFilterTemplate,
        controller: 'CallFiltersController as ctrl',
        reloadOnSearch: false
      })
      .state('root.nav.pbx.contacts', {
        url: '/contacts',
        template: phoenixTemplate,
        controller: 'PhoenixController as ctrl'
      })
      .state('root.nav.pbx.deviceStatus', {
        url: '/devicestatus',
        abstract: true,
        template: '<ui-view></ui-view>'
      })
      .state('root.nav.pbx.deviceStatus.list', {
        url: '?q&sort&direction&source',
        controller: 'DeviceStatusController as ctrl',
        template: deviceStatusTemplate,
        reloadOnSearch: false
      })
      .state('root.nav.pbx.unavailableLines', {
        url: '/unavailablelines',
        abstract: true,
        template: '<ui-view></ui-view>'
      })
      .state('root.nav.pbx.unavailableLines.list', {
        url: '?q&sort&direction&source',
        controller: 'UnavailableLinesController as ctrl',
        template: unavailableLinesTemplate,
        reloadOnSearch: false
      })
      .state('root.nav.pbx.tools', {
        url: '/tools',
        controller: 'ToolsController as ctrl',
        template: toolsTemplate
      })
      .state('root.nav.pbx.toolsv2', {
        url: '/tools-v2',
        template: phoenixTemplate,
        controller: 'PhoenixController as ctrl'
      })
      .state('root.nav.pbx.locations', {
        url: '/locations',
        template: phoenixTemplate,
        controller: 'PhoenixController as ctrl'
      })
      .state('root.nav.pbx.roles', {
        url: '/roles',
        template: phoenixTemplate,
        controller: 'PhoenixController as ctrl'
      })
      .state('root.nav.pbx.roles.details', {
        url: '/:roleId/:tab',
        template: phoenixTemplate,
        controller: 'PhoenixController as ctrl',
      })
      .state('404', {
        url: '/404',
        template: notFoundTemplate
      })

    $urlRouterProvider.otherwise($injector =>
      $injector.get('$state').go('root.nav.pbx.dashboard')
    )
  })
  .run($templateCache => {
    'ngInject'
    $templateCache.put('commonRecordingTemplate-html', commonRecordingTemplate)
    $templateCache.put(
      'commonRecordingWarningTemplate-html',
      commonRecordingWarningTemplate
    )
    $templateCache.put(
      'unsupportedBrowserWarningTemplate-html',
      unsupportedBrowserWarningTemplate
    )
    $templateCache.put(
      'pbxActivationWarningTemplate-html',
      pbxActivationWarningTemplate
    )
  })
