import _module from 'module.js'
import controller from './aclMonitoringPermissionsController'

_module.directive('aclMonitoringPermissions', () => {
  return {
    restrict: 'AE',
    controllerAs: 'aclMonitoringPermissions',
    controller
  }
})
