import jrnParser from 'classes/jrnParser'

let _jrn, _state, _globalState, _portalApi, _HoldMusicPlaylist
export default class GetHoldMusicController {
  constructor (
    gettextCatalog,
    $state,
    globalState,
    portalApi,
    HoldMusicPlaylist
  ) {
    'ngInject'
    this._model = {}
    _state = $state
    _globalState = globalState
    _jrn = jrnParser
    _portalApi = portalApi
    _HoldMusicPlaylist = HoldMusicPlaylist

    this.placeholder = gettextCatalog.getString('Select a playlist...')


    this.extraParams = [
      {
        key: 'language',
        value: _globalState.currentLanguage.langCode
      }
    ]

    this.$onInit = () => {
      this.setViewValue = item => {
        this.ngModel.$setViewValue(item.id)
      }
    }
  }

  api (id) {
    if (id) {
      return new _HoldMusicPlaylist(id)
    }
    return _portalApi.media
      .pbxs(_globalState.selectedPbxUUID)
      .holdMusicPlaylists()
      .one('all')
  }

  map (playlist) {
    return {
      id: playlist.jrn,
      text: playlist.name
    }
  }

  openLink () {
    let holdmusic = this._model.text === 'Default'
      ? 'default'
      : _jrn.parse(this.jrn).getResources()[1]
    _state.go('root.nav.pbx.holdmusic.list', {
      id: _globalState.selectedPbx.domain,
      holdmusicId: holdmusic
    })
  }
}
