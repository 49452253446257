import _module from 'module.js'
import _ from 'lodash'

_module.directive('uniqueTrunkPrefix', function (
  portalApi,
  $q,
  globalState
) {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, $input, attrs, ngModelCtrl) {
      let originalValue = scope.$eval(attrs.ngModel)

      ngModelCtrl.$asyncValidators.uniqueTrunkPrefix = (
        model,
        view
      ) => {
        var value = model || view
        var dfd = $q.defer()

        if (!value || value.length === 0) {
          dfd.resolve(true)
        }
        let currentPrefixes = attrs.currentPrefixes
        if(!currentPrefixes) {
          dfd.resolve(true)
        }

        if (!originalValue || originalValue !== value) {
          if (value) {
            let prefixes = currentPrefixes.split(",")
              _.findIndex(prefixes, (oldPrefix) => {
                if(oldPrefix == value) {
                  dfd.reject('exists')
                }
              })
              dfd.resolve(true)
          }
        } else {
          dfd.resolve(true)
        }
        return dfd.promise
      }
    }
  }
})
