import _module from 'module.js'
import is from 'is'
import _ from 'lodash'
import jrnParser from 'classes/jrnParser'
import { createBrowserHistory } from 'history'
import { LMI_ADMIN_PORTAL_TOKEN } from './jiveOAuthClient'

export default class GlobalStateService {
  constructor($rootScope, $location, FEATURE_FLAGS, $localStorage) {
    'ngInject'
    this._rootScope = $rootScope
    this._location = $location
    this._localStorage = $localStorage
    this._FEATURE_FLAGS = FEATURE_FLAGS
    this._ambassadorClientAppId = '9CQ7fIq9p7fhlmGDZy67tZYPgp7EbCv1KrVDxcVx'
    this._selectedPbx = null
    this._selectedPbxUUID = null
    this._selectedPbxLmiAccountKey = null
    this._selectedPbxLanguage = null
    this._selectedPbxUnifiedAdmin = null

    this._noPbx = null
    this._currentLanguage = {}
    this._supportedLanguages = null
    this._supportedLanguagesWDefault = null
    this._supportedRegions = null
    this._supportedRegionsWDefault = null

    this._isWindows = is.ie() || is.edge()

    this._isBannerShowing =
      JSON.parse(localStorage.getItem('isBannerShowingV2')) === null
    this._isBrowserSupported =
      is.chrome() || is.firefox() || is.safari() || is.ie() || is.edge()
    this._isHvoice = is.include(this._location.host(), 'hvoice')


    this._audioFormats = [
      {
        id: 'WAV49',
        text: 'WAV49'
      },
      {
        id: 'WAV',
        text: 'WAV'
      },
      {
        id: 'MP3',
        text: 'MP3'
      }
    ]

    this._isJiveEmployee = false
    this._newView = false
    this._userData = null
    this._isPlatform = false
    this._isPlatformAdmin = false
    this._isPlatformFeatureFlag = false
    this._isSupportingHungaryRegionFeatureFlag = false
    this._partnerAdmin = false
    this._partnerPbxAdmin = false
    this._isUserSuperAdmin = false
    this._isUserManageSeatsOnly = false
    this._isUserManageUsersOnly = false
    this._isUserManageSettingsOnly = false
    this._isUserManageGroupsOnly = false

    this._previousListState = null
    this._previousListParams = null
    this._locationSearch = null

    this._listSource = {
      stateName: null,
      stateParams: null
    }
    this._isUserAgentSipTrunk = null

    this._isList = false

    // history to be passed to Phoenix root component
    this._history = createBrowserHistory({
      basename:
        $location.host() === 'localhost'
          ? '/'
          : 'URL' in window
            ? new URL(document.baseURI).pathname.replace(/\/$/, '')
            : '/pbx'
    })

    try {
      // OAuth token to be passed to Phoenix root component
      this._oauthToken = JSON.parse(
        localStorage.getItem(LMI_ADMIN_PORTAL_TOKEN) || localStorage.getItem('jive-foken') || '{}'
      ).token
    } catch (e) {
      console.error(e)
    }

    this._rootScope.$on(
      '$stateChangeStart',
      (event, toState, toParams, fromState, fromParams) => {
        let toStateNameArray = toState.name.split('.')
        let fromStateNameArray = fromState.name.split('.')

        if (fromState.name === '') {
          // we are just loading a page directly, there could be url query parameters
          this._listSource.stateParams = this._location.search()
          this._listSource.stateName = this._listSource.stateParams
            ? this._listSource.stateParams.source
            : null
        } else if (
          toState.name !== this._listSource.stateName &&
          (toState.name.endsWith('list') ||
            toState.name === 'root.nav.pbx.dashboard' ||
            _.includes(toState.name, 'root.nav.pbx.settings') ||
            toState.name === 'root.nav.pbx.holdmusic.view' ||
            toState.name === 'root.nav.pbx.tools')
        ) {
          this._listSource = {
            stateName: null,
            stateParams: null
          }
        } else if (fromState && fromState.name.endsWith('list')) {
          // we need to keep where we are from
          this._listSource = {
            stateName: fromState.name,
            stateParams: fromParams
          }
          this._listSource.stateParams.source = fromState.name
        } else if (
          fromState.name !== toState.name &&
          !fromState.name.endsWith('list') &&
          !toState.name.endsWith('list')
        ) {
          // clicking on a link to another entity from a dropdown
          this._listSource = {
            stateName: null,
            stateParams: null
          }
        }
      }
    )

    this._rootScope.$on(
      '$stateChangeSuccess',
      (event, toState, toParams, fromState, fromParams) => {
        this._isList = toState.name.endsWith('list')
        this._location.search(
          _.pick(this._listSource.stateParams, [
            'q',
            'sort',
            'direction',
            'source',
            'filter'
          ])
        )
      }
    )
  }

  get ambassadorClientAppId() {
    return this._ambassadorClientAppId
  }

  get selectedPbx() {
    return this._selectedPbx
  }

  get isList() {
    return this._isList
  }

  set selectedPbx(pbx) {
    this._selectedPbx = pbx
    this._selectedPbxUUID = this._selectedPbx
      ? jrnParser.parse(this._selectedPbx.jrn).getAccount()
      : null
    this._isNA = this._selectedPbx
      ? this._selectedPbx.region === 'US' || this._selectedPbx.region === 'CA'
      : true
    this._selectedPbxLmiAccountKey = this.selectedPbx ? this._selectedPbx.lmiAccountKey : null
    this._selectedPbxLanguage = this.selectedPbx ? this._selectedPbx.language : null
    this._selectedPbxUnifiedAdmin = this.selectedPbx ? this._selectedPbx.unifiedAdmin : null
  }

  get selectedPbxUUID() {
    return this._selectedPbxUUID
  }

  get selectedPbxLmiAccountKey() {
    return this._selectedPbxLmiAccountKey
  }

  get selectedPbxLanguage() {
    return this._selectedPbxLanguage
  }

  get selectedPbxUnifiedAdmin() {
    return this._selectedPbxUnifiedAdmin
  }

  get currentLanguage() {
    return this._currentLanguage
  }

  set currentLanguage(lang) {
    this._currentLanguage = lang
  }

  get isNA() {
    return this._isNA
  }

  get isBrowserSupported() {
    return this._isBrowserSupported
  }

  get isBannerShowing() {
    return this._isBannerShowing
  }

  set isUserAgentSipTrunk(userAgent) {
    this._isUserAgentSipTrunk = userAgent
  }

  get isUserAgentSipTrunk() {
    return this._isUserAgentSipTrunk
  }
  set isBannerShowing(bannerShowing) {
    this._isBannerShowing = bannerShowing
  }

  get audioFormats() {
    return this._audioFormats
  }

  get supportedRegions() {
    if (this.isInternalRegionsSupported) {
      return this._supportedRegions
    }
    else {
      return this._supportedRegions.filter((region) => !region.internal)
    }
  }

  set supportedRegions(regions) {
    this._supportedRegions = regions
  }

  get supportedRegionsWDefault() {
    return this._supportedRegionsWDefault
  }

  set supportedRegionsWDefault(regionsWDefault) {
    this._supportedRegionsWDefault = regionsWDefault
  }

  get isWhiteLabel() {
    return this._isHvoice
  }

  get isHvoice() {
    return this._isHvoice
  }

  get pbxTitle() {
    return this.selectedPbx.name + ' (' + this.selectedPbx.domain + ')'
  }

  get supportedLanguages() {
    return this._supportedLanguages
  }

  set supportedLanguages(languages) {
    this._supportedLanguages = languages
  }

  get supportedLanguagesWDefault() {
    return this._supportedLanguagesWDefault
  }

  set supportedLanguagesWDefault(languagesWDefault) {
    this._supportedLanguagesWDefault = languagesWDefault
  }

  get listSource() {
    return this._listSource
  }

  get noPbx() {
    return this._noPbx
  }

  set noPbx(flag) {
    this._noPbx = flag
  }

  get newView() {
    return this._newView
  }

  set newView(flag) {
    this._newView = flag
  }

  get isJiveEmployee() {
    return this._isJiveEmployee
  }

  set isJiveEmployee(flag) {
    this._isJiveEmployee = flag
  }

  get isPlatformFeatureFlag() {
    return this._isPlatformFeatureFlag
  }

  set isPlatformFeatureFlag(flag) {
    this._isPlatformFeatureFlag = flag
  }

  get isInternalRegionsSupported() {
    return this.hasFeatureFlag(this._FEATURE_FLAGS.internalRegions)
  }

  get isPlatform() {
    return this._isPlatform
  }

  set isPlatform(flag) {
    this._isPlatform = flag
  }

  get isPlatformAdmin() {
    return this._isPlatformAdmin
  }

  set isPlatformAdmin(flag) {
    this._isPlatformAdmin = flag
  }

  get isPartnerAdmin() {
    return this._isPartnerAdmin
  }

  set isPartnerAdmin(flag) {
    this._isPartnerAdmin = flag
  }

  get isPartnerPbxAdmin() {
    return this._isPartnerPbxAdmin
  }

  set isPartnerPbxAdmin(flag) {
    this._isPartnerPbxAdmin = flag
  }

  get isUserSuperAdmin() {
    return this._isUserSuperAdmin
  }

  set isUserSuperAdmin(flag) {
    this._isUserSuperAdmin = flag
  }

  get isUserManageSeatsOnly() {
    return this._isUserManageSeatsOnly
  }

  set isUserManageSeatsOnly(flag) {
    this._isUserManageSeatsOnly = flag
  }

  get isUserManageUsersOnly() {
    return this._isUserManageUsersOnly
  }

  set isUserManageUsersOnly(flag) {
    this._isUserManageUsersOnly = flag
  }

  get isUserManageGroupsOnly() {
    return this._isUserManageGroupsOnly
  }

  set isUserManageGroupsOnly(flag) {
    this._isUserManageGroupsOnly = flag
  }

  get isUserManageSettingsOnly() {
    return this._isUserManageSettingsOnly
  }

  set isUserManageSettingsOnly(flag) {
    this._isUserManageSettingsOnly = flag
  }

  get userData() {
    return this._userData
  }

  set userData(data) {
    this._userData = data
  }

  get isWindows() {
    return this._isWindows
  }

  setPbxRegion(region) {
    if (this._selectedPbx) {
      this._selectedPbx.region = region
      this._isNA =
        this._selectedPbx.region === 'US' || this._selectedPbx.region === 'CA'
    }
  }

  get displayE911Carousel() {
    return this.isE911Enabled && !this._localStorage.e911CarouselViewed
  }

  set displayE911Carousel(flag) {
    this._localStorage.e911CarouselViewed = flag
  }

  hasEntitlement(entitlement) {
    return this._selectedPbx && this._selectedPbx.entitlements && this._selectedPbx.entitlements[entitlement]
  }

  hasFeatureFlag(flag) {
    return this._selectedPbx && this._selectedPbx.featureFlags && !!this._selectedPbx.featureFlags[flag]
  }

  get isE911Enabled() {
    return this.hasFeatureFlag(this._FEATURE_FLAGS.e911Enabled) && this.isUserSuperAdmin
  }

  get isE911V3Enabled() {
    return this.hasFeatureFlag(this._FEATURE_FLAGS.e911V3Configuration)
  }

  get isDeviceEmergencyLocationEnabled() {
    return this.hasFeatureFlag(this._FEATURE_FLAGS.e911Enabled) && !this.hasFeatureFlag(this._FEATURE_FLAGS.e911V3Configuration)
  }

  get showButtonConfiguration() {
    return (!this.hasFeatureFlag(this._FEATURE_FLAGS.sipTrunks)) || ((this.hasFeatureFlag(this._FEATURE_FLAGS.sipTrunks) && !this.isUserAgentSipTrunk))
  }

  get isLocationEnabled() {
    return this.hasFeatureFlag(this._FEATURE_FLAGS.pbxAdminLocation)
  }

  get isCallReportRecordingsEnabled() {
    return this.hasFeatureFlag(this._FEATURE_FLAGS.callReportRecordingsEnabled)
  }

  get isVirtualFaxMachineLargeFaxesEnabled() {
    return this.hasFeatureFlag(this._FEATURE_FLAGS.virtualFaxMachineLargeFaxesEnabled)
  }

  get isVirtualFaxMachinePermissionsEnabled() {
    return this.hasFeatureFlag(this._FEATURE_FLAGS.virtualFaxMachinePermissionsEnabled)
  }

  get isVirtualFaxMachineStorageEnabled() {
    return this.hasFeatureFlag(this._FEATURE_FLAGS.virtualFaxMachineStorage)
  }

  get isVirtualReceptionistEnabled() {
    return this.hasFeatureFlag(this._FEATURE_FLAGS.virtualReceptionistV1)
  }

  get isStoreIncomingFaxes() {
    return this._selectedPbx.storeIncomingFaxes;
  }

  get history() {
    return this._history
  }

  get oauthToken() {
    return this._oauthToken
  }

  get smsCampaignInformations() {
    return {
      needToRegisterSmsCampaign:
        (this._selectedPbx.featureFlags[this._FEATURE_FLAGS.smsCampaignRegistration] &&
          this._selectedPbx.pbxSmsCampaignDetails.pbxNeedsACampaignToUseSms) || false,
      hasSmsCampaign: this._selectedPbx.pbxSmsCampaignDetails.pbxHasACampaign || false,
      userCanRegisterSmsCampaign: this._selectedPbx.pbxSmsCampaignDetails.userIsAddOnAdmin || false,
      pbxIsUsingSms: this._selectedPbx.pbxSmsCampaignDetails.pbxIsUsingSms || false,
    }
  }
}

_module.service('globalState', GlobalStateService)
