import _module from 'module.js'
import addDeviceFormTemplate
  from '../views/common/addDeviceForm.html'
import _ from 'lodash'
import jrnParser from 'classes/jrnParser'

class AddDeviceFormController {
  constructor (
    Device,
    Line,
    User,
    Hardware,
    portalApi,
    $stateParams,
    $q,
    $analytics,
    gettextCatalog,
    portalUtil,
    $mdDialog,
    globalState
  ) {
    'ngInject'
    this.form = {
      device: {
        useUserFullNameAsName: false,
        useUserFullNameAsStationLabel: false,
        presenceAware: true
      },
      busy: false,
      userUserNameAsStationNameAndLabel: true
    }

    this.Device = Device
    this.Line = Line
    this.User = User
    this.Hardware = Hardware
    this.portalApi = portalApi
    this.$stateParams = $stateParams
    this.jrn = jrnParser
    this.$q = $q
    this.$analytics = $analytics
    this.gettextCatalog = gettextCatalog
    this.portalUtil = portalUtil
    this.$mdDialog = $mdDialog
    this.globalState = globalState
    this.showExtensionWarning = globalState.selectedPbx.extensionDigits == 3

    this.deviceHardwareSelector = "SELECT"
    this.assignUser = true
    this.assignLine = false
  }

  createDevice (device, user, line, hardware) {
    if (user) {
      device.useUserFullNameAsName = true
      device.useUserFullNameAsStationLabel = true
    } else {
      device.useUserFullNameAsName = false
      device.useUserFullNameAsStationLabel = false
    }

    var _device = new this.Device(null, device)
    _device.assignedUser = user

    if (hardware) {
      _device.userAgent = hardware.jrn
    }

    _device.buttons = {
      '0:0': {
        label: line.extensionNumber,
        parameters: line.jrn,
        type: 'LINE',
        userEditable: true
      }
    }


    return _device.save().then(result => {
      return result.plain()
    })
  }

  createLine (line) {
    var _line = new this.Line(null, line)
    return _line.save().then(result => result.plain())
  }

  createHardware (hardware) {
    let _hardware = new this.Hardware(null, hardware)
    return _hardware.save()
  }

  complete () {
    this.form.busy = true
    if(!this.assignUser) {
      this.form.device.name = this.form.deviceDisplayName
    }
    this.form.line.name = this.form.device.name
    let creators = []
    if(!this.assignLine) {
      creators.push(this.createLine(this.form.line))
    } else {
      let lineId = this.jrn.parse(this.form.assignedExtensionJrn).getResources()[1]
      creators.push(
        this.portalApi.extension
          .pbxs(this.globalState.selectedPbxUUID)
          .lines(lineId)
          .get()
      )
    }

    if (this.form.hardware && this.form.hardware.device) {
      let hardwareId = this.jrn.parse(this.form.hardware.device).getResources()[1]
      creators.push(
        this.portalApi.userAgent
          .pbxs(this.globalState.selectedPbxUUID)
          .userAgents(hardwareId)
          .get()
      )
    } else if (this.form.hardware && this.form.hardware.macAddress) {
      creators.push(this.createHardware(this.form.hardware))
    }

    this.$q
      .all(creators)
      .then(([line, hardware]) => {
        return this.createDevice(
          this.form.device,
          this.form.assignedUser,
          line,
          hardware
        ).then(newDevice => {
          this.form.busy = false
          this.$mdDialog.hide(newDevice)
        })
      })
      .catch(error => {
        this.form.busy = false
        this.portalUtil.showErrorAlert(
          this.gettextCatalog.getString('Save failed, please retry.')
        )
        throw error
      })
  }

  userChanged (userJrn) {
    let userId = this.jrn.parse(userJrn).getResources()[1]
    let userApi = new this.User(userId)

    userApi.get().then(user => {
      let fullName =
        (_.isEmpty(user.firstName) ? '' : user.firstName + ' ') + user.lastName
      this.form.device.name = fullName
      this.form.device.stationLabel = fullName
    })
  }

  setExtensionInfo (extensionJrn) {
    let lineId = this.jrn.parse(extensionJrn).getResources()[1]
    let lineApi = new this.Line(lineId)

    lineApi.get().then(line => {
      this.form.line.extensionNumber = line.number
    })

  }

  cancelDialog () {
    this.$mdDialog.cancel()
  }

  static factory (
    Device,
    Line,
    User,
    Hardware,
    portalApi,
    $stateParams,
    $q,
    $analytics,
    gettextCatalog,
    portalUtil,
    $mdDialog,
    globalState
  ) {
    'ngInject'
    return new AddDeviceFormController(
      Device,
      Line,
      User,
      Hardware,
      portalApi,
      $stateParams,
      $q,
      $analytics,
      gettextCatalog,
      portalUtil,
      $mdDialog,
      globalState
    )
  }
}

class AddDeviceForm {
  constructor ($mdDialog, $analytics) {
    'ngInject'
    this.$mdDialog = $mdDialog
    this.$analytics = $analytics
  }

  open () {
    return this.$mdDialog.show({
      template: addDeviceFormTemplate,
      controllerAs: 'ctrl',
      controller: AddDeviceFormController.factory,
      parent: angular.element(document.body),
      clickOutsideToClose: true,
      fullscreen: true
    })
  }

  static factory ($mdDialog, $analytics) {
    'ngInject'
    return new AddDeviceForm($mdDialog, $analytics)
  }
}

_module.factory('addDeviceForm', AddDeviceForm.factory)
