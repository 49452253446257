import _module from 'module.js'

// this is for Music through the Library (Built-in and Uploaded Music)
let _portalApi, _globalState
export default class HoldMusic {
  constructor () {
    'ngInject'
    this.LIMIT_DEFAULT = 1000
    this.offset = 0
    this.cacheEnabled = true
  }

  getMusicApiBase () {
    if (!this.musicApiBase) this.musicApiBase = _portalApi.music.api()

    return this.musicApiBase
  }

  getUploadedApi () {
    // cannot cache  uploadedApi as pbx ($stateParams.id)  can change
    this.uploadedApi = _portalApi.media
      .pbxs(_globalState.selectedPbxUUID)
      .holdMusicFiles()
    return this.uploadedApi
  }

  plainify (promise) {
    return promise.then(data => {
      return data.plain()
    })
  }

  getUploadedMusic () {
    return this.plainify(this.getUploadedApi().get())
  }

  getDefaultMusic () {
    return this.plainify(this.getMusicApiBase().default().get())
  }

  getBuiltInMusic () {
    return this.plainify(this.getMusicApiBase().builtIn().get())
  }

  _uploadMusicApi () {
    return this.getUploadedApi()
  }

  uploadMusicFile (file, volume) {
    var formData = new FormData()

    var details = {
      name: file.name,
      description: file.description,
      normalization: volume
    }

    formData.append('content', file)
    formData.append('details', JSON.stringify(details))

    return this.plainify(
      this._uploadMusicApi()
        .withHttpConfig({
          transformRequest: angular.identity
        })
        .customPOST(formData, undefined, undefined, {
          'Content-Type': undefined
        })
    )
  }

  getMusicApi (type) {
    let ret = null
    if (type === 'uploaded') {
      ret = this.getUploadedApi()
    } else if (type === 'builtIn') {
      ret = this.getMusicApiBase().builtIn()
    }
    return ret
  }

  retrieveMusic (_type, _offset, _limit, _query) {
    let musicApi = this.getMusicApi(_type)
    return musicApi
      .get({
        q: _query,
        offset: _offset,
        limit: _limit
      })
      .then(data => {
        data.noMoreData = data.items.length < this.LIMIT_DEFAULT
        this.offset = this.offset + this.LIMIT_DEFAULT
        return data
      })
  }

  getMusic (type) {
    this.offset = 0
    return this.retrieveMusic(type, this.offset, this.LIMIT_DEFAULT, null)
  }

  requestMoreMusics (type, query) {
    return this.retrieveMusic(type, this.offset, this.LIMIT_DEFAULT, query)
  }

  applyQuery (type, query) {
    this.offset = 0
    return this.retrieveMusic(type, this.offset, this.LIMIT_DEFAULT, query)
  }

  static factory (globalState, portalApi) {
    'ngInject'
    _portalApi = portalApi
    _globalState = globalState

    return new HoldMusic()
  }
}

_module.factory('holdMusic', HoldMusic.factory)
