/**
 * @ngdoc function
 * @name platformApp.factory:dialPlanService
 * @description
 * # dialPlanService
 * Controller of the platformApp
 */

import _module from 'module.js'
import ExtensionBaseES6 from 'factories/extensionBaseES6'
import is from 'is'

let _portalApi, _portalUtil, _globalState, _pbxUtil, _licensesService

export default class DialPlan extends ExtensionBaseES6 {
  constructor (id, dialPlan, gettextCatalog) {
    super(id)

    this.gettextCatalog = gettextCatalog
    if (id === undefined) {
      throw new Error(
        this.gettextCatalog.getString('Missing id for new DialPlan')
      )
    }

    if (dialPlan) {
      this._map(dialPlan)
    }
  }

  getApi () {
    if (!this._api) {
      this._api = _portalApi.extension.pbxs(
        _globalState.selectedPbxUUID
      ).dialPlans
    }
    return this._api
  }

  getReferencesPath () {
    if (!this._referencesPath) {
      this._referencesPath = _portalApi.extension
        .pbxs(_globalState.selectedPbxUUID)
        .dialPlans(this._id)
        .one('references')
    }
    return this._referencesPath
  }

  getDialPlanXml () {
    return this.getPath()
      .one('dial-plan.xml')
      .get()
  }

  setDialPlanXml (dialPlanXml) {
    return this.getPath().post(
      'dial-plan.xml',
      new XMLSerializer().serializeToString(dialPlanXml),
       {},
       {'Cache-Control' : 'no-cache'}
    )
  }

  dialPlanToXml (dialPlan) {
    return new window.DOMParser().parseFromString(dialPlan, 'text/xml')
  }

  downloadDialPlanXml (dialPlanXml, dialPlan) {
    const fileIdentifier = this.gettextCatalog.getString('dialplan')
    const mimeType = 'data:text/plain;charset=utf-8'
    const fileName = dialPlan.name + '.' + fileIdentifier + '.xml'

    if (is.ie() || is.edge()) {
      return navigator.msSaveBlob(
        new Blob([dialPlanXml], { type: mimeType }),
        fileName
      )
    }

    const element = document.createElement('a')
    element.setAttribute(
      'href',
      mimeType + ',' + encodeURIComponent(dialPlanXml)
    )
    element.setAttribute('download', fileName)

    element.style.display = 'none'
    document.body.appendChild(element)

    element.click()

    document.body.removeChild(element)
  }

  getPath () {
    if (!this._path) {
      this._path = _portalApi.extension
        .pbxs(_globalState.selectedPbxUUID)
        .dialPlans(this._id)
    }
    return this._path
  }

  map (data) {
    this.extensionRegion = data.extensionRegion ? data.extensionRegion : ''
  }

  build (payload) {
    payload.extensionNumber = this.extensionNumber || null
    return payload
  }

  _encodedName () {
    let dialplanName = this.name
    if (this.extensionNumber) {
      dialplanName = this.extensionNumber + ': ' + dialplanName
    }
    return encodeURIComponent(dialplanName)
  }

  async createDialPlanUrl () {
    const queryParams = [
      'lang=' + _portalUtil.getLanguageParamForDialPlanEditor(),
      'dialPlanId=' + this._id,
      'pbx=' + _globalState.selectedPbxUUID,
      'dialplanName=' + this._encodedName()
    ]
    const needsToOpenNewDialPlanEditor = await this.needsToOpenNewDialPlanEditor()

    const baseGoToUrl = _pbxUtil.getGoToAdminUrl(_globalState.selectedPbx)
    const oldDialPlanEditorUrl = '/pbx/dpe2/?' + queryParams.join('&')
    return needsToOpenNewDialPlanEditor? `${baseGoToUrl}/dial-plans/dial-plan-editor/${this._id}`: oldDialPlanEditorUrl
  }

  async needsToOpenNewDialPlanEditor () {
    const dialPlanSmsNodeProvisioned = await this.hasNotAllowedNode('dialPlanSmsNodeProvisioned','SMSNode')
    const virtualReceptionistAllowed = _globalState.isVirtualReceptionistEnabled && await this.hasNotAllowedNode('virtualReceptionistAllowed','VirtualReceptionistNode')
    return dialPlanSmsNodeProvisioned || virtualReceptionistAllowed
  }

  async hasNotAllowedNode(entitlement, nodeType){
    if(_globalState.hasEntitlement(entitlement)){
      const xml = await this.getDialPlanXml();
      return xml.includes(`type="${nodeType}"`);
    }
    return false
  }

  getClass () {
    return 'DialPlan'
  }

  static factory (portalApi, gettextCatalog, portalUtil, globalState, pbxUtil, licensesService) {
    'ngInject'
    _portalApi = portalApi
    _portalUtil = portalUtil
    _globalState = globalState
    _pbxUtil = pbxUtil
    _licensesService = licensesService

    return (id, dialPlan) => {
      return new DialPlan(id, dialPlan, gettextCatalog)
    }
  }
}

_module.factory('DialPlan', DialPlan.factory)
