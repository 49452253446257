import _module from 'module.js'

_module.directive('extensionMappingPattern', function () {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, $input, attrs, ngModelCtrl) {
      ngModelCtrl.$validators.extensionMappingPattern = () => {
        return /^[\dxX*]+$/.test($input.val())
      }
    }
  }
})
