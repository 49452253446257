/**
 * @ngdoc function
 * @name platformApp.controller:GroupController
 * @description
 * # GroupController
 * Controller of the platformApp
 */
import _module from 'module.js'
import _ from 'lodash'
import BaseController from 'classes/baseController'

export default class GroupController extends BaseController {
  constructor (
    portalApi,
    $stateParams,
    Group,
    userService,
    gettextCatalog,
    $state,
    portalUtil,
    errorService,
    globalState,
    navigation,
    $rootScope,
    $timeout
  ) {
    'ngInject'
    super(
      $state,
      $stateParams,
      Group,
      portalApi,
      userService,
      gettextCatalog,
      portalUtil,
      errorService,
      globalState,
      navigation,
      'root.nav.pbx.groups.list',
      'groupId'
    )

    this.hasManageGroupsPerm = globalState.isUserManageGroupsOnly
    this.hasManageUsersPerm = globalState.isUserManageUsersOnly
    this.hasManageSeatsOnly = globalState.isUserManageSeatsOnly
    this.canEdit = !this.hasManageSeatsOnly || this.hasManageGroupsPerm || this.hasManageUsersPerm

    this.data
      .get()
      .then(data => {
        this.ready = true
        this.initial = false
        this.itemName = data.name
        this.hasLoop = data.containsLoop
        $timeout(() => {
          $rootScope.$apply()
        })
      })
      .catch(error => {
        let errorMessage = this.errorService.getErrorMessage(error)
        this.portalUtil.showErrorAlert(
          _.isEmpty(errorMessage)
            ? this.gettextCatalog.getString(
                'Data retrieval failed, please retry.'
              )
            : errorMessage
        )
        this.ready = true
        this.initial = false
        $timeout(() => {
          $rootScope.$apply()
        })
        throw error
      })
  }

  hideSave () {
    return !this.canEdit
  }

  save (form) {
    this.ready = false
    this.data.name = this.itemName
    return this.data
      .update()
      .then(() => {
        this.itemName = this.data.name
        form.$setPristine()
        this.ready = true
      })
      .catch(error => {
        if (this.errorService._isJiveRuntimeError(error)) {
          this.handleJiveRuntimeError(error)
        } else {
          this.portalUtil.showErrorAlert(
            this.gettextCatalog.getString('Save failed, please retry.')
          )
        }

        this.ready = true
        throw error
      })
  }

  handleJiveRuntimeError(jiveRuntimeError) {
    let errorMessage = jiveRuntimeError.data.message
    switch (jiveRuntimeError.data.errorCode) {
      case 'MULTIPLE_PARENT':
        errorMessage = this.gettextCatalog.getString('This group is already a member of a parent group. A group can only be assigned to one parent group.')
        break
      case 'MEMBERSHIP_LOOP':
        errorMessage = this.gettextCatalog.getString('Adding to this group will create a membership loop. Review your group membership to determine a solution that does not create membership loop.')
        break
    }
    this.portalUtil.showErrorAlert(errorMessage)
  }
}

_module.controller('GroupController', GroupController)
