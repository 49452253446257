import _module from 'module.js'
import changeExtensionDigitsTemplate
  from '../controllers/settings/settingsGeneral/changeExtensionDigits.html'
import changeExtensionDigitsPopup from './changeExtensionDigitsPopup.js'
import _ from 'lodash'

export default class ChangeExtensionDigits {
  constructor (
    $mdDialog,
    $mdMedia,
    portalApi,
    $stateParams,
    gettextCatalog,
    portalUtil
  ) {
    'ngInject'
    this.$mdDialog = $mdDialog
    this.$mdMedia = $mdMedia
    this.portalApi = portalApi
    this.portalUtil = portalUtil
    this.$stateParams = $stateParams
    this.gettextCatalog = gettextCatalog

    this.useFullScreen = this.$mdMedia('sm') || this.$mdMedia('xs')
  }

  confirm (ev, originalLength) {
    return this.$mdDialog.show({
      template: changeExtensionDigitsTemplate,
      parent: angular.element(document.body),
      targetEvent: ev,
      clickOutsideToClose: true,
      fullscreen: this.useFullScreen,
      controllerAs: 'ctrl',
      locals: {
        originalLength: originalLength,
        $mdDialog: this.$mdDialog,
        portalApi: this.portalApi,
        portalUtil: this.portalUtil,
        gettextCatalog: this.gettextCatalog,
        $stateParams: this.$stateParams
      },

      controller: changeExtensionDigitsPopup
    })
  }
}

_module.service('changeExtensionDigits', ChangeExtensionDigits)
