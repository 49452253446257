/**
 * @ngdoc function
 * @name platformApp.controller:RingGroupController
 * @description
 * # RingGroupController
 * Controller of the platformApp
 */
import _module from 'module.js'
import _ from 'lodash'
import ExtensionController from 'classes/extensionController'
import jrnParser from 'classes/jrnParser'

let CONFIRM_PICKUP_LIMIT = 19
let GENERAL_LIMIT = 50

export default class RingGroupController extends ExtensionController {
  constructor (
    $state,
    $stateParams,
    portalApi,
    RingGroup,
    userService,
    confirmationWindowOK,
    confirmationWindowWarn,
    gettextCatalog,
    portalUtil,
    errorService,
    globalState,
    navigation,
    $filter,
    voicemailGreeting,
    $rootScope,
    $timeout
  ) {
    'ngInject'
    super(
      $state,
      $stateParams,
      RingGroup,
      portalApi,
      userService,
      gettextCatalog,
      portalUtil,
      errorService,
      globalState,
      navigation,
      'root.nav.pbx.extensions.ringgroups.list',
      'extId'
    )

    this.jrn = jrnParser
    this.filter = $filter
    this.confirmationWindowOK = confirmationWindowOK
    this.confirmationWindowWarn = confirmationWindowWarn
    this.voicemailGreeting = voicemailGreeting
    this.GDPREnabled = this.globalState._selectedPbx.featureFlags['pbx.gdpr']
    this.displayVoicemailTranscriptionSupportedWarning= false

    this.voiceGreetingApis = {
      tts: this.voicemailGreeting.uploadRingGroupTTSGreeting.bind(
        this.voicemailGreeting
      ),
      upload: this.voicemailGreeting.uploadRingGroupGreeting.bind(
        this.voicemailGreeting
      ),
      del: this.voicemailGreeting.getRingGroupGreetingApi().one('vmgreeting'),
      play: this.voicemailGreeting
        .getRingGroupGreetingApi()
        .one('vmgreeting-url')
    }

    this.voicemailgreetingtypes = this.voicemailGreeting.greetings

    this.forwardingOptions = {
      radios: [
        {
          label: this.gettextCatalog.getString('Follow Device Forwards'),
          value: false
        },
        {
          label: this.gettextCatalog.getString('Ignore Forwarded Devices'),
          value: true
        }
      ]
    }

    this.emailAddressOptions = {
      radios: [
        {
          label: this.gettextCatalog.getString("Use assigned user's email"),
          value: true
        },
        {
          label: this.gettextCatalog.getString('Use another address:'),
          value: false
        }
      ]
    }

    this.getLines = {
      api: this.portalApi.extension.pbxs(this.globalState.selectedPbxUUID)
        .lines,
      filter: line => {
        return !this.data._lineRingGroupMembers.some(
          member => member.id === line.jrn
        )
      },
      map: line => {
        let number = ''
        if (line.extensionNumber) {
          number = line.extensionNumber + ': '
        }
        let resolvedPath = this.$state.href(
          'root.nav.pbx.extensions.lines.view.general',
          { extId: this.jrn.parse(line.jrn).getResources()[1] }
        )
        return {
          id: line.jrn,
          text: number + line.name,
          path: resolvedPath
        }
      }
    }

    this.getExtensions = {
      api: this.portalApi.extension.pbxs(this.globalState.selectedPbxUUID)
        .extensions,
      filter: item => {
        if (this.data.jrn === item.jrn) {
          return false
        }
        return true
      },
      map: item => {
        let number = ''
        if (item.extensionNumber) {
          number = item.extensionNumber + ': '
        }
        return {
          id: item.jrn,
          text: number + item.name,
          path: this.$state.href('root.nav.pbx.extensions.lines.view.general', {
            extId: this.jrn.parse(item.jrn).getResources()[1]
          })
        }
      }
    }

    this.getSharedVoicemailBoxes = {
      api: this.portalApi.extension.pbxs(this.globalState.selectedPbxUUID)
        .sharedVoicemailBoxes,
      map: item => {
        let number = ''
        if (item.extensionNumber) {
          number = item.extensionNumber + ': '
        }
        return {
          id: item.jrn,
          text: number + item.name
        }
      }
    }

    this.warningWhenMemberOverLimit = 0
    this.warningConfirmPickupOverLimit = 0
    this.data.overLimit = false

    this.newLine = null
    this.newExternalNumber = ''

    this.data
      .get()
      .then(data => {
        this.voicemailgreetingtypes = this.voicemailGreeting.assignGreetingValues(
          this.voicemailgreetingtypes,
          data
        )
        this.itemName = data.extensionNumber
          ? data.extensionNumber + ': ' + data.name
          : data.name
        this.initExtensionNumber = this.data.extensionNumber
        this.ready = true
        this.initial = false
        this.data.overLimit = data.confirmPickup
          ? data._members.length > CONFIRM_PICKUP_LIMIT
          : data._members.length > GENERAL_LIMIT
        $timeout(() => {
          $rootScope.$apply()
        })
      })
      .catch(error => {
        let errorMessage = this.errorService.getErrorMessage(error)
        this.portalUtil.showErrorAlert(
          _.isEmpty(errorMessage)
            ? this.gettextCatalog.getString(
                'Data retrieval failed, please retry.'
              )
            : errorMessage
        )
        this.ready = true
        this.initial = false
        $timeout(() => {
          $rootScope.$apply()
        })
        throw error
      })

      this.canClearVoiceMail = !this.globalState.isUserManageSettingsOnly
  }

  save (form) {
    this.ready = false
    this.itemName = this.data.extensionNumber
      ? this.data.extensionNumber + ': ' + this.data.name
      : this.data.name
    this.initExtensionNumber = this.data.extensionNumber
    this.formatMembers()
    return this.data
      .update()
      .then(() => {
        form.$setPristine()
        this.ready = true
      })
      .catch(error => {
        this.portalUtil.showErrorAlert(
          this.gettextCatalog.getString('Save failed, please retry.')
        )
        this.ready = true
        throw error
      })
  }

  confirmClearVoicemails () {
    this.confirmationWindowWarn
      .show(
        this.gettextCatalog.getString(
          'Do you really want to clear all voicemails?'
        ),
        this.gettextCatalog.getString('This action will delete all voicemails, greetings, and other data associated with this mailbox and cannot be undone.'),
        this.gettextCatalog.getString('Clear')
      )
      .then(() => this.data.deleteCustomerData(), () => {})
      .catch(() => {
        this.portalUtil.showErrorAlert(
          this.gettextCatalog.getString(
            'Clear Voicemails failed, please retry.'
          )
        )
      })
  }

  formatMembers () {
    this.data._members = this.data._lineRingGroupMembers.concat(
      this.data._externalRingGroupMembers
    )
  }

  addLineMember (member) {
    let memberTotal =
      this.data._lineRingGroupMembers.length +
      this.data._externalRingGroupMembers.length
    if (
      (this.data.confirmPickup && memberTotal >= CONFIRM_PICKUP_LIMIT) ||
      memberTotal >= GENERAL_LIMIT
    ) {
      this.warnMemberOverLimit(member, this.confirmAddLineMember.bind(this))
    } else {
      this.confirmAddLineMember(member)
    }
  }

  confirmAddLineMember (member) {
    if (member) {
      member.path = this.$state.href(
        'root.nav.pbx.extensions.lines.view.general',
        { extId: this.jrn.parse(member.id).getResources()[1] }
      )
      this.data._lineRingGroupMembers.push(member)
      this.formatAndSave()
    }
  }

  removeLineMember (member) {
    let i = _.findKey(this.data._lineRingGroupMembers, member)
    this.data._lineRingGroupMembers.splice(i, 1)
    let memberTotal =
      this.data._lineRingGroupMembers.length +
      this.data._externalRingGroupMembers.length
    this.data.overLimit =
      (this.data.confirmPickup && memberTotal > CONFIRM_PICKUP_LIMIT) ||
      memberTotal > GENERAL_LIMIT
  }

  addExternalMember (member) {
    let memberTotal =
      this.data._lineRingGroupMembers.length +
      this.data._externalRingGroupMembers.length
    if (
      (this.data.confirmPickup && memberTotal >= CONFIRM_PICKUP_LIMIT) ||
      memberTotal >= GENERAL_LIMIT
    ) {
      member = this.warnMemberOverLimit(
        member,
        this.confirmAddExternalMember.bind(this)
      )
    } else {
      this.confirmAddExternalMember(member)
    }
  }

  confirmAddExternalMember (member) {
    if (member) {
      this.data._externalRingGroupMembers.push({
        id: 'external-number' + Math.random(),
        text: this.filter('tel')(member),
        e164: member
      })

      this.formatMembers()

      this.data.update().catch(error => {
        this.portalUtil.showErrorAlert(
          this.gettextCatalog.getString('Save failed, please retry.')
        )
        throw error
      })
    }
  }

  warnMemberOverLimit (member, callback) {
    if (!member) {
      callback()
      return
    }

    let warningMessage = this.gettextCatalog.getString(
      'Adding over 50 members to the ring group will result in some members being randomly excluded from calls. Are you sure you want to do this?'
    )

    if (this.data.confirmPickup) {
      warningMessage = this.gettextCatalog.getString(
        'Adding over 19 members to the ring group with confim call pickup will result in some members being randomly excluded from calls. Are you sure you want to do this?'
      )
    }

    if (this.warningWhenMemberOverLimit === 0) {
      this.warningWhenMemberOverLimit++
      this.confirmationWindowOK
        .show(this.gettextCatalog.getString('Warning'), warningMessage)
        .then(
          () => {
            this.data.overLimit = true
            callback(member)
          },
          () => {
            callback()
          }
        )
    } else {
      this.data.overLimit = true
      callback(member)
    }
  }

  warnConfirmPickup () {
    let memberTotal =
      this.data._lineRingGroupMembers.length +
      this.data._externalRingGroupMembers.length
    if (this.data.confirmPickup && memberTotal > CONFIRM_PICKUP_LIMIT) {
      let warningMessage = this.gettextCatalog.getString(
        'Adding confirm call pickup to a ring group with over 19 members will result in some members being randomly excluded from calls. Are you sure you want to do this?'
      )

      if (this.warningConfirmPickupOverLimit === 0) {
        this.warningConfirmPickupOverLimit++
        this.confirmationWindowOK
          .show(this.gettextCatalog.getString('Warning'), warningMessage)
          .then(
            () => {
              this.data.overLimit = true
            },
            () => {
              this.data.confirmPickup = false
            }
          )
      } else {
        this.data.overLimit = true
      }
    }
  }

  formatAndSave () {
    let memberTotal =
      this.data._lineRingGroupMembers.length +
      this.data._externalRingGroupMembers.length
    this.data.overLimit =
      (this.data.confirmPickup && memberTotal > CONFIRM_PICKUP_LIMIT) ||
      memberTotal > GENERAL_LIMIT

    this.formatMembers()

    this.data.update().catch(error => {
      this.portalUtil.showErrorAlert(
        this.gettextCatalog.getString('Save failed, please retry.')
      )
      throw error
    })
  }

  getSharedVoicemailBoxes (q) {
    q = q || null
    return this.portalApi.extension
      .pbxs(this._pbxId)
      .sharedVoicemailBoxes()
      .get({
        q: q
      })
      .then(data => {
        return data.items.map(item => {
          let number = ''
          if (item.extensionNumber) {
            number = item.extensionNumber + ': '
          }
          return {
            id: item.jrn,
            text: number + item.name
          }
        })
      })
  }

  openDialPlan (dialPlan) {
    this.portalUtil.openDialPlanWindow(dialPlan)
  }
}

_module.controller('RingGroupController', RingGroupController)
