import jrnParser from 'classes/jrnParser'

let _q, _state, _jrn, _userApi, _groupApi, _filterId, _members

export default class GetMembersController {
  constructor ($state, $q, Group, User) {
    'ngInject'
    _q = $q
    _state = $state
    _jrn = jrnParser

    this._model = {}
    this.allMembers = []

    _groupApi = new Group(null)
    _userApi = new User(null)

    _filterId = this.filterId
    _members = this.members

    this.$onInit = () => {
      this.setViewValue = item => {
        this.ngModel.$setViewValue(item.id)
      }
    }
  }

  api () {
    return {
      get: params => {
        params = params || null
        return _q
          .all([_userApi.getList(params), _groupApi.getList(params)])
          .then(data => {
            return {
              items: data[0].concat(data[1])
            }
          })
      }
    }
  }

  map (item) {
    return item
  }

  filter (item) {
    return (
      !(_filterId && _filterId === item.id) &&
      !_members.some(member => {
        return member.id === item.id
      })
    )
  }

  addMember (member) {
    let resolvedPath
    if (_jrn.parse(member.id).getResources()[0] === 'user-group') {
      resolvedPath = _state.href('root.nav.pbx.groups.view.general', {
        groupId: _jrn.parse(member.id).getResources()[1]
      })
    } else {
      resolvedPath = _state.href('root.nav.pbx.users.view.general', {
        userId: _jrn.parse(member.id).getResources()[1]
      })
    }
    member.path = resolvedPath
    _members.push(member)
  }
}
