/**
 * @ngdoc function
 * @name platformApp.controller:PagingRealmController
 * @description
 * # PagingRealmController
 * Controller of the platformApp
 */
import _module from 'module.js'
import _ from 'lodash'
import BaseController from 'classes/baseController'
import jrnParser from 'classes/jrnParser'

let _jrn, _state, _stateParams

export default class PagingRealmController extends BaseController {
  constructor (
    portalApi,
    $stateParams,
    PagingRealm,
    userService,
    gettextCatalog,
    $state,
    portalUtil,
    errorService,
    globalState,
    navigation,
    $rootScope,
    $timeout
  ) {
    'ngInject'
    super(
      $state,
      $stateParams,
      PagingRealm,
      portalApi,
      userService,
      gettextCatalog,
      portalUtil,
      errorService,
      globalState,
      navigation,
      'root.nav.pbx.devices.realms.list',
      'realmId'
    )

    _jrn = jrnParser
    _state = $state
    _stateParams = $stateParams

    this.data
      .get()
      .then(data => {
        this.itemName = data.name
        this.ready = true
        this.initial = false
        $timeout(() => {
          $rootScope.$apply()
        })
      })
      .catch(error => {
        let errorMessage = this.errorService.getErrorMessage(error)
        this.portalUtil.showErrorAlert(
          _.isEmpty(errorMessage)
            ? this.gettextCatalog.getString(
                'Data retrieval failed, please retry.'
              )
            : errorMessage
        )
        this.ready = true
        this.initial = false
        $timeout(() => {
          $rootScope.$apply()
        })
        throw error
      })
  }

  save (form) {
    this.ready = false
    this.data.name = this.itemName
    return this.data
      .update()
      .then(() => {
        form.$setPristine()
        this.ready = true
      })
      .catch(error => {
        this.portalUtil.showErrorAlert(
          this.gettextCatalog.getString('Save failed, please retry.')
        )
        this.ready = true
        throw error
      })
  }

  goToProfile (profileJrn) {
    var id = _jrn.parse(profileJrn).getResources()[1]
    _state.go('root.nav.pbx.devices.profiles.view.general', {
      id: this.globalState.selectedPbx.domain,
      profileId: id
    })
  }
}
_module.controller('PagingRealmController', PagingRealmController)
