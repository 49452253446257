import ListControllerBase from 'classes/listController.class'
import _module from 'module.js'

export default class deviceStatusControllerFactory extends ListControllerBase {
  constructor (
    $state,
    $stateParams,
    $location,
    $analytics,
    DeviceStatus,
    gettextCatalog,
    infiniteLoader,
    navigation,
    globalState,
    portalUtil
  ) {
    'ngInject'
    super(
      gettextCatalog,
      $analytics,
      $location,
      portalUtil,
      globalState,
      navigation,
      $state,
      $stateParams
    )

    if (!globalState.isPlatform) {
      $state.go('root.nav.pbx.dashboard', $stateParams)
      return
    }

    this.gettextCatalog = gettextCatalog
    this.title = this.gettextCatalog.getString('Device Status')
    this.headers = [
      {
        width: 20,
        name: this.gettextCatalog.getString('ID')
      },
      {
        width: 20,
        name: this.gettextCatalog.getString('Device Name'),
        sortBy: 'name',
        asc: true
      },
      {
        width: 10,
        name: this.gettextCatalog.getString('Extension'),
        sortBy: 'extension_number',
        asc: null
      },
      {
        width: 25,
        name: this.gettextCatalog.getString('Extension Name'),
        sortBy: 'extension_name',
        asc: null
      },
      {
        width: 15,
        name: this.gettextCatalog.getString('Status')
      },
      {
        width: 10,
        name: this.gettextCatalog.getString('Registrar')
      }
    ]

    this.loader = infiniteLoader(DeviceStatus)
  }
}

_module.controller('DeviceStatusController', deviceStatusControllerFactory)
