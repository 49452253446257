import jrnParser from 'classes/jrnParser'
export default class DisplayZohoUserExtensionController {
  constructor (integrationsService) {
    'ngInject'
    this._model = {}
    this.integrationsService = integrationsService
    this.$onInit = () => {
      this.setViewValue = item => {
        this.ngModel.$setViewValue(item.id)
      }
    }
  }

  getUser () {
    if (this.integrationsService.getExtensionsMapping()) {
      let extensionMapping = this.integrationsService
        .getExtensionsMapping()
        .find(extension => {
          let userId = jrnParser.parse(extension.jrn).getResources()[1]
          let extensionId = jrnParser.parse(this.extension).getResources()[1]
          if (userId && extensionId) {
            return userId === extensionId
          } else {
            return false
          }
        })
      if (extensionMapping) {
        let display =
          (extensionMapping.extensionNumber
            ? extensionMapping.extensionNumber + ': '
            : '') + extensionMapping.name
        return display
      } else {
        return ''
      }
    } else {
      return ''
    }
  }
}
