import _module from 'module.js'
import JbcStatusTooltipTemplate from './jbcStatusTooltipTemplate.html'
import JbcStatusTooltipController from './jbcStatusTooltipController.js'

_module.component('jbcStatusTooltip', {
  template: JbcStatusTooltipTemplate,
  bindings: {
    status: '<',
    subStatuses: '<'
  },
  controllerAs: 'jbcStatusTooltip',
  controller: JbcStatusTooltipController
})
