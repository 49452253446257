import _ from 'lodash'
import jrnParser from 'classes/jrnParser'
import countReferences from 'classes/countReferences'

let _globalState, _state
export default class DeletionConfirmationController {
  constructor (
    $mdDialog,
    $window,
    name,
    item,
    entityType,
    $rootScope,
    $timeout,
    urlProvider,
    globalState,
    $state,
  ) {
    'ngInject'
    this.item = item
    this.item.displayName = name
    this.loading = true
    this.entityType = entityType
    this.$mdDialog = $mdDialog
    this.$window = $window
    this.$rootScope = $rootScope
    this.$timeout = $timeout
    this.urlProvider = urlProvider
    _globalState = globalState
    _state = $state

    this.referencingClass = this.entityType.getClass()

    this.hideMessage = false

    this.refreshExcludeClasses = [
      'Network',
      'CallFilter',
      'Holiday',
      'ZohoMapping',
      'Peers',
      'Partners'
    ]

    this.refresh()
  }

  ok () {
    this.$mdDialog.hide(this.item)
  }

  cancel () {
    this.$mdDialog.cancel('cancel')
  }

  isExtension () {
    return this.urlProvider.isExtension(this.referencingClass)
  }

  isRefreshAvailable () {
    return (
      !this.refreshExcludeClasses.includes(this.referencingClass) &&
      this.entityType.getReferencesApi()
    )
  }

  reset () {
    this.references = null
    this.referencesCount = 0
    this.loading = false
  }

  async refresh () {
    if (!this.isRefreshAvailable()) {
      this.hideMessage = true
      return this.reset()
    }

    this.loading = true

    const references = await this.entityType.getReferencesApi().get()

    if (!references) {
      return this.reset()
    }

    this.references = references.plain()

    this.referencesCount = countReferences(
      this.referencingClass,
      this.references
    )

    this.loading = false
    this.$timeout(() => this.$rootScope.$apply())
  }

  openNewWindow (referencedType, resource) {
    const url = this.urlProvider.urlFor(
      referencedType,
      resource,
      this.referencingClass
    )
    this.$window.open(url, '_blank').focus()
  }


}
