import _module from 'module.js'
import isUUID from 'classes/isUUID'
import jrnParser from 'classes/jrnParser'
import itemTemplate from 'controllers/item/itemTemplate.html'
import schedulesTemplate from 'controllers/schedules/schedulesTemplate.html'
import scheduleGeneralTemplate from 'controllers/schedule/scheduleGeneral/scheduleGeneralTemplate.html'
import scheduleHolidaysTemplate from 'controllers/schedule/scheduleHolidays/scheduleHolidaysTemplate.html'
import scheduleUsageTemplate from 'controllers/schedule/scheduleUsage/scheduleUsageTemplate.html'
import zohoMappingTemplate from 'controllers/zohoMapping/zohoMappingTemplate.html'
import environments from '../configs/environments'

const basePath = environments.current === 'local' ? '/images' : '/pbx/images'

_module.config((gettext, $stateProvider) => {
  'ngInject'

  $stateProvider
    .state('root.nav.pbx.schedules', {
      url: '/schedules',
      abstract: true,
      template: '<ui-view></ui-view>'
    })
    .state('root.nav.pbx.schedules.list', {
      url: '?q&sort&direction&source',
      template: schedulesTemplate,
      controller: 'SchedulesController as ctrl',
      reloadOnSearch: false
    })
    .state('root.nav.pbx.schedules.view', {
      url: '/:scheduleId',
      template: itemTemplate,
      controller: 'ScheduleController as ctrl',
      abstract: true,
      resolve: {
        schedule: (Schedule, $stateParams, globalState, portalApi) => {
          if (globalState.selectedPbxUUID) {
            return Schedule(
              $stateParams.scheduleId,
              null,
              globalState.selectedPbxUUID
            ).get()
          }
          if (isUUID($stateParams.id)) {
            return Schedule(
              $stateParams.scheduleId,
              null,
              $stateParams.id
            ).get()
          }
          return portalApi.pbx
            .pbxs('domain')
            .one($stateParams.id)
            .get()
            .then(pbxJrn => {
              return Schedule(
                $stateParams.scheduleId,
                null,
                jrnParser.parse(pbxJrn).getAccount()
              ).get()
            })
        }
      },
      data: {
        title: gettext('Schedule'),
        itemIcon: `${basePath}/calendar-inactive.svg`,
        tabs: [
          {
            id: 'schedule-general',
            label: gettext('General'),
            state: 'root.nav.pbx.schedules.view.general'
          },
          {
            id: 'schedule-holidays',
            label: gettext('Holidays'),
            state: 'root.nav.pbx.schedules.view.holidays'
          },
          {
            id: 'schedule-usage',
            label: gettext('Usage'),
            state: 'root.nav.pbx.schedules.view.usage'
          }
        ]
      }
    })
    .state('root.nav.pbx.schedules.view.general', {
      url: '/general',
      template: scheduleGeneralTemplate
    })
    .state('root.nav.pbx.schedules.view.holidays', {
      url: '/holidays',
      template: scheduleHolidaysTemplate,
      controller: 'ScheduleHolidaysController as ctrl',
      data: {
        hideSave: true
      }
    })
    .state('root.nav.pbx.schedules.view.usage', {
      url: '/usage',
      template: scheduleUsageTemplate
    })
    .state('root.nav.pbx.zohoMapping', {
      url: '/crmmapping',
      abstract: true,
      template: '<ui-view></ui-view>'
    })
    .state('root.nav.pbx.zohoMapping.list', {
      url: '?q&source',
      template: zohoMappingTemplate,
      controller: 'ZohoMappingController as ctrl',
      reloadOnSearch: false
    })
})
