let _userAgentTypeService, _globalState

export default class GetUserAgentTypeController {
  constructor (gettextCatalog, globalState, userAgentTypeService, FEATURE_FLAGS) {
    'ngInject'
    this._model = {}

    this.placeholder = gettextCatalog.getString('Select a model...')

    _userAgentTypeService = userAgentTypeService
    _globalState = globalState
    this.api = _userAgentTypeService.getCommonApiService()
    this.useStandardPaging = true
    this.isSipTrunkenabled = globalState.selectedPbx.featureFlags[FEATURE_FLAGS.sipTrunks]

    this.$onInit = () => {
      this.setViewValue = item => {
        this.ngModel.$setViewValue(item.id)
      }
    }

    this.filter = uat => {
      if(this.isSipTrunkEnabled && uat.id === 'goto.sip_trunk'){
        return false
      }
      if (uat.metadata && uat.metadata.platformSupportMetadata && uat.metadata.supportedProducts) {
        return (uat.metadata.supportedProducts.includes('GO_TO_CONNECT')
          && (uat.metadata.hardwareCategory && uat.metadata.hardwareCategory !== 'SOFT_CLIENT')
          && uat.metadata.platformSupportMetadata.allowUsage);
      }
      return true
    }
  }
  map (userAgentType) {
    return {
      id: _userAgentTypeService.mapUserAgentTypeIdToJrn(userAgentType.id),
      text: userAgentType.fullName || userAgentType.model,
      immutableTypeId: userAgentType.metadata.platformSupportMetadata.immutableTypeId || false
    }
  }
}
