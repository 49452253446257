import _ from 'lodash'

const PASS_THRU_CALLER_ID = 'PASS_THRU_CALLER_ID'
const USE_OUTBOUND_CALLER_ID_CONFIG = 'USE_OUTBOUND_CALLER_ID_CONFIG'

const createRingStep = () => {
  return {
    confirmPickup: false,
    passThruCallerId: false,
    callerTypeId: null,
    timeoutSeconds: 20,
    ringItems: [createRingItem()]
  }
}

const createRingItem = () => {
  return {
    destinationType: 'LINE',
    line: null,
    externalNumber: ''
  }
}

export default class LineDialplanController {
  constructor (gettextCatalog) {
    'ngInject'
    this.gettextCatalog = gettextCatalog

    this.noAnswerOpts = [
      {
        displayValue: this.gettextCatalog.getString('Voicemail'),
        value: 'VOICEMAIL'
      },
      {
        displayValue: this.gettextCatalog.getString('Extension'),
        value: 'EXTENSION'
      },
      {
        displayValue: this.gettextCatalog.getString('External Number'),
        value: 'EXTERNAL_NUMBER'
      },
      {
        displayValue: this.gettextCatalog.getString('Transferer'),
        value: 'TRANSFERER'
      },
      {
        displayValue: this.gettextCatalog.getString('Hang Up'),
        value: 'HANG_UP'
      }
    ]

    this.callFailOpts = [
      {
        displayValue: gettextCatalog.getString('Unspecified'),
        value: 'NONE'
      },
      {
        displayValue: gettextCatalog.getString('External Number'),
        value: 'EXTERNAL_NUMBER'
      },
      {
        displayValue: gettextCatalog.getString('Extension'),
        value: 'EXTENSION'
      }
    ]

    this.$onInit = () => {
      this.ngModel.$render = () => {
        this.dialPlan.ringSteps.forEach(ringStep => {
          ringStep.ringItems = ringStep.ringItems.map(ringItem => {
            ringItem.selected = ringItem.line
            return ringItem
          })

          this.addRingItem(ringStep)
        })

        if (this.dialPlan.finalStep.extension) {
          this.finalStepExt = this.dialPlan.finalStep.extension
        }
        if (this.dialPlan.unavailableStep.extension) {
          this.unavailableStepExt = this.dialPlan.unavailableStep.extension
        }
      }
    }
  }

  addRingStep () {
    this.dialPlan.ringSteps.push(createRingStep())
  }

  removeRingStep (step) {
    this.dialPlan.ringSteps = _(this.dialPlan.ringSteps)
      .reject(item => {
        return step === item
      })
      .value()
  }

  ringItemSet (step) {
    this.addRingItem(step)
  }

  extensionChanged (finalStep, extension) {
    finalStep.extension = extension
  }

  passThruCallerIdChanged (step)
  {
    step.callerIdType = null;
  }

  addRingItem (step) {
    let lastItem = step.ringItems[step.ringItems.length - 1]
    if (lastItem.line || lastItem.externalNumber) {
      step.ringItems.push(createRingItem())
    }
  }

  removeRingItem (ringItem, step) {
    if (ringItem.line !== null || ringItem.externalNumber !== null) {
      step.ringItems = _(step.ringItems)
        .reject(item => {
          return item === ringItem
        })
        .value()
    }
  }
}
