import _ from 'lodash'
import ListControllerBase from 'classes/listController.class'
import _module from 'module.js'
import is from 'is'
import jrnParser from 'classes/jrnParser'

let _jrn, _Extension, _flashDetectUtil, _q, _window, _DialPlan, _globalState

// 3.5MB
const MAX_FILE_SIZE = 3670016
export default class dialPlansControllerFactory extends ListControllerBase {
  constructor (
    $state,
    $stateParams,
    $location,
    $analytics,
    DialPlan,
    gettextCatalog,
    infiniteLoader,
    $scope,
    navigation,
    globalState,
    portalUtil,
    $window,
    Extension,
    $q,
    flashDetectUtil
  ) {
    'ngInject'
    super(
      gettextCatalog,
      $analytics,
      $location,
      portalUtil,
      globalState,
      navigation,
      $state,
      $stateParams
    )

    this.gettextCatalog = gettextCatalog
    _jrn = jrnParser
    _Extension = Extension
    _flashDetectUtil = flashDetectUtil
    _q = $q
    _window = $window
    _DialPlan = DialPlan
    _globalState = globalState

    this.scope = $scope

    this.title = this.gettextCatalog.getString('Dial Plans')
    this.headers = [
      {
        width: 10,
        name: gettextCatalog.getString('Extension'),
        sortBy: 'extensionNumber',
        asc: true
      },
      {
        width: 40,
        name: gettextCatalog.getString('Name'),
        sortBy: 'name',
        asc: null
      },
      {
        width: 20,
        name: gettextCatalog.getString('Omit from Directory')
      },
      {
        width: 15,
        name: ''
      },
      {
        width: 15,
        name: ''
      }
    ]

    this.fileUploadText = this.gettextCatalog.getString('Import Dial Plan')
    this.buttonText = this.gettextCatalog.getString('Add Dial Plan')

    this.loader = infiniteLoader(_DialPlan)
  }

  delete (event, dialPlan) {
    let name =
      this.gettextCatalog.getString('dial plan') +
      ': ' +
      (dialPlan.extensionNumber
        ? dialPlan.extensionNumber + ': ' + dialPlan.name
        : dialPlan.name)
    this.loader.entityApi.startDelete(event, dialPlan, this.data, name)
  }

  importDialPlan (file) {
    if (file.size > MAX_FILE_SIZE) {
      this.portalUtil.showErrorAlert(
        this.gettextCatalog.getString(
          'File exceeds maximum allowed file size! (3.5MB)'
        )
      )
      file = null
      return
    }
    let reader = new FileReader()
    let dialPlan = this.loader.entityApi.createFromType({})

    reader.onload = ({ target }) => {
      let xml = dialPlan.dialPlanToXml(target.result)
      let name =
        xml.getElementsByTagName('dialplan')[0].getAttribute('name') ||
        this.gettextCatalog.getString('Dial Plan')

      this.duplicateOldDialPlan(
        { name },
        this.loader.entityApi
      ).then(newDialPlan => {
        this.loader.entityApi.createFromType(newDialPlan).setDialPlanXml(xml).catch(error => {
          let badDialPlan = this.data.items[0]
          this.loader.entityApi.confirmDelete(badDialPlan, this.data)
          if(error.status >= 400 && error.status < 500) {
            this.portalUtil.showErrorAlert(
              this.gettextCatalog.getString('One or more IDs in the dial plan are invalid. Please remove the invalid IDs and re-import.')
            )
          }
          if(error.status >= 500) {
            this.portalUtil.showErrorAlert(
              this.gettextCatalog.getString('Save failed, please retry.')
            )
          }
        })
      })
    }

    reader.readAsText(file)
  }

  exportDialPlan (dialPlan, entityApi) {
    let _dialPlan = entityApi.createFromType({})

    entityApi.createFromType(dialPlan).getDialPlanXml().then(dialPlanXml => {
      _dialPlan.downloadDialPlanXml(dialPlanXml, dialPlan)
    })
  }

  duplicateOldDialPlan (dialPlan, entityApi, options = {}) {
    this.data.loading = true

    if (!options.name) {
      let dialplans = this.data.items
      let iteration = 0
      let iterationRegex = /^ \((\d+)\)$/
      let nameRegex = / \((\d+)\)$/
      let nameMatch = nameRegex.exec(dialPlan.name)
      let originalName = dialPlan.name
      if (nameMatch) {
        originalName = dialPlan.name.substring(
          0,
          dialPlan.name.length - nameMatch[0].length
        )
      }
      dialplans.forEach(plan => {
        if (plan.name.startsWith(originalName)) {
          let match = iterationRegex.exec(
            plan.name.substring(originalName.length)
          )
          let number = 0
          if (match) {
            number = parseInt(match[1])
          }
          if (number > iteration) {
            iteration = number
          }
        }
      })
      options.name = originalName + ' (' + ++iteration + ')'
    }

    this.data.items.unshift({
      isNew: false
    })

    return new _Extension(null)
      .getNext()
      .then(data => {
        let dp = _.clone(dialPlan)
        dp.extensionNumber = data
        dp.name = options.name
        return dp
      })
      .then(dp => {
        this.data.loading = false
        // scope.parentForm is the only way to access it.
        this.scope.parentForm.form.$setPristine()
        return entityApi.saveNew(_.omit(dp, 'jrn'), this.data)
      })
  }

  duplicate (dialPlan, entityApi, options = {}) {
    this.duplicateOldDialPlan(dialPlan, entityApi, options)
      .then(newDialPlan => {
        let dialPlanXmlPromise = options.dialPlanXml
          ? _q.when(options.dialPlanXml)
          : entityApi.createFromType(dialPlan).getDialPlanXml()
        return _q.all([_q.when(newDialPlan), dialPlanXmlPromise])
      })
      .then(([newDialPlan, dialPlanXmlString]) => {
        let _dialPlan = entityApi.createFromType(newDialPlan)
        let dialPlanXml = _dialPlan.dialPlanToXml(dialPlanXmlString)
        _dialPlan.setDialPlanXml(dialPlanXml).catch(error => {
          let badDialPlan = this.data.items[0]
          this.loader.entityApi.confirmDelete(badDialPlan, this.data)
          if(error.status >= 400 && error.status < 500) {
            this.portalUtil.showErrorAlert(
              this.gettextCatalog.getString('One or more IDs in the dial plan are invalid. Please remove the invalid IDs before cloning.')
            )
          }
          if(error.status >= 500) {
            this.portalUtil.showErrorAlert(
              this.gettextCatalog.getString('Save failed, please retry.')
            )
          }
        })
        return newDialPlan
      })
  }

  itemSelected (item) {
    let dialPlan = new _DialPlan(_jrn.parse(item.jrn).getResources()[1], item)
    return dialPlan.createDialPlanUrl().then((url)=> _window.open(url, '_blank').focus())
  }
}

_module.controller('DialPlansController', dialPlansControllerFactory)
