import * as angular from 'angular'

angular
  .module('exceptionOverride', [])
  .factory('$exceptionHandler', function ($analytics, $log, $window) {
    'ngInject'
    return function (exception, cause) {
      $analytics.eventTrack(
        `Error: ${exception.message}-${$window.location.href}`,
        { category: 'Angular Error' }
      )
      exception.message += ' (caused by "' + cause + '")'
      $log.error.apply($log, arguments)
    }
  })
