import _module from 'module.js'
import soundClipRecorderWizardTemplate from './soundClipRecorderWizardTemplate.html'
import moment from 'moment/min/moment-with-locales'

class soundClipWizardController {
  constructor (SoundClip, $analytics, gettextCatalog, portalUtil, $mdDialog) {
    'ngInject'
    this.busy = false

    this.soundClip = {
      name: moment().format('L') + ' ' + moment().format('LT'),
      description: ''
    }

    this.SoundClip = SoundClip
    this.$analytics = $analytics
    this.gettextCatalog = gettextCatalog
    this.portalUtil = portalUtil
    this.$mdDialog = $mdDialog
  }

  uploadRecording () {
    const newSoundClip = new this.SoundClip(null, this.soundClip)

    const blob = this.audio.element.recordRTC.getBlob()
    const fileName = this.soundClip.name + '.wav'

    const file = new Blob([blob], {
      type: 'audio/wav'
    })

    return newSoundClip.save(file, fileName)
  }

  async ok () {
    this.busy = true
    try {
      const newSoundClip = await this.uploadRecording()
      this.$mdDialog.hide(newSoundClip)
    } catch (err) {
      this.portalUtil.showErrorAlert(
        this.gettextCatalog.getString('Save failed, please retry.')
      )
      throw err
    } finally {
      this.busy = false
    }
  }

  cancelDialog () {
    this.$mdDialog.hide()
  }

  static factory (SoundClip, $analytics, gettextCatalog, portalUtil, $mdDialog) {
    'ngInject'
    return new soundClipWizardController(
      SoundClip,
      $analytics,
      gettextCatalog,
      portalUtil,
      $mdDialog
    )
  }
}

class SoundClipWizard {
  constructor ($mdDialog, $analytics) {
    'ngInject'
    this.$mdDialog = $mdDialog
    this.$analytics = $analytics
  }

  open () {
    this.$analytics.eventTrack('Sound Clip Recorder: Open', {
      category: 'Sound Clip Recorder'
    })
    return this.$mdDialog.show({
      template: soundClipRecorderWizardTemplate,
      controllerAs: 'comboWizard',
      controller: soundClipWizardController.factory,
      parent: angular.element(document.body),
      clickOutsideToClose: true,
      fullscreen: true
    })
  }

  static factory ($mdDialog, $analytics) {
    'ngInject'
    return new SoundClipWizard($mdDialog, $analytics)
  }
}

_module.factory('soundClipWizard', SoundClipWizard.factory)
