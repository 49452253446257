const NETWORK_ERROR_CODE = 2 // src file used to be accessible but failed (401 is in this catagory)

class AudioPlayerController {
  constructor (audioPlayerService, $sce) {
    'ngInject'
    this.audioPlayerService = audioPlayerService
    this.$sce = $sce
    this.$onInit = () => {
      this.ngModel.$render = () => {
        if (this.url) {
          this.config = {
            preload: 'none',
            sources: [
              { src: $sce.trustAsResourceUrl(this.url), type: 'audio/mpeg' }
              // {src: $sce.trustAsResourceUrl("http://static.videogular.com/assets/audios/videogular.mp3"), type: "audio/mpeg"}
            ],
            theme: './node_modules/videogular-themes-default/videogular.min.css',
            autoPlay: angular.isDefined(this.autoPlay) ? this.autoPlay : true
          }
        }
      }
    }
  }

  onError (event) {
    let refresh = false
    if (event && event.target && event.target.error) {
      refresh = event.target.error.code === NETWORK_ERROR_CODE
    }

    if (this.refreshJrn && refresh) {
      this.url = this.audioPlayerService
        .getSongUrl(this.refreshJrn)
        .then(url => {
          this.config.sources = [
            { src: this.$sce.trustAsResourceUrl(url), type: 'audio/mpeg' }
          ]
        })
    } else if (this.refreshFunction && refresh) {
      this.refreshFunction.api
        .get({
          redirect: false,
          transcode: true,
          format: 'mp3',
          vmgtype: this.refreshFunction.param
        })
        .then(url => {
          this.config.autoPlay = true
          this.config.sources = [
            {
              src: this.$sce.trustAsResourceUrl(url.contentUrl),
              type: 'audio/mpeg'
            }
          ]
        })
    }
  }
}

export default AudioPlayerController
