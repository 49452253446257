import _module from 'module.js'
import tryNewGoToAdminFormTemplate from '../views/common/tryNewGoToAdminForm.html'
import jrnParser from 'classes/jrnParser'
import moment from 'moment-timezone'

class TryNewGoToAdminController {
  constructor (
    $analytics,
    gettextCatalog,
    portalUtil,
    pbxUtil,
    portalApi,
    portalConfig,
    $mdDialog,
    globalState
   ) {
    'ngInject'
    this.$analytics = $analytics
    this.gettextCatalog = gettextCatalog
    this.portalUtil = portalUtil
    this.pbxUtil = pbxUtil
    this.portalApi = portalApi
    this.$mdDialog = $mdDialog
    this.globalState = globalState
    this.jrn = jrnParser
    this.infoIcon = portalConfig.infoIcon
    this.starIcon = portalConfig.starEmptyIcon
    this.paintIcon = portalConfig.paintIcon
    this.spacesIcon = portalConfig.spacesIcon
  }

  getCurrentTime() {
    return moment().utc().format('LLLL Z')
  }

  cancelDialog () {
    this.$mdDialog.cancel()
  }

   upgradeToGoToAdmin() {
    var payload = this.portalApi.pbx
    .pbxs(this.globalState.selectedPbxUUID)
    .one('unified-admin')

    payload.put({isUnifiedAdmin: true})
      .then(() => {
        window.location.reload();
      })
      .catch(error => {
        this.portalUtil.showErrorAlert(
          this.gettextCatalog.getString('Upgrade to GoTo Admin failed, please retry.')
        )
        throw error
      })
  }

  redirectToGoToAdmin(){
    this.pbxUtil.redirectToGoToAdmin(this.globalState.selectedPbx)
  }

  static factory (
    $analytics,
    gettextCatalog,
    portalUtil,
    pbxUtil,
    portalApi,
    portalConfig,
    $mdDialog,
    globalState
  ) {
    'ngInject'
    return new TryNewGoToAdminController(
      $analytics,
      gettextCatalog,
      portalUtil,
      pbxUtil,
      portalApi,
      portalConfig,
      $mdDialog,
      globalState
    )
  }
}

class TryNewGoToAdminForm {
  constructor ($mdDialog, $analytics) {
    'ngInject'
    this.$mdDialog = $mdDialog
    this.$analytics = $analytics
  }

  open () {
    return this.$mdDialog.show({
      template: tryNewGoToAdminFormTemplate,
      controllerAs: 'ctrl',
      controller: TryNewGoToAdminController.factory,
      parent: angular.element(document.body),
      clickOutsideToClose: true,
      fullscreen: true
    })
  }

  static factory ($mdDialog, $analytics) {
    'ngInject'
    return new TryNewGoToAdminForm($mdDialog, $analytics)
  }
}

_module.factory('tryNewGoToAdminForm', TryNewGoToAdminForm.factory)
