export default class LineAssignmentController {
  constructor (
    portalApi,
    $stateParams,
    gettextCatalog,
    globalState,
    $rootScope
  ) {
    'ngInject'
    this.portalApi = portalApi
    this.$stateParams = $stateParams
    this.globalState = globalState
    this.gettextCatalog = gettextCatalog
    this.$rootScope = $rootScope

    this.buttons = {}
    this.isSaving = false

    if (!this.configs.userAgentType) {
      this.configs.userAgentType = {
        id: 'unknown',
        manufacturerName: 'Unknown',
        model: '',
        numberLineButtons: 0,
        sidecarCapable: false,
        maxNumberOfSidecars: 0,
        numberSidecarButtons: 0,
        numberSidecarPages: 0,
        tftpProvisioning: false,
        faxCapable: false,
        resyncSupported: false,
        customAttributeDefinitions: [],
        useButtonConfiguration: false,
        supportedButtonTypes: ['LINE'],
        supportedSidecarButtonTypes: [],
        maxAccounts: 0,
        dedicatedLineButtons: false,
        displayString: 'Unknown',
        listName: 'Unknown'
      }
    }

    this.isPolycomDevice = this.configs.userAgentType.jrn
      ? this.configs.userAgentType.jrn.match('polycom', 'gi')
      : false

    this.isPolycomAndSupportsButtonConfig = this.configs.userAgentType.jrn
      ? this.configs.userAgentType.jrn.match(
          'polycom.(ccx|edge|vvx|soundpointip\.(321|331|335|450|550|560|650|670)|soundstation\.(5000|6000|7000))',
          'gi'
        )
      : false

    this.doesSupportRemoteDirectory = this.configs.userAgentType.jrn
      ? this.configs.userAgentType.jrn.match('yealink', 'gi') ||
          this.configs.userAgentType.jrn.match(
            /(cisco\.(?!(79|binary|xml|simple)).+)/,
            'gi'
          ) ||
          this.configs.userAgentType.jrn.match('linksys', 'gi') ||
          this.configs.userAgentType.jrn.match(/aastra\.\d{2,4}i(ct)?/gi)
      : false

    this.checkSharedLine()

    this.$rootScope.$on('updatePresenceAware', (event, button) => {
      this.checkSharedLine(button)
    })
  }

  checkSharedLine (button) {
    if (
      button &&
      button.sharedAppearance &&
      !this.isNotValidButtonType(button)
    ) {
      this.sharedLineEnabled = true
      this.configs.presenceAware = true
    } else {
      for (let buttonId in this.configs.buttons) {
        if (button && !button.sharedAppearance && buttonId === button.id) {
          if (_.size(this.configs.buttons) > 1) {
            // Skip Button
          } else {
            this.sharedLineEnabled = false
          }
        } else if (
          this.configs.buttons[buttonId].sharedAppearance &&
          !this.isNotValidButtonType(this.configs.buttons[buttonId])
        ) {
          this.sharedLineEnabled = true
          this.configs.presenceAware = true
          break
        } else {
          this.sharedLineEnabled = false
        }
      }
      if (Object.keys(this.configs.buttons).length === 0) {
        this.sharedLineEnabled = false
      }
    }
  }

  isNotValidButtonType (button) {
    if (button) {
      return (
        !this.configs.useButtonConfiguration ||
        button.type === 'ON_PHONE_DEFINED' ||
        button.type === 'MCAST' ||
        button.type === 'CALL_PARK' ||
        button.type === '' ||
        button.speedDialIsNum
      )
    }
    return false
  }

  resetDirectory (resetDirectoryForm) {
    this.configs.polycomContactsAsString = 'empty'
    resetDirectoryForm.$setDirty(true)
  }

  toggleButtonConfiguration (
    buttonConfiguration,
    isUseDirectoryToConvertToButtonConfiguration
  ) {
    this.isSaving = true
    if (buttonConfiguration) {
      this.doSave().then(request => {
        this.portalApi.userAgent
          .pbxs(this.globalState.selectedPbxUUID)
          .userAgentConfigurations(this.$stateParams.deviceId)
          .one(
            'convertButtons?useDirectoryToConvert=' +
              isUseDirectoryToConvertToButtonConfiguration
          )
          .post()
          .then(updatedConfig => {
            this.configs._map(updatedConfig.plain())
            this.ngModel.$setViewValue(this.configs)
          })
          .catch(() => {
            this.configs.useButtonConfiguration = false
          })
          .finally(() => {
            this.isSaving = false
          })
      })
    } else {
      this.configs.customAttributes['_polycom.useButtonConfiguration'] = 'false'

      this.configs.buttons = _.pickBy(this.configs.buttons, button => {
        return button.type === 'LINE'
      })

      this.doSave()
        .then(() => {
          this.configs.useButtonConfiguration = false
        })
        .finally(() => {
          this.isSaving = false
        })
    }
  }

  determineIfDirectoryUseIsSet () {
    return !!(typeof this.configs.customAttributes[
      '_polycom.usePolycomDirectoryOnConversion'
    ] === 'undefined' ||
      this.configs.customAttributes[
        '_polycom.usePolycomDirectoryOnConversion'
      ] === 'true')
  }

  buttonsUpdated (buttons) {
    this.configs.buttons = buttons
    this.ngModel.$setViewValue(this.configs)
  }

  configsUpdated (configs) {
    this.ngModel.$setViewValue(configs)
  }

  updatePresenceButtonType (buttonObj, buttonType) {
    for (let buttons in buttonObj) {
      let isPresence =
        buttonObj[buttons].type === 'MONITORED_EXTENSION' ||
        buttonObj[buttons].type === 'BUDDY_WATCH'
      if (buttonType === 'BUDDY_WATCH' && isPresence) {
        buttonObj[buttons].type = 'BUDDY_WATCH'
        buttonObj[buttons].typeText = this.gettextCatalog.getString(
          'SPEED DIAL w/ PRESENCE (BUDDY_WATCH)'
        )
      } else if (buttonType === 'BLF' && isPresence) {
        buttonObj[buttons].type = 'MONITORED_EXTENSION'
        buttonObj[buttons].typeText = this.gettextCatalog.getString(
          'SPEED DIAL w/ PRESENCE (BLF)'
        )
      }
    }
  }
}
