import _module from 'module.js'
import segmentedCircleTemplate from './segmentedCircleTemplate.html'
import { SegmentedCircleController } from './segmentedCircleController'

_module.component('segmentedCircle', {
  template: segmentedCircleTemplate,
  bindings: {
    radius: '@',
    centerX: '<',
    centerY: '<',
    segments: '<'
  },
  controllerAs: 'segmentedCircleCtrl',
  controller: SegmentedCircleController
})
