import validationTemplate from './validationTemplate.html'
import _module from 'module.js'

_module.directive('validation', function () {
  return {
    template: validationTemplate,
    restrict: 'A',
    scope: {
      validation: '<',
      showValidation: '<'
    }
  }
})
