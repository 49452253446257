import _module from 'module.js'
import './audioPlayerService'
import audioPlayerTemplate from './audioPlayerTemplate.html'
import AudioPlayerController from './audioPlayerController'

_module.component('audioPlayer', {
  template: audioPlayerTemplate,
  require: {
    ngModel: 'ngModel'
  },
  bindings: {
    url: '=ngModel',
    refreshJrn: '=?',
    refreshFunction: '=?',
    autoPlay: '=?'
  },
  controllerAs: 'ctrl',
  controller: AudioPlayerController
})
