/**
 * @ngdoc function
 * @name platformApp.service:Music
 * @description
 * # Music
 * Service of the platformApp
 */
import _module from 'module.js'
import _ from 'lodash'

let _stateParams, _portalApi, _globalState

export default class Music {
  constructor (id, gettextCatalog) {
    'ngInject'
    this._id = _stateParams.groupId || id
    this._pbxId = _globalState.selectedPbxUUID

    this.gettextCatalog = gettextCatalog

    if (this._id === undefined) {
      throw new Error(this.gettextCatalog.getString('Missing id for new Music'))
    }

    this.jrn = ''
    this.name = ''
    this.deletable = true
  }

  getApi () {
    if (!this._api) {
      this._api = _portalApi.media.pbxs(this._pbxId).holdMusicFiles
    }
    return this._api
  }

  getPath () {
    if (!this._path) {
      this._path = _portalApi.media.pbxs(this._pbxId).holdMusicFiles(this._id)
    }
    return this._path
  }

  getReferencesPath () {
    if (!this._referencesPath) {
      this._referencesPath = _portalApi.media
        .pbxs(this._pbxId)
        .holdMusicFiles(this._id)
        .one('references')
    }
    return this._referencesPath
  }

  getReferencesApi () {
    return this.getReferencesPath()
  }

  // return a deep copy of this object,
  copy () {
    var clone = new Music(this.getId())
    angular.copy(this, clone)
    return clone
  }

  map (data) {
    this.jrn = data.jrn
    this.name = data.name
  }

  build (payload) {
    payload.name = this.name
    return payload
  }

  get (options) {
    options = options || {}
    this.getPath()
    return this._path.get(options).then(playlist => {
      this.map(playlist.plain())
      return this
    })
  }

  mapListItem (group) {
    return {
      id: group.jrn,
      text: group.name
    }
  }

  getId () {
    return this._id
  }

  save () {
    this.getPath()
    var payload = this.build(this._path)
    return payload.post()
  }

  update () {
    this.getPath()
    let payload = this.build(this._path)
    return payload.put()
  }

  delete () {
    this.getPath()
    let payload = this.build(this._path)
    return payload.remove()
  }

  getClass () {
    return 'Music'
  }

  static factory ($stateParams, portalApi, gettextCatalog, globalState) {
    'ngInject'
    _stateParams = $stateParams
    _portalApi = portalApi
    _globalState = globalState

    return id => {
      return new Music(id, gettextCatalog)
    }
  }
}

_module.factory('Music', Music.factory)
