import _ from 'lodash'

export default class MonitoringController {
  constructor () {
    'ngInject'
    _.bindAll(this, 'filter', 'map')
  }

  filter (user) {
    return !this.list.some(item => item.id === user.id || item.jrn === user.id)
  }

  add (user) {
    this.list.push(user)
    if (this.checkSelected && this.id === 'who') {
      this.checkSelected(user)
    }
  }

  unselectAllUsers () {
    this.list.forEach(user => {
      user.active = false
      user.selected = false
    })
  }

  map ({ id, text }) {
    return {
      text,
      id,
      jrn: id,
      name: text
    }
  }
}
