import _module from 'module.js'

_module.directive('isNotPath', function () {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, $input, attrs, ngModelCtrl) {
      ngModelCtrl.$validators.isNotPath = val => {
        if (val) {
          return val.indexOf('/') === -1
        } else {
          return true
        }
      }
    }
  }
})
