import _module from 'module.js'
import LmiAuthClient from '@getgo/auth-client'
/**
 * @ngdoc service
 * @name platformApp.api
 * @description
 * # jiveOAuthClient
 * Factory in the platformApp.
 */

const LMI_OAUTH_SETTINGS = {
  prod: {
    oauthUrl: 'https://authentication.logmeininc.com',
    callbackUrl: 'https://my.jive.com/pbx',
    clientID: '2a320c18-8778-4eff-9b62-b1b5235c0c48'
  },
  hvoice: {
    oauthUrl: 'https://authentication.logmeininc.com',
    callbackUrl: 'https://my.hvoice.net/pbx',
    clientID: '5cd45d58-e0ad-42ad-86ee-03ad913b8f42'
  },
  staging: {
    oauthUrl: 'https://authenticationstage.logmeininc.com',
    callbackUrl: 'https://my.staging.jive.com/pbx',
    clientID: 'a417e54a-da08-47f5-a911-ca5830e981a9'
  },
  feature: {
    oauthUrl: 'https://authenticationed1.col.test.expertcity.com',
    callbackUrl: `${window.location.origin}`,
    clientID: 'a0c84a0b-f653-45de-9c4b-c44073725ccc'
  },
  dev: {
    oauthUrl: 'https://authenticationed1.col.test.expertcity.com',
    callbackUrl: 'https://my.dev.jive.com/pbx',
    clientID: 'a0c84a0b-f653-45de-9c4b-c44073725ccc'
  },
  devHvoice: {
    oauthUrl: 'https://authenticationed1.col.test.expertcity.com',
    callbackUrl: 'https://my.dev.hvoice.net/pbx',
    clientID: 'a0c84a0b-f653-45de-9c4b-c44073725ccc'
  },
  local2dev: {
    oauthUrl: 'https://authenticationed1.col.test.expertcity.com',
    callbackUrl: 'http://localhost:9000/pbx',
    clientID: 'a0c84a0b-f653-45de-9c4b-c44073725ccc'
  },
  local: {  //local portal pointing to production backend
    oauthUrl: 'https://authentication.logmeininc.com',
    callbackUrl: 'http://localhost:9000/pbx',
    clientID: '2a320c18-8778-4eff-9b62-b1b5235c0c48'
  }
}


export const LMI_ADMIN_PORTAL_TOKEN = 'lmi-admin-portal-token'

const createLMIOAuthClient = ($location) => {
  const { clientID, oauthUrl, callbackUrl } = LMI_OAUTH_SETTINGS[getEnvironment($location)]

  const authClient = new LmiAuthClient({
    client_id: clientID,
    url: oauthUrl,
    redirect_url: callbackUrl
  })

  const getAuthToken = async ({ inflightRequest = '' }) => {
    let tokenFromCb = authClient.init()
    const isCallback = !!tokenFromCb
    if (isCallback) {
      tokenFromCb = {
        token: tokenFromCb.access_token,
        jiveId: tokenFromCb.principal,
        expires: tokenFromCb.expires,
        inflightRequest: tokenFromCb.state,
        type: tokenFromCb.token_type
      }
      localStorage.setItem(LMI_ADMIN_PORTAL_TOKEN, JSON.stringify(tokenFromCb))
    }

    let token = localStorage.getItem(LMI_ADMIN_PORTAL_TOKEN)
    if (!token) {
      authClient.login({ state: inflightRequest })
      return false
    }

    token = JSON.parse(token)

    // Necessary to smoothly transition from lower case 'bearer' to 'Bearer' for CCA-1665
    // Should be temporary since when users relog into the app the Bearer should be corrected.
    if(token.type && typeof token.type === "string") {
      const capitalizedToken = token.type.charAt(0).toUpperCase() + token.type.substring(1);
      token.type = capitalizedToken;
    }

    if (!isCallback) {
      delete token.inflightRequest
    }

    return token
  }

  const getAuthInfo = ({ inflightRequest }, cb) => {
    getAuthToken({ inflightRequest })
      .then((token) => token && cb(null, token))
      .catch((err) => cb(err))
  }

  const logout = (cb) => {
    clearAuthInfo()
    authClient.logout()
  }

  const clearAuthInfo = () => {
    localStorage.removeItem(LMI_ADMIN_PORTAL_TOKEN)
  }

  return {
    auth: {
      getAuthInfo,
      logout,
      clearAuthInfo
    }
  }
}

const getEnvironment = ($location) => {
  const host = $location.host().toLowerCase();
  let env = 'prod'
  if (host.includes('my.staging')) {
    env = 'staging'
  } else if(host.includes('my.dev.hvoice')) {
    env = 'devhvoice'
  } else if (host.includes('my.dev')) {
    env = 'dev'
  } else if (host.includes('my.hvoice')) {
    env = 'hvoice'
  } else if (host.includes('localhost')) {
    env = window.environment === 'dev' ? 'local2dev' : 'local'
  } else if (window.environment == 'feature') {
    env = 'feature'
  }
  return env
}

_module.factory('jiveOAuthClient', createLMIOAuthClient)
