import _module from 'module.js'
import soundRecorderTemplate from './soundRecorderTemplate.html'
import soundRecorderController from './soundRecorderController.js'

_module.component('soundRecorder', {
  template: soundRecorderTemplate,
  bindings: {
    audio: '=ngModel'
  },
  controllerAs: 'soundRecorder',
  controller: soundRecorderController
})
