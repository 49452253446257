import _module from 'module.js'
import { FEATURE_FLAGS } from '../../services/featuresService'

export default class UnifiedAdminDashboardAccessService {
  constructor (globalState, featuresService) {
    'ngInject'
    this.globalState = globalState;
    this.featuresService = featuresService
  }

  isDashboardAvailable () {
    return this.globalState.selectedPbx.featureFlags[FEATURE_FLAGS.adminItDashboard] || this.globalState.isPlatformAdmin || this.globalState.isPlatform;
  }

  fetchIsDashboardAvailable () {
    return this.featuresService
      .getContextFeature(this.globalState._selectedPbxUUID, FEATURE_FLAGS.adminItDashboard)
      .then(featureFlag => {
        const isFeatureEnabled = featureFlag.setting === 'ENABLED';
        this.globalState.selectedPbx.featureFlags[FEATURE_FLAGS.adminItDashboard] = isFeatureEnabled;
        
        return this.isDashboardAvailable();
      });
  }

  static factory (globalState, featuresService) {
    'ngInject'
    return new UnifiedAdminDashboardAccessService(globalState, featuresService)
  }
}

_module.factory(
  'unifiedAdminDashboardAccessService',
  UnifiedAdminDashboardAccessService.factory
)
