import { cloneDeep } from 'lodash'
import { getJrnDefaultComponent } from 'components/getJrn/getJrnDefaultComponent'
import getDidController from 'components/getOutboundDid/getOutboundDidController'
import _module from 'module.js'

const getOutboundDidComponent = cloneDeep(getJrnDefaultComponent)
getOutboundDidComponent.controller = getDidController
getOutboundDidComponent.bindings.restrictSite = '<?'

_module.component('getOutboundDid', getOutboundDidComponent)
