export default class GetZohoUserController {
  constructor (gettextCatalog, integrationsService) {
    'ngInject'
    this._model = {}

    this.placeholder = gettextCatalog.getString('Select a user...')

    this.api = integrationsService.getZohoUsersApiService()

    this.$onInit = () => {
      this.setViewValue = item => {
        this.ngModel.$setViewValue(item.id)
      }
    }
  }

  map (zohoUser) {
    return {
      id: zohoUser.id,
      text: zohoUser.userName
    }
  }
}
