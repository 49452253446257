import _module from 'module.js'
import isUUID from 'classes/isUUID'
import jrnParser from 'classes/jrnParser'
import itemTemplate from 'controllers/item/itemTemplate.html'
import permissionsGeneralTemplate from 'controllers/permissions/permissionsGeneral/permissionsGeneralTemplate.html'
import permissionsDialingTemplate from 'controllers/permissions/permissionsDialing/permissionsDialingTemplate.html'
import permissionsNetworkTemplate from 'controllers/permissions/permissionsNetwork/permissionsNetworkTemplate.html'
import permissionsNetworkAccessTemplate from 'controllers/permissions/permissionsNetwork/permissionsNetworkAccessTemplate.html'
import permissionsPromptsTemplate from 'controllers/permissions/permissionsPrompts/permissionsPromptsTemplate.html'
import permissionsVmFaxTemplate from 'controllers/permissions/permissionsVmFax/permissionsVmFaxTemplate.html'
import permissionsMonitoringTemplate from 'controllers/permissions/permissionsMonitoring/permissionsMonitoringTemplate.html'
import permissionsIdentityTemplate from 'controllers/permissions/permissionsIdentityListTemplate.html'

_module.config((gettext, $stateProvider) => {
  'ngInject'

  $stateProvider
    .state('root.nav.pbx.permissions', {
      url: '/permissions',
      template: itemTemplate,
      resolve: {
        PermissionsData: (
          Permissions,
          globalState,
          portalApi,
          $stateParams
        ) => {
          if (globalState.selectedPbxUUID) {
            return Permissions(globalState.selectedPbxUUID).get()
          }
          if (isUUID($stateParams.id)) {
            return Permissions($stateParams.id).get()
          }
          return portalApi.pbx
            .pbxs('domain')
            .one($stateParams.id)
            .get()
            .then(pbxJrn => {
              return Permissions(jrnParser.parse(pbxJrn).getAccount()).get()
            })
        },
        MonitoringData: (Monitoring, globalState, $stateParams, portalApi) => {
          if (globalState.selectedPbxUUID) {
            return Monitoring(globalState.selectedPbxUUID).get()
          }
          if (isUUID($stateParams.id)) {
            return Monitoring($stateParams.id).get()
          }
          return portalApi.pbx
            .pbxs('domain')
            .one($stateParams.id)
            .get()
            .then(pbxJrn => {
              return Monitoring(jrnParser.parse(pbxJrn).getAccount()).get()
            })
        }
      },
      controller: 'PermissionsController as ctrl',
      abstract: true,
      data: {
        itemIcon: 'icon-admin',
        title: gettext('Permissions'),
        tabs: [
          {
            id: 'permissions-general',
            label: gettext('General'),
            state: 'root.nav.pbx.permissions.general.template'
          },
          {
            id: 'permissions-dialing',
            label: gettext('Dialing'),
            state: 'root.nav.pbx.permissions.dialing.template'
          },
          {
            id: 'permissions-network',
            label: gettext('Network'),
            state: 'root.nav.pbx.permissions.network'
          },
          {
            id: 'permissions-prompts',
            label: gettext('Prompts'),
            state: 'root.nav.pbx.permissions.prompts'
          },
          {
            id: 'permissions-monitoring',
            label: gettext('Monitoring'),
            state: 'root.nav.pbx.permissions.monitoring'
          },
          {
            id: 'permissions-vm-fax',
            label: gettext('VM/Fax'),
            state: 'root.nav.pbx.permissions.vmfax'
          }
        ]
      }
    })
    .state('root.nav.pbx.permissions.general', {
      abstract: true,
      url: '',
      template: permissionsGeneralTemplate,
      controller: 'PermissionsGeneralController as ctrl'
    })
    .state('root.nav.pbx.permissions.general.template', {
      url: '/general',
      template: permissionsIdentityTemplate
    })
    .state('root.nav.pbx.permissions.dialing', {
      abstract: true,
      url: '',
      template: permissionsDialingTemplate,
      controller: 'PermissionsDialingController as ctrl'
    })
    .state('root.nav.pbx.permissions.dialing.template', {
      url: '/dialing',
      template: permissionsIdentityTemplate
    })
    .state('root.nav.pbx.permissions.monitoring', {
      url: '/monitoring',
      template: permissionsMonitoringTemplate
    })
    .state('root.nav.pbx.permissions.prompts', {
      url: '/prompts',
      template: permissionsPromptsTemplate,
      controller: 'PermissionsPromptsController as ctrl'
    })
    .state('root.nav.pbx.permissions.network', {
      url: '/network',
      template: permissionsNetworkTemplate
    })
    .state('root.nav.pbx.permissions.vmfax', {
      url: '/vmfax',
      template: permissionsVmFaxTemplate
    })
    .state('root.nav.pbx.networkAccess', {
      url: '/network',
      controller: 'NetworksController as ctrl',
      template: permissionsNetworkAccessTemplate
    })
})
