import _module from 'module.js'
import userSynchronizationsTemplate from 'controllers/userSynchronizations/userSynchronizationsTemplate.html'
import extensionsTemplate from 'controllers/extensions/extensionsTemplate.html'

_module.config($stateProvider => {
  'ngInject'

  $stateProvider
    .state('root.nav.pbx.usersynchronizations', {
      url: '/usersynchronizations',
      template: userSynchronizationsTemplate,
      controller: 'UserSynchronizationsController as ctrl'
    })
    .state('root.nav.pbx.extensions', {
      url: '/extensions',
      abstract: true,
      template: '<ui-view class="extensions-abstract max-height"></ui-view>'
    })
    .state('root.nav.pbx.extensions.list', {
      url: '?q&sort&direction&source',
      template: extensionsTemplate,
      controller: 'ExtensionsController as ctrl',
      reloadOnSearch: false
    })
})
