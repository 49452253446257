import _module from 'module.js'
import timeZoneSelectTemplate from './timeZoneSelectTemplate.html'
import timeZoneSelectController from './timeZoneSelectController'

_module.component('timeZoneSelect', {
  template: timeZoneSelectTemplate,
  require: {
    ngModel: 'ngModel'
  },
  bindings: {
    timeZone: '=ngModel',
    noDefault: '<'
  },
  controllerAs: 'timeZoneSelect',
  controller: timeZoneSelectController
})
