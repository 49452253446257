import _module from 'module.js'
import PermissionsBase from '../PermissionsBase'

export default class PermissionsDialingController extends PermissionsBase {
  constructor (
    PermissionsData,
    User,
    Group,
    confirmationWindowOK,
    globalState,
    gettextCatalog,
    userService,
    $state
  ) {
    'ngInject'
    super(
      PermissionsData,
      User,
      Group,
      'dialing',
      confirmationWindowOK,
      globalState,
      gettextCatalog,
      userService,
      $state
    )

    const everyoneAce = this.targets.find(ace => ace.id === null)
    if (everyoneAce) {
      this.setAsActive(everyoneAce, this.targets)
    } else {
      this.addItem(this.targets, { id: null })
    }
    this.phoenixBulkChangesPath = `/${globalState.selectedPbx.domain}/permissions/dialing`
  }

  addItem (list, item) {
    super.addItem(list, item)
    this.setAsActive(item)
  }
}

_module.controller('PermissionsDialingController', PermissionsDialingController)
