import _module from 'module.js'

export default class DeviceFeaturesService {
  constructor (Restangular, portalConfig, $q) {
    'ngInject'
    this.api = Restangular.withConfig(restangularConfig => {
      restangularConfig.setBaseUrl(portalConfig.deviceFeaturesApiUrl)
    })
    this.$q = $q
  }

  setConfigurableDeviceFeature (deviceFeature, featureType, defaultValue) {
    return this.api
      .one('configurable-device-features')
      .post(null, {
        key: deviceFeature,
        valueType: featureType,
        defaultValue: defaultValue
      })
      .then(data => data.plain())
  }

  listDeviceFeatures (
    {
      manufacturers,
      userAgentTypeIds,
      firmwareIds,
      configurable,
      query,
      page,
      pageSize
    } = {}
  ) {
    return this.api
      .one('device-features')
      .get({
        manufacturer: manufacturers,
        userAgentTypeId: userAgentTypeIds,
        firmwareId: firmwareIds,
        configurable: configurable,
        q: query,
        page: page,
        pageSize: pageSize
      })
      .then(data => data.plain())
  }

  getDeviceFeature (featureKey) {
    return this.api
      .one('device-features')
      .get(featureKey)
      .then(data => data.plain())
  }

  listConfigurableDeviceFeatures (manufacturers, userAgentTypeIds, firmwareIds) {
    return this.api
      .one('configurable-device-features')
      .get({
        manufacturer: manufacturers,
        userAgentTypeId: userAgentTypeIds,
        firmwareId: firmwareIds
      })
      .then(data => data.plain())
  }

  getConfigurableDeviceFeature (featureKey) {
    return this.api
      .one('configurable-device-features')
      .get(featureKey)
      .then(data => data.plain())
  }

  listDeviceFeatureFirmwareRestrictions (
    deviceFeatureKeys,
    userAgentTypeIds,
    firmwareIds
  ) {
    return this.api
      .one('device-feature-firmware-restrictions')
      .get({
        deviceFeatureKey: deviceFeatureKeys,
        userAgentTypeId: userAgentTypeIds,
        firmwareId: firmwareIds
      })
      .then(data => data.plain())
  }

  getDeviceFeatureFirmwareRestrictions (firmwareRestrictionId) {
    return this.api
      .one('device-feature-firmware-restrictions')
      .get(firmwareRestrictionId)
      .then(data => data.plain())
  }

  listDeviceFeatureAssociations (
    deviceFeatureKeys,
    userAgentTypeIds,
    firmwareIds
  ) {
    return this.api
      .one('device-feature-associations')
      .get({
        deviceFeatureKey: deviceFeatureKeys,
        userAgentTypeId: userAgentTypeIds,
        firmwareId: firmwareIds
      })
      .then(data => data.plain())
  }

  getDeviceFeatureAssociation (associationId) {
    return this.api
      .one('device-feature-associations')
      .get(associationId)
      .then(data => data.plain())
  }

  listDeviceFeatureConfigurations (
    deviceFeatureKeys,
    userAgentTypeIds,
    firmwareIds,
    userAgentConfigurationIds
  ) {
    return this.api
      .one('device-feature-configurations')
      .get({
        deviceFeatureKey: deviceFeatureKeys,
        userAgentTypeId: userAgentTypeIds,
        firmwareId: firmwareIds,
        userAgentConfigurationId: userAgentConfigurationIds
      })
      .then(data => data.plain())
  }

  getDeviceFeatureConfigurations (userAgentConfigurationId) {
    return this.api
      .one('device-feature-configurations')
      .get({ id: userAgentConfigurationId })
      .then(data => data.plain())
  }

  listOrganizationFeatureConfigurations (
    deviceFeatureKeys,
    userAgentTypeIds,
    firmwareIds,
    organizationIds
  ) {
    return this.api
      .one('organization-feature-configurations')
      .get({
        deviceFeatureKey: deviceFeatureKeys,
        userAgentTypeId: userAgentTypeIds,
        firmwareId: firmwareIds,
        organizationId: organizationIds
      })
      .then(data => data.plain())
  }

  setDeviceFeatureConfiguration (
    deviceFeatureKey,
    value,
    userAgentConfigurationId,
    useUserAgentDefaultValue = false
  ) {
    return this.api
      .one('device-feature-configurations')
      .post(null, {
        deviceFeatureKey: deviceFeatureKey,
        value: value,
        userAgentConfigurationId: userAgentConfigurationId,
        useUserAgentDefaultValue: useUserAgentDefaultValue
      })
      .then(data => data.plain())
  }

  setOrganizationFeatureConfiguration (
    deviceFeatureKey,
    value,
    organizationId,
    useUserAgentDefaultValue
  ) {
    return this.api
      .one('organization-feature-configurations')
      .post(null, {
        deviceFeatureKey: deviceFeatureKey,
        value: value,
        organizationId: organizationId,
        useUserAgentDefaultValue: useUserAgentDefaultValue
      })
      .then(data => data.plain())
  }

  getOrganizationFeatureConfigurations (organizationId) {
    return this.api
      .one('organization-feature-configurations')
      .get(organizationId)
      .then(data => data.plain())
  }

  getAppliedDeviceFeatures (
    userAgentConfigurationId,
    organizationId,
    userAgentTypeId
  ) {
    return this.api
      .one('applied-feature-configuration')
      .one(userAgentConfigurationId)
      .get({
        firmwareId: null,
        userAgentTypeId: userAgentTypeId,
        organizationId: organizationId,
        hotDeskingUserAgentId: null
      })
      .then(data => data.plain())
  }

  getOrganizationAppliedFeatureConfiguration (organizationId, userAgentTypeIds) {
    return this.api
      .one('applied-feature-configuration')
      .one('organization')
      .one(organizationId)
      .get({
        userAgentTypeIds: userAgentTypeIds
      })
      .then(data => data.plain())
  }
}

_module.service('deviceFeaturesService', DeviceFeaturesService)
