import _module from 'module.js'
import environments from '../configs/environments'
import { UnleashClient } from 'unleash-proxy-client';



class UnleashService {
  constructor () {
    'ngInject'
    this.unleashClient = new UnleashClient({
      url: 'https://api.jive.com/feature-flags-edge/v1/api/frontend',
      clientKey: environments.unleashClientKey,
      refreshInterval: 300,
      appName: 'Admin Jive',
      environment: environments.unleashEnvironment,
    });
    this.unleashClient.start()
  }

  isEnabled(feature) {
    return this.unleashClient.isEnabled(feature);
  }

  updateContext(properties) {
    this.unleashClient.updateContext({properties});
  }
}

_module
  .service('unleashService', UnleashService)
