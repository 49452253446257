import _module from 'module.js'
import { FEATURE_FLAGS } from '../../../services/featuresService'
import PermissionsBase from '../PermissionsBase'

export default class PermissionsGeneralController extends PermissionsBase {
  constructor (
    globalState,
    confirmationWindowOK,
    gettextCatalog,
    PermissionsData,
    User,
    Group,
    userService,
    confirmationWindowWarn,
    $state
  ) {
    'ngInject'
    super(
      PermissionsData,
      User,
      Group,
      'general',
      confirmationWindowOK,
      globalState,
      gettextCatalog,
      userService,
      $state
    )

    this.data = PermissionsData
    this.globalState = globalState
    this.confirmationWindowWarn = confirmationWindowWarn
    this.gettextCatalog = gettextCatalog
    this.selectedPbx = globalState.selectedPbx
    this.data.isCallReportRecordingsEnabled = globalState.isCallReportRecordingsEnabled
    this.featureManageSeatsEnabled = globalState.selectedPbx.featureFlags[FEATURE_FLAGS.manageSeatsPerm];
    this.featureManageUsersEnabled = globalState.selectedPbx.featureFlags[FEATURE_FLAGS.manageUsersPerm];
    this.featureManageSettingsEnabled = globalState.selectedPbx.featureFlags[FEATURE_FLAGS.manageSettingsPerm];
    this.featureManageGroupsEnabled = globalState.selectedPbx.featureFlags[FEATURE_FLAGS.manageGroupsPerm];
    this.isGroupActive = false

    const i18nLang = ['fr', 'de', 'pt', 'es'].filter((lang) => lang === globalState.currentLanguage.langCode.split('-')[0])
    const defaultLang = i18nLang.length > 0 ? `${i18nLang[0]}/` : ''
    this.rolesLink = `https://support.goto.com/${defaultLang}connect/help/what-are-roles-and-why-am-i-being-switched-from-permissions`
  }

  addItem (list, item) {
    super.addItem(list, item)
    this.setAsActive(item)
  }

  setAsActive(item, list) {
    super.setAsActive(item, list)
    this.isGroupActive = this.jrn.parse(item.id).getResources()[0] === 'user-group'
  }

  _checkedAll (_active) {
    _active.configPbx = true
    _active.manageSeats = true
    _active.manageUsers = true
    _active.manageSettings = true
    _active.manageGroups = true
    _active.reports = true
    _active.reportRecordings = true
    _active.viewInvoices = true
    _active.payInvoices = true
  }

  _adminChecked (_active) {
    if (!_active.id.includes('user-group')) {
      return this._checkedAll(_active)
    }
    const title = this.gettextCatalog.getString('Warning')
    const message = this.gettextCatalog.getString(
      'Enabling Super Administrator permissions will grant this privilege to every member of this group. Additionally, any user added to this group in the future will also have super administrator rights. Are you sure you want to do this?'
    )
    this.confirmationWindowWarn.show(title, message).then(
      () => {
        this._checkedAll(_active)
      },
      () => {
        // turn back to the original value
        _active.admin = false
      }
    )
  }

  _adminUnchecked (_active) {
    const matchingUser = this.globalState.userData.users.find(
      user => _active.id === user.jrn
    )
    if (!matchingUser) {
      return
    }
    const title = this.gettextCatalog.getString('Warning')
    const message = this.gettextCatalog.getString(
      'Disabling Super Administrator permissions will no longer allow you to re-enable them for yourself. Are you sure you want to do this?'
    )
    this.confirmationWindowWarn.show(title, message).then(
      () => {
        // do nothing
      },
      () => {
        // turn back to the original value
        _active.admin = true
      }
    )
  }

  toggleAdmin (_active) {
    if (_active.admin) {
      this._adminChecked(_active)
    } else {
      this._adminUnchecked(_active)
    }
  }

  toggleConfigPbx (_active) {
    if (_active.configPbx) {
      _active.manageUsers = true
      _active.manageSettings = true
      _active.manageGroups = true
    }
  }

  toggleUserSettings (_active) {
    if (_active.manageSettings) {
      _active.manageUsers = true
    }
  }

  viewInvoicesChanged (_active) {
    // if the user tries to disable viewInvoices and payInvoices is enabled
    // show an alert before disable it
    if (_active.payInvoices && !_active.viewInvoices) {
      const title = this.gettextCatalog.getString('Warning')
      const message = this.gettextCatalog.getString(
        'Disabling the View Invoices permission for this user will prevent them from paying invoices. Are you sure you want to do this?'
      )
      const disable = this.gettextCatalog.getString('Disable')
      this.confirmationWindowWarn.show(title, message, disable).then(
        () => {
          _active.payInvoices = false
        },
        () => {
          // turn back to the original value
          _active.viewInvoices = true
        }
      )
    }
  }

  payInvoicesChanged (_active) {
    // if the user doesn't have viewInvoices enabled, show an alert before enabled it
    if (_active.payInvoices && !_active.viewInvoices) {
      const title = this.gettextCatalog.getString('Warning')
      const message = this.gettextCatalog.getString(
        'User needs the View Invoices permission in order to pay invoices. Do you want to enable that permission?'
      )
      const enable = this.gettextCatalog.getString('Enable')
      this.confirmationWindowWarn.show(title, message, enable).then(
        () => {
          _active.viewInvoices = true
        },
        () => {
          // turn back to the original value
          _active.payInvoices = false
        }
      )
    }
  }

   manageUsersChanged (_active) {
    // if the user tries to disable manage users and manage user settings is enabled
    // show an alert before disable it
    if (_active.manageSettings && !_active.manageUsers) {
      const title = this.gettextCatalog.getString('Warning')
      const message = this.gettextCatalog.getString(
        'Disabling the Manage users permission for this user will prevent them from managing user settings. Are you sure you want to do this?'
      )
      const disable = this.gettextCatalog.getString('Disable')
      this.confirmationWindowWarn.show(title, message, disable).then(
        () => {
          _active.manageSettings = false
        },
        () => {
          // turn back to the original value
          _active.manageUsers = true
        }
      )
    }
  }

  manageUserSettingsChanged (_active) {
    // if the user doesn't have manage users enabled, show an alert before enabled it
    if (_active.manageSettings && !_active.manageUsers) {
      const title = this.gettextCatalog.getString('Warning')
      const message = this.gettextCatalog.getString(
        'User needs the Manage users permission in order to manage user settings. Do you want to enable that permission?'
      )
      const enable = this.gettextCatalog.getString('Enable')
      this.confirmationWindowWarn.show(title, message, enable).then(
        () => {
          _active.manageUsers = true
        },
        () => {
          // turn back to the original value
          _active.manageSettings = false
        }
      )
    }
  }

  reportsChanged (_active) {
    // if the user tries to disable reports and reportRecordings is enabled
    // show an alert before disable it
    if (this.data.isCallReportRecordingsEnabled && _active.reportRecordings && !_active.reports) {
      const title = this.gettextCatalog.getString('Warning')
      const message = this.gettextCatalog.getString(
        'Disabling the View Reports permission for this user will prevent them from accessing call recordings. Are you sure you want to do this?'
      )
      const disable = this.gettextCatalog.getString('Disable')
      this.confirmationWindowWarn.show(title, message, disable).then(
        () => {
          _active.reportRecordings = false
        },
        () => {
          // turn back to the original value
          _active.reports = true
        }
      )
    }
  }

  reportRecordingsChanged (_active) {
    // if the user doesn't have reports enabled, show an alert before enabled it
    if (this.data.isCallReportRecordingsEnabled && _active.reportRecordings && !_active.reports) {
      const title = this.gettextCatalog.getString('Warning')
      const message = this.gettextCatalog.getString(
        'User needs the View Reports permission in order to access call recordings. Do you want to enable that permission?'
      )
      const enable = this.gettextCatalog.getString('Enable')
      this.confirmationWindowWarn.show(title, message, enable).then(
        () => {
          _active.reports = true
        },
        () => {
          // turn back to the original value
          _active.reportRecordings = false
        }
      )
    }
  }
}

_module.controller('PermissionsGeneralController', PermissionsGeneralController)
