// https://stackoverflow.com/a/18473154/1311916
const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0
  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians)
  }
}

const describeArc = (x, y, radius, startAngle, endAngle) => {
  x = Number(x)
  y = Number(y)

  const start = polarToCartesian(x, y, radius, endAngle)
  const end = polarToCartesian(x, y, radius, startAngle)

  const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1'

  return [
    'M',
    start.x.toFixed(2),
    start.y.toFixed(2),
    'A',
    radius,
    radius,
    0,
    largeArcFlag,
    0,
    end.x.toFixed(2),
    end.y.toFixed(2)
  ].join(' ')
}

export class SegmentedCircleController {
  constructor ($log) {
    'ngInject'
    this.$log = $log
    this.radius = 0
  }

  describeArcForSegment (index) {
    if (index >= this.segments.length) {
      this.$log.warn(
        `index [${index}] is out of bounds, actual length is [${
          this.segments.length
        }]`
      )
      return describeArc(this.centerX, this.centerY, this.radius, 0, 0)
    }

    const section = this.segments[index]

    const offsetPercent = this.segments.reduce(
      (acc, value, checkIndex) =>
        acc + (checkIndex < index ? Number(value.percent) : 0),
      0
    )

    const sectionOffset = offsetPercent * 360

    const sectionLength = section.percent * 360 + sectionOffset

    if (sectionLength <= 0) {
      // Don't draw the path if there isn't anything to show
      return ''
    }

    const sectionLengthAdjusted = sectionLength - 5

    return describeArc(
      this.centerX,
      this.centerY,
      this.radius,
      sectionOffset,
      sectionLengthAdjusted,
    )
  }
}
