import getJrnDefaultTemplate from './getJrnDefaultTemplate.html'

let getJrnDefaultComponent = {
  template: getJrnDefaultTemplate,
  require: {
    ngModel: 'ngModel'
  },
  bindings: {
    jrn: '=ngModel',
    filter: '=?',
    removable: '=?',
    showLink: '<?',
    allowCreate: '<?',
    off: '=',
    queryParams: '=?',
    isJrnUser: '=?',
    isJrnUserAgentType: '=?'
  },
  controllerAs: 'ctrl',
  controller: null
}

export { getJrnDefaultComponent }
