import jrnParser from 'classes/jrnParser'

let _jrn, _state, _globalState, _portalApi, _filter
export default class GetCallbackDidController {
  constructor (gettextCatalog, $state, globalState, $filter, portalApi) {
    'ngInject'
    this._model = {}
    _state = $state
    _globalState = globalState
    _jrn = jrnParser
    _portalApi = portalApi
    _filter = $filter

    this.placeholder = gettextCatalog.getString('Select a phone number...')

    this.extraParams = [
      {
        key: 'siteUnassignedOnly',
        value: true
      },
      {
        key: 'outboundRoutable',
        value: true
      }
    ]

    this.api = _portalApi.pstn.pbxs(_globalState.selectedPbxUUID).dids

    this.$onInit = () => {
      this.setViewValue = item => {
        this.ngModel.$setViewValue(item.id)
      }
    }
  }

  map (did) {
    return {
      id: did.jrn,
      text: did.name +
        ' (' +
        (did.callerId ? did.callerId + ' ' : '') +
        '<' +
        _filter('telE164')(did.number) +
        '>' +
        ')'
    }
  }

  openLink () {
    _state.go('root.nav.pbx.dids.view.general', {
      id: _globalState.selectedPbx.domain,
      didId: _jrn.parse(this.jrn).getResources()[1]
    })
  }
}
