import _module from 'module.js'
import _ from 'lodash'
import ExtensionController from 'classes/extensionController'

export default class SharedVoicemailBoxController extends ExtensionController {
  constructor (
    $state,
    $stateParams,
    portalApi,
    SharedVoicemailBox,
    userService,
    gettextCatalog,
    portalUtil,
    errorService,
    globalState,
    navigation,
    voicemailGreeting,
    confirmationWindowWarn,
    $rootScope,
    $timeout
  ) {
    'ngInject'
    super(
      $state,
      $stateParams,
      SharedVoicemailBox,
      portalApi,
      userService,
      gettextCatalog,
      portalUtil,
      errorService,
      globalState,
      navigation,
      'root.nav.pbx.extensions.voicemailboxes.list',
      'extId'
    )

    this.$timeout = $timeout
    this.$rootScope = $rootScope

    this.isSharedVoicemail = true
    this.voicemailGreeting = voicemailGreeting
    this.confirmationWindowWarn = confirmationWindowWarn

    this.GDPREnabled = globalState._selectedPbx.featureFlags['pbx.gdpr']

    this.voicemailgreetingtypes = this.voicemailGreeting.greetings

    this.displayVoicemailTranscriptionSupportedWarning= false

    this.voiceGreetingApis = {
      tts: this.voicemailGreeting.uploadSharedVMTTSGreeting.bind(
        this.voicemailGreeting
      ),
      upload: this.voicemailGreeting.uploadSharedVMGreeting.bind(
        this.voicemailGreeting
      ),
      del: this.voicemailGreeting.getSharedVMGreetingApi().one('vmgreeting'),
      play: this.voicemailGreeting
        .getSharedVMGreetingApi()
        .one('vmgreeting-url')
    }

    this.emailAddressOptions = {
      radios: [
        {
          label: this.gettextCatalog.getString("Use assigned user's email"),
          value: true
        },
        {
          label: this.gettextCatalog.getString('Use another address:'),
          value: false
        }
      ]
    }

    this.getExtensions = {
      api: this.portalApi.extension.pbxs(this.globalState.selectedPbxUUID)
        .extensions,
      filter: item => {
        if (this.data.jrn === item.jrn) {
          return false
        }
        return true
      },
      map: item => {
        let number = ''
        if (item.extensionNumber) {
          number = item.extensionNumber + ': '
        }
        return {
          id: item.jrn,
          text: number + item.name
        }
      }
    }

    this.getSharedVoicemailBoxes = {
      api: this.portalApi.extension.pbxs(this.globalState.selectedPbxUUID)
        .sharedVoicemailBoxes,
      filter: item => {
        if (this.data.jrn === item.jrn) {
          return false
        }
        return true
      },
      map: item => {
        let number = ''
        if (item.extensionNumber) {
          number = item.extensionNumber + ': '
        }
        return {
          id: item.jrn,
          text: number + item.name
        }
      }
    }

    this.canClearVoiceMail = !this.globalState.isUserManageSettingsOnly

    this.load()
  }

  async load () {
    try {
      const data = await this.data.get()
      this.itemName = data.extensionNumber
        ? data.extensionNumber + ': ' + data.name
        : data.name
      this.voicemailgreetingtypes = this.voicemailGreeting.assignGreetingValues(
        this.voicemailgreetingtypes,
        data
      )
      this.initExtensionNumber = this.data.extensionNumber
    } catch (err) {
      const errorMessage = this.errorService.getErrorMessage(err)
      this.portalUtil.showErrorAlert(
        _.isEmpty(errorMessage)
          ? this.gettextCatalog.getString(
            'Data retrieval failed, please retry.'
          )
          : errorMessage
      )
      throw err
    } finally {
      this.ready = true
      this.initial = false
      this.$timeout(() => this.$rootScope.$apply())
    }
  }

  async save (form) {
    this.ready = false
    try {
      const result = await this.data.update()
      form.$setPristine()
      this.itemName = this.data.extensionNumber
        ? this.data.extensionNumber + ': ' + this.data.name
        : this.data.name
      this.initExtensionNumber = this.data.extensionNumber
      return result
    } catch (err) {
      this.portalUtil.showErrorAlert(
        this.gettextCatalog.getString('Save failed, please retry.')
      )
      throw err
    } finally {
      this.ready = true
    }
  }

  confirmClearVoicemails () {
    this.confirmationWindowWarn
      .show(
        this.gettextCatalog.getString(
          'Do you really want to clear all voicemails?'
        ),
        this.gettextCatalog.getString('This action will delete all voicemails, greetings, and other data associated with this mailbox and cannot be undone.'),
        this.gettextCatalog.getString('Clear')
      )
      .then(() => this.data.deleteCustomerData(), () => {})
      .catch(() => {
        this.portalUtil.showErrorAlert(
          this.gettextCatalog.getString(
            'Clear Voicemails failed, please retry.'
          )
        )
      })
  }
}

_module.controller('SharedVoicemailBoxController', SharedVoicemailBoxController)
