import _module from 'module.js'
import template from './monitoringTemplate.html'
import controller from './monitoringController'

_module.directive('monitoring', () => {
  return {
    restrict: 'AE',
    require: ['?^aclMonitoringPermissions', 'monitoring'],
    template,
    scope: {
      api: '=',
      list: '=',
      label: '@',
      id: '@'
    },
    controllerAs: 'ctrl',
    bindToController: true,
    controller,
    link: (scope, el, attrs, [aclMonitoringCtrl, ctrl]) => {
      ctrl.list.forEach(item => {
        item.id = item.jrn
        item.text = item.name
      })

      scope.removeUser = user => {
        ctrl.list = ctrl.list.filter(item => {
          return item.jrn !== user.jrn
        })
      }

      if (aclMonitoringCtrl) {
        ctrl.checkSelected = (user) => {
          if (!aclMonitoringCtrl.selectedUser && ctrl.list.length > 0) {
            ctrl.unselectAllUsers()
            let firstUser = ctrl.list[0]
            firstUser.selected = true
            firstUser.active = true
            aclMonitoringCtrl.selectUser(firstUser)
          } else if (user) {
            ctrl.unselectAllUsers()
            user.selected = true
            user.active = true
            aclMonitoringCtrl.selectUser(user)
          }
        }

        scope.selectUser = (user, $event) => {
          if ($event) {
            $event.preventDefault()
          }
          aclMonitoringCtrl.selectedUser = null
          ctrl.unselectAllUsers()
          user.selected = true
          user.active = true
          if (user.allowedToMonitor) {
            user.allowedToMonitor.forEach(item => {
              item.id = item.jrn
              item.text = item.name
            })
          }
          aclMonitoringCtrl.selectUser(user)
        }

        scope.removeUser = (user, $event) => {
          if ($event) {
            $event.preventDefault()
          }

          if (user.allowedToMonitor) {
            ctrl.list = ctrl.list.filter(item => {
              return item.jrn !== user.jrn
            })

            aclMonitoringCtrl.clearIfSelectedUser(user)
            ctrl.checkSelected()
          } else {
            aclMonitoringCtrl.removeUser(user)
          }
        }

        scope.$on('$destroy', () => {
          ctrl.unselectAllUsers()
        })

        ctrl.checkSelected()
      }
    }
  }
})
