import getLocationsTemplate from './getLocationsTemplate.html'
import getLocationsController from './getLocationsController.js'
import _module from 'module.js'

_module.component('getLocations', {
  template: getLocationsTemplate,
  require: {
    ngModel: 'ngModel'
  },
  bindings: {
    locationItem:'=ngModel',
    removable: '<?',
    onRemove: '&',
    showLink: '<?',
    off: '<',
    queryParams: '=?',
  },
  controllerAs: 'getLocationsCtrl',
  controller: getLocationsController
})
