import _ from 'lodash'

export default class BaseController {
  constructor (
    $state,
    $stateParams,
    entityFactory,
    portalApi,
    userService,
    gettextCatalog,
    portalUtil,
    errorService,
    globalState,
    navigation,
    listState,
    idParam,
    entityParams
  ) {
    'ngInject'

    this.hideWarningBanners = false
    
    this.$state = $state
    this.portalApi = portalApi
    this.$stateParams = $stateParams
    this.gettextCatalog = gettextCatalog
    this.portalUtil = portalUtil
    this.errorService = errorService
    this.globalState = globalState
    this.idParam = idParam
    this.userService = userService

    this.ready = false
    this.initial = true

    if (idParam === 'noList' || idParam === 'pbxId') {
      this.data = entityFactory()
    } else if (idParam === 'permissions') {
      // do nothing here are those data are resolved through UI-router
    } else {
      this.data = entityFactory($stateParams[idParam], entityParams)
      this.sourceList = portalUtil.getListSource(listState)
    }

    this.isPlatform = globalState.isPlatform

    this.isBrowserSupported = globalState.isBrowserSupported
    this.selectedPbx = globalState.selectedPbx
    this.isWhiteLabel = globalState.isWhiteLabel
    this.portalUtil.setStateI18N(this, this.$state.$current.parent.data)
    this.supportedRegionsWDefault = globalState.supportedRegionsWDefault
    this.supportedLanguagesWDefault = globalState.supportedLanguagesWDefault
    this.audioFormats = globalState.audioFormats

    this.navigation = navigation

    this.showHidden = false

    if (idParam !== 'didId') {
      // DID has its own criteria for showing the hidden SMS tab, other places just check if the user has platform permission
      this.userService
        .hasPlatformPermissions()
        .then(hasPermissions => {
          this.showHidden = hasPermissions
        })
    } 
  }

  next () {
    this.navigation.navigateNext(this.$state.current, this.idParam)
  }

  prev () {
    this.navigation.navigatePrev(this.$state.current, this.idParam)
  }

  hideSave () {
    return this.$state.$current.data && this.$state.$current.data.hideSave
  }
}
