import _module from 'module.js'
import usagePopupTemplate from '../views/common/usagePopupTemplate.html'
import jrnParser from 'classes/jrnParser'

_module.factory('usagePopup', function (
  $mdDialog,
  $mdMedia,
  $state,
  $window,
  DialPlan,
  extensionTypes
) {
  'ngInject'
  var useFullScreen = $mdMedia('sm') || $mdMedia('xs')
  var openDialog = function (ev, name, item, entityType) {
    return $mdDialog.show({
      template: usagePopupTemplate,
      parent: angular.element(document.body),
      targetEvent: ev,
      clickOutsideToClose: true,
      fullscreen: useFullScreen,

      controller: function ($scope, _item, _entityType) {
        'ngInject'
        $scope.item = _item
        $scope.item.displayName = name
        $scope.loading = true

        $scope.referencingClass = _entityType.getClass()

        $scope.hideMessage = false
        $scope.refresh = () => {
          if (!_entityType.getReferencesApi()) {
            $scope.references = null
            $scope.referencesCount = 0
            $scope.loading = false
            return
          }
          $scope.loading = true
          _entityType.getReferencesApi().get().then(references => {
            if (!references) {
              $scope.references = null
              $scope.referencesCount = 0
              $scope.loading = false
            } else {
              $scope.references = references.plain()

              switch ($scope.referencingClass) {
                case 'HoldMusicPlaylist':
                  $scope.referencesCount =
                    ($scope.references.callQueuesHoldMusic
                      ? $scope.references.callQueuesHoldMusic.length
                      : 0) +
                    ($scope.references.didsHoldMusic
                      ? $scope.references.didsHoldMusic.length
                      : 0) +
                    ($scope.references.extensionsRingback
                      ? $scope.references.extensionsRingback.length
                      : 0) +
                    ($scope.references.linesHoldMusic
                      ? $scope.references.linesHoldMusic.length
                      : 0) +
                    ($scope.references.pbxHoldMusic
                      ? $scope.references.pbxHoldMusic.length
                      : 0)
                  break

                case 'Music':
                  $scope.referencesCount = $scope.references.playlists
                    ? $scope.references.playlists.length
                    : 0
                  break

                case 'SoundClip':
                  $scope.referencesCount =
                    ($scope.references.dialplans
                      ? $scope.references.dialplans.length
                      : 0) +
                    ($scope.references.callQueuesUsingClipInPreCall
                      ? $scope.references.callQueuesUsingClipInPreCall.length
                      : 0) +
                    ($scope.references.callQueuesUsingClipInPerodicAnouncement
                      ? $scope.references
                          .callQueuesUsingClipInPerodicAnouncement.length
                      : 0) +
                    ($scope.references.callFilters
                      ? $scope.references.callFilters.length
                      : 0)
                  break
                case 'CorporateDirectory':
                case 'Directory':
                  $scope.referencesCount = $scope.references.pbxs
                    ? $scope.references.pbxs.length
                    : 0
                  break

                case 'Proxy':
                case 'OutboundProxy':
                  $scope.referencesCount =
                    ($scope.references.userAgentConfigurations
                      ? $scope.references.userAgentConfigurations.length
                      : 0) +
                    ($scope.references.pbxs ? $scope.references.pbxs.length : 0)
                  break
                case 'BillingGroup':
                  $scope.referencesCount =
                    ($scope.references.userAgentConfigurations
                      ? $scope.references.userAgentConfigurations.length
                      : 0) +
                    ($scope.references.dids ? $scope.references.dids.length : 0)
                  break
                default:
                  $scope.referencesCount = -1
                  break
              }
              $scope.loading = false
            }
          })
        }

        $scope.refresh(), ($scope.ok = () => {
          $mdDialog.cancel()
        })

        $scope.isExtension = () => {
          return (
            $scope.referencingClass === 'Extension' ||
            $scope.referencingClass === 'Line' ||
            $scope.referencingClass === 'RingGroup' ||
            $scope.referencingClass === 'CallQueue' ||
            $scope.referencingClass === 'ConferenceRoom' ||
            $scope.referencingClass === 'DialPlan' ||
            $scope.referencingClass === 'SharedVoicemailBox' ||
            $scope.referencingClass === 'VirtualFaxMachine'
          )
        }

        $scope.openNewWindow = (referencedType, object) => {
          var url = ''
          let id = jrnParser.parse(object.jrn).getResources()[1]
          let extType = _.find(extensionTypes.allTypes, {
            id: jrnParser.parse(object.jrn).getResources()[0]
          })
          switch (referencedType) {
            case 'pbx':
              url = $state.href('root.nav.pbx.settings.general', {})
              break
            case 'device':
              if ($scope.referencingClass === 'Hardware') {
                url = $state.href('root.nav.pbx.devices.view.hardware', {
                  deviceId: id
                })
              } else {
                url = $state.href('root.nav.pbx.devices.view.settings', {
                  deviceId: id
                })
              }
              break
            case 'did':
              url = $state.href('root.nav.pbx.dids.view.general', { didId: id })
              break
            case 'line':
              url = $state.href('root.nav.pbx.extensions.lines.view.general', {
                extId: id
              })
              break
            case 'dialPlan':
              // to do get the url for dialplan
              let dialPlan = new DialPlan(id, object)
              url = dialPlan.createDialPlanUrl()
              break
            case 'ringGroup':
              url = $state.href(
                'root.nav.pbx.extensions.ringgroups.view.general',
                { extId: id }
              )
              break
            case 'callQueue':
              if (
                $scope.referencingClass === 'Device' ||
                $scope.isExtension()
              ) {
                url = $state.href(
                  'root.nav.pbx.extensions.callqueues.view.general',
                  { extId: id }
                )
              } else {
                url = $state.href(
                  'root.nav.pbx.extensions.callqueues.view.queuebehavior',
                  { extId: id }
                )
              }
              break
            case 'extension':
              switch (extType.id) {
                case 'line':
                  url = $state.href(
                    'root.nav.pbx.extensions.lines.view.voicemail',
                    { extId: id }
                  )
                  break
                case 'ringGroup':
                  url = $state.href(
                    'root.nav.pbx.extensions.ringgroups.view.voicemail',
                    { extId: id }
                  )
                  break
                case 'dialPlan':
                  // to do get the url for dialplan
                  let dialPlan = new DialPlan(this._id, type)
                  url = $state.href(
                    'root.nav.pbx.extensions.lines.view.general',
                    { extId: id }
                  )
                  break
                case 'callQueue':
                  url = $state.href(
                    'root.nav.pbx.extensions.callqueues.view.queuebehavior',
                    { extId: id }
                  )
                  break
                default:
                  console.log('unknown type: ' + extType)
                  break
              }
              break
            case 'pagingProfile':
              url = $state.href('root.nav.pbx.devices.profiles.view.general', {
                profileId: id
              })
              break

            case 'userGroup':
              url = $state.href('root.nav.pbx.groups.item.view', {
                groupId: id
              })
              break

            case 'virtualFaxMachine':
              url = $state.href(
                'root.nav.pbx.extensions.virtualfaxmachines.view.sending',
                { extId: id }
              )
              break

            case 'callFilter':
              url = $state.href('root.nav.pbx.callfilters')
              break

            default:
              console.log('unknown type: ' + referencedType)
              break
          }

          // for whatever reason, the url returned above includes 'index.htm' at the beginning
          let starting = 'index.htm'
          let startingIndex = url.indexOf('index.htm')
          if (startingIndex != -1) {
            url = url.substring(startingIndex + starting.length + 1)
          }

          $window.open(url, '_blank').focus()
        }
      },
      resolve: {
        _item: function () {
          return item
        },
        _entityType: function () {
          return entityType
        }
      }
    })
  }

  return {
    open: openDialog
  }
})
