import _module from 'module.js'

/**
 * @ngdoc service
 * @name platformApp.flashDetectUtil
 * @description
 * # flashDetectUtil
 * Service in the platformApp.
 */

export default class FlashDetectUtil {
  constructor ($mdDialog, gettextCatalog) {
    'ngInject'
    this.installed = false
    this.raw = ''
    this.major = -1
    this.minor = -1
    this.revision = -1
    this.revisionStr = ''

    this.$mdDialog = $mdDialog
    this.gettextCatalog = gettextCatalog

    this.activeXDetectRules = [
      {
        name: 'ShockwaveFlash.ShockwaveFlash.7',
        version: obj => {
          return getActiveXVersion(obj)
        }
      },
      {
        name: 'ShockwaveFlash.ShockwaveFlash.6',
        version: obj => {
          let version = '6,0,21'
          try {
            obj.AllowScriptAccess = 'always'
            version = getActiveXVersion(obj)
          } catch (err) {}
          return version
        }
      },
      {
        name: 'ShockwaveFlash.ShockwaveFlash',
        version: obj => {
          return getActiveXVersion(obj)
        }
      }
    ]

    if (navigator.plugins && navigator.plugins.length > 0) {
      let type = 'application/x-shockwave-flash'
      let mimeTypes = navigator.mimeTypes
      if (
        mimeTypes &&
        mimeTypes[type] &&
        mimeTypes[type].enabledPlugin &&
        mimeTypes[type].enabledPlugin.description
      ) {
        let version = mimeTypes[type].enabledPlugin.description
        let versionObj = this.parseStandardVersion(version)
        this.raw = versionObj.raw
        this.major = versionObj.major
        this.minor = versionObj.minor
        this.revisionStr = versionObj.revisionStr
        this.revision = versionObj.revision
        this.installed = true
      }
    } else if (navigator.appVersion.indexOf('Mac') == -1 && window.execScript) {
      let version = -1
      for (
        let i = 0;
        i < this.activeXDetectRules.length && version == -1;
        i++
      ) {
        let obj = this.getActiveXObject(this.activeXDetectRules[i].name)
        if (!obj.activeXError) {
          this.installed = true
          version = this.activeXDetectRules[i].version(obj)
          if (version != -1) {
            let versionObj = this.parseActiveXVersion(version)
            this.raw = versionObj.raw
            this.major = versionObj.major
            this.minor = versionObj.minor
            this.revision = versionObj.revision
            this.revisionStr = versionObj.revisionStr
          }
        }
      }
    }
  }

  /**
   * Extract the ActiveX version of the plugin.
   *
   * @param {Object} The flash ActiveX object.
   * @type String
   */
  getActiveXVersion (activeXObj) {
    let version = -1
    try {
      version = activeXObj.GetVariable('$version')
    } catch (err) {}
    return version
  }
  /**
   * Try and retrieve an ActiveX object having a specified name.
   *
   * @param {String} name The ActiveX object name lookup.
   * @return One of ActiveX object or a simple object having an attribute of activeXError with a value of true.
   * @type Object
   */
  getActiveXObject (name) {
    let obj = -1
    try {
      obj = new ActiveXObject(name)
    } catch (err) {
      obj = { activeXError: true }
    }
    return obj
  }
  /**
   * Parse an ActiveX $version string into an object.
   *
   * @param {String} str The ActiveX Object GetVariable($version) return value.
   * @return An object having raw, major, minor, revision and revisionStr attributes.
   * @type Object
   */
  parseActiveXVersion (str) {
    let versionArray = str.split(',') // replace with regex
    return {
      raw: str,
      major: parseInt(versionArray[0].split(' ')[1], 10),
      minor: parseInt(versionArray[1], 10),
      revision: parseInt(versionArray[2], 10),
      revisionStr: versionArray[2]
    }
  }
  /**
   * Parse a standard enabledPlugin.description into an object.
   *
   * @param {String} str The enabledPlugin.description value.
   * @return An object having raw, major, minor, revision and revisionStr attributes.
   * @type Object
   */
  parseStandardVersion (str) {
    let descParts = str.split(/ +/)
    let majorMinor = descParts[2].split(/\./)
    let revisionStr = descParts[3]
    return {
      raw: str,
      major: parseInt(majorMinor[0], 10),
      minor: parseInt(majorMinor[1], 10),
      revisionStr: revisionStr,
      revision: this.parseRevisionStrToInt(revisionStr)
    }
  }
  /**
   * Parse the plugin revision string into an integer.
   *
   * @param {String} The revision in string format.
   * @type Number
   */
  parseRevisionStrToInt (str) {
    return parseInt(str.replace(/[a-zA-Z]/g, ''), 10) || this.revision
  }
  /**
   * Is the major version greater than or equal to a specified version.
   *
   * @param {Number} version The minimum required major version.
   * @type Boolean
   */
  majorAtLeast (version) {
    return this.major >= version
  }
  /**
   * Is the minor version greater than or equal to a specified version.
   *
   * @param {Number} version The minimum required minor version.
   * @type Boolean
   */
  minorAtLeast (version) {
    return this.minor >= version
  }
  /**
   * Is the revision version greater than or equal to a specified version.
   *
   * @param {Number} version The minimum required revision version.
   * @type Boolean
   */
  revisionAtLeast (version) {
    return this.revision >= version
  }
  /**
   * Is the version greater than or equal to a specified major, minor and revision.
   *
   * @param {Number} major The minimum required major version.
   * @param {Number} (Optional) minor The minimum required minor version.
   * @param {Number} (Optional) revision The minimum required revision version.
   * @type Boolean
   */
  versionAtLeast (major) {
    let properties = [this.major, this.minor, this.revision]
    let len = Math.min(properties.length, arguments.length)
    for (i = 0; i < len; i++) {
      if (properties[i] >= arguments[i]) {
        if (i + 1 < len && properties[i] == arguments[i]) {
          continue
        } else {
          return true
        }
      } else {
        return false
      }
    }
  }

  showFlashMissingWarning () {
    this.$mdDialog.show(
      this.$mdDialog
        .alert()
        .clickOutsideToClose(true)
        .title(
          this.gettextCatalog.getString('Adobe Flash Player is not installed.')
        )
        .htmlContent(
          this.gettextCatalog.getString(
            '<div>Please install Adobe Flash Player by visiting <a href="https://get.adobe.com/flashplayer/" target="_blank">here.</a></div>'
          )
        )
        .ariaLabel(
          this.gettextCatalog.getString('Adobe Flash Player is not installed.')
        )
        .ok(this.gettextCatalog.getString('Ok'))
    )
  }
}

_module.service('flashDetectUtil', FlashDetectUtil)
