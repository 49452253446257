/**
 * @ngdoc function
 * @name platformApp.factory:directoryService
 * @description
 * # directoryRoomService
 * Controller of the platformApp
 */
import _module from 'module.js'
import BaseES6 from 'factories/baseES6'
import jrnParser from 'classes/jrnParser'

let _stateParams, _portalApi, _portalUtil, _jrn, _globalState

export default class Directory extends BaseES6 {
  constructor (id, directory, pbxId, gettextCatalog) {
    super(id || _stateParams.directoryId, pbxId || _globalState.selectedPbxUUID)
    this.gettextCatalog = gettextCatalog

    if (id === undefined) {
      throw new Error(
        this.gettextCatalog.getString('Missing id for new Directory')
      )
    }

    this._entries = []

    if (directory) {
      this._map(directory)
    }
  }

  getApi () {
    if (!this._api) { this._api = _portalApi.extension.pbxs(this._pbxId).directories }

    return this._api
  }

  getPath () {
    if (!this._path) { this._path = _portalApi.extension.pbxs(this._pbxId).directories(this._id) }

    return this._path
  }

  getReferencesPath () {
    if (!this._referencesPath) {
      this._referencesPath = _portalApi.extension
        .pbxs(this._pbxId)
        .directories(this._id)
        .one('references')
    }
    return this._referencesPath
  }

  map (data) {
    if (data.entries) {
      this._entries = data.entries.map(ext => {
        if (ext) {
          var number = ''
          if (ext.extensionNumber) {
            number = ext.extensionNumber + ': '
          }
          let resourceType = _jrn.parse(ext.jrn).getResources()[0]
          let params = { extId: _jrn.parse(ext.jrn).getResources()[1] }
          var val = {
            id: ext.jrn,
            text: number + ext.name,
            path: _portalUtil.resolveExtensionOrDirectoryPath(ext)
          }
        }
        this._entries.push(val)
        return val
      })
    }
  }

  build (payload) {
    if (this._entries) {
      payload.entries = []
      this._entries.forEach(ext => {
        payload.entries.push(ext.id)
      })
    }

    return payload
  }

  mapListItem (dir) {
    return {
      id: dir.jrn,
      text: dir.name
    }
  }

  getClass () {
    return 'Directory'
  }

  static factory (
    $stateParams,
    portalApi,
    portalUtil,
    gettextCatalog,
    globalState
  ) {
    'ngInject'
    _stateParams = $stateParams
    _portalApi = portalApi
    _portalUtil = portalUtil
    _jrn = jrnParser
    _globalState = globalState

    return (id, directory, pbxId = null) => {
      return new Directory(id, directory, pbxId, gettextCatalog)
    }
  }
}

_module.factory('Directory', Directory.factory)
