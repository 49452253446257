/**
 * @ngdoc function
 * @name platformApp.controller:RootCtrl
 * @description
 * # RootCtrl
 * Controller of the platformApp

 * This controller's purpose is to direct the user
 */
import _module from 'module.js'
import _ from 'lodash'
import is from 'is'
import moment from 'moment'

_module.controller('RootCtrl', function (
  $state,
  userService,
  portalApi,
  pbxUtil,
  gettextCatalog,
  $q,
  $location,
  $stateParams,
  $document,
  featuresService,
  FEATURE_FLAGS,
  $localStorage,
  globalState
) {
  'ngInject'
  this.isBrowserSupported = globalState.isBrowserSupported
  this.isWhiteLabel = globalState.isWhiteLabel

  this.audioFormats = globalState.audioFormats

  this.isPlatform = false

  portalApi.i18n.regions().get().then(regions => {
    globalState.supportedRegions = regions.items.map(item => {
      item.name = gettextCatalog.getString(item.name)
      return item
    })

    globalState.supportedRegionsWDefault = [
      { code: '', name: gettextCatalog.getString('PBX Default') }
    ].concat(globalState.supportedRegions)
  })

  portalApi.i18n.languages().get().then(lanugages => {
    const allLanguages = _.map(lanugages.items, language => {
      return {
        code: language.tag.toLowerCase(),
        name: gettextCatalog.getString(language.name)
      }
    })
    globalState.supportedLanguages = _.sortBy(allLanguages, l=> l.name)
    globalState.supportedLanguagesWDefault = [
      { code: '', name: gettextCatalog.getString('PBX Default') }
    ].concat(globalState.supportedLanguages)
  })
})
