import _module from 'module.js'
import _ from 'lodash'
import BaseES6 from 'factories/baseES6'

let _globalState, _portalApi

export default class OutboundProxies extends BaseES6 {
  constructor (id, outboundProxy) {
    'ngInject'
    super(id, _globalState.selectedPbxUUID)

    if (outboundProxy) {
      this._map(outboundProxy)
    }
  }

  getApi () {
    if (!this._api) { this._api = _portalApi.userAgent.pbxs(this._pbxId).outboundProxies }

    return this._api
  }

  map (data) {
    if (data.isNew && !this.protocol) {
      this.protocol = 'UDP'
    }
  }

  getPath () {
    if (!this._path) {
      this._path = _portalApi.userAgent
        .pbxs(this._pbxId)
        .outboundProxies(this._id)
    }

    return this._path
  }

  getReferencesPath () {
    if (!this._referencesPath) {
      this._referencesPath = _portalApi.userAgent
        .pbxs(this._pbxId)
        .outboundProxies(this._id)
        .one('references')
    }
    return this._referencesPath
  }

  getClass () {
    return 'OutboundProxy'
  }

  static factory (globalState, portalApi) {
    'ngInject'
    _globalState = globalState
    _portalApi = portalApi

    return (id, proxy, pbxId = null) => {
      return new OutboundProxies(id, proxy, pbxId)
    }
  }
}

_module.factory('OutboundProxies', OutboundProxies.factory)
