/**
 * @ngdoc function
 * @name platformApp.controller: ZohoMappingController
 * @description
 * # ZohoMappingController
 * Controller of the platformApp
 */
import _module from 'module.js'
import jrnParser from 'classes/jrnParser'

import ListControllerBase from 'classes/listController.class'

export default class ZohoMappingController extends ListControllerBase {
  constructor (
    $location,
    $analytics,
    ZohoMappingFactory,
    gettextCatalog,
    infiniteLoader,
    navigation,
    $state,
    $stateParams,
    globalState,
    portalUtil,
    integrationsService,
    zohoMappingAuthService
  ) {
    'ngInject'
    super(gettextCatalog, $analytics, $location, portalUtil, globalState, navigation, $state, $stateParams)

    this.zohoMappingAuthService = zohoMappingAuthService
    this.integrationsService = integrationsService

    this.gettextCatalog = gettextCatalog

    this.title = this.gettextCatalog.getString('Zoho Mapping')
    this.headers = [
      {
        width: 40,
        name: this.gettextCatalog.getString('Line'),
        sortBy: 'extensionId',
        asc: true
      },
      {
        width: 50,
        name: this.gettextCatalog.getString('Zoho User'),
        sortBy: 'integrationUserId',
        asc: null
      },
      {
        width: 10,
        name: ''
      }
    ]
    this.buttonText = this.gettextCatalog.getString('Add Mapping')

    this.comboText = this.gettextCatalog.getString('Set me as Primary User')
    this.mappingOnTheLine = this.gettextCatalog.getString(
      'the mapping on the line'
    )
    this.loader = infiniteLoader(ZohoMappingFactory)
  }

  startComboWizard () {
    this.integrationsService.setMasterUser()
  }

  delete (event, zohoMapping) {
    let display = ''
    let deletedExtension = jrnParser
      .parse(zohoMapping.extensionJrn)
      .getResources()[1]
    let extensionMapping = this.integrationsService
      .getExtensionsMapping()
      .find(extension => {
        let userId = jrnParser.parse(extension.jrn).getResources()[1]
        if (userId && deletedExtension) {
          return userId === deletedExtension
        } else {
          return false
        }
      })

    if (extensionMapping) {
      display =
        (extensionMapping.extensionNumber
          ? extensionMapping.extensionNumber + ': '
          : '') + extensionMapping.name
    }
    this.loader.entityApi.startDelete(
      event,
      zohoMapping,
      this.data,
      ` ${this.mappingOnTheLine} ${display}`
    )
  }
}

_module.controller('ZohoMappingController', ZohoMappingController)
