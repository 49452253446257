import _module from 'module.js'
import ListControllerBase from 'classes/listController.class'
import jrnParser from 'classes/jrnParser'
import _ from 'lodash'

let _jrn, _portalUtil, _UserAgentConfigurationSecurity, _state, _timeout

// Timeout used to clear notes after updating config. Closely matches the amount of time used by
// fa-spin after switching secure calling mode.
const CONFIG_UPDATE_NOTE_TIMEOUT = 3500

export default class SecurityController extends ListControllerBase {
  constructor (
    $state,
    $stateParams,
    $location,
    $analytics,
    UserAgentConfigurationSecurity,
    gettextCatalog,
    infiniteLoader,
    navigation,
    globalState,
    portalUtil,
    $timeout,
    deviceFeaturesService,
    $q,
    FEATURE_FLAGS,
    portalApi,
    errorService
  ) {
    'ngInject'
    super(
      gettextCatalog,
      $analytics,
      $location,
      portalUtil,
      globalState,
      navigation,
      $state,
      $stateParams
    )

    _jrn = jrnParser
    this.gettextCatalog = gettextCatalog
    _portalUtil = portalUtil
    _UserAgentConfigurationSecurity = UserAgentConfigurationSecurity
    _state = $state
    _timeout = $timeout
    this.errorService = errorService
    this.portalApi = portalApi
    this.FEATURE_FLAGS = FEATURE_FLAGS
    this.$q = $q
    this.deviceFeaturesService = deviceFeaturesService

    this.featureFlags = globalState._selectedPbx.featureFlags

    this.securePasswordEnabled = this.featureFlags[
      this.FEATURE_FLAGS['securePassword']
    ]
    this.secureProvisioningEnabled =
      this.featureFlags[this.FEATURE_FLAGS['encryptProvisioning']] ||
      this.featureFlags[this.FEATURE_FLAGS['secureDirectory']]

    this.passwordText = this.gettextCatalog.getString(
      'This is the Admin Password for accessing any devices web user interface. Passwords must have a minimum length of 6 characters'
    )
    this.pbxId = _jrn.parse(this.selectedPbx.jrn)._account

    this.supportedDeviceFeatures = {}
    this.baseEditableDeviceFeatureConfigurations = {}
    this.appliedFeatureConfigurations = {}
    this.editableDeviceFeatureConfigurations = {}

    this.securePassword = ''

    this.updateAppliedFeatures()

    this.title = this.gettextCatalog.getString('Devices')

    this.headers = [
      {
        width: 10,
        name: this.gettextCatalog.getString('Secure Calling')
      },
      {
        width: 15,
        name: this.gettextCatalog.getString('Config Name'),
        sortBy: 'name',
        asc: true
      },
      {
        width: 20,
        name: this.gettextCatalog.getString('Extensions')
      },
      {
        width: 15,
        name: this.gettextCatalog.getString('Device Password')
      },
      {
        width: 10,
        name: this.gettextCatalog.getString('Device Model'),
        sortBy: 'device_model',
        asc: null
      },
      {
        width: 30,
        name: this.gettextCatalog.getString('Notes')
      }
    ]

    this.loader = infiniteLoader(UserAgentConfigurationSecurity)
    this.secureCallingDevicesDiff = 0
  }

  resetSecureProvisioningAll () {
    // Reset these values if the user clicks the reset button again after it's loaded.
    this.circleLoaderComplete = false
    this.drawCheckmark = false

    this.moveResetText = true
    this.showLoadCircle = true

    this.portalApi.userAgent
      .pbxs(this.pbxId)
      .userAgentConfigurationSecurity()
      .one('resetProvisioningAll')
      .put()
      .then(() => {
        this.circleLoaderComplete = true
        this.drawCheckmark = true
      })
      .catch(error => {
        this.moveResetText = false
        this.showLoadCircle = false
        this.drawCheckmark = false
        this.resetError = true

        let errorMessage = this.errorService.getErrorMessage(error)
        _portalUtil.showErrorAlert(
          _.isEmpty(errorMessage)
            ? this.gettextCatalog.getString(
                'Could not reset secure provisioning'
              )
            : errorMessage
        )
        this.ready = true
        throw error
      })
      .finally(() => {
        _timeout(() => (this.resetError = false), 1500)
      })
  }

  save (item) {
    const updatePromises = []

    let deviceFeatureAdminPasswordPromise = this.deviceFeaturesService.setOrganizationFeatureConfiguration(
      'provisioning.security.adminPassword',
      this.securePassword,
      this.pbxId,
      this.editableDeviceFeatureConfigurations[
        'provisioning.security.adminPassword'
      ] === null
    )
    updatePromises.push(deviceFeatureAdminPasswordPromise)

    let updatedPromise = this.$q
      .all([deviceFeatureAdminPasswordPromise])
      .catch(() => {
        let revertPromises = []
        let revertAdminPasswordPromise = deviceFeatureAdminPasswordPromise.then(
          () => {
            return this.deviceFeaturesService
              .setOrganizationFeatureConfiguration(
                'provisioning.security.adminPassword',
                this.baseEditableDeviceFeatureConfigurations[
                  'provisioning.security.adminPassword'
                ],
                this.selectedPbxUUID
              )
              .catch(revertError => {
                this.$log.error(
                  'Failure reverting changes to device admin password for ' +
                    this.pbxId,
                  revertError
                )
              })
          }
        )

        revertPromises.push(revertAdminPasswordPromise)

        this.$q
          .all(revertPromises)
          .then(error => {
            throw error
          })
          .catch(error => {
            throw error
          })
      })

    return updatedPromise
      .then(() => {
        this.updateAppliedFeatures()
        if (item) {
          item.$setPristine()
        }
        this.ready = true
      })
      .catch(error => {
        throw error
      })
  }

  saveSecureCallingRow (item) {
    let Type = this.loader.entityApi._typeFactory(item)
    item._notes = this.gettextCatalog.getString(
      'Updating device configuration. The device may restart.'
    )

    if (item._checked && item.secureCallingSupported) {
      if (item.opportunisticSrtpSupported) {
        item.secureCallingMode = 'OPTIONAL'
      } else {
        item.secureCallingMode = 'REQUIRED'
      }
    } else {
      item.secureCallingMode = null
    }

    const diffAmount = item.secureCallingMode === null ? -1 : 1

    return Type.extend(item)
      .update()
      .then(() => {
        this.secureCallingDevicesDiff += diffAmount
      })
      .catch(error => {
        _portalUtil.showErrorAlert(
          this.gettextCatalog.getString('Save failed, please retry.')
        )
        throw error
      })
      .finally(() => {
        _timeout(() => (item._notes = ''), CONFIG_UPDATE_NOTE_TIMEOUT)
      })
  }

  requestMoreData (query) {
    return super.requestMoreData(query).then(result => {
      if (result && result.items) {
        result.items.forEach(result => {
          result._checked = !!result.secureCallingMode

          if (!result.model) {
            result._notes = this.gettextCatalog.getString(
              'Device model has not been set.'
            )
          } else if (!result.secureCallingSupported) {
            result._notes = this.gettextCatalog.getString(
              'Secure calling is not supported on this device type.'
            )
          }
        })
      }
    })
  }

  updateAppliedFeatures () {
    this.deviceFeaturesService
      .getOrganizationAppliedFeatureConfiguration(this.pbxId, null)
      .then(response => {
        if (response.featureConfigurations.length > 0) {
          _.forEach(response.featureConfigurations, feature => {
            let deviceFeature = feature.deviceFeatureKey
            this.appliedFeatureConfigurations[deviceFeature] = feature
            this.editableDeviceFeatureConfigurations[deviceFeature] =
              feature.value
          })
          this.securePassword = this.editableDeviceFeatureConfigurations[
            'provisioning.security.adminPassword'
          ]
          this.baseEditableDeviceFeatureConfigurations = _.cloneDeep(
            this.editableDeviceFeatureConfigurations
          )
        }
      })
      .catch(error => {
        throw error
      })
  }
}

_module.controller('SecurityController', SecurityController)
