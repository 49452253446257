let _filterGetExtension
const applyFilterIfPresent = data => {
  return _filterGetExtension ? _filterGetExtension.filter(data) : data
}

export default class GetPagingZoneController {
  constructor (portalApi, $stateParams, globalState, gettextCatalog) {
    'ngInject'
    this.findPagingQuery = ''
    this._originalZones = []
    this.gettextCatalog = gettextCatalog

    this.CHOOSE_EXTENSION = this.gettextCatalog.getString(
      'Choose Multicast Paging Group'
    )
    this.SEARCH_EXTENSIONS = this.gettextCatalog.getString(
      'Search Multicast Paging Group'
    )

    portalApi.userAgent
      .pbxs(globalState.selectedPbxUUID)
      .userAgentConfigurations($stateParams.deviceId)
      .get({ cache: true })
      .then(uacInfo => {
        let pagingProfileId = uacInfo.pagingProfile.split('/')
        if (pagingProfileId.length == 2) {
          portalApi.userAgent
            .pbxs(globalState.selectedPbxUUID)
            .pagingProfiles(pagingProfileId[1])
            .get()
            .then(pagingProfile => {
              this._originalZones = pagingProfile.groups.filter(group => {
                return group.transmit
              })
            })
        }
      })

    this.$onInit = () => {
      _filterGetExtension = this.filterGetExtension
      if (!this.displayString) {
        if (this.extZoneReference) {
          let pagingName = this.parsePagingInfo(this.extZoneReference)
          this.ngModel.$setViewValue(pagingName)
          if (this.form && this.form.$$parentForm) {
            this.form.$$parentForm.$setPristine()
          } else if (this.form) {
            this.form.$setPristine()
          } else {
            this.ngModel.$$setPristine()
          }
        }
      }
    }
  }

  parsePagingInfo (pagingZone) {
    let label = ''
    if (pagingZone) {
      if (pagingZone.name) {
        label = pagingZone.name + '-'
      }
      label += pagingZone.address
    } else {
      label = this.gettextCatalog.getString('Not Found')
    }
    return label
  }

  findZone (text) {
    let zoneArr = []
    for (let i = 0; i < this.zones.length; i++) {
      if (
        this.zones[i].name.toLowerCase().indexOf(text.toLowerCase()) !== -1 ||
        this.zones[i].address.toLowerCase().indexOf(text.toLowerCase()) !== -1
      ) {
        zoneArr.push(this.zones[i])
      }
    }
    return zoneArr
  }

  lineFocusSet (val, $evt) {
    if ($evt) {
      $evt.stopImmediatePropagation()
    }

    this.isFocused = val
    if (val) {
      this.findPagingQuery = ''
      this.zones = applyFilterIfPresent(this._originalZones)
    }
  }

  searchForPagingZone (text) {
    this._originalZones = applyFilterIfPresent(this.findZone(text))
  }

  setPagingZone (pagingZone) {
    this.isFocused = false
    this.displayString = this.parsePagingInfo(pagingZone)
    this.ngModel.$setViewValue(pagingZone)
  }
}
