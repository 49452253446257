import _module from 'module.js'
import adminCenterUserWarningTemplate
  from '../views/common/adminCenterUserWarningTemplate.html'
import environments from "./../configs/environments";

class AdminCenterUserWarningController {
  constructor (
    $analytics,
    gettextCatalog,
    $mdDialog,
    $window,
    lmiAccountKey,
    userEmail
  ) {
    'ngInject'
    this.gettextCatalog = gettextCatalog
    this.$mdDialog = $mdDialog
    this.window = $window
    this.lmiAccountKey = lmiAccountKey
    this.userEmail = userEmail
    this.env = environments.current

  }

  goToAdminCenter () {
    var url = "https://admin.logmeininc.com"
    switch (this.env) {
      case "staging":
        url = "https://adminstage.logmeininc.com"
        break;
      case "dev":
        url = "https://admined1.lmiinc.test.expertcity.com"
        break;
      }
    if(this.lmiAccountKey) {
      url += "/portal/#accounts/" + this.lmiAccountKey + "/users?filterType=usertype"
      if(this.userEmail) {
        url += "&search=" + encodeURIComponent(this.userEmail)
      }
    }
    this.window.open(url, '_blank').focus()
  }

  complete () {
    this.$mdDialog.cancel()
  }
}

class AdminCenterUserWarningPopup {
  constructor (
    $mdDialog,
    $analytics,
    $window,
    gettextCatalog,
    globalState
  ) {
    'ngInject'
    this.$mdDialog = $mdDialog
    this.$analytics = $analytics
    this.gettextCatalog = gettextCatalog
    this.window = $window
    this.lmiAccountKey = globalState._selectedPbx.lmiAccountKey
  }

  open (userEmail) {
    return this.$mdDialog.show({
      template: adminCenterUserWarningTemplate,
      controllerAs: 'ctrl',
      controller: AdminCenterUserWarningController,
      locals: {
        $mdDialog: this.$mdDialog,
        gettextCatalog: this.gettextCatalog,
        portalUtil : this.portalUtil,
        $analytics: this.$analytics,
        $window: this.window,
        lmiAccountKey: this.lmiAccountKey,
        userEmail: userEmail
      },
      parent: angular.element(document.body),
      clickOutsideToClose: true,
      fullscreen: true
    })
  }
}

_module.service('adminCenterUserWarningPopup', AdminCenterUserWarningPopup)
