import _module from 'module.js'

const COMMON_JIVE_ERROR_ATTRS = ['errorCode', 'message', 'reference']

export default class ErrorService {
  constructor (gettextCatalog) {
    'ngInject'
    this.gettextCatalog = gettextCatalog
  }

  _logInDevEnv () {
    if (window.environment !== 'prod') {
      console.error.apply(null, arguments)
    }
  }

  _isJiveRuntimeError (error) {
    return error && error.data && !!error.data.errorCode
  }

  _getJiveRuntimeErrorMessage (error) {
    const { errorCode, message, reference } = error
    const errorData = Object.keys(error).reduce(
      (acc, attr) =>
        COMMON_JIVE_ERROR_ATTRS.includes(attr)
          ? acc
          : Object.assign(acc, { [attr]: error[attr] }),
      {}
    )
    this._logInDevEnv(errorCode, message, errorData, reference)
    const mappingCode = errorData.mappingCode || ''
    switch (mappingCode) {
      case 'EXTERNAL_USER_ID_REQUIRED':
        return this.gettextCatalog.getString('External user ID required.')
      case 'NUMBERS_GATEWAY_UNAVAILABLE':
        return this.gettextCatalog.getString(
          'Third-party numbers API unvailable.'
        )
      case 'ADDRESSE_LINE2_TOO_LONG':
        return this.gettextCatalog.getString(
          'Combined length of both addressLine2s can not be longer than {{maxLen}} characters.',
          { maxLen: errorData.addressLine2MaxLength }
        )
      case 'ADDRESSES_NOT_FOUND':
        return this.gettextCatalog.getString('Some addresses not found.')
      case 'SITE_NOT_FOUND':
        return this.gettextCatalog.getString('Site not found.')
      case 'SITE_IN_PENDING_STATE':
        return this.gettextCatalog.getString(
          'Site in pending state, {{operation}} failed.',
          { operation: errorData.operation }
        )
      case 'CONFIGURATION_ADDRESS_NOT_FOUND':
        return this.gettextCatalog.getString('Configuration address not found.')
      case 'UNKNOWN':
      default:
        return message
    }
  }

  getErrorMessage (error) {
    if (this._isJiveRuntimeError(error)) {
      return this._getJiveRuntimeErrorMessage(error.data)
    }
    const data = error.data

    if (!data) {
      this._logInDevEnv(error)
      return // let the catch set the default error message
    }
    let errorMessage = ''
    for (let prop in data) {
      if (!data.hasOwnProperty(prop)) {
        return
      }
      const reason = data[prop]

      if (reason.message === 'AWSCredential.invalid') {
        errorMessage = this.gettextCatalog.getString(
          'Invalid Amazon Web Service credentials.'
        )
      }
      if (reason.message === 'LineAssignements.mismatch.LowUsageDevice') {
        errorMessage = this.gettextCatalog.getString(
          'Low Usage Device can have only one line.'
        )
      }
    }
    return errorMessage
  }
}

_module.service('errorService', ErrorService)
