import _module from 'module.js'

export default class timeZoneSelectConstants {
   constructor (gettextCatalog) {
    'ngInject'
    this.gettextCatalog = gettextCatalog

    this.gettextCatalog.getString("Asia/Aden")
    this.gettextCatalog.getString("America/Cuiaba")
    this.gettextCatalog.getString("Etc/GMT+9")
    this.gettextCatalog.getString("Etc/GMT+8")
    this.gettextCatalog.getString("Africa/Nairobi")
    this.gettextCatalog.getString("America/Marigot")
    this.gettextCatalog.getString("Asia/Aqtau")
    this.gettextCatalog.getString("Pacific/Kwajalein")
    this.gettextCatalog.getString("America/El_Salvador")
    this.gettextCatalog.getString("Asia/Pontianak")
    this.gettextCatalog.getString("Africa/Cairo")
    this.gettextCatalog.getString("Pacific/Pago_Pago")
    this.gettextCatalog.getString("Africa/Mbabane")
    this.gettextCatalog.getString("Asia/Kuching")
    this.gettextCatalog.getString("Pacific/Honolulu")
    this.gettextCatalog.getString("Pacific/Rarotonga")
    this.gettextCatalog.getString("America/Guatemala")
    this.gettextCatalog.getString("Australia/Hobart")
    this.gettextCatalog.getString("Europe/London")
    this.gettextCatalog.getString("America/Belize")
    this.gettextCatalog.getString("America/Panama")
    this.gettextCatalog.getString("Asia/Chungking")
    this.gettextCatalog.getString("America/Managua")
    this.gettextCatalog.getString("America/Indiana/Petersburg")
    this.gettextCatalog.getString("Asia/Yerevan")
    this.gettextCatalog.getString("Europe/Brussels")
    this.gettextCatalog.getString("Europe/Warsaw")
    this.gettextCatalog.getString("America/Chicago")
    this.gettextCatalog.getString("Asia/Kashgar")
    this.gettextCatalog.getString("Chile/Continental")
    this.gettextCatalog.getString("Pacific/Yap")
    this.gettextCatalog.getString("Etc/GMT-1")
    this.gettextCatalog.getString("Etc/GMT-0")
    this.gettextCatalog.getString("Europe/Jersey")
    this.gettextCatalog.getString("America/Tegucigalpa")
    this.gettextCatalog.getString("Etc/GMT-5")
    this.gettextCatalog.getString("Europe/Istanbul")
    this.gettextCatalog.getString("America/Eirunepe")
    this.gettextCatalog.getString("Etc/GMT-4")
    this.gettextCatalog.getString("America/Miquelon")
    this.gettextCatalog.getString("Etc/GMT-3")
    this.gettextCatalog.getString("Europe/Luxembourg")
    this.gettextCatalog.getString("Etc/GMT-2")
    this.gettextCatalog.getString("Etc/GMT-9")
    this.gettextCatalog.getString("America/Argentina/Catamarca")
    this.gettextCatalog.getString("Etc/GMT-8")
    this.gettextCatalog.getString("Etc/GMT-7")
    this.gettextCatalog.getString("Etc/GMT-6")
    this.gettextCatalog.getString("Europe/Zaporozhye")
    this.gettextCatalog.getString("Canada/Yukon")
    this.gettextCatalog.getString("Canada/Atlantic")
    this.gettextCatalog.getString("Atlantic/St_Helena")
    this.gettextCatalog.getString("Australia/Tasmania")
    this.gettextCatalog.getString("Europe/Guernsey")
    this.gettextCatalog.getString("America/Grand_Turk")
    this.gettextCatalog.getString("Asia/Samarkand")
    this.gettextCatalog.getString("America/Argentina/Cordoba")
    this.gettextCatalog.getString("Asia/Phnom_Penh")
    this.gettextCatalog.getString("Africa/Kigali")
    this.gettextCatalog.getString("Asia/Almaty")
    this.gettextCatalog.getString("US/Alaska")
    this.gettextCatalog.getString("Asia/Dubai")
    this.gettextCatalog.getString("Europe/Isle_of_Man")
    this.gettextCatalog.getString("America/Araguaina")
    this.gettextCatalog.getString("Asia/Novosibirsk")
    this.gettextCatalog.getString("America/Argentina/Salta")
    this.gettextCatalog.getString("Etc/GMT+3")
    this.gettextCatalog.getString("Africa/Tunis")
    this.gettextCatalog.getString("Etc/GMT+2")
    this.gettextCatalog.getString("Etc/GMT+1")
    this.gettextCatalog.getString("Pacific/Fakaofo")
    this.gettextCatalog.getString("Africa/Tripoli")
    this.gettextCatalog.getString("Etc/GMT+0")
    this.gettextCatalog.getString("Africa/Banjul")
    this.gettextCatalog.getString("Etc/GMT+7")
    this.gettextCatalog.getString("Indian/Comoro")
    this.gettextCatalog.getString("Etc/GMT+6")
    this.gettextCatalog.getString("Etc/GMT+5")
    this.gettextCatalog.getString("Etc/GMT+4")
    this.gettextCatalog.getString("Pacific/Port_Moresby")
    this.gettextCatalog.getString("US/Arizona")
    this.gettextCatalog.getString("Antarctica/Syowa")
    this.gettextCatalog.getString("Indian/Reunion")
    this.gettextCatalog.getString("Pacific/Palau")
    this.gettextCatalog.getString("Europe/Kaliningrad")
    this.gettextCatalog.getString("America/Montevideo")
    this.gettextCatalog.getString("Africa/Windhoek")
    this.gettextCatalog.getString("Asia/Karachi")
    this.gettextCatalog.getString("Africa/Mogadishu")
    this.gettextCatalog.getString("Australia/Perth")
    this.gettextCatalog.getString("Brazil/East")
    this.gettextCatalog.getString("Etc/GMT")
    this.gettextCatalog.getString("Asia/Chita")
    this.gettextCatalog.getString("Pacific/Easter")
    this.gettextCatalog.getString("Antarctica/Davis")
    this.gettextCatalog.getString("Antarctica/McMurdo")
    this.gettextCatalog.getString("Asia/Macao")
    this.gettextCatalog.getString("America/Manaus")
    this.gettextCatalog.getString("Africa/Freetown")
    this.gettextCatalog.getString("Europe/Bucharest")
    this.gettextCatalog.getString("Asia/Tomsk")
    this.gettextCatalog.getString("America/Argentina/Mendoza")
    this.gettextCatalog.getString("Asia/Macau")
    this.gettextCatalog.getString("Europe/Malta")
    this.gettextCatalog.getString("Mexico/BajaSur")
    this.gettextCatalog.getString("Pacific/Tahiti")
    this.gettextCatalog.getString("Europe/Busingen")
    this.gettextCatalog.getString("America/Argentina/Rio_Gallegos")
    this.gettextCatalog.getString("Africa/Malabo")
    this.gettextCatalog.getString("Europe/Skopje")
    this.gettextCatalog.getString("America/Catamarca")
    this.gettextCatalog.getString("America/Godthab")
    this.gettextCatalog.getString("Europe/Sarajevo")
    this.gettextCatalog.getString("Australia/ACT")
    this.gettextCatalog.getString("Africa/Lagos")
    this.gettextCatalog.getString("America/Cordoba")
    this.gettextCatalog.getString("Europe/Rome")
    this.gettextCatalog.getString("Asia/Dacca")
    this.gettextCatalog.getString("Indian/Mauritius")
    this.gettextCatalog.getString("Pacific/Samoa")
    this.gettextCatalog.getString("America/Regina")
    this.gettextCatalog.getString("America/Fort_Wayne")
    this.gettextCatalog.getString("America/Dawson_Creek")
    this.gettextCatalog.getString("Africa/Algiers")
    this.gettextCatalog.getString("Europe/Mariehamn")
    this.gettextCatalog.getString("America/St_Johns")
    this.gettextCatalog.getString("America/St_Thomas")
    this.gettextCatalog.getString("Europe/Zurich")
    this.gettextCatalog.getString("America/Anguilla")
    this.gettextCatalog.getString("Asia/Dili")
    this.gettextCatalog.getString("America/Denver")
    this.gettextCatalog.getString("Africa/Bamako")
    this.gettextCatalog.getString("Europe/Saratov")
    this.gettextCatalog.getString("Mexico/General")
    this.gettextCatalog.getString("Pacific/Wallis")
    this.gettextCatalog.getString("Europe/Gibraltar")
    this.gettextCatalog.getString("Africa/Conakry")
    this.gettextCatalog.getString("Africa/Lubumbashi")
    this.gettextCatalog.getString("Asia/Istanbul")
    this.gettextCatalog.getString("America/Havana")
    this.gettextCatalog.getString("Asia/Choibalsan")
    this.gettextCatalog.getString("America/Porto_Acre")
    this.gettextCatalog.getString("Asia/Omsk")
    this.gettextCatalog.getString("Europe/Vaduz")
    this.gettextCatalog.getString("US/Michigan")
    this.gettextCatalog.getString("Asia/Dhaka")
    this.gettextCatalog.getString("America/Barbados")
    this.gettextCatalog.getString("Europe/Tiraspol")
    this.gettextCatalog.getString("Atlantic/Cape_Verde")
    this.gettextCatalog.getString("Asia/Yekaterinburg")
    this.gettextCatalog.getString("America/Louisville")
    this.gettextCatalog.getString("Pacific/Johnston")
    this.gettextCatalog.getString("Pacific/Chatham")
    this.gettextCatalog.getString("Europe/Ljubljana")
    this.gettextCatalog.getString("America/Sao_Paulo")
    this.gettextCatalog.getString("Asia/Jayapura")
    this.gettextCatalog.getString("America/Curacao")
    this.gettextCatalog.getString("Asia/Dushanbe")
    this.gettextCatalog.getString("America/Guyana")
    this.gettextCatalog.getString("America/Guayaquil")
    this.gettextCatalog.getString("America/Martinique")
    this.gettextCatalog.getString("Europe/Berlin")
    this.gettextCatalog.getString("Europe/Moscow")
    this.gettextCatalog.getString("Europe/Chisinau")
    this.gettextCatalog.getString("America/Puerto_Rico")
    this.gettextCatalog.getString("America/Rankin_Inlet")
    this.gettextCatalog.getString("Pacific/Ponape")
    this.gettextCatalog.getString("Europe/Stockholm")
    this.gettextCatalog.getString("Europe/Budapest")
    this.gettextCatalog.getString("America/Argentina/Jujuy")
    this.gettextCatalog.getString("Australia/Eucla")
    this.gettextCatalog.getString("Asia/Shanghai")
    this.gettextCatalog.getString("Europe/Zagreb")
    this.gettextCatalog.getString("America/Port_of_Spain")
    this.gettextCatalog.getString("Europe/Helsinki")
    this.gettextCatalog.getString("Asia/Beirut")
    this.gettextCatalog.getString("Asia/Tel_Aviv")
    this.gettextCatalog.getString("Pacific/Bougainville")
    this.gettextCatalog.getString("US/Central")
    this.gettextCatalog.getString("Africa/Sao_Tome")
    this.gettextCatalog.getString("Indian/Chagos")
    this.gettextCatalog.getString("America/Cayenne")
    this.gettextCatalog.getString("Asia/Yakutsk")
    this.gettextCatalog.getString("Pacific/Galapagos")
    this.gettextCatalog.getString("Australia/North")
    this.gettextCatalog.getString("Europe/Paris")
    this.gettextCatalog.getString("Africa/Ndjamena")
    this.gettextCatalog.getString("Pacific/Fiji")
    this.gettextCatalog.getString("America/Rainy_River")
    this.gettextCatalog.getString("Indian/Maldives")
    this.gettextCatalog.getString("Australia/Yancowinna")
    this.gettextCatalog.getString("SystemV/AST4")
    this.gettextCatalog.getString("Asia/Oral")
    this.gettextCatalog.getString("America/Yellowknife")
    this.gettextCatalog.getString("Pacific/Enderbury")
    this.gettextCatalog.getString("America/Juneau")
    this.gettextCatalog.getString("Australia/Victoria")
    this.gettextCatalog.getString("America/Indiana/Vevay")
    this.gettextCatalog.getString("Asia/Tashkent")
    this.gettextCatalog.getString("Asia/Jakarta")
    this.gettextCatalog.getString("Africa/Ceuta")
    this.gettextCatalog.getString("Asia/Barnaul")
    this.gettextCatalog.getString("America/Recife")
    this.gettextCatalog.getString("America/Buenos_Aires")
    this.gettextCatalog.getString("America/Noronha")
    this.gettextCatalog.getString("America/Swift_Current")
    this.gettextCatalog.getString("Australia/Adelaide")
    this.gettextCatalog.getString("America/Metlakatla")
    this.gettextCatalog.getString("Africa/Djibouti")
    this.gettextCatalog.getString("America/Paramaribo")
    this.gettextCatalog.getString("Europe/Simferopol")
    this.gettextCatalog.getString("Europe/Sofia")
    this.gettextCatalog.getString("Africa/Nouakchott")
    this.gettextCatalog.getString("Europe/Prague")
    this.gettextCatalog.getString("America/Indiana/Vincennes")
    this.gettextCatalog.getString("Antarctica/Mawson")
    this.gettextCatalog.getString("America/Kralendijk")
    this.gettextCatalog.getString("Europe/Samara")
    this.gettextCatalog.getString("Indian/Christmas")
    this.gettextCatalog.getString("America/Antigua")
    this.gettextCatalog.getString("Pacific/Gambier")
    this.gettextCatalog.getString("America/Indianapolis")
    this.gettextCatalog.getString("America/Inuvik")
    this.gettextCatalog.getString("America/Iqaluit")
    this.gettextCatalog.getString("Pacific/Funafuti")
    this.gettextCatalog.getString("Antarctica/Macquarie")
    this.gettextCatalog.getString("Canada/Pacific")
    this.gettextCatalog.getString("America/Moncton")
    this.gettextCatalog.getString("Africa/Gaborone")
    this.gettextCatalog.getString("Pacific/Chuuk")
    this.gettextCatalog.getString("Asia/Pyongyang")
    this.gettextCatalog.getString("America/St_Vincent")
    this.gettextCatalog.getString("Asia/Gaza")
    this.gettextCatalog.getString("Etc/Universal")
    this.gettextCatalog.getString("Atlantic/Faeroe")
    this.gettextCatalog.getString("Asia/Qyzylorda")
    this.gettextCatalog.getString("Canada/Newfoundland")
    this.gettextCatalog.getString("America/Kentucky/Louisville")
    this.gettextCatalog.getString("America/Yakutat")
    this.gettextCatalog.getString("Asia/Ho_Chi_Minh")
    this.gettextCatalog.getString("Antarctica/Casey")
    this.gettextCatalog.getString("Europe/Copenhagen")
    this.gettextCatalog.getString("Africa/Asmara")
    this.gettextCatalog.getString("Atlantic/Azores")
    this.gettextCatalog.getString("Europe/Vienna")
    this.gettextCatalog.getString("Pacific/Pitcairn")
    this.gettextCatalog.getString("America/Mazatlan")
    this.gettextCatalog.getString("Australia/Queensland")
    this.gettextCatalog.getString("Pacific/Nauru")
    this.gettextCatalog.getString("Europe/Tirane")
    this.gettextCatalog.getString("Asia/Kolkata")
    this.gettextCatalog.getString("SystemV/MST7")
    this.gettextCatalog.getString("Australia/Canberra")
    this.gettextCatalog.getString("Australia/Broken_Hill")
    this.gettextCatalog.getString("Europe/Riga")
    this.gettextCatalog.getString("America/Dominica")
    this.gettextCatalog.getString("Africa/Abidjan")
    this.gettextCatalog.getString("America/Mendoza")
    this.gettextCatalog.getString("America/Santarem")
    this.gettextCatalog.getString("Kwajalein")
    this.gettextCatalog.getString("America/Asuncion")
    this.gettextCatalog.getString("Asia/Ulan_Bator")
    this.gettextCatalog.getString("America/Boise")
    this.gettextCatalog.getString("Australia/Currie")
    this.gettextCatalog.getString("Pacific/Guam")
    this.gettextCatalog.getString("Pacific/Wake")
    this.gettextCatalog.getString("Atlantic/Bermuda")
    this.gettextCatalog.getString("America/Costa_Rica")
    this.gettextCatalog.getString("America/Dawson")
    this.gettextCatalog.getString("Asia/Chongqing")
    this.gettextCatalog.getString("Europe/Amsterdam")
    this.gettextCatalog.getString("America/Indiana/Knox")
    this.gettextCatalog.getString("America/North_Dakota/Beulah")
    this.gettextCatalog.getString("Africa/Accra")
    this.gettextCatalog.getString("Atlantic/Faroe")
    this.gettextCatalog.getString("Mexico/BajaNorte")
    this.gettextCatalog.getString("America/Maceio")
    this.gettextCatalog.getString("Pacific/Apia")
    this.gettextCatalog.getString("America/Atka")
    this.gettextCatalog.getString("Pacific/Niue")
    this.gettextCatalog.getString("Australia/Lord_Howe")
    this.gettextCatalog.getString("Europe/Dublin")
    this.gettextCatalog.getString("Pacific/Truk")
    this.gettextCatalog.getString("America/Monterrey")
    this.gettextCatalog.getString("America/Nassau")
    this.gettextCatalog.getString("America/Jamaica")
    this.gettextCatalog.getString("Asia/Bishkek")
    this.gettextCatalog.getString("America/Atikokan")
    this.gettextCatalog.getString("Atlantic/Stanley")
    this.gettextCatalog.getString("Australia/NSW")
    this.gettextCatalog.getString("US/Hawaii")
    this.gettextCatalog.getString("SystemV/CST6")
    this.gettextCatalog.getString("Indian/Mahe")
    this.gettextCatalog.getString("Asia/Aqtobe")
    this.gettextCatalog.getString("America/Sitka")
    this.gettextCatalog.getString("Asia/Vladivostok")
    this.gettextCatalog.getString("Africa/Libreville")
    this.gettextCatalog.getString("Africa/Maputo")
    this.gettextCatalog.getString("America/Kentucky/Monticello")
    this.gettextCatalog.getString("Africa/El_Aaiun")
    this.gettextCatalog.getString("Africa/Ouagadougou")
    this.gettextCatalog.getString("America/Coral_Harbour")
    this.gettextCatalog.getString("Pacific/Marquesas")
    this.gettextCatalog.getString("Brazil/West")
    this.gettextCatalog.getString("America/Aruba")
    this.gettextCatalog.getString("America/North_Dakota/Center")
    this.gettextCatalog.getString("America/Cayman")
    this.gettextCatalog.getString("Asia/Ulaanbaatar")
    this.gettextCatalog.getString("Asia/Baghdad")
    this.gettextCatalog.getString("Europe/San_Marino")
    this.gettextCatalog.getString("America/Indiana/Tell_City")
    this.gettextCatalog.getString("America/Tijuana")
    this.gettextCatalog.getString("Pacific/Saipan")
    this.gettextCatalog.getString("SystemV/YST9")
    this.gettextCatalog.getString("Africa/Douala")
    this.gettextCatalog.getString("America/Chihuahua")
    this.gettextCatalog.getString("America/Ojinaga")
    this.gettextCatalog.getString("Asia/Hovd")
    this.gettextCatalog.getString("America/Anchorage")
    this.gettextCatalog.getString("Chile/EasterIsland")
    this.gettextCatalog.getString("America/Halifax")
    this.gettextCatalog.getString("Antarctica/Rothera")
    this.gettextCatalog.getString("America/Indiana/Indianapolis")
    this.gettextCatalog.getString("US/Mountain")
    this.gettextCatalog.getString("Asia/Damascus")
    this.gettextCatalog.getString("America/Argentina/San_Luis")
    this.gettextCatalog.getString("America/Santiago")
    this.gettextCatalog.getString("Asia/Baku")
    this.gettextCatalog.getString("America/Argentina/Ushuaia")
    this.gettextCatalog.getString("Atlantic/Reykjavik")
    this.gettextCatalog.getString("Africa/Brazzaville")
    this.gettextCatalog.getString("Africa/Porto-Novo")
    this.gettextCatalog.getString("America/La_Paz")
    this.gettextCatalog.getString("Antarctica/DumontDUrville")
    this.gettextCatalog.getString("Asia/Taipei")
    this.gettextCatalog.getString("Antarctica/South_Pole")
    this.gettextCatalog.getString("Asia/Manila")
    this.gettextCatalog.getString("Asia/Bangkok")
    this.gettextCatalog.getString("Africa/Dar_es_Salaam")
    this.gettextCatalog.getString("Atlantic/Madeira")
    this.gettextCatalog.getString("Antarctica/Palmer")
    this.gettextCatalog.getString("America/Thunder_Bay")
    this.gettextCatalog.getString("Africa/Addis_Ababa")
    this.gettextCatalog.getString("Asia/Yangon")
    this.gettextCatalog.getString("Europe/Uzhgorod")
    this.gettextCatalog.getString("Brazil/DeNoronha")
    this.gettextCatalog.getString("Asia/Ashkhabad")
    this.gettextCatalog.getString("Etc/Zulu")
    this.gettextCatalog.getString("America/Indiana/Marengo")
    this.gettextCatalog.getString("America/Creston")
    this.gettextCatalog.getString("America/Punta_Arenas")
    this.gettextCatalog.getString("America/Mexico_City")
    this.gettextCatalog.getString("Antarctica/Vostok")
    this.gettextCatalog.getString("Asia/Jerusalem")
    this.gettextCatalog.getString("Europe/Andorra")
    this.gettextCatalog.getString("US/Samoa")
    this.gettextCatalog.getString("Asia/Vientiane")
    this.gettextCatalog.getString("Pacific/Kiritimati")
    this.gettextCatalog.getString("America/Matamoros")
    this.gettextCatalog.getString("America/Blanc-Sablon")
    this.gettextCatalog.getString("Asia/Riyadh")
    this.gettextCatalog.getString("Iceland")
    this.gettextCatalog.getString("Pacific/Pohnpei")
    this.gettextCatalog.getString("Asia/Ujung_Pandang")
    this.gettextCatalog.getString("Atlantic/South_Georgia")
    this.gettextCatalog.getString("Europe/Lisbon")
    this.gettextCatalog.getString("Asia/Harbin")
    this.gettextCatalog.getString("Europe/Oslo")
    this.gettextCatalog.getString("Asia/Novokuznetsk")
    this.gettextCatalog.getString("Atlantic/Canary")
    this.gettextCatalog.getString("America/Knox_IN")
    this.gettextCatalog.getString("Asia/Kuwait")
    this.gettextCatalog.getString("SystemV/HST10")
    this.gettextCatalog.getString("Pacific/Efate")
    this.gettextCatalog.getString("Africa/Lome")
    this.gettextCatalog.getString("America/Bogota")
    this.gettextCatalog.getString("America/Menominee")
    this.gettextCatalog.getString("America/Adak")
    this.gettextCatalog.getString("Pacific/Norfolk")
    this.gettextCatalog.getString("Europe/Kirov")
    this.gettextCatalog.getString("America/Resolute")
    this.gettextCatalog.getString("Pacific/Tarawa")
    this.gettextCatalog.getString("Africa/Kampala")
    this.gettextCatalog.getString("Asia/Krasnoyarsk")
    this.gettextCatalog.getString("Greenwich")
    this.gettextCatalog.getString("SystemV/EST5")
    this.gettextCatalog.getString("America/Edmonton")
    this.gettextCatalog.getString("Europe/Podgorica")
    this.gettextCatalog.getString("Australia/South")
    this.gettextCatalog.getString("Canada/Central")
    this.gettextCatalog.getString("Africa/Bujumbura")
    this.gettextCatalog.getString("America/Santo_Domingo")
    this.gettextCatalog.getString("US/Eastern")
    this.gettextCatalog.getString("Europe/Minsk")
    this.gettextCatalog.getString("Pacific/Auckland")
    this.gettextCatalog.getString("Africa/Casablanca")
    this.gettextCatalog.getString("America/Glace_Bay")
    this.gettextCatalog.getString("Canada/Eastern")
    this.gettextCatalog.getString("Asia/Qatar")
    this.gettextCatalog.getString("Europe/Kiev")
    this.gettextCatalog.getString("Singapore")
    this.gettextCatalog.getString("Asia/Magadan")
    this.gettextCatalog.getString("SystemV/PST8")
    this.gettextCatalog.getString("America/Port-au-Prince")
    this.gettextCatalog.getString("Europe/Belfast")
    this.gettextCatalog.getString("America/St_Barthelemy")
    this.gettextCatalog.getString("Asia/Ashgabat")
    this.gettextCatalog.getString("Africa/Luanda")
    this.gettextCatalog.getString("America/Nipigon")
    this.gettextCatalog.getString("Atlantic/Jan_Mayen")
    this.gettextCatalog.getString("Brazil/Acre")
    this.gettextCatalog.getString("Asia/Muscat")
    this.gettextCatalog.getString("Asia/Bahrain")
    this.gettextCatalog.getString("Europe/Vilnius")
    this.gettextCatalog.getString("America/Fortaleza")
    this.gettextCatalog.getString("Etc/GMT0")
    this.gettextCatalog.getString("US/East-Indiana")
    this.gettextCatalog.getString("America/Hermosillo")
    this.gettextCatalog.getString("America/Cancun")
    this.gettextCatalog.getString("Africa/Maseru")
    this.gettextCatalog.getString("Pacific/Kosrae")
    this.gettextCatalog.getString("Africa/Kinshasa")
    this.gettextCatalog.getString("Asia/Kathmandu")
    this.gettextCatalog.getString("Asia/Seoul")
    this.gettextCatalog.getString("Australia/Sydney")
    this.gettextCatalog.getString("America/Lima")
    this.gettextCatalog.getString("Australia/LHI")
    this.gettextCatalog.getString("America/St_Lucia")
    this.gettextCatalog.getString("Europe/Madrid")
    this.gettextCatalog.getString("America/Bahia_Banderas")
    this.gettextCatalog.getString("America/Montserrat")
    this.gettextCatalog.getString("Asia/Brunei")
    this.gettextCatalog.getString("America/Santa_Isabel")
    this.gettextCatalog.getString("Canada/Mountain")
    this.gettextCatalog.getString("America/Cambridge_Bay")
    this.gettextCatalog.getString("Asia/Colombo")
    this.gettextCatalog.getString("Australia/West")
    this.gettextCatalog.getString("Indian/Antananarivo")
    this.gettextCatalog.getString("Australia/Brisbane")
    this.gettextCatalog.getString("Indian/Mayotte")
    this.gettextCatalog.getString("US/Indiana-Starke")
    this.gettextCatalog.getString("Asia/Urumqi")
    this.gettextCatalog.getString("US/Aleutian")
    this.gettextCatalog.getString("Europe/Volgograd")
    this.gettextCatalog.getString("America/Lower_Princes")
    this.gettextCatalog.getString("America/Vancouver")
    this.gettextCatalog.getString("Africa/Blantyre")
    this.gettextCatalog.getString("America/Rio_Branco")
    this.gettextCatalog.getString("America/Danmarkshavn")
    this.gettextCatalog.getString("America/Detroit")
    this.gettextCatalog.getString("America/Thule")
    this.gettextCatalog.getString("Africa/Lusaka")
    this.gettextCatalog.getString("Asia/Hong_Kong")
    this.gettextCatalog.getString("America/Argentina/La_Rioja")
    this.gettextCatalog.getString("Africa/Dakar")
    this.gettextCatalog.getString("SystemV/CST6CDT")
    this.gettextCatalog.getString("America/Tortola")
    this.gettextCatalog.getString("America/Porto_Velho")
    this.gettextCatalog.getString("Asia/Sakhalin")
    this.gettextCatalog.getString("Etc/GMT+10")
    this.gettextCatalog.getString("America/Scoresbysund")
    this.gettextCatalog.getString("Asia/Kamchatka")
    this.gettextCatalog.getString("Asia/Thimbu")
    this.gettextCatalog.getString("Africa/Harare")
    this.gettextCatalog.getString("Etc/GMT+12")
    this.gettextCatalog.getString("Etc/GMT+11")
    this.gettextCatalog.getString("America/Nome")
    this.gettextCatalog.getString("Europe/Tallinn")
    this.gettextCatalog.getString("Africa/Khartoum")
    this.gettextCatalog.getString("Africa/Johannesburg")
    this.gettextCatalog.getString("Africa/Bangui")
    this.gettextCatalog.getString("Europe/Belgrade")
    this.gettextCatalog.getString("Africa/Bissau")
    this.gettextCatalog.getString("Asia/Tehran")
    this.gettextCatalog.getString("Europe/Astrakhan")
    this.gettextCatalog.getString("Africa/Juba")
    this.gettextCatalog.getString("America/Campo_Grande")
    this.gettextCatalog.getString("America/Belem")
    this.gettextCatalog.getString("Etc/Greenwich")
    this.gettextCatalog.getString("Asia/Saigon")
    this.gettextCatalog.getString("America/Ensenada")
    this.gettextCatalog.getString("Pacific/Midway")
    this.gettextCatalog.getString("America/Jujuy")
    this.gettextCatalog.getString("Africa/Timbuktu")
    this.gettextCatalog.getString("America/Bahia")
    this.gettextCatalog.getString("America/Goose_Bay")
    this.gettextCatalog.getString("America/Pangnirtung")
    this.gettextCatalog.getString("Asia/Katmandu")
    this.gettextCatalog.getString("America/Phoenix")
    this.gettextCatalog.getString("Africa/Niamey")
    this.gettextCatalog.getString("America/Whitehorse")
    this.gettextCatalog.getString("Pacific/Noumea")
    this.gettextCatalog.getString("Asia/Tbilisi")
    this.gettextCatalog.getString("America/Montreal")
    this.gettextCatalog.getString("Asia/Makassar")
    this.gettextCatalog.getString("America/Argentina/San_Juan")
    this.gettextCatalog.getString("Asia/Nicosia")
    this.gettextCatalog.getString("America/Indiana/Winamac")
    this.gettextCatalog.getString("SystemV/MST7MDT")
    this.gettextCatalog.getString("America/Argentina/ComodRivadavia")
    this.gettextCatalog.getString("America/Boa_Vista")
    this.gettextCatalog.getString("America/Grenada")
    this.gettextCatalog.getString("Asia/Atyrau")
    this.gettextCatalog.getString("Australia/Darwin")
    this.gettextCatalog.getString("Asia/Khandyga")
    this.gettextCatalog.getString("Asia/Kuala_Lumpur")
    this.gettextCatalog.getString("Asia/Famagusta")
    this.gettextCatalog.getString("Asia/Thimphu")
    this.gettextCatalog.getString("Asia/Rangoon")
    this.gettextCatalog.getString("Europe/Bratislava")
    this.gettextCatalog.getString("Asia/Calcutta")
    this.gettextCatalog.getString("America/Argentina/Tucuman")
    this.gettextCatalog.getString("Asia/Kabul")
    this.gettextCatalog.getString("Indian/Cocos")
    this.gettextCatalog.getString("Pacific/Tongatapu")
    this.gettextCatalog.getString("America/New_York")
    this.gettextCatalog.getString("Etc/GMT-12")
    this.gettextCatalog.getString("Etc/GMT-11")
    this.gettextCatalog.getString("Etc/GMT-10")
    this.gettextCatalog.getString("SystemV/YST9YDT")
    this.gettextCatalog.getString("Europe/Ulyanovsk")
    this.gettextCatalog.getString("Etc/GMT-14")
    this.gettextCatalog.getString("Etc/GMT-13")
    this.gettextCatalog.getString("America/Merida")
    this.gettextCatalog.getString("America/Rosario")
    this.gettextCatalog.getString("Canada/Saskatchewan")
    this.gettextCatalog.getString("America/St_Kitts")
    this.gettextCatalog.getString("Arctic/Longyearbyen")
    this.gettextCatalog.getString("America/Fort_Nelson")
    this.gettextCatalog.getString("America/Caracas")
    this.gettextCatalog.getString("America/Guadeloupe")
    this.gettextCatalog.getString("Asia/Hebron")
    this.gettextCatalog.getString("Indian/Kerguelen")
    this.gettextCatalog.getString("SystemV/PST8PDT")
    this.gettextCatalog.getString("Africa/Monrovia")
    this.gettextCatalog.getString("Asia/Ust-Nera")
    this.gettextCatalog.getString("Asia/Srednekolymsk")
    this.gettextCatalog.getString("America/North_Dakota/New_Salem")
    this.gettextCatalog.getString("Asia/Anadyr")
    this.gettextCatalog.getString("Australia/Melbourne")
    this.gettextCatalog.getString("Asia/Irkutsk")
    this.gettextCatalog.getString("America/Shiprock")
    this.gettextCatalog.getString("America/Winnipeg")
    this.gettextCatalog.getString("Europe/Vatican")
    this.gettextCatalog.getString("Asia/Amman")
    this.gettextCatalog.getString("Etc/UTC")
    this.gettextCatalog.getString("SystemV/AST4ADT")
    this.gettextCatalog.getString("Asia/Tokyo")
    this.gettextCatalog.getString("America/Toronto")
    this.gettextCatalog.getString("Asia/Singapore")
    this.gettextCatalog.getString("Australia/Lindeman")
    this.gettextCatalog.getString("America/Los_Angeles")
    this.gettextCatalog.getString("SystemV/EST5EDT")
    this.gettextCatalog.getString("Pacific/Majuro")
    this.gettextCatalog.getString("America/Argentina/Buenos_Aires")
    this.gettextCatalog.getString("Europe/Nicosia")
    this.gettextCatalog.getString("Pacific/Guadalcanal")
    this.gettextCatalog.getString("Europe/Athens")
    this.gettextCatalog.getString("US/Pacific")
    this.gettextCatalog.getString("Europe/Monaco")
  }
}

_module.service('timeZoneSelectConstants', timeZoneSelectConstants)