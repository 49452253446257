import _module from 'module.js'
import phoenixTemplate from 'controllers/phoenix/phoenixTemplate.html'

_module.config((gettext, $stateProvider) => {
  'ngInject'

  $stateProvider
    .state('root.nav.pbx.sitesv2', {
      url: '/emergency-locations',
      abstract: true,
      template: '<ui-view></ui-view>'
    })
    .state('root.nav.pbx.sitesv2.list', {
      url: '/',
      abstract: true,
      template: phoenixTemplate,
      controller: 'PhoenixController as ctrl'
    })
    .state('root.nav.pbx.sitesv2.list.locations', {
      url: 'locations',
      template: phoenixTemplate,
      controller: 'PhoenixController as ctrl'
    })
    .state('root.nav.pbx.sitesv2.list.locations.view', {
      url: '/:emergencyLocationId/:tab',
      template: phoenixTemplate,
      controller: 'PhoenixController as ctrl',
    })
    .state('root.nav.pbx.sitesv2.list.devices', {
      url: 'devices',
      template: phoenixTemplate,
      controller: 'PhoenixController as ctrl'
    })

})
