import { getJrnDefaultComponent } from '../getJrn/getJrnDefaultComponent.js'
import getDialByNameDirectoryController
  from './getDialByNameDirectoryController.js'
import _module from 'module.js'
import _ from 'lodash'

let getDialByNameDirectoryComponent = _.cloneDeep(getJrnDefaultComponent)
getDialByNameDirectoryComponent.controller = getDialByNameDirectoryController

_module.component('getDialByNameDirectory', getDialByNameDirectoryComponent)
