import { FEATURE_FLAGS } from '../../services/featuresService'

export default portalConfig => {
  'ngInject'
  const contextFeaturesRegex = new RegExp(
    `${
      portalConfig.featuresApiUrl
    }/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$`
  )
  const contextFeatureRegex = new RegExp(
    `${
      portalConfig.featuresApiUrl
    }/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/[a-zA-Z._-]+`
  )

  const featureCache = {
    get () {
      return {
        setting: 'ENABLED'
      }
    }
  }

  const allFeaturesCache = {
    get () {
      return Object.values(FEATURE_FLAGS).reduce((result, featureFlagName) => {
        result[featureFlagName] = 'ENABLED'
        return result
      }, {})
    }
  }

  return {
    request: function (requestConfig) {
      if (
        requestConfig.method === 'GET' &&
        contextFeaturesRegex.test(requestConfig.url)
      ) {
        requestConfig.url = 'contextFeatures'
        requestConfig.cache = allFeaturesCache
      }

      if (
        requestConfig.method === 'GET' &&
        contextFeatureRegex.test(requestConfig.url)
      ) {
        requestConfig.url = 'contextFeature'
        requestConfig.cache = featureCache
      }

      return requestConfig
    }
  }
}
