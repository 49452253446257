/**
 * @ngdoc function
 * @name platformApp.controller:HardwareController
 * @description
 * # HardwareController - List view of hardware
 * Controller of the platformApp
 */
import _module from 'module.js'
import _ from 'lodash'
import BaseController from 'classes/baseController'
import jrnParser from 'classes/jrnParser'

export default class HardwareController extends BaseController {
  constructor (
    $q,
    portalApi,
    $stateParams,
    Hardware,
    userService,
    gettextCatalog,
    $state,
    LIST_LIMIT_DEFAULT,
    portalUtil,
    errorService,
    hotDeskingService,
    $log,
    globalState,
    navigation,
    $rootScope,
    $timeout,
    FEATURE_FLAGS
  ) {
    'ngInject'
    super(
      $state,
      $stateParams,
      Hardware,
      portalApi,
      userService,
      gettextCatalog,
      portalUtil,
      errorService,
      globalState,
      navigation,
      'root.nav.pbx.devices.hardware.list',
      'hardwareId'
    )

    this.baseHotDeskingUserAgentOptions = {}
    this._$q = $q
    this._hotDeskingService = hotDeskingService
    this._jrn = jrnParser
    this.isE911Enabled = globalState.isE911Enabled
    this.isE911V3Enabled = globalState.isE911V3Enabled

    this.data
      .get()
      .then(data => {
        this.itemName = data.macAddress
        this.initial = false

        this.hotDeskingUserAgentOptions = {}
        return (!this.globalState.isUserManageSeatsOnly ?
          this._hotDeskingService
            .getUserAgentOptions(this.data._id)
            .then(hotDeskingUserAgentOptions => {
              this.baseHotDeskingUserAgentOptions = _.clone(
                hotDeskingUserAgentOptions
              )
              this.hotDeskingUserAgentOptions = hotDeskingUserAgentOptions
            })
          : Promise.resolve())
          .then(() => this.ready = true)
      })
      .catch(error => {
        let errorMessage = this.errorService.getErrorMessage(error)
        this.portalUtil.showErrorAlert(
          _.isEmpty(errorMessage)
            ? this.gettextCatalog.getString(
                'Data retrieval failed, please retry.'
              )
            : errorMessage
        )
        this.ready = true
        this.initial = false
        $timeout(() => {
          $rootScope.$apply()
        })
        throw error
      })
  }

  getUserAgentTypes (q) {
    return portalApi.userAgent
      .userAgentTypes()
      .withHttpConfig({
        cache: true
      })
      .get({
        q: q,
        limit: LIST_LIMIT_DEFAULT
      })
      .then(data => {
        return data.items.map(item => {
          return {
            id: item.jrn,
            text: item.displayString
          }
        })
      })
  }

  save (form) {
    this.ready = false

    const hotDeskingUpdatePromise = this.globalState.isUserManageSeatsOnly ?
      this._hotDeskingService.updateUserAgentOptions(
        this.baseHotDeskingUserAgentOptions,
        this.hotDeskingUserAgentOptions
      )
    : Promise.resolve()

    const updatePromise = this.data.update().catch(error => {
      return hotDeskingUpdatePromise
        .then(() => {
          return !this.globalState.isUserManageSeatsOnly ?
            this._hotDeskingService
              .updateUserAgentOptions(
                this.hotDeskingUserAgentOptions,
                this.baseHotDeskingUserAgentOptions
              )
              .catch(revertError => {
                $log.error(
                  'Failure reverting changes to hot desk user agent options for ' +
                    this.baseHotDeskingUserAgentOptions.userAgentId,
                  revertError
                )
              })
            : Promise.resolve()
        })
        .finally(() => {
          throw error
        })
    })

    return this._$q
      .all([hotDeskingUpdatePromise, updatePromise])
      .then(results => {
        if (!this.globalState.isUserManageSeatsOnly) {
          const updatedHotDeskingUserAgentOptions = results[0]
          this.baseHotDeskingUserAgentOptions = _.clone(
            updatedHotDeskingUserAgentOptions
          )
          this.hotDeskingUserAgentOptions = updatedHotDeskingUserAgentOptions
        }

        form.$setPristine()

        this.ready = true
      })
      .catch(error => {
        this.portalUtil.showErrorAlert(
          this.gettextCatalog.getString('Save failed, please retry.')
        )
        this.ready = true
        throw error
      })
  }

  goToDevice () {
    if (this.data.configuration) {
      let configId = this._jrn.parse(this.data.configuration).getResources()[1]
      this.$stateParams['deviceId'] = configId
      this.$state.go('root.nav.pbx.devices.view.hardware', this.$stateParams)
    }
  }
}

_module.controller('HardwareController', HardwareController)
