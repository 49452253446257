/**
 * @ngdoc function
 * @name platformApp.controller:ConferenceRoomController
 * @description
 * # ConferenceRoomController
 * Controller of the platformApp
 */
import _module from 'module.js'
import _ from 'lodash'
import is from 'is'
import ExtensionController from 'classes/extensionController'

export default class ConferenceRoomController extends ExtensionController {
  constructor (
    $state,
    $stateParams,
    portalApi,
    ConferenceRoom,
    gettextCatalog,
    portalUtil,
    errorService,
    globalState,
    navigation,
    $location,
    $window,
    userService,
    $timeout,
    scheduleConference,
    $analytics,
    $rootScope
  ) {
    'ngInject'
    super(
      $state,
      $stateParams,
      ConferenceRoom,
      portalApi,
      userService,
      gettextCatalog,
      portalUtil,
      errorService,
      globalState,
      navigation,
      'root.nav.pbx.extensions.conferencerooms.list',
      'extId'
    )

    this.$location = $location
    this.$window = $window
    this.userService = userService
    this.$timeout = $timeout
    this.scheduleConference = scheduleConference
    this.$analytics = $analytics

    /*
     * General tab
     */

    this.data
      .get()
      .then(data => {
        this.itemName = data.extensionNumber
          ? data.extensionNumber + ': ' + data.name
          : data.name
        this.initExtensionNumber = this.data.extensionNumber
        this.confPath = data.url
        this.conferenceURL =
          this.$location.protocol() +
          '://' +
          this.$location.host() +
          ':' +
          this.$location.port() +
          this.confPath

        this.updateFlags(data)
        this.ready = true
        this.initial = false
        $timeout(() => {
          $rootScope.$apply()
        })
      })
      .catch(error => {
        var errorMessage = this.errorService.getErrorMessage(error)
        this.portalUtil.showErrorAlert(
          _.isEmpty(errorMessage)
            ? this.gettextCatalog.getString(
                'Data retrieval failed, please retry.'
              )
            : errorMessage
        )
        this.ready = true
        this.initial = false
        $timeout(() => {
          $rootScope.$apply()
        })
        throw error
      })

    /*
     * Hidden Options tab
     */

    // Hackathon 06/09/2016: hide the schedule button for now.

    this.showScheduleUI = false
  }

  save (form) {
    this.ready = false
    return this.data
      .update()
      .then(() => {
        this.updateFlags(this.data)
        this.itemName = this.data.extensionNumber
          ? this.data.extensionNumber + ': ' + this.data.name
          : this.data.name
        this.initExtensionNumber = this.data.extensionNumber
        form.$setPristine()
        this.ready = true
      })
      .catch(error => {
        this.portalUtil.showErrorAlert(
          this.gettextCatalog.getString('Save failed, please retry.')
        )
        this.ready = true
        throw error
      })
  }

  updateFlags (data) {
    this.hostPinSaved = data.adminPassword && data.adminPassword.length > 0
    this.participantPinSaved = data.password && data.password.length > 0
    this.extensionNumberSaved =
      data.extensionNumber && data.extensionNumber.length > 0
  }

}

_module.controller('ConferenceRoomCtrl', ConferenceRoomController)
