import _module from 'module.js'

export default class extensionTypes {
  constructor (gettextCatalog) {
    'ngInject'
    this.gettextCatalog = gettextCatalog
  }

  get allTypes () {
    return [
      {
        id: 'line',
        text: this.gettextCatalog.getString('Line')
      },
      {
        id: 'ring-group',
        text: this.gettextCatalog.getString('Ring Group')
      },
      {
        id: 'conference-room',
        text: this.gettextCatalog.getString('Conference Bridge')
      },
      {
        id: 'call-queue',
        text: this.gettextCatalog.getString('Call Queue')
      },
      {
        id: 'dial-plan',
        text: this.gettextCatalog.getString('Dial Plan')
      },
      {
        id: 'shared-voicemail-box',
        text: this.gettextCatalog.getString('Shared Voicemail Box')
      },
      {
        id: 'virtual-fax-machine',
        text: this.gettextCatalog.getString('Virtual Fax Machine')
      }
    ]
  }
}

_module.service('extensionTypes', extensionTypes)

_module.filter('extType', function (gettextCatalog) {
  return function (type) {
    switch (type) {
      case 'line':
        return gettextCatalog.getString('Line')
      case 'ring-group':
        return gettextCatalog.getString('Ring Group')
      case 'conference-room':
        return gettextCatalog.getString('Conference Bridge')
      case 'call-queue':
        return gettextCatalog.getString('Call Queue')
      case 'dial-plan':
        return gettextCatalog.getString('Dial Plan')
      case 'shared-voicemail-box':
        return gettextCatalog.getString('Shared Voicemail Box')
      case 'virtual-fax-machine':
        return gettextCatalog.getString('Virtual Fax Machine')
      default:
        return gettextCatalog.getString('Unknown')
    }
  }
})
