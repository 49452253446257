import _module from 'module.js'

_module.directive('intlTel', globalState => {
  return {
    restrict: 'AE',
    require: ['ngModel'],
    scope: {
      number: '=ngModel',
      code: '=',
      forceValid: '='
    },

    link: (scope, elements, attrs, ctrls) => {
      let ngModel = ctrls[0]
      let element = elements[0]
      let intl = window.intlTelInput(element);
      intl.telInput.addEventListener("countrychange", function() {
        scope.number =intl.getNumber()
        ngModel.$validate()
      });

      ngModel.$validators.validNumber = (modelValue, viewValue) => {
        let error = intl.getValidationError()

        if (scope.forceValid || viewValue) {
          return !error
        } 

        return true
      }

      ngModel.$parsers.unshift(() => {
        return intl.getNumber()
      })

      ngModel.$render = () => {
        if (scope.number) {
          intl.setNumber(scope.number)
        } else {
          intl.setCountry(scope.code || globalState.selectedPbx.region)
        }
      }
    }
  }
})
