import jrnParser from 'classes/jrnParser'

let _filter, _state, _globalState

export default class GetOutboundDidController {
  constructor (gettextCatalog, $state, globalState, $filter, portalApi) {
    'ngInject'

    this._model = {}

    _state = $state
    _filter = $filter
    _globalState = globalState

    this.placeholder = gettextCatalog.getString('Select a phone number...')

    this.extraParams = [
      {
        key: 'outboundRoutable',
        value: true
      },
      {
        key: 'siteUnassignedOnly',
        value: false
      }
    ]
    if(this.restrictSite) {
      this.extraParams[1].value = true
    }

    this.api = portalApi.pstn.pbxs(globalState.selectedPbxUUID).dids

    this.$onInit = () =>
      (this.setViewValue = item => this.ngModel.$setViewValue(item.id))
  }

  map (did) {
    const number = _filter('telE164')(did.number)

    const text = []
    text.push(did.name)
    text.push(`(${did.callerId ? did.callerId + ' ' : ''}<${number}>)`)
    if (did.siteName) {
      text.push(`- ${did.siteName}`)
    }

    return {
      id: did.jrn,
      text: text.join(' ')
    }
  }

  openLink () {
    _state.go('root.nav.pbx.dids.view.general', {
      id: _globalState.selectedPbx.domain,
      didId: jrnParser.parse(this.jrn).getResources()[1]
    })
  }
}
