/**
 * @ngdoc function
 * @name platformApp.controller:DirectoryController
 * @description
 * # DirectoryController
 * Controller of the platformApp
 */
import _module from 'module.js'
import _ from 'lodash'
import BaseController from 'classes/baseController'

export default class DirectoryController extends BaseController {
  constructor (
    $stateParams,
    Directory,
    portalApi,
    $state,
    gettextCatalog,
    portalUtil,
    errorService,
    globalState,
    navigation,
    userService,
    $rootScope,
    $timeout
  ) {
    'ngInject'
    super(
      $state,
      $stateParams,
      Directory,
      portalApi,
      userService,
      gettextCatalog,
      portalUtil,
      errorService,
      globalState,
      navigation,
      'root.nav.pbx.directories.list',
      'directoryId'
    )

    this.data
      .get()
      .then(data => {
        this.itemName = data.name
        this.ready = true
        this.initial = false
        $timeout(() => {
          $rootScope.$apply()
        })
      })
      .catch(error => {
        let errorMessage = errorService.getErrorMessage(error)
        this.portalUtil.showErrorAlert(
          _.isEmpty(errorMessage)
            ? this.gettextCatalog.getString(
                'Data retrieval failed, please retry.'
              )
            : errorMessage
        )
        this.ready = true
        this.initial = false
        $timeout(() => {
          $rootScope.$apply()
        })
        throw error
      })

    this.data.getReferencesApi().get().then(references => {
      this.data.references = references
    })

    this.nameOptions = {
      radios: [
        {
          label: this.gettextCatalog.getString(
            'Include all extensions (exclude listed extensions)'
          ),
          value: true
        },
        {
          label: this.gettextCatalog.getString(
            'Include only extensions listed'
          ),
          value: false
        }
      ]
    }

    // Members
    this.newLine = null

    this.getExtensions = {
      api: this.portalApi.extension.pbxs(globalState.selectedPbxUUID)
        .extensions,
      filter: item => {
        for (var i = this.data._entries.length - 1; i >= 0; i--) {
          if (this.data._entries[i].id === item.jrn) {
            return false
          }
        }
        return true
      },
      map: item => {
        var number = ''
        if (item.extensionNumber) {
          number = item.extensionNumber + ': '
        }

        return {
          id: item.jrn,
          text: number + item.name,
          path: this.portalUtil.resolveExtensionOrDirectoryPath(item)
        }
      }
    }
  }

  save (form) {
    this.ready = false
    this.data.name = this.itemName
    return this.data
      .update()
      .then(() => {
        form.$setPristine()
        this.ready = true
      })
      .catch(error => {
        this.portalUtil.showErrorAlert(
          this.gettextCatalog.getString('Save failed, please retry.')
        )
        this.ready = true
        throw error
      })
  }

  addExtension (member) {
    if (member) {
      this.data._entries.push(member)
    }
  }
  removeExtension (member) {
    var i = _.findKey(this.data._entries, member)
    this.data._entries.splice(i, 1)
  }
}

_module.controller('DirectoryController', DirectoryController)
