import getCorporateDirectoryController
  from './getCorporateDirectoryController.js'
import { getJrnDefaultComponent } from '../getJrn/getJrnDefaultComponent.js'
import _module from 'module.js'
import _ from 'lodash'

let getCorporateDirectoryComponent = _.cloneDeep(getJrnDefaultComponent)
getCorporateDirectoryComponent.controller = getCorporateDirectoryController

_module.component('getCorporateDirectory', getCorporateDirectoryComponent)
