import _module from 'module.js'
import _ from 'lodash'
import moment from 'moment'
import jrnParser from 'classes/jrnParser'
import { getWelcomeGuideLinks } from '../../classes/welcomeGuideLinks'

export default class PbxController {
  constructor (
    $stateParams,
    $state,
    globalState,
    pbxUtil,
    portalApi,
    $window,
    userService,
    $localStorage,
    $analytics,
    portalUtil,
    portalConfig,
    e911CarouselPopup
  ) {
    'ngInject'
    this.globalState = globalState
    this.$state = $state
    this.isBrowserSupported = globalState.isBrowserSupported
    this.selectedPbx = globalState.selectedPbx
    this.isJiveEmployee = globalState.isJiveEmployee
    this.isWhiteLabel = globalState.isWhiteLabel
    this.title = globalState.pbxTitle
    this.newView = globalState.newView
    this.$window = $window
    this.pbxUtil = pbxUtil
    this.$localStorage = $localStorage
    this.$analytics = $analytics
    this.portalUtil = portalUtil
    this.jrn = jrnParser
    this.portalConfig = portalConfig
    this.e911CarouselPopup = e911CarouselPopup
    this.isE911Visible = globalState.isE911Enabled
    this.isE911V3Enabled = globalState.isE911V3Enabled
    this.usersIcon = portalConfig.usersIcon
    this.flowchartIcon = portalConfig.flowchartIcon
    this.hardphoneIcon = portalConfig.hardphoneIcon
    this.voipIcon = portalConfig.voipIcon
    this.phoneIcon = portalConfig.phoneIcon
    this.isPlatform = globalState.isPlatform

    this.tourImage = this.portalConfig.e911Carousel1

    this.goToAdminUrl = this.pbxUtil.getGoToAdminUrl(this.selectedPbx)

    const manualLinks = {
      'en-US': 'https://support.goto.com/jive',
      'en-GB': 'https://support.goto.com/jive',
      en: 'https://support.goto.com/jive',
      es: 'https://support.goto.com/jive',
      'es-MX': 'https://support.goto.com/jive',
      'es-ES': 'https://support.goto.com/jive',
      'pt-BR': 'https://support.goto.com/jive',
      'de-DE': 'https://support.goto.com/de/jive'
    }

    this.coreManualUrl = manualLinks[this.globalState.currentLanguage.langCode] || manualLinks['en-US']

    const supportPhoneNumbers = {
      'US': '(866) 768-5429',
      'MX': '+52 (55) 4741-1465',
      'CA': '(866) 768-5429',
      'FR': '(866) 768-5429',
      'BR': '+55 (11) 3197-4866',
      'DE': '+49 800 7235089',
      'GB': '+44 800 069 8192',
      'IE': '+353 1800 901 757',
      'AU': '+61 1800 091 811',
      'ES': '(866) 768-5429'
    }

    this.supportPhoneNumber = this.selectedPbx.partnerInfo.supportPhoneNumber
    // partnerInfo defaults to US support number
    if (this.globalState.selectedPbx.region !== 'US' && this.supportPhoneNumber === supportPhoneNumbers['US']) {
      this.supportPhoneNumber  = supportPhoneNumbers[this.globalState.selectedPbx.region]
    }

    // Users with "manage users" or "manage groups" or "manage seats" should be redirected to the users page
    Promise.all([
      userService.hasManageSeatsPermission(this.selectedPbx, true),
      userService.hasManageUsersPermission(this.selectedPbx, true),
      userService.hasManageGroupsPermission(this.selectedPbx, true)
    ])
    .then((data) => {
      if (data[0] || data[1] || data[2]) {
        $state.go('root.nav.pbx.users.list')
      } else {
        userService
          .getUserInfo()
          .then(userInfo => this.trackingUserAccessData(this.selectedPbx, userInfo))

        if ((this.globalState.isPbxAdmin || this.globalState.isPlatformReadOnlyAccess) && $stateParams.id) {
          // hardcoded to Mountain Time Zone as the report data is updated in the mid-night of Mountain Time.
          this.lastReportedDate = moment()
            .tz('America/Denver')
            .subtract(1, 'days')
            .format('M/D/YYYY')

          portalApi.billing
            .pbxs(this.globalState.selectedPbxUUID)
            .summary()
            .get()
            .then(summary => {
              this.billingSummary = summary
            })
        }

        userService.isBillingAllowed(this.selectedPbx).then(flag => {
          this.globalState.selectedPbx.isBillingAllowed = flag
        })

        portalApi.userAgent
          .pbxs(this.globalState.selectedPbxUUID)
          .unavailableLines()
          .get()
          .then(data => {
            this.globalState.selectedPbx.unavailableLinesTotal = data.totalCount
          })
      }
    });
  }

  toggleView () {
    this.newView = this.globalState.newView = !this.globalState.newView
    this.$localStorage.newView = this.newView
    this.$analytics.eventTrack(globalState.newView ? 'On' : 'Off', {
      category: 'Toggle Improved Navigation'
    })
    this.$window.location.reload()
  }

  contactDirectory () {
    this.portalUtil.downloadContactDirectory()
  }

  openE911Carousel() {
    this.e911CarouselPopup.open().then(viewed => {
      this.globalState.displayE911Carousel = true
      this.$state.go('root.nav.pbx.sitesv2.list.locations', {
        id: this.globalState.selectedPbx.domain
      })
    }).catch(cancelled => {
      this.globalState.displayE911Carousel = true
    })
  }

  trackingUserAccessData (pbx, userInfo) {
    let permissionsFound = false
    if (userInfo.authorities.length !== 0) {
      this.$analytics.eventTrack(
        `Pbx: ${pbx.domain}, Authorities: ${userInfo.authorities.toString()}`,
        { category: 'User Permissions' }
      )
      permissionsFound = true
    } else {
      let userPbx = _.find(userInfo.users, user => {
        let userPbxId = this.jrn.parse(user.jrn).getAccount()
        if (userPbxId === this.jrn.parse(pbx.jrn).getAccount()) {
          return true
        }
        return false
      })

      if (userPbx && userPbx.pbxPermissions.length !== 0) {
        this.$analytics.eventTrack(
          `Pbx: ${
            pbx.domain
          }, User Permission: ${userPbx.pbxPermissions.toString()}`,
          { category: 'User Permissions' }
        )
        permissionsFound = true
      }

      // check partner
      let partnerPbx = _.find(userInfo.partnerUsers, user => {
        if (
          pbx.partner &&
          this.jrn.parse(user.jrn).getAccount() ===
            this.jrn.parse(pbx.partner).getResources()[1]
        ) {
          return true
        }
        return false
      })

      if (partnerPbx && partnerPbx.partnerPermissions !== 0) {
        this.$analytics.eventTrack(
          `Pbx: ${
            pbx.domain
          }, Partner User Permission: ${partnerPbx.partnerPermissions.toString()}`,
          { category: 'User Permissions' }
        )
        permissionsFound = true
      }
    }

    if (!permissionsFound) {
      this.$analytics.eventTrack(
        `Pbx: ${pbx.domain}, no permission specified`,
        { category: 'User Permissions' }
      )
    }
  }
}

_module.controller('PbxController', PbxController)
