/**
 * @ngdoc function
 * @name platformApp.controller: VirtualFaxMachinesController
 * @description
 * # VirtualFaxMachinesController
 * Controller of the platformApp
 */
import _module from 'module.js'
import _ from 'lodash'

import ListControllerBase from 'classes/listController.class'

export default class virtualFaxMachinesController extends ListControllerBase {
  constructor (
    $state,
    $stateParams,
    $location,
    $analytics,
    VirtualFaxMachineFactory,
    gettextCatalog,
    infiniteLoader,
    navigation,
    globalState,
    portalUtil
  ) {
    'ngInject'
    super(
      gettextCatalog,
      $analytics,
      $location,
      portalUtil,
      globalState,
      navigation,
      $state,
      $stateParams
    )

    this.gettextCatalog = gettextCatalog

    this.title = this.gettextCatalog.getString('Virtual Fax Machines')
    this.headers = [
      {
        width: 10,
        name: this.gettextCatalog.getString('Extension'),
        sortBy: 'extensionNumber',
        asc: true
      },
      {
        width: 50,
        name: this.gettextCatalog.getString('Name'),
        sortBy: 'name',
        asc: null
      },
      {
        width: 30,
        name: this.gettextCatalog.getString('Omit from Directory')
      },
      {
        width: 10,
        name: ''
      }
    ]
    this.buttonText = this.gettextCatalog.getString('Add Virtual Fax Machine')
    this.loader = infiniteLoader(VirtualFaxMachineFactory)
  }

  delete (event, vfm) {
    let name =
      this.gettextCatalog.getString('virtual fax machine') +
      ': ' +
      (vfm.extensionNumber ? vfm.extensionNumber + ': ' + vfm.name : vfm.name)
    this.loader.entityApi.startDelete(event, vfm, this.data, name)
  }

  itemSelected (vfm) {
    super.itemSelected(
      vfm,
      'root.nav.pbx.extensions.virtualfaxmachines.view.general',
      'extId'
    )
  }
}

_module.controller('VirtualFaxMachinesController', virtualFaxMachinesController)
