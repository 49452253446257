import _module from "module.js";
import usersRowEditorTemplate from "controllers/users/usersRowEditorTemplate.html";
import groupsRowEditorTemplate from "controllers/groups/groupsRowEditorTemplate.html";
import didsRowEditorTemplate from "controllers/dids/didsRowEditorTemplate.html";
import deviceStatusRowEditorTemplate from "controllers/deviceStatus/deviceStatusListTemplate.html";
import unavailableLinesRowEditorTemplate from "controllers/unavailableLines/unavailableLinesRowEditorTemplate.html";
import hardwaresRowEditorTemplate from "controllers/hardwares/hardwaresRowEditorTemplate.html";
import pagingProfilesRowEditorTemplate from "controllers/pagingProfiles/pagingProfilesRowEditorTemplate.html";
import pagingRealmsRowEditorTemplate from "controllers/pagingRealms/pagingRealmsRowEditorTemplate.html";
import extensionRowTemplate from "controllers/extensions/extensionRowTemplate.html";
import lineRowTemplate from "controllers/lines/linesRowEditorTemplate.html";
import ZohoMappingRowEditorTemplate from "controllers/zohoMapping/zohoMappingRowEditorTemplate.html";
import sharedVoiceMailboxesRowEditorTemplate from "controllers/sharedVoicemailBoxes/sharedVoicemailBoxesRowEditorTemplate.html";
import virtualFaxMachinesRowEditorTemplate from "controllers/virtualFaxMachines/virtualFaxMachinesRowEditorTemplate.html";
import ringGroupsRowEditorTemplate from "controllers/ringGroups/ringGroupsRowEditorTemplate.html";
import conferenceRoomsRowEditorTemplate from "controllers/conferenceRooms/conferenceRoomsRowEditorTemplate.html";
import soundClipsRowEditorTemplate from "controllers/soundClips/soundClipsRowEditorTemplate.html";
import schedulesRowEditorTemplate from "controllers/schedules/schedulesRowEditorTemplate.html";
import scheduleHolidaysRowEditorTemplate from "controllers/schedule/scheduleHolidays/scheduleHolidaysRowEditorTemplate.html";
import directoriesRowEditorTemplate from "controllers/directories/directoriesRowEditorTemplate.html";
import corporateDirectoriesRowEditorTemplate from "controllers/corpDirectories/corpDirectoriesRowEditorTemplate.html";
import musicRowEditorTemplate from "directives/holdMusic/music/musicRowEditorTemplate.html";
import networkRowEditorTemplate from "controllers/permissions/permissionsNetwork/networkRowEditorTemplate.html";
import callFilterRowEditorTemplate from "controllers/settings/settingsCallFilter/callFilterRowEditorTemplate.html";
import dialPlanRowEditorTemplate from "controllers/dialPlans/dialPlansRowEditorTemplate.html";
import proxiesRowEditorTemplate from "controllers/settings/settingsProxies/proxiesRowEditorTemplate.html";
import outboundProxiesRowEditorTemplate from "controllers/settings/settingsOutboundProxies/outboundProxiesRowEditorTemplate.html";
import jbcsRowEditorTemplate from "controllers/settings/settingsJbc/jbcsRowEditorTemplate.html";
import extensionMappingsRowEditorTemplate from "controllers/settings/settingsExtensionMappings/extensionMappingsRowEditorTemplate.html";
import billingGroupsRowEditorTemplate from "controllers/settings/settingsBillingGroups/billingGroupsRowEditorTemplate.html";
import devicesRowEditorTemplate from "controllers/devices/devicesRowEditorTemplate.html";
import userAgentConfigurationSecurityRowEditorTemplate from "controllers/security/userAgentConfigurationSecurityRowEditorTemplate.html";
import RowEditor from "directives/rowEditor/rowEditorDirective";
import "components/audioPlayer/audioPlayerService";

// Users Editor
var usersRowEditorDirectiveFactory = function ($q, $analytics) {
  return new RowEditor($q, $analytics, {
    template: usersRowEditorTemplate,
    controllerName: "usersRowEditor",
  });
};

_module.directive("usersRowEditor", usersRowEditorDirectiveFactory);

// Groups Editor
var groupsRowEditorDirectiveFactory = function ($q, $analytics) {
  return new RowEditor($q, $analytics, {
    template: groupsRowEditorTemplate,
    controllerName: "groupsRowEditor",
  });
};

_module.directive("groupsRowEditor", groupsRowEditorDirectiveFactory);

// Dids Editor
var didsRowEditorDirectiveFactory = function ($q, $analytics) {
  return new RowEditor($q, $analytics, {
    template: didsRowEditorTemplate,
    controllerName: "didsRowEditor",
  });
};

_module.directive("didsRowEditor", didsRowEditorDirectiveFactory);

// Device Editor
var devicesRowEditorDirectiveFactory = function (
  $q,
  $analytics,
  portalApi,
  $stateParams,
  $timeout,
  gettextCatalog,
  DEVICE_RESYNC_DELAY,
  portalUtil,
  globalState
) {
  return new RowEditor($q, $analytics, {
    template: devicesRowEditorTemplate,
    controllerName: "devicesRowEditor",
    portalApi,
    $stateParams,
    $timeout,
    gettextCatalog,
    DEVICE_RESYNC_DELAY,
    portalUtil,
    globalState,
  });
};

_module.directive("devicesRowEditor", devicesRowEditorDirectiveFactory);

// Device Status
var deviceStatusRowEditorDirectiveFactory = function ($q, $analytics) {
  return new RowEditor($q, $analytics, {
    template: deviceStatusRowEditorTemplate,
    controllerName: "deviceStatusRowEditor",
  });
};

_module.directive(
  "deviceStatusRowEditor",
  deviceStatusRowEditorDirectiveFactory
);

// Unavailable Lines
var unavailableLinesRowEditorDirectiveFactory = function ($q, $analytics) {
  return new RowEditor($q, $analytics, {
    template: unavailableLinesRowEditorTemplate,
    controllerName: "unavailableLinesRowEditor",
  });
};

_module.directive(
  "unavailableLinesRowEditor",
  unavailableLinesRowEditorDirectiveFactory
);

// Hardwares Editor
var hardwaresRowEditorDirectiveFactory = function ($q, $analytics) {
  return new RowEditor($q, $analytics, {
    template: hardwaresRowEditorTemplate,
    controllerName: "hardwaresRowEditor",
  });
};

_module.directive("hardwaresRowEditor", hardwaresRowEditorDirectiveFactory);

// Paging Profiles Editor
var pagingProfilesRowEditorDirectiveFactory = function ($q, $analytics) {
  return new RowEditor($q, $analytics, {
    template: pagingProfilesRowEditorTemplate,
    controllerName: "pagingProfilesRowEditor",
  });
};

_module.directive(
  "pagingProfilesRowEditor",
  pagingProfilesRowEditorDirectiveFactory
);

// Paging Realms Editor
var pagingRealmsRowEditorDirectiveFactory = function ($q, $analytics) {
  return new RowEditor($q, $analytics, {
    template: pagingRealmsRowEditorTemplate,
    controllerName: "pagingRealmsRowEditor",
  });
};

_module.directive(
  "pagingRealmsRowEditor",
  pagingRealmsRowEditorDirectiveFactory
);

// Extensions Editor
var extensionsRowEditorDirectiveFactory = function ($q, $analytics) {
  return new RowEditor($q, $analytics, {
    template: extensionRowTemplate,
    controllerName: "extensionsRowEditor",
  });
};

_module.directive("extensionsRowEditor", extensionsRowEditorDirectiveFactory);

// Lines Editor
var linesRowEditorDirectiveFactory = function ($q, $analytics, globalState) {
  return new RowEditor($q, $analytics, {
    template: lineRowTemplate,
    controllerName: "linesRowEditor",
    globalState,
  });
};

_module.directive("linesRowEditor", linesRowEditorDirectiveFactory);

// Ring Groups Editor
var ringGroupsRowEditorDirectiveFactory = function ($q, $analytics) {
  return new RowEditor($q, $analytics, {
    template: ringGroupsRowEditorTemplate,
    controllerName: "ringGroupsRowEditor",
  });
};

_module.directive("ringGroupsRowEditor", ringGroupsRowEditorDirectiveFactory);

// Shared Voicemail Boxes
var sharedVoicemailBoxesRowEditorDirectiveFactory = function ($q, $analytics) {
  return new RowEditor($q, $analytics, {
    template: sharedVoiceMailboxesRowEditorTemplate,
    controllerName: "sharedVoicemailBoxesRowEditor",
  });
};

_module.directive(
  "sharedVoicemailBoxesRowEditor",
  sharedVoicemailBoxesRowEditorDirectiveFactory
);

// Virtual Fax Machine Editor
var virtualFaxMachinesRowEditorDirectiveFactory = function ($q, $analytics) {
  return new RowEditor($q, $analytics, {
    template: virtualFaxMachinesRowEditorTemplate,
    controllerName: "virtualFaxMachinesRowEditor",
  });
};

_module.directive(
  "virtualFaxMachinesRowEditor",
  virtualFaxMachinesRowEditorDirectiveFactory
);

// Zoho Mapping Editor
var zohoMappingRowEditorDirectiveFactory = function (
  $q,
  $analytics,
  $timeout,
  confirmationWindowOK
) {
  return new RowEditor($q, $analytics, {
    template: ZohoMappingRowEditorTemplate,
    controllerName: "zohoMappingRowEditor",
    scope: {
      data: "=",
    },
  });
};

_module.directive("zohoMappingRowEditor", zohoMappingRowEditorDirectiveFactory);

// Conference Rooms Editor
var conferenceRoomsRowEditorDirectiveFactory = function (
  $q,
  $analytics,
  $stateParams,
  $window,
  $location,
  $timeout,
  gettextCatalog,
  globalState,
  confirmationWindowOK
) {
  return new RowEditor($q, $analytics, {
    template: conferenceRoomsRowEditorTemplate,
    controllerName: "conferenceRoomsRowEditor",
    $stateParams,
    $window,
    $location,
    $timeout,
    gettextCatalog,
    globalState,
    confirmationWindowOK,
  });
};

_module.directive(
  "conferenceRoomsRowEditor",
  conferenceRoomsRowEditorDirectiveFactory
);

// Dial Plans Editor
var dialPlansRowEditorDirectiveFactory = function ($q, $analytics) {
  return new RowEditor($q, $analytics, {
    template: dialPlanRowEditorTemplate,
    controllerName: "dialPlansRowEditor",
  });
};

_module.directive("dialPlansRowEditor", dialPlansRowEditorDirectiveFactory);

// Sound Clips Editor
var soundClipsRowEditorDirectiveFactory = function (
  $q,
  $analytics,
  audioPlayerService,
  confirmationWindowOK,
  gettextCatalog,
  mediaTypeSelector
) {
  return new RowEditor($q, $analytics, {
    template: soundClipsRowEditorTemplate,
    controllerName: "soundClipsRowEditor",
    audioPlayerService,
    confirmationWindowOK,
    gettextCatalog,
    mediaTypeSelector,
  });
};

_module.directive("soundClipsRowEditor", soundClipsRowEditorDirectiveFactory);

// Schedules Editor
var schedulesRowEditorDirectiveFactory = function ($q, $analytics) {
  return new RowEditor($q, $analytics, {
    template: schedulesRowEditorTemplate,
    controllerName: "schedulesRowEditor",
  });
};

_module.directive("schedulesRowEditor", schedulesRowEditorDirectiveFactory);

// Schedule Holidays Editor
var scheduleHolidaysRowEditorDirectiveFactory = function ($q, $analytics) {
  return new RowEditor($q, $analytics, {
    template: scheduleHolidaysRowEditorTemplate,
    controllerName: "scheduleHolidaysRowEditor",
  });
};

_module.directive(
  "scheduleHolidaysRowEditor",
  scheduleHolidaysRowEditorDirectiveFactory
);

// Directories Editor
var directoriesRowEditorDirectiveFactory = function ($q, $analytics) {
  return new RowEditor($q, $analytics, {
    template: directoriesRowEditorTemplate,
    controllerName: "directoriesRowEditor",
  });
};

_module.directive("directoriesRowEditor", directoriesRowEditorDirectiveFactory);

// Corporate Directories Editor
var corporateDirectoriesRowEditorDirectiveFactory = function ($q, $analytics) {
  return new RowEditor($q, $analytics, {
    template: corporateDirectoriesRowEditorTemplate,
    controllerName: "corporateDirectoriesRowEditor",
  });
};

_module.directive(
  "corporateDirectoriesRowEditor",
  corporateDirectoriesRowEditorDirectiveFactory
);

// Music Editor
var musicRowEditorDirectiveFactory = function ($q, $analytics) {
  return new RowEditor($q, $analytics, {
    template: musicRowEditorTemplate,
    controllerName: "holdMusicRowEditor",
  });
};

_module.directive("holdMusicRowEditor", musicRowEditorDirectiveFactory);

// Permissions Editor
var networkRowEditorDirectiveFactory = function ($q, $analytics) {
  return new RowEditor($q, $analytics, {
    template: networkRowEditorTemplate,
    controllerName: "networkRowEditor",
  });
};

_module.directive("networkRowEditor", networkRowEditorDirectiveFactory);

// Call Filter Editor
var callFilterRowEditorDirectiveFactory = function ($q, $analytics) {
  return new RowEditor($q, $analytics, {
    template: callFilterRowEditorTemplate,
    controllerName: "callFilterRowEditor",
  });
};

_module.directive("callFilterRowEditor", callFilterRowEditorDirectiveFactory);

// Proxies Editor
var proxiesRowEditorDirectiveFactory = function ($q, $analytics) {
  return new RowEditor($q, $analytics, {
    template: proxiesRowEditorTemplate,
    controllerName: "proxiesRowEditor",
    scope: {
      defaultRegProxy: "=",
    },
  });
};

_module.directive("proxiesRowEditor", proxiesRowEditorDirectiveFactory);

// Outbound Proxies Editor
var outboundProxiesRowEditorDirectiveFactory = function ($q, $analytics) {
  return new RowEditor($q, $analytics, {
    template: outboundProxiesRowEditorTemplate,
    controllerName: "outboundProxiesRowEditor",
  });
};

_module.directive(
  "outboundProxiesRowEditor",
  outboundProxiesRowEditorDirectiveFactory
);

// Jbcs Editor
var jbcsRowEditorDirectiveFactory = function ($q, $analytics) {
  return new RowEditor($q, $analytics, {
    template: jbcsRowEditorTemplate,
    controllerName: "jbcsRowEditor",
  });
};

_module.directive("jbcsRowEditor", jbcsRowEditorDirectiveFactory);

// Extension Mapping Editor
var extensionMappingsRowEditorDirectiveFactory = function ($q, $analytics) {
  return new RowEditor($q, $analytics, {
    template: extensionMappingsRowEditorTemplate,
    controllerName: "extensionMappingsRowEditor",
  });
};

_module.directive(
  "extensionMappingsRowEditor",
  extensionMappingsRowEditorDirectiveFactory
);

// Billing Groups Editor
var billingGroupsRowEditorDirectiveFactory = function ($q, $analytics) {
  return new RowEditor($q, $analytics, {
    template: billingGroupsRowEditorTemplate,
    controllerName: "billingGroupsRowEditor",
  });
};

_module.directive(
  "billingGroupsRowEditor",
  billingGroupsRowEditorDirectiveFactory
);

// UAC Security Editor
const userAgentConfigurationSecurityRowEditorDirectiveFactory = function (
  $q,
  $analytics
) {
  return new RowEditor($q, $analytics, {
    template: userAgentConfigurationSecurityRowEditorTemplate,
    controllerName: "userAgentConfigurationSecurityRowEditor",
    allFormsPristineOnSave: false,
  });
};

_module.directive(
  "userAgentConfigurationSecurityRowEditor",
  userAgentConfigurationSecurityRowEditorDirectiveFactory
);
