import _module from 'module.js'

_module.directive('dialableRegexString', function () {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, $input, attrs, ngModelCtrl) {
      ngModelCtrl.$validators.dialableString = val => {
        if (val) {
          let regEx = /[\+\d\(\)\.\-\^\$\* ]+$/g
          return regEx.test(val)
        }
        return true
      }
    }
  }
})
