import _module from 'module.js'

import ListControllerBase from 'classes/listController.class'

export default class RingGroupsController extends ListControllerBase {
  constructor (
    $state,
    $stateParams,
    $location,
    $analytics,
    RingGroup,
    gettextCatalog,
    infiniteLoader,
    navigation,
    globalState,
    portalUtil
  ) {
    'ngInject'
    super(
      gettextCatalog,
      $analytics,
      $location,
      portalUtil,
      globalState,
      navigation,
      $state,
      $stateParams
    )
    this.gettextCatalog = gettextCatalog
    this.title = this.gettextCatalog.getString('Ring Groups')
    this.headers = [
      {
        width: 10,
        name: this.gettextCatalog.getString('Extension'),
        sortBy: 'extensionNumber',
        asc: true
      },
      {
        width: 35,
        name: this.gettextCatalog.getString('Name'),
        sortBy: 'name',
        asc: null
      },
      {
        width: 25,
        name: this.gettextCatalog.getString('Members')
      },
      {
        width: 20,
        name: this.gettextCatalog.getString('Omit from Directory')
      },
      {
        width: 10,
        name: ''
      }
    ]
    this.buttonText = this.gettextCatalog.getString('Add Ring Group')
    this.loader = infiniteLoader(RingGroup)
  }

  delete (event, ringGroup) {
    let name =
      this.gettextCatalog.getString('ring group') +
      ': ' +
      (ringGroup.extensionNumber
        ? ringGroup.extensionNumber + ': ' + ringGroup.name
        : ringGroup.name)

    this.loader.entityApi.startDelete(event, ringGroup, this.data, name)
  }

  itemSelected (item) {
    super.itemSelected(
      item,
      'root.nav.pbx.extensions.ringgroups.view.general',
      'extId'
    )
  }
}

_module.controller('RingGroupsController', RingGroupsController)
