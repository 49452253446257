import _module from 'module.js'
import portalConfigs from './portalConfigs'
import configureMocks from './configureMocks'
import environments from './environments'

_module.constant('portalConfig', portalConfigs(environments.current))

_module.config(function (tmhDynamicLocaleProvider) {
  'ngInject'
  tmhDynamicLocaleProvider.localeLocationPattern(
    environments.angularI18NPrefix + 'angular-i18n/angular-locale_{{locale}}.js'
  )
})

if (environments.current === 'local') {
  configureMocks()
}
