import _module from 'module.js'

_module.filter('featureFlagEnabled', function () {
  return function (
    items,
    featureFlags,
    featureFlagPropertyName = 'featureFlag'
  ) {
    return items.filter(item => {
      if (!featureFlags || !item[featureFlagPropertyName]) {
        return true
      } else {
        return featureFlags[item[featureFlagPropertyName]]
      }
    })
  }
})
