import _module from 'module.js'

_module.directive('submit', $document => {
  return {
    priority: 10,
    restrict: 'A',
    scope: {
      submit: '&ngClick'
    },
    link: function (scope) {
      this.submitOnEnter = event => {
        if (event.keyCode === 13) {
          scope.submit({
            $event: event
          })
        }
      }
      $document.bind('keydown', this.submitOnEnter)

      scope.$on('$destroy', () => {
        $document.unbind('keydown', this.submitOnEnter)
      })
    }
  }
})
