import _module from 'module.js'
import _ from 'lodash'

export default class ParentFormController {
  constructor () {
    this.forms = []
  }

  addForm (form) {
    this.forms.push(form)
  }

  setPristine (targetForm) {
    targetForm.$setPristine()
    let dirty = _.some(this.forms, form => {
      return form.$dirty
    })
    if (!dirty) {
      this.form.$setPristine()
    }
  }

  setAllFormsPristine () {
    this.forms.forEach(form => form.$setPristine())
    this.form.$setPristine()
  }
}

_module.directive('parentForm', () => {
  return {
    restrict: 'A',
    require: ['form', 'parentForm'],
    controllerAs: 'parentForm',
    bindToController: true,
    controller: ParentFormController,
    link: ($scope, $element, $attrs, ctrls) => {
      let parentForm = ctrls[1]
      parentForm.form = ctrls[0]
    }
  }
})
