import jrnParser from 'classes/jrnParser'

let _jrn, _state, _Group, _filterId, _members, _limit
export default class GetGroupsController {
  constructor ($state, Group) {
    'ngInject'
    this._model = {}
    _state = $state
    _jrn = jrnParser
    _Group = Group
    _filterId = this.filterId
    _members = this.members
    _limit = this.limit

    this.updateIsOverTheLimit()

    this.$onInit = () => {
      this.setViewValue = item => {
        this.ngModel.$setViewValue(item.id)
      }
    }
  }

  api () {
    return {
      get: function (params) {
        params = params || null
        let groupApi = new _Group(null)
        return groupApi.getList(params).then(function (data) {
          return {
            items: data
          }
        })
      }
    }
  }

  map (group) {
    return group
  }

  filter (item) {
    return (
      !(_filterId && _filterId === item.id) &&
      !_members.some(member => {
        return member.id === item.id
      })
    )
  }

  addMember (member) {
    member.path = _state.href('root.nav.pbx.groups.view.general', {
      groupId: _jrn.parse(member.id).getResources()[1] + '/'
    })
    _members.push(member)
    this.updateIsOverTheLimit()
  }

  updateIsOverTheLimit() {
    this.isOverTheLimit = !!_limit && _limit <= _members.length
  }
}
