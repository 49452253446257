/**
 * Defines the map between a referencing class
 * and fields that must be summed to determinate the
 * entity's number of references.
 */
const refMapping = {}

const sameAs = field => [...refMapping[field]]

// e.g. here we must sum fields below to have
// a 'HoldMusicPlaylist entity' references number
refMapping.HoldMusicPlaylist = [
  'callQueuesHoldMusic',
  'didsHoldMusic',
  'extensionsRingback',
  'linesHoldMusic',
  'pbxHoldMusic'
]
refMapping.Music = ['playlists']
refMapping.SoundClip = [
  'dialPlans',
  'callQueuesUsingClipInPreCall',
  'callQueuesUsingClipInPerodicAnouncement',
  'callFilters'
]

refMapping.CorporateDirectory = ['pbxs']
refMapping.Directory = sameAs('CorporateDirectory')

refMapping.Extension = [
  'extensionsUsingSharedVoicemailBox',
  'lines',
  'dialplans',
  'ringGroups',
  'directories',
  'userAgentConfigurations',
  'dids',
  'callFilters',
  'callQueues',
  'extMappings'
]
refMapping.Line = sameAs('Extension')
refMapping.RingGroup = sameAs('Extension')
refMapping.CallQueue = sameAs('Extension')
refMapping.ConferenceRoom = sameAs('Extension')
refMapping.DialPlan = sameAs('Extension')
refMapping.SharedVoicemailBox = sameAs('Extension')
refMapping.VirtualFaxMachine = sameAs('Extension')

refMapping.Did = ['userAgents', 'lines', 'dialPlans', 'address', 'callerIdOrganization']
refMapping.PagingProfile = ['userAgentConfigurations']
refMapping.PagingRealm = ['pagingProfiles']
refMapping.Device = ['callQueues']
refMapping.Hardware = ['userAgentConfigurations']
refMapping.User = [
  'userAgentConfigurations',
  'virtualFaxMachines',
  'userGroups'
]
refMapping.Group = ['userGroups']
refMapping.Schedule = ['dialplans']

refMapping.Proxy = ['userAgentConfigurations', 'pbxs']
refMapping.OutboundProxy = sameAs('Proxy')

refMapping.BillingGroup = ['userAgentConfigurations', 'dids']

refMapping.Site = ['userAgentConfigurations']

refMapping.MasterDid = ['dialplans', 'lines', 'userAgents', 'pbx', 'site']

export default (referencingClass, references) => {
  const fields = refMapping[referencingClass] || []
  if (!fields.length) {
    return -1
  }
  return fields.reduce(
    (acc, field) =>
      acc + (references[field]
        ? Array.isArray(references[field])
          ? references[field].length
          : 1
        : 0),
    0
  )
}
