import _module from 'module.js'
import e911CarouselTemplate from '../views/common/e911CarouselTemplate.html'
import _ from 'lodash'

let pathToRedirect

class E911CarouselPopupController {
  constructor (
    gettextCatalog,
    $mdDialog,
    globalState,
    portalConfig
  ) {
    'ngInject'
    this.gettextCatalog = gettextCatalog
    this.isWhiteLabel = globalState.isWhiteLabel
    this.portalConfig = portalConfig

    this.slides = [
    {
      id: 0,
      image: this.portalConfig.e911Carousel1,
      title: this.gettextCatalog.getString('Define your emergency locations'),
      text: this.gettextCatalog.getString('Register addresses and callback numbers to provide accurate information for first responders in the case of an emergency.'),
      link: {
        text:this.gettextCatalog.getString('Go to emergency locations'),
        path: 'emergency-locations',
        sref: 'root.nav.pbx.sitesv2.list.locations'
      }
    },
    {
      id: 1,
      image: this.portalConfig.e911Carousel2,
      title: this.gettextCatalog.getString('Location service'),
      text: this.gettextCatalog.getString('Easily maintain accurate addresses in a centralized service so you don’t have to repeat any work when you need to re-use an address.'),
      link: {
        text:this.gettextCatalog.getString('Go to locations'),
        path: 'locations',
        sref: 'root.nav.pbx.locations'
      }
    },
    {
      id: 2,
      image: this.portalConfig.e911Carousel3,
      title: this.gettextCatalog.getString('Associate your devices'),
      text: this.gettextCatalog.getString('Assign your devices to emergency locations so that the right information is sent to first responders in the case of an emergency call.'),
      link: {
        text:this.gettextCatalog.getString('Go to device settings'),
        path: 'emergency-locations/devices',
        sref: 'root.nav.pbx.sitesv2.list.devices'
      }
    }]

    this.$mdDialog = $mdDialog
  }

  complete (path) {
    pathToRedirect = path
    this.$mdDialog.cancel()
  }

  next () {
    if (this.active < (this.slides.length - 1)) {
      this.active++;
    }
  }

  prev () {
    if(this.active > 0) {
      this.active--;
    }
  }

}

class E911CarouselPopup {
  constructor (
    $mdDialog,
    $analytics,
    Jbcs,
    gettextCatalog,
    globalState,
    portalConfig
  ) {
    'ngInject'
    this.$mdDialog = $mdDialog
    this.$analytics = $analytics
    this.Jbcs = Jbcs
    this.globalState = globalState
    this.gettextCatalog = gettextCatalog
    this.portalConfig = portalConfig
  }

  open () {
    return this.$mdDialog.show({
      template: e911CarouselTemplate,
      controllerAs: 'e911CarouselCtrl',
      controller: E911CarouselPopupController,
      locals: {
        $mdDialog: this.$mdDialog,
        gettextCatalog: this.gettextCatalog,
        globalState: this.globalState,
        portalConfig: this.portalConfig
      },
      parent: angular.element(document.body),
      clickOutsideToClose: true,
      escapeToClose: true,
      fullscreen: true,
      onRemoving: () => {
        if (pathToRedirect) {
          this.globalState.history.push(`/${this.globalState.selectedPbx.domain}/${pathToRedirect}`)
          pathToRedirect = null
        }
      }
    })
  }
}

_module.service('e911CarouselPopup', E911CarouselPopup)
