/**
 * @ngdoc function
 * @name platformApp.factory:Network
 * @description
 * # Network
 * Service of the platformApp
 */
import _module from 'module.js'

import BaseES6 from 'factories/baseES6'

let _stateParams, _portalApi, _globalState

export default class NetworkService extends BaseES6 {
  constructor (id, network, pbxId, gettextCatalog) {
    'ngInject'
    super(id, pbxId || _globalState.selectedPbxUUID)
    this.gettextCatalog = gettextCatalog

    this.permissions = []

    if (network) {
      this._map(network)
    }
  }

  getApi () {
    if (!this._api) this._api = _portalApi.pbx.pbxs(this._pbxId).one('networks')

    return () => {
      return this._api
    }
  }

  getPath () {
    if (!this._path) { this._path = _portalApi.pbx.pbxs(this._pbxId).one('networks', this._id) }

    return this._path
  }

  map (data) {
    if (data.expiration) {
      this.expiration = new Date(data.expiration)
    }
  }

  mapBatchData (data) {
    if (data.expiration) {
      data.expiration = new Date(data.expiration)
    }
    return data
  }

  build (payload) {
    if (this.expiration) {
      payload.expiration = new Date(this.expiration)
    }
    return payload
  }

  getClass () {
    return 'Network'
  }

  static factory ($stateParams, portalApi, gettextCatalog, globalState) {
    'ngInject'
    _stateParams = $stateParams
    _portalApi = portalApi
    _globalState = globalState

    return (id, network, pbxId = null) => {
      return new NetworkService(id, network, pbxId, gettextCatalog)
    }
  }
}

_module.factory('Network', NetworkService.factory)
