import { getJrnDefaultComponent } from '../getJrn/getJrnDefaultComponent.js'
import getSoundClipController from './getSoundClipController.js'
import _module from 'module.js'
import _ from 'lodash'

let getSoundClipComponent = _.cloneDeep(getJrnDefaultComponent)
getSoundClipComponent.controller = getSoundClipController
getSoundClipComponent.bindings.onCreate = '&'

_module.component('getSoundClip', getSoundClipComponent)
