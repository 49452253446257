import _module from 'module.js'

const MAX_LONG = 12345678901234567890123456

_module.directive('jbcPriorityNumber', function () {
  'ngInject'
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, $input, attrs, ngModelCtrl) {
      'ngInject'
      ngModelCtrl.$validators.jbcPriorityNumber = () => {
        return (
          $input.val().length === 0 ||
          (/^[0-9]{1,25}$/.test($input.val()) &&
            parseInt($input.val(), 10) <= MAX_LONG)
        )
      }
    }
  }
})
