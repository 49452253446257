import _ from 'lodash'

export default class JvListItemsController {
  constructor (
    $scope,
    $element,
    $attrs,
    gettextCatalog,
    confirmationWindowWarn
  ) {
    'ngInject'
    this.$scope = $scope
    this.$element = $element
    this.$attrs = $attrs
    this.gettextCatalog = gettextCatalog
    this.confirmationWindowWarn = confirmationWindowWarn
  }

  remove (item) {
    this.confirmationWindowWarn
      .show(
        this.gettextCatalog.getString('Confirm Remove'),
        this.gettextCatalog.getString(
          'Are you sure you want to remove {{item.text}}?',
          { item: item }
        ),
        this.gettextCatalog.getString('Remove')
      )
      .then(() => {
        _.remove(this.items, i => {
          return i.id === item.id
        })
        if (this.onRemove) {
          this.onRemove({
            item: item,
            items: this.items
          })
        }
      })
  }

  selectItem (item) {
    if (this.$attrs.hasOwnProperty('highlightActive')) {
      this.setAsActive(item)
    }

    if (this.onSelect) {
      this.onSelect({
        item: item,
        items: this.items
      })
    }
  }

  setAsActive (active) {
    this.items.forEach(item => {
      item.active = false
      if (item.id === active.id) {
        item.active = true
      }
    })
  }
}
