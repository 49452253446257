import _module from 'module.js'
import ExtensionNotRecommendedTemplate
  from '../views/common/extensionNotRecommendedTemplate.html'

class ExtensionNotRecommendedController {
  constructor (
    $analytics,
    gettextCatalog,
    $mdDialog,
  ) {
    'ngInject'
    this.gettextCatalog = gettextCatalog
    this.$mdDialog = $mdDialog
  }

  complete () {
    this.$mdDialog.hide(true)
  }

  cancel () {
    this.$mdDialog.cancel()
  }
}

class ExtensionNotRecommendedPopup {
  constructor (
    $mdDialog,
    $analytics,
    gettextCatalog,
  ) {
    'ngInject'
    this.$mdDialog = $mdDialog
    this.$analytics = $analytics
    this.gettextCatalog = gettextCatalog
  }

  open () {
    return this.$mdDialog.show({
      template: ExtensionNotRecommendedTemplate,
      controllerAs: 'ctrl',
      controller: ExtensionNotRecommendedController,
      locals: {
        $mdDialog: this.$mdDialog,
        gettextCatalog: this.gettextCatalog,
        $analytics: this.$analytics,
      },
      parent: angular.element(document.body),
      clickOutsideToClose: true,
      fullscreen: true
    })
  }
}

_module.service('extensionNotRecommendedPopup', ExtensionNotRecommendedPopup)
