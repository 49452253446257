import inputLabelTemplate from './inputLabelTemplate.html'
import _module from 'module.js'

_module.component('inputLabel', {
  template: inputLabelTemplate,
  transclude: true,
  bindings: {
    label: '@',
    beta: '@',
    helpText: '@'
  }
})
