import _module from 'module.js'
import { LMI_ADMIN_PORTAL_TOKEN } from '../services/jiveOAuthClient'

export default class ImportExportTool {
  constructor (
    globalState,
    portalApi,
    portalUtil,
    gettextCatalog,
    FileSaver,
    $window
  ) {
    'ngInject'
    this.globalState = globalState
    this.portalApi = portalApi
    this.portalUtil = portalUtil
    this.gettextCatalog = gettextCatalog
    this.fileSaver = FileSaver
    this.$window = $window
  }

  bindExport (ctrl, pbxId = null) {
    ctrl.export = type => this._export(ctrl, type, pbxId)
  }

  bindImport (ctrl, types, pbxId = null) {
    this._bindImportDelete(ctrl, 'import', types, pbxId)
  }

  bindDelete (ctrl, types, pbxId = null) {
    this._bindImportDelete(ctrl, 'delete', types, pbxId)
  }

  _bindImportDelete (ctrl, meth, types, pbxId = null) {
    types.forEach(type => {
      const methName = meth + type[0].toUpperCase() + type.slice(1)
      if (meth === 'delete') {
        type += 'Delete'
      }
      ctrl[methName] = file => this['_' + meth](ctrl, type, file, pbxId)
    })
  }

  _import (ctrl, type, file, pbxId = null) {
    this._importOrDelete(ctrl, type, file, pbxId)
  }

  _delete (ctrl, type, file, pbxId = null) {
    this._importOrDelete(ctrl, type, file, pbxId)
  }

  _getApi (type, pbxId = null) {
    return pbxId ? this.portalApi.tool.pbxs(pbxId)[type] : this.portalApi.tool.pbxs(this.globalState.selectedPbxUUID)[type]
  }

  async _export (ctrl, type, pbxId = null) {
    ctrl.busy = true;
    const api = this._getApi(type, pbxId);

    if (type === "userAgents") {
      type = "hardware";
    }
    let filename = type + ".csv";

    if (pbxId) {
      filename = pbxId + "-" + filename;
    } else if (this.globalState.selectedPbx && this.globalState.selectedPbx.domain) {
      filename = this.globalState.selectedPbx.domain + "-" + filename;
    }

    const url = `${api().getRequestedUrl()}?language=en-US`;
    // https://jira.ops.expertcity.com/browse/ADMIN-12283 internal tools exports
    // only in English for export to import functionality

    const oauthToken = JSON.parse(
      localStorage.getItem(LMI_ADMIN_PORTAL_TOKEN) || localStorage.getItem('jive-foken')
    ).token

    fetch(url, {
      method: 'GET',
      headers: new Headers({
        'Authorization': `Bearer ${oauthToken}`
      }),
    }).then(res => res.blob()) // Get Blob from response
    .then(blob => {
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = filename;
      downloadLink.style.display = 'none';

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }).catch((error) => {
      this.portalUtil.showErrorAlert(
        this.gettextCatalog.getString("File download failed, please retry.")
      );
      throw error;
    }).finally(() => {
      ctrl.busy = false;
    });
  }

  _importOrDelete (ctrl, type, file, pbxId) {
    ctrl.busy = true
    const api = this._getApi(type, pbxId)
    const frw = new FileReader()
    frw.onload = async () => {
      try {
        const csvResult = await api()
          .withHttpConfig({
            transformRequest: angular.identity
          })
          .customPOST(frw.result, undefined, undefined, {
            'Content-Type': "application/octet-stream"
          })
        const data = new Blob([csvResult], {
          type: 'text/plain;charset=utf-8'
        })
        if (type === 'userAgents') {
          type = 'hardware'
        }
        this.fileSaver.saveAs(data, type + '.result.csv')
      } catch (err) {
        if (err.status == 415) {
          this.portalUtil.showAlert(
            this.gettextCatalog.getString('File import error'),
            this.gettextCatalog.getString('Imported file is not encoded as UTF-8 so it cannot be processed. Make sure to select the UTF-8 format when saving your files. <a href="https://link.gotoconnect.com/csv-format" target="_blank">See the how to format CSV guide.</a>'),
            true
          )
        } else {
          this.portalUtil.showErrorAlert(
            this.gettextCatalog.getString('File upload failed, please retry.')
          )
        }
        throw err
      } finally {
        ctrl.busy = false
        if (type === 'organizations' || type === 'organizationsState' ) {
          this.$window.location.reload()
        }
      }
    }
    frw.readAsArrayBuffer(file)
  }
}

_module.service('importExportTool', ImportExportTool)
