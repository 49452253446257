import _module from 'module.js'
import ListControllerBase from 'classes/listController.class'
import jrnParser from 'classes/jrnParser'

export default class OutboundProxiesController extends ListControllerBase {
  constructor (
    $state,
    $stateParams,
    $location,
    $analytics,
    OutboundProxies,
    gettextCatalog,
    infiniteLoader,
    navigation,
    globalState,
    portalUtil,
    usagePopup
  ) {
    'ngInject'
    super(
      gettextCatalog,
      $analytics,
      $location,
      portalUtil,
      globalState,
      navigation,
      $state,
      $stateParams
    )

    this.gettextCatalog = gettextCatalog
    this.jrn = jrnParser
    this.usagePopup = usagePopup
    this.OutboundProxies = OutboundProxies

    this.title = this.gettextCatalog.getString('Outbound Proxies')

    this.headers = [
      {
        width: 30,
        name: this.gettextCatalog.getString('Address'),
        sortBy: 'address',
        asc: null
      },
      {
        width: 30,
        name: this.gettextCatalog.getString('Label'),
        sortBy: 'label',
        asc: true
      },
      {
        width: 20,
        name: this.gettextCatalog.getString('Protocol'),
        sortBy: 'transportProtocol',
        asc: null
      },
      {
        width: 10,
        name: ''
      }
    ]

    this.buttonText = this.gettextCatalog.getString('Add Outbound Proxy')
    this.loader = infiniteLoader(OutboundProxies)
  }

  delete (event, proxy) {
    let name =
      this.gettextCatalog.getString('outbound proxy') + ': ' + proxy.address
    this.loader.entityApi.startDelete(event, proxy, this.data, name)
  }

  getUsage (event, item) {
    let name =
      this.gettextCatalog.getString('Outbound Proxy') + ': ' + item.name
    let id = this.jrn.parse(item.jrn).getResources()[1]
    this.usagePopup
      .open(event, name, item, new this.OutboundProxies(id, item))
      .then(() => {}, () => {})
      .catch(error => {
        this.portalUtil.showErrorAlert(
          this.gettextCatalog.getString('Query failed, please retry.')
        )
        throw error
      })
  }

  itemSelected () {}
}

_module.controller('OutboundProxiesController', OutboundProxiesController)
