import lineAssignmentTemplate from './lineAssignmentTemplate.html'
import lineAssignmentController from './lineAssignmentController.js'
import module from 'module'

module.component('lineAssignment', {
  template: lineAssignmentTemplate,
  controllerAs: 'lineAssignment',
  bindings: {
    configs: '=ngModel',
    doSave: '&'
  },
  require: {
    ngModel: 'ngModel'
  },
  controller: lineAssignmentController
})
