import rowItemTemplate from './rowItemTemplate.html'
import _module from 'module.js'

_module.directive('rowItem', function () {
  return {
    restrict: 'A',
    require: ['^rowEditor', 'rowItem'],
    trasclude: true,
    template: rowItemTemplate,
    scope: {
      ngModel: '=',
      type: '@',
      required: '@?'
    },
    controller: function () {
      'ngInject'
      this.editing = false
      this.template = null
    },
    controllerAs: 'rowItem',
    bindToController: true,
    link: function (scope, el, attrs, ctrls) {
      let rowEditor = ctrls[0]
      let rowItem = ctrls[1]

      if (!attrs.type) {
        throw new Error('Missing attr: "type"')
      }

      rowItem.required = false
      if (attrs.required !== undefined) {
        rowItem.required = true
      }

      let templatesUrl = '/scripts/directives/rowItem/templates/'
      rowItem.template = templatesUrl + attrs.type + '.html'

      rowEditor.register(isEditing => {
        rowItem.editing = isEditing
      })
    }
  }
})
