import moment from 'moment-timezone'
import _ from 'lodash'

export default class TimeZoneSelectController {
  constructor (gettextCatalog, pbxUtil, $log) {
    'ngInject'
    let disallowedTimeZones = [
      'CET',
      'CST6CDT',
      'Cuba',
      'EET',
      'Egypt',
      'Eire',
      'EST',
      'EST5EDT',
      'GB',
      'GB-Eire',
      'GMT',
      'GMT+0',
      'GMT-0',
      'GMT0',
      'Greenwich',
      'Hongkong',
      'Iceland',
      'Iran',
      'Israel',
      'Jamaica',
      'Japan',
      'Kwajalein',
      'Libya',
      'MET',
      'MST',
      'MST7MDT',
      'Navajo',
      'NZ',
      'NZ-CHAT',
      'Poland',
      'Portugal',
      'PRC',
      'PST8PDT',
      'ROC',
      'ROK',
      'Singapore',
      'Turkey',
      'UCT',
      'Universal',
      'UTC',
      'W-SU',
      'WET',
      'Zulu',
      'America/Fort_Nelson',
      'Antarctica/Troll',
      'Asia/Atyrau',
      'Asia/Barnaul',
      'Asia/Chita',
      'Asia/Famagusta',
      'Asia/Riyadh87',
      'Asia/Riyadh88',
      'Asia/Riyadh89',
      'Asia/Srednekolymsk',
      'Asia/Tomsk',
      'Asia/Yangon',
      'Europe/Astrakhan',
      'Europe/Kirov',
      'Europe/Saratov',
      'Europe/Ulyanovsk',
      'Mideast/Riyadh87',
      'Mideast/Riyadh88',
      'Mideast/Riyadh89',
      'US/Pacific-New',
      'Africa/Asmera',
      'America/Virgin',
      'ETC/UCT'
    ]

    this.gettextCatalog = gettextCatalog
    this.isFocused = false
    this.q = ''
    this.originalTZs
    this.timeZones = []

    // Create the data structure for timezones and cache them, filter when
    // the user inputs query

    pbxUtil
      .getValidTimeZones()
      .then(validTimeZones => {
        this.timeZones = moment.tz
          .names()
          .filter(name => {
            return (
              !disallowedTimeZones.includes(name) &&
              validTimeZones.includes(name)
            )
          })
          .map(name => {
            return {
              id: name,
              text: this.gettextCatalog.getString(name),
              offset: moment.tz(name).format('Z z')
            }
          })

        if (!this.noDefault) {
          this.timeZones.unshift({
            id: null,
            offset: '',
            text: gettextCatalog.getString('PBX Default')
          })
        }

        this.originalTZs = _.clone(this.timeZones)

        if (this.autoFocus) {
          this.isFocused = true
        }
      })
      .catch(error => {
        $log.error(`Failed to lookup Time Zones`, error)
      })
  }

  getTimeZones () {
    if (!this.q) {
      this.timeZones = _.clone(this.originalTZs)
      return
    }

    let escapedQuery = _.escapeRegExp(this.q)

    let regex = new RegExp(escapedQuery, 'gi')
    this.timeZones = this.originalTZs.filter(name => {
      return name.text.match(regex) || name.offset.match(regex)
    })
  }

  clear () {
    this.q = ''
    this.getTimeZones()
  }

  toggleFocus () {
    this.isFocused = !this.isFocused
  }

  select (item) {
    this.toggleFocus()

    this.clear()
    this.ngModel.$setViewValue(item)
  }
}
