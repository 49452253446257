import _module from 'module.js'
import jrnParser from 'classes/jrnParser'
import ListControllerBase from 'classes/listController.class'

export default class ExtensionsController extends ListControllerBase {
  constructor (
    $state,
    $stateParams,
    $location,
    $analytics,
    Extension,
    gettextCatalog,
    infiniteLoader,
    navigation,
    globalState,
    portalUtil,
    $window
  ) {
    'ngInject'
    super(
      gettextCatalog,
      $analytics,
      $location,
      portalUtil,
      globalState,
      navigation,
      $state,
      $stateParams
    )

    this.gettextCatalog = gettextCatalog
    this.portalUtil = portalUtil
    this.window = $window

    this.title = this.gettextCatalog.getString('Extensions Overview')

    this.headers = [
      {
        width: 10,
        name: this.gettextCatalog.getString('Extension'),
        sortBy: 'extensionNumber',
        asc: true
      },
      {
        width: 35,
        name: this.gettextCatalog.getString('Name'),
        sortBy: 'name',
        asc: null
      },
      {
        width: 20,
        name: this.gettextCatalog.getString('Extension Type')
      },
      {
        width: 25,
        name: this.gettextCatalog.getString('Omit from Directory')
      },
      {
        width: 10,
        name: ''
      }
    ]

    this.buttonText = gettextCatalog.getString('Add')

    this.loader = infiniteLoader(Extension)
  }

  getLanguageParamForDialPlanEditor () {
    if (this.globalState.currentLanguage.langCode === 'es-MX') {
      return 'es-AM'
    }
    return this.globalState.currentLanguage.langCode
  }

  delete (event, ext) {
    const name =
      this.gettextCatalog.getString('extension') +
      ': ' +
      (ext.extensionNumber ? ext.extensionNumber + ': ' + ext.name : ext.name)
    this.loader.entityApi.startDelete(event, ext, this.data, name)
  }

  itemSelected (item) {
    const info = jrnParser.parse(item.jrn).getResources()
    switch (info[0]) {
      case 'call-queue':
        super.itemSelected(
          item,
          'root.nav.pbx.extensions.callqueues.view.general',
          'extId'
        )
        break
      case 'conference-room':
        super.itemSelected(
          item,
          'root.nav.pbx.extensions.conferencerooms.view.general',
          'extId'
        )
        break
      case 'line':
        super.itemSelected(
          item,
          'root.nav.pbx.extensions.lines.view.general',
          'extId'
        )
        break
      case 'ring-group':
        super.itemSelected(
          item,
          'root.nav.pbx.extensions.ringgroups.view.general',
          'extId'
        )
        break
      case 'virtual-fax-machine':
        super.itemSelected(
          item,
          'root.nav.pbx.extensions.virtualfaxmachines.view.general',
          'extId'
        )
        break
      case 'shared-voicemail-box':
        super.itemSelected(
          item,
          'root.nav.pbx.extensions.voicemailboxes.view.general',
          'extId'
        )
        break
      case 'dial-plan':
        const url = this.portalUtil.createDialPlanUrl(
          item.jrn,
          item.name,
          item.extensionNumber
        )
        this.window.open(url, '_blank').focus()
        break
      default:
      // do nothing
    }
  }
}

_module.controller('ExtensionsController', ExtensionsController)
