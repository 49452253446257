import _module from 'module.js'
import isUUID from 'classes/isUUID'
import jrnParser from 'classes/jrnParser'
import phoenixTemplate from 'controllers/phoenix/phoenixTemplate.html'
import itemTemplate from 'controllers/item/itemTemplate.html'
import devicesTemplate from 'controllers/devices/devicesTemplate.html'
import deviceSettingsTemplate from 'controllers/device/deviceSettings/deviceSettingsTemplate.html'
import deviceHardwareTemplate from 'controllers/device/deviceHardware/deviceHardwareTemplate.html'
import linesAndButtonsTemplate from 'controllers/device/deviceLinesAndButtons/linesAndButtonsTemplate.html'
import deviceE911Template from 'controllers/device/deviceE911/deviceE911Template.html'
import deviceUsageTemplate from 'controllers/device/deviceUsage/deviceUsageTemplate.html'
import deviceHiddenTemplate from 'controllers/device/deviceHidden/deviceHiddenTemplate.html'
import hardwaresTemplate from 'controllers/hardwares/hardwaresTemplate.html'
import hardwareGeneralTemplate from 'controllers/hardware/hardwareGeneral/hardwareGeneralTemplate.html'
import pagingProfilesTemplate from 'controllers/pagingProfiles/pagingProfilesTemplate.html'
import pagingProfileGeneralTemplate from 'controllers/pagingProfile/general/pagingProfileGeneralTemplate.html'
import pagingProfilePolycomTemplate from 'controllers/pagingProfile/polycom/pagingProfilePolycomTemplate.html'
import pagingProfileUsageTemplate from 'controllers/pagingProfile/usage/pagingProfileUsageTemplate.html'
import pagingRealmsTemplate from 'controllers/pagingRealms/pagingRealmsTemplate.html'
import pagingRealmGeneralTemplate from 'controllers/pagingRealm/general/pagingRealmGeneralTemplate.html'
import pagingRealmPolycomTemplate from 'controllers/pagingRealm/polycom/pagingRealmPolycomTemplate.html'
import pagingRealmProfilesTemplate from 'controllers/pagingRealm/profiles/pagingRealmProfiles.html'
import environments from '../configs/environments'

const basePath = environments.current === 'local' ? '/images' : '/pbx/images'

_module.config((gettext, $stateProvider, FEATURE_FLAGS) => {
  'ngInject'

  $stateProvider
    .state('root.nav.pbx.devices', {
      url: '/devices',
      abstract: true,
      template: '<ui-view class="devices-abstract max-height"></ui-view>'
    })
    .state('root.nav.pbx.devicesv2', {
      url: '/devices-v2',
      template: phoenixTemplate,
      controller: 'PhoenixController as ctrl',
    })
    .state('root.nav.pbx.devicesv2.view', {
      url: '/:deviceId/:tab',
      template: phoenixTemplate,
      controller: 'PhoenixController as ctrl',
    })
    .state('root.nav.pbx.devices.list', {
      url: '?q&sort&direction&source',
      template: devicesTemplate,
      controller: 'DevicesController as ctrl',
      reloadOnSearch: false
    })
    .state('root.nav.pbx.devices.view', {
      url: '/:deviceId',
      template: itemTemplate,
      controller: 'DeviceController as ctrl',
      resolve: {
        deviceData: (Device, $stateParams, globalState, portalApi) => {
          if (globalState.selectedPbxUUID) {
            return Device(
              $stateParams.deviceId,
              null,
              globalState.selectedPbxUUID
            ).get()
          }
          if (isUUID($stateParams.id)) {
            return Device($stateParams.deviceId, null, $stateParams.id).get()
          }
          return portalApi.pbx
            .pbxs('domain')
            .one($stateParams.id)
            .get()
            .then(pbxJrn => {
              return Device(
                $stateParams.deviceId,
                null,
                jrnParser.parse(pbxJrn).getAccount()
              ).get()
            })
        }
      },
      abstract: true,
      onEnter: function ($stateParams, $state) {
        if (!$stateParams.deviceId) {
          $state.go('pbx')
        }
      },
      data: {
        title: 'Device',
        itemIcon: `${basePath}/voip.svg`,
        tabs: [
       
          {
            id: 'device-settings',
            label: gettext('Settings'),
            state: 'root.nav.pbx.devices.view.settings'
          },
          {
            id: 'device-hardware',
            label: gettext('Hardware'),
            state: 'root.nav.pbx.devices.view.hardware'
          },
          {
            id: 'device-button-configuration',
            label: gettext('Button Configuration'),
            state: 'root.nav.pbx.devices.view.linesAndButtons',
            settingEnabled: ['showButtonConfiguration']
          },
          {
            id: 'device-usage',
            label: gettext('Usage'),
            state: 'root.nav.pbx.devices.view.usage'
          },
          {
            id: 'device-e911',
            label: gettext('Emergency Location'),
            state: 'root.nav.pbx.devices.view.e911',
            settingEnabled: ['isDeviceEmergencyLocationEnabled']
          }
        ],
        hiddenTab: [
          {
            id: 'device-hidden',
            label: gettext('Hidden Options'),
            state: 'root.nav.pbx.devices.view.hidden'
          }
        ]
      }
    })
    .state('root.nav.pbx.devices.view.settings', {
      url: '/settings',
      template: deviceSettingsTemplate
    })
    .state('root.nav.pbx.devices.view.hardware', {
      url: '/hardware',
      template: deviceHardwareTemplate
    })
    .state('root.nav.pbx.devices.view.linesAndButtons', {
      url: '/lines-buttons',
      template: linesAndButtonsTemplate
    })
    .state('root.nav.pbx.devices.view.e911', {
      url: '/e911',
      template: deviceE911Template
    })
    .state('root.nav.pbx.devices.view.usage', {
      url: '/usage',
      template: deviceUsageTemplate
    })
    .state('root.nav.pbx.devices.view.hidden', {
      url: '/hidden-options',
      template: deviceHiddenTemplate,
      controller: 'DeviceHiddenController as ctrl'
    })
    .state('root.nav.pbx.devices.hardware', {
      url: '/hardware',
      abstract: true,
      template: '<ui-view></ui-view>'
    })
    .state('root.nav.pbx.devices.hardware.list', {
      url: '?q&sort&direction&source',
      template: hardwaresTemplate,
      controller: 'HardwaresController as ctrl',
      reloadOnSearch: false
    })
    .state('root.nav.pbx.devices.hardwarev2', {
      url: '/hardware-v2',
      template: phoenixTemplate,
      controller: 'PhoenixController as ctrl',
    })
    .state('root.nav.pbx.devices.hardwarev2.view', {
      url: '/:hardwareId/:tab',
      template: phoenixTemplate,
      controller: 'PhoenixController as ctrl',
    })
    .state('root.nav.pbx.devices.hardware.view', {
      url: '/:hardwareId',
      template: itemTemplate,
      controller: 'HardwareController as ctrl',
      abstract: true,
      data: {
        title: gettext('Hardware'),
        itemIcon: `${basePath}/hardphone.svg`,
        tabs: [
          {
            id: 'hardware-general',
            label: gettext('General'),
            state: 'root.nav.pbx.devices.hardware.view.general'
          }
        ]
      }
    })
    .state('root.nav.pbx.devices.hardware.view.general', {
      url: '/general',
      template: hardwareGeneralTemplate
    })
    .state('root.nav.pbx.devices.profiles', {
      url: '/profiles',
      abstract: true,
      template: '<ui-view></ui-view>'
    })
    .state('root.nav.pbx.devices.profilesv2', {
      url: '/profiles-v2',
      template: phoenixTemplate,
      controller: 'PhoenixController as ctrl',
    })
    .state('root.nav.pbx.devices.profilesv2.view', {
      url: '/:pagingProfileId/:tab',
      template: phoenixTemplate,
      controller: 'PhoenixController as ctrl',
    })
    .state('root.nav.pbx.devices.profiles.list', {
      url: '?q&sort&direction&source',
      template: pagingProfilesTemplate,
      controller: 'PagingProfilesController as ctrl',
      reloadOnSearch: false
    })
    .state('root.nav.pbx.devices.profiles.view', {
      url: '/:profileId',
      template: itemTemplate,
      controller: 'PagingProfileController as ctrl',
      abstract: true,
      data: {
        title: gettext('Paging Profile'),
        itemIcon: `${basePath}/loud-speaker.svg`,
        tabs: [
          {
            id: 'profile-general',
            label: gettext('General'),
            state: 'root.nav.pbx.devices.profiles.view.general'
          },
          {
            id: 'profile-polycom',
            label: gettext('Polycom'),
            state: 'root.nav.pbx.devices.profiles.view.polycom'
          },
          {
            id: 'profile-usage',
            label: gettext('Usage'),
            state: 'root.nav.pbx.devices.profiles.view.usage'
          }
        ]
      }
    })
    .state('root.nav.pbx.devices.profiles.view.general', {
      url: '/general',
      template: pagingProfileGeneralTemplate
    })
    .state('root.nav.pbx.devices.profiles.view.polycom', {
      url: '/polycom',
      template: pagingProfilePolycomTemplate
    })
    .state('root.nav.pbx.devices.profiles.view.usage', {
      url: '/usage',
      template: pagingProfileUsageTemplate
    })
    .state('root.nav.pbx.devices.realms', {
      url: '/realms',
      abstract: true,
      template: '<ui-view></ui-view>'
    })
    .state('root.nav.pbx.devices.realmsv2', {
      url: '/realms-v2',
      template: phoenixTemplate,
      controller: 'PhoenixController as ctrl',
    })
    .state('root.nav.pbx.devices.realmsv2.view', {
      url: '/:pagingRealmId/:tab',
      template: phoenixTemplate,
      controller: 'PhoenixController as ctrl',
    })
    .state('root.nav.pbx.devices.realms.list', {
      url: '?q&sort&direction&source',
      template: pagingRealmsTemplate,
      controller: 'PagingRealmsController as ctrl',
      reloadOnSearch: false
    })
    .state('root.nav.pbx.devices.realms.view', {
      url: '/:realmId',
      template: itemTemplate,
      controller: 'PagingRealmController as ctrl',
      abstract: true,
      data: {
        title: gettext('Paging Realm'),
        itemIcon: `${basePath}/cloud.svg`,
        tabs: [
          {
            id: 'realm-general',
            label: gettext('General'),
            state: 'root.nav.pbx.devices.realms.view.general'
          },
          {
            id: 'realm-polycom',
            label: gettext('Polycom'),
            state: 'root.nav.pbx.devices.realms.view.polycom'
          },
          {
            id: 'realm-profiles',
            label: gettext('Profiles'),
            state: 'root.nav.pbx.devices.realms.view.profiles'
          }
        ]
      }
    })
    .state('root.nav.pbx.devices.realms.view.general', {
      url: '/general',
      template: pagingRealmGeneralTemplate
    })
    .state('root.nav.pbx.devices.realms.view.polycom', {
      url: '/polycom',
      template: pagingRealmPolycomTemplate
    })
    .state('root.nav.pbx.devices.realms.view.profiles', {
      url: '/profiles',
      template: pagingRealmProfilesTemplate
    })
})
