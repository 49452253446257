import _module from 'module.js'
import {LMI_ADMIN_PORTAL_TOKEN} from '../../services/jiveOAuthClient'

export default class UnifiedAdminDashboardController {
  constructor(globalState, authService, unifiedAdminDashboardAccessService, $state)
  {
    this.globalState = globalState
    this.authService = authService
    this.isUnifiedAdminDashboardAllowed = false;
    this.state = $state;
    this.isUnifiedAdminDashboardAllowed = unifiedAdminDashboardAccessService.isDashboardAvailable();

    if (this.isUnifiedAdminDashboardAllowed)
    {
      this._setToken()
      this.orgId = this.globalState.selectedPbxUUID
      this.locale = this.globalState.currentLanguage.langCode
      this.error401Handler = this.reAuthenticate.bind(this)

      setTimeout(() => {
        this.readyToRender = true
      }, 0)
    }
    else
    {
      this.goToPbxDashboard(globalState._pbxId)
    }
  }

  goToPbxDashboard(id)
  {
    this.state.go('root.nav.pbx.dashboard', {
      id: id
    })
  }

  reAuthenticate()
  {
    this.authService.reAuthenticate((err, authInfo) => {
      if (authInfo)
      {
        if (authInfo.inflightRequest)
        {
          _window.location.assign(new URL(decodeURIComponent(authInfo.inflightRequest), window.location.toString()).pathname)
        }
        else
        {
          this.readyToRender = false
          this._setToken()
          this.readyToRender = true
        }
      }
    })
  }

  _setToken()
  {
    const {token, jiveId} = JSON.parse(
      localStorage.getItem(LMI_ADMIN_PORTAL_TOKEN) || localStorage.getItem('jive-foken')
    )
    this.oauthToken = token
    this.principal = jiveId
    this.lmiAccountKey = this.globalState.selectedPbx.lmiAccountKey
    this.users = this.globalState.userData.users
  }
}

_module.controller('UnifiedAdminDashboardController', UnifiedAdminDashboardController)
