import _module from 'module.js'

/**
 * @ngdoc service
 * @name platformApp.api
 * @description
 * # auth.dev
 * Factory in the platformApp.
 */
let listeners = []
let deferreds = []
let authData = null

const HOUR = 1000 * 60 * 60

const isExpired = foken => {
  return !foken || !foken.expires || foken.expires < new Date().getTime()
}

const notifyListeners = () => {
  for (let i = 0, l = listeners.length; i < l; i++) {
    listeners[i](authData)
  }
}

const finalizePromises = () => {
  for (let i = 0, l = deferreds.length; i < l; i++) {
    deferreds[i].resolve(authData)
  }
}

const hasAuthData = () => {
  return !!authData
}

let _q, _window

export default class AuthService {
  generateAuthInfo () {
    // foken format: user=username,scopes=pbx.v1.admin,ldap=platform-admin
    let foken = JSON.parse(_window.localStorage.getItem('jive-foken'))
    if (isExpired(foken)) {
      foken = {}
      let fokenString = _window.prompt(
        'Foken Token',
        'user=auburn@example.com,scopes=pbx.v1.admin pbx.v1.platform.administration contact-center-config.v1.bff,ldap=platform-admin'
      )
      let fokenParts = fokenString.split(',')
      for (let i = 0, l = fokenParts.length; i < l; i++) {
        let entry = fokenParts[i].split('=')
        foken[entry[0]] = entry[1]
      }

      foken.expiresIn = HOUR // 1hr

      if (foken.expires && parseInt(foken.expires) < 9999) {
        foken.expires = new Date().setFullYear(foken.expires)
      }

      foken.expires = foken.expires || new Date().getTime() + foken.expiresIn
      foken.jiveId = foken.user
      foken.type = 'Bearer'
      foken.token = 'foken ' + fokenString
      _window.localStorage.setItem('jive-foken', JSON.stringify(foken))
    }
    authData = foken
  }

  doAuth (scopes) {
    authData = null
    this.generateAuthInfo(scopes)
    notifyListeners()
    finalizePromises()
  }

  getAuthInfo (listener) {
    if (listener !== undefined && typeof listener === 'function') {
      listeners.push(listener)
      if (hasAuthData()) {
        listener(authData)
      }
    }

    let deferred = _q.defer()
    if (hasAuthData()) {
      deferred.resolve(authData)
    } else {
      deferreds.push(deferred)
    }
    return deferred.promise
  }

  logout () {
    _window.localStorage.clear()
    _window.location.href = 'https://authentication.logmeininc.com/oauth/logout'
  }

  reAuthenticate (callback) {
    _window.localStorage.clear()
    this.doAuth()
  }

  static factory ($q, $window) {
    'ngInject'
    _q = $q
    _window = $window
    return new AuthService()
  }
}

_module.factory('authService', AuthService.factory)
