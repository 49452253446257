import _module from 'module.js'
import _ from 'lodash'
import moment from 'moment-timezone'
import createHardwareTemplate from './createHardware/createHardwareTemplate.html'
import createHardwareController from './createHardware/createHardwareController.js'
import BaseController from 'classes/baseController'
import jrnParser from 'classes/jrnParser'
import { BILLALBLE_HARDWARE } from './deviceService'
import is from 'is'


const ADDRESS_LINE2_MAX_LENGTH = 20

const OLD_YEALINK_DEVICES = [
  'jrn:user-agent::jive::user-agent-type/yealink.sip.t19p',
  'jrn:user-agent::jive::user-agent-type/yealink.sip.t20p',
  'jrn:user-agent::jive::user-agent-type/yealink.sip.t21p',
  'jrn:user-agent::jive::user-agent-type/yealink.sip.t26p',
  'jrn:user-agent::jive::user-agent-type/yealink.sip.t28p',
  'jrn:user-agent::jive::user-agent-type/yealink.sip.t32g',
  'jrn:user-agent::jive::user-agent-type/yealink.sip.t38g'
]

export default class DeviceController extends BaseController {
  constructor (
    $state,
    $stateParams,
    portalApi,
    Device,
    gettextCatalog,
    gettext,
    portalUtil,
    errorService,
    globalState,
    navigation,
    $mdDialog,
    userService,
    $analytics,
    deviceData,
    $timeout,
    $q,
    User,
    Hardware,
    DEVICE_RESYNC_DELAY,
    hotDeskingService,
    $log,
    PagingProfile,
    featuresService,
    $location,
    deviceFeaturesService,
    portalConfig,
    Site,
    pbxUtil,
    confirmationWindowWarn,
    $rootScope,
    FEATURE_FLAGS,
  ) {
    'ngInject'
    super(
      $state,
      $stateParams,
      Device,
      portalApi,
      userService,
      gettextCatalog,
      portalUtil,
      errorService,
      globalState,
      navigation,
      'root.nav.pbx.devices.list',
      'deviceId'
    )

    this.jrn = jrnParser

    this.$mdDialog = $mdDialog
    this.userService = userService
    this.$analytics = $analytics
    this.$timeout = $timeout
    this.$q = $q
    this.User = User
    this.Device = Device
    this.Hardware = Hardware
    this.hotDeskingService = hotDeskingService
    this.deviceFeaturesService = deviceFeaturesService
    this.$log = $log
    this.PagingProfile = PagingProfile
    this.showBilling = globalState.selectedPbx.billingGroupsEnabled
    this.isSipTrunkEnabled = globalState.selectedPbx.featureFlags[FEATURE_FLAGS.sipTrunks]
    this.$location = $location
    this.featuresService = featuresService
    this.appliedFeatureConfigurations = {}
    this.editableDeviceFeatureConfigurations = {}
    this.portalConfig = portalConfig
    this.Site = Site
    this.$rootScope = $rootScope
    this.confirmationWindowWarn =  confirmationWindowWarn
    this.selectedTab = window.location.href.substring(window.location.href.lastIndexOf('/') + 1)

    if ($state.current.name === 'root.nav.pbx.devices.view.hidden' && !globalState.isPlatform) {
      $state.go('root.nav.pbx.devices.view.settings', $stateParams)
    }

    if ($state.current.name === 'root.nav.pbx.devices.view.e911' && !globalState.isE911Enabled) {
      $state.go('root.nav.pbx.devices.view.settings', $stateParams)
    }

    this.region = globalState.supportedRegions.find(
      ({ code }) => code === globalState.selectedPbx.region
    )

    this.eolIcon = portalConfig.eolIcon
    this.isE911Enabled = globalState.isE911Enabled

    this.loadFeatureFlags()

    this.isWhiteLabel = globalState.isWhiteLabel

    this.jbcLabel = this.isWhiteLabel
      ? this.gettextCatalog.getString('Business Continuity')
      : this.gettextCatalog.getString('JBC')
    this.jbcDisableText = this.isWhiteLabel
      ? this.gettextCatalog.getString(
        'Disable Business Continuity for this device'
      )
      : this.gettextCatalog.getString('Disable JBC for this device')
    this.jbcOrgDefaultText = this.isWhiteLabel
      ? this.gettextCatalog.getString(
        "Use PBX's default business continuity settings"
      )
      : this.gettextCatalog.getString("Use PBX's default JBC settings")
    this.usingJbcOverwrite = this.isWhiteLabel
      ? this.gettextCatalog.getString('Disabled - Using Business Continuity')
      : this.gettextCatalog.getString('Disabled - Using JBC')
    this.jbcConfigured = null
    this.pbxDefaultJbcDisabled = true

    this.multicastMegaVolume = {
      label: this.gettextCatalog.getString('Multicast Mega Volume'),
      helpText: this.gettextCatalog.getString(
        'Significantly increase the volume for received multicast pages on this device. Useful in large rooms or noisy environments.'
      )
    }
    this.pbxDynamicAddressSupported = this.region.emergencyServiceSupported && this.region.dynamicAddressSupported
    this.data = deviceData
    this.data.addressLine2 = deviceData.sublocation && deviceData.sublocation.addressLine2


    this.baseData = deviceData.copy()
    this.isUATypeSipTrunk = !!this.data.userAgentType && this.data.userAgentType.jrn === "jrn:user-agent::jive::user-agent-type/goto.sip_trunk"
    globalState.isUserAgentSipTrunk = this.isUATypeSipTrunk
    this.hideSection = this.isUATypeSipTrunk && this.isSipTrunkEnabled
    this.billingGroupsUseMaster = this.data.billingGroup === null
    this.requireSublocation = globalState._selectedPbx.requireSublocation
    this.sublocationPlaceholder = !this.data._markSublocationNA
      ? ''
      : this.gettextCatalog.getString('Will be marked as “N/A”')
    this.siteChanged(true)

    this.supportedDeviceFeatures = {}
    this.updateSupportedDeviceFeatures()
    this.hotdeskingSupported = false
    this.secureCallingEnabled = !!this.data.secureCallingMode

    this.baseEditableDeviceFeatureConfigurations = {}

    this.unsupportedPolycomPaging = false

    this.baseHotDeskingUserAgentOptions = {}

    this.itemName = this.data.specifiedName

    this.resyncEnabled = this.data.userAgentTypeModel
      ? this.data.userAgentTypeModel.resyncSupported
      : false

    this.isOnline = !!this.data.lineAssignments.find(
      extension => extension.status === 'ONLINE'
    )
    this.hasBillableDevice = this.data.userAgentTypeModel && BILLALBLE_HARDWARE.includes(this.data.userAgentTypeModel.listName.toLowerCase())
    this.selectedHardware = {}

    this.retrieveHardware(this.data.userAgent)

    this.portalApi.userAgent
      .pbxs(this.globalState.selectedPbxUUID)
      .userAgentConfigurations(this.$stateParams.deviceId)
      .one('lowUsage')
      .get()
      .then(data => {
        this.validLowUsage = data
      })

    this.isUpToDate = this.data.userAgentLastCheckin
      ? moment(this.data.userAgentLastCheckin) >= moment(this.data.lastModified)
      : false

    this.loadReferences()

    this.ready = true
    this.initial = false

    this.isResyncing = false
    this.resyncDelay = DEVICE_RESYNC_DELAY

    const knownCustomSettings = {
      'polycom.phone.cfg': {
        name: this.gettextCatalog.getString('Polycom phone.cfg'),
        description: this.gettextCatalog.getString(
          'Configuration changes made on the phone'
        )
      },
      'polycom.web.cfg': {
        name: this.gettextCatalog.getString('Polycom web.cfg'),
        description: this.gettextCatalog.getString(
          'Configuration changes made in the web interface'
        )
      },
      'yealink.local.cfg': {
        name: this.gettextCatalog.getString('Yealink local.cfg'),
        description: this.gettextCatalog.getString(
          'Configuration changes made on the phone or in the web interface'
        )
      }
    }

    this.customSettings = _(this.data.customAttributes)
      .pickBy((value, key) => key.indexOf('_') !== 0)
      .mapValues((value, key) => ({
        key: key,
        info: knownCustomSettings[key] || {
          name: key
            .replace(/[^a-zA-Z0-9]+/g, ' ')
            // title case
            .replace(
              /\w\S*/g,
              txt => txt.charAt(0).toUpperCase() + txt.substr(1)
            ),
          description: ''
        }
      }))
      .values()
      .value()

    this.isPolycomDevice = this.baseData.userAgentType
      ? this.baseData.userAgentType.manufacturerName === 'polycom'
      : false

    this.isFullFeaturedYealinkDevice = this.baseData.userAgentType &&
      this.baseData.userAgentType.jrn
      ? this.baseData.userAgentType.manufacturerName === 'yealink' &&
      OLD_YEALINK_DEVICES.indexOf(this.baseData.userAgentType.jrn) === -1
      : false

    this.isCisco7900Device =
      this.baseData.userAgentType && this.baseData.userAgentType.listName
        ? this.baseData.userAgentType.listName.match(/Cisco (79|89|99)\d\d/, 'gi')
        : false

    this.isYealinkT49Device =
      this.baseData.userAgentType && this.baseData.userAgentType.listName
      ? this.baseData.userAgentType.listName.match(/Yealink SIP-T49G/, 'gi')
      : false

    this.disableSecureCallingOption = this.isYealinkT49Device && !this.secureCallingEnabled;

    this.checkHardwareUpdateDisabled()
      .then(disabled => this.isHardwareUpdateDisabled = disabled)

    this.goToAdminUrl = pbxUtil.getGoToAdminUrl(globalState.selectedPbx)

    this.canAddNewHardware = !globalState.isUserManageSettingsOnly || globalState.isUserManageSeatsOnly
    this.canOpenHardwareLink = !globalState.isUserManageSettingsOnly || globalState.isUserManageSeatsOnly
    this.canOpenPagingProfileLink = !globalState.isUserManageSettingsOnly && !globalState.isUserManageSeatsOnly
    this.canOpenCorporateDirectoryLink = !globalState.isUserManageSettingsOnly && !globalState.isUserManageSeatsOnly

  }

  async loadFeatureFlags () {
    try {
      const featureFlags = await this.featuresService.getAllContextFeatures(
        this.data._pbxId
      )
      this.secureProvisioningEnabled = false
      featureFlags.forEach(feature => {
        if(feature.feature === 'ua.secure-url' || feature.feature === 'ua.encrypt-provisioning') {
          this.secureProvisioningEnabled = this.secureProvisioningEnabled || feature.setting === 'ENABLED'
        }
      })
    } catch (err) {
      this.$log.error(
        `Failed to lookup features for PBX [` + this.data._pbxId + `]`,
        err
      )
    }
  }

  async loadReferences () {
    this.data.usageLoading = true
    const references = await this.data.getReferencesApi().get()
    this.data.usageLoading = false
    if (references && references.callQueues) {
      references.callQueues.forEach(callQueue => {
        callQueue.id = this.jrn.parse(callQueue.jrn).getResources()[1]
      })
      this.data.references = references
    }
  }

  async save (form) {
    this.ready = false
    if (this.billingGroupsUseMaster) {
      this.data.billingGroup = null
    }

    // Normally this kind of logic should go in .build() but we rely on the supported features data here
    if (!this.globalState.isUserManageSettingsOnly && !this.globalState.isUserManageSeatsOnly) {
      if (
        this.secureCallingEnabled &&
        this.supportedDeviceFeatures['security.secureCalling'] &&
        !this.disableSecureCallingOption
      ) {
        if (this.supportedDeviceFeatures['security.opportunisticSrtp']) {
          this.data.secureCallingMode = 'OPTIONAL'
        } else {
          this.data.secureCallingMode = 'REQUIRED'
        }
      } else {
        if (!this.disableSecureCallingOption) {
          this.disableSecureCallingOption = true;
        }
        this.data.secureCallingMode = null
      }
    }

    this.data.specifiedName = this.itemName
    this.data.isNotActivated = this.data.activationDate
      ? moment(this.data.activationDate).isAfter(moment.utc())
      : false
    if (!this.data.specifiedStationLabel) {
      this.data.specifiedStationLabel = this.data.specifiedName
    }

    let acousticFenceSupported = !!this.appliedFeatureConfigurations[
      'polycom.acousticFenceEnable'
    ]
    let isDirectoryFeaturesSupported =
      !!this.appliedFeatureConfigurations['polycom.ldap.backgroundSync'] &&
      !!this.appliedFeatureConfigurations[
        'polycom.ldap.directoryViewPersistence'
      ]

    const updatePromises = []

    const uacPromise = this.data.update()
    updatePromises.push(uacPromise)

    const hotDeskingPromise = (!this.globalState.isUserManageSettingsOnly && !this.globalState.isUserManageSeatsOnly)
      ? this.hotDeskingService.updateUserAgentOptions(
         this.baseHotDeskingUserAgentOptions,
         this.hotDeskingUserAgentOptions
      )
      : Promise.resolve()
    updatePromises.push(hotDeskingPromise)

    const deviceFeatureBackgroundSyncEnabledPromise = isDirectoryFeaturesSupported
      ? this.deviceFeaturesService.setDeviceFeatureConfiguration(
        'polycom.ldap.backgroundSync',
        !!this.editableDeviceFeatureConfigurations[
          'polycom.ldap.backgroundSync'
        ],
        this.data._id
      )
      : Promise.resolve()
    updatePromises.push(deviceFeatureBackgroundSyncEnabledPromise)

    const deviceFeatureDirectoryViewPersistEnabledPromise = isDirectoryFeaturesSupported
      ? this.deviceFeaturesService.setDeviceFeatureConfiguration(
        'polycom.ldap.directoryViewPersistence',
        !!this.editableDeviceFeatureConfigurations[
          'polycom.ldap.directoryViewPersistence'
        ],
        this.data._id
      )
      : Promise.resolve()
    updatePromises.push(deviceFeatureDirectoryViewPersistEnabledPromise)

    let deviceFeatureAcousticFenceEnablePromise = Promise.resolve()
    let deviceFeatureAcousticFenceThresholdPromise = Promise.resolve()
    if (acousticFenceSupported) {
      // When the user disables acoustic fence and then save it this will null out the threshold so when they reload the page it will be the default.
      // They can disable acoustic fence, and then without saving re-enable acoustic fence and it will retain it's value. Also this will null out the value if they delete
      // the value in portal and then click save.
      if (
        this.editableDeviceFeatureConfigurations[
          'polycom.acousticFenceEnable'
        ] === false ||
        this.editableDeviceFeatureConfigurations[
          'polycom.acousticFenceThreshold'
        ] === ''
      ) {
        this.editableDeviceFeatureConfigurations[
          'polycom.acousticFenceThreshold'
        ] = null
      }

      deviceFeatureAcousticFenceEnablePromise = this.deviceFeaturesService.setDeviceFeatureConfiguration(
        'polycom.acousticFenceEnable',
        !!this.editableDeviceFeatureConfigurations[
          'polycom.acousticFenceEnable'
        ],
        this.data._id
      )
      updatePromises.push(deviceFeatureAcousticFenceEnablePromise)

      deviceFeatureAcousticFenceThresholdPromise = this.deviceFeaturesService.setDeviceFeatureConfiguration(
        'polycom.acousticFenceThreshold',
        +this.editableDeviceFeatureConfigurations[
          'polycom.acousticFenceThreshold'
        ],
        this.data._id
      )
      updatePromises.push(deviceFeatureAcousticFenceThresholdPromise)
    }

    const supportsJbc = this.jbcConfigured !== null
    let deviceFeatureJbcNetworkPromise = Promise.resolve()

    if (supportsJbc) {
      let useDeviceDefault = false
      if (this.jbcConfigured === 'NONE') {
        this.editableDeviceFeatureConfigurations['network.jbc'] = null
        useDeviceDefault = true
      }
      if (this.jbcConfigured === 'DEFAULT') {
        this.editableDeviceFeatureConfigurations['network.jbc'] = null
      }

      deviceFeatureJbcNetworkPromise = this.deviceFeaturesService.setDeviceFeatureConfiguration(
        'network.jbc',
        this.editableDeviceFeatureConfigurations['network.jbc'],
        this.data._id,
        useDeviceDefault
      )
    }

    const revert = async () => {
      const revertPromises = []
      const revertUac = uacPromise.then(() =>
        this.baseData.update().catch(revertError => {
          this.$log.error(
            'Failure reverting changes to uac for ' + this.baseData.userAgentId,
            revertError
          )
        })
      )

      revertPromises.push(revertUac)

      if (!this.globalState.isUserManageSettingsOnly && !this.globalState.isUserManageSeatsOnly) {
        const revertHotDesking = hotDeskingPromise.then(() =>
          this.hotDeskingService
            .updateUserAgentOptions(
              this.hotDeskingUserAgentOptions,
              this.baseHotDeskingUserAgentOptions
            )
            .catch(revertError => {
              this.$log.error(
                'Failure reverting changes to hot desk user agent options for ' +
                  this.baseHotDeskingUserAgentOptions.userAgentId,
                revertError
              )
            })
        )
        revertPromises.push(revertHotDesking)
      }

      if (acousticFenceSupported) {
        const revertAcousticFenceEnable = deviceFeatureAcousticFenceEnablePromise.then(
          () =>
            this.deviceFeaturesService
              .setDeviceFeatureConfiguration(
                'polycom.acousticFenceEnable',
                this.baseEditableDeviceFeatureConfigurations[
                  'polycom.acousticFenceEnable'
                ],
                this.data._id
              )
              .catch(revertError => {
                this.$log.error(
                  'Failure reverting changes to acoustic fence enabled for ' +
                    this.baseData.userAgentId,
                  revertError
                )
              })
        )
        revertPromises.push(revertAcousticFenceEnable)

        const revertAcousticFenceThreshold = deviceFeatureAcousticFenceThresholdPromise.then(
          () =>
            this.deviceFeaturesService
              .setDeviceFeatureConfiguration(
                'polycom.acousticFenceThreshold',
                this.baseEditableDeviceFeatureConfigurations[
                  'polycom.acousticFenceThreshold'
                ],
                this.data._id
              )
              .catch(revertError => {
                this.$log.error(
                  'Failure reverting changes to acoustic fence threshold for ' +
                    this.baseData.userAgentId,
                  revertError
                )
              })
        )
        revertPromises.push(revertAcousticFenceThreshold)
      }

      if (isDirectoryFeaturesSupported) {
        const revertBackgroundSyncPromise = deviceFeatureBackgroundSyncEnabledPromise.then(
          () =>
            this.deviceFeaturesService
              .setDeviceFeatureConfiguration(
                'polycom.ldap.backgroundSync',
                this.baseEditableDeviceFeatureConfigurations[
                  'polycom.ldap.backgroundSync'
                ],
                this.data._id
              )
              .catch(revertError => {
                this.$log.error(
                  'Failure reverting changes to background sync for ' +
                    this.baseData.userAgentId,
                  revertError
                )
              })
        )
        revertPromises.push(revertBackgroundSyncPromise)

        const revertDirectoryViewPersistPromise = deviceFeatureDirectoryViewPersistEnabledPromise.then(
          () =>
            this.deviceFeaturesService
              .setDeviceFeatureConfiguration(
                'polycom.ldap.directoryViewPersistence',
                this.baseEditableDeviceFeatureConfigurations[
                  'polycom.ldap.directoryViewPersistence'
                ],
                this.data._id
              )
              .catch(revertError => {
                this.$log.error(
                  'Failure reverting changes to directory view persistence for ' +
                    this.baseData.userAgentId,
                  revertError
                )
              })
        )
        revertPromises.push(revertDirectoryViewPersistPromise)
      }

      if (supportsJbc) {
        const revertJbcNetworkPromise = deviceFeatureJbcNetworkPromise.then(
          () =>
            this.deviceFeaturesService
              .setDeviceFeatureConfiguration(
                'network.jbc',
                this.baseEditableDeviceFeatureConfigurations['network.jbc'],
                this.data._id
              )
              .catch(revertError => {
                this.$log.error(
                  'Failure reverting changes to buisness continuity for ' +
                    this.baseData.userAgentId,
                  revertError
                )
              })
        )
        revertPromises.push(revertJbcNetworkPromise)
      }

      try {
        await this.$q.all(revertPromises)
      } catch (err) {
        console.error(err)
      }
    }

    try {
      const results = await Promise.all([
        uacPromise,
        hotDeskingPromise,
        deviceFeatureAcousticFenceEnablePromise,
        deviceFeatureAcousticFenceThresholdPromise,
        deviceFeatureBackgroundSyncEnabledPromise,
        deviceFeatureDirectoryViewPersistEnabledPromise,
        deviceFeatureJbcNetworkPromise
      ])

      if (!this.globalState.isUserManageSettingsOnly && !this.globalState.isUserManageSeatsOnly) {
        const updatedHotDeskingUserAgentOptions = results[1]
        this.baseHotDeskingUserAgentOptions = _.clone(
          updatedHotDeskingUserAgentOptions
        )

        this.hotDeskingUserAgentOptions = updatedHotDeskingUserAgentOptions
      }

      if (acousticFenceSupported || isDirectoryFeaturesSupported) {
        this.updateAppliedFeatures()
      }

      if (this.data.useUserFullNameAsName) {
        this.updateRelatedInfo()
      }

      this.data._lowUsageDevice = this.data.lowUsageDevice
      if (form) {
        form.$setPristine()
      }

      this.ready = true
    } catch (err) {
      await revert()

      const errorMessage = this.errorService.getErrorMessage(err)
      this.portalUtil.showErrorAlert(
        _.isEmpty(errorMessage)
          ? this.gettextCatalog.getString('Save failed, please retry.')
          : errorMessage.includes('are losing their UACs, which is not allowed.') && this.selectedHardware !== {}
          ? this.getHardwareErrorMessage()
          : errorMessage
      )
      this.ready = true
      throw err
    }
  }

 getHardwareErrorMessage () {
    if (this.selectedHardware.name) {
      return this.gettextCatalog.getString('Device: ' + this.selectedHardware.name + ' is losing its configuration which is not allowed. Please delete the device to reassign the hardware.')
    } else {
     return  this.gettextCatalog.getString('Device with macAddress: ' + this.selectedHardware.macAddress + ' is losing its configuration which is not allowed. Please delete the device to reassign the hardware.')
    }
  }

  saveAndResync (form) {
    return this.save(form).then(() => this.resync())
  }

  saveAndResyncWarning (form) {
    return this.startLineAssignementWarning(form).then(() => this.resync())
  }

  startLineAssignementWarning (form) {
    const dialogTitle = this.gettextCatalog.getString(
      'Do you wish to continue?',
    )
    const warningMessage = this.gettextCatalog.getString(
      'You are about to add an extension on a device that will accrue additional charges to your account per extension.'
    )
    const okText = this.gettextCatalog.getString('Ok')

    if( this.hasBillableDevice && Object.values(this.data.buttons).length > this.baseData.lineAssignments.length){
      return this.confirmationWindowWarn
          .show(dialogTitle, warningMessage, okText)
          .then(() => this.save(form))

    } else {
      return this.save(form)
    }
  }


  deviceButtonsUpdated (config) {
    this.data._map(config)
  }

  /*
   * Hardware tab
   */
  async retrieveHardware (userAgent) {
    if (!userAgent) {
      return
    }

    const id = this.jrn.parse(userAgent).getResources()[1]

    this.hardware = await new this.Hardware(id).get()
    this.data.userAgentTypeModel = this.hardware.type
      ? await this.data.getUserAgent(this.hardware.type.split('/')[1])
      : null

    if (is.not.include(this.$location.host(), 'hvoice')) {
      try {
        await this.featuresService.getAllContextFeatures(
          this.globalState.selectedPbxUUID
        )
      } catch (err) {
        // do nothing here
      }

      if (!this.globalState.isUserManageSettingsOnly && !this.globalState.isUserManageSeatsOnly) {
        this.hotDeskingUserAgentOptions = await this.hotDeskingService.getUserAgentOptions(
          id
        )
        this.baseHotDeskingUserAgentOptions = _.clone(
          this.hotDeskingUserAgentOptions
        )
      }
    }
    this.selectedHardware = this.hardware
  }


  changeHardware () {
    this.retrieveHardware(this.data.userAgent)
  }


  setSelectedTab (tab) {
    return this.selectedTab = tab
  }

  createHardware () {
    return this.$mdDialog
      .show({
        template: createHardwareTemplate,
        controllerAs: 'hardwareCtrl',
        controller: createHardwareController,
        parent: angular.element(document.body),
        clickOutsideToClose: true,
        fullscreen: true
      })
      .then(newHardware => {
        this.data.userAgent = newHardware.jrn
        this.changeHardware()
        return {
          id: newHardware.jrn,
          text: newHardware.macAddress
        }
      })
  }

  resync () {
    this.isResyncing = true
    this.$timeout(() => {
      this.isResyncing = false
    }, this.resyncDelay)

    this.isResyncRequestSent = true
    return this.portalApi.userAgent
      .pbxs(this.globalState.selectedPbxUUID)
      .userAgentConfigurations()
      .one('resync')
      .one(this.$stateParams.deviceId)
      .put()
      .catch(error => {
        this.portalUtil.showErrorAlert(
          this.gettextCatalog.getString(
            'Resync failed, please retry. \n' + error
          )
        )
      })
  }

  async useUserFullNameAsName (flag) {
    if (flag) {
      const userId = this.jrn.parse(this.data.assignedUser).getResources()[1]
      const userApi = new this.User(userId)
      const user = await userApi.get()
      this.itemName =
        (_.isEmpty(user.firstName) ? '' : user.firstName + ' ') + user.lastName
    } else {
      this.itemName = this.data.specifiedName
    }
  }

  async updateRelatedInfo () {
    if (this.data.useUserFullNameAsName) {
      const deviceApi = new this.Device(this.$stateParams.deviceId)
      const device = await deviceApi.get()
      this.itemName = device.specifiedName
      this.data.buttons = device.buttons
    } else {
      this.itemName = this.data.specifiedName
    }
  }



  updateTimeZone (timeZoneSource) {
    let timeZoneToUse = this.data.pbxTimeZone
    if (timeZoneSource === "DEVICE" && this.data._timeZone) {
      timeZoneToUse = this.data._timeZone.id
    } else if (timeZoneSource === "USER"
      && this.data.assignedUser
      && this.data.userTimeZone
      && this.data.useUaTimeZoneSource) {
      timeZoneToUse = this.data.userTimeZone
    } else {
      this.data._timeZoneSource = "PBX"
    }

    this.data.timeZoneToUse = moment()
      .tz(timeZoneToUse)
      .format('z')

    if (timeZoneSource !== "DEVICE") {
      this.data._timeZone = {
        id: timeZoneToUse,
        text: timeZoneToUse,
        offset: moment.tz(timeZoneToUse).format('Z z')
      };
      this.data.timeZone = timeZoneToUse;
    }
  }

  detachUser () {
    if (this.data.timeZoneSource === "USER") {
      this.data.timeZoneSource = "PBX"
    }
    this.updateTimeZone(this.data.timeZoneSource)
  }

  goToUserProfile () {
    if (!this.data.assignedUser) {
      return
    }
    const id = this.jrn.parse(this.data.assignedUser).getResources()[1]
    this.$state.go('root.nav.pbx.users.view.general', {
      id: this.globalState.selectedPbx.domain,
      userId: id
    })
  }

  async pagingProfileChanged (newPagingProfileJrn) {
    if (!newPagingProfileJrn) {
      this.unsupportedPolycomPaging = false
      return
    }

    const id = this.jrn.parse(newPagingProfileJrn).getResources()[1]
    const pagingProfile = await new this.PagingProfile(id).get()
    this.unsupportedPolycomPaging =
      pagingProfile.usePolycomPagingProtocol &&
      !this.userAgentSupportsPolycomPagingProtocol()
  }

  pagingProfileCleared () {
    this.unsupportedPolycomPaging = false
  }

  userAgentSupportsDeviceFeatures () {
    const mTarget = this.data.userAgentType.jrn || this.data.userAgentType
    return !mTarget.match('mobility', 'gi') && !mTarget.match('unknown', 'gi')
  }

  userAgentSupportsPolycomPagingProtocol () {
    if (
      !this.data ||
      !this.data.userAgentType ||
      !this.data.userAgentType.jrn
    ) {
      return false
    }

    const { jrn } = this.data.userAgentType

    return (
      (jrn.toLowerCase().indexOf('yealink') !== -1 && OLD_YEALINK_DEVICES.indexOf(jrn) === -1) ||
      jrn.toLowerCase().indexOf('polycom') !== -1 ||
      jrn.toLowerCase().indexOf('algo.') !== -1
    )
  }

  async updateSupportedDeviceFeatures () {
    if (
        this.globalState.isUserManageSettingsOnly ||
        this.globalState.isUserManageSeatsOnly ||
        !this.data.userAgentType ||
        !this.data.userAgent ||
        !this.userAgentSupportsDeviceFeatures()
    ) {
      return
    }

    const { items } = await this.deviceFeaturesService.listDeviceFeatures({
      userAgentTypeIds: this.data.userAgentType.jrn.split(
        'jrn:user-agent::jive::user-agent-type/'
      )[1],
      page: 0,
      pageSize: 200
    })

    if (items) {
      _.forEach(items, feature => {
        this.supportedDeviceFeatures[feature.key] = feature
      })
    }

    this.hotdeskingSupported = !!this.supportedDeviceFeatures['feature.hotDesking'];

    if (!this.baseData.userAgentType) {
      this.title = this.gettextCatalog.getString(
        'Device - No Assigned Hardware'
      )
    } else if (this.baseData.userAgentType.listName !== 'Unknown') {
      this.title =
        this.gettextCatalog.getString('Device - ') +
        this.baseData.userAgentType.listName +
        (!this.supportedDeviceFeatures['feature.centrally-provisionable']
          ? this.gettextCatalog.getString(' (Manual Provisioning Only)')
          : '')
    } else {
      this.title = this.gettextCatalog.getString(
        'Device - Unknown Type - Manual Provisioning Only'
      )
    }

    this.updateAppliedFeatures()
  }

  async resetSecureProvisioning () {
    // Reset these values if the user clicks the reset button again after it's loaded.
    this.circleLoaderComplete = false
    this.drawCheckmark = false

    this.moveResetText = true
    this.showLoadCircle = true

    try {
      await this.portalApi.userAgent
        .pbxs(this.data._pbxId)
        .userAgents(this.hardware._path.id)
        .customPUT({
          location: this.hardware.location,
          type: this.hardware.type,
          emergencyServicesDid: this.hardware.emergencyServicesDid,
          macAddress: this.hardware.macAddress,
          configuration: this.hardware.configuration,
          assignedUserKey: this.hardware.assignedUserKey,
          outboundProxy: this.hardware.outboundProxy,
          pagingProfile: this.hardware.pagingProfile,
          corporateDirectory: this.hardware.corporateDirectory,
          name: this.hardware.name,
          secureCallingMode: this.hardware.secureCallingMode,
          faxOptimizeEnabled: this.hardware.faxOptimizeEnabled,
          securityMode: []
        })
      this.circleLoaderComplete = true
      this.drawCheckmark = true
    } catch (err) {
      this.moveResetText = false
      this.showLoadCircle = false
      this.drawCheckmark = false
      this.resetError = true

      const errorMessage = this.errorService.getErrorMessage(err)
      this.portalUtil.showErrorAlert(
        _.isEmpty(errorMessage)
          ? this.gettextCatalog.getString(
            'Could not reset secure provisioning.'
          )
          : errorMessage
      )
      this.ready = true

      throw err
    } finally {
      this.$timeout(() => (this.resetError = false), 1500)
    }
  }

  updateAppliedFeatures () {
    if (
      !this.data.userAgentType ||
      !this.data.userAgent ||
      !this.userAgentSupportsDeviceFeatures() ||
      !this.supportedDeviceFeatures['feature.centrally-provisionable']
    ) {
      return
    }

    this.deviceFeaturesService
      .getAppliedDeviceFeatures(
        this.data._id,
        this.data._pbxId,
        this.data.userAgentType.jrn
          ? this.data.userAgentType.jrn.split(
            'jrn:user-agent::jive::user-agent-type/'
          )[1]
          : this.data.userAgentType.split(
            'jrn:user-agent::jive::user-agent-type/'
          )[1],
        this.data.userAgent.split(
          'jrn:user-agent::jive:' + this.data._pbxId + ':user-agent/'
        )[1]
      )
      .then(({ featureConfigurations }) => {
        if (featureConfigurations.length === 0) {
          return
        }
        _.forEach(featureConfigurations, feature => {
          let deviceFeature = feature.deviceFeatureKey
          this.appliedFeatureConfigurations[deviceFeature] = feature
          this.editableDeviceFeatureConfigurations[deviceFeature] =
            feature.value
          if (deviceFeature === 'network.jbc') {
            this.jbcConfigured = 'OVERRIDE'
            if (feature.value === null) {
              this.jbcConfigured = 'DEFAULT'
              if (feature.useUserAgentDefaultValue) {
                this.jbcConfigured = 'NONE'
              }
            }
          }
        })

        this.baseEditableDeviceFeatureConfigurations = _.cloneDeep(
          this.editableDeviceFeatureConfigurations
        )

        if (this.jbcConfigured == 'DEFAULT') {
          this.deviceFeaturesService
            .getOrganizationAppliedFeatureConfiguration(this.data._pbxId, null)
            .then(response => {
              _.forEach(response.featureConfigurations, feature => {
                if (
                  feature.deviceFeatureKey === 'network.jbc' &&
                  feature.value === null
                ) {
                  this.pbxDefaultJbcDisabled = true
                }
              })
            })
        }
      })
      .catch(err => {
        this.portalUtil.showErrorAlert(
          this.gettextCatalog.getString(
            'Some device features failed to load, please retry.'
          )
        )
      })
  }

  removeCustomSetting (index, setting, form) {
    if (setting && setting.key) {
      delete this.data.customAttributes[setting.key]
    }

    this.customSettings.splice(index, 1)

    if (form) {
      form.$setDirty()
    }
  }

  markSublocationNAChanged () {
    this.data.addressLine2 = ''
    this.sublocationPlaceholder = !this.data._markSublocationNA
      ? ''
      : this.gettextCatalog.getString('Will be marked as “N/A”')
  }

  async siteChanged (isInitialLoading = false) {
    if (this.globalState.isUserManageSettingsOnly ||
        !this.data.site) {
      return
    }

    const id = this.jrn.parse(this.data.site).getResources()[1]

    this.site = await new this.Site(id).get()

    const completeStatuses = ['COMPLETE', 'PARTIAL', 'ADJUSTED_PARTIAL', 'ADJUSTED']
    const failedStatuses = ['FAILED', 'DEFAULT']
    // Defautl status is processing
    this.status = 'processig'
    this.statusText = this.gettextCatalog.getString('Sublocation update pending')
    this.statusIcon = this.portalConfig.clockInactiveIcon

    if (this.site.address && completeStatuses.includes(this.site.address.e911Status)) {
      this.status = 'complete'
      this.statusText = this.gettextCatalog.getString('Sublocation update complete')
      this.statusIcon = this.portalConfig.checkIcon
    } else if (this.site.address && failedStatuses.includes(this.site.address.e911Status)) {
      this.status = 'failed'
      this.statusText = this.gettextCatalog.getString('Sublocation update failed')
      this.statusIcon = this.portalConfig.errorIcon
    }

    this.site.maxAddressLine2Length =
      ADDRESS_LINE2_MAX_LENGTH -
      (this.site.address && this.site.address.addressLine2 ? this.site.address.addressLine2.length + 1 : 0)
    // Device's sublocation, only set it to empty when it is not loaded first time
    if (!isInitialLoading) {
      this.data.addressLine2 = ''
    }
    this.$timeout(() => this.$rootScope.$apply())
  }

  async checkHardwareUpdateDisabled () {
    if (
      !this.globalState.selectedPbx.billedByLicense ||
      !this.baseData.userAgent ||
      !this.baseData.assignedUser
    ) {
      return false
    }

    const user = await this.getUser(this.baseData.assignedUser)
    return !!user.hardwareUser
  }

  async getUser(userJrn) {
    const userId = this.jrn.parse(userJrn).getResources()[1]
    const userApi = new this.User(userId)
    return await userApi.get()
  }
}

_module.controller('DeviceController', DeviceController)
