import _module from 'module.js'

_module.directive('uniqueDialableUsername', function (User, $q) {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, $input, attrs, ngModelCtrl) {
      var DialableUsernameApi = new User(null),
        originalValue = scope.$eval(attrs.ngModel)
      ngModelCtrl.$asyncValidators.uniqueDialableUsername = (model, view) => {
        var value = model || view
        var dfd = $q.defer()

        if (!value || value.length === 0) {
          dfd.resolve(true)
        }

        if (!originalValue || originalValue !== value) {
          if (value) {
            DialableUsernameApi.getByDialableUsername(value).then(
              () => {
                dfd.reject('exists')
              },
              err => {
                if (err.status === 404) {
                  dfd.resolve(true)
                } else {
                  $q.reject(err)
                }
              }
            )
          }
        } else {
          dfd.resolve(true)
        }
        return dfd.promise
      }
    }
  }
})
