import getMembersTemplate from './getMembersTemplate.html'
import getMembersController from './getMembersController.js'
import module from 'module'

module.component('getMembers', {
  template: getMembersTemplate,
  controllerAs: 'ctrl',
  bindings: {
    members: '<ngModel',
    filterId: '<',
    disabled: '<',
    queryParams: '=?'
  },
  require: {
    ngModel: 'ngModel'
  },
  controller: getMembersController
})
