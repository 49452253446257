import "controllers/hardware/hardwareController";
import "controllers/hardware/hardwareService";
import "controllers/hardwares/hardwaresController";
import "controllers/pagingProfile/pagingProfileController";
import "controllers/pagingProfile/pagingProfileService";
import "controllers/pagingProfiles/pagingProfilesController";
import "controllers/pagingRealm/pagingRealmController";
import "controllers/pagingRealm/pagingRealmService";
import "controllers/pagingRealms/pagingRealmsController";

import "controllers/contactCenterConfiguration/contactCenterConfigurationController";
import "controllers/dialPlan/dialPlanService";
import "controllers/dialPlans/dialPlansController";
import "controllers/phoenix/phoenixController";
import "controllers/lines/linesController";
import "controllers/guidedSetup/guidedSetupController";
import "controllers/unifiedAdminDashboard/unifiedAdminDashboardController";
import "controllers/unifiedAdminDashboard/unifiedAdminDashboardAccessService";
import "controllers/line/lineController";
import "controllers/line/lineService";
import "controllers/conferenceRooms/conferenceRoomsController";
import "controllers/conferenceRoom/conferenceRoomController";
import "controllers/conferenceRoom/callback/conferenceRoomCallbackController";
import "controllers/conferenceRoom/conferenceRoomService";
import "controllers/callQueue/callQueueService";
import "controllers/sharedVoicemailBox/sharedVoicemailBoxController";
import "controllers/sharedVoicemailBox/sharedVoicemailBoxService";
import "controllers/sharedVoicemailBoxes/sharedVoicemailBoxesController";
import "controllers/virtualFaxMachine/virtualFaxMachineController";
import "controllers/virtualFaxMachine/virtualFaxMachineService";
import "controllers/virtualFaxMachines/virtualFaxMachinesController";
import "controllers/ringGroups/ringGroupsController";
import "controllers/ringGroup/ringGroupController";
import "controllers/ringGroup/ringGroupService";
import "controllers/ringGroups/ringGroupMemberFilter";
import "controllers/holdMusics/holdMusicsController";
import "controllers/holdMusic/holdMusicPlaylistService";
import "controllers/holdMusic/holdMusicService";
import "controllers/holdMusic/musicService";
import "controllers/item/featureFlagEnabledFilter";
import "controllers/item/settingEnabledFilter";
import "controllers/soundClips/soundClipsController";
import "controllers/soundClip/soundClipService";
import "controllers/settings/settingsService";
import "controllers/settings/settingsController";
import "controllers/settings/settingsCallFilter/callFiltersController";
import "controllers/settings/settingsCallFilter/callFilterService";
import "controllers/settings/settingsProxies/proxiesController";
import "controllers/settings/settingsProxies/proxiesService";
import "controllers/settings/settingsOutboundProxies/outboundProxiesController";
import "controllers/settings/settingsOutboundProxies/outboundProxiesService";
import "controllers/settings/settingsJbc/jbcsController";
import "controllers/settings/settingsJbc/jbcsService";
import "controllers/settings/settingsExtensionMappings/extensionMappingsController";
import "controllers/settings/settingsExtensionMappings/extensionMappingsService";
import "controllers/settings/settingsBillingGroups/billingGroupsController";
import "controllers/settings/settingsBillingGroups/billingGroupsService";
import "controllers/security/securityController";
import "controllers/security/userAgentConfigurationSecurityService";
import "controllers/permissions/permissionsController";
import "controllers/permissions/permissionsService";
import "controllers/permissions/permissionsGeneral/permissionsGeneralController";
import "controllers/permissions/permissionsDialing/permissionsDialingController";
import "controllers/permissions/permissionsMonitoring/monitoringService";
import "controllers/permissions/permissionsPrompts/permissionsPromptsController";
import "controllers/permissions/permissionsNetwork/networkController";
import "controllers/permissions/permissionsNetwork/networkService";
import "controllers/permissions/permissionsNetwork/expirationFilter";
import "controllers/schedule/scheduleService";
import "controllers/schedule/holidayFilter";
import "controllers/schedule/scheduleController";
import "controllers/schedule/scheduleHolidays/scheduleHolidaysController";
import "controllers/schedules/schedulesController";
import "controllers/extensions/extensionService";
import "controllers/extensions/extensionsController";
import "controllers/userSynchronizations/userSynchronizationsController";
import "controllers/users/usersController";
import "controllers/user/userController";
import "controllers/user/userService";
import "controllers/navBar/navBarController";
import "controllers/devices/devicesController";
import "controllers/device/deviceController";
import "controllers/device/deviceService";
import "controllers/device/deviceHidden/deviceHiddenController";
import "controllers/groups/groupsController";
import "controllers/group/groupController";
import "controllers/group/groupService";
import "controllers/dids/didsController";
import "controllers/dids/didsFilters";
import "controllers/leftPanel/leftPanelController";
import "controllers/did/didController";
import "controllers/did/didSMS/didSMSController";
import "controllers/did/didService";
import "controllers/directories/directoriesController";
import "controllers/directory/directoryController";
import "controllers/directory/directoryService";
import "controllers/corpDirectories/corpDirectoriesController";
import "controllers/corpDirectory/corpDirectoryController";
import "controllers/corpDirectory/corpDirectoryService";
import "controllers/tools/toolsController";
import "controllers/zohoMapping/zohoMappingController";
import "controllers/zohoMapping/zohoMappingService";
import "controllers/site/siteService";
import "controllers/pbx/pbxController";
import "controllers/root/rootController";
import "controllers/deviceStatus/deviceStatusController";
import "controllers/deviceStatus/deviceStatusService";
import "controllers/unavailableLines/unavailableLinesController";
import "controllers/unavailableLines/unavailableLineService";

import "controllers/zohoMapping/zohoMappingAuthComponent";
import "controllers/zohoMapping/zohoMappingAuthService";

import "controllers/unifiedAdminLocations/unifiedAdminLocationsController";

import "directives/buttonConfigurationMode/filterGetExtensionDirective";
import "directives/intlTelInputDirective";
import "directives/defaultProxy/defaultProxyDirective";

import "directives/clickOutside/clickOutsideDirective";
import "directives/confirmNavigation/confirmNavigationDirective";
import "directives/autoFocusDirective";
import "directives/jvDropdown/jvDropdownDirective";
import "directives/jvSearch/jvSearchDirective";
import "directives/jvSearch/clearInputDirective";
import "directives/extensionDirective";
import "directives/hideIfEmpty/hideIfEmpty";
import "directives/selectExtensionType/selectExtensionTypeDirective";
import "directives/saveButton/saveButtonDirective";
import "directives/submitDirective";
import "directives/cancelDirective";
import "directives/parentFormDirective";
import "directives/preventSubmitDirective";
import "directives/schedule/scheduleDragDirective";
import "directives/schedule/timeRangeDirective";
import "directives/schedule/TimeRangeService";
import "directives/rowEditor/rowEditorDirective";
import "directives/rowEditor/rowEditorDirectives";
import "directives/rowItem/rowItemDirective";
import "directives/rowItemMulti/rowItemMultiFilter";
import "directives/rowOptions/rowOptionsDirective";
import "directives/rowOptionsEdit/rowOptionsEditDirective";
import "directives/networkPermissions/networkPermissionsDirective";
import "directives/holdMusic/music/musicRowEditorDirective";
import "directives/holdMusic/playlists/playlistRowDirective";
import "directives/fileUpload/fileUploadDirective";
import "directives/scrollInto/scrollIntoDirective";
import "directives/timePicker/timePickerDirective";
import "directives/listDisplay/listDisplayDirective";
import "directives/monitoring/monitoringDirective";
import "directives/monitoring/aclMonitoringPermissionsDirective";
import "directives/langSwitcher/langSwitcher.provider";
import "directives/langSwitcher/langSwitcher.directive";
import "directives/noDirty/noDirty.directive"

import "factories/resyncAllDevicesWarning";

import "services/adminCenterUserWarning";
import "services/portalApi";
import "services/jiveOAuthClient";
import "services/authInterceptor";
import "services/pbxUtil";
import "services/userService";
import "services/tel";
import "services/languageService";
import "services/infiniteScrollLoader";
import "services/dataController";
import "services/deleteConfirmation";
import "services/awsSecretsPopup";
import "services/usagePopup";
import "services/jbcEditPopup";
import "services/e911CarouselPopup";
import "services/normalizationPopup";
import "services/addUserForm";
import "services/addSipTrunkForm";
import "services/addDeviceForm";
import "services/changeExtensionDigits";
import "services/extensionNotRecommended";
import "services/scheduleConference";
import "services/voicemailGreetingService";
import "services/portalUtil";
import "services/errorService";
import "services/exceptionHandlerFactory";
import "services/flashDetectUtil";
import "services/featuresService";
import "services/hotDeskingService";
import "services/deviceFeaturesService";
import "services/userSyncManagementService";
import "services/globalStateService";
import "services/navigationService";
import "services/extensionTypes";
import "services/confirmation/confirmationWindowOK";
import "services/confirmation/confirmationWindowWarn";
import "services/recorderWizard/soundClipRecorderWizard";
import "services/recorderWizard/greetingRecorderWizard";
import "services/userAgentTypeService";
import "services/integrationsService";
import "services/userConfigurationCheck";
import "services/mediaType/mediaTypeSelector";
import "services/auth";
import "services/contactCenterConfigurationService";
import "services/licensesService";
import "services/tryNewGoToAdminForm";
import "services/unleashService";
import "configs/settings.js";

import "services/unifiedAdminLocationsService"

import "directives/validation/validationDirective";
import "directives/validation/awsStorage";
import "directives/validation/confirmPasswordDirective";
import "directives/validation/numberOnlyDirective";
import "directives/validation/phoneNumberTransferPin";
import "directives/validation/extensionMappingPattern";
import "directives/validation/extensionLengthDirective";
import "directives/validation/uniqueExtension";
import "directives/validation/uniqueSite";
import "directives/validation/validTime";
import "directives/validation/uniqueJiveId";
import "directives/validation/uniqueDialableUsername";
import "directives/validation/uniqueDomain";
import "directives/validation/uniqueEmergencyPhoneNumber";
import "directives/validation/unusedExtension";
import "directives/validation/macAddressDirective";
import "directives/validation/nonBlank";
import "directives/validation/ipAddressPortDirective";
import "directives/validation/ipAddressMask";
import "directives/validation/ipAddressNoPortDirective";
import "directives/validation/jbcPstnGatewayDirective";
import "directives/validation/jbcPriorityNumberDirective";
import "directives/validation/uniqueMacAddress";
import "directives/validation/uniqueBillingGroupName";
import "directives/validation/uniqueTrunkPrefix";
import "directives/validation/dialableString";
import "directives/validation/dialableRegexString";
import "directives/validation/isNotPathDirective";
import "directives/validation/validAreaCode";
import "directives/validation/largeFaxDestRequired";

import "components/inputLabel/inputLabelComponent";
import "components/schedule/scheduleComponent";
import "components/lineDialplan/lineDialplanComponent";
import "components/lineAssignment/lineAssignmentComponent";
import "components/jvListItems/jvListItemsComponent";
import "components/timeDuration/timeDurationComponent";
import "components/angularMailcheck/angularMailcheckComponent";
import "components/groupListTooltip/groupListTooltipComponent";
import "components/audioPlayer/audioPlayerComponent";
import "components/audioPreview/audioPreviewComponent";
import "components/callFilterActions/callFilterActionsComponent";
import "components/holdMusic/music/musicListsComponent";
import "components/holdMusic/music/musicListItemsComponent";
import "components/holdMusic/playlists/playlistsComponent";
import "components/holdMusic/playlists/playlistItemsComponent";
import "components/buttonConfigurationMode/buttonConfigurationModeComponent";
import "components/musicFileUpload/musicFileUploadComponent";
import "components/voicemailGreeting/voicemailGreetingComponent";
import "components/voicemailGreeting/voicemailGreetingUploaderComponent";
import "components/timeZoneSelect/timeZoneSelectComponent";
import "components/timeZoneSelect/timeZoneSelectConstants";
import "components/assignLine/assignLineComponent";
import "components/assignLines/assignLinesComponent";
import "components/dedicatedLineKeysButtonConfig/dedicatedLineKeysButtonConfigComponent";
import "components/getJrn/getJrnComponent";
import "components/getCorporateDirectory/getCorporateDirectoryComponent";
import "components/getDid/getDidComponent";
import "components/getOutboundDid/getOutboundDidComponent";
import "components/getCallbackDid/getCallbackDidComponent";
import "components/getChatQueue/getChatQueueComponent";
import "components/getJbc/getJbcComponent";
import "components/getExtension/getExtensionComponent";
import "components/getGroups/getGroupsComponent";
import "components/getBillingGroup/getBillingGroupComponent";
import "components/getHardware/getHardwareComponent";
import "components/getHoldMusic/getHoldMusicComponent";
import "components/getHotDeskDevice/getHotDeskDeviceComponent";
import "components/getOutboundProxy/getOutboundProxyComponent";
import "components/getPagingProfile/getPagingProfileComponent";
import "components/getPagingZone/getPagingZoneComponent";
import "components/getPbx/getPbxComponent";
import "components/getRegistrationProxy/getRegistrationProxyComponent";
import "components/getUser/getUserComponent";
import "components/getUserAgentType/getUserAgentTypeComponent";
import "components/getZohoUser/getZohoUserComponent";
import "components/displayZohoUserName/displayZohoUserNameComponent";
import "components/displayZohoUserExtension/displayZohoUserExtensionComponent";
import "components/getSoundClip/getSoundClipComponent";
import "components/getDialByNameDirectory/getDialByNameDirectoryComponent";
import "components/getExtensionModel/getExtensionModelComponent";
import "components/getMembers/getMembersComponent";
import "components/getPagingRealm/getPagingRealmComponent";
import "components/getSite/getSiteComponent";
import "components/getLocations/getLocationsComponent";
import "components/listTooltip/listTooltipComponent";
import "components/jbcStatusTooltip/jbcStatusTooltipComponent";
import "components/soundRecorder/soundRecorderComponent";
import "components/secureDevicesInfo/secureDevicesInfoComponent";
import "components/segmentedCircle/segmentedCircleComponent";
import "components/banner/bannerComponent";
import "components/alert/alertComponent";
import "components/danteAi/danteAiPopoverComponent";

import "classes/importExportTool";
import "classes/urlProvider";
