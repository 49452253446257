import featureConfigurations from './fixtures/featureConfigurations'

export default ($q, portalConfig) => {
  'ngInject'
  const deviceFeaturesRegex = new RegExp(
    `${portalConfig.deviceFeaturesApiUrl}/device-features`
  )
  const deviceFeaturesSetDeviceFeatureRegex = new RegExp(
    `${portalConfig.deviceFeaturesApiUrl}/device-feature-configurations`
  )
  const deviceFeaturesSetOrganizationFeatureRegex = new RegExp(
    `${portalConfig.deviceFeaturesApiUrl}/organization-feature-configurations`
  )
  const deviceFeaturesGetAppliedFeaturesListRegex = new RegExp(
    `${
      portalConfig.deviceFeaturesApiUrl
    }/applied-feature-configuration/organization/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})`
  )
  const deviceFeaturesGetAppliedFeaturesRegex = new RegExp(
    `${
      portalConfig.deviceFeaturesApiUrl
    }/applied-feature-configuration/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})`
  )

  const deviceFeatures = {}
  const deviceFeaturesCache = {
    get (userAgentConfigurationId) {
      return deviceFeatures[userAgentConfigurationId]
    }
  }

  const supportedDeviceFeatures = [
    {
      key: 'security.secureCalling'
    },
    {
      key: 'security.opportunisticSrtp'
    },
    {
      // Comment out this feature if you want the manual provisioning text to show up.
      key: 'feature.centrally-provisionable'
    }
  ]
  const supportedDeviceFeaturesCache = {
    get () {
      return {
        items: supportedDeviceFeatures
      }
    }
  }

  let deviceFeatureList = function () {
    return {
      featureConfigurations
    }
  }

  return {
    request: function (requestConfig) {
      let resourceId
      let cache

      if (deviceFeaturesGetAppliedFeaturesRegex.test(requestConfig.url)) {
        const match = deviceFeaturesGetAppliedFeaturesRegex.exec(
          requestConfig.url
        )
        resourceId = match[1]

        if (!deviceFeatures[resourceId]) {
          deviceFeatures[resourceId] = deviceFeatureList()
        }

        cache = deviceFeaturesCache
      } else if (
        deviceFeaturesSetDeviceFeatureRegex.test(requestConfig.url) ||
        deviceFeaturesSetOrganizationFeatureRegex.test(requestConfig.url)
      ) {
        cache = deviceFeaturesCache
      } else if (deviceFeaturesRegex.test(requestConfig.url)) {
        resourceId = 'thecache'
        cache = supportedDeviceFeaturesCache
      } else if (
        deviceFeaturesGetAppliedFeaturesListRegex.test(requestConfig.url)
      ) {
        const match = deviceFeaturesGetAppliedFeaturesListRegex.exec(
          requestConfig.url
        )
        resourceId = match[1]

        if (!deviceFeatures[resourceId]) {
          deviceFeatures[resourceId] = deviceFeatureList()
        }

        cache = deviceFeaturesCache
      } else {
        return requestConfig
      }

      if (requestConfig.method === 'GET') {
        requestConfig.url = resourceId
        requestConfig.params = {}
        requestConfig.cache = cache
      } else if (requestConfig.method === 'POST') {
        if (deviceFeaturesSetDeviceFeatureRegex.test(requestConfig.url)) {
          resourceId = requestConfig.data.userAgentConfigurationId
        } else {
          resourceId = requestConfig.data.organizationId
        }
        let deviceFeatureKey = requestConfig.data.deviceFeatureKey

        if (!deviceFeatures[resourceId]) {
          deviceFeatures[resourceId] = deviceFeatureList()
        }

        let deviceFeatureConfiguration = deviceFeatures[
          resourceId
        ].featureConfigurations.filter(item => {
          return item.deviceFeatureKey === deviceFeatureKey
        })[0]

        deviceFeatureConfiguration.value = requestConfig.data.value

        return $q.reject({
          intercepted: true,
          data: deviceFeatureConfiguration,
          config: requestConfig
        })
      }

      return requestConfig
    },

    responseError: function (error) {
      if (error.intercepted) {
        return $q.when({
          data: error.data,
          status: 200,
          statusText: 'OK',
          headers: () => {},
          config: error.config
        })
      } else {
        return $q.reject(error)
      }
    }
  }
}
