import _module from 'module.js'
import FileUploadController
  from '../../directives/fileUpload/fileUploadDirective'
import '../../components/audioPlayer/audioPlayerService'
import ListControllerBase from 'classes/listController.class'
import jrnParser from 'classes/jrnParser'
import is from 'is'

let _timeout,
  _jrn,
  _usagePopup,
  _SoundClip,
  _document,
  _soundClipWizard,
  _confirmationWindowOK,
  _anchorScroll

export default class SoundClipsController extends ListControllerBase {
  constructor (
    $state,
    $stateParams,
    $location,
    $analytics,
    $timeout,
    SoundClip,
    gettextCatalog,
    infiniteLoader,
    usagePopup,
    navigation,
    globalState,
    portalUtil,
    $document,
    soundClipWizard,
    confirmationWindowOK,
    $anchorScroll
  ) {
    'ngInject'
    super(
      gettextCatalog,
      $analytics,
      $location,
      portalUtil,
      globalState,
      navigation,
      $state,
      $stateParams
    )

    this.gettextCatalog = gettextCatalog
    _timeout = $timeout
    _SoundClip = SoundClip
    _usagePopup = usagePopup
    _jrn = jrnParser
    _document = $document
    _soundClipWizard = soundClipWizard
    _confirmationWindowOK = confirmationWindowOK
    _anchorScroll = $anchorScroll

    this.title = this.gettextCatalog.getString('Sound Clips')

    this.headers = [
      {
        width: 25,
        name: this.gettextCatalog.getString('Name'),
        sortBy: 'name',
        asc: true
      },
      {
        width: 15,
        name: this.gettextCatalog.getString('Uploaded'),
        sortBy: 'uploaded_date',
        asc: null
      },
      {
        width: 35,
        name: this.gettextCatalog.getString('Description'),
        sortBy: 'description',
        asc: null
      },
      {
        width: 25,
        name: ''
      }
    ]

    this.buttonText = this.gettextCatalog.getString('Upload')
    this.comboText = this.gettextCatalog.getString('Record')

    this.loader = infiniteLoader(SoundClip)
  }

  createNew (data) {
    let uploadSoundclip = new FileUploadController(
      null,
      {
        accept: '.wav,.WAV,.ulaw,.mp3',
        type: 'file'
      },
      null,
      this.gettextCatalog,
      _timeout,
      this.portalUtil
    )

    uploadSoundclip.openFileUploader(file => {
      data.items.unshift({
        file: file,
        isNew: true,
        name: file.name
      })
      _anchorScroll()

      // For whatever reason, the newly created soundclips input would appear until click or some other event
      _document.click()
    }, this)
  }

  delete (event, item) {
    let name = this.gettextCatalog.getString('sound clip') + ': ' + item.name
    this.loader.entityApi.startDelete(event, item, this.data, name)
  }

  itemSelected () {
    // NO-OP
  }

  startComboWizard () {
    if (is.safari() || is.ie()) {
      _confirmationWindowOK.show(
        this.gettextCatalog.getString('Warning'),
        this.gettextCatalog.getString(
          'Audio recording is supported by Chrome, Firefox or Edge browser.'
        ),
        true
      )
    } else {
      this.busy = true
      _soundClipWizard.open().then(newSoundClip => {
        if (newSoundClip) {
          this.data.items.unshift(newSoundClip.plain())
          this.data.totalCount++
          this.data.matchCount++
        }
        this.busy = false
      })
    }
  }

  getUsage (event, item) {
    let name = this.gettextCatalog.getString('Sound Clip') + ': ' + item.name
    let id = _jrn.parse(item.jrn).getResources()[1]
    _usagePopup
      .open(event, name, item, new _SoundClip(id, item))
      .then(
        () => {
          // NO-OP
        },
        () => {}
      )
      .catch(error => {
        this.portalUtil.showErrorAlert(
          this.gettextCatalog.getString('Query failed, please retry.')
        )
        throw error
      })
  }
}

_module.controller('SoundClipsController', SoundClipsController)
