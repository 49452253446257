import scheduleTemplate from './scheduleTemplate.html'
import scheduleController from './scheduleController.js'
import module from 'module'

module.component('schedule', {
  template: scheduleTemplate,
  controllerAs: 'schedule',
  bindings: {
    timeRanges: '=',
    name: '<'
  },
  require: {
    ngModel: 'ngModel'
  },
  controller: scheduleController
})
