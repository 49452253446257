import assignLineTemplate from './assignLineTemplate.html'
import jrnParser from 'classes/jrnParser'
import _module from 'module.js'

const isCompleteButtonConfig = buttonConfig => {
  if (!buttonConfig.type) {
    return false
  } else if (buttonConfig.type !== 'ON_PHONE_DEFINED') {
    return buttonConfig.label && buttonConfig.parameters
  } else {
    return true
  }
}

const sortSupportedTypeOptions = options => {
  const sortReference = [
    'LINE',
    'LINE_SILENT',
    'SPEED_DIAL',
    'MONITORED_EXTENSION',
    'BUDDY_WATCH',
    'CALL_PARK',
    'ON_PHONE_DEFINED',
    'MCAST',
  ]
  return [...(options ? options : [])].sort((a, b) => sortReference.indexOf(a) - sortReference.indexOf(b))
}

export default class assignLineController {
  constructor (gettextCatalog, $rootScope) {
    'ngInject'

    const modelId = this.userAgentConfig.userAgentType && this.userAgentConfig.userAgentType.jrn && jrnParser.parse(this.userAgentConfig.userAgentType.jrn).getResources()[1]
    this.isPolycomCallParkRename = modelId && modelId.startsWith('polycom') && !modelId.startsWith('polycom.obi.rove') && modelId !== 'polycom.vvx.150'

    this.gettextCatalog = gettextCatalog
    this.$rootScope = $rootScope
    this.buttonSource = {}
    this.statusClass = ''
    this.showSpeedDialCheckbox = false

    this.buttonTypeOptions = []
    this.availableButtonOptions = {
      LINE: {
        text: gettextCatalog.getString('Line'),
        value: 'LINE',
        description: gettextCatalog.getString('Add a primary or secondary extension')
      },
      LINE_SILENT: {
        text: gettextCatalog.getString('Line (no ring)'),
        value: 'LINE_SILENT',
        description: gettextCatalog.getString('Add a primary or secondary extension with no audible ring')
      },
      SPEED_DIAL: {
        text: gettextCatalog.getString('Speed dial'),
        value: 'SPEED_DIAL',
        description: gettextCatalog.getString('Program internal or external numbers')
      },
      CALL_PARK: {
        text: this.isPolycomCallParkRename ? gettextCatalog.getString('Speed dial / Transfer w/ presence') : gettextCatalog.getString('Call park'),
        value: 'CALL_PARK',
        description: this.isPolycomCallParkRename ? gettextCatalog.getString('Program a number for speed dial and one-touch transfer') : gettextCatalog.getString('Assign as parking spot with presence monitoring')
      },
      MONITORED_EXTENSION: {
        text: gettextCatalog.getString('Speed dial w/ presence'),
        value: 'MONITORED_EXTENSION',
        description: gettextCatalog.getString('Program a number and monitor presence')
      },
      BUDDY_WATCH: {
        text: gettextCatalog.getString('Speed dial w/ presence (buddy watch)'),
        value: 'BUDDY_WATCH',
        description: gettextCatalog.getString('Program a number and monitor its presence and status')
      },
      MCAST: {
        text: gettextCatalog.getString('Multicast paging group'),
        value: 'MCAST',
        description: gettextCatalog.getString('Broadcast messages to a specific paging realm')
      },
      ON_PHONE_DEFINED: {
        text: gettextCatalog.getString('Custom defined on phone'),
        value: 'ON_PHONE_DEFINED',
        description: gettextCatalog.getString('Manually define button function on device')
      }
    }

    this.SELECT_BUTTON_TYPE = gettextCatalog.getString('Select Button Type')
    this.BUTTON_LABEL = gettextCatalog.getString('Button Label')
    this.PHONE_NUMBER = gettextCatalog.getString('Phone Number')
    this.CALL_PARK_SPOT = this.isPolycomCallParkRename ? gettextCatalog.getString('Extension number or full call park number with *37 prefix') : gettextCatalog.getString('Call Park Spot')
    this.ALLOW_USER_TO_CHANGE_THIS_SPEED_DIAL = gettextCatalog.getString(
      'Allow user to change this Speed Dial button in GoToConnect'
    )
    this.USE_EXT_NAME = gettextCatalog.getString('Use Ext Name')
  }

  setActiveLine (line) {
    if (line && !line.address) {
      this.buttonSource.parameters = line.jrn

      if (!this.buttonSource.label) {
        this.buttonSource.label = line.extensionNumber
      }
    } else if (line && line.address) {
      if (!this.buttonSource.label) {
        this.buttonSource.label = line.name
        this.buttonSource.parameters = line.name
          ? line.name + '-' + line.address
          : line.address
      }
    }

    this.buttonValueChanged()
  }

  buttonTypeChanged (type) {
    if (type === 'LINE' || type === 'LINE_SILENT') {
      if (this.buttonTypeSelected === 'MCAST') {
        this.buttonSource.displayString = this.gettextCatalog.getString(
          'Choose Extension'
        )
      }
      this.buttonTypeSelected = 'LINE'
      this.showSpeedDialCheckbox = false
    } else if (type === 'CALL_PARK' && !this.isPolycomCallParkRename) {
      this.buttonTypeSelected = 'CALL_PARK'
      this.showSpeedDialCheckbox = true
    } else if (type === 'SPEED_DIAL' || (type === 'CALL_PARK' && this.isPolycomCallParkRename)) {
      this.buttonTypeSelected = 'NUMBER'
      this.showSpeedDialCheckbox = true
    } else if (type === 'MONITORED_EXTENSION' || type === 'BUDDY_WATCH') {
      this.buttonTypeSelected = 'MONITORED_EXTENSION'
      this.showSpeedDialCheckbox = true
    } else if (type === 'MCAST') {
      this.buttonSource.parameters = this.gettextCatalog.getString(
        'Choose Paging Zone'
      )
      this.buttonTypeSelected = type
      this.showSpeedDialCheckbox = true
    } else if (type === 'ON_PHONE_DEFINED') {
      this.buttonSource.parameters = ''
      this.buttonSource.label = null
      this.buttonTypeSelected = type
      this.showSpeedDialCheckbox = false
    } else {
      this.buttonTypeSelected = null
      this.showSpeedDialCheckbox = false
    }

    if (
      this.assignLines &&
      (type === 'MONITORED_EXTENSION' ||
        type === 'SPEED_DIAL' ||
        type === 'CALL_PARK' ||
        type === 'BUDDY_WATCH')
    ) {
      this.button.userEditable = this.assignLines.selectAllSpeedDialButtons
    }

    if (
      (type === 'MONITORED_EXTENSION' || type === 'BUDDY_WATCH') &&
      !this.userAgentConfig.presenceAware
    ) {
      this.userAgentConfig.presenceAware = true
    }

    this.buttonValueChanged()
  }

  buttonNumberChanged () {
    this.buttonValueChanged()
  }

  isNotValidButtonForUseExtensionLabel () {
    return (
      !this.userAgentConfig.useButtonConfiguration ||
      this.buttonSource.type === 'ON_PHONE_DEFINED' ||
      this.buttonSource.type === 'MCAST' ||
      (this.buttonSource.type === 'CALL_PARK' && !this.isPolycomCallParkRename) ||
      this.speedDialIsNum
    )
  }

  isNotValidButtonType () {
    return (
      !this.userAgentConfig.useButtonConfiguration ||
      this.buttonSource.type !== 'ON_PHONE_DEFINED' ||
      this.buttonSource.type !== 'MCAST' ||
      this.buttonSource.type !== 'CALL_PARK' ||
      (this.buttonSource.type === 'CALL_PARK' && this.isPolycomCallParkRename) ||
      this.buttonSource.type !== ''
    )
  }

  buttonValueChanged () {
    if (
      this.buttonSource.useExtensionLabel &&
      this.assignedExtension &&
      this.assignedExtension.name
    ) {
      this.buttonSource.label = this.assignedExtension.name
    } else if (
      this.buttonSource.useExtensionLabel &&
      this.buttonSource.displayString
    ) {
      this.buttonSource.label = this.buttonSource.displayString.split(': ')[1]
    }

    if (isCompleteButtonConfig(this.buttonSource)) {
      this.ngModel.$setViewValue(_.clone(this.buttonSource))
      if (this.assignLines) {
        this.assignLines.setSelectAllSpeedDialButtons()
      }
    } else if (this.buttonSource.type === '') {
      this.ngModel.$setViewValue(null)
    }

    if (
      this.userAgentConfig &&
      this.userAgentConfig.userAgentType &&
      !this.userAgentConfig.userAgentType.dedicatedLineButtons
    ) {
      let currentButton

      if (
        this.sharedLineExistsOnAssignedExtension() &&
        this.isNotValidButtonType() &&
        this.buttonSource.type.toLowerCase() ===
          this.assignedExtension.type.toLowerCase()
      ) {
        currentButton = this.assignedExtension
      } else if (
        this.button &&
        this.button.sharedLineEnabled &&
        this.buttonSource.type.toLowerCase() === this.button.type.toLowerCase()
      ) {
        currentButton = this.button
      } else {
        currentButton = this.buttonSource
      }

      if (
        this.assignLines &&
        this.assignLines.sidecars &&
        _.invert(this.assignLines.sidecars)[true]
      ) {
        currentButton.id =
          parseInt(_.invert(this.assignLines.sidecars)[true]) +
          1 +
          ':' +
          this.index
      } else {
        currentButton.id = '0:' + this.index
      }

      this.$rootScope.$broadcast('updatePresenceAware', currentButton)
    }
  }

  sharedLineExistsOnAssignedExtension () {
    return (
      this.assignedExtension &&
      this.assignedExtension.sharedAppearance !== undefined
    )
  }

  speedDialTargetTypeChanged () {
    if (this.speedDialIsNum) {
      this.assignedExtension = null
      this.buttonSource.useExtensionLabel = true
    }

    this.buttonSource.parameters = ''

    this.speedDialIsNum = !this.speedDialIsNum
  }

  initData () {
    this.buttonSource = this.button
    this.buttonSource.useExtensionLabel = this.isNotValidButtonForUseExtensionLabel()
      ? false
      : !this.buttonSource.parameters
          ? true
          : this.buttonSource.useExtensionLabel
    this.buttonSource.typeText = this.buttonSource.type
      ? this.availableButtonOptions[this.buttonSource.type].text
      : this.gettextCatalog.getString('Not configured')
    // set the status-class
    if (this.buttonSource.type === 'LINE') {
      this.statusClass = this.buttonSource.status
    }

    this.buttonTypeSelected = this.button.type
    if (this.buttonTypeSelected === 'LINE_SILENT') {
      this.buttonTypeSelected = 'LINE'
    } else if (this.buttonTypeSelected === 'CALL_PARK' && !this.isPolycomCallParkRename) {
      this.buttonTypeSelected = 'CALL_PARK'
      this.showSpeedDialCheckbox = true
    } else if (this.buttonTypeSelected === 'SPEED_DIAL' || (this.buttonTypeSelected === 'CALL_PARK' && this.isPolycomCallParkRename)) {
      this.buttonTypeSelected = 'NUMBER'
      this.showSpeedDialCheckbox = true
      this.speedDialIsNum = !(this.button.parameters &&
        this.button.parameters.match(/jrn/i))
    } else if (
      this.buttonTypeSelected === 'MONITORED_EXTENSION' ||
      this.buttonTypeSelected === 'BUDDY_WATCH'
    ) {
      this.showSpeedDialCheckbox = true
      this.buttonTypeSelected = 'MONITORED_EXTENSION'
      this.speedDialIsNum = !(this.button.parameters &&
        this.button.parameters.match(/jrn/i))
    }

    if (
      this.userAgentConfig.userAgentType &&
      this.userAgentConfig.userAgentType.manufacturerName === 'Polycom'
    ) {
      this.availableButtonOptions[
        'MONITORED_EXTENSION'
      ].text = this.gettextCatalog.getString('SPEED DIAL w/ PRESENCE (BLF)')
    }

    if (!this.blockUnconfigurable) {
      this.buttonTypeOptions = [
        {
          text: this.gettextCatalog.getString('Not configured'),
          value: '',
          description: this.gettextCatalog.getString('Button does nothing')
        }
      ]
    } else {
      this.buttonTypeSelected = this.button.type = 'LINE'
    }

    if (this.buttonTypeSelected === 'LINE') {
      this.assignedExtension = this.button.parameters
    }

    if (this.buttonTypeSelected === 'MCAST') {
      this.buttonSource.parameters = this.button.parameters
    }

    if (this.userAgentConfig.useButtonConfiguration) {
      sortSupportedTypeOptions(this.supportedButtonTypes).forEach(type => {
        if (this.availableButtonOptions[type]) {
          this.buttonTypeOptions.push(this.availableButtonOptions[type])
        }
      })
    } else {
      this.buttonTypeOptions.push(this.availableButtonOptions.LINE)
    }
  }

  $onInit () {
    this.ngModel.$render = () => {
      this.initData()
    }
  }

  $onChanges (changes) {
    if (changes.userAgentConfig && changes.userAgentConfig.presenceAware) {
      if (
        this.buttonSource.type === 'MONITORED_EXTENSION' ||
        this.buttonSource.type === 'BUDDY_WATCH'
      ) {
        this.buttonSource.type = this.buttonTypeSelected = 'SPEED_DIAL'
        this.buttonSource.typeText = this.availableButtonOptions[
          'SPEED_DIAL'
        ].text
        this.buttonTypeChanged('SPEED_DIAL')
      }
    }
  }
}

_module.component('assignLine', {
  template: assignLineTemplate,
  require: {
    ngModel: 'ngModel',
    assignLines: '?^'
  },
  bindings: {
    button: '<ngModel',
    userAgentConfig: '<',
    index: '<',
    blockUnconfigurable: '<',
    supportedButtonTypes: '<'
  },
  controllerAs: 'assignLine',
  controller: assignLineController
})
