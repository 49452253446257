const SPLIT_CHAR = ':'
const RESOURCES_SPLIT_CHAR = '/'

export default class jrnParser {
  constructor (str = '') {
    const parts = str.split(SPLIT_CHAR)
    if (parts.length === 6) {
      this._resources = parts[5].split(RESOURCES_SPLIT_CHAR)
    } else if (parts.length > 6) {
      this._resources = parts
      parts.splice(0, 5)
    }
    this._scheme = parts[0] || 'jrn'
    this._service = parts[1] || ''
    this._location = parts[2] || ''
    this._landlord = parts[3] || 'jive'
    this._account = parts[4] || ''
  }

  toString () {
    const parts = [
      this._scheme,
      this._service,
      this._location,
      this._landlord,
      this._account
    ]
    if (this._resources.length > 0) {
      parts.push(this._resources.join(RESOURCES_SPLIT_CHAR))
    }
    return parts.join(SPLIT_CHAR)
  }

  getService () {
    return this._service
  }

  getAccount () {
    return this._account
  }

  getResources () {
    return this._resources
  }

  static parse (str) {
    return new jrnParser(str)
  }
}
