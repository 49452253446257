import _module from 'module.js'
import is from 'is'
import _ from 'lodash'
import jrnParser from 'classes/jrnParser'

export default class NavigationService {
  constructor ($state, $stateParams, $q) {
    'ngInject'
    this.$state = $state
    this.$stateParams = $stateParams
    this.jrnParser = jrnParser
    this.$q = $q

    this._listController
    this._data
    this._jrn
    this._index
  }

  set listController (lc) {
    this._listController = lc
  }

  get jrn () {
    return this._jrn
  }

  get isAvailable () {
    return this._listController
  }

  set jrn (jrn) {
    this._jrn = jrn
    if (this._listController && this._listController.data.items) {
      this._index = _.findIndex(this._listController.data.items, { jrn: jrn })
    } else {
      this._index = -1
    }
  }

  get nextJrn () {
    let deferred = this.$q.defer()
    if (this._index < this._listController.data.matchCount) {
      this._index++
      if (this._index >= this._listController.data.items.length) {
        this._listController
          .requestMoreData(this._listController.data)
          .then(moreData => {
            deferred.resolve(this._listController.data.items[this._index].jrn)
          })
      } else {
        deferred.resolve(this._listController.data.items[this._index].jrn)
      }
    } else {
      deferred.resolve(null)
    }
    return deferred.promise
  }

  get prevJrn () {
    let deferred = this.$q.defer()
    if (this._index > 0) {
      this._index--
      deferred.resolve(this._listController.data.items[this._index].jrn)
    } else {
      deferred.resolve(null)
    }
    return deferred.promise
  }

  getIndex (jrn) {
    if (jrn !== this._jrn) {
      this.jrn = jrn
    }
    return this._index
  }

  get matchCount () {
    return this._listController ? this._listController.data.matchCount : 0
  }

  navigateTo (toRetrieveJrn, toState, idParam) {
    toRetrieveJrn.then(toJrn => {
      if (toJrn) {
        this.$stateParams[idParam] =
          idParam === 'pbxId'
            ? this.jrnParser.parse(toJrn).getAccount()
            : this.jrnParser.parse(toJrn).getResources()[1]
        this.$state.go(toState, this.$stateParams)
      }
    })
  }

  navigateNext (toState, idParam) {
    this.navigateTo(this.nextJrn, toState, idParam)
  }

  navigatePrev (toState, idParam) {
    this.navigateTo(this.prevJrn, toState, idParam)
  }
}

_module.service('navigation', NavigationService)
