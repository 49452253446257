import _module from 'module.js'

_module.directive('macAddress', function () {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, $input, attrs, ngModelCtrl) {
      ngModelCtrl.$validators.macAddress = val => {
        if (val) {
          let potential = $input.val().replace(/[:\.-]/g, '')
          return /^([0-9A-Fa-f]){12}$/.test(potential)
        }

        return true
      }
    }
  }
})
