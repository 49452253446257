import _module from 'module.js'

const VIEW_CONTACT_CENTER_CONFIGURATION = 'cc-config';

export default class ContactCenterConfigurationService {
  constructor (portalApi) {
    'ngInject'
    this.portalApi = portalApi
  }

  getUserContext(pbxUUID) {
    return this.portalApi.contactCenterAuthority.pbxs(pbxUUID).userContext().get()
  }

  getTrialLicenseEligibilities(pbxUUID) {
    return this.portalApi.contactCenterConfiguration.pbxs(pbxUUID).licenseEligibilities().get()
  }

  enrollInFreeTrial(pbxUUID, campaignId) {
    return this.portalApi.contactCenterConfiguration.pbxs(pbxUUID).enroll().post(null, { campaignId })
  }

  upgradeLicenseFromFreeTrial(pbxUUID, campaignId) {
    return this.portalApi.contactCenterConfiguration.pbxs(pbxUUID).upgrade().post(null, { campaignId })
  }

  hasContactCenterConfigurationPermission(context) {
    return context && context.permissions && context.permissions.includes(VIEW_CONTACT_CENTER_CONFIGURATION)
  }
}

_module
  .service('contactCenterConfigurationService', ContactCenterConfigurationService)
