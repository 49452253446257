import voicemailGreetingUploaderTemplate
  from './voicemailGreetingUploaderTemplate.html'
import VoicemailGreetingUploaderController
  from './voicemailGreetingUploaderController.js'

import _module from 'module.js'

_module.component('voicemailGreetingUploader', {
  template: voicemailGreetingUploaderTemplate,
  require: {
    ngModel: 'ngModel',
    voicemailGreeting: '^voicemailGreeting'
  },
  restrict: 'E',
  bindings: {
    param: '=',
    uploader: '&',
    getGreeting: '&',
    fileUrl: '='
  },
  controllerAs: 'voicemailGreetingUploader',
  controller: VoicemailGreetingUploaderController
})
