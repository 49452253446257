import _module from 'module.js'
import isUUID from 'classes/isUUID'
import jrnParser from 'classes/jrnParser'
import itemTemplate from 'controllers/item/itemTemplate.html'
import didsTemplate from 'controllers/dids/didsTemplate.html'
import didGeneralTemplate from 'controllers/did/didGeneral/didGeneralTemplate.html'
import didRecordingTemplate from 'controllers/did/didRecording/didRecordingTemplate.html'
import didSMSTemplate from 'controllers/did/didSMS/didSMSTemplate.html'
import didLogsTemplate from 'controllers/did/didLogs/didLogsTemplate.html'
import permissionUserListTemplate from 'controllers/did/didSMS/permissionUserListTemplate.html'
import environments from '../configs/environments'

const basePath = environments.current === 'local' ? '/images' : '/pbx/images'

_module.config((gettext, $stateProvider) => {
  'ngInject'

  $stateProvider
    .state('root.nav.pbx.dids', {
      url: '/dids',
      template: '<ui-view class="did-abstract max-height"></ui-view>',
      abstract: true
    })
    .state('root.nav.pbx.dids.list', {
      url: '?q&sort&direction&source',
      template: didsTemplate,
      controller: 'DidsController as ctrl',
      reloadOnSearch: false
    })
    .state('root.nav.pbx.dids.view', {
      url: '/:didId',
      template: itemTemplate,
      controller: 'DidController as ctrl',
      resolve: {
        didData: (Did, $stateParams, globalState, portalApi) => {
          if (globalState.selectedPbxUUID) {
            return Did(
              $stateParams.didId,
              null,
              globalState.selectedPbxUUID
            ).get()
          }
          if (isUUID($stateParams.id)) {
            return Did($stateParams.didId, null, $stateParams.id).get()
          }
          return portalApi.pbx
            .pbxs('domain')
            .one($stateParams.id)
            .get()
            .then(pbxJrn => {
              return Did(
                $stateParams.didId,
                null,
                jrnParser.parse(pbxJrn).getAccount()
              ).get()
            })
        }
      },
      abstract: true,
      data: {
        title: 'Phone Number',
        itemIcon: `${basePath}/phone-up-inactive.svg`,
        tabs: [
          {
            id: 'phone-number-general',
            label: gettext('General'),
            state: 'root.nav.pbx.dids.view.general'
          },
          {
            id: 'phone-number-recording',
            label: gettext('Recording'),
            state: 'root.nav.pbx.dids.view.recording'
          }
        ],
        hiddenTab: [
          {
            id: 'phone-number-sms',
            label: gettext('Permissions'),
            state: 'root.nav.pbx.dids.view.sms.template'
          },
          {
            id: 'phone-number-logs',
            label: gettext('Logs'),
            state: 'root.nav.pbx.dids.view.logs'
          }
        ]
      }
    })
    .state('root.nav.pbx.dids.view.general', {
      url: '/general',
      template: didGeneralTemplate
    })
    .state('root.nav.pbx.dids.view.recording', {
      url: '/recording',
      template: didRecordingTemplate
    })
    .state('root.nav.pbx.dids.view.sms', {
      url: '',
      template: didSMSTemplate,
      controller: 'didSMSController as ctrl'
    })
    .state('root.nav.pbx.dids.view.sms.template', {
      url: '/sms',
      template: permissionUserListTemplate
    })
    .state('root.nav.pbx.dids.view.logs', {
      url: '/logs',
      template: didLogsTemplate
    })
})
