import angularMailcheckTemplate from './angularMailcheckTemplate.html'
import angularMailcheckController from './angularMailcheckController.js'
import _module from 'module.js'

_module.component('angularMailcheck', {
  template: angularMailcheckTemplate,
  transclude: true,
  controller: angularMailcheckController,
  controllerAs: 'ctrl',
  bindings: {
    email: '<',
    onCorrection: '&'
  }
})
