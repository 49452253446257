/**
 * @ngdoc function
 * @name platformApp.controller:GroupController
 * @description
 * # GroupController
 * Controller of the platformApp
 */
import _module from 'module.js'
import ListControllerBase from 'classes/listController.class'

export default class GroupsController extends ListControllerBase {
  constructor (
    $state,
    $stateParams,
    $location,
    $analytics,
    Group,
    gettextCatalog,
    infiniteLoader,
    navigation,
    globalState,
    portalUtil,
    userService,
  ) {
    'ngInject'
    super(
      gettextCatalog,
      $analytics,
      $location,
      portalUtil,
      globalState,
      navigation,
      $state,
      $stateParams
    )

    this._userService = userService
    this._globalState = globalState

    this.toggleVisibleItemsFromPermissions()

    this.gettextCatalog = gettextCatalog
    this.title = this.gettextCatalog.getString('User Groups')
    this.headers = [
      {
        width: 20,
        name: this.gettextCatalog.getString('Name'),
        sortBy: 'name',
        asc: true
      },
      {
        width: 35,
        name: this.gettextCatalog.getString('Members')
      },
      {
        width: 35,
        name: this.gettextCatalog.getString('Member of')
      },
      {
        width: 10,
        name: ''
      }
    ]
    this.loader = infiniteLoader(Group)
  }

  toggleVisibleItemsFromPermissions() {
    // Users with manage users cannot edit or delete a group
    this.hideEdit = false
    this.hideDelete = false
    this.buttonText = this.gettextCatalog.getString('Add Group')
    if (!this.globalState.isUserManageGroupsOnly && (this.globalState.isUserManageUsersOnly || this.globalState.isUserManageSeatsOnly)) {
      this.hideEdit = true
      this.hideDelete = true
      this.buttonText = undefined
    }
  }

  delete (event, item) {
    let name = this.gettextCatalog.getString('group') + ': ' + item.name
    this.loader.entityApi.startDelete(event, item, this.data, name)
  }

  itemSelected (item) {
    super.itemSelected(item, 'root.nav.pbx.groups.view.general', 'groupId')
  }
}

_module.controller('GroupsController', GroupsController)
