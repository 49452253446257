import _module from 'module.js'
import _ from 'lodash'
import jrnParser from 'classes/jrnParser'

let _deletionConfirmation, _analytics, _portalUtil, _jrn, _anchorScroll

export default class dataController {
  constructor (dataApi, Type, gettextCatalog) {
    'ngInject'
    this.api = dataApi
    this.Type = Type
    this.gettextCatalog = gettextCatalog
  }

  _typeFactory (entity) {
    if (entity.jrn && this.api.getClass() !== 'ExtensionMapping') {
      var id = _jrn.parse(entity.jrn).getResources()[1]
      return new this.Type(id, entity)
    }
    if (this.api.getClass() === 'Jbc') {
      return new this.Type(entity.id, entity)
    }
    return new this.Type(null, entity)
  }

  createFromType (item) {
    return this._typeFactory(item)
  }

  createNew (state) {
    if (state.items && (!state.items[0] || !state.items[0].isNew)) {
      _analytics.eventTrack('Data Controller: Create', {
        category: 'Data Controller'
      })
      // set the location.hash to the id of
      // the element you wish to scroll to.
      _anchorScroll()

      let newItem = this.createFromType({ isNew: true })
      state.items.unshift(newItem)
    }
  }

  saveNew (entity, state) {
    state.loading = true
    _analytics.eventTrack('Data Controller: Save', {
      category: 'Data Controller'
    })
    let createType = this.Type
    return this._typeFactory(entity)
      .save()
      .then(savedEntity => {
        let newItem = savedEntity.plain()
        let itemType = new createType(null, null)
        if (itemType.cleanListItem) {
          newItem = itemType.cleanListItem(newItem)
        }
        state.items[0] = newItem
        state.items[0].type = entity.type
        state.totalCount++
        state.matchCount++
        state.loading = false
        return savedEntity.plain()
      })
      .catch(error => {
        _portalUtil.showErrorAlert(
          this.gettextCatalog.getString('Save failed, please retry.')
        )
        state.loading = false
        throw error
      })
  }

  confirmDelete (entity, state) {
    state.loading = true
    _analytics.eventTrack('Data Controller: Delete', {
      category: 'Data Controller'
    })
    let deleteType = this.Type
    return this._typeFactory(entity)
      .delete()
      .then(() => {
        let itemType = new deleteType(null, null)
        _.remove(state.items, i => {
          return i.jrn === entity.jrn
        })
        if (itemType.cleanOldInfo) {
          _.map(state.items, item => {
            return itemType.cleanOldInfo(item, entity)
          })
        }
        state.totalCount--
        state.matchCount--
        state.loading = false
      })
      .catch(error => {
        _portalUtil.showErrorAlert(
          this.gettextCatalog.getString('Delete failed, please retry.')
        )
        state.loading = false
        throw error
      })
  }

  startDelete (event, entity, state, name) {
    _analytics.eventTrack('Data Controller: Start Delete', {
      category: 'Data Controller'
    })

    _deletionConfirmation
      .confirm(event, name, entity, this._typeFactory(entity))
      .then(
        () => {
          this.confirmDelete(entity, state)
        },
        () => {
          // do nothing for cancel.
        }
      )
      .catch(error => {
        _portalUtil.showErrorAlert(
          this.gettextCatalog.getString('Delete failed, please retry.')
        )
        throw error
      })
  }

  update (entity) {
    _analytics.eventTrack('Data Controller: Update', {
      category: 'Data Controller'
    })
    var Type = this._typeFactory(entity)
    return Type.get()
      .then(() => {
        Type.extend(entity).update().catch(error => {
          _portalUtil.showErrorAlert(
            this.gettextCatalog.getString('Save failed, please retry.')
          )
          throw error
        })
      })
      .catch(error => {
        _portalUtil.showErrorAlert(
          this.gettextCatalog.getString('Save failed, please retry.')
        )
        throw error
      })
  }

  cancelNew (state) {
    _analytics.eventTrack('Data Controller: Cancel Create', {
      category: 'Data Controller'
    })
    state.items.shift()
  }

  static factory (
    gettextCatalog,
    deletionConfirmation,
    $analytics,
    portalUtil,
    $anchorScroll
  ) {
    'ngInject'
    _deletionConfirmation = deletionConfirmation
    _analytics = $analytics
    _portalUtil = portalUtil
    _jrn = jrnParser
    _anchorScroll = $anchorScroll

    return (dataApi, Type) => {
      return new dataController(dataApi, Type, gettextCatalog)
    }
  }
}

_module.factory('dataController', dataController.factory)
