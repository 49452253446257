import playlistsTemplate from './playlistsTemplate.html'
import _module from 'module.js'
import jrnParser from 'classes/jrnParser'
import _ from 'lodash'

let _jrn, _HoldMusicPlaylist
export default class playlistsController {
  constructor (
    HoldMusicPlaylist,
    $location,
    $interval,
    globalState,
    portalApi,
    gettextCatalog,
    holdMusic,
    LIMIT_DEFAULT,
    $analytics,
    deletionConfirmation,
    portalUtil,
    usagePopup
  ) {
    'ngInject'
    _jrn = jrnParser
    this.portalApi = portalApi
    this.gettextCatalog = gettextCatalog
    this.globalState = globalState
    this.holdMusic = holdMusic
    this.deletionConfirmation = deletionConfirmation
    this.portalUtil = portalUtil
    this.usagePopUp = usagePopup
    this.$analytics = $analytics
    this.$interval = $interval
    this.$location = $location
    _HoldMusicPlaylist = HoldMusicPlaylist
    this.LIMIT_DEFAULT = LIMIT_DEFAULT

    this.offset = 0
    this.query = null // no query UI for playlist

    this.HoldMusicPlaylistApi = new _HoldMusicPlaylist(null)

    // the model
    this.defaultPlaylist = new _HoldMusicPlaylist('default')
    this.silentPlaylist = new _HoldMusicPlaylist('silent')
    this.playlists = []
    this.newPlaylist = null
    this.noMoreData = false
    this.selectedListId = null

    this.$onInit = () => {
      this.ngModel.$render = () => {
        if (!this.selectedList) return
        this.selectedListId = this.selectedList._id
      }
    }

    this.search(this.offset, this.LIMIT_DEFAULT).then(data => {
      this.offset = this.offset + this.LIMIT_DEFAULT
      this.total = data.totalCount
      this.playlistCount = data.totalCount + 1
      if (data.items.length < this.LIMIT_DEFAULT) {
        this.noMoreData = true
      }
      this.playlists = this.playlists.concat(data.items.map(this.mapPlaylist))
    })

    // Paging
    this.debouncedQuery = _.debounce(() => {
      let _this = this
      this.search(this.offset, this.LIMIT_DEFAULT).then(data => {
        this.offset = this.offset + this.LIMIT_DEFAULT

        if (data.items.length < this.LIMIT_DEFAULT) {
          _this.noMoreData = true
        }
        _this.playlists = _this.playlists.concat(
          data.items.map(this.mapPlaylist)
        )
      })
    }, 200)
  }

  search (offsetParam, limit) {
    return this.HoldMusicPlaylistApi.getListResult({
      q: this.query,
      offset: offsetParam,
      limit: limit,
      language: this.globalState.currentLanguage.langCode
    })
  }

  requestMorePlaylists () {
    if (this.playlists.length > 1) {
      this.debouncedQuery()
    }
  }

  mapPlaylist (playlist) {
    let id
    if (playlist.hasOwnProperty('jrn')) {
      id = _jrn.parse(playlist.jrn).getResources()[1]
    } else {
      id = playlist._id
    }

    let list = new _HoldMusicPlaylist(id)
    list.map(playlist)
    return list
  }

  applyQuery (query) {
    this.query = query
    this.$location.search('q', this.query === '' ? null : this.query)
    // TODO fix setting offset = 0 if it becomes a problem
    this.offset = 0
    this.playlists = []
    this.noMoreData = false
    this.debouncedQuery()
  }

  save (item) {
    let id
    if (item.hasOwnProperty('jrn')) {
      id = _jrn.parse(item.jrn).getResources()[1]
    } else {
      id = item._id
    }

    let playlist = new _HoldMusicPlaylist(id)
    return playlist.get().then(() => {
      playlist.map(item)
      return playlist.update().then(null, function (error) {
        this.portalUtil.showErrorAlert(
          this.gettextCatalog.getString(
            'Saving a playlist failed, please retry.'
          )
        )
        throw error
      })
    })
  }

  delete (event, item) {
    this.$analytics.eventTrack('HoldMusic Playlist: Start Delete', {
      category: 'HoldMusicPlaylist'
    })
    let displayName =
      this.gettextCatalog.getString('playlist') + ': ' + item.name
    let playlist = this.mapPlaylist(item)

    this.deletionConfirmation
      .confirm(event, displayName, item, playlist)
      .then(
        () => {
          this.confirmDelete(item)
        },
        () => {
          // do nothing for cancel.
        }
      )
      .catch(error => {
        this.portalUtil.showErrorAlert(
          this.gettextCatalog.getString('Delete failed, please retry.')
        )
        throw error
      })
  }

  getUsage (event, item) {
    let name = this.gettextCatalog.getString('Playlist') + ': ' + item.name
    let id
    if (item.jrn) {
      id = _jrn.parse(item.jrn).getResources()[1]
    } else {
      id = item._id
    }
    this.usagePopUp
      .open(event, name, item, new _HoldMusicPlaylist(id, item))
      .then(() => {}, () => {})
      .catch(error => {
        this.portalUtil.showErrorAlert(
          this.gettextCatalog.getString('Query failed, please retry.')
        )
        throw error
      })
  }

  confirmDelete (item) {
    let playlist = this.mapPlaylist(item)
    return playlist
      .delete()
      .then(() => {
        _.remove(this.playlists, i => {
          return i.getId() === playlist.getId()
        })
        this.total--
        this.playlistCount--
        if (this.selectedListId === item._id) {
          this.selectedList = this.defaultPlaylist
        }
      })
      .catch(error => {
        this.portalUtil.showErrorAlert(
          this.gettextCatalog.getString('Deletion of playlist failed.')
        )
        throw error
      })
  }

  retrieveMusic (item) {
    return item.get()
  }

  createNewPlaylist () {
    if (!this.newPlaylist) {
      let playlist = new _HoldMusicPlaylist(null)
      playlist.isNew = true
      this.newPlaylist = playlist
    }
  }

  saveNewPlaylist (item) {
    return item
      .save()
      .then(savedPlaylist => {
        delete this.newPlaylist
        let playlist = this.mapPlaylist(savedPlaylist)
        this.$interval(
          () => {
            this.playlists.unshift(playlist)
            this.total++
            this.playlistCount++
          },
          200,
          1
        )
      })
      .catch(error => {
        this.portalUtil.showErrorAlert(
          this.gettextCatalog.getString('Save failed, please retry.')
        )
        throw error
      })
  }

  cancelNewPlaylist () {
    delete this.newPlaylist
  }

  playlistSelectedHandler (item) {
    this.retrieveMusic(item).then(
      data => {
        this.selectedListId = data._id
        this.ngModel.$setViewValue(data)
        // item.HMLoading is set to false inside playlistItemsDirective
      },
      error => {
        item.HMLoading = false
        this.portalUtil.showErrorAlert(
          this.gettextCatalog.getString(
            'Retrieving music of playlist ' +
              item.name +
              ' failed, please retry.'
          )
        )
        throw error
      }
    )
  }
}
