// --
// utils constants/functions for permissions
// -
//
const ADMIN = 'admin'

const CONFIGURE_PBX_ACCESS = 'configPbx'
const MANAGE_SEATS = 'manageSeats'
const MANAGE_USERS = 'manageUsers'
const MANAGE_GROUPS = 'manageGroups'
const MANAGE_SETTINGS = 'manageSettings'
const BILLING_VIEW_ACCESS = 'viewInvoices'
const BILLING_ACCESS = 'payInvoices'
const REPORTS_ACCESS = 'reports'
const REPORTS_RECORDING_ACCESS = 'reportRecordings'

const DIAL_INTERNAL = 'internal'
const DIAL_LOCAL = 'local'
const DIAL_DOMESTIC_LONG_DISTANCE = 'longDist'
const DIAL_DOMESTIC_MOBILE = 'mobile'
const DIAL_INTERNATIONAL = 'international'
const DIAL_TOLL_NUMBER = 'tollNumbers'
const DIAL_MALICIOUS_AREA_CODE = 'malAreaCodes'

export const GENERAL_PERMISSIONS_MAP = {
  CONFIGURE_PBX_ACCESS,
  MANAGE_SEATS,
  MANAGE_USERS,
  MANAGE_GROUPS,
  MANAGE_SETTINGS,
  BILLING_VIEW_ACCESS,
  BILLING_ACCESS,
  REPORTS_ACCESS,
  REPORTS_RECORDING_ACCESS
}

export const DIALING_PERMISSIONS_MAP = {
  DIAL_INTERNAL,
  DIAL_LOCAL,
  DIAL_DOMESTIC_LONG_DISTANCE,
  DIAL_DOMESTIC_MOBILE,
  DIAL_INTERNATIONAL,
  DIAL_TOLL_NUMBER,
  DIAL_MALICIOUS_AREA_CODE
}

const CONFIGURE_PBX_CHILDREN = ['MANAGE_SEATS', 'MANAGE_USERS', 'MANAGE_GROUPS', 'MANAGE_SETTINGS']

export const PERMISSION_CHILDREN = {
  [BILLING_VIEW_ACCESS]: [BILLING_ACCESS],
  [REPORTS_ACCESS]: [REPORTS_RECORDING_ACCESS]
}

const initPermissionsMapObj = (permissionsMap, initialValue = false) =>
  Object.values(permissionsMap).reduce(
    (acc, permission) => Object.assign({}, acc, { [permission]: initialValue }),
    {}
  )

export const NO_GENERAL_PERMISSIONS = initPermissionsMapObj(
  GENERAL_PERMISSIONS_MAP
)
export const ALL_GENERAL_PERMISSIONS = initPermissionsMapObj(
  GENERAL_PERMISSIONS_MAP,
  true
)

export const validatePermissions = (type, permissionsMap) => {
  if (type === 'ADMIN') {
    return { type, [ADMIN]: true }
  }
  if (type === 'NONE') {
    return Object.assign({ type }, { [ADMIN]: false }, NO_GENERAL_PERMISSIONS)
  }
  return Object.assign({ type }, NO_GENERAL_PERMISSIONS, permissionsMap, {
    [ADMIN]: false
  })
}

export const mapSimpleSRI = (target, resolveIdentityPath) => ({
  id: target.identity,
  text: getName(target),
  path: resolveIdentityPath(target)
})

export const getName = (target, everyoneName) => {
  let name = target.userGroupName
  if (target.userLastName) {
    name = target.userLastName
    if (target.userFirstName) {
      name = target.userFirstName + ' ' + name
    }
  }
  if (!target.identity) {
    name = everyoneName
  }
  return name
}

const hasPermission = (target, permission) =>
  target.permissions.includes(permission)

export const shouldHide = target => target.identity === null

// general
export const isAdmin = target => hasPermission(target, 'ADMIN')
export const isConfigPBX = target =>
  hasPermission(target, 'CONFIGURE_PBX_ACCESS')
export const isManageSeats = target =>
  hasPermission(target, 'MANAGE_SEATS')
export const isManageUsers = target =>
  hasPermission(target, 'MANAGE_USERS')
export const isManageGroups = target =>
  hasPermission(target, 'MANAGE_GROUPS')
export const isManageSettings = target =>
  hasPermission(target, 'MANAGE_SETTINGS')
export const isViewReports = target => hasPermission(target, 'REPORTS_ACCESS')
export const isReportRecordings = target =>
  hasPermission(target, 'REPORTS_RECORDING_ACCESS')
export const isViewInvoices = target =>
  hasPermission(target, 'BILLING_VIEW_ACCESS')
export const isPayInvoices = target => hasPermission(target, 'BILLING_ACCESS')

// dialing
export const isInternal = target => hasPermission(target, 'DIAL_INTERNAL')
export const isLocal = target => hasPermission(target, 'DIAL_LOCAL')
export const isLongDistance = target =>
  hasPermission(target, 'DIAL_DOMESTIC_LONG_DISTANCE')
export const isMobile = target => hasPermission(target, 'DIAL_DOMESTIC_MOBILE')
export const isInternational = target =>
  hasPermission(target, 'DIAL_INTERNATIONAL')
export const isTollNumber = target => hasPermission(target, 'DIAL_TOLL_NUMBER')
export const isMalicious = target =>
  hasPermission(target, 'DIAL_MALICIOUS_AREA_CODE')

export const mapGeneral = target => ({
  identity: target.id,
  permissions: buildGeneralPermissions(target)
})

const permissionsMapToArray = (target, permissionsMap) => {
  let perms = Object.entries(permissionsMap).reduce((permissions, [permission, field]) => {
    if (target[field]) {
      permissions.push(permission)
    }
    return permissions
  }, [])

  if (perms.includes('CONFIGURE_PBX_ACCESS')) {
    perms = perms.filter(perm => !CONFIGURE_PBX_CHILDREN.includes(perm))
  }
  return perms
}

export const buildGeneralPermissions = target =>
  target.admin
    ? ['ADMIN']
    : permissionsMapToArray(target, GENERAL_PERMISSIONS_MAP)

export const mapDialing = target => ({
  identity: target.id,
  permissions: buildDialingPermissions(target)
})

const buildDialingPermissions = target =>
  permissionsMapToArray(target, DIALING_PERMISSIONS_MAP)

export const mapPrompts = target => ({
  identity: target.id,
  permissions: ['RECORD_PROMPTS']
})
