/**
 * @ngdoc function
 * @name platformApp.factory:directoryService
 * @description
 * # directoryRoomService
 * Controller of the platformApp
 */
import _module from 'module.js'
import BaseES6 from 'factories/baseES6'

let _stateParams, _portalApi, _globalState

export default class DeviceStatus extends BaseES6 {
  constructor (id, status, gettextCatalog) {
    'ngInject'
    super(_stateParams.deviceId || id)

    this.gettextCatalog = gettextCatalog

    if (id === undefined) {
      throw new Error(
        this.gettextCatalog.getString('Missing id for new DeviceStatus')
      )
    }

    this._entries = []

    if (status) {
      this._map(status)
    }
  }

  getApi () {
    if (!this._api) {
      this._api = _portalApi.userAgent.pbxs(
        _globalState.selectedPbxUUID
      ).deviceStatusList
    }

    return this._api
  }

  static factory ($stateParams, portalApi, gettextCatalog, globalState) {
    'ngInject'
    _stateParams = $stateParams
    _portalApi = portalApi
    _globalState = globalState

    return (id, status) => {
      return new DeviceStatus(id, status, gettextCatalog)
    }
  }
}

_module.factory('DeviceStatus', DeviceStatus.factory)
