const isCompleteButtonConfig = buttonConfig => {
  if (!buttonConfig.type) {
    return false
  }
  if (buttonConfig.type !== 'ON_PHONE_DEFINED') {
    return buttonConfig.label && buttonConfig.parameters
  }
  return true
}

const parseDeviceGroupIndex = id => Number(id.split(':')[0])

const parseButtonIndex = id => Number(id.split(':')[1])

const fillInUnconfiguredButtonConfigs = (unconfiguredCount, collection) => {
  _.times(unconfiguredCount, () => {
    collection.push({
      label: '',
      type: '',
      userEditable: false
    })
  })
  return collection
}

export class ButtonConfigController {
  constructor () {
    this.buttonConfigs = []
    this.sidecarButtonConfigs = []
    this.availableButtonTypes = []
    this.sidecars = {}
    this.sidecarCapable = true
    this.editable = [
      'SPEED_DIAL',
      'CALL_PARK',
      'MONITORED_EXTENSION',
      'BUDDY_WATCH'
    ]

    // TODO Save this as a backend field?
    this.selectAllSpeedDialButtons = false
    this.isSelectAllSpeedDialButtonsIndeterminate = true
  }

  assignSidecarVisibility (idx) {
    _.each(this.sidecars, (sidecar, i) => {
      if (i !== idx.toString()) {
        this.sidecars[i] = false
      } else {
        this.sidecars[idx] = !this.sidecars[idx]
      }
    })
  }

  serializeButtonConfigs (buttonConfigs, index) {
    return _(buttonConfigs).reduce((list, buttonConfig, idx) => {
      if (isCompleteButtonConfig(buttonConfig)) {
        list[index + ':' + idx] = buttonConfig
      }
      return list
    }, {})
  }

  changeAllConfigsToEditable (bool) {
    const setCorrectConfigsToEditable = buttonConfig => {
      if (this.editable.includes(buttonConfig.type)) {
        buttonConfig.userEditable = bool
      }
    }

    this.buttonConfigs.forEach(setCorrectConfigsToEditable)
    this.sidecarButtonConfigs.forEach(sidecarButtonConfigs => {
      sidecarButtonConfigs.forEach(setCorrectConfigsToEditable)
    })
    this.isSelectAllSpeedDialButtonsIndeterminate = false
  }

  setSelectAllSpeedDialButtons () {
    // NOTE: this may need some extension points in the future if other button config
    // variables are used for speed dials (other than buttonConfigs and sidecarButtonConfigs)

    const checkUserEditableByValue = (buttonConfig, value) => {
      if (this.editable.includes(buttonConfig.type)) {
        return buttonConfig.userEditable === value
      } else {
        return true
      }
    }

    const checkUserEditableByTrue = buttonConfig =>
      checkUserEditableByValue(buttonConfig, true)

    const checkUserEditableByFalse = buttonConfig =>
      checkUserEditableByValue(buttonConfig, false)

    const isButtonConfigsEditableAllTrue = _.every(
      this.buttonConfigs,
      checkUserEditableByTrue
    )

    const isButtonConfigsEditableAllFalse = _.every(
      this.buttonConfigs,
      checkUserEditableByFalse
    )

    const isSidecarButtonConfigsEditableAllTrue = _.every(
      this.sidecarButtonConfigs,
      sidecar => _.every(sidecar, checkUserEditableByTrue)
    )

    const isSidecarButtonConfigsEditableAllFalse = _.every(
      this.sidecarButtonConfigs,
      sidecar => _.every(sidecar, checkUserEditableByFalse)
    )

    this.isSelectAllSpeedDialButtonsIndeterminate = !(
      (isButtonConfigsEditableAllTrue &&
        isSidecarButtonConfigsEditableAllTrue) ||
      (isButtonConfigsEditableAllFalse &&
        isSidecarButtonConfigsEditableAllFalse)
    )

    this.selectAllSpeedDialButtons =
      isButtonConfigsEditableAllTrue && isSidecarButtonConfigsEditableAllTrue
  }

  loadSerializedButtons () {
    throw new Error('Subclasses must override loadSerializedButtons')
  }

  buttonConfigEdited () {
    this.ngModel.$setViewValue(this.loadSerializedButtons())
  }

  createAndFillAllDeviceButtons (numberOfButtons, preconfiguredButtons) {
    const allDeviceButtons = fillInUnconfiguredButtonConfigs(
      numberOfButtons,
      []
    )

    if (preconfiguredButtons) {
      preconfiguredButtons.forEach(button => {
        allDeviceButtons[button.index] = button.button
      })
    }

    return allDeviceButtons
  }

  createAndFillAllSidecarCollections (userAgent, sideCarAndDeviceButtons) {
    _.times(userAgent.maxNumberOfSidecars, sidecarNumber => {
      let sidecarWithAllConfigsFilledIn = fillInUnconfiguredButtonConfigs(
        userAgent.numberSidecarButtons,
        []
      )

      if (sideCarAndDeviceButtons[sidecarNumber]) {
        sideCarAndDeviceButtons[sidecarNumber].forEach(buttonConfig => {
          sidecarWithAllConfigsFilledIn[buttonConfig.index] =
            buttonConfig.button
        })
      }
      this.sidecarButtonConfigs.push(sidecarWithAllConfigsFilledIn)
    })

    _.each(this.sidecarButtonConfigs, (sidecar, i) => {
      this.sidecars[i] = false
    })
  }

  seperateSidecarAndDeviceButtons (buttonConfigs) {
    return _(buttonConfigs).reduce(
      (allButtonConfigs, button, idx) => {
        const buttonGroupIndex = parseDeviceGroupIndex(idx)
        const buttonSetIndex = parseButtonIndex(idx)
        const _button = {
          button: button,
          index: buttonSetIndex
        }

        if (allButtonConfigs[buttonGroupIndex]) {
          allButtonConfigs[buttonGroupIndex].push(_button)
        } else {
          allButtonConfigs[buttonGroupIndex] = [_button]
        }
        return allButtonConfigs
      },
      [[]]
    )
  }

  /**
   * Use the provided buttons data to initialize any view specific data models on the controller.
   *
   * @param allButtonsByGroup array of arrays of buttons, each item in the array represents all the buttons in that one
   * @param userAgent the user agent model with information about number of buttons
   *   group
   */
  initButtonConfigSections (allButtonsByGroup, userAgent) {
    throw new Error('Subclasses must override initButtonConfigSections')
  }

  initWithUserAgent (userAgent) {
    this.availableButtonTypes = userAgent.supportedButtonTypes
    this.sidecarCapable = userAgent.sidecarCapable
  }

  initData () {
    const userAgent = this.configs.userAgentTypeModel
    this.initWithUserAgent(userAgent)

    const allButtonsByGroup = this.seperateSidecarAndDeviceButtons(this.buttons)
    this.initButtonConfigSections(allButtonsByGroup, userAgent)
    this.setSelectAllSpeedDialButtons()
  }

  $onInit () {
    this.ngModel.$render = () => this.initData()
  }
}
