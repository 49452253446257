import displayZohoUserNameController from './displayZohoUserNameController'
import _module from 'module.js'

let displayZohoUserNameComponent = {
  template: `<div>{{ctrl.getUser().userName}}</div>`,
  require: {
    ngModel: 'ngModel'
  },
  bindings: {
    integrationUserId: '=ngModel'
  },
  controllerAs: 'ctrl',
  controller: displayZohoUserNameController
}

_module.component('displayZohoUserName', displayZohoUserNameComponent)
