import _ from 'lodash'
import is from 'is'
import moment from 'moment'
import jrnParser from 'classes/jrnParser'

let _q, _analytics, _jrn, _urlProvider

class RowEditor {
  constructor ($q, $analytics, options, urlProvider) {
    'ngInject'
    _q = $q
    _analytics = $analytics
    _jrn = jrnParser
    _urlProvider = urlProvider
    this.options = options
    this.restrict = 'A'
    this.require = [
      'ngModel',
      options.controllerName,
      'form',
      '^parentForm',
      '^^?form'
    ]
    this.template = options.template
    this.allFormsPristineOnSave = options.allFormsPristineOnSave !== false

    this.scope = _.extend(
      {
        row: '=ngModel',
        translations: '<',
        onSelect: '&',
        onSave: '&',
        onDelete: '&',
        onCancel: '&',
        selected: '=',
        hideDelete: '<',
        hideEdit: '<',
        onDuplicate: '&',
        onExport: '&',
        onUsage: '&'
      },
      options.scope
    )

    this.controllerAs = 'rowEditor'
    this.bindToController = true
  }

  controller (
    portalConfig,
    $window,
    $location,
    globalState,
    extensionNotRecommendedPopup
  ) {
    'ngInject'
    this.$window = $window
    this.editing = false
    this.inline = true
    this.clonedData = _.cloneDeep(this.row)
    this.isResyncing = false
    this.linkCopyDelay = 10000
    this.busy = false
    this.usageIcon = portalConfig.usageIcon
    this.globalState = globalState
    this.extensionNotRecommendedPopup = extensionNotRecommendedPopup
    this.selectedPbx = this.globalState.selectedPbx
    this.isDidDeletionEnabled = globalState._selectedPbx.featureFlags['pbx.did.deletion']

    this.hideDelete = (this.row.jrn && is.include(this.row.jrn, 'did') && ( !this.isDidDeletionEnabled || (this.isDidDeletionEnabled && !this.row.active ) ) ) ||  this.hideDelete

    this.sharedLineIcon = portalConfig.sharedLineIcon
    this.eolIcon = portalConfig.eolIcon
    this.GoToIcons = portalConfig.GoTo

    this.row.isCisco7900Device = this.row.model
      ? this.row.model.match('Cisco 79.*|Cisco 89.*|Cisco 99.*')
      : false

    this.row.isYealinkT49Device = this.row.model
      ? this.row.model.match('Yealink.*T49G.*')
      : false

    this.row.doesSupportSecureCallingAndNotEos = this.row.secureCallingSupported &&
      (!this.row.isYealinkT49Device || (!!this.row.secureCallingMode))

    this.cancelEdit = ev => {
      _analytics.eventTrack('Cancel Edit Inline', { category: 'Row Editor' })
      ev.stopImmediatePropagation()
      this.editing = false
      this.audioPlaying = false
      this.row = this.clonedData
      this.parentForm.setAllFormsPristine()

      if (this.row.isNew) {
        this.onCancel()
      }
    }

    this.togglePlaying = ev => {
      if (ev) ev.stopImmediatePropagation()
      this.audioPlaying = !this.audioPlaying
    }

    this.playAudioTrack = url => {
      _analytics.eventTrack('Play Audio', { category: 'Row Editor' })
      this.fileUrl = url
      this.togglePlaying()
    }

    this.startAudioFile = (_jrn, ev) => {
      ev.stopImmediatePropagation()
      this.options.audioPlayerService.getSongUrl(_jrn).then(this.playAudioTrack)
    }

    this.downloadFile = (_jrn, ev) => {
      _analytics.eventTrack('Download File', { category: 'Row Editor' })
      ev.stopImmediatePropagation()
      this.options.mediaTypeSelector.open().then(mediaType => {
        this.options.audioPlayerService.downloadAudioFile(_jrn, mediaType)
      })
    }

    this.toggleEdit = () => {
      _analytics.eventTrack('Toggle Edit', { category: 'Row Editor' })
      this.editing = !this.editing
      this.clonedData = _.cloneDeep(this.row)
    }

    this.duplicate = (item, ev) => {
      _analytics.eventTrack('Duplicate', { category: 'Row Editor' })
      ev.stopImmediatePropagation()
      _q.when(
        this.onDuplicate({
          item
        })
      )
    }

    this.export = (item, ev) => {
      _analytics.eventTrack('Export', { category: 'Row Editor' })
      ev.stopImmediatePropagation()
      _q.when(
        this.onExport({
          item
        })
      )
    }

    this.navigate = (item, ev) => {
      if (typeof item === 'string') {
        const pbxId = jrnParser.parse(item).getAccount()
        // portal automagically converts it to a domain name
        return this.navigate({ domain: pbxId }, ev)
      }
      ev.stopImmediatePropagation()
      const prefix = is.include($location.host(), 'localhost') ? '' : 'pbx/'
      const url = '/' + prefix + item.domain + '/dashboard'
      this.$window.open(url, '_blank').focus()
    }

    this.navigateToDetailTab = (type, item, page, ev) => {
      ev.stopImmediatePropagation()
      let prefix = 'pbx/'
      if (is.include($location.host(), 'localhost')) {
        prefix = ''
      }
      let detailId = _jrn.parse(item.jrn).getResources()[1]
      let url =
        '/' +
        prefix +
        globalState.selectedPbx.domain +
        '/' +
        type +
        '/' +
        detailId +
        '/' +
        page
      this.$window.open(url, '_blank').focus()
    }

    this.save = ev => {
      if (!this.form.$valid) {
        return
      }

      _analytics.eventTrack('Save', { category: 'Row Editor' })
      if (ev) {
        ev.stopImmediatePropagation()
      }
      this.busy = true
      _q.when(
        this.onSave({
          item: this.row
        })
      )
        .then(data => {
          if (this.allFormsPristineOnSave) {
            this.parentForm.setAllFormsPristine()
          } else {
            this.parentForm.setPristine(this.form)
          }
          if (this.clonedData) {
            delete this.clonedData
          }
          this.editing = false
          this.busy = false
        })
        .catch(error => {
          this.busy = false
          this.editing = true
        })
    }

    this.delete = ev => {
      _analytics.eventTrack('Delete', { category: 'Row Editor' })
      ev.stopImmediatePropagation()
      this.onDelete({
        event: ev,
        item: this.row
      })
    }

    this.usage = ev => {
      ev.stopImmediatePropagation()
      this.onUsage({
        event: ev,
        item: this.row
      })
    }

    this.select = editing => {
      if (!editing) {
        this.onSelect({
          item: this.row
        })
      }
    }

    // Device specific code
    this.resync = (device, evt) => {
      _analytics.eventTrack('Resync', { category: 'Row Editor' })
      evt.stopImmediatePropagation()
      this.isResyncing = true
      this.options.$timeout(() => {
        this.isResyncing = false
      }, this.options.DEVICE_RESYNC_DELAY)
      return this.options.portalApi.userAgent
        .pbxs(this.options.globalState.selectedPbxUUID)
        .userAgentConfigurations()
        .one('resync')
        .one(jrnParser.parse(device.jrn).getResources()[1])
        .put()
        .catch(error => {
          this.options.portalUtil.showErrorAlert(
            this.options.gettextCatalog.getString(
              'Resync failed, please retry. \n' + error
            )
          )
        })
    }

    this.clickToCopy = ev => {
      if (ev) {
        ev.stopImmediatePropagation()
      }
    }

    this.clipboardSuccess = ev => {
      this.copyToolTip = this.options.gettextCatalog.getString(
        'Copied to Clipboard'
      )

      this.options.$timeout(() => {
        this.copyToolTip = this.options.gettextCatalog.getString(
          'Copy to Clipboard'
        )
      }, this.linkCopyDelay)

      ev.clearSelection()
    }

    this.clipboardError = ev => {
      this.copyToolTip = this.options.gettextCatalog.getString(
        'Press ⌘+C to copy'
      )
      this.options.$timeout(() => {
        this.copyToolTip = this.options.gettextCatalog.getString(
          'Copy to Clipboard'
        )
      }, this.linkCopyDelay)
    }

    this.changeExtensionType = () => {
      let newType = this.row.type
      if (newType !== this.row._obj.getClass().toLowerCase()) {
        this.row.changeExtensionType(newType)
      }
    }

    this.checkExtensionRecommended = () =>{
      if(this.selectedPbx.extensionDigits == 3 && this.row.extensionNumber == "112") {
        this.extensionNotRecommendedPopup.open().then(cleanUp => {
          if(cleanUp) {
            this.row.extensionNumber = ""
          }
        })
      }
    }
  }

  link (scope, el, attrs, ctrls) {
    var ngModel = ctrls[0]
    var ctrl = ctrls[1]
    ctrl.form = ctrls[2]
    ctrl.parentForm = ctrls[3]
    ctrl.tableForm = ctrls[4]

    ctrl.parentForm.addForm(ctrl.form)

    if (ctrl.tableForm && ctrl.tableForm.$$parentForm) {
      ctrl.parentForm.addForm(ctrl.tableForm.$$parentForm)
    }

    ctrl.options = this.options

    if (this.options.gettextCatalog) {
      ctrl.copyToolTip = this.options.gettextCatalog.getString(
        'Copy to Clipboard'
      )
    }

    if (this.options.globalState) {
      if (
        this.options.globalState.selectedPbx.partnerInfo &&
        this.options.$location
      ) {
        ctrl.hideConfLinks = is.include(this.options.$location.host(), 'hvoice')
      } else {
        ctrl.hideConfLinks = false
      }
    } else {
      ctrl.sharedLineIcon = null
    }

    ctrl.confUrl = null
    if (ctrl.row.url) {
      let location = this.options.$location
      ctrl.confUrl =
        location.protocol() +
        '://' +
        location.host() +
        ':' +
        location.port() +
        ctrl.row.url
    }

    ngModel.$render = () => {
      if (ctrl.row.isNew) {
        ctrl.toggleEdit()
      }

      var extensions = ctrl.row.extensions
      var isOnline = false
      for (var i in extensions) {
        if (extensions[i].status === 'ONLINE') {
          isOnline = true
          break
        }
      }

      ctrl.deviceOnline = isOnline
      ctrl.enableResync = ctrl.row.deviceResyncEnabled
      ctrl.deviceIsUpToDate = ctrl.row.userAgentLastCheckin
        ? moment(ctrl.row.userAgentLastCheckin) >= moment(ctrl.row.lastModified)
        : false
    }

    ctrl.isValidInput = () => {
      return ctrl.form.$valid
    }

    ctrl.getIdFromJrn = jrn => {
      if (jrn) {
        return jrnParser.parse(jrn).getResources()[1]
      }

      return ''
    }

    ctrl.getAccountFromJrn = jrn =>
      jrn ? jrnParser.parse(jrn).getAccount() : ''
  }
}

export default RowEditor
