import _module from 'module.js'
import _ from 'lodash'
import BaseES6 from 'factories/baseES6'

let _globalState, _portalApi

export default class Proxies extends BaseES6 {
  constructor (id, proxy) {
    'ngInject'
    super(id, _globalState.selectedPbxUUID)

    if (proxy) {
      this._map(proxy)
    }
  }

  getApi () {
    if (!this._api) this._api = _portalApi.userAgent.pbxs(this._pbxId).proxies
    return this._api
  }

  getProxyApi () {
    return _portalApi.pbx.pbxs(this._pbxId).one('reg-proxy')
  }

  map (data) {
    if (data.isNew && !this.protocol) {
      this.protocol = 'UDP'
    }
  }

  getPath () {
    if (!this._path) { this._path = _portalApi.userAgent.pbxs(this._pbxId).proxies(this._id) }

    return this._path
  }

  getReferencesPath () {
    if (!this._referencesPath) {
      this._referencesPath = _portalApi.userAgent
        .pbxs(this._pbxId)
        .proxies(this._id)
        .one('references')
    }
    return this._referencesPath
  }

  setDefaultRegProxy (proxy) {
    return _.extend(this.getProxyApi(), proxy).put()
  }

  removeDefaultRegProxy () {
    return _.extend(this.getProxyApi(), { defaultProxy: null }).put()
  }

  getClass () {
    return 'Proxy'
  }

  static factory (globalState, portalApi, gettextCatalog) {
    'ngInject'
    _globalState = globalState
    _portalApi = portalApi

    return (id, proxy, pbxId = null) => {
      return new Proxies(id, proxy, pbxId)
    }
  }
}

_module.factory('Proxies', Proxies.factory)
