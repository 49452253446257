import _module from 'module.js'
import awsSecretsPopupTemplate
  from '../views/common/awsSecretsPopupTemplate.html'
import jrnParser from 'classes/jrnParser'

class AwsSecretsController {
  constructor (
    $analytics,
    gettextCatalog,
    portalUtil,
    portalApi,
    $mdDialog,
    globalState,
    awsData
  ) {
    'ngInject'
    this.gettextCatalog = gettextCatalog

    this.$analytics = $analytics
    this.$mdDialog = $mdDialog
    this.portalUtil = portalUtil
    this.portalApi = portalApi
    this.jrn = jrnParser
    this.globalState = globalState
    this.data = awsData

    this.formBusy = false


  }

  complete () {
    this.$mdDialog.hide(this.data)
  }

  async updateAWSCredentials () {
    this.$analytics.eventTrack('Call Recording: AWS Secrets Saved', {
      category: 'Settings'
    })
    const payload = this.portalApi.pbx
      .pbxs(this.globalState.selectedPbxUUID)
      .one('aws-secret')
    payload.storageSecretAccessKey = this.storageSecretAccessKey
    payload.storageBucketName = this.data.storageBucketName
    payload.storageAccessKeyId = this.data.storageAccessKeyId
    this.data.busy = true
    try {
      await payload.put()
      this.data.hasStorageSecretAccessKey = true
      this.enterNewStorageAccessKey = false
      this.data.busy = false
    } catch (err) {
      this.enterNewStorageAccessKey = false
      this.storageSecretAccessKey = ''
      this.data.busy = false
      this.$analytics.eventTrack('Call Recording: AWS Secrets Save Failed', {
        category: 'Settings'
      })
      this.portalUtil.showErrorAlert(
        this.gettextCatalog.getString(
          'Failed to update AWS Credentials, please retry.'
        )
      )
    }
  }

  cancelDialog () {
    this.$mdDialog.cancel()
  }
}

class AwsSecretsPopup {
  constructor (
    $mdDialog,
    $analytics,
    gettextCatalog,
    portalUtil,
    portalApi,
    globalState
  ) {
    'ngInject'
    this.$mdDialog = $mdDialog
    this.$analytics = $analytics
    this.gettextCatalog = gettextCatalog
    this.portalUtil = portalUtil
    this.portalApi = portalApi
    this.globalState = globalState
  }

  open (awsData) {
    this.$analytics.eventTrack('Call Recording: AWS Secrets Open', {
      category: 'Settings'
    })

    return this.$mdDialog.show({
      template: awsSecretsPopupTemplate,
      controllerAs: 'ctrl',
      controller: AwsSecretsController,
      locals: {
        $mdDialog: this.$mdDialog,
        gettextCatalog: this.gettextCatalog,
        portalUtil: this.portalUtil,
        portalApi: this.portalApi,
        $analytics: this.$analytics,
        globalState: this.globalState,
        awsData: awsData || {}
      },
      parent: angular.element(document.body),
      clickOutsideToClose: true,
      fullscreen: true
    })
  }
}

_module.service('awsSecretsPopup', AwsSecretsPopup)
