import _module from 'module.js'
import deletionConfirmationTemplate
  from '../views/common/deletionConfirmation.html'
import deletionConfirmationController from './deletionConfirmationController.js'

export default class DeletionConfirmation {
  constructor ($mdDialog, $mdMedia, $state, $window, DialPlan, extensionTypes) {
    'ngInject'
    this.$mdDialog = $mdDialog
    this.$mdMedia = $mdMedia
    this.$state = $state
    this.$window = $window
    this.DialPlan = DialPlan
    this.extensionTypes = extensionTypes

    this.useFullScreen = this.$mdMedia('sm') || this.$mdMedia('xs')
  }

  confirm (ev, name, item, entityType) {
    return this.$mdDialog.show({
      template: deletionConfirmationTemplate,
      parent: angular.element(document.body),
      targetEvent: ev,
      clickOutsideToClose: true,
      fullscreen: this.useFullScreen,
      controllerAs: 'ctrl',
      locals: {
        name: name,
        $mdDialog: this.$mdDialog,
        item: item,
        entityType: entityType,
        $state: this.$state,
        $window: this.$window,
        DialPlan: this.DialPlan,
        extensionTypes: this.extensionTypes
      },

      controller: deletionConfirmationController
    })
  }
}

_module.service('deletionConfirmation', DeletionConfirmation)
