import _module from 'module.js'
import _ from 'lodash'

_module.directive('uniqueEmergencyPhoneNumber', function (
  portalApi,
  $q,
  globalState
) {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, $input, attrs, ngModelCtrl) {
      let originalValue = scope.$eval(attrs.ngModel)

      ngModelCtrl.$asyncValidators.uniqueEmergencyPhoneNumber = (
        model,
        view
      ) => {
        var value = model || view
        var dfd = $q.defer()

        if (!value || value.length === 0) {
          dfd.resolve(true)
        }

        if (!originalValue || originalValue !== value) {
          if (value) {
            portalApi
              .customEmergencyNumbers(globalState.selectedPbxUUID)
              .getList()
              .then(
                data => {
                  if (!_.some(data, numberObj => numberObj.number === value)) {
                    dfd.resolve(true)
                  } else {
                    dfd.reject('exists')
                  }
                },
                err => {
                  $q.reject(err)
                }
              )
          }
        } else {
          dfd.resolve(true)
        }
        return dfd.promise
      }
    }
  }
})
