import _module from 'module.js'
import _ from 'lodash'

let _localStorage, _portalApi, _q, _tmhDynamicLocale, _languages

export default class Language {
  getSelectedLanguage () {
    let lang = _localStorage.selected_language
    if (!lang) {
      return _portalApi.language.get().then(data => {
        let language = null
        let groupedLanguages = _.groupBy(_languages, 'langCode')

        if (data.acceptLanguage) {
          let acceptedLanguage = data.acceptLanguage[0].language
          let acceptedLanguageExists = groupedLanguages[acceptedLanguage]
          if (acceptedLanguageExists) {
            language = groupedLanguages[acceptedLanguage][0]
          } else {
            language = _languages[0]
          }
        }
        return language
      })
    } else {
      let langJson = JSON.parse(lang)
      _tmhDynamicLocale.set(langJson.langCode.toLowerCase())
      return _q.when(langJson)
    }
  }

  static factory ($localStorage, portalApi, $q, tmhDynamicLocale, languages) {
    'ngInject'
    _localStorage = $localStorage
    _q = $q
    _tmhDynamicLocale = tmhDynamicLocale
    _portalApi = portalApi
    _languages = languages

    return new Language()
  }
}

_module.factory('language', Language.factory)

_module.factory('languages', function (gettext) {
  'ngInject'
  return [
    {
      text: gettext('English (US)'),
      flagCode: 'usa',
      langCode: 'en-US',
      regionCode: 'US'
    },
    {
      text: gettext('English (Australia)'),
      flagCode: 'australia',
      langCode: 'en-AU',
      regionCode: 'AU'
    },
    {
      text: gettext('English (Canada)'),
      flagCode: 'canada',
      langCode: 'en-CA',
      regionCode: 'CA'
    },
    {
      text: gettext('English (Ireland)'),
      flagCode: 'ireland',
      langCode: 'en-IE',
      regionCode: 'IE'
    },
    {
      text: gettext('English (United Kingdom)'),
      flagCode: 'uk',
      langCode: 'en-GB',
      regionCode: 'GB'
    },
    {
      text: gettext('French - Français (Canada)'),
      flagCode: 'canada',
      langCode: 'fr-CA',
      regionCode: 'CA'
    },
    {
      text: gettext('French - Français (France)'),
      flagCode: 'france',
      langCode: 'fr-FR',
      regionCode: 'FR'
    },
    {
      text: gettext('German - Deutsch (Deutschland)'),
      flagCode: 'germany',
      langCode: 'de-DE',
      regionCode: 'DE'
    },
    {
      text: gettext('Portuguese - Português (Brasil)'),
      flagCode: 'brazil',
      langCode: 'pt-BR',
      regionCode: 'BR'
    },
    {
      text: gettext('Spanish - Español (España)'),
      flagCode: 'spain',
      langCode: 'es-ES',
      regionCode: 'ES'
    },
    {
      text: gettext('Spanish - Español (México)'),
      flagCode: 'mexico',
      langCode: 'es-MX',
      regionCode: 'MX'
    }
  ]
})
