import _module from 'module.js'
import itemTemplate from 'controllers/item/itemTemplate.html'
import phoenixTemplate from 'controllers/phoenix/phoenixTemplate.html'
import extensionsUsageEcma6Template from 'views/common/extension.usage.ecma6.tab.html'
import linesTemplate from 'controllers/lines/linesTemplate.html'
import lineGeneralTemplate from 'controllers/line/general/lineGeneralTemplate.html'
import lineDialPlanTemplate from 'controllers/line/dialPlan/lineDialPlanTemplate.html'
import lineRecordingTemplate from 'controllers/line/recording/lineRecordingTemplate.html'
import lineFaxTemplate from 'controllers/line/fax/lineFaxTemplate.html'
import ringGroupsTemplate from 'controllers/ringGroups/ringGroupsTemplate.html'
import ringGroupGeneralTemplate from 'controllers/ringGroup/general/general.html'
import ringGroupMembersTemplate from 'controllers/ringGroup/members/members.html'
import ringGroupRecordingTemplate from 'controllers/ringGroup/recording/recording.html'
import conferenceRoomsTemplate from 'controllers/conferenceRooms/conferenceRoomsTemplate.html'
import conferenceRoomGeneralTemplate from 'controllers/conferenceRoom/general/general.html'
import conferenceRoomRecordingTemplate from 'controllers/conferenceRoom/recording/recording.html'
import conferenceRoomCallbckTemplate from 'controllers/conferenceRoom/callback/callbackTemplate.html'
import dialPlansTemplate from 'controllers/dialPlans/dialPlansTemplate.html'
import sharedVoicemailBoxesTemplate from 'controllers/sharedVoicemailBoxes/sharedVoicemailBoxesTemplate.html'
import sharedVoicemailBoxGeneralTemplate from 'controllers/sharedVoicemailBox/sharedVoicemailBoxGeneral/sharedVoicemailBoxGeneralTemplate.html'
import virtualFaxMachinesTemplate from 'controllers/virtualFaxMachines/virtualFaxMachinesTemplate.html'
import virtualFaxMachineGeneralTemplate from 'controllers/virtualFaxMachine/general/general.html'
import virtualFaxMachineSendingTemplate from 'controllers/virtualFaxMachine/sending/sending.html'
import virtualFaxMachineReceivingTemplate from 'controllers/virtualFaxMachine/receiving/receiving.html'
import virtualFaxMachinePermissionsTemplate from 'controllers/virtualFaxMachine/permissions/permissions.html'
import virtualFaxMachinePermissionsIdentityListTemplate from 'controllers/virtualFaxMachine/permissions/permissionsIdentityList.html'
import voicemailEs6Template from 'views/common/voicemail.es6.tab.html'
import voicemailAccessEs6Template from 'views/common/voicemailAccess.es6.tab.html'
import environments from '../configs/environments'

const basePath = environments.current === 'local' ? '/images' : '/pbx/images'

_module.config((gettext, $stateProvider, FEATURE_FLAGS) => {
  'ngInject'

  $stateProvider
    .state('root.nav.pbx.extensions.lines', {
      url: '/lines',
      abstract: true,
      template: '<ui-view class="line-abstract max-height"></ui-view>'
    })
    .state('root.nav.pbx.extensions.lines.list', {
      url: '?q&sort&direction&source',
      template: linesTemplate,
      controller: 'LinesController as ctrl',
      reloadOnSearch: false
    })
    .state('root.nav.pbx.extensions.lines.view', {
      url: '/:extId',
      template: itemTemplate,
      controller: 'LineController as ctrl',
      abstract: true,
      data: {
        title: gettext('Line'),
        itemIcon: 'icon-lines',
        tabs: [
          {
            id: 'line-general',
            label: gettext('General'),
            state: 'root.nav.pbx.extensions.lines.view.general'
          },
          {
            id: 'line-dial-plan',
            label: gettext('Dial Plan'),
            state: 'root.nav.pbx.extensions.lines.view.dial-plan'
          },
          {
            id: 'line-voicemail',
            label: gettext('Voicemail'),
            state: 'root.nav.pbx.extensions.lines.view.voicemail'
          },
          {
            id: 'line-voicemail-access',
            label: gettext('Voicemail Access'),
            state: 'root.nav.pbx.extensions.lines.view.voicemailAccess'
          },
          {
            id: 'line-recording',
            label: gettext('Recording'),
            state: 'root.nav.pbx.extensions.lines.view.recording'
          },
          {
            id: 'line-fax',
            label: gettext('Fax'),
            state: 'root.nav.pbx.extensions.lines.view.fax'
          },
          {
            id: 'line-usage',
            label: gettext('Usage'),
            state: 'root.nav.pbx.extensions.lines.view.usage'
          }
        ]
      }
    })
    .state('root.nav.pbx.extensions.lines.view.general', {
      url: '/general',
      template: lineGeneralTemplate
    })
    .state('root.nav.pbx.extensions.lines.view.dial-plan', {
      url: '/dial-plan',
      template: lineDialPlanTemplate
    })
    .state('root.nav.pbx.extensions.lines.view.voicemail', {
      url: '/voicemail',
      template: voicemailEs6Template
    })
    .state('root.nav.pbx.extensions.lines.view.voicemailAccess', {
      url: '/voicemailAccess',
      template: voicemailAccessEs6Template
    })
    .state('root.nav.pbx.extensions.lines.view.recording', {
      url: '/recording',
      template: lineRecordingTemplate
    })
    .state('root.nav.pbx.extensions.lines.view.usage', {
      url: '/usage',
      template: extensionsUsageEcma6Template
    })
    .state('root.nav.pbx.extensions.lines.view.fax', {
      url: '/fax',
      template: lineFaxTemplate
    })
    // -----
    .state('root.nav.pbx.extensions.ringgroupsv2', {
      url: '/ringgroups-v2',
      template: phoenixTemplate,
      controller: 'PhoenixController as ctrl',
    })
    .state('root.nav.pbx.extensions.ringgroupsv2.view', {
      url: '/:ringGroupId/:tab',
      template: phoenixTemplate,
      controller: 'PhoenixController as ctrl',
    })
    .state('root.nav.pbx.extensions.ringgroups', {
      url: '/ringgroups',
      abstract: true,
      template: '<ui-view class="ring-groups-abstract max-height"></ui-view>'
    })
    .state('root.nav.pbx.extensions.ringgroups.list', {
      url: '?q&sort&direction&source',
      template: ringGroupsTemplate,
      controller: 'RingGroupsController as ctrl',
      reloadOnSearch: false
    })
    .state('root.nav.pbx.extensions.ringgroups.view', {
      url: '/:extId',
      template: itemTemplate,
      controller: 'RingGroupController as ctrl',
      abstract: true,
      data: {
        itemIcon: `${basePath}/arrow-right.svg`,
        title: gettext('Ring Group'),
        tabs: [
          {
            id: 'ringgroup-general',
            label: gettext('General'),
            state: 'root.nav.pbx.extensions.ringgroups.view.general'
          },
          {
            id: 'ringgroup-members',
            label: gettext('Members'),
            state: 'root.nav.pbx.extensions.ringgroups.view.members'
          },
          {
            id: 'ringgroup-voicemail',
            label: gettext('Voicemail'),
            state: 'root.nav.pbx.extensions.ringgroups.view.voicemail'
          },
          {
            id: 'ringgroup-voicemail-access',
            label: gettext('Voicemail Access'),
            state: 'root.nav.pbx.extensions.ringgroups.view.voicemailAccess'
          },
          {
            id: 'ringgroup-recording',
            label: gettext('Recording'),
            state: 'root.nav.pbx.extensions.ringgroups.view.recording'
          },
          {
            id: 'ringgroup-usage',
            label: gettext('Usage'),
            state: 'root.nav.pbx.extensions.ringgroups.view.usage'
          }
        ]
      }
    })
    .state('root.nav.pbx.extensions.ringgroups.view.general', {
      url: '/general',
      template: ringGroupGeneralTemplate
    })
    .state('root.nav.pbx.extensions.ringgroups.view.members', {
      url: '/members',
      template: ringGroupMembersTemplate
    })
    .state('root.nav.pbx.extensions.ringgroups.view.voicemail', {
      url: '/voicemail',
      template: voicemailEs6Template
    })
    .state('root.nav.pbx.extensions.ringgroups.view.voicemailAccess', {
      url: '/voicemailAccess',
      template: voicemailAccessEs6Template
    })
    .state('root.nav.pbx.extensions.ringgroups.view.recording', {
      url: '/recording',
      template: ringGroupRecordingTemplate
    })
    .state('root.nav.pbx.extensions.ringgroups.view.usage', {
      url: '/usage',
      template: extensionsUsageEcma6Template
    })
    // -----
    .state('root.nav.pbx.extensions.conferencerooms', {
      url: '/conferencerooms',
      abstract: true,
      template:
        '<ui-view class="conference-rooms-abstract max-height"></ui-view>'
    })
    .state('root.nav.pbx.extensions.conferenceroomsv2', {
      url: '/conferencerooms-v2',
      template: phoenixTemplate,
      controller: 'PhoenixController as ctrl',
    })
    .state('root.nav.pbx.extensions.conferenceroomsv2.view', {
      url: '/:conferenceRoomId/:tab',
      template: phoenixTemplate,
      controller: 'PhoenixController as ctrl',
    })
    .state('root.nav.pbx.extensions.conferencerooms.list', {
      url: '?q&sort&direction&source',
      template: conferenceRoomsTemplate,
      controller: 'ConferenceRoomsController as ctrl',
      reloadOnSearch: false
    })
    .state('root.nav.pbx.extensions.conferencerooms.view', {
      url: '/:extId',
      template: itemTemplate,
      controller: 'ConferenceRoomCtrl as ctrl',
      abstract: true,
      data: {
        itemIcon: `${basePath}/room-physical.svg`,
        title: gettext('Conference Bridge'),
        tabs: [
          {
            id: 'conferencerooms-general',
            label: gettext('General'),
            state: 'root.nav.pbx.extensions.conferencerooms.view.general'
          },
          {
            id: 'conferencerooms-recording',
            label: gettext('Recording'),
            state: 'root.nav.pbx.extensions.conferencerooms.view.recording'
          },
          {
            id: 'conferencerooms-usage',
            label: gettext('Usage'),
            state: 'root.nav.pbx.extensions.conferencerooms.view.usage'
          }
        ]
      }
    })
    .state('root.nav.pbx.extensions.conferencerooms.view.general', {
      url: '/general',
      template: conferenceRoomGeneralTemplate
    })
    .state('root.nav.pbx.extensions.conferencerooms.view.recording', {
      url: '/recording',
      template: conferenceRoomRecordingTemplate
    })
    .state('root.nav.pbx.extensions.conferencerooms.view.usage', {
      url: '/usage',
      template: extensionsUsageEcma6Template
    })
    .state('root.nav.pbx.extensions.conferencerooms.view.callback', {
      url: '/callback?sendAt',
      template: conferenceRoomCallbckTemplate,
      controller: 'ConferenceRoomCallbackController as ctrl'
    })
    // -----
    // Call queue has been replaced with Contact Center
    // Redirect all call queues URLs
    .state('root.nav.pbx.extensions.callqueues', {
      url: '/callqueues',
      abstract: true,
    })
    .state('root.nav.pbx.extensions.callqueues.list', {
      url: '',
      onEnter: ($state, $timeout) => {
        $timeout(() => $state.go('root.nav.pbx.contactCenterConfiguration.queues'));
      },
    })
    .state('root.nav.pbx.extensions.callqueues.view', {
      url: '/:extId',
      abstract: true,
      onEnter: ($stateParams, $state, $timeout) => {
        $timeout(() =>
          $state.go('root.nav.pbx.contactCenterConfiguration.queues.single', {
            id: $stateParams.id,
            queueId: $stateParams.extId
          }));
      },
    })
    .state('root.nav.pbx.extensions.callqueues.view.general', {
      url: '/general',
    })
    .state('root.nav.pbx.extensions.callqueues.view.queuebehavior', {
      url: '/queuebehavior',
    })
    .state('root.nav.pbx.extensions.callqueues.view.recording', {
      url: '/recording',
    })
    .state('root.nav.pbx.extensions.callqueues.view.usage', {
      url: '/usage',
    })
    // -----
    .state('root.nav.pbx.extensions.dialplans', {
      url: '/dialplans',
      abstract: true,
      template: '<ui-view class="dial-plans-abstract max-height"></ui-view>'
    })
    .state('root.nav.pbx.extensions.dialplansv2', {
      url: '/dialplans-v2',
      template: phoenixTemplate,
      controller: 'PhoenixController as ctrl',
    })
    .state('root.nav.pbx.extensions.dialplansv2.view', {
      url: '/:dialPlanId/:tab',
      template: phoenixTemplate,
      controller: 'PhoenixController as ctrl',
    })
    .state('root.nav.pbx.extensions.dialplans.list', {
      url: '?q&sort&direction&source',
      template: dialPlansTemplate,
      controller: 'DialPlansController as ctrl',
      reloadOnSearch: false
    })
    // -----
    .state('root.nav.pbx.extensions.voicemailboxes', {
      url: '/voicemailboxes',
      abstract: true,
      template:
        '<ui-view class="shared-voicemail-box-abstract max-height"></ui-view>'
    })
    .state('root.nav.pbx.extensions.voicemailboxesv2', {
      url: '/voicemailboxes-v2',
      template: phoenixTemplate,
      controller: 'PhoenixController as ctrl',
    })
    .state('root.nav.pbx.extensions.voicemailboxesv2.view', {
      url: '/:voicemailBoxId/:tab',
      template: phoenixTemplate,
      controller: 'PhoenixController as ctrl',
    })
    .state('root.nav.pbx.extensions.voicemailboxes.list', {
      url: '?q&sort&direction&source',
      template: sharedVoicemailBoxesTemplate,
      controller: 'SharedVoicemailBoxesController as ctrl',
      reloadOnSearch: false
    })
    .state('root.nav.pbx.extensions.voicemailboxes.view', {
      url: '/:extId',
      template: itemTemplate,
      controller: 'SharedVoicemailBoxController as ctrl',
      abstract: true,
      data: {
        title: gettext('Shared Voicemail Box'),
        itemIcon: `${basePath}/voicemail.svg`,
        tabs: [
          {
            id: 'voicemailbox-general',
            label: gettext('General'),
            state: 'root.nav.pbx.extensions.voicemailboxes.view.general'
          },
          {
            id: 'voicemailbox-voicemail',
            label: gettext('Voicemail'),
            state: 'root.nav.pbx.extensions.voicemailboxes.view.voicemail'
          },
          {
            id: 'voicemailbox-voicemail-access',
            label: gettext('Voicemail Access'),
            state:
              'root.nav.pbx.extensions.voicemailboxes.view.voicemailAccess'
          }
        ]
      }
    })
    .state('root.nav.pbx.extensions.voicemailboxes.view.general', {
      url: '/general',
      template: sharedVoicemailBoxGeneralTemplate
    })
    .state('root.nav.pbx.extensions.voicemailboxes.view.voicemail', {
      url: '/voicemail',
      template: voicemailEs6Template
    })
    .state('root.nav.pbx.extensions.voicemailboxes.view.voicemailAccess', {
      url: '/voicemailAccess',
      template: voicemailAccessEs6Template
    })
    // -----
    .state('root.nav.pbx.extensions.virtualfaxmachines', {
      url: '/virtualfaxmachines',
      abstract: true,
      template:
        '<ui-view class="virtual-fax-machine-abstract max-height"></ui-view>'
    })
    .state('root.nav.pbx.extensions.virtualfaxmachines.list', {
      url: '?q&sort&direction&source',
      template: virtualFaxMachinesTemplate,
      controller: 'VirtualFaxMachinesController as ctrl',
      reloadOnSearch: false
    })
    .state('root.nav.pbx.extensions.virtualfaxmachinesv2', {
      url: '/virtualfaxmachines-v2',
      template: phoenixTemplate,
      controller: 'PhoenixController as ctrl',
    })
    .state('root.nav.pbx.extensions.virtualfaxmachinesv2.view', {
      url: '/:virtualFaxMachineId/:tab',
      template: phoenixTemplate,
      controller: 'PhoenixController as ctrl',
    })
    .state('root.nav.pbx.extensions.virtualfaxmachines.view', {
      url: '/:extId',
      template: itemTemplate,
      controller: 'VirtualFaxMachineCtrl as ctrl',
      abstract: true,
      data: {
        itemIcon: 'icon-sendFax',
        title: gettext('Virtual fax machine'),
        tabs: [
          {
            id: 'virtualfaxmachine-general',
            label: gettext('General'),
            state: 'root.nav.pbx.extensions.virtualfaxmachines.view.general'
          },
          {
            id: 'virtualfaxmachine-sending',
            label: gettext('Sending'),
            state: 'root.nav.pbx.extensions.virtualfaxmachines.view.sending'
          },
          {
            id: 'virtualfaxmachine-receiving',
            label: gettext('Receiving'),
            state: 'root.nav.pbx.extensions.virtualfaxmachines.view.receiving'
          },
          {
            id: 'virtualfaxmachine-usage',
            label: gettext('Usage'),
            state: 'root.nav.pbx.extensions.virtualfaxmachines.view.usage'
          },
          {
            id: 'virtualfaxmachine-permissions',
            label: gettext('Permissions'),
            state: 'root.nav.pbx.extensions.virtualfaxmachines.view.permissions.template',
            settingEnabled: ['isStoreIncomingFaxes', 'isVirtualFaxMachinePermissionsEnabled']
          }
        ]
      }
    })
    .state('root.nav.pbx.extensions.virtualfaxmachines.view.general', {
      url: '/general',
      template: virtualFaxMachineGeneralTemplate
    })
    .state('root.nav.pbx.extensions.virtualfaxmachines.view.sending', {
      url: '/sending',
      template: virtualFaxMachineSendingTemplate
    })
    .state('root.nav.pbx.extensions.virtualfaxmachines.view.receiving', {
      url: '/receiving',
      template: virtualFaxMachineReceivingTemplate
    })
    .state('root.nav.pbx.extensions.virtualfaxmachines.view.usage', {
      url: '/usage',
      template: extensionsUsageEcma6Template
    })
    .state('root.nav.pbx.extensions.virtualfaxmachines.view.permissions', {
      url: '',
      template: virtualFaxMachinePermissionsTemplate,
      abstract: true
    })
    .state('root.nav.pbx.extensions.virtualfaxmachines.view.permissions.template', {
      url: '/permissions',
      template: virtualFaxMachinePermissionsIdentityListTemplate
    })
})
