import _module from 'module.js'
import defaultProxyTemplate from './defaultProxyTemplate.html'

_module.directive('defaultProxy', () => {
  return {
    restrict: 'AE',
    template: defaultProxyTemplate,

    scope: {
      defaultProxyJrn: '=',
      proxyJrn: '='
    },

    controllerAs: 'ctrl',
    bindToController: true,
    controller: function (Proxies) {
      'ngInject'
      this.api = new Proxies(null, {})

      this.setDefaultProxy = defaultProxy => {
        this.api.setDefaultRegProxy({ defaultProxy })
      }
    }
  }
})
