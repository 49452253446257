import _module from 'module.js'
import googleLib from 'google-libphonenumber'

let { PhoneNumberUtil, PhoneNumberFormat } = googleLib
let phoneUtil = PhoneNumberUtil.getInstance()
/**
 * @ngdoc filter
 * @name platformApp.tel
 * @description
 * # tel
 * Filter in the platformApp.
 */
_module
  .filter('tel', globalState => {
    /* jshint ignore:start */
    if (!googleLib) {
      throw Error('lib phoneUtils not found')
    }

    // set as selected or default to US
    var regionCode = 'US'
    if (globalState.currentLanguage) {
      regionCode = globalState.currentLanguage.regionCode || 'US'
    }

    return tel => {
      if (!tel) {
        return ''
      }

      // May change in the future based on user feedback
      try 
      {
        return phoneUtil.format(
          phoneUtil.parse(tel, regionCode),
          PhoneNumberFormat.INTERNATIONAL
        )
      } catch(err)
      {
        return tel
      }
      
    }

    /* jshint ignore:end */
  })
  .filter('telE164', globalState => {
    /* jshint ignore:start */
    if (!googleLib) {
      throw Error('lib phoneUtils not found')
    }

    // set as selected or default to US
    var regionCode = 'US'
    if (globalState.currentLanguage) {
      regionCode = globalState.currentLanguage.regionCode || 'US'
    }

    return tel => {
      if (!tel) {
        return ''
      }

      // May change in the future based on user feedback
      return phoneUtil.format(
        phoneUtil.parse(tel, regionCode),
        PhoneNumberFormat.E164
      )
    }

    /* jshint ignore:end */
  })
  .filter('telMasterDid', globalState => {
    /* jshint ignore:start */
    if (!googleLib) {
      throw Error('lib phoneUtils not found')
    }

    // set as selected or default to US
    var regionCode = 'US'

    return tel => {
      if (!tel) {
        return ''
      }
      try
      {
        return phoneUtil.format(
          phoneUtil.parse(tel, regionCode),
          PhoneNumberFormat.INTERNATIONAL
        )
      } catch(err)
      {
        return tel
      }
      // May change in the future based on user feedback
    }

    /* jshint ignore:end */
  })
