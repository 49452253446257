import _module from 'module.js'
import ListControllerBase from 'classes/listController.class'

export default class NetworksController extends ListControllerBase {
  constructor (
    $state,
    $stateParams,
    $location,
    $analytics,
    Network,
    gettextCatalog,
    infiniteLoader,
    navigation,
    globalState,
    portalUtil
  ) {
    'ngInject'
    super(
      gettextCatalog,
      $analytics,
      $location,
      portalUtil,
      globalState,
      navigation,
      $state,
      $stateParams
    )

    this.gettextCatalog = gettextCatalog
    this.title = this.gettextCatalog.getString('Network Access Permissions')
    this.headers = [
      {
        width: 15,
        name: this.gettextCatalog.getString('Network'),
        sortBy: 'network',
        asc: true
      },
      {
        width: 30,
        name: this.gettextCatalog.getString('Access')
      },
      {
        width: 20,
        name: this.gettextCatalog.getString('Expiration'),
        sortBy: 'expiration',
        asc: null
      },
      {
        width: 25,
        name: this.gettextCatalog.getString('Comment'),
        sortBy: 'comment',
        asc: null
      },
      {
        width: 10,
        name: ''
      }
    ]

    this.buttonText = this.gettextCatalog.getString(
      'Add Network Access Permission'
    )
    this.loader = infiniteLoader(Network)
  }

  delete (event, item) {
    let name = this.gettextCatalog.getString('network') + ': ' + item.network
    this.loader.entityApi.startDelete(event, item, this.data, name)
  }

  itemSelected () {
    // do nothing
  }
}

_module.controller('NetworksController', NetworksController)
