class AudioPreviewController {
  constructor (audioPlayerService, $sce) {
    'ngInject'
    this.audioPlayerService = audioPlayerService
    this.$onInit = () => {
      this.ngModel.$render = () => {
        if (this.url) {
          this.config = {
            preload: 'none',
            sources: [
              { src: $sce.trustAsResourceUrl(this.url), type: 'audio/mpeg' }
              // {src: $sce.trustAsResourceUrl("http://static.videogular.com/assets/audios/videogular.mp3"), type: "audio/mpeg"}
            ],
            theme: './node_modules/videogular-themes-default/videogular.min.css',
            autoPlay: angular.isDefined(this.songInfo.autoPlay)
              ? this.songInfo.autoPlay
              : true
          }
        }
      }
    }
  }

  previewComplete (state) {
    if (state !== 'play') {
      this.songInfo.autoPlay = false
    }
  }
}

export default AudioPreviewController
