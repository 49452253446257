export default class aclMonitoringPermissionsController {
  selectUser (user) {
    this.selectedUser = user
    if (!this.selectedUser.allowedToMonitor) { this.selectedUser.allowedToMonitor = [] }
  }

  addUser (user, $event) {
    $event.preventDefault()
    this.selectedUser.allowedToMonitor.push(user)
  }

  removeUser (user) {
    this.selectedUser.allowedToMonitor = this.selectedUser.allowedToMonitor.filter(
      item => {
        return user.jrn !== item.jrn
      }
    )
  }

  clearIfSelectedUser (user) {
    if (user === this.selectedUser) delete this.selectedUser
  }
}
