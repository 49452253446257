import _module from 'module.js'
import _ from 'lodash'

// This is from some legacy code, but no longer need the behavior
// Leaving this here if we need it in the future
const getExtensionsToReject = buttons => {
  return []
}

const removeEmptyButtons = buttons => {
  return _(buttons)
    .reject(button => {
      return !button.parameters
    })
    .value()
}

class filterGetExtensionController {
  constructor ($scope, $parse, $attrs) {
    'ngInject'
    this.$scope = $scope
    this.$parse = $parse
    this.$attrs = $attrs
  }

  getCollectionFromScope () {
    return this.$parse(this.$attrs.filterGetExtension)(this.$scope)
  }

  filter (exts) {
    let extensionsToRemove = getExtensionsToReject(
      removeEmptyButtons(this.getCollectionFromScope())
    )

    return _(exts)
      .reject(extension => {
        return _.includes(extensionsToRemove, extension.jrn)
      })
      .value()
  }
}

_module.directive('filterGetExtension', function () {
  return {
    require: 'filterGetExtension',
    restrict: 'AE',

    controllerAs: 'filterGetExtension',
    bindToController: true,
    controller: filterGetExtensionController
  }
})
