import _module from 'module.js'
import ListControllerBase from 'classes/listController.class'
import _ from 'lodash'
import { LMI_ADMIN_PORTAL_TOKEN } from '../../services/jiveOAuthClient'

export default class UnifiedAdminLocationsController extends ListControllerBase {
  constructor (
    $state,
    $stateParams,
    $location,
    $analytics,
    gettextCatalog,
    navigation,
    globalState,
    portalUtil,
    unifiedAdminLocationsService
  ) {
    'ngInject'
    super(
      gettextCatalog,
      $analytics,
      $location,
      portalUtil,
      globalState,
      navigation,
      $state,
      $stateParams
    )

    this.isUnifiedAdminLocationsEnabled = false;
    this.host = 'ADMIN';
    this.isUnifiedAdminLocationsEnabled = unifiedAdminLocationsService.isLocationsAvailable();
    this.globalState = globalState

    if (this.isUnifiedAdminLocationsEnabled)
    {
      this._setToken()
      this.orgId = this.globalState.selectedPbxUUID
      var adminFormattedLocale = this.globalState.currentLanguage.langCode
      this.locale = adminFormattedLocale.replace(/-/g, '_') // The Admin Portal locale is format xx-XX but we need xx_XX for GTA
      this.error401Handler = this.reAuthenticate.bind(this)

      setTimeout(() => {
        this.readyToRender = true
      }, 0)
    }
    else
    {
      this.goToPbxLocations(globalState._pbxId)
    }
  }

  goToPbxLocations(id)
  {
    this.state.go('root.nav.pbx.unifiedAdminLocations.emergencyLocations', {
      id: id
    })
  }

  reAuthenticate()
  {
    this.authService.reAuthenticate((err, authInfo) => {
      if (authInfo)
      {
        if (authInfo.inflightRequest)
        {
          _window.location.assign(new URL(decodeURIComponent(authInfo.inflightRequest), window.location.toString()).pathname)
        }
        else
        {
          this.readyToRender = false
          this._setToken()
          this.readyToRender = true
        }
      }
    })
  }

  _setToken()
  {
    const {token, jiveId, expires, inflightRequest, type} = JSON.parse(
      localStorage.getItem(LMI_ADMIN_PORTAL_TOKEN) || localStorage.getItem('jive-foken')
    )
    this.authToken = {token_type: type, access_token: token, expires_in: expires, principal: jiveId, state: {inflightRequest: inflightRequest}}
    this.selectedAccountKey = this.globalState.selectedPbx.lmiAccountKey
    this.users = this.globalState.userData.users
  }

}

_module.controller('UnifiedAdminLocationsController', UnifiedAdminLocationsController)
