import _module from 'module.js'

_module.directive('ipAddressPort', function () {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, $input, attrs, ngModelCtrl) {
      ngModelCtrl.$validators.ipAddressPort = () => {
        return (
          /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}\:[0-9]{1,5}$/.test($input.val()) ||
          $input.val().length === 0
        )
      }
    }
  }
})
