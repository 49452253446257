import _module from 'module.js'
import greetingRecorderWizardTemplate
  from './greetingRecorderWizardTemplate.html'
import jrnParser from 'classes/jrnParser'

class greetingRecorderController {
  constructor ($analytics, gettextCatalog, $mdDialog) {
    'ngInject'
    this.soundClip = {
      name: 'New Recording',
      description: ''
    }

    this.jrn = jrnParser
    this.$analytics = $analytics
    this.gettextCatalog = gettextCatalog
    this.$mdDialog = $mdDialog
  }

  ok () {
    let blob = this.audio.element.recordRTC.getBlob()
    let fileName = 'greeting.wav'

    let file = new Blob([blob], {
      type: 'audio/wav'
    })
    file.name = fileName

    this.$mdDialog.hide(file)
  }

  cancelDialog () {
    this.$mdDialog.cancel()
  }

  static factory ($analytics, gettextCatalog, $mdDialog) {
    'ngInject'
    return new greetingRecorderController($analytics, gettextCatalog, $mdDialog)
  }
}

class GreetingRecorderWizard {
  constructor ($mdDialog, $analytics) {
    'ngInject'
    this.$mdDialog = $mdDialog
    this.$analytics = $analytics
  }

  open () {
    this.$analytics.eventTrack('Greeting Recorder: Open', {
      category: 'Greeting Recorder'
    })
    return this.$mdDialog.show({
      template: greetingRecorderWizardTemplate,
      controllerAs: 'comboWizard',
      controller: greetingRecorderController.factory,
      parent: angular.element(document.body),
      clickOutsideToClose: true,
      fullscreen: true
    })
  }

  static factory ($mdDialog, $analytics) {
    'ngInject'
    return new GreetingRecorderWizard($mdDialog, $analytics)
  }
}

_module.factory('greetingRecorderWizard', GreetingRecorderWizard.factory)
