import BaseController from 'classes/baseController'

export default class ExtensionController extends BaseController {
  constructor (
    $state,
    $stateParams,
    entityFactory,
    portalApi,
    userService,
    gettextCatalog,
    portalUtil,
    errorService,
    globalState,
    navigation,
    listState,
    idParam
  ) {
    'ngInject'
    super(
      $state,
      $stateParams,
      entityFactory,
      portalApi,
      userService,
      gettextCatalog,
      portalUtil,
      errorService,
      globalState,
      navigation,
      listState,
      idParam
    )
    
    this.canRecord = this.globalState._selectedPbx.storageAccessKeyId ||
          this.globalState._selectedPbx.useCboxStorage
          
    this.isCboxStorage = this.globalState._selectedPbx.useCboxStorage

    if ($state.current.name.endsWith('usage')) {
      this.data.getUsage()
    }
  }

  isOnMultipleDevices (data) {

    if (data.voicemailBox.useUsersEmailAddressForEmailNotification) {
      data.voicemailBox.useUsersEmailAddressForEmailNotification =
        data.primaryUsageCount === 1
    }

    return data.primaryUsageCount !== 1
  }

  checkFaxEmail (data) {

    this.data.viableForFaxEmail = data.primaryUsageCount === 1
    if (this.data.useUsersEmailForIncomingFax) {
      data.useUsersEmailForIncomingFax = this.data.viableForFaxEmail
    }
    return this.data.viableForFaxEmail
  }
}
