export const getWelcomeGuideLinks = (lang) => {
  const welcomeGuideLinks = {
    "en-US": "https://support.goto.com/jive-supportcenter-itadmin",
    "en-GB": "https://support.goto.com/jive-supportcenter-itadmin",
    en: "https://support.goto.com/jive-supportcenter-itadmin",
    es: "https://support.goto.com/es/jive/supportcenter-itadmin",
    "fr-FR": "https://support.goto.com/fr/jive/supportcenter-itadmin",
    "fr-CA": "https://support.goto.com/fr/jive/supportcenter-itadmin",
    "es-MX": "https://support.goto.com/es/jive/supportcenter-itadmin",
    "es-ES": "https://support.goto.com/es/jive/supportcenter-itadmin",
    "de-DE": "https://support.goto.com/de/jive/supportcenter-itadmin",
  };

  return welcomeGuideLinks[lang] || welcomeGuideLinks["en-US"];
};
