/**
 * @ngdoc function
 * @name platformApp.factory:ExtensionService
 * @description
 * # ExtensionService
 * Controller of the platformApp
 */
import _module from 'module.js'
import _ from 'lodash'
import jrnParser from 'classes/jrnParser'

let _stateParams,
  _portalApi,
  _extensionTypes,
  _log,
  _VirtualFaxMachineFactory,
  _Line,
  _RingGroup,
  _CallQueue,
  _ConferenceRoom,
  _DialPlan,
  _SharedVoicemailBox,
  _jrn,
  _globalState

export default class Extension {
  constructor (id, type, pbxId, gettextCatalog) {
    'ngInject'
    this._id = _stateParams.extId || id
    this._pbxId = _globalState.selectedPbxUUID || pbxId

    this.gettextCatalog = gettextCatalog

    if (this._id === undefined) {
      throw new this.gettextCatalog.getString('Missing id for new Extension')
    }

    this._api = _portalApi.extension.pbxs(this._pbxId).extensions

    // keep the type as an object
    if (type && !type.jrn) {
      if (type.hasOwnProperty('id')) {
        this.type = type
      } else {
        this.type = _.find(_extensionTypes.allTypes, {
          id: type.type || type
        })
        this.isNew = type.isNew

        if (!this.type) {
          this.type = _extensionTypes.allTypes[0]
        }
      }
    } else if (type && type.jrn) {
      this.type = _.find(_extensionTypes.allTypes, {
        id: _jrn.parse(type.jrn).getResources()[0]
      })
    }

    if (this.type && this.type.id) {
      switch (this.type.id) {
        case 'line':
          this._obj = new _Line(this._id, type)
          break
        case 'ring-group':
          this._obj = new _RingGroup(this._id, type)
          break
        case 'conference-room':
          this._obj = new _ConferenceRoom(this._id, type)
          break
        case 'call-queue':
          this._obj = new _CallQueue(this._id, type)
          break
        case 'dial-plan':
          this._obj = new _DialPlan(this._id, type)
          break
        case 'virtual-fax-machine':
          this._obj = _VirtualFaxMachineFactory(this._id, type)
          break
        case 'shared-voicemail-box':
          this._obj = new _SharedVoicemailBox(this._id, type)
          break
        default:
          _log.error('Unrecognized type ' + type)
      }
    }
  }

  // return a deep copy of this object,
  copy () {
    let destination = new Extension(this.getId(), this.type)
    destination.map(this._obj)
    return destination
  }

  changeExtensionType (newType) {
    switch (newType) {
      case 'line':
        this._obj = new _Line(null, {})
        break
      case 'ring-group':
        this._obj = new _RingGroup(null, {})
        break
      case 'conference-room':
        this._obj = new _ConferenceRoom(null, {})
        break
      case 'call-queue':
        this._obj = new _CallQueue(null, {})
        break
      case 'dial-plan':
        this._obj = new _DialPlan(null, {})
        break
      case 'virtual-fax-machine':
        this._obj = _VirtualFaxMachineFactory(null, {})
        break
      case 'shared-voicemail-box':
        this._obj = new _SharedVoicemailBox(null, {})
        break
      default:
        _log.error('changeExtensionType: Unrecognized type ' + newType)
    }
  }

  map (data) {
    this._obj.name = data.name
    this._obj.extensionNumber = data.extensionNumber
    this._obj.omitFromAutomaticDirectories = data.omitFromAutomaticDirectories
    this._obj.omittedFromAutoAttendants = data.omittedFromAutoAttendants
  }

  build (payload) {
    return this._obj.build(payload)
  }

  get () {
    return this._obj.get()
  }

  getReferencesApi () {
    return this._obj.getReferencesPath()
  }

  getListResult (params) {
    params = params || {}
    return this._api.call(this).get(params)
  }

  extend (data) {
    _.extend(this._obj, data)
    return this
  }

  getId () {
    return this._obj.getId()
  }

  save () {
    return this._obj.save()
  }

  update () {
    let _copy = _.cloneDeep(this._obj)
    return this._obj.get().then(() => {
      this.map(_copy)
      return this._obj.update()
    })
  }

  delete () {
    return this._obj.delete()
  }

  getNext () {
    return this._api.call(this).one('extension-number/next').get()
  }

  getByExtensionNumber (number) {
    return this._api.call(this).one('extension-number/' + number).get()
  }

  getClass () {
    return 'Extension'
  }

  cleanListItem (item) {
    return {
      jrn: item.jrn,
      extensionNumber: item.extensionNumber,
      name: item.name,
      omitFromAutomaticDirectories: item.omitFromAutomaticDirectories,
      type: item.type,
      voicemailBoxId: item.voicemailBoxId
    }
  }

  customParams () {
    return {}
  }

  static factory (
    $stateParams,
    portalApi,
    extensionTypes,
    $log,
    VirtualFaxMachineFactory,
    Line,
    RingGroup,
    CallQueue,
    ConferenceRoom,
    DialPlan,
    SharedVoicemailBox,
    gettextCatalog,
    globalState
  ) {
    'ngInject'
    _stateParams = $stateParams
    _portalApi = portalApi
    _extensionTypes = extensionTypes
    _log = $log
    _VirtualFaxMachineFactory = VirtualFaxMachineFactory
    _Line = Line
    _RingGroup = RingGroup
    _CallQueue = CallQueue
    _ConferenceRoom = ConferenceRoom
    _DialPlan = DialPlan
    _SharedVoicemailBox = SharedVoicemailBox
    _jrn = jrnParser
    _globalState = globalState

    return (id, type, pbxId = null) => {
      return new Extension(id, type, pbxId, gettextCatalog)
    }
  }
}

_module.factory('Extension', Extension.factory)
