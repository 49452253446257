import _module from 'module.js'
import template from './langSwitcher.template.html'
import controller from './langSwitcher.controller'
import './langSwitcher.provider'

_module
    .directive('langSwitcher', () => {

        return {
            restrict: 'AE',
            template,
            scope: true,
            replace: true,
            controllerAs: 'ctrl',
            controller
        }

    })
