import _module from 'module.js'

_module.directive('uniqueBillingGroupName', function (BillingGroup, $q) {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, $input, attrs, ngModelCtrl) {
      var billingGroupApi = new BillingGroup(null),
        originalValue = scope.$eval(attrs.ngModel)

      ngModelCtrl.$asyncValidators.uniqueBillingGroupName = (model, view) => {
        var value = model || view
        var dfd = $q.defer()

        if (!value || value.length === 0) {
          dfd.resolve(true)
        }

        if (!originalValue || originalValue !== value) {
          if (value) {
            billingGroupApi.getListResult({ q: value }).then(
              data => {
                if (data.items.length === 0) {
                  dfd.resolve(true)
                } else {
                  data.items.forEach(item => {
                    if (item.name === value) dfd.reject('exists')
                  })
                  dfd.resolve(true)
                }
              },
              err => {
                $q.reject(err)
              }
            )
          }
        } else {
          dfd.resolve(true)
        }
        return dfd.promise
      }
    }
  }
})
