import jrnParser from 'classes/jrnParser'

let _filterGetExtension, _jrn
const applyFilterIfPresent = data => {
  return _filterGetExtension ? _filterGetExtension.filter(data) : data
}

export default class GetExtensionModelController {
  constructor (
    portalApi,
    globalState,
    gettextCatalog,
    portalConfig,
    LIST_LIMIT_DEFAULT,
    $q
  ) {
    'ngInject'
    this.sharedLineIcon = portalConfig.sharedLineIcon
    this.gettextCatalog = gettextCatalog
    this.LIST_LIMIT_DEFAULT = LIST_LIMIT_DEFAULT
    this.$q = $q
    _jrn = jrnParser

    this.SHARED_LINE = this.gettextCatalog.getString('Shared Line')
    this.CHOOSE_EXTENSION = this.gettextCatalog.getString('Choose Extension')
    this.SEARCH_EXTENSIONS = this.gettextCatalog.getString('Search Extensions')

    this.infiniteItems = {
      getItemAtIndex: index => {
        let currentLength = this.query.items.length
        if (!this.busy && !this.query.noMoreData && index > currentLength) {
          this.loadData(this.query.offset + this.query.limit).then(newItems => {
            this.query.items = this.query.items.concat(
              applyFilterIfPresent(newItems)
            )
          })
          return null
        }
        return this.query.items[index]
      },
      getLength: () => {
        return this.query.items.length + 2
      }
    }

    if (this.extensionType === 'line') {
      this.api = portalApi.extension.pbxs(globalState.selectedPbxUUID).lines
    } else if (this.extensionType === 'ringGroup') {
      this.api = portalApi.extension.pbxs(
        globalState.selectedPbxUUID
      ).ringGroups
    } else if (this.extensionType === 'callQueue') {
      this.api = portalApi.extension.pbxs(
        globalState.selectedPbxUUID
      ).callQueues
    } else if (this.extensionType === 'conferenceRoom') {
      this.api = portalApi.extension.pbxs(
        globalState.selectedPbxUUID
      ).conferenceRooms
    } else if (this.extensionType === 'dialPlan') {
      this.api = portalApi.extension.pbxs(globalState.selectedPbxUUID).dialPlans
    } else {
      this.api = portalApi.extension.pbxs(
        globalState.selectedPbxUUID
      ).extensions
    }

    this.query = {
      q: '',
      offset: 0,
      limit: LIST_LIMIT_DEFAULT,
      noMoreData: false,
      items: []
    }
  }

  $onInit () {
    _filterGetExtension = this.filterGetExtension
    if (!this.displayString) {
      if (this.extJrn) {
        this.getExtensionByJrn().then(extension => {
          this.ngModel.$setViewValue(extension)
          if (this.form && this.form.$$parentForm) {
            this.form.$$parentForm.$setPristine()
          } else if (this.form) {
            this.form.$setPristine()
          } else {
            this.ngModel.$$setPristine()
          }
        })
      }
    }
  }

  loadData (offset) {
    if (!this.query.noMoreData) {
      this.busy = true
      this.query.offset = offset

      let _query = _.omit(this.query, ['items', 'noMoreData'])
      return this.api
        .call(this)
        .get(_query)
        .then(data => {
          this.query.noMoreData = this.LIST_LIMIT_DEFAULT > data.items.length
          this.busy = false
          return data.items
        })
        .catch(error => {
          this.busy = false
          throw error
        })
    } else {
      return this.$q.when([])
    }
  }

  getExtensionByJrn () {
    if (this.extJrn) {
      let id = _jrn.parse(this.extJrn).getResources()[1]
      this.extension = this.api(id).get({ cache: true }).then(data => {
        let line = data.plain()
        this.displayString = this.parseExtensionInfo(line)
        return line
      })
    }

    return this.extension
  }

  parseExtensionInfo (extension) {
    let label = ''
    if (extension) {
      if (extension.extensionNumber) {
        label = extension.extensionNumber + ': '
      }
      label += extension.name
    } else {
      label = this.gettextCatalog.getString('Not Found')
    }

    return label
  }

  lineFocusSet (val, $evt) {
    if ($evt) {
      $evt.stopImmediatePropagation()
    }

    this.isFocused = val
    if (val) {
      this.query.q = ''
      this.query.offset = 0
      this.query.items = []
      this.query.noMoreData = false

      this.loadData(0).then(newItems => {
        this.query.items = this.query.items.concat(
          applyFilterIfPresent(newItems)
        )
      })
    }
  }

  searchForExtensions (text) {
    this.query.q = text
    this.query.offset = 0
    this.query.items = []
    this.query.noMoreData = false

    this.loadData(0).then(newItems => {
      this.query.items = this.query.items.concat(applyFilterIfPresent(newItems))
    })
  }

  setExtension (extension) {
    this.isFocused = false
    this.displayString = this.parseExtensionInfo(extension)
    this.sharedAppearance = extension.sharedAppearance
    this.ngModel.$setViewValue(extension)
  }

  clearExtensionQuery () {
    this.searchForExtensions('')
  }
}
