import _module from 'module.js'

_module.directive('validAreaCode', function (portalUtil) {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, $input, attrs, ngModelCtrl) {
      ngModelCtrl.$validators.validAreaCode = () => {
        return portalUtil.validateAreaCodeOnRegionChange(
          $input.val(),
          attrs.region
        )
      }
    }
  }
})
