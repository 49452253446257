import _module from 'module.js'

export default class ToolsController {
  constructor (
    globalState,
    portalUtil,
    portalApi,
    gettextCatalog,
    FileSaver,
    Blob,
    $http,
    portalConfig,
    importExportTool,
    $state,
    $stateParams
  ) {
    'ngInject'
    this.globalState = globalState
    this.portalApi = portalApi
    this.FileSaver = FileSaver
    this.Blob = Blob
    this.portalUtil = portalUtil
    this.gettextCatalog = gettextCatalog
    this.http = $http
    this.siteIcon = portalConfig.siteIconBlack

    this.busy = false
    this.isBrowserSupported = globalState.isBrowserSupported
    this.showMasterDid = globalState.isPlatformNumbersOrHigher
    this.usersIcon = portalConfig.usersIcon

    if (!this.globalState.isPlatform) {
      $state.go('root.nav.pbx.dashboard', $stateParams)
      return
    }

    importExportTool.bindExport(this)
    importExportTool.bindImport(this, [
      'lines',
      'extensions',
      'users',
      'userAgents',
      'phoneNumbers',
      'masterPhoneNumbers',
      'sites',
      'userAgentConfigurations',
      'userFulfillmentWs',
      'ringGroups'
    ])
    importExportTool.bindDelete(this, [
      'lines',
      'extensions',
      'users',
      'userAgents',
      'userAgentConfigurations',
      'ringGroups'
    ])
  }
}

_module.controller('ToolsController', ToolsController)
