import _module from 'module.js'

let _globalState, _portalApi, _portalConfig, _confirmationWindowOK
let zohoId = '0KLY9muNR4uSRg8gFaUvhZ6ic6cnVH9xzJUVyRjI'

class IntegrationsService {
  constructor (
    globalState,
    portalApi,
    portalConfig,
    confirmationWindowOK,
    gettextCatalog
  ) {
    'ngInject'
    _globalState = globalState
    _portalApi = portalApi
    _portalConfig = portalConfig
    _confirmationWindowOK = confirmationWindowOK
    this.gettextCatalog = gettextCatalog
    this._users = null
    this._extensions = null
    this._providers = null
    this._authorization = null
  }

  getExtensionsService () {
    return _portalApi.extension.pbxs(_globalState.selectedPbxUUID).lines
  }

  getZohoMappingApiService () {
    return _portalApi.userMapping
      .organization(_globalState.selectedPbxUUID)
      .integrations(zohoId).mapping
  }

  getZohoMappingPath (id) {
    return _portalApi.userMapping
      .organization(_globalState.selectedPbxUUID)
      .integrations(zohoId)
      .mapping(id)
  }

  getZohoUsersApiService () {
    return _portalApi.userMapping
      .organization(_globalState.selectedPbxUUID)
      .integrations(zohoId).users
  }

  getZohoMasterUserApiService () {
    return _portalApi.userMapping
      .organization(_globalState.selectedPbxUUID)
      .integrations(zohoId).masteruser
  }

  getZohoUserAuthorizationService (jrnParser) {
    let jrnId = _globalState.userData.users.filter(
      user =>
        jrnParser.parse(user.jrn).getAccount() === _globalState.selectedPbxUUID
    )[0].jrn
    let userId = jrnParser.parse(jrnId).getResources()[1]
    return _portalApi.userMapping
      .authorization(_globalState.selectedPbxUUID)
      .users(userId).providers
  }

  getZohoUserProvidersService () {
    return _portalApi.userMapping
      .authorization(_globalState.selectedPbxUUID)
      .metadata().providers
  }

  mapZohoMappingIdToJrn (zohoMappingId) {
    let orgId = _globalState.selectedPbxUUID

    return `jrn:integrations-usermapping::jive:${orgId}:zohoMapping/${zohoMappingId}`
  }

  getZohoUsers () {
    if (!this._users) {
      let zohoUsersApiService = this.getZohoUsersApiService()
      zohoUsersApiService
        .call()
        .get()
        .then(results => {
          this._users = results.items
        })
        .catch(e => {
          this._users = []
          throw e
        })
    }
    return this._users
  }

  getExtensionsMapping () {
    if (!this._extensions) {
      let extensionsService = this.getExtensionsService()
      extensionsService
        .call()
        .get()
        .then(results => {
          this._extensions = results.items
        })
        .catch(e => {
          this._extensions = []
        })
    }
    return this._extensions
  }

  getUserProviders (jrnParser) {
    let _pbxDomain = _globalState.selectedPbx.domain
    let _integrationEnableUrl = `${_portalConfig.ambassadorEnablementPath}${_pbxDomain}/zohocrm`

    if (
      _globalState.isPlatformAdmin ||
      _globalState.isPlatform ||
      _globalState.isPartnerAdmin ||
      _globalState.isPartnerPbxAdmin
    ) {
      return Promise.resolve({
        isAuthenticated: true,
        pbxDomain: _integrationEnableUrl
      })
    } else {
      let zohoUserAuthorizationService = this.getZohoUserAuthorizationService(
        jrnParser
      )
        .call()
        .get()
      let zohoUserProvidersService = this.getZohoUserProvidersService()
        .call()
        .get()

      return Promise.all([
        zohoUserAuthorizationService,
        zohoUserProvidersService
      ]).then(results => {
        this._authorization = results[0].items
        this._providers = results[1].items

        let zohoProvider = this._providers.filter(
          provider =>
            !_.isEmpty(
              provider.config.filter(config => config.appId === zohoId)
            )
        )[0]
        if (_.isEmpty(zohoProvider)) {
          return {
            isAuthenticated: false,
            pbxDomain: _integrationEnableUrl
          }
        } else {
          let authorizedProvider = this._authorization.filter(
            activeAccount => activeAccount.providerUuid === zohoProvider.id
          )[0]
          if (_.isEmpty(authorizedProvider)) {
            return {
              isAuthenticated: false,
              pbxDomain: _integrationEnableUrl
            }
          } else {
            return {
              isAuthenticated: true,
              pbxDomain: _integrationEnableUrl
            }
          }
        }
      })
    }
  }

  setMasterUser () {
    let zohoMasterUserApiService = this.getZohoMasterUserApiService()
    zohoMasterUserApiService
      .call()
      .put()
      .then(results => {
        _confirmationWindowOK.show(
          this.gettextCatalog.getString('Successfully set as primary user'),
          this.gettextCatalog.getString(
            'You have been successfully set as the new primary user for your organization.'
          ),
          true
        )
      })
      .catch(() => {
        _confirmationWindowOK.show(
          this.gettextCatalog.getString('Something went wrong'),
          this.gettextCatalog.getString(
            'An error occurred while trying to set you as the new primary user for your organization.'
          ),
          true
        )
      })
  }
}

_module.service('integrationsService', IntegrationsService)
