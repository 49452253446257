import _ from 'lodash'
import ListControllerBase from 'classes/listController.class'
import _module from 'module.js'

export default class directoriesControllerFactory extends ListControllerBase {
  constructor (
    $state,
    $stateParams,
    $location,
    $analytics,
    Directory,
    gettextCatalog,
    infiniteLoader,
    navigation,
    globalState,
    portalUtil
  ) {
    'ngInject'
    super(
      gettextCatalog,
      $analytics,
      $location,
      portalUtil,
      globalState,
      navigation,
      $state,
      $stateParams
    )

    this.gettextCatalog = gettextCatalog
    this.title = this.gettextCatalog.getString('Dial By Name Directories')

    this.headers = [
      {
        width: 90,
        name: this.gettextCatalog.getString('Name'),
        sortBy: 'name',
        asc: true
      },
      {
        width: 10,
        name: ''
      }
    ]

    this.buttonText = this.gettextCatalog.getString(
      'Add Dial By Name Directory'
    )
    this.loader = infiniteLoader(Directory)
  }

  delete (event, item) {
    let name = this.gettextCatalog.getString('directory') + ': ' + item.name
    this.loader.entityApi.startDelete(event, item, this.data, name)
  }

  itemSelected (item) {
    super.itemSelected(
      item,
      'root.nav.pbx.directories.view.general',
      'directoryId'
    )
  }
}

_module.controller('DirectoriesController', directoriesControllerFactory)
