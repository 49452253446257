import _module from 'module.js'
import itemTemplate from 'controllers/item/itemTemplate.html'
import usersTemplate from 'controllers/users/usersTemplate.html'
import userGeneralTemplate from 'controllers/user/general/userGeneralTemplate.html'
import userSettingsTemplate from 'controllers/user/settings/userSettingsTemplate.html'
import userHiddenTemplate from 'controllers/user/hidden/userHiddenTemplate.html'
import environments from '../configs/environments'

const basePath = environments.current === 'local' ? '/images' : '/pbx/images'

_module.config((gettext, $stateProvider, FEATURE_FLAGS) => {
  'ngInject'

  $stateProvider
    .state('root.nav.pbx.users', {
      url: '/users',
      abstract: true,
      template: '<div ui-view class="pbx-users-abstract max-height"></div>'
    })
    .state('root.nav.pbx.users.list', {
      url: '?q&sort&direction&source',
      template: usersTemplate,
      controller: 'UsersController as ctrl',
      reloadOnSearch: false
    })
    .state('root.nav.pbx.users.view', {
      url: '/:userId',
      abstract: true,
      template: itemTemplate,
      data: {
        title: gettext('User'),
        itemIcon: `${basePath}/person-inactive.svg`,
        tabs: [
          {
            id: 'user-general',
            label: gettext('General'),
            state: 'root.nav.pbx.users.view.general'
          },
          {
            id: 'user-settings',
            label: gettext('Settings'),
            state: 'root.nav.pbx.users.view.settings'
          }
        ],
        hiddenTab: [
          {
            id: 'user-hidden',
            label: gettext('Hidden'),
            state: 'root.nav.pbx.users.view.hidden'
          }
        ]
      },
      controller: 'UserController as ctrl'
    })
    .state('root.nav.pbx.users.view.general', {
      url: '/general',
      template: userGeneralTemplate
    })
    .state('root.nav.pbx.users.view.settings', {
      url: '/settings',
      template: userSettingsTemplate
    })
    .state('root.nav.pbx.users.view.hidden', {
      url: '/hidden',
      template: userHiddenTemplate,
      data: {
        hideSave: true
      }
    })
})
