import angular from 'angular';
import { react2angular } from 'react2angular';
import ContactCenterConfiguration from '@ucc/cc-config';
import Phoenix, { RoleChangeDropdown, PhoneSettingsBulkChanges } from '@ucc/phoenix';
import GuidedSetup from '@ucc/guided-setup';
import UnifiedAdminDashboard from '@ucc/unified-admin-dashboard';
import UnifiedAdminLocations from '@ucc/unified-admin-locations';

import 'angular-messages';
import 'angular-material';
import 'angular-material-icons';
import 'ng-infinite-scroll';
import 'angular-ui-router';
import 'angular-dynamic-locale';
import 'ngstorage';
import 'angular-cookies';
import 'restangular';
import 'angular-gettext';
import 'angular-sanitize';
import 'angular-cache';
import 'angular-ui-bootstrap';

let _module = angular
  .module('platformApp', [
    'ui.router',
    'ngSanitize',
    'infinite-scroll',
    'restangular',
    'tmh.dynamicLocale',
    'gettext',
    'ngStorage',
    'ngMaterial',
    'ngMdIcons',
    'ngMessages',
    'ui.bootstrap',
    'Jive_Navbar',
    'Jive_DragNDrop',
    'angulartics',
    'angulartics.google.analytics',
    'exceptionOverride',
    'ngclipboard',
    'com.2fdevs.videogular',
    'com.2fdevs.videogular.plugins.controls',
    'ngFileSaver',
    'angular-cache'
  ])
  .component(
    'contactCenterConfiguration',
    react2angular(ContactCenterConfiguration, [
      'oauthToken',
      'orgId',
      'userContext',
      'locale',
      'host',
      'whitelabel',
      'baseUrl'
    ])
  )
  .component('phoenix', react2angular(Phoenix, ['oauthToken', 'orgId', 'locale', 'history']))
  .component(
    'roleChangeDropdown',
    react2angular(RoleChangeDropdown, [
      'oauthToken',
      'lmiAccountKey',
      'locale',
      'showNotificationCheckbox',
      'initialSelectedRole',
      'selectedRole',
      'sendNotification',
      'onChange'
    ])
  )
  .component(
    'phoneSettingsBulkChanges',
    react2angular(PhoneSettingsBulkChanges, [
      'oauthToken',
      'orgId',
      'accountKey',
      'locale',
      'history',
      'path',
      'isOpen',
      'onClose',
      'onConfirm'
    ])
  )
  .component(
    'guidedSetup',
    react2angular(GuidedSetup, ['oauthToken', 'orgId', 'locale', 'history', 'error401Handler', 'principal'])
  )
  .component(
    'unifiedAdminDashboard',
    react2angular(UnifiedAdminDashboard, ['orgId', 'locale', 'oauthToken', 'principal', 'users', 'lmiAccountKey'])
  )
  .component(
    'unifiedAdminLocations',
    react2angular(UnifiedAdminLocations, [
      'orgId',
      'locale',
      'authToken',
      'history',
      'host',
      'selectedAccountKey',
      'users',
    ])
  );

export default _module;
