import getExtensionController from './getExtensionController.js'
import { getJrnDefaultComponent } from '../getJrn/getJrnDefaultComponent.js'
import _module from 'module.js'
import _ from 'lodash'

let getExtensionComponent = _.cloneDeep(getJrnDefaultComponent)
getExtensionComponent.controller = getExtensionController
getExtensionComponent.bindings.type = '@?'

_module.component('getExtension', getExtensionComponent)
