import _module from 'module.js'
import _ from 'lodash'

_module.directive('extension', function (
  Extension,
  portalApi,
  $stateParams,
  $q,
  globalState
) {
  'ngInject'
  return {
    template: '<input number-only extension-length unique-extension>',
    replace: true,
    restrict: 'A',
    scope: {
      region: '=',
      extNumber: '=ngModel',
      initExtensionNumber: '=',
      isNew: '=',
      inLine: '='
    },
    require: ['ngModel'],
    link: (scope, $input, attrs, ctrls) => {
      let ngModel = ctrls[0],
        extensionApi = new Extension(null),
        value = scope.extNumber,
        initExtensionNumber = scope.initExtensionNumber,
        isNew = scope.isNew,
        inLine = scope.inLine


      scope.$watch(
        'region',
        (newValue, oldValue) => {
          if (newValue !== oldValue) {
            ngModel.$validate()
          }
        },
        true
      )

      scope.$watch(
        'initExtensionNumber',
        (newValue, oldValue) => {
          if (newValue !== oldValue && ngModel.$viewValue !== newValue) {
            ngModel.$validate()
          }
        },
        true
      )

      ngModel.$render = () => {
        $input.val(ngModel.$viewValue || '')
      }


      ngModel.$asyncValidators.emergencyNumber = val => {
        let dfd = $q.defer()
        let extensionRegion = scope.region
        let initExtensionNumber = scope.initExtensionNumber
        const initialExtensionNumber = !isNew && inLine ? value : initExtensionNumber

        portalApi.regionInfo
          .pbxs(globalState.selectedPbxUUID)
          .regionInfo()
          .get()
          .then(regionInfo => {
            let allReservedNumbers = []
            let allEmergencyNumbers = []
            if (extensionRegion) {
              let thisRegion = _.find(globalState.supportedRegions, {
                code: extensionRegion
              })
              if (thisRegion) {
                regionInfo.regionsInUse.push(thisRegion)
              }
            }

            angular.forEach(regionInfo.regionsInUse, region => {
              allReservedNumbers = _.union(
                allReservedNumbers,
                region.reservedNumbers
              )
              allEmergencyNumbers = _.union(
                allEmergencyNumbers,
                region.reservedNumberPrefixes
              )
            })

            for (let i in allEmergencyNumbers) {
              const shouldNotVerifyEmergencyNumbers = !isNew  && initialExtensionNumber && initialExtensionNumber.startsWith(allEmergencyNumbers[i]) && val === initialExtensionNumber
              if (val && val.startsWith(allEmergencyNumbers[i]) && !shouldNotVerifyEmergencyNumbers) {
                dfd.reject('reserved number')
                break
              }
            }

            const currentRegion = _.find(globalState.supportedRegions, {
                code: globalState._selectedPbx.region
              })

            if(currentRegion.reservedNumberPattern){
              const reservedNumberPattern = new RegExp(currentRegion.reservedNumberPattern)
              if(reservedNumberPattern.test(val)) {
                dfd.reject('reserved number')
              }
            }

            const shouldNotVerifyReservedNumbers = !isNew  && initialExtensionNumber && _.indexOf(allReservedNumbers, initialExtensionNumber) > -1 && val === initialExtensionNumber
            if (_.indexOf(allReservedNumbers, val) > -1 && !shouldNotVerifyReservedNumbers) {
              dfd.reject('reserved number')
            } else {
              dfd.resolve(true)
            }
          })
        return dfd.promise
      }

      if (value === undefined || value === '') {
        extensionApi.getNext().then(data => {
          ngModel.$setViewValue(data)
          ngModel.$render()
          ngModel.$validate()
        })
      }
    }
  }
})
