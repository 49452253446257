import getPagingZoneTemplate from './getPagingZoneTemplate.html'
import getPagingZoneController from './getPagingZoneController.js'
import module from 'module'

module.component('getPagingZone', {
  template: getPagingZoneTemplate,
  controllerAs: 'getPagingZone',
  bindings: {
    extZoneReference: '<',
    displayString: '='
  },
  require: {
    ngModel: 'ngModel',
    filterGetExtension: '^?filterGetExtension',
    form: '^form'
  },
  controller: getPagingZoneController
})
