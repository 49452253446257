import _module from 'module.js'
import BaseES6 from 'factories/baseES6'

let _stateParams, _portalApi, _filter, _globalState

export default class Did extends BaseES6 {
  constructor (id, did, pbxId, gettextCatalog) {
    'ngInject'
    super(
      id || _stateParams.didId,
      pbxId || _stateParams.pbxId || _globalState.selectedPbxUUID
    )

    this.gettextCatalog = gettextCatalog

    if (id === undefined) {
      throw new Error(this.gettextCatalog.getString('Missing id for new Did'))
    }

    this.action = 'EXTENSION'
    this._type = this.gettextCatalog.getString('Two-Way')
    this._target = null

    if (did) {
      this._map(did)
    }

    this.cacheEnabled = true
  }

  foundUserInfo(email) {
    return _portalApi.identity.pbxs(this._pbxId).users().one(`jive-id/${email}`)
  }

  getName ({ identity, userGroupName, userFirstName, userLastName }) {
    if (!identity) {
      return this.gettextCatalog.getString('Everyone')
    }
    if (!userLastName) {
      return userGroupName
    }
    return [userFirstName, userLastName]
      .filter(str => str && str.length)
      .join(' ')
  }

  isEnableSMS (target) {
    return !!target.permissions.find(p => p === 'ENABLE_SMS')
  }

  isEnableOutboundCallerId (target) {
    return !!target.permissions.find(p => p === 'ENABLE_OUTBOUND_CALLS')
  }

  isEnableAutoReplyManagement (target) {
    return !!target.permissions.find(p => p === 'ENABLE_SMS_AUTO_REPLY_MANAGEMENT')
  }

  getApi () {
    if (!this._api) {
      this._api = _portalApi.pstn.pbxs(this._pbxId).dids
    }
    return this._api
  }

  getPath () {
    if (!this._path) {
      this._path = _portalApi.pstn.pbxs(this._pbxId).dids(this._id)
    }
    return this._path
  }

  getReferencesPath () {
    if (!this._referencesPath) {
      this._referencesPath = _portalApi.pstn
        .pbxs(this._pbxId)
        .dids(this._id)
        .one('references')
    }
    return this._referencesPath
  }

  getType (type) {
    return _filter('didType')(type)
  }

  getTarget (data) {
    return _filter('didTarget')(data)
  }

  map (data) {
    // number format
    this.number = _filter('tel')(data.number)

    if (data.action) {
      this.action = data.action
    }

    this._type = this.getType(data)
    this._target = this.getTarget(data)

    // add id field in didPermissions
    this.didPermissions = this.didPermissions.map(permission => {
      permission.id = permission.identity
      permission.text = this.getName(permission)
      permission.active = false
      permission.enableSMS = this.isEnableSMS(permission)
      permission.enableOuboundCallerId = this.isEnableOutboundCallerId(permission)
      permission.enableAutoReplyManagement = this.isEnableAutoReplyManagement(permission)
      return permission
    })
  }

  build (payload) {
    payload.didPermissions = this.didPermissions.map(didPermission => {
      const permissions = []
      if (didPermission.enableSMS) {
        permissions.push('ENABLE_SMS')
      }
      if (didPermission.enableOuboundCallerId) {
        permissions.push('ENABLE_OUTBOUND_CALLS')
      }
      if (didPermission.enableSMS && didPermission.enableAutoReplyManagement) {
        permissions.push('ENABLE_SMS_AUTO_REPLY_MANAGEMENT')
      }
      return {
        identity: didPermission.id,
        permissions
      }
    })
    return payload
  }

  mapListItem (did) {
    return {
      id: did.jrn,
      text: did.name
    }
  }

  getClass () {
    return 'Did'
  }
}

_module.factory(
  'Did',
  ($stateParams, portalApi, gettextCatalog, $filter, globalState) => {
    'ngInject'
    _stateParams = $stateParams
    _portalApi = portalApi
    _filter = $filter
    _globalState = globalState

    return (id, did, pbxId = null) => new Did(id, did, pbxId, gettextCatalog)
  }
)
