import _module from 'module.js'

_module.directive('nonBlank', function () {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, $input, attrs, ngModelCtrl) {
      ngModelCtrl.$validators.nonBlank = () => {
        return $input.val() && $input.val().trim().length > 0
      }
    }
  }
})
