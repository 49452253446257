import _module from 'module.js'

_module.filter('multi', function () {
  return function (limit) {
    if (limit > 1) {
      return '+' + (limit - 1)
    }

    return ''
  }
})
