import _module from 'module.js'
import secureDevicesInfoTemplate from './secureDevicesInfoTemplate.html'
import { SecureDevicesInfoController } from './secureDevicesInfoController'

_module.component('secureDevicesInfo', {
  template: secureDevicesInfoTemplate,
  bindings: {
    secureCallingDevicesDiff: '<'
  },
  controllerAs: 'secureDevicesInfoCtrl',
  controller: SecureDevicesInfoController
})
