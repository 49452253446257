import _module from 'module.js'
import { fetchJiveEntitlements } from '@ucc/phoenix'

class LicensesService {
  constructor (portalApi, globalState) {
    'ngInject'
    this.portalApi = portalApi
    this.globalState = globalState

  }

  getLicenses (pbxId) {
    return this.portalApi.pbx
      .pbxs(pbxId)
      .one('licenses')
      .get()
      .then(response => response.plain())
      .catch(err => {
        console.log(err)
        return []
      })
  }

  async getEntitlements(accountKey) {
    const oauthToken = this.globalState.oauthToken
    return fetchJiveEntitlements({token: oauthToken, accountKey: accountKey})
  }
}

_module.service('licensesService', LicensesService)
