export default class ChangeExtensionDigitPopup {
  constructor (
    originalLength,
    $mdDialog,
    portalApi,
    globalState,
    gettextCatalog,
    portalUtil
  ) {
    'ngInject'
    this.$mdDialog = $mdDialog
    this.portalApi = portalApi
    this.portalUtil = portalUtil
    this.globalState = globalState
    this.gettextCatalog = gettextCatalog
    this.data = {
      originalLength: originalLength,
      newLength: parseInt(originalLength),
      position: '0',
      filler: '0',
      noConflict: false,
      conflicts: null,
      busy: false,
      checked: false,
      confirmation: false,
      allowSevenDigitExtension: this.globalState.selectedPbx.featureFlags[
        'seven.digit.extension'
      ]
    }
  }

  check () {
    this.data.busy = true
    this.data.checked = false
    this.portalApi.pbx
      .pbxs(this.globalState.selectedPbxUUID)
      .one('extension-digits/check-conflict')
      .get({
        newLength: this.data.newLength,
        position: this.data.position,
        filler: this.data.filler
      })
      .then(data => {
        this.data.busy = false
        this.data.checked = true
        data = data.plain()
        if (_.isEmpty(data)) {
          this.data.noConflict = true
          this.data.conflicts = null
        } else {
          this.data.noConflict = false
          this.data.conflicts = data
        }
      })
      .catch(error => {
        this.data.busy = false
        this.portalUtil.showErrorAlert(
          htis.gettextCatalog.getString(
            'Conflict checking failed, please retry.'
          )
        )
        throw error
      })
  }

  isChangeAllowed () {
    return this.data.checked && this.data.noConflict
  }

  positionChanged () {
    this.data.checked = false
    this.data.noConflict = false
    this.data.conflicts = null
  }

  lengthChanged () {
    this.data.checked = false
    this.data.noConflict = false
    this.data.conflicts = null

    if (this.data.newLength > this.data.originalLength) {
      var diff = this.data.newLength - this.data.originalLength
      this.data.fillerRE = new RegExp('^\\d{' + diff + '}$')
      this.data.filler = ''
      for (var i = 0; i < diff; i++) {
        this.data.filler = this.data.filler + '0'
      }
    }
  }

  confirmation () {
    this.data.confirmation = true
  }

  noChange () {
    this.data.confirmation = false
  }

  change () {
    this.data.confirmation = false
    this.data.busy = true
    var payload = this.portalApi.pbx
      .pbxs(this.globalState.selectedPbxUUID)
      .one('extension-digits/update')
    payload.newLength = this.data.newLength
    payload.position = this.data.position
    if (this.data.newLength > this.data.originalLength) {
      payload.filler = this.data.filler
    }
    // This is a long operation, set the timeout to be 10 minutes 10 * 60 * 1000 = 600,000 milliseconds
    payload
      .withHttpConfig({
        timeout: 600000
      })
      .put()
      .then(() => {
        this.data.busy = false
        this.$mdDialog.hide(this.data.newLength)
      })
      .catch(error => {
        this.data.busy = false
        this.portalUtil.showErrorAlert(
          this.gettextCatalog.getString('Update failed, please retry.')
        )
        throw error
      })
  }

  cancel () {
    this.$mdDialog.cancel('cancel')
  }
}
