export default class MusicListsController {
  constructor (portalApi, $stateParams, gettextCatalog, holdMusic) {
    'ngInject'
    this.portalApi = portalApi
    this.$stateParams = $stateParams
    this.gettextCatalog = gettextCatalog
    this.selectedType = this.selectableTypes['BUILT_IN']
    this.holdMusic = holdMusic
    this.$onInit = () => {
      this.ngModel.$render = () => {
        if (!this.selectedList) return
        let type = this.selectedList.type
        let typeIsList =
          type === this.setSelectedType('UPLOADED') ||
          type === this.setSelectedType('BUILT_IN')

        if (!type && !typeIsList) this.selectedType = null
      }
    }
  }

  setSelectedType (type) {
    this.selectedType = this.selectableTypes[type]
    return this.selectedType
  }

  getUploadedMusic () {
    return this.holdMusic.getMusic('uploaded')
  }

  getBuiltInMusic () {
    return this.holdMusic.getMusic('builtIn')
  }

  fileUploaded (song) {
    if (this.selectedList.type === this.selectableTypes['UPLOADED']) {
      this.selectedList.items.unshift(song)
    }
  }

  setViewModel (data, type) {
    data.type = type
    this.ngModel.$setViewValue(data)
  }

  uploadMusicHandler () {
    this.getUploadedMusic().then(data => {
      this.setViewModel(data, this.setSelectedType('UPLOADED'))
    })
  }

  builtInMusicHandler () {
    this.getBuiltInMusic().then(data => {
      this.setViewModel(data, this.setSelectedType('BUILT_IN'))
    })
  }
}
