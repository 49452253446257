import jrnParser from 'classes/jrnParser'

let _portalApi, _jrn, _state, _window

export default class GetPbxController {
  constructor (gettextCatalog, $state, portalApi, $window) {
    'ngInject'
    this._model = {}
    _portalApi = portalApi
    _state = $state
    _jrn = jrnParser
    _window = $window

    this.placeholder = gettextCatalog.getString('Select a PBX...')

    this.api = _portalApi.pbx.pbxs

    this.$onInit = () => {
      this.setViewValue = item => {
        this.ngModel.$setViewValue(item.id)
      }
    }
  }

  map (pbx) {
    return {
      id: pbx.jrn,
      text: pbx.name + ' (' + pbx.domain + ')'
    }
  }

  async openLink () {
    const pbxId = _jrn.parse(this.jrn).getAccount()
    const pbx = await this.api.call(this, pbxId).get({ cache: true })

    let url = _state.href('root.nav.pbx.settings.general', {id: pbx.domain})

    const starting = 'index.htm'
    const startingIndex = url.indexOf('index.htm')
    if (startingIndex != -1) {
      url = url.substring(startingIndex + starting.length + 1)
    }

    _window.open(url, '_blank')
  }

}


