let _confirmationWindowWarn

export default class VoicemailGreetingUploaderController {
  constructor (
    portalUtil,
    gettextCatalog,
    greetingRecorderWizard,
    confirmationWindowOK,
    normalizationPopup
  ) {
    'ngInject'
    this.portalUtil = portalUtil
    this.gettextCatalog = gettextCatalog
    this.greetingRecorderWizard = greetingRecorderWizard
    this.confirmationWindowOK = confirmationWindowOK
    this.normalizationPopup = normalizationPopup
    this.loading = false

    this.uploadFile = file => {
      this.normalizationPopup
        .open()
        .then(volume => {
          this.volume = volume
          this.loading = true
          this.onFileUpload(file).then(data => {
            this.ngModel.$setViewValue(data)
            this.initialize()
              .get({
                redirect: false,
                transcode: true,
                format: 'mp3',
                vmgtype: this.param
              })
              .then(url => {
                this.loading = false
                this.voicemailGreeting.fileUrl = url.contentUrl
              })
          })
        })
        .catch(error => {
          this.loading = false
          this.portalUtil.showErrorAlert(
            this.gettextCatalog.getString(
              'Greeting upload failed, please retry.'
            )
          )
          throw error
        })
    }
  }

  onFileUpload (file) {
    return this.uploader()(file, { type: this.param, volume: this.volume })
  }

  initialize () {
    return this.getGreeting()
  }

  startRecording () {
    if (is.safari() || is.ie()) {
      this.confirmationWindowOK.show(
        this.gettextCatalog.getString('Warning'),
        this.gettextCatalog.getString(
          'Audio recording is supported by Chrome, Firefox or Edge browser.'
        ),
        true
      )
    } else {
      this.greetingRecorderWizard.open().then(newGreeting => {
        this.uploadFile(newGreeting)
      })
    }
  }
}
