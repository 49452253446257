import _module from 'module.js'
import _ from 'lodash'

_module.directive('awsStorage', function (
  portalApi,
  $q,
  globalState
) {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, $input, attrs, ngModelCtrl) {
      let originalValue = scope.$eval(attrs.ngModel)

      ngModelCtrl.$validators.awsStorage = (
        model,
        view
      ) => {
        var value = model && model.value || view && view.value
        return value || attrs.hasAwsSecret == "true"
      }
    }
  }
})
