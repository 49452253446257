import _module from 'module.js'

_module.directive('confirmPassword', function () {
  return {
    restrict: 'A',
    require: 'ngModel',
    scope: {
      password: '='
    },
    link: function (scope, element, attrs, ngModel) {
      ngModel.$validators.confirmPassword = value => {
        return value === scope.password
      }

      scope.$watch('password', () => {
        ngModel.$validate()
      })
    }
  }
})
