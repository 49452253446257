import _module from 'module.js'
import itemTemplate from 'controllers/item/itemTemplate.html'
import settingsProxiesTemplate from 'controllers/settings/settingsProxies/settingsProxiesTemplate.html'
import settingsOutboundProxiesTemplate from 'controllers/settings/settingsOutboundProxies/outboundProxiesTemplate.html'
import settingsExtensionMappingsTemplate from 'controllers/settings/settingsExtensionMappings/extensionMappingsTemplate.html'
import settingsBillingGroupsTemplate from 'controllers/settings/settingsBillingGroups/billingGroupsTemplate.html'
import settingsHiddenTemplate from 'controllers/settings/settingsHidden/settingsHiddenTemplate.html'
import settingsJbcTemplate from 'controllers/settings/settingsJbc/jbcsTemplate.html'
import settingsGeneralTemplate from 'controllers/settings/settingsGeneral/settingsGeneralTemplate.html'
import settingsEmergencyTemplate from 'controllers/settings/settingsEmergency/settingsEmergencyTemplate.html'
import settingsStorageTemplate from 'controllers/settings/settingsStorage/settingsStorageTemplate.html'

_module.config((gettext, $stateProvider) => {
  'ngInject'

  $stateProvider
    .state('root.nav.pbx.settings', {
      url: '/settings',
      template: itemTemplate,
      controller: 'SettingsController as ctrl',
      abstract: true,
      data: {
        title: gettext('System Settings'),
        itemIcon: 'icon-settings',
        classes: ['large-page-name'],
        tabs: [
          {
            id: 'setting-general',
            label: gettext('General'),
            state: 'root.nav.pbx.settings.general'
          },
          {
            id: 'setting-recording',
            label: gettext('Recording and storage'),
            state: 'root.nav.pbx.settings.recording'
          },
          {
            id: 'setting-bc',
            label: gettext('Business Continuity'),
            state: 'root.nav.pbx.settings.jbc'
          },
          {
            id: 'setting-emergency',
            label: gettext('Emergency Locations'),
            state: 'root.nav.pbx.settings.location'
          },
          {
            id: 'setting-extension-mappings',
            label: gettext('Extension Mapping'),
            state: 'root.nav.pbx.settings.extensionMappings'
          },
          {
            id: 'setting-billing-groups',
            label: gettext('Billing Groups'),
            state: 'root.nav.pbx.settings.billingGroups'
          }
        ],
        hiddenTab: [
          {
            id: 'setting-outbound-proxies',
            label: gettext('Outbound Proxies'),
            state: 'root.nav.pbx.settings.outboundProxies'
          },
          {
            id: 'setting-reg-proxies',
            label: gettext('Registration Proxies'),
            state: 'root.nav.pbx.settings.proxies'
          },
          {
            id: 'setting-hidden',
            label: gettext('Hidden'),
            state: 'root.nav.pbx.settings.hidden'
          }
        ]
      }
    })
    .state('root.nav.pbx.settings.general', {
      url: '/general',
      template: settingsGeneralTemplate
    })
    .state('root.nav.pbx.settings.recording', {
      url: '/recording',
      template: settingsStorageTemplate
    })
    .state('root.nav.pbx.settings.location', {
      url: '/location',
      template: settingsEmergencyTemplate
    })
    .state('root.nav.pbx.settings.proxies', {
      url: '/proxies',
      controller: 'ProxiesController as ctrl',
      template: settingsProxiesTemplate,
      reloadOnSearch: false,
      data: {
        hideSave: true
      }
    })
    .state('root.nav.pbx.settings.outboundProxies', {
      url: '/outboundproxies',
      controller: 'OutboundProxiesController as ctrl',
      template: settingsOutboundProxiesTemplate,
      reloadOnSearch: false,
      data: {
        hideSave: true
      }
    })
    .state('root.nav.pbx.settings.extensionMappings', {
      url: '/extensionMappings',
      controller: 'ExtensionMappingsController as ctrl',
      template: settingsExtensionMappingsTemplate,
      reloadOnSearch: false,
      data: {
        hideSave: true
      }
    })
    .state('root.nav.pbx.settings.billingGroups', {
      url: '/billinggroups',
      controller: 'BillingGroupController as ctrl',
      template: settingsBillingGroupsTemplate,
      reloadOnSearch: false
    })
    .state('root.nav.pbx.settings.jbc', {
      url: '/jbc',
      controller: 'JbcsController as ctrl',
      template: settingsJbcTemplate,
      reloadOnSearch: false,
      data: {
        hideSave: true
      }
    })
    .state('root.nav.pbx.settings.hidden', {
      url: '/hidden',
      template: settingsHiddenTemplate
    })
})
