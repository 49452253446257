import _ from 'lodash'
let _langSwitcher, _$window

export default class langSwitcherController {
    constructor (langSwitcher, $window) {
        'ngInject'

        _$window          = $window
        _langSwitcher     = langSwitcher
        this.languages    = langSwitcher.languages

    }

    get selectedLanguage () {
        return _.get(_langSwitcher, 'selectedLanguage.flagCode')
    }

    toggleShow () {
        this.show = !this.show
    }

    switchLanguage (language, $evt) {
        _langSwitcher.language(language, $evt)
        _$window.location.reload() /*bc of move to one-time bindings, needs to reload for translations to show.*/
    }
}
