/**
 * @ngdoc function
 * @name platformApp.factory:directoryService
 * @description
 * # directoryRoomService
 * Controller of the platformApp
 */
import _module from 'module.js'
import BaseES6 from 'factories/baseES6'

let _portalApi, _globalState

export default class CorporateDirectory extends BaseES6 {
  constructor (id, directory, gettextCatalog) {
    'ngInject'
    super(id)

    this.gettextCatalog = gettextCatalog

    if (id === undefined) {
      throw new Error(
        this.gettextCatalog.getString('Missing id for new CorporateDirectory')
      )
    }

    if (directory) {
      this._map(directory)
    }
  }

  getApi () {
    if (!this._api) {
      this._api = _portalApi.userAgent.pbxs(
        _globalState.selectedPbxUUID
      ).corporateDirectories
    }

    return this._api
  }

  getPath () {
    if (!this._path) {
      this._path = _portalApi.userAgent
        .pbxs(_globalState.selectedPbxUUID)
        .corporateDirectories(this._id)
    }

    return this._path
  }

  getReferencesPath () {
    if (!this._referencesPath) {
      this._referencesPath = _portalApi.userAgent
        .pbxs(_globalState.selectedPbxUUID)
        .corporateDirectories(this._id)
        .one('references')
    }
    return this._referencesPath
  }

  mapListItem (dir) {
    return {
      id: dir.jrn,
      text: dir.name
    }
  }

  getClass () {
    return 'CorporateDirectory'
  }

  static factory (globalState, portalApi, gettextCatalog) {
    'ngInject'
    _portalApi = portalApi
    _globalState = globalState

    return (id, corporateDirectory) => {
      return new CorporateDirectory(id, corporateDirectory, gettextCatalog)
    }
  }
}

_module.factory('CorporateDirectory', CorporateDirectory.factory)
