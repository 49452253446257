import _ from 'lodash'

const isCompleteButtonConfig = buttonConfig => {
  return !!buttonConfig.parameters
}

const hasAtLeastOneCompleteButton = arr => {
  return _(arr).some(button => {
    return isCompleteButtonConfig(button)
  })
}

let DEFAULT_MAX_ACCOUNTS = 100

export default class buttonConfigurationModeController {
  constructor () {
    'ngInject'
  }

  canBeRemoved (button) {
    return this.buttonConfigs.length > 1 && isCompleteButtonConfig(button)
  }

  serializeButtonConfigs (buttonConfigs) {
    return _(buttonConfigs).reduce((list, buttonConfig, idx) => {
      if (isCompleteButtonConfig(buttonConfig)) {
        list[0 + ':' + idx] = buttonConfig
      }
      return list
    }, {})
  }

  removeButtonConfig (button, isLast) {
    this.buttonConfigs = _(this.buttonConfigs)
      .reject(_button => {
        return button === _button
      })
      .value()

    var deviceButtonConfigs = this.serializeButtonConfigs(this.buttonConfigs)
    this.ngModel.$setViewValue(_.clone(deviceButtonConfigs))
    if (isLast) {
      this.addNewConfigurableButtonToList()
    }
  }

  deviceButtonConfigurationEdited (button, isLast) {
    let deviceButtonConfigs = this.serializeButtonConfigs(this.buttonConfigs)
    this.ngModel.$setViewValue(_.clone(deviceButtonConfigs))
    if (isLast) {
      this.addNewConfigurableButtonToList()
    }
  }

  addNewConfigurableButtonToList () {
    let remainingButtonsToConfigure =
      this.maxButtons - this.buttonConfigs.length
    if (remainingButtonsToConfigure > 0) {
      this.buttonConfigs.push({
        label: '',
        type: '',
        userEditable: false
      })
    }
  }

  prepareData () {
    this.buttonConfigs = []
    this.userAgentType = this.configs.userAgentTypeModel
    this.maxButtons = this.userAgentType !== null &&
      this.userAgentType.maxAccounts !== 0
      ? this.userAgentType.maxAccounts
      : DEFAULT_MAX_ACCOUNTS

    let sortedKeys = _.sortBy(_.keys(this.buttons), id => {
      let groupIndex = Number(id.split(':')[0])
      let buttonIndex = Number(id.split(':')[1])
      // assuming there is always less than 1000 buttons/lines
      return groupIndex * 1000 + buttonIndex
    })

    _.forEach(sortedKeys, key => {
      this.buttonConfigs.push(this.buttons[key])
    })

    this.addNewConfigurableButtonToList()
  }

  $onInit () {
    this.ngModel.$render = () => {
      this.prepareData()
    }
  }
}
