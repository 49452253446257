import _module from 'module.js'
import jrnParser from 'classes/jrnParser'
import addSipTrunkFormTemplate
  from '../views/common/addSipTrunkForm.html'

class AddSipTrunkFormController {
  constructor (
      Device,
      Line,
      User,
      Hardware,
      portalApi,
      $stateParams,
      $q,
      $analytics,
      gettextCatalog,
      portalUtil,
      $mdDialog,
      globalState
      ) {
          'ngInject'
          this.form={
            device: {
              useUserFullNameAsName: false,
              useUserFullNameAsStationLabel: false,
              presenceAware: true
            },
              hardware:{
              type: 'jrn:user-agent::jive::user-agent-type/goto.sip_trunk',
              },
              busy: false,
          }
          this.Device = Device
          this.Line = Line
          this.User = User
          this.Hardware = Hardware
          this.portalApi = portalApi
          this.$stateParams = $stateParams
          this.$q = $q
          this.jrn = jrnParser
          this.$analytics = $analytics
          this.gettextCatalog = gettextCatalog
          this.portalUtil = portalUtil
          this.$mdDialog = $mdDialog
          this.globalState = globalState
          this.showExtensionWarning = globalState.selectedPbx.extensionDigits == 3
        }

  createSipTrunk (device, line, hardware) {      
    var _device = new this.Device(null, device)

    if (hardware) {
      _device.userAgent = hardware.jrn
    }

    _device.buttons = {
      '0:0': {
        label: line.extensionNumber,
        parameters: line.jrn,
        type: 'LINE',
        userEditable: true
      }
    }

    return _device.save().then(result => 
      result.plain()
    )
  }

  createLine (line) {
    var _line = new this.Line(null, line)
    return _line.save().then(result => result.plain())
  }

  createHardware (hardware) {
    let _hardware = new this.Hardware(null, hardware)
    return _hardware.save()
  }

  complete () {
    this.form.busy = true

    this.form.device.name = this.form.sipTrunkDisplayName
    this.form.line.name = this.form.device.name
    
    let creators = []
    creators.push(this.createLine(this.form.line))
    creators.push(this.createHardware(this.form.hardware))

    this.$q
    .all(creators)
    .then(([line, hardware]) => {
      return this.createSipTrunk(
        this.form.device,
        line,
        hardware
      ).then(newDevice => {
        this.form.busy = false
        this.$mdDialog.hide(newDevice)
      })
    })
    .catch(error => {
      this.form.busy = false
      this.portalUtil.showErrorAlert(
        this.gettextCatalog.getString('Save failed, please retry.')
      )
      throw error
    })
  }
  
  cancelDialog () {
    this.$mdDialog.cancel()
  }

  static factory (
      Device,
      Line,
      User,
      Hardware,
      portalApi,
      $stateParams,
      $q,
      $analytics,
      gettextCatalog,
      portalUtil,
      $mdDialog,
      globalState
    ) {
      'ngInject'
      return new AddSipTrunkFormController(
        Device,
        Line,
        User,
        Hardware,
        portalApi,
        $stateParams,
        $q,
        $analytics,
        gettextCatalog,
        portalUtil,
        $mdDialog,
        globalState
      )
    }
}

class AddSipTrunkForm {
  constructor ($mdDialog, $analytics) {
    'ngInject'
    this.$mdDialog = $mdDialog
    this.$analytics = $analytics
  }

  open () {
    return this.$mdDialog.show({
      template: addSipTrunkFormTemplate,
      controllerAs: 'ctrl',
      controller: AddSipTrunkFormController.factory,
      parent: angular.element(document.body),
      clickOutsideToClose: true,
      fullscreen: true
    })
  }

  static factory ($mdDialog, $analytics) {
    'ngInject'
    return new AddSipTrunkForm($mdDialog, $analytics)
  }
}
  
_module.factory('addSipTrunkForm', AddSipTrunkForm.factory)