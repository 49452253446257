import _module from 'module.js'
import unifiedAdminLocationsTemplate from 'controllers/unifiedAdminLocations/unifiedAdminLocationsTemplate.html'
_module.config($stateProvider => {
  'ngInject'

  $stateProvider
    .state('root.nav.pbx.unifiedAdminLocations', {
      url: '/unified-admin-locations',
      template: unifiedAdminLocationsTemplate,
      controller: 'UnifiedAdminLocationsController as ctrl',
      abstract: true
    })
    .state('root.nav.pbx.unifiedAdminLocations.emergencyLocations', {
      url: '/emergency-locations/*path',
    })
    .state('root.nav.pbx.unifiedAdminLocations.emergencyLocationsDetails', {
      url: '/emergency-locations-details/:itemId*path',
    })
})
