import ListControllerBase from 'classes/listController.class'
import _module from 'module.js'
import jrnParser from 'classes/jrnParser'

export default class DidsController extends ListControllerBase {
  constructor(
    $state,
    $stateParams,
    $location,
    $analytics,
    Did,
    gettextCatalog,
    infiniteLoader,
    navigation,
    globalState,
    portalUtil,
    portalApi,
    FileSaver,
    $window,
    deletionConfirmation,
    pbxUtil,
  ) {
    'ngInject'
    super(
      gettextCatalog,
      $analytics,
      $location,
      portalUtil,
      globalState,
      navigation,
      $state,
      $stateParams
    )
    this.$analytics = $analytics
    this.gettextCatalog = gettextCatalog
    this.deletionConfirmation = deletionConfirmation
    this.Did = Did
    this.$window = $window
    this.FileSaver = FileSaver
    this.portalApi = portalApi
    this.selectedLanguage = globalState.currentLanguage.langCode || 'en-US'
    this.isDidDeletionEnabled = globalState._selectedPbx.featureFlags['pbx.did.deletion']

    this.title = this.gettextCatalog.getString('Phone Numbers')
    this.filterStates =
      [
        {
          text: this.gettextCatalog.getString('Unassigned'),
          value: "unassigned"
        },
        {
          text: this.gettextCatalog.getString('Assigned'),
          value: "assigned"
        }, {
          text: this.gettextCatalog.getString('All'),
          value: null
        }
      ]

    this.selectedFilter = this.filterStates[2]

    if (this.globalState.isE911Enabled) {
      this.exportDidsText = this.gettextCatalog.getString('Export to CSV')
    }

    this.headers = [
      {
        width: 20,
        name: this.gettextCatalog.getString('Name'),
        sortBy: 'name',
        asc: null
      },
      {
        width: 15,
        name: this.gettextCatalog.getString('Number'),
        sortBy: 'number',
        asc: true
      },
      {
        width: 10,
        name: this.gettextCatalog.getString('Caller ID'),
        sortBy: 'caller_id_name',
        asc: null
      },
      {
        width: 10,
        name: this.gettextCatalog.getString('Type')
      },
      {
        width: 35,
        name: this.gettextCatalog.getString('Route to')
      },
      {
        width: 10,
        name: ''
      }
    ]

    this.loader = infiniteLoader(this.Did)
    this.orderingAllowed =
      (globalState.selectedPbx.region === 'US' ||
        globalState.selectedPbx.region === 'CA') &&
      !globalState.isWhiteLabel
    this.orderButtonText = this.gettextCatalog.getString('Order New')
    this.transferButtonText = this.gettextCatalog.getString('Transfer')

    let smsCampaignInformations = globalState.smsCampaignInformations
    this.goToAdminSmsCampaignUrl = pbxUtil.getGoToAdminUrl(globalState._selectedPbx) + '/sms-campaign'
    this.smsCampaignRegistrationAllowed = smsCampaignInformations.needToRegisterSmsCampaign && smsCampaignInformations.userCanRegisterSmsCampaign
    this.smsCampaignRegistration = this.gettextCatalog.getString('SMS compliance')
  }

  applyFilters() {
    this.data.filter = this.selectedFilter.value
    this.search(this.data)
  }

  async toggleActivation(item) {
    const id = jrnParser.parse(item.jrn).getResources()[1]
    const data = await new this.Did(id, item).get()
    data.active = !data.active
    item.active = !item.active
    data.update(!item.active)
  }


  delete(event, item) {
    let name = this.gettextCatalog.getString('phone number') + ': ' + item.name
    let id = jrnParser.parse(item.jrn).getResources()[1]
    let did = new this.Did(id)

    this.deletionConfirmation.confirm(event, name, item, did)
      .then(
        () => {
          //we change the status
          this.toggleActivation(item)
        },
        () => {
          // do nothing for cancel.
        }
      )
      .catch(error => {
        this.portalUtil.showErrorAlert(
          this.gettextCatalog.getString('Delete failed, please retry.')
        )
        throw error
      })
  }

  itemSelected(item) {
    super.itemSelected(item, 'root.nav.pbx.dids.view.general', 'didId')
  }

  async onUpdate(item) {
    const id = jrnParser.parse(item.jrn).getResources()[1]
    const data = await new this.Did(id, item).get()
    item.targetDidName = data.targetDidName
    item.targetDidNumber = data.targetDidNumber
    item.targetExtensionName = data.targetExtensionName
    item.targetExtensionNumber = data.targetExtensionNumber
  }

  orderNew() {
    this.$window.open('/ordering/numbers', '_blank').focus()
  }

  transferNumber() {
    this.$window.open('/ordering/transfer', '_blank').focus()
  }

  registerSmsCampaign() {
    this.$window.open(this.goToAdminSmsCampaignUrl, 'isFromLegacyPortal').focus()
  }

  exportDids() {
    this.busy = true
    const pbxDomain = this.globalState.selectedPbx.domain

    this.portalApi.tool
      .pbxs(this.globalState.selectedPbxUUID)
      .phoneNumbers()
      .get({ language: this.selectedLanguage })
      .then(csv => {
        const data = new Blob([csv], { type: 'text/plain;charset=utf-8' })
        this.FileSaver.saveAs(data, pbxDomain + '-Phone Number Export.csv')
        this.busy = false
      })
      .catch(error => {
        this.busy = false
        this.portalUtil.showErrorAlert(
          this.gettextCatalog.getString('File download failed, please retry.')
        )
        throw error
      })
  }
}

_module.controller('DidsController', DidsController)
