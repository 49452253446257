import _module from 'module.js'

_module.directive('noDirty', function () {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, element, attrs, ngModelCtrl) {
      // override the $setDirty method on ngModelController
      ngModelCtrl.$setDirty = angular.noop;
    }
  }
})