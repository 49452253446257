export default class DisplayZohoUserNameController {
  constructor (integrationsService) {
    'ngInject'
    this._model = {}
    this.integrationsService = integrationsService
    this.$onInit = () => {
      this.setViewValue = item => {
        this.ngModel.$setViewValue(item.id)
      }
    }
  }

  getUser () {
    if (this.integrationsService.getZohoUsers()) {
      return this.integrationsService.getZohoUsers().find(user => {
        return user.id === this.integrationUserId
      })
    } else {
      return {
        userName: this.integrationUserId
      }
    }
  }
}
