/**
 * @ngdoc function
 * @name platformApp.factory:scheduleService
 * @description
 * # scheduleService
 * Controller of the platformApp
 */
import _module from 'module.js'
import BaseES6 from 'factories/baseES6'

let _stateParams, _portalApi, _globalState
let _stringNone, _stringOpen, _stringClosed

export default class Schedule extends BaseES6 {
  constructor (id, schedule, pbxId, gettextCatalog) {
    'ngInject'
    super(id || _stateParams.scheduleId, pbxId || _globalState.selectedPbxUUID)
    this.gettextCatalog = gettextCatalog

    if (id === undefined) {
      throw new Error(
        this.gettextCatalog.getString('Missing id for new Schedule')
      )
    }

    this.overrideState = 'NONE'
    this.overrideLabel = this.gettextCatalog.getString('None')

    this.holidays = []
    this.timeRanges = []
    this.timeZone = null
    this._timeZone = {
      id: null,
      text: this.gettextCatalog.getString('PBX Default')
    }

    this.overrideOptions = [
      {
        label: this.gettextCatalog.getString('None'),
        value: 'NONE'
      },
      {
        label: this.gettextCatalog.getString('Open'),
        value: 'OPEN'
      },
      {
        label: this.gettextCatalog.getString('Closed'),
        value: 'CLOSED'
      }
    ]

    if (schedule) {
      this._map(schedule)
    }

    this.cacheEnabled = true
  }

  getApi () {
    if (!this._api) this._api = _portalApi.schedule.pbxs(this._pbxId).schedules

    return this._api
  }

  getPath () {
    if (!this._path) { this._path = _portalApi.schedule.pbxs(this._pbxId).schedules(this._id) }

    return this._path
  }

  getReferencesPath () {
    if (!this._referencesPath) {
      this._referencesPath = _portalApi.schedule
        .pbxs(this._pbxId)
        .schedules(this._id)
        .one('references')
    }
    return this._referencesPath
  }

  map (data) {
    if (data.timeZone) {
      this._timeZone = {
        id: data.timeZone,
        text: data.timeZone
      }
    }
  }

  build (payload) {
    payload.timeZone = this._timeZone.id
    return payload
  }

  mapListItem (schedule) {
    return {
      id: schedule.jrn,
      text: schedule.name
    }
  }

  cleanListItem (schedule) {
    let cleanSchedule = {
      jrn: schedule.jrn,
      overrideState: schedule.overrideState,
      overrideLabel: schedule.overrideLabel,
      overrideOptions: this.overrideOptions,
      overrideDisplay: function () {
        for (var i = 0; i < this.overrideOptions.length; i++) {
          if (this.overrideOptions[i].value === this.overrideState) {
            this.overrideLabel = this.overrideOptions[i].label
          }
        }
      },
      name: schedule.name
    }
    cleanSchedule.overrideDisplay()
    return cleanSchedule
  }

  mapBatchData (schedule) {
    schedule.overrideOptions = [
      {
        label: _stringNone,
        value: 'NONE'
      },
      {
        label: _stringOpen,
        value: 'OPEN'
      },
      {
        label: _stringClosed,
        value: 'CLOSED'
      }
    ]

    schedule.overrideDisplay = () => {
      for (var i = 0; i < schedule.overrideOptions.length; i++) {
        if (schedule.overrideOptions[i].value === schedule.overrideState) {
          schedule.overrideLabel = schedule.overrideOptions[i].label
        }
      }
    }
    schedule.overrideDisplay()
    return schedule
  }

  getHolidayData () {
    return this.getPath().get({}).then(data => {
      this.holidays = data.holidays
      return data
    })
  }

  getClass () {
    return 'Schedule'
  }

  static factory ($stateParams, portalApi, gettextCatalog, globalState) {
    'ngInject'
    _stateParams = $stateParams
    _portalApi = portalApi
    _globalState = globalState

    _stringNone = gettextCatalog.getString('None')
    _stringOpen = gettextCatalog.getString('Open')
    _stringClosed = gettextCatalog.getString('Closed')

    return (id, schedule, pbxId = null) => {
      return new Schedule(id, schedule, pbxId, gettextCatalog)
    }
  }
}

_module.factory('Schedule', Schedule.factory)
