import _module from 'module.js'

_module.directive('numberOnly', function () {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, $input, attrs, ngModelCtrl) {
      ngModelCtrl.$validators.numberOnly = () => {
        return /^[0-9]+$/.test($input.val()) || $input.val().length === 0
      }
    }
  }
})
