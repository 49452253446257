/**
 * @ngdoc filter
 * @name platformApp.filter:holiday
 * @description
 * # holiday
 */
import _module from 'module.js'
import moment from 'moment'

_module.filter('holiday', function ($filter) {
  return function (input) {
    input = input || ''
    var out = ''
    var range = input.split('/').map(function (time) {
      return moment(time).toDate()
    })
    out = $filter('date')(range[0], 'shortDate')
    if (out !== $filter('date')(range[1], 'shortDate')) {
      out += ' - ' + $filter('date')(range[1], 'shortDate')
    }
    return out
  }
})
