import _ from 'lodash'
import jrnParser from 'classes/jrnParser'

const parseDisplayInfo = item => (item ? item.text : null)

export default class GetJrnController {
  constructor (
    $q,
    LIST_LIMIT_DEFAULT,
    audioPlayerService,
    confirmationWindowOK,
    $rootScope,
    $timeout
  ) {
    'ngInject'
    this.assignedJrn = ''
    this.selectedItem = {}
    this.busy = false
    this.audioPlayerService = audioPlayerService
    this.$q = $q
    this.$rootScope = $rootScope
    this.LIST_LIMIT_DEFAULT = LIST_LIMIT_DEFAULT
    this.confirmationWindowOK = confirmationWindowOK
    this.$timeout = $timeout

    this.query = {
      q: '',
      noMoreData: false,
      infiniteScrollLength: 0,
      items: []
    }

    if (!this.filter) {
      this.filter = item => !this.jrn || this.jrn !== item.jrn
    }

    if (!this.map) {
      this.map = item =>
        Object.assign(
          {},
          {
            id: item.jrn,
            text: item.name || item.label
          }
        )
    }

    this.infiniteItems = {
      getItemAtIndex: index => {
        if (!this.busy && index > this.query.items.length) {
          this.loadData(false)
          return null
        }
        return this.query.items[index]
      },
      getLength: () => this.query.infiniteScrollLength
    }
  }

  $onInit () {
    this.ngModel.$render = () => {
      const validateOnResponse = promisedResource =>
        promisedResource.then(res => {
          if (res) {
            this.ngModel.$setValidity('required', true)
          }
        })

      if (this.jrn && !this.assignedJrn) {
        validateOnResponse(this.requestJrn(this.jrn))
      } else {
        this.$onChanges = changes => {
          if (changes.jrn) {
            validateOnResponse(this.requestJrn(changes.jrn.currentValue))
          }
        }
      }

      if (!this.clearOnFocus) {
        this.assignedJrn = parseDisplayInfo(this.ngModel.$modelValue)
      }
    }

    if (this.required) {
      this.ngModel.$validators.isSet = value => !!value
    }

    this._resetPaging()

    // tightly coupled to api service
    if (!this.api) {
      throw new Error('Missing api attribute!')
    }
  }

  setSelectedItem (item) {
    this.inputFocusSet(false)
    if (!this.clearOnFocus) {
      this.assignedJrn = parseDisplayInfo(item)
      this.jrn = item.id
    }
    this.selectedItem = item
    this.ngModel.$setViewValue(item)
    this.resetQuery()
  }

  extractJrnId (jrn) {
    const parsedJrn = jrnParser.parse(jrn)
    const resources = parsedJrn.getResources()
    if (!resources || resources.length === 0) {
      // Not a JRN, just use the param
      return jrn
    }
    if (resources[0] === 'pbx') {
      return parsedJrn.getAccount()
    }
    // hack for hold music playlists
    if (resources[0] === 'built-in-hold-music-playlist') {
      return 'default'
    }
    return resources[1]
  }

  async requestJrn (jrnParam) {
    if (!jrnParam || this.busy) {
      return
    }

    // Grab the current item from the backend if it exists
    this.busy = true
    const id = this.extractJrnId(jrnParam)
    try {
      const data = await this.api.call(this, id).get({ cache: true })
      const [item] = [data].map(this.map)

      if (item.id !== this.jrn) {
        // when requesting extensions, the jrn becomes more specific, such as line, dial-plan, etc.
        this.jrn = item.id
      }
      // when assigned user is a hardware user we must not see the assigned user on the select box on init
      if(!this.isJrnUser || (this.isJrnUser && !data.hardwareUser)) {
        this.assignedJrn = parseDisplayInfo(item)
      }
      // when user agent type has immutableTypeId=true is a manually provisioned device and model cannot be edited
      if(this.isJrnUserAgentType && item.immutableTypeId) {
        this.off = true
      }
      this.selectedItem = item
      return data
    } catch (err) {
      // nothing
    } finally {
      this.busy = false
      this.$timeout(() => this.$rootScope.$apply())
    }
  }

  remove (event) {
    event.stopImmediatePropagation()
    Object.assign(this, {
      assignedJrn: null,
      selectedItem: null,
      jrn: null
    })
    if (this.onRemove) {
      this.onRemove()
    }
  }

  resetQuery (text) {
    this.q = this.query.q = text || ''
    this._resetPaging()
    this.query.items = []
    this.query.noMoreData = false
  }

  async loadData (resetPaging) {
    if (this.query.noMoreData) {
      return
    }

    this.busy = true

    if (resetPaging) {
      this._resetPaging()
    } else {
      if (this.useStandardPaging) {
        this.query.page += 1
      } else {
        this.query.offset += this.query.limit
      }
    }

    const _query = _.omit(this.query, [
      'items',
      'noMoreData',
      'infiniteScrollLength'
    ])

    if (this.queryParams) {
        Object.assign(_query, this.queryParams)
    }

    try {
      const data = await this.api.call(this).get(_query)
      if (data) {
        this.query.noMoreData = this.LIST_LIMIT_DEFAULT > data.items.length
        this.query.items = this.query.items.concat(
          data.items.filter(this.filter).map(this.map)
        )
        this.query.infiniteScrollLength = this.query.noMoreData
          ? this.query.items.length
          : this.query.items.length + 2
      }
    } catch (err) {
      // nothing
    } finally {
      this.busy = false
      this.$timeout(() => this.$rootScope.$apply())
    }
  }

  inputFocusSet (val, $evt) {
    const extraHeight = 350
    const docEl = angular.element(document)
    let itemTemplate = docEl.find('.item-template')
    let newHeight = 0
    if (itemTemplate.length !== 0) {
      newHeight = angular.element('.item-template')[0].offsetHeight
    }
    if ($evt) {
      $evt.stopImmediatePropagation()
    }
    if (this.off) {
      return
    }
    if (this.clearOnFocus) {
      this.resetQuery()
    }
    if (val) {
      this.isFocused = val
      this.loadData(true)

      // prevent the left panel scroll when this is in focus
      docEl.find('md-sidenav').css({
        'overflow-y': 'hidden'
      })

      docEl.find('md-content').css({
        'overflow-y': 'scroll'
      })

      docEl.find('.list-display .table-container').css({
        'overflow-y': 'hidden'
      })

      if (newHeight !== 0) {
        newHeight = newHeight + extraHeight
        docEl.find('.item-template').css({
          height: newHeight + 'px'
        })
      }

    } else if (this.isFocused) {
      this.isFocused = false
      _.each(this.query.items, item => {
        item.autoPlay = false
      })
      docEl.find('md-sidenav').css({
        'overflow-y': 'auto'
      })
      docEl.find('.list-display .table-container').css({
        'overflow-y': 'auto'
      })

      if (newHeight !== 0) {
        newHeight = newHeight - extraHeight
        docEl.find('.item-template').css({
          height: newHeight + 'px'
        })
      }
    }
  }

  searchForJrns (text) {
    if (text !== this.query.q) {
      this.resetQuery(text)
    }
    this.loadData(true)
  }

  clearSearch () {
    this.resetQuery('')
    this.loadData(true)
  }

  gotoLink (event) {
    event.stopImmediatePropagation()
    this.openLink()
  }

  async createNew () {
    const newInfo = await this.onCreate()
    this.setSelectedItem(newInfo)
  }

  async initPreview (event, item) {
    event.stopImmediatePropagation()
    const url = await this.audioPlayerService.getSongUrl(item.id)
    item.fileUrl = url
    item.autoPlay = true
  }

  _resetPaging () {
    if (this.useStandardPaging) {
      this.query.page = 0
      this.query.pageSize = this.LIST_LIMIT_DEFAULT
    } else {
      this.query.offset = 0
      this.query.limit = this.LIST_LIMIT_DEFAULT
    }
  }
}
