/**
 * @ngdoc function
 * @name platformApp.factory:conferenceRoomService
 * @description
 * # conferenceRoomService
 * Controller of the platformApp
 */
import _module from 'module.js'
import ExtensionBaseES6 from 'factories/extensionBaseES6'

let _portalApi, _globalState

export default class ConferenceRoom extends ExtensionBaseES6 {
  constructor (id, conferenceRoom, gettextCatalog) {
    'ngInject'
    super(id)

    this.gettextCatalog = gettextCatalog

    if (id === undefined) {
      throw new Error(
        this.gettextCatalog.getString('Missing id for new ConferenceRoom')
      )
    }

    if (conferenceRoom) {
      this._map(conferenceRoom)
    }
  }

  getApi () {
    if (!this._api) {
      this._api = _portalApi.extension.pbxs(
        _globalState.selectedPbxUUID
      ).conferenceRooms
    }

    return this._api
  }

  getPath () {
    if (!this._path) {
      this._path = _portalApi.extension
        .pbxs(_globalState.selectedPbxUUID)
        .conferenceRooms(this._id)
    }

    return this._path
  }

  getReferencesPath () {
    if (!this._referencesPath) {
      this._referencesPath = _portalApi.extension
        .pbxs(_globalState.selectedPbxUUID)
        .conferenceRooms(this._id)
        .one('references')
    }
    return this._referencesPath
  }

  build (payload) {
    payload.extensionNumber = this.extensionNumber || null

    return payload
  }

  map (data) {
    this.extensionRegion = data.extensionRegion ? data.extensionRegion : ''
  }

  mapBatchData (data) {
    const pbxRegion = _globalState._selectedPbx.region
    data.hideConfLinks = pbxRegion !== 'US' && pbxRegion !== 'CA' && pbxRegion !== 'MX' && pbxRegion !== 'BR'
    return data
  }

  getClass () {
    return 'ConferenceRoom'
  }

  cleanListItem (ext) {
    let newConf = super.cleanListItem(ext)
    newConf.adminPassword = ext.adminPassword
    newConf.password = ext.password
    return newConf
  }

  static factory (portalApi, gettextCatalog, globalState) {
    'ngInject'
    _portalApi = portalApi
    _globalState = globalState

    return (id, conferenceRoom) => {
      return new ConferenceRoom(id, conferenceRoom, gettextCatalog)
    }
  }
}

_module.factory('ConferenceRoom', ConferenceRoom.factory)
