import _module from 'module.js'

_module.directive('phoneNumberTransferPin', function () {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, $input, attrs, ngModelCtrl) {
      ngModelCtrl.$validators.phoneNumberTransferPin = () => {
        return (
          /^[A-Za-z0-9]{4,10}$/.test($input.val()) || $input.val().length === 0
        )
      }
    }
  }
})
