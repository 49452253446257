// All UATs referenced in the PBX services test data
export default [
  {
    id: 'aastra.12i',
    range: null,
    manufacturer: 'aastra',
    model: '12i',
    fullName: 'Aastra 9112i',
    metadata: {
      metadataType: 'MinimalPhoneUserAgentTypeMetadata',
      sipAccountMetadata: {
        maxCount: 9,
        usernameLength: 30,
        passwordLength: 16
      },
      platformSupportMetadata: {
        dateOfSupportStart: '1970-01-01T00:00:00',
        dateOfSupportEnd: '2100-12-31T23:59:00',
        allowUsage: true
      },
      supportedProducts: [ 'GO_TO_CONNECT' ],
      hardwareCategory: 'DESK_PHONE',
      primaryDisplayButtonCount: 10,
      primaryDisplayButtonTypes: ['LINE']
    }
  },
  {
    id: 'aastra.31i',
    range: null,
    manufacturer: 'aastra',
    model: '31i',
    fullName: 'Aastra 6731i',
    metadata: {
      metadataType: 'MinimalPhoneUserAgentTypeMetadata',
      sipAccountMetadata: {
        maxCount: 6,
        usernameLength: 30,
        passwordLength: 16
      },
      platformSupportMetadata: {
        dateOfSupportStart: '1970-01-01T00:00:00',
        dateOfSupportEnd: '2100-12-31T23:59:00',
        allowUsage: true
      },
      supportedProducts: [ 'GO_TO_CONNECT' ],
      hardwareCategory: 'DESK_PHONE',
      primaryDisplayButtonCount: 8,
      primaryDisplayButtonTypes: ['LINE']
    }
  },
  {
    id: 'aastra.35i',
    range: null,
    manufacturer: 'aastra',
    model: '35i',
    fullName: 'Aastra 6735i',
    metadata: {
      metadataType: 'MinimalPhoneUserAgentTypeMetadata',
      sipAccountMetadata: {
        maxCount: 10,
        usernameLength: 30,
        passwordLength: 16
      },
      platformSupportMetadata: {
        dateOfSupportStart: '1970-01-01T00:00:00',
        dateOfSupportEnd: '2100-12-31T23:59:00',
        allowUsage: true
      },
      supportedProducts: [ 'GO_TO_CONNECT' ],
      hardwareCategory: 'DESK_PHONE',
      primaryDisplayButtonCount: 12,
      primaryDisplayButtonTypes: ['LINE']
    }
  },
  {
    id: 'aastra.480i',
    range: null,
    manufacturer: 'aastra',
    model: '480i',
    fullName: 'Aastra 480i',
    metadata: {
      metadataType: 'MinimalPhoneUserAgentTypeMetadata',
      sipAccountMetadata: {
        maxCount: 4,
        usernameLength: 30,
        passwordLength: 16
      },
      platformSupportMetadata: {
        dateOfSupportStart: '1970-01-01T00:00:00',
        dateOfSupportEnd: '2100-12-31T23:59:00',
        allowUsage: true
      },
      supportedProducts: [ 'GO_TO_CONNECT' ],
      hardwareCategory: 'DESK_PHONE',
      primaryDisplayButtonCount: 6,
      primaryDisplayButtonTypes: ['LINE']
    }
  },
  {
    id: 'cisco.7912',
    range: null,
    manufacturer: 'cisco',
    model: '7912',
    fullName: 'Cisco 7912',
    metadata: {
      metadataType: 'Cisco79xxUserAgentTypeMetadata',
      sipAccountMetadata: {
        maxCount: 1,
        usernameLength: 13,
        passwordLength: 16
      },
      platformSupportMetadata: {
        dateOfSupportStart: '1970-01-01T00:00:00',
        dateOfSupportEnd: '2020-08-01T00:00:00',
        allowUsage: false
      },
      supportedProducts: [ 'GO_TO_CONNECT' ],
      hardwareCategory: 'DESK_PHONE',
      primaryDisplayButtonCount: 2,
      primaryDisplayButtonTypes: ['LINE'],
      sidecarsCount: 0,
      sidecarPagesCount: 0,
      sidecarButtonsCount: 0,
      sidecarButtonTypes: []
    }
  },
  {
    id: 'cisco.7960',
    range: null,
    manufacturer: 'cisco',
    model: '7960',
    fullName: 'Cisco 7960',
    metadata: {
      metadataType: 'Cisco79xxUserAgentTypeMetadata',
      sipAccountMetadata: {
        maxCount: 2,
        usernameLength: 30,
        passwordLength: 16
      },
      platformSupportMetadata: {
        dateOfSupportStart: '1970-01-01T00:00:00',
        dateOfSupportEnd: '2020-08-01T00:00:00',
        allowUsage: false
      },
      supportedProducts: [ 'GO_TO_CONNECT' ],
      hardwareCategory: 'DESK_PHONE',
      primaryDisplayButtonCount: 6,
      primaryDisplayButtonTypes: ['LINE'],
      sidecarsCount: 0,
      sidecarPagesCount: 0,
      sidecarButtonsCount: 0,
      sidecarButtonTypes: []
    }
  },
  {
    id: 'cisco.7965',
    range: null,
    manufacturer: 'cisco',
    model: '7965',
    fullName: 'Cisco 7965',
    metadata: {
      metadataType: 'Cisco79xxUserAgentTypeMetadata',
      sipAccountMetadata: {
        maxCount: 6,
        usernameLength: 30,
        passwordLength: 16
      },
      platformSupportMetadata: {
        dateOfSupportStart: '1970-07-04T04:51:43',
        dateOfSupportEnd: '2020-08-01T00:00:00',
        allowUsage: false
      },
      supportedProducts: [ 'GO_TO_CONNECT' ],
      hardwareCategory: 'DESK_PHONE',
      primaryDisplayButtonCount: 6,
      primaryDisplayButtonTypes: [
        'LINE',
        'LINE_SILENT',
        'SPEED_DIAL',
        'MONITORED_EXTENSION'
      ],
      sidecarsCount: 2,
      sidecarPagesCount: 2,
      sidecarButtonsCount: 24,
      sidecarButtonTypes: [
        'LINE',
        'LINE_SILENT',
        'SPEED_DIAL',
        'MONITORED_EXTENSION'
      ]
    }
  },
  {
    id: 'cisco.7971',
    range: null,
    manufacturer: 'cisco',
    model: '7971',
    fullName: 'Cisco 7971',
    metadata: {
      metadataType: 'Cisco79xxUserAgentTypeMetadata',
      sipAccountMetadata: {
        maxCount: 8,
        usernameLength: 30,
        passwordLength: 16
      },
      platformSupportMetadata: {
        dateOfSupportStart: '1970-01-01T00:00:00',
        dateOfSupportEnd: '2020-08-01T00:00:00',
        allowUsage: false
      },
      supportedProducts: [ 'GO_TO_CONNECT' ],
      hardwareCategory: 'DESK_PHONE',
      primaryDisplayButtonCount: 8,
      primaryDisplayButtonTypes: [
        'LINE',
        'LINE_SILENT',
        'SPEED_DIAL',
        'MONITORED_EXTENSION'
      ],
      sidecarsCount: 0,
      sidecarPagesCount: 0,
      sidecarButtonsCount: 0,
      sidecarButtonTypes: []
    }
  },
  {
    id: 'cisco.7975',
    range: null,
    manufacturer: 'cisco',
    model: '7975',
    fullName: 'Cisco 7975',
    metadata: {
      metadataType: 'Cisco79xxUserAgentTypeMetadata',
      sipAccountMetadata: {
        maxCount: 8,
        usernameLength: 30,
        passwordLength: 16
      },
      platformSupportMetadata: {
        dateOfSupportStart: '1970-01-01T00:00:00',
        dateOfSupportEnd: '2020-08-01T00:00:00',
        allowUsage: false
      },
      supportedProducts: [ 'GO_TO_CONNECT' ],
      hardwareCategory: 'DESK_PHONE',
      primaryDisplayButtonCount: 8,
      primaryDisplayButtonTypes: [
        'LINE',
        'LINE_SILENT',
        'SPEED_DIAL',
        'MONITORED_EXTENSION'
      ],
      sidecarsCount: 2,
      sidecarPagesCount: 2,
      sidecarButtonsCount: 24,
      sidecarButtonTypes: [
        'LINE',
        'LINE_SILENT',
        'SPEED_DIAL',
        'MONITORED_EXTENSION'
      ]
    }
  },
  {
    id: 'grandstream.gxp.2200',
    range: 'grandstream_gxp',
    manufacturer: 'grandstream',
    model: 'GXP2200',
    fullName: 'Grandstream GXP2200',
    metadata: {
      metadataType: 'GrandstreamUserAgentTypeMetadata',
      sipAccountMetadata: {
        maxCount: 6,
        usernameLength: 30,
        passwordLength: 16
      },
      supportedProducts: [ 'GO_TO_CONNECT' ],
      hardwareCategory: 'DESK_PHONE',
      primaryDisplayButtonCount: 6,
      primaryDisplayButtonTypes: ['LINE', 'SPEED_DIAL', 'MONITORED_EXTENSION'],
      sidecarsCount: 4,
      sidecarPagesCount: 1,
      sidecarButtonsCount: 24,
      sidecarButtonTypes: ['LINE', 'SPEED_DIAL', 'MONITORED_EXTENSION']
    }
  },
  {
    id: 'intelbras.tip.100',
    range: 'intelbras_tip',
    manufacturer: 'intelbras',
    model: 'TIP 100',
    fullName: 'Intelbras TIP 100',
    metadata: {
      metadataType: 'MinimalPhoneUserAgentTypeMetadata',
      sipAccountMetadata: {
        maxCount: 1,
        usernameLength: 13,
        passwordLength: 13
      },
      supportedProducts: [ 'GO_TO_CONNECT' ],
      hardwareCategory: 'DESK_PHONE',
      primaryDisplayButtonCount: 1,
      primaryDisplayButtonTypes: ['LINE']
    }
  },
  {
    id: 'linksys.spa.525g',
    range: 'cisco_spa',
    manufacturer: 'linksys',
    model: 'SPA 525G',
    fullName: 'Cisco SPA 525G/G2',
    metadata: {
      metadataType: 'CiscoUserAgentTypeMetadata',
      sipAccountMetadata: {
        maxCount: 5,
        usernameLength: 30,
        passwordLength: 16
      },
      supportedProducts: [ 'GO_TO_CONNECT' ],
      hardwareCategory: 'DESK_PHONE',
      primaryDisplayButtonCount: 5,
      primaryDisplayButtonTypes: [
        'LINE',
        'LINE_SILENT',
        'SPEED_DIAL',
        'MONITORED_EXTENSION',
        'ON_PHONE_DEFINED'
      ],
      sidecarsCount: 2,
      sidecarPagesCount: 1,
      sidecarButtonsCount: 32,
      sidecarButtonTypes: [
        'SPEED_DIAL',
        'MONITORED_EXTENSION',
        'ON_PHONE_DEFINED'
      ],
      multicastGroupsCount: 25
    }
  },
  {
    id: 'mobility.android.jive.phone',
    range: null,
    manufacturer: 'jive',
    model: 'Jive Mobile (Android Phone)',
    fullName: 'Jive Mobile (Android Phone)',
    metadata: {
      metadataType: 'MinimalUserAgentTypeMetadata',
      sipAccountMetadata: {
        maxCount: 32,
        usernameLength: 30,
        passwordLength: 16
      }
    }
  },
  {
    id: 'mobility.android.jive.tablet',
    range: null,
    manufacturer: 'jive',
    model: 'Jive Mobile (Android Tablet)',
    fullName: 'Jive Mobile (Android Tablet)',
    metadata: {
      metadataType: 'MinimalUserAgentTypeMetadata',
      sipAccountMetadata: {
        maxCount: 32,
        usernameLength: 30,
        passwordLength: 16
      }
    }
  },
  {
    id: 'mobility.ios.jive.phone',
    range: null,
    manufacturer: 'jive',
    model: 'Jive Mobile (iPhone)',
    fullName: 'Jive Mobile (iPhone)',
    metadata: {
      metadataType: 'MinimalUserAgentTypeMetadata',
      sipAccountMetadata: {
        maxCount: 32,
        usernameLength: 30,
        passwordLength: 16
      }
    }
  },
  {
    id: 'mobility.ios.jive.tablet',
    range: null,
    manufacturer: 'jive',
    model: 'Jive Mobile (iOS Tablet)',
    fullName: 'Jive Mobile (iOS Tablet)',
    metadata: {
      metadataType: 'MinimalUserAgentTypeMetadata',
      sipAccountMetadata: {
        maxCount: 32,
        usernameLength: 30,
        passwordLength: 16
      }
    }
  },
  {
    id: 'mobility.web.jive.rtc',
    range: null,
    manufacturer: 'jive',
    model: 'Jive Web',
    fullName: 'Jive Web',
    metadata: {
      metadataType: 'MinimalUserAgentTypeMetadata',
      sipAccountMetadata: {
        maxCount: 32,
        usernameLength: 30,
        passwordLength: 16
      }
    }
  },
  {
    id: 'panasonic.kx.ut248b',
    range: 'panasonic_ut',
    manufacturer: 'panasonic',
    model: 'KX-UT248',
    fullName: 'Panasonic KX-UT248',
    metadata: {
      metadataType: 'PanasonicUserAgentTypeMetadata',
      sipAccountMetadata: {
        maxCount: 6,
        usernameLength: 30,
        passwordLength: 16
      },
      supportedProducts: [ 'GO_TO_CONNECT' ],
      hardwareCategory: 'DESK_PHONE',
      primaryDisplayButtonCount: 24,
      primaryDisplayButtonTypes: [
        'LINE',
        'LINE_SILENT',
        'SPEED_DIAL',
        'MONITORED_EXTENSION'
      ],
      sidecarsCount: 0,
      sidecarPagesCount: 0,
      sidecarButtonsCount: 0,
      sidecarButtonTypes: [],
      multicastGroupsCount: 0
    }
  },
  {
    id: 'panasonic.kx.utg300b',
    range: 'panasonic_utg',
    manufacturer: 'panasonic',
    model: 'KX-UTG300b',
    fullName: 'Panasonic KX-UTG300b',
    metadata: {
      metadataType: 'PanasonicUserAgentTypeMetadata',
      sipAccountMetadata: {
        maxCount: 6,
        usernameLength: 30,
        passwordLength: 16
      },
      supportedProducts: [ 'GO_TO_CONNECT' ],
      hardwareCategory: 'DESK_PHONE',
      primaryDisplayButtonCount: 24,
      primaryDisplayButtonTypes: [
        'LINE',
        'SPEED_DIAL',
        'MONITORED_EXTENSION',
        'ON_PHONE_DEFINED'
      ],
      sidecarsCount: 3,
      sidecarPagesCount: 1,
      sidecarButtonsCount: 36,
      sidecarButtonTypes: [
        'LINE',
        'SPEED_DIAL',
        'MONITORED_EXTENSION',
        'ON_PHONE_DEFINED'
      ],
      multicastGroupsCount: 0
    }
  },
  {
    id: 'polycom.soundpointip.335',
    range: 'polycom_spip',
    manufacturer: 'polycom',
    model: 'SoundPointIP 335',
    fullName: 'Polycom SoundPointIP 335',
    metadata: {
      metadataType: 'PolycomUserAgentTypeMetadata',
      sipAccountMetadata: {
        maxCount: 2,
        usernameLength: 30,
        passwordLength: 16
      },
      supportedProducts: [ 'GO_TO_CONNECT' ],
      hardwareCategory: 'DESK_PHONE',
      primaryDisplayButtonCount: 2,
      primaryDisplayButtonTypes: ['LINE'],
      sidecarsCount: 0,
      sidecarPagesCount: 0,
      sidecarButtonsCount: 0,
      sidecarButtonTypes: [],
      multicastGroupsCount: 10,
      videoCapable: false,
      speedDialStart: 10
    }
  },
  {
    id: 'polycom.vvx.600',
    range: 'polycom_vvx',
    manufacturer: 'polycom',
    model: 'VVX 600',
    fullName: 'Polycom VVX 600',
    metadata: {
      metadataType: 'PolycomUserAgentTypeMetadata',
      sipAccountMetadata: {
        maxCount: 34,
        usernameLength: 30,
        passwordLength: 16
      },
      supportedProducts: [ 'GO_TO_CONNECT' ],
      hardwareCategory: 'DESK_PHONE',
      primaryDisplayButtonCount: 16,
      primaryDisplayButtonTypes: [
        'LINE',
        'LINE_SILENT',
        'SPEED_DIAL',
        'MONITORED_EXTENSION',
        'BUDDY_WATCH',
        'ON_PHONE_DEFINED'
      ],
      sidecarsCount: 3,
      sidecarPagesCount: 3,
      sidecarButtonsCount: 84,
      sidecarButtonTypes: [
        'LINE',
        'LINE_SILENT',
        'SPEED_DIAL',
        'MONITORED_EXTENSION',
        'BUDDY_WATCH',
        'ON_PHONE_DEFINED'
      ],
      multicastGroupsCount: 10,
      videoCapable: true,
      speedDialStart: 50
    }
  },
  {
    id: 'snom.760',
    range: null,
    manufacturer: 'snom',
    model: '760',
    fullName: 'SNOM 760',
    metadata: {
      metadataType: 'SnomUserAgentTypeMetadata',
      sipAccountMetadata: {
        maxCount: 1,
        usernameLength: 30,
        passwordLength: 16
      },
      supportedProducts: [ 'GO_TO_CONNECT' ],
      hardwareCategory: 'DESK_PHONE',
      primaryDisplayButtonCount: 12,
      primaryDisplayButtonTypes: ['LINE'],
      multicastGroupsCount: 10
    }
  },
  {
    id: 'vtech.vsp600',
    range: 'vtech_vsp',
    manufacturer: 'vtech',
    model: 'VSP600',
    fullName: 'Vtech VSP600',
    metadata: {
      metadataType: 'MinimalPhoneUserAgentTypeMetadata',
      sipAccountMetadata: {
        maxCount: 5,
        usernameLength: 30,
        passwordLength: 16
      },
      supportedProducts: [ 'GO_TO_CONNECT' ],
      hardwareCategory: 'DESK_PHONE',
      primaryDisplayButtonCount: 6,
      primaryDisplayButtonTypes: ['LINE']
    }
  },
  {
    id: 'vtech.vsp735',
    range: 'vtech_vsp',
    manufacturer: 'vtech',
    model: 'VSP735',
    fullName: 'Vtech VSP735',
    metadata: {
      metadataType: 'MinimalPhoneUserAgentTypeMetadata',
      sipAccountMetadata: {
        maxCount: 5,
        usernameLength: 30,
        passwordLength: 16
      },
      supportedProducts: [ 'GO_TO_CONNECT' ],
      hardwareCategory: 'DESK_PHONE',
      primaryDisplayButtonCount: 16,
      primaryDisplayButtonTypes: ['LINE', 'SPEED_DIAL', 'MONITORED_EXTENSION']
    }
  },
  {
    id: 'yealink.sip.t46g',
    range: 'yealink_t2x_t4x',
    manufacturer: 'yealink',
    model: 'SIP-T46G',
    fullName: 'Yealink SIP-T46G',
    metadata: {
      metadataType: 'YealinkUserAgentTypeMetadata',
      sipAccountMetadata: {
        maxCount: 16,
        usernameLength: 30,
        passwordLength: 16
      },
      supportedProducts: [ 'GO_TO_CONNECT' ],
      hardwareCategory: 'DESK_PHONE',
      primaryDisplayButtonCount: 27,
      primaryDisplayButtonTypes: [
        'LINE',
        'SPEED_DIAL',
        'MONITORED_EXTENSION',
        'MCAST',
        'ON_PHONE_DEFINED'
      ],
      sidecarsCount: 6,
      sidecarPagesCount: 2,
      sidecarButtonsCount: 40,
      sidecarButtonTypes: [
        'LINE',
        'SPEED_DIAL',
        'MONITORED_EXTENSION',
        'MCAST',
        'ON_PHONE_DEFINED'
      ],
      multicastGroupsCount: 31,
      memoryKeyButtonCount: 0,
      memoryKeyButtonTypes: [],
      programmableKeyButtonCount: 13,
      programmableKeyButtonTypes: []
    }
  }
]
