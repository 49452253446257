import _ from 'lodash'
import _module from 'module.js'
import ListControllerBase from 'classes/listController.class'

export default class PagingRealmsController extends ListControllerBase {
  constructor (
    $state,
    $stateParams,
    $location,
    $analytics,
    PagingRealm,
    gettextCatalog,
    infiniteLoader,
    navigation,
    globalState,
    portalUtil
  ) {
    'ngInject'
    super(
      gettextCatalog,
      $analytics,
      $location,
      portalUtil,
      globalState,
      navigation,
      $state,
      $stateParams
    )

    this.gettextCatalog = gettextCatalog

    this.title = this.gettextCatalog.getString('Paging Realms')
    this.headers = [
      {
        width: 25,
        name: this.gettextCatalog.getString('Name'),
        sortBy: 'name',
        asc: true
      },
      {
        width: 65,
        name: ''
      },
      {
        width: 10,
        name: ''
      }
    ]
    this.buttonText = this.gettextCatalog.getString('Add Realm')
    this.loader = infiniteLoader(PagingRealm)
  }

  delete (event, item) {
    let name = this.gettextCatalog.getString('realm') + ': ' + item.name
    this.loader.entityApi.startDelete(event, item, this.data, name)
  }

  itemSelected (item) {
    super.itemSelected(
      item,
      'root.nav.pbx.devices.realms.view.general',
      'realmId'
    )
  }
}

_module.controller('PagingRealmsController', PagingRealmsController)
