import _module from 'module.js'
import _ from 'lodash'
import jrnParser from 'classes/jrnParser'

export default class PortalUtilService {
  constructor (
    gettextCatalog,
    $state,
    $stateParams,
    globalState,
    portalApi,
    flashDetectUtil,
    $window,
    confirmationWindowOK,
    FileSaver,
    authService
  ) {
    'ngInject'
    this.gettextCatalog = gettextCatalog
    this.$state = $state
    this.$stateParams = $stateParams
    this.jrn = jrnParser
    this.globalState = globalState
    this.portalApi = portalApi
    this.$window = $window
    this.flashDetectUtil = flashDetectUtil
    this.confirmationWindowOK = confirmationWindowOK
    this.FileSaver = FileSaver
    this.authService = authService

    this.stateNamePair = {
      'root.nav.pbx.users.list': this.gettextCatalog.getString('User'),
      'root.nav.pbx.groups.list': this.gettextCatalog.getString('Group'),
      'root.nav.pbx.dids.list': this.gettextCatalog.getString('Phone Number'),
      'root.nav.pbx.devices.list': this.gettextCatalog.getString('Device'),
      'root.nav.pbx.devices.hardware.list': this.gettextCatalog.getString(
        'Hardware'
      ),
      'root.nav.pbx.devices.profiles.list': this.gettextCatalog.getString(
        'Paging Profile'
      ),
      'root.nav.pbx.devices.realms.list': this.gettextCatalog.getString(
        'Paging Realm'
      ),
      'root.nav.pbx.extensions.list': this.gettextCatalog.getString(
        'Extension'
      ),
      'root.nav.pbx.extensions.lines.list': this.gettextCatalog.getString(
        'Line'
      ),
      'root.nav.pbx.extensions.ringgroups.list': this.gettextCatalog.getString(
        'Ring Group'
      ),
      'root.nav.pbx.extensions.conferencerooms.list': this.gettextCatalog.getString(
        'Conference Bridge'
      ),
      'root.nav.pbx.extensions.callqueues.list': this.gettextCatalog.getString(
        'Call Queue'
      ),
      'root.nav.pbx.extensions.dialplans.list': this.gettextCatalog.getString(
        'Dial Plan'
      ),
      'root.nav.pbx.extensions.voicemailboxes.list': this.gettextCatalog.getString(
        'Shared Voicemail Box'
      ),
      'root.nav.pbx.extensions.virtualfaxmachines.list': this.gettextCatalog.getString(
        'Virtual Fax Machine'
      ),
      'root.nav.pbx.holdmusic.list': this.gettextCatalog.getString(
        'Hold Music'
      ),
      'root.nav.pbx.holdmusic.view': this.gettextCatalog.getString(
        'Hold Music'
      ),
      'root.nav.pbx.directories.list': this.gettextCatalog.getString(
        'Dial By Name Directory'
      ),
      'root.nav.pbx.corp-directories.list': this.gettextCatalog.getString(
        'Corporate Directory'
      ),
      'root.nav.pbx.schedules.list': this.gettextCatalog.getString('Schedule'),
      'root.nav.pbx.soundclips.list': this.gettextCatalog.getString(
        'Sound Clip'
      ),
      'root.nav.pbx.callfilters.list': this.gettextCatalog.getString(
        'Call Filter'
      ),
      'root.nav.pbx.deviceStatus.list': this.gettextCatalog.getString(
        'Device Status'
      ),
      'root.nav.pbx.unavailableLines.list': this.gettextCatalog.getString(
        'Unavailable Lines'
      )
    }
  }

  getNameFromUserOrGroup (target) {
    let name = target.userGroupName
    if (target.userLastName) {
      name = target.userLastName
      if (target.userFirstName) {
        name = target.userFirstName + ' ' + name
      }
    }
    if (!target.identity) {
      name = this.gettextCatalog.getString('Everyone')
    }
    return name
  }

  setStateI18N (dest, src) {
    _.assignIn(dest, _.cloneDeep(src))
    dest.title = this.gettextCatalog.getString(dest.title)

    _.forEach(dest.tabs, tab => {
      tab.label = this.gettextCatalog.getString(tab.label)
    })

    _.forEach(dest.hiddenTab, tab => {
      tab.label = this.gettextCatalog.getString(tab.label)
    })
  }

  showErrorAlert (message) {
    return this.showAlert(this.gettextCatalog.getString('Error'), message, true)
  }

  showAlert (title, message, isNoCancel) {
    return this.confirmationWindowOK.show(title, message, isNoCancel)
  }

  resolveExtensionOrDirectoryPath (ext) {
    let resolvedPath
    let resourceType = this.jrn.parse(ext.jrn).getResources()[0]
    let params = { extId: this.jrn.parse(ext.jrn).getResources()[1] }
    switch (resourceType) {
      case 'conference-room':
        resolvedPath = this.$state.href(
          'root.nav.pbx.extensions.conferencerooms.view.general',
          params
        )
        break
      case 'ring-group':
        resolvedPath = this.$state.href(
          'root.nav.pbx.extensions.ringgroups.view.general',
          params
        )
        break
      case 'directory':
        resolvedPath = this.$state.href(
          'root.nav.pbx.directories.view.general',
          { directoryId: this.jrn.parse(ext.jrn).getResources()[1] }
        )
        break
      case 'line':
        resolvedPath = this.$state.href(
          'root.nav.pbx.extensions.lines.view.general',
          params
        )
        break
      case 'call-queue':
        resolvedPath = this.$state.href(
          'root.nav.pbx.extensions.callqueues.view.general',
          params
        )
        break
      case 'virtual-fax-machine':
        resolvedPath = this.$state.href(
          'root.nav.pbx.extensions.virtualfaxmachines.view.general',
          params
        )
        break
      case 'shared-voicemail-box':
        resolvedPath = this.$state.href(
          'root.nav.pbx.extensions.voicemailboxes.view.general',
          params
        )
        break
      case 'line':
        resolvedPath = this.$state.href(
          'root.nav.pbx.extensions.lines.view.general',
          params
        )
        break
      case 'dial-plan':
        resolvedPath = this.createDialPlanUrl(
          ext.jrn,
          ext.name,
          ext.extensionNumber
        )
        break
      default:
      // do nothing;
    }
    return resolvedPath
  }

  createDialPlanUrl (dialPlanJrn, dialplanName, extensionNumber) {
    if (extensionNumber) {
      dialplanName = extensionNumber + ': ' + dialplanName
    }

    dialplanName = encodeURIComponent(dialplanName)

    var queryParams = [
      'lang=' + this.getLanguageParamForDialPlanEditor(),
      'dialPlanId=' + this.jrn.parse(dialPlanJrn).getResources()[1],
      'pbx=' + this.globalState.selectedPbxUUID,
      'dialplanName=' + dialplanName
    ]

    return '/pbx/dpe2/?' + queryParams.join('&')
  }

  openDialPlanWindow (dialPlan) {
    let url = this.createDialPlanUrl(
      dialPlan.jrn,
      dialPlan.name,
      dialPlan.extensionNumber
    )
    this.$window.open(url, '_blank').focus()
  }

  getLanguageParamForDialPlanEditor () {
    if (this.globalState.currentLanguage.langCode === 'es-MX') return 'es-AM'

    return this.globalState.currentLanguage.langCode
  }

  resolveIdentityPath (target) {
    let resolvedPath
    let resourceType = this.jrn.parse(target.identity).getResources()[0]
    let resourceId = this.jrn.parse(target.identity).getResources()[1]
    switch (resourceType) {
      case 'user':
        resolvedPath = this.$state.href('root.nav.pbx.users.view.general', {
          userId: resourceId
        })
        break
      case 'user-group':
        resolvedPath = this.$state.href('root.nav.pbx.groups.view.general', {
          groupId: resourceId
        })
        break
      default:
      // do nothing
    }
    return resolvedPath
  }

  validateAreaCodeOnRegionChange (areaCode, region) {
    if (areaCode && areaCode.length) {
      let thisRegion
      if (region === '') {
        thisRegion = _.find(this.globalState.supportedRegions, {
          code: this.globalState.selectedPbx.region
        })
      } else {
        thisRegion = _.find(this.globalState.supportedRegions, { code: region })
      }
      let re = new RegExp(thisRegion.areaCodePattern)
      return re.test(areaCode)
    } else {
      return false
    }
  }

  regionHasAreaCodes(regionCode) {
    const region = _.find(this.globalState.supportedRegions, { code: regionCode || this.globalState.selectedPbx.region })

    return !!region && !!region.areaCodePattern
  }

  getListSource (defaultListState) {
    let listSource = this.globalState.listSource
    let listState = listSource.stateName
      ? listSource.stateName
      : defaultListState
    let listStateParams = listSource.stateParams ? listSource.stateParams : {}
    listStateParams.source = listState

    let previousListURL = this.$state.href(listState, listStateParams)
    let starting = 'index.htm'
    let startingIndex = previousListURL.indexOf(starting)
    if (startingIndex != -1) {
      previousListURL = previousListURL.substring(
        startingIndex + starting.length + 1
      )
    }

    let displayName = this.gettextCatalog.getString(
      'Return to {{listName}} List',
      {
        listName: this.stateNamePair[listState]
          ? this.gettextCatalog.getString(this.stateNamePair[listState])
          : ''
      }
    )
    if (
      listState === 'root.nav.pbx.deviceStatus.list' ||
      listState === 'root.nav.pbx.unavailableLines.list'
    ) {
      displayName = this.gettextCatalog.getString('Return to {{listName}}', {
        listName: this.stateNamePair[listState]
          ? this.gettextCatalog.getString(this.stateNamePair[listState])
          : ''
      })
    }

    return {
      url: previousListURL,
      state: listState,
      displayListName: displayName
    }
  }

  downloadContactDirectory () {
    let portalUtil = this
    let fileName =
      this.gettextCatalog.getString('Organization Directory') + '.pdf'
    this.authService.getAuthInfo().then(authInfo => {
      let authHeader = authInfo.type + ' ' + authInfo.token

      let xhr = new XMLHttpRequest()
      xhr.onload = function (e) {
        if (this.status == 200) {
          let data = new Blob([this.response], { type: 'application/pdf' })
          portalUtil.FileSaver.saveAs(data, fileName)
        }
      }
      xhr.open(
        'GET',
        this.portalApi.tool
          .pbxs(this.globalState.selectedPbxUUID)
          .directoryPdf()
          .getRestangularUrl(),
        true
      )
      xhr.setRequestHeader('Authorization', authHeader)
      xhr.responseType = 'blob'

      xhr.send()
    })
  }
}

_module.service('portalUtil', PortalUtilService)
