import _module from 'module.js'

export default class didSMSController {
  constructor (User, didData, globalState, pbxUtil, unleashService) {
    'ngInject'
    this.User = User
    this.data = didData
    this.unleashService = unleashService;
    this.mmsEnabled = globalState._selectedPbx.featureFlags['messaging.bandwidth.protocol.http']
    this.isDidDeletionEnabled = globalState._selectedPbx.featureFlags['pbx.did.deletion']
    this.isOutboundCallerIdEnabled = globalState._selectedPbx.featureFlags['pbx.did.multi-callerid']

    this.chatQueuesEnabled = globalState._selectedPbx.chatQueuesEnabled

    this.goToAdminSmsCampaignUrl = pbxUtil.getGoToAdminUrl(globalState._selectedPbx) + '/sms-campaign'
    this.smsCampaignInformations = globalState.smsCampaignInformations

    this.useChatQueue = !!this.data.chatQueueId && this.chatQueuesEnabled;
    this.active = null
    this.targets = this.data.didPermissions
    this.targets.forEach(item => {
      if (item.active) {
        this.setAsActive(item)
        this.active = item
      }
    })

    if (!this.active && this.targets[0]) {
      this.targets[0].active = true
      this.setAsActive(this.targets[0])
    }

    let userApi = new User(null)
    let memberQuery = () => {
      return {
        get: params => {
          params = params || null
          return userApi.getList(params).then(data => {
            return {
              items: data
            }
          })
        }
      }
    }

    this.data.getMembers = {
      api: memberQuery,
      filter: member => {
        for (var i = this.targets.length - 1; i >= 0; i--) {
          if (this.targets[i].id === member.id) {
            return false
          }
        }
        return true
      },
      map: function (member) {
        return member
      }
    }
  }

  setAsActive (item) {
    this.active = item || null
    if (item) {
      item.active = true
    }
    this.updateDidData()
  }

  addItem (list, item) {
    list.forEach(i => {
      i.active = false
    })
    list.push(item)
    this.setAsActive(item)
  }

  resetActive (item, list) {
    if (item.active) {
      this.setAsActive(list[0])
    }
  }

  updateDidData() {
    this.data.didPermissions = this.targets
  }

  toggleChatQueue() {
    if(!this.useChatQueue) {
      this.data.chatQueueId = null;
      this.chatQueueForm.$setDirty(true);
    }
  }
 }

_module.controller('didSMSController', didSMSController)
