import _module from 'module.js'
import { UserConfigurationService } from '@jive/core/user'

export default class UserConfigCheck {
  static factory ($location) {
    'ngInject'
    // in terms of error handling, there are only two envs, prod and staging

    let env = 'staging'
    const host = $location.host()

    if (host === 'my.jive.com' || host === 'my.hvoice.net') {
      env = 'prod'
    }

    return new UserConfigurationService(env)
  }
}

_module.factory('userConfigCheck', UserConfigCheck.factory)
