import getExtensionModelTemplate from './getExtensionModelTemplate.html'
import getExtensionModelController from './getExtensionModelController.js'
import module from 'module'

module.component('getExtensionModel', {
  template: getExtensionModelTemplate,
  controllerAs: 'getExtensionModel',
  bindings: {
    extJrn: '<',
    displayString: '=',
    extensionType: '@?',
    sharedAppearance: '=?'
  },
  require: {
    ngModel: 'ngModel',
    filterGetExtension: '^?filterGetExtension',
    form: '^form'
  },
  controller: getExtensionModelController
})
