import _module from 'module.js'

_module.directive('jbcPstnGateway', function () {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, $input, attrs, ngModelCtrl) {
      ngModelCtrl.$validators.jbcPstnGateway = () => {
        return (
          /^sip:[a-zA-Z0-9.]+:[0-9]{1,5}$/.test($input.val()) ||
          $input.val().length === 0
        )
      }
    }
  }
})
