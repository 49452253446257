/**
 * TMP switch mecanism.
 * TODO: Have a unified & dynamic auth mecanism
 */

if (window.environment === 'local') {
  require('./configureForLocal')
} else {
  require('./configureForProd')
}
