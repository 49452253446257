import _module from 'module.js'
import _ from 'lodash'
import jrnParser from 'classes/jrnParser'

export default class ZohoMappingAuthService {
  constructor (integrationsService) {
    'ngInject'
    this.integrationsService = integrationsService
  }

  resolve () {
    return this.integrationsService.getUserProviders(jrnParser)
  }

  getClass () {
    return 'ZohoMappingAuthService'
  }
}
_module.service('zohoMappingAuthService', ZohoMappingAuthService)
