import _module from 'module.js'
import _ from 'lodash'
import jrnParser from 'classes/jrnParser'

import ExtensionController from 'classes/extensionController'
import { isVoicemailTranscriptionLangSupported } from 'classes/voicemailTranscriptionsUtils'

export default class LineController extends ExtensionController {
  constructor (
    $state,
    $stateParams,
    portalApi,
    Line,
    userService,
    gettextCatalog,
    portalUtil,
    errorService,
    globalState,
    navigation,
    voicemailGreeting,
    confirmationWindowWarn,
    featuresService,
    FEATURE_FLAGS,
    portalConfig,
    $rootScope,
    $timeout,
    Did
  ) {
    'ngInject'
    super(
      $state,
      $stateParams,
      Line,
      portalApi,
      userService,
      gettextCatalog,
      portalUtil,
      errorService,
      globalState,
      navigation,
      'root.nav.pbx.extensions.lines.list',
      'extId'
    )

    this.voicemailTranscriptionsLink = `https://support.goto.com/connect/help/how-do-i-enable-voicemail-transcription`
    this.$timeout = $timeout
    this.$rootScope = $rootScope

    this.voicemailGreeting = voicemailGreeting
    this.featuresService = featuresService
    this.FEATURE_FLAGS = FEATURE_FLAGS
    this.confirmationWindowWarn = confirmationWindowWarn
    this.Did = Did
    this.defaultPbxLanguage = this.globalState.selectedPbxLanguage

    this.GDPREnabled = this.globalState._selectedPbx.featureFlags['pbx.gdpr']

    this.sharedLineIcon = portalConfig.sharedLineIcon
    this.intercomOptions = {
      radios: [
        {
          label: this.gettextCatalog.getString('Enabled'),
          value: true
        },
        {
          label: this.gettextCatalog.getString('Disabled'),
          value: false
        }
      ]
    }

    this.voicemailgreetingtypes = this.voicemailGreeting.greetings
    this.voiceGreetingApis = {
      tts: this.voicemailGreeting.uploadLineTTSGreeting.bind(
        this.voicemailGreeting
      ),
      upload: this.voicemailGreeting.uploadLineGreeting.bind(
        this.voicemailGreeting
      ),
      del: this.voicemailGreeting.getLineGreetingApi().one('vmgreeting'),
      play: this.voicemailGreeting.getLineGreetingApi().one('vmgreeting-url')
    }

    this.faxOptions = {
      radios: [
        {
          label: this.gettextCatalog.getString("Send to Assigned User's Email"),
          value: true
        },
        {
          label: this.gettextCatalog.getString(
            'Send Faxes to Another Address:'
          ),
          value: false
        }
      ]
    }

    this.emailAddressOptions = {
      radios: [
        {
          label: this.gettextCatalog.getString("Use assigned user's email"),
          value: true
        },
        {
          label: this.gettextCatalog.getString('Use another address:'),
          value: false
        }
      ]
    }

    const hasDifferentJrn = item => this.data.jrn !== item.jrn
    const mapExtensionItem = item => {
      const number = item.extensionNumber ? `${item.extensionNumber}: ` : ''
      return {
        id: item.jrn,
        text: number + item.name
      }
    }

    this.getExtensions = {
      api: this.portalApi.extension.pbxs(this.globalState.selectedPbxUUID)
        .extensions,
      filter: hasDifferentJrn,
      map: mapExtensionItem
    }

    this.getSharedVoicemailBoxes = {
      api: this.portalApi.extension.pbxs(this.globalState.selectedPbxUUID)
        .sharedVoicemailBoxes,
      filter: hasDifferentJrn,
      map: mapExtensionItem
    }

    this.sharedAppearanceEnabled = false
    this.featuresService
      .getContextFeature(
        this.globalState.selectedPbxUUID,
        this.FEATURE_FLAGS.sharedAppearance
      )
      .then(contextFeature => {
        this.sharedAppearanceEnabled = contextFeature['setting'] === 'ENABLED'
      })

    this.canOpenHoldMusicLink = !this.globalState.isUserManageSettingsOnly
    this.canOpenDidLink = !this.globalState.isUserManageSettingsOnly
    this.canClearVoiceMail = !this.globalState.isUserManageSettingsOnly

    this.pbxRegionHasAreaCodes = this.portalUtil.regionHasAreaCodes(this.globalState.selectedPbx.region)

    this.load()
  }

  async load () {
    try {
      const data = await this.data.get()

      await this.handleCallerIdWarnings()

      this.itemName = data.extensionNumber
        ? data.extensionNumber + ': ' + data.name
        : data.name
      this.voicemailgreetingtypes = this.voicemailGreeting.assignGreetingValues(
        this.voicemailgreetingtypes,
        data
      )
      this.initExtensionNumber = this.data.extensionNumber      
      this.shouldShowAreaCodeField = this.portalUtil.regionHasAreaCodes(this.data.extensionRegion)
    } catch (err) {
      const errorMessage = this.errorService.getErrorMessage(err)
      this.portalUtil.showErrorAlert(
        _.isEmpty(errorMessage)
          ? this.gettextCatalog.getString(
              'Data retrieval failed, please retry.'
            )
          : errorMessage
      )
      throw err
    } finally {
      this.ready = true
      this.initial = false
      this.$timeout(() => this.$rootScope.$apply())
    }
  }

  async handleCallerIdWarnings () {
    this.showPbxCallerIdWarning = false
    this.showLineCallerIdWarning = false

    let outboundDid
    if (!this.data.usePbxOutboundDid) {
      outboundDid = this.data.outboundDid
    } else {
      outboundDid = this.globalState.selectedPbx.defaultOutboundDid
    }

    if (outboundDid) {
      this.outboundDid = await new this.Did(
        jrnParser.parse(outboundDid).getResources()[1]
      ).get()
      if (!this.outboundDid.outboundRoutable && !this.data.usePbxOutboundDid) {
        this.showLineCallerIdWarning = true
      } else if (
        !this.outboundDid.outboundRoutable &&
        this.data.usePbxOutboundDid
      ) {
        this.showPbxCallerIdWarning = true
      }
    } else {
      this.showPbxCallerIdWarning = true
    }
  }

  regionChanged (form) {
    this.shouldShowAreaCodeField = this.portalUtil.regionHasAreaCodes(this.data.extensionRegion)

    if (this.shouldShowAreaCodeField) {
      if (form.localAreaCode && form.localAreaCode.$viewValue && form.localAreaCode.$viewValue.length) {
        const valid = this.portalUtil.validateAreaCodeOnRegionChange(
          form.localAreaCode.$viewValue,
          this.data.extensionRegion
        )
        form.localAreaCode.$setTouched(true)
        form.localAreaCode.$setValidity('validAreaCode', valid)
        if (valid) {
          this.data.localAreaCode = form.localAreaCode.$viewValue
        }
      }
      if (!this.pbxRegionHasAreaCodes) {
        this.data.usePbxLocalAreaCode = false
      }
    } else {
      this.data.localAreaCode = null
      if (this.pbxRegionHasAreaCodes) {
        this.data.usePbxLocalAreaCode = false
      }
    }
  }

  async save (form) {
    this.ready = false
    try {
      await this.data.update()
      await this.handleCallerIdWarnings()
      this.itemName = this.data.extensionNumber
        ? this.data.extensionNumber + ': ' + this.data.name
        : this.data.name
      this.data.dialPlan.ringSteps.forEach(ringStep => {
        let lastItem = ringStep.ringItems[ringStep.ringItems.length - 1]
        if (lastItem.line || lastItem.externalNumber) {
          ringStep.ringItems.push({
            destinationType: 'LINE',
            line: null,
            externalNumber: ''
          })
        }
      })
      this.initExtensionNumber = this.data.extensionNumber
      form.$setPristine()
    } catch (err) {
      this.portalUtil.showErrorAlert(
        this.gettextCatalog.getString('Save failed, please retry.')
      )
    } finally {
      this.ready = true
    }
  }

  confirmClearVoicemails () {
    this.confirmationWindowWarn
      .show(
        this.gettextCatalog.getString(
          'Do you really want to clear all voicemails?'
        ),
        this.gettextCatalog.getString(
          'This action will delete all voicemails, greetings, and other data associated with this mailbox and cannot be undone.'
        ),
        this.gettextCatalog.getString('Clear')
      )
      .then(
        () => this.data.deleteCustomerData(),
        () => {}
      )
      .catch(() => {
        this.portalUtil.showErrorAlert(
          this.gettextCatalog.getString(
            'Clear Voicemails failed, please retry.'
          )
        )
      })
  }

  sharedAppearanceChanged (sharedAppearance) {
    if (sharedAppearance) {
      this.data.useStationLabelAsName = false
    }
  }

  openDialPlan (dialPlan) {
    this.portalUtil.openDialPlanWindow(dialPlan)
  }

  get displayVoicemailTranscriptionSupportedWarning () {
    return this.data
      ? !isVoicemailTranscriptionLangSupported(
          this.defaultPbxLanguage,
          this.data.language
        )
      : false
  }
}

_module.controller('LineController', LineController)
