export default class JbcStatusTooltipController {
  constructor () {
    'ngInject'

    this.status = this.status || "UNKNOWN"
    this.icon = this.convertStatus(this.status)

    if (this.subStatuses) {
      this.subStatuses = this.subStatuses.map(item => {
        let status = item.status || "UNKNOWN"
        let icon = this.convertStatus(status)

        return {
          summary: item.summary,
          icon: icon,
          status: status
        }
      })
    }
  }


  $onChanges (attributesObj) {
    if(attributesObj.status){
      this.icon = this.convertStatus(attributesObj.status.currentValue)
    }

  }

  convertStatus (status) {
    let icon = "PENDING"
    if(status === "OK" || status === "WARNING") {
      icon = "AVAILABLE"
    }
    if(status === "ERROR" || status === "UNKNOWN") {
      icon = "OFFLINE"
    }
    return icon
  }
}
