/**
 * @ngdoc directive
 * @name platformApp.directive:autoFocus
 * @description
 * # autoFocus
 */

import _module from 'module.js'
_module.directive('autoFocus', $timeout => {
  'ngInject'
  return {
    restrict: 'A',
    link: (scope, el) => {
      $timeout(() => {
        el[0].focus()
      }, 20)
    }
  }
})
