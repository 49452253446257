import _module from 'module.js'
import ListControllerBase from 'classes/listController.class'
import jrnParser from 'classes/jrnParser'

let _jrn,
  _portalApi,
  _User,
  _globalState,
  _portalUtil,
  _addDeviceForm,
  _resyncAllDevicesWarning

const getAssignedUserInfo = async device => {
  if (!device.assignedUser) {
    return
  }
  const id = _jrn.parse(device.assignedUser).getResources()[1]
  const userApi = new _User(id)
  const { firstName, lastName } = await userApi.get()
  device.assignedUserFirstName = firstName
  device.assignedUserLastName = lastName
}

export default class devicesControllerFactory extends ListControllerBase {
  constructor (
    $state,
    $stateParams,
    $location,
    $analytics,
    portalApi,
    Device,
    gettextCatalog,
    portalUtil,
    infiniteLoader,
    User,
    addDeviceForm,
    resyncAllDevicesWarning,
    DEVICE_RESYNCALL_DELAY,
    globalState,
    navigation,
    userService,
    FEATURE_FLAGS,
    addSipTrunkForm,

  ) {
    'ngInject'
    super(
      gettextCatalog,
      $analytics,
      $location,
      portalUtil,
      globalState,
      navigation,
      $state,
      $stateParams
    )

    this.gettextCatalog = gettextCatalog
    _jrn = jrnParser
    _portalApi = portalApi
    _User = User
    _globalState = globalState
    _portalUtil = portalUtil
    _addDeviceForm = addDeviceForm
    _resyncAllDevicesWarning = resyncAllDevicesWarning
    this.isSipTrunkEnabled = globalState.selectedPbx.featureFlags[FEATURE_FLAGS.sipTrunks] 
    this.userService = userService
 
    this.title = this.gettextCatalog.getString('Devices')

    this.headers = [
      {
        width: 15,
        name: this.gettextCatalog.getString('Name'),
        sortBy: 'name',
        asc: true
      },
      {
        width: 15,
        name: this.gettextCatalog.getString('Assigned User'),
        sortBy: 'user_last_name',
        asc: null
      },
      {
        width: 15,
        name: this.gettextCatalog.getString('Extensions')
      },
      {
        width: 15,
        name: this.gettextCatalog.getString('Device Model'),
        sortBy: 'device_model',
        asc: null
      },
      {
        width: 5,
        name: this.gettextCatalog.getString('Low Usage'),
        sortBy: 'low_usage',
        asc: null
      },
      {
        width: 10,
        name: this.gettextCatalog.getString('MAC Address'),
        sortBy: 'mac_address',
        asc: null
      },
      {
        width: 15,
        name: this.gettextCatalog.getString('Last Provisioned'),
        sortBy: 'last_provisioned',
        asc: null
      },
      {
        width: 10,
        name: ''
      }
    ]

    this.isResyncingAll = false
    this.addSipTrunkForm = addSipTrunkForm
    this.showSipTrunkButton = false
    this.userService.hasPlatformPermissions().then((hasPermission) => this.showSipTrunkButton = hasPermission)
    
    this.translations = {
      buttonText: this.gettextCatalog.getString('Add Device'),
      addSipTrunkText: this.gettextCatalog.getString('Create SIP Trunk'),
      resyncAllText: this.gettextCatalog.getString('Resync All'),
      resyncingAllText: this.gettextCatalog.getString('Resyncing...'),
      resyncText: this.gettextCatalog.getString('Resync'),
      neverText: this.gettextCatalog.getString('Never'),
      eolNoticeText: this.gettextCatalog.getString('EOL Notice'),
      platformSupportWillEndText: this.gettextCatalog.getString(
        'Platform support for this device will end July 31, 2020.'
      ),
      noLongerSupportedAfterEOLText: this.gettextCatalog.getString(
        'Upon End of Life (EOL) this device will no longer be supported on the platform. Please contact your sales representative for assistance in replacing this device.'
      ),
      eosNoticeText: this.gettextCatalog.getString('TLS Notice'),
      tlsPlatformSupportWillEndText: this.gettextCatalog.getString(
        'TLS 1.0 & 1.1 support will end soon.'
      ),
      noLongerSupportedAfterEOSText: this.gettextCatalog.getString(
        'Upon end of support, this device will no longer be able to use Secure Calling. If you would like to continue using Secure Calling, please contact your sales representative for assistance in replacing this device.'
      ),
      actionWillAddBillText: this.gettextCatalog.getString(
        'This action will add a new billable device.'
      ),
      synchronizedWithUserNameText: this.gettextCatalog.getString(
        "Synchronized with the user's name"
      ),
      designateAsLowUsageDeviceText: this.gettextCatalog.getString(
        'Designate as low-usage device.'
      ),
      resyncNotAvailableText: this.gettextCatalog.getString(
        'Resync is not available since the device is offline.'
      )
    }
    
    this.resyncAllText = this.translations.resyncAllText
    this.resyncingAllText = this.translations.resyncingAllText
    this.addSipTrunkText = this.translations.addSipTrunkText

    this.resyncDelay = DEVICE_RESYNCALL_DELAY

    this.queryParams.optimizedFor = 'deviceList'

    this.loader = infiniteLoader(Device)

    this.isPlatform = _globalState.isPlatform
    this.toggleVisibleItemsFromPermissions()
  }

   toggleVisibleItemsFromPermissions() {
    // Users with manage settings cannot add or delete
    this.hideDelete = false
    this.buttonText = this.translations.buttonText
    if (this.globalState.isUserManageSettingsOnly && !this.globalState.isUserManageSeatsOnly) {
      this.hideDelete = true
      this.buttonText = undefined
    }
  }


  createNewSipTRunk () {
    this.addSipTrunkForm.open().then(newDevice => {
      this.itemSelected(newDevice)
    })
  }

  save (item) {
    item.specifiedName = item.name
    super.save(item)
  }

  delete (event, device) {
    let name = this.gettextCatalog.getString('device') + ': ' + device.name
    this.loader.entityApi.startDelete(event, device, this.data, name)
  }

  itemSelected (item) {
    super.itemSelected(item, 'root.nav.pbx.devices.view.settings', 'deviceId')
  }

  async resyncAll () {
    this.isResyncingAll = true
    try {
      await _portalApi.userAgent
        .pbxs(_globalState.selectedPbxUUID)
        .userAgentConfigurations()
        .one('resync')
        .put()
    } catch (err) {
      _portalUtil.showErrorAlert(
        this.gettextCatalog.getString(
          'Resync All failed, please retry. \n' + err
        )
      )
      throw err
    } finally {
      this.isResyncingAll = false
    }
  }

  createNew () {
    _addDeviceForm.open().then(newDevice => {
      this.itemSelected(newDevice)
    })
  }

  startResyncAllDevicesWarning (deviceCount) {
    _resyncAllDevicesWarning.open(
      deviceCount,
      this.resyncAll.bind(this),
      this.gettextCatalog
    )
  }

  onUpdate (device) {
    getAssignedUserInfo(device)
  }

  onNew () {
    getAssignedUserInfo(this.data.items[0])
  }
}

_module.controller('DevicesController', devicesControllerFactory)
