import _module from 'module.js'
import moment from 'moment/min/moment-with-locales'

_module.directive('validTime', function (globalState) {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, $input, attrs, ngModelCtrl) {
      ngModelCtrl.$validators.validTime = val => {
        if (val) {
          return moment(
            val,
            'LT',
            globalState.currentLanguage.langCode,
            true
          ).isValid()
        }
        return false
      }
    }
  }
})
